import React from "react";
import {ConnectionIcon, FacebookIcon, LinkedinIcon, SpeakersIcon, TwitterIcon} from "../../../../SVG/Icons";
import {OnlineOfflineUser} from "../../../../Util/Utils";
import { Image } from "../../../../UIComponets/Image";
import { EventApi } from "../../../../hooks/shared/EventApi";

export const OPPeopleCard = (props) => {

    const {
        state:{
            details
        }
    } = EventApi();

    const user = OnlineOfflineUser(props);

    return(
        <>
            <div className="network_people_box main_card">
                <div className="people_card_decktop" >
                    {props.isOnline ? <span className="dot"></span> : ""}
                    <h2>{user.getFullName()}</h2>
                    <div className="n_people_profile_box">
                        <div className="img_box">
                            <Image src={props?.avatar ? props?.avatar : user.getAvatar()} placeholderSrc={"/images/placeholder_square.jpg"} alt={'network people'} />
                        </div>
                        <div className="social_btn">
                            {props?.info?.linkedin_link && (<button type="button" className="btn">
                                <a href={props?.info?.linkedin_link} target={'_blank'}><LinkedinIcon fill={details?.primary_color} /></a>
                            </button>)}
                            {props?.info?.twitter_link && (<button type="button" className="btn">
                                <a href={props?.info?.twitter_link} target={'_blank'}><TwitterIcon fill={details?.primary_color}/></a>
                            </button>)}
                            {props?.info?.facebook_link && (<button type="button" className="btn">
                                <a href={props?.info?.facebook_link} target={'_blank'}><FacebookIcon fill={details?.primary_color} /></a>
                            </button>)}
                        </div>
                    </div>
                    <div className="n_people_info mt-30">
                        {user.getTitle() && (<p>{user.getTitle()}</p>)}
                        {user.getCompany() && (<h6>{user.getCompany()}</h6>)}
                        <button type="button" className="btn btn_border_yellow mt-20"  data-toggle="modal" data-target="#speaker-details" onClick={props.speakerDetailsCallback}>
                            <ConnectionIcon fill={details?.text_color}/> CONNECT
                        </button>
                    </div>
                </div>
                {/* mobile */}
                <div className="people_card_mobile">
                    <div class="n_people_profile_box">
                        <div class="img_box">
                            <Image src={props?.avatar ? props?.avatar : user.getAvatar()} placeholderSrc={"/images/placeholder_square.jpg"} alt={'network people'} />
                             {props.isOnline ? <span className="dot"></span> : ""}
                        </div>
                        <div class="info_box">
                            <h2>{user.getFullName()}</h2>
                            {user.getTitle() && (<p>{user.getTitle()}</p>)}
                            {user.getCompany() && (<h6><b>{user.getCompany()}</b></h6>)}
                            <div className="info_social_btn">
                                {props?.info?.linkedin_link && (<button type="button" className="btn">
                                    <a href={props?.info?.linkedin_link} target={'_blank'}><LinkedinIcon fill={details?.primary_color} /></a>
                                </button>)}
                                {props?.info?.twitter_link && (<button type="button" className="btn">
                                    <a href={props?.info?.twitter_link} target={'_blank'}><TwitterIcon fill={details?.primary_color}/></a>
                                </button>)}
                                {props?.info?.facebook_link && (<button type="button" className="btn">
                                    <a href={props?.info?.facebook_link} target={'_blank'}><FacebookIcon fill={details?.primary_color} /></a>
                                </button>)}
                            </div>
                        </div>
                        
                    </div>
                    <div class="more_btn">
                         <button type="button" className="btn btn_border_yellow"  data-toggle="modal" data-target="#speaker-details" onClick={props.speakerDetailsCallback}> MORE</button>
                    </div>
                    
                </div>
            </div>
        </>

    )
}