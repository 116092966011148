import React,{ useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { HorizontalScrollingMenu } from '../../../../../hooks/HorizontalScrollingMenu';

const DailyTriviaCat = ({ filterTrivias, triviasCat, isActive, translations}) => {

    const {
        slide,
        scrollCheck,
        anim,
        anim2,
        state:{
            scrl,
            scrollX,
            scrolEnd
        }
    } = HorizontalScrollingMenu();

    const [scrollBarArrow , setscrollBarArrow] = useState(false);

    useEffect(() => {
        if (scrl?.current?.scrollWidth > scrl?.current?.clientWidth) {
            setscrollBarArrow(true);
        }
    }, [scrollBarArrow]);

    if (!triviasCat || triviasCat?.length === 0){
        return <></>
    }
    return (
            <>
            <div className='session-category-list mb-50'>
                {scrollBarArrow ? scrollX !== 0 &&  (
                <span className="prev cat-arrow-prev cat-arrow" onClick={() => slide(-50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)}>
                <i className="fa fa-angle-left"></i>
                </span>
                ) : null}
                <ul className="video_vault_nav daily_trivia_nav " ref={scrl} onScroll={scrollCheck}>
                    <li className={isActive === "all" ? "active" : ""} onClick={() => filterTrivias("all") }>
                        <Link to={"#"}>{translations?.general?.all}</Link>
                    </li>
                    {triviasCat && triviasCat.map((trivias_categorie, index) => {
                        return (
                            <li key={index} className={isActive === trivias_categorie?.id ? "active" : ""} onClick={()=> filterTrivias(trivias_categorie?.id)}>
                                <Link to={"#"} style={{color:trivias_categorie?.color}}>{trivias_categorie?.name}</Link>
                            </li>
                            )
                        })
                    }
                </ul>
                {scrollBarArrow ? !scrolEnd && (
                <span className="cat-arrow-next next cat-arrow" onClick={() => slide(+50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)} >
                <i className="fa fa-angle-right"></i>
                </span>
                ) : null}
            </div>
        </>
    )
}

export default DailyTriviaCat
