import React from 'react';
import {imageWithStorage} from "../../../../../../constants/url/urlConstants";
import useSlug from '../../../../../../hooks/useSlug';
import { Link } from 'react-router-dom';
import { useTemplate } from '../../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../../Model/TemplateDetails";

const SponsorsList = ({sponsors, sponserDetailsCallback}) => {
    const slug = useSlug().absolutePath;

    const {
        state: {
            layout
        }
    } = useTemplate();
  
    const detail = TemplateDetails(layout)
    const isOnePageTemplate = detail.getTemplate() === Template.ONEPAGE;

    const sponsorslider = <>
        {sponsors && sponsors?.map((sponsor, index) => {
            return ( 
                <div className="items" key={index}>
                    {isOnePageTemplate ? 
                    (<div className="sponsors_slider_img" data-toggle="modal" data-target="#sponsors-details" onClick={() => sponserDetailsCallback(sponsor)}>
                        <img src={imageWithStorage + sponsor?.logo} alt={sponsor?.name} />
                    </div>) 
                    : (<Link to={slug+`/sponsors-details/`+sponsor?.id}>
                    <div className="sponsors_slider_img" data-toggle="modal" data-target="#sponsors-details" onClick={() => sponserDetailsCallback(sponsor)}>
                        <img src={imageWithStorage + sponsor?.logo} alt={sponsor?.name} />
                    </div>
                    </Link>)} 
                </div>
            ) 
        })}
    </>
    return (sponsorslider)
}

export default SponsorsList;
