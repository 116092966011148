import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import {VODPlayer} from "../../classes/VODPlayer";
import $ from 'jquery';

export const ModalIframe = forwardRef(({replayPlayer, className, id, size = "xl"}, ref) => {
    const vodPlaterRef = useRef()

    useImperativeHandle(
        ref,
        () => ({
            play() {
                play()
            },
            stop() {
                stop()
            }
        }),
    )

    const play = () =>  {
        if (vodPlaterRef.current){
            vodPlaterRef?.current?.play()
        }
    }
    const stop = () => {
        if (vodPlaterRef.current){
            vodPlaterRef?.current?.stop()
        }
    }


    // const closeSidemenu= () => {
    //     let iFrame = $('#isVideoVimeo iframe');
    //     let iFrameSRC = iFrame.attr('src');
    //     iFrame.attr('src',' ');
    //     iFrame.attr('src',iFrameSRC);
    //     return true;
    // }

    return (
        <div id="modalVideo" className={`modal fade live_room replay_model connection_request ${className}`} id={`${id}`} data-backdrop="static" data-keyboard={false}>
            <div className={`modal-dialog modal-${size} modal-dialog-centered modal-dialog-scrollable modal-xl`}>
                <div className="modal-content">
                    <div className='modal-header'>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={stop}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="connection_p_head">
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.148" height="37.098" viewBox="0 0 29.148 37.098">
                                    <path id="Icon_material-play-arrow" data-name="Icon material-play-arrow" d="M12,7.5V44.6L41.148,26.049Z" transform="translate(-12 -7.5)" fill="#f26334"/>
                                </svg>
                            </span>
                            <div className="info mb-30">
                                <h4>REPLAY </h4>
                                <h5>{replayPlayer?.meta?.player_title}</h5>
                            </div>
                        </div>
                        <div className="reply-embed-container" id='isVideoVimeo'>
                            <VODPlayer object={replayPlayer} ref={vodPlaterRef} source={replayPlayer?.meta?.replay_type} contentId={replayPlayer?.meta?.replay_session} direct_link={replayPlayer?.meta?.direct_link} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

