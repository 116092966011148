import React, { useEffect, useState } from 'react';
import { imageWithStorage } from '../../../../../constants/url/urlConstants';
import { UserApi } from '../../../../../hooks/shared/UserApi';
import { Image } from '../../../../../UIComponets/Image';
import ReactHtmlParser from 'react-html-parser';
import {EventApi} from "../../../../../hooks/shared/EventApi";
import {Constants} from "../../../../../classes/Constants";

const DailyTriviaList = ({triviasItem, onClick}) => {

    const {
        user
    } = UserApi();

    const [userDetailString, setUserDetailString] = useState(null);

    useEffect(() =>{
        if(user){
            let str = user?.name ? `firstname=${user?.name},` : "";
        if (user?.last_name){
            str = str.concat("lastname=", user?.last_name, ",")  
        }
        if (user?.email){
            str = str.concat("email=", user?.email)  
        }
        setUserDetailString(str);

        }
    }, [user]);

    const setScript = () => {
        let timerFunc = setTimeout(() => {
            window.DailyTriviaScript().loadScript();
        }, 1000);
        return () => {clearTimeout(timerFunc)};
    }
    const event = EventApi()

    return (
        <>
            <div className="row">
                {triviasItem?.length > 0 ? 
                    triviasItem?.map((trivia, index)=> {
                    const {title, description, img_path, embed, html_embed, id} = trivia;
                    return(
                        <div className="col-md-6 col-lg-4" key={id}>
                            <div className="daily_trivia_box mb-60">
                                <div className="daily_triva_img">
                                    <a data-tf-popup={html_embed} 
                                    {...userDetailString ? {'data-tf-hidden': userDetailString} : {} }
                                    className="zoomin">
                                        <figure><Image src={img_path} placeholderSrc={"/images/big-placeholder-trivia.png"} className="image"/></figure>
                                    </a>
                                </div> 
                                <div className="daily_trivia_content">
                                <h4 data-tf-popup={html_embed} 
                                    {...userDetailString ? {'data-tf-hidden': userDetailString} : {} }
                                    className="zoomin">{title}</h4>
                                <p>{description? (ReactHtmlParser(description?.substring(0, 150) ?? '')) : ""}</p>
                                </div>
                            </div>
                        </div>)
                    })
                : <div className="col text-center"><span>{event?.state?.details?.no_trivia_message ?? Constants.NO_DATA_AVAILABLE }</span></div>}
            </div> 
            {setScript()}
        </>
    )
}

export default DailyTriviaList
