import React, {useContext, useState, useEffect,useRef} from 'react';
import SlideDeckList from './SlideDeckList';
import { ShimmerTitle } from "react-shimmer-effects";
import { FaSearch } from "react-icons/fa";
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import { Link } from 'react-router-dom';
import useSlug from '../../../../../../hooks/useSlug';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import SlideDecksMobile from './SlideDecksMobile';
import {AppSettingOptions} from "../../../../../../hooks/shared/AppSettings";
import { HorizontalScrollingMenu } from '../../../../../../hooks/HorizontalScrollingMenu';

export const SlideDecksWrapper = () => {
    const {isSlidedackEnable}  = AppSettingOptions()
    return <>
        {isSlidedackEnable ? (<SlideDecks/>) : null}
    </>
}
const SlideDecks = () => {
   const slug = useSlug().absolutePath;
   const searchRef = useRef(null);
   const slideRef = useRef(null)
   const {
      setFavoriteData,
      state: {
          eventsPageData: {
            details,
            translations,
            slide_decks, 
            slide_decks_categories,
          },
          isValid
      },
    } = useContext(defaultContext);

    const [isActive, setActive] = useState();
    const [stateSlideDecks, setstateSlideDecks] = useState(slide_decks);
    const [favorite, setFavorite] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [scrollArrow, setScrollArrow] = useState(true);
    const [slideArrow, setSlideArrow] = useState(false);


    useEffect(() => {
      setActive("all");
      setstateSlideDecks(slide_decks);
    }, [slide_decks]);

    useEffect(() =>{
      if(slideRef.current.scrollHeight && slideRef.current.clientHeight){
         if(slideRef.current.scrollHeight > slideRef.current.clientHeight){
            setSlideArrow(true);
         } 
      }
   },[slideRef])

    //THIS RESETS THE FILE FIELD
    const handleReset = (values) => {
      searchRef.current.value = ""; 
   }

    const filterHandlerCategary = (slide_cat_id) => {  
      setIsLoading(true);
      handleReset(); 
      setActive(slide_cat_id);
      if(slide_cat_id == "all")
      {
         setIsLoading(false); 
         setstateSlideDecks(slide_decks);
         return;
      }
      const updateSlideDecks = slide_decks && slide_decks.filter((currentCat) => {
            setIsLoading(false);
            return currentCat.category_id === slide_cat_id;
      });
      setstateSlideDecks((pre) => updateSlideDecks);
    }

   const searchHandlerSlideDecks = (query) => {
      if(query !== ''){
         const updatedList = slide_decks && slide_decks.filter((item) => {
            return item?.title.toLowerCase().includes(query.toLowerCase());
         });
         setstateSlideDecks(updatedList);
      }else{
         setstateSlideDecks(slide_decks);
      }
   }

   const handleFavoirte = (id) => {
         setFavorite([...favorite, id]);
         const type = 'slide_decks';
         setFavoriteData(slug, {type, id}, (response, error) => {
            if (response?.data?.status) {
               const res = response?.data?.data;
               const checked = res?.wishlist?.slide_decks.includes(id) ? true : false;
               const updateSlideDecksData = stateSlideDecks?.map(el => (
                   el?.id === id ? {...el, isWishlisted:checked}: el
               ));
               setstateSlideDecks(updateSlideDecksData);
               setFavorite([]);
            }
      });
   }

   const slideDecksScroll  = () => {
      let divElement = document.getElementById("slide_content_box");
      if(divElement.scrollTop > 0){
         divElement.scroll({
            top: 0,
            behavior: 'smooth'
         });
        setScrollArrow(true);
      }else{
         divElement.scroll({
            top: divElement.scrollHeight,
            behavior: 'smooth'
         });
         setScrollArrow(false);
      }
   }

   const {
      slide,
      scrollCheck,
      anim,
      anim2,
      state:{
          scrl,
          scrollX,
          scrolEnd
      }
  } = HorizontalScrollingMenu();

  const [scrollBarArrow , setscrollBarArrow] = useState(false);

  useEffect(() => {
      if(scrl.current){
         if (scrl.current.scrollWidth > scrl.current.clientWidth) {
            setscrollBarArrow(true);
         }
      }
  }, [scrollBarArrow]);

   return (
      <>
            {/* Start slide deck area */}
            <section className="slide_decks_Area mt-50" id="slide-decks">
               <div className="container">
                  <div className="slide_deck_inner">
                        <h1 className="main_heading mb-20">
                           {translations?.slide_decks?.title}
                        </h1>
                              {slide_decks_categories?.length > 0 && (<div className="slide_decks_header">

                                 <div className="session-category-list">
                                    {scrollBarArrow ? scrollX !== 0 &&  (
                                       <span className="prev cat-arrow-prev cat-arrow" onClick={() => slide(-50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)}>
                                          <i className="fa fa-angle-left"></i>
                                       </span>
                                    ) : null}
                                    <ul className="nav"  ref={scrl} onScroll={scrollCheck}>
                                          <li className={isActive === "all" ? "active" : ""} onClick={() => filterHandlerCategary("all")}>
                                             {translations?.general?.all}
                                          </li>
                                          {slide_decks_categories && slide_decks_categories.map((slide_decks_cat ,index) => {
                                             return(
                                                <li 
                                                   key={index} 
                                                   className={isActive === slide_decks_cat?.id ? "active" : ""}
                                                   onClick={() => filterHandlerCategary(slide_decks_cat?.id)}
                                                   style={{color:slide_decks_cat?.color}}
                                                   >
                                                   {slide_decks_cat?.name.toUpperCase()}
                                                </li>
                                             )
                                          })}
                                    </ul>
                                    {scrollBarArrow ? !scrolEnd && (
                                       <span className="cat-arrow-next next cat-arrow" onClick={() => slide(+50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)} >
                                          <i className="fa fa-angle-right"></i>
                                       </span>
                                    ) : null}
                                 </div>

                                 <div className="searchbar_right">
                                       <input type="text" ref={searchRef} placeholder="Search.." name="search" className="form-control" onChange={(e)=>searchHandlerSlideDecks(e.target.value)} />
                                       <button type="button"><FaSearch/></button>
                                 </div>
                              </div>)}

                              {/* <!-- mobile_slide_deck_content --> */}
                              <div className="mobile_slide_deck_content mt-40">
                                 {slide_decks ? slide_decks?.length > 0 ? (<SlideDecksMobile 
                                 slide_decks={stateSlideDecks.filter(sd => sd.active )} 
                                 slide_decks_categories={slide_decks_categories} 
                                 onClick={handleFavoirte}
                                 favorites={favorite}
                                 details={details}
                                 />) : (<div className='col text-center'><span>No data available.</span></div>) : (<ShimmerTitle line={10} gap={15} variant="primary" />)}
                                 {/* <!-- mobile_slide_deck_content --> */}  
                              </div>

                              <div className="slide_deck_content mt-40">
                                 <div className="row align-items-center">
                                       <div className="col-md-12 col-lg-12">
                                             <div className="slide_content_box" id='slide_content_box' ref={slideRef}>
                                                {/* start slide deck lits */}
                                                {slide_decks ? slide_decks?.length > 0 ? (<SlideDeckList 
                                                   slide_decks={stateSlideDecks.filter(sd => sd.active )} 
                                                   slide_decks_categories={slide_decks_categories} 
                                                   onClick={handleFavoirte}
                                                   favorites={favorite}
                                                   details={details}
                                                />) : (<div className='col text-center'><span>No data available.</span></div>) : (<ShimmerTitle line={10} gap={15} variant="primary" />)}
                                                {/* close slide deck lits */}
                                             </div>
                                             {slideArrow && (<div className="read_more_btn">
                                                <Link to={"#"} id="moreless-button" className={scrollArrow && "active"} onClick={slideDecksScroll}>
                                                   {scrollArrow ? (<FaChevronUp/>) : (<FaChevronDown />)}
                                                </Link>
                                             </div>) }
                                             <div className="view_all_btn">
                                                <Link to={slug+'/slide-decks'}>
                                                  <button className="btn"><span><i className="fa fa-angle-right" ></i>
                                                  </span> {translations?.general?.view_all}
                                                  </button>
                                                </Link>
                                             </div>
                                       </div>
                                    {/* <div className="col-md-3 col-lg-3 slide_edit_box">
                                       <div className="slide_img_box text-center">
                                          <img src="/images/slide-logo.png" className="img-fluid"/>
                                       </div>
                                    </div> */}
                                 </div>
                              </div>
                           </div>
                        </div>
                     {/* <div className="slide_edit_img">
                        <img src="/images/edit_icon2.png" />
                     </div> */}
               </section>
            {/* <!-- slide_decl_close --> */}
      </>
   )
}

export default SlideDecks;
