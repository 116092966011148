import React from "react";

export const LiveIcon = () => {
    return <span className="wifi ml-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.105" height="13.732"
             viewBox="0 0 15.105 13.732">
            <g id="Multimedia-_-33---Multimedia_-sound_-surround-icon"
               data-name="Multimedia-/-33---Multimedia,-sound,-surround-icon"
               transform="translate(-0.75 -1.965)">
                <path id="Path"
                      d="M11.877,14.661A6.776,6.776,0,0,0,15.1,8.911,7.039,7.039,0,0,0,11.865,3M4.727,14.661A7.039,7.039,0,0,1,1.5,8.758,6.777,6.777,0,0,1,4.739,3m.977,3.4a3.161,3.161,0,0,0,0,4.859m5.34,0a3.161,3.161,0,0,0,0-4.859"
                      fill="none" strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth="1.5" fillRule="evenodd"/>
                                             <circle id="Path-2" data-name="Path" cx="0.648" cy="0.648" r="0.648"
                                                     transform="translate(7.654 8.183)" fill="none"
                                                     strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
        </svg>
    </span>
}