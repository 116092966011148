import React from 'react';
import { FaTimes } from "react-icons/fa";
import { imageWithStorage } from '../../../../../constants/url/urlConstants';
import useSlug from '../../../../../hooks/useSlug';

const VodFilesModel = ({id, files, close, event}) => {
  const slug = useSlug().path;
    return (
        <>
          <div id={`files_${id}`} className="files_popup" style={{display:'none'}} >
            <span className="helper"></span>
            <div>
                <div className="popupCloseButton" onClick={() =>
                  close(id)} id={`close_${id}`}>
                  <FaTimes/>
                </div>
                <h2>Files</h2>
                <ul className="list-group">
                  {files && files.map((file, index)=>{
                  return(
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="file_name">{file?.filename}</span>
                      <a href={file && imageWithStorage+file?.path} target="_blank" rel="noopener noreferrer" download>
                      <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                          <g id="Group_1947" data-name="Group 1947">
                              <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                              </g>
                              <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                          </g>
                        </svg>
                      </a>
                  </li>
                  )
                  })}
                </ul>
                <div className='text-center mt-20'>
                  {files?.length > 1 && (
                      <a href={`${process.env.REACT_APP_API_HOST}/v2.0/zip-download/${event?.id}?module_id=${id}&type=vod`} target="_blank" className="text-dark">
                        <span className="mr-2">
                          <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                            <g id="Group_1947" data-name="Group 1947">
                            <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                            <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                            </g>
                            <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                            </g>
                          </svg>
                        </span>
                        <span> Download All Files </span>
                      </a>
                  )}
                </div>
            </div>
          </div>
        </>
    )
}

export default VodFilesModel;

