import React,{useContext} from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import useSlug from '../../../../../../hooks/useSlug';
import {URLContants} from "../../../../../../Model/URLContants";
import { Image } from '../../../../../../UIComponets/Image';
import { ShimmerPostList } from "react-shimmer-effects";
import {UserApi} from "../../../../../../hooks/shared/UserApi";
import {GetServeyUrl, ServeyUrlUtils} from "../../../../../../Util/Utils";


function NowLive() {
    const slug = useSlug()
    const Path = (url) => slug.absolutePath + url
    const {
        state:{ 
           eventsPageData: { 
              options,
              carousels,
              translations,

           },
        }
     } = useContext(defaultContext);

    const countItems = (ScreenWidth) => {
        let vodsDetailsItemLength= carousels?.length ?? 0;
        if(ScreenWidth > 1600){
           return vodsDetailsItemLength > 4 ? true : false;
        }
        else if(ScreenWidth > 1200){
           return vodsDetailsItemLength > 3 ? true : false;
        }
        else if(ScreenWidth > 600){
           return vodsDetailsItemLength > 2 ? true : false;
        }
        else if(ScreenWidth <= 400){
           return vodsDetailsItemLength > 1 ? true : false;
        }
    }

   const itemCheck = countItems(window.innerWidth);

    const owlOptions = {
        loop:itemCheck,
        margin: 20,
        nav:itemCheck,
        dots:false,
        autoplay:itemCheck,
        autoplayTimeout:3000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1200: {
                items: 3,
            },
            1600: {
                items: 4,

            }
        },
    };
    
    const add3Dots = (string, limit) => {
        let dots = "...";
        if(string?.length > limit)
        {
            string = string.substring(0,limit) + dots;
        }
        return string;
    }
    const {user} = UserApi()

    return (
        <>
        {/*  mobile_live_section */}
         <div className="mobile_live_section mt-50">
            <div className="container">
                <div className="mobile_live_inner">
                    <Link to={Path(URLContants.LIVE)}>
                        <span><img src="/images/live.png" alt='live image'/></span>
                        <h4>{translations?.general?.live}</h4>
                    </Link>
                </div>
            </div>
        </div>
         {/* mobile_live_section_close */}
            {/* now live slider */}
           {options && options?.includes('carousel') ? (<section className="now_live_slider mt-50">
                <div className="container">
                    {carousels.length > 0 ? ( <h1 className="main_heading mb-20">{translations?.general?.live_now}</h1>) : null}
                    {carousels.length > 0 ? (<OwlCarousel className='owl-carousel owl-theme now_live' {...owlOptions}>
                        {carousels.map((items, index) => {
                            const isExternalUrl = items?.is_external !== 0
                            const isSurvey = !isExternalUrl && items.url === "survey"
                            const isURLOnly =  !isExternalUrl && !isSurvey
                            const cventUrl = GetServeyUrl(user)
                            const buttonText = items.button_text ?? translations?.general?.watch;
                            return (<>
                               {items?.active ? (
                                    <div className="items" index={index}>
                                        <div className="now_live_box card">
                                            <div className="card-body">
                                                {
                                                    isExternalUrl  && <a href={items?.external_url} target={items?.blank_page ? '_blank' : '_self'}>
                                                        <Image src={items?.image} placeholderSrc={"/images/now_img1.png"} alt={"live images"}/>
                                                    </a>
                                                }

                                                {
                                                    isSurvey && <a href={cventUrl} target={'_blank'}>
                                                        <Image src={items?.image} placeholderSrc={"/images/now_img1.png"} alt={"live images"}/>
                                                    </a>
                                                }

                                                {
                                                    isURLOnly && <Link to={`${slug.absolutePath}/${items?.url}`} target={items?.blank_page ? '_blank' : '_self'}>
                                                        <Image src={items?.image} placeholderSrc={"/images/now_img1.png"} alt={"live images"}/>
                                                    </Link>
                                                }
                                                <p title={items?.title}>{add3Dots(items?.title, 50)}</p>
                                            </div>
                                            <div className="card_footer">
                                                {
                                                    isExternalUrl  && <a href={items?.external_url} target={items?.blank_page ? '_blank' : '_self'}>
                                                     <button className="btn btn_theme_blue">{buttonText}</button>
                                                 </a>
                                                }

                                                {
                                                    isSurvey && <a href={cventUrl} target={'_blank'}>
                                                        <button className="btn btn_theme_blue">{buttonText}</button>
                                                    </a>
                                                }

                                                {
                                                    isURLOnly && <Link to={`${slug.absolutePath}/${items?.url}`} target={items?.blank_page ? '_blank' : '_self'}>
                                                        <button className="btn btn_theme_blue">{buttonText}</button>
                                                    </Link>
                                                }

                                            </div>
                                        </div>
                                    </div>
                               ) : null }
                            </>
                            )
                        })} 
                    </OwlCarousel>) : (<ShimmerPostList postStyle="STYLE_FOUR" col={carousels.length ?? 3} row={1} gap={30} />) }
                </div>
            </section>) : null }
            {/* now live slider */}
        </>
    )
}

export default NowLive;
