import React, {useContext, useState} from 'react';
import {useHistory} from "react-router-dom";
import Location from "../../../../hooks/Location";
import logout from "../../../../hooks/logout";
import {Context as AuthContext} from "../../../../context/AuthContext";
import {UserApi} from "../../../../hooks/shared/UserApi";
import {IconLoader} from "../../../../UIComponets/IconLoader";

const LogoutPage = () => {

    const [isLoading, setIsLoading] = useState(false)

    const {
        logout
    } = UserApi()

    const onLogout = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        logout(() => {
            setIsLoading(false)
        })

        //history.replace(`/${client}/${event}/register`);
    };

    return (
        <>
            <div className="modal fade connection_request outging_call show" id="logout">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="connection_request_mbody text-center">
                            <p className='h4 mb-20'>Are you sure you want to log-off?</p>
                            <div className="calling-btn">
                                <button type="button" disabled={isLoading} onClick={onLogout} className="btn btn_border_blue" data-dismiss="modal" data-toggle="modal" data-target="#confirm_del">
                                    <IconLoader isLoading={isLoading}>Yes</IconLoader>
                                </button>
                                <button type="button" disabled={isLoading} className="btn btn_border_orange" data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogoutPage;
