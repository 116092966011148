import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import React from 'react';
import {CookiesProvider} from "react-cookie";
import CookieBot from "react-cookiebot";
import {ToastProvider} from 'react-toast-notifications';
import toast, { Toaster } from 'react-hot-toast';
import LayoutRenderer from './templates/LayoutRenderer';
import {Provider as LanguageContext} from './context/LanguageContext';
import {Provider as AuthContext} from './context/AuthContext';
import {Provider as LayoutContext} from './context/LayoutContext';
import {Provider as DefaultSectionContext} from './context/DefaultSectionContext';
import ContextSynchronizer from "./context/ContextSynchronizer";
import SimpleReactLightbox from 'simple-react-lightbox';
const domainGroupId = '51244b73-7fbb-48cc-bae5-124904885709';

const tagManagerArgs = {
    gtmId: 'GTM-NWT9L8N'
}



function App() {
    // ;

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        TagManager.initialize(tagManagerArgs);
        ReactGA.initialize('UA-39244389-6')
    }



    return (
        <>
            <CookieBot domainGroupId={domainGroupId} />
        {/*<CookiesProvider>*/}
            <ToastProvider>
              <SimpleReactLightbox>
                <LayoutContext>
                    <LanguageContext>
                        <AuthContext>
                            <DefaultSectionContext>
                                <ContextSynchronizer>
                                    <Toaster position="top-right" reverseOrder={false}/>
                                    <LayoutRenderer/>
                                </ContextSynchronizer>
                            </DefaultSectionContext>
                        </AuthContext>
                    </LanguageContext>
                </LayoutContext>
                </SimpleReactLightbox>
            </ToastProvider>
        {/*</CookiesProvider>*/}
            </>
    );
}

export default App;
