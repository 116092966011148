import React from 'react';
import { imageWithStorage } from '../../../../../constants/url/urlConstants';
import useSlug from '../../../../../hooks/useSlug';
import { LinkIcon, FavoriteIcon, FileDownIcon} from '../../../../../SVG/Icons';
import { IconLoader } from '../../../../../UIComponets/IconLoader';
import { FiFile } from "react-icons/fi";
import { FaRegFile } from "react-icons/fa";
import ReactHtmlParser from 'react-html-parser';
import { Translations } from '../../../../../hooks/shared/Translations';
import SlideDecksFileModal from './SlideDecksFileModal';

const SlideDecksMobileView = ({slideDecksList, slideDecksCate, onClick, favorites, details}) => {
    const slug = useSlug().absolutePath;
    const {translations} = Translations();
    const map = slideDecksCate?.reduce((prev, current) => {
       return {
             ...prev,
             [current?.id]: <span style={{background:current?.color}} className="mb-40 p-1">{current?.name.toUpperCase()}</span>
       }
    },{});

    const handleFilePopupMobile = (id) => {
        let filseId =  document.getElementById('files_'+id+'_mobile');
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleCloseMobile = (id) => {
        document.getElementById('files_'+id+"_mobile").style.display = "none";
    }

    return (
        <>
        {/* <!-- mobile_slide_deck_content --> */}
        <div className="mobile_slide_deck_content mt-40 mb-40">
        {slideDecksList && slideDecksList?.map((slide_deck, index) => {
            return (
            <div className="slide_de_con_left" key={index}>
                <div className="row align-items-center">
                    <div className="col-2 col-sm-1">
                        <div className="edit_icon">
                        <span><FiFile/></span>
                        </div>
                    </div>
                    <div className="col-6 col-sm-8">
                        <div className="s_description" data-toggle="collapse" href={`#collapseExample_${slide_deck?.id}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                            <p>{slide_deck?.title}</p>
                        </div>
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className="slide_icon_btn">
                            {slide_deck?.file ? (<button type='button' className='slide-file'>
                                <a href={imageWithStorage+slide_deck?.file} target="_blank" title='Download File' download><FaRegFile/></a>
                            </button>) : null}
                            {/* {slide_deck?.file ? (<button type='button' className='slide-file' onClick={()=>handleFilePopupMobile(slide_deck?.id)}><FaRegFile/></button>) : null} */}
                            {/* <SlideDecksFileModal file={slide_deck?.file} view={'mobile'} id={slide_deck?.id} fileName={slide_deck?.name} close={handleCloseMobile}/> */}
                            {slide_deck?.link ? (<button><a href={slide_deck?.link} title="Link" target="_blank"><LinkIcon/></a></button>) : null}
                            <button type='button' onClick={() => onClick(slide_deck?.id)}>
                                <IconLoader isLoading={favorites.includes(slide_deck?.id) ? true : false}>
                                    <FavoriteIcon stroke={details?.primary_color} width="32" height="29.525" color={slide_deck?.isWishlisted ? details?.primary_color : "none"}/>
                                </IconLoader>
                            </button>
                        </div>
                    </div>
                </div>
                {slide_deck?.description && (<div className="collapse" id={`collapseExample_${slide_deck?.id}`}>
                    <div className="card card-body">
                    <div className='row'>
                            {/* <div className="col-lg-12 col-xl-12">
                            <p className='slide_decks_description'>{ReactHtmlParser(slide_deck?.description ?? '')}</p>
                            </div> */}
                            <div className={`${slide_deck?.file ? "" : "col-xl-12"} col-lg-8 col-xl-8`}>
                                <p className='slide_decks_description'>{ReactHtmlParser(slide_deck?.description ?? '')}</p>
                            </div>
                            {slide_deck?.file ? (
                                <div className='col-lg-4 col-xl-4'>
                                    <div className="session_info_file">
                                            <h2>File</h2>
                                            <ul className="list-group">
                                                <li className="d-flex justify-content-between align-items-center">
                                                    <span className="file_name">{slide_deck?.name}</span>
                                                    <a href={imageWithStorage+slide_deck?.file} target="_blank" rel="noopener noreferrer" title='Download File' download> <FileDownIcon/></a>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            ) : null} 
                        </div>
                    </div>
                </div>)}
            </div>)
            })}
        </div>
        {/* <!-- mobile_slide_deck_content_close --> */}
        </>
    )
}

export default SlideDecksMobileView;
