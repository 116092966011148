import React from 'react';
import { Link } from 'react-router-dom';

const BreakoutRoomCat = ({filterBreakouts, breakoutsCat, isActive}) => {
    return (
        <>
            <ul className="video_vault_nav mb-50">
                <li className={isActive === "all" && "active"} onClick={ ()=> filterBreakouts("all")}>
                    <Link to={"#"}>All</Link>
                </li>
                {breakoutsCat && breakoutsCat.map((category, index) => {
                    return (
                        <li key={index} onClick={ ()=> filterBreakouts(category?.Id)} className={isActive === category?.id && "active"}>
                            <Link to={"#"} style={{color:category?.color}}>{category?.name}</Link>
                        </li>
                    )
                })}
            </ul>  
        </>
    )
}

export default BreakoutRoomCat
