import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import {Appointment, DateUtils, DayGreetingFormate, UserDTO} from "../../../../../Util/Utils";
import { Image } from "../../../../../UIComponets/Image";
import {useToasts} from "react-toast-notifications";
import {SessionApiV2} from "../../../../../hooks/shared/SessionApi2";
import { VideoCallApi } from "../../../../../hooks/videoCallApi";
import {Error, OnlineOfflineUser} from "../../../../../Util/Utils";
import {ResponseWrapper} from "../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import { IconLoader } from "../../../../../UIComponets/IconLoader";
import useSlug from "../../../../../hooks/useSlug";
import { RouteWrapper } from "../../../../Event/Routes";
import { URLContants } from "../../../../../Model/URLContants";

export const AcceptedAppointment = (props) => {

    const {
        getZoneTime,
        getZoneDateTime
    } = SessionApiV2()

    const {
        videoConnect,
        onlineUsers,
    } = VideoCallApi();

    const { addToast } = useToasts();
    const history = useHistory();
    const slug = useSlug()
    const Path = RouteWrapper(slug.absolutePath)
    const [isLoading, setIsLoading] = useState(false)

    const user = props.currentUser;
    const appointment = Appointment(props)
    const particiapnt = user?.id === appointment.getHostUserId() ? appointment.getParticiapnt() : appointment.getHost()
    const userDTO = UserDTO(particiapnt)
    const isOnline = onlineUsers?.membersMap && onlineUsers?.membersMap[userDTO.getId()] ? true : false;
    const delay = 1500;
    
    const startCall = () => {
        if (particiapnt){
            if (isOnline){
                const user_id =  OnlineOfflineUser(particiapnt).getId();
                setIsLoading(true)
                videoConnect({user_id, first_message: ""}, (response, error) => {
                    const wrapper = ResponseWrapper(response)
                    if (error){
                        const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                        addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                    }else{
                        const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                        addToast(wrapper.getMessage(), {appearance, autoDismiss})
                        setTimeout(() => {
                            history.push(Path(URLContants.NETWORK));
                        }, delay)
                    }
                    setIsLoading(false)
                })
            }else{
                const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                let message = "Participant is not online."
                const fullName =  OnlineOfflineUser(particiapnt).getFullName();
                if (fullName.length > 0) {
                    message = `${fullName} is not online.`
                }
                addToast(message, {appearance, autoDismiss})
            }
        }
    }

    const getTime = (time) => {
        return  getZoneTime(props?.activeTime, time)
    };

    // const getTime = (time) => {
    //     return  DateUtils.getAppointmentTime(props?.activeTime, time)
    // };

    const getDateTime = (time) => {
        return  DateUtils.getAppointmentDateTime(props?.activeTime, time)
    };

    const _date = getDateTime(props?.date)

    return <div className="accepted_box mb-20">
        <div className="accepted_box_row">

            <div className="image_box">
                <Image src={userDTO.getAvatar()} placeholderSrc={"/images/placeholder_square.jpg"} alt={"particiapnt image"}/>
            </div>

            <div className="accepted_box_content">
                <div className="accepted_datedescripion">
                    <div className="date">
                        <p>{getTime(props?.date)} <br/> {_date.format("DD") } {_date.format("MMM")}</p>
                    </div>
                    <div className="description">
                        <h4>{userDTO.getFullName()}</h4>
                        <p><span>{userDTO.getCompany()}</span> {userDTO.getTitle()}</p>

                    </div>
                </div>
                <div className="accepted_btn">
                    {(props?.alreadyStarted || props?.onLoby) ? 
                    (<button className="btn btn_theme_pink" onClick={() => startCall()}><IconLoader isLoading={isLoading}>JOIN</IconLoader></button>) : 
                    ( <button className="btn btn_theme_grey"  data-title='Meeting room will open 10 minutes before the start time.' disabled>JOIN</button>)}
                </div>
            </div>
        </div>
    </div>
}