import React, {useEffect, useState} from "react";
import {Translations} from "../../../../../hooks/shared/Translations";
import {AppointmentsApi} from "../../../../../hooks/shared/AppointmentsApi";
import _ from "lodash"
import {AppointmentStatus} from "../../../../../Model/AppointmentStatus";
import {AcceptedAppointment} from "./AcceptedAppointment";
import {PendingAppointment} from "./PendingAppointment";
import {UserApi} from "../../../../../hooks/shared/UserApi";
import {ScheduleAppointmentModel} from "../Networking/UIComponents/ScheduleAppointmentModel";
import moment from "moment";
import { Link } from "react-router-dom";
import useSlug from "../../../../../hooks/useSlug";
import { URLContants } from "../../../../../Model/URLContants";
import {BadgesApi} from "../../../../../hooks/shared/BadgesApi";
import {Badge} from "../../../../../UIComponets/Badge";
import {resetNetworkingBadge} from "../../../../../context/DefaultSectionContext";
import {NetworkingBadgeType} from "../../../../../classes/BadgeType";
import {Constants} from "../../../../../classes/Constants";
import Logger from "../../../../../Util/Logger";


export const MyMeetings = () => {
    const slug = useSlug()
    const Path = (url) => slug.absolutePath + url
    const {translations} = Translations()
    const {
        state: {
            appointments,
            event,
            details
        },
        acceptAppointment,
        cancelAppointment,
        setActiveEu,
    } = AppointmentsApi()

    const {networking_badges, resetNetworkingBadge} = BadgesApi()
    const {
        user
    } = UserApi()

    const [acceptedAppointment, setAcceptedAppointments] = useState([]);
    const [pendingAppointment, setPendingAppointments] = useState([]);
    const [appointmentUpdate, setAppointmentUpdate] = useState(null)
    const [currentTime, setCurrentTime] = useState(new Date());
    const [activeTime, setActiveTime] = useState(false);

    useEffect(() => {
        if (appointments){
            const grouped = _.chain(appointments)
                .groupBy("status")
                .value()
            setAcceptedAppointments(grouped[AppointmentStatus.ACCEPTED])
            setPendingAppointments(grouped[AppointmentStatus.PENDING])
        }
    }, [JSON.stringify(appointments)])

   
    useEffect(() => {
        setInterval(function() {
          // do stuff
          setCurrentTime(new Date())
        }, 2000);    
      }, [])

    const isAlreadyStarted = (date) => {
        const _currrent = moment().tz(event?.timezone ?? Constants.DEFAULT_TIMEZONE).utcOffset(0, true)
        let threash_hold = moment(date).utcOffset(0, true)
        return _currrent >= threash_hold;
    }

    const onLoby = (date) => {
        const _currrent = moment().tz(event?.timezone ?? Constants.DEFAULT_TIMEZONE).utcOffset(0, true)
        let lobyDateTime = moment(date).utcOffset(0, true).subtract(10, 'minutes').local(false)
        let threash_hold = new Date(lobyDateTime);
        return _currrent >= threash_hold;
    }

    useEffect(() => {
        if (acceptedAppointment || pendingAppointment){
            const first= window.$("#networking-meeting-nav .active").first()?.data("id")
            first && resetNetworkingBadge(first)
        }

    }, [JSON.stringify(acceptedAppointment), JSON.stringify(pendingAppointment)])

    const navClick = (e) => {
        const first= window.$(e.currentTarget)?.data("id")
        first && resetNetworkingBadge(first)
    }

    useEffect(() => {
        setActiveEu(activeTime)
    }, [activeTime])

    return <div className="col-md-12 col-lg-12 col-xl-4">
        <h1 className="main_heading mb-20">
            {translations?.general?.my_meetings}
        </h1>
        <div className="active_meeting_inner">
            <div className="my_meeting_box participant_session " id="networking-meeting-nav">
                <ul className="nav eu_time_tab mb-3">
                    <li className={activeTime ? "active" : ""} onClick={() => setActiveTime(true)}>
                        <a>{event?.shortTimezone} {translations?.general?.time}</a>
                    </li>
                    <li className={activeTime ? "" : "active"} onClick={() => setActiveTime(false)}>
                        <a>{translations?.general?.device_time}</a>
                    </li>
                </ul>
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a onClick={navClick} data-id="accepted" className="nav-link active accepted" data-toggle="tab" href="#ACCEPTED" role="tab">
                            <Badge badge={networking_badges?.accepted}/>
                            {translations?.general?.accepted}
                        </a>
                    </li>
                    <li className="nav-item">
                        <a onClick={navClick} className="nav-link pending" data-id="pending" data-toggle="tab" href="#PENDING" role="tab">
                            <Badge badge={networking_badges?.pendings}/>
                            {translations?.general?.pending}
                        </a>
                    </li>
                </ul>
                {/* -- Tab panes -- */}
                <div className="tab-content mt-40">
                    <div className="tab-pane active" id="ACCEPTED" role="tabpanel">
                        {acceptedAppointment?.length > 0 ? acceptedAppointment?.map(a =>  <AcceptedAppointment activeTime={activeTime}  event={event} currentUser={user} alreadyStarted={isAlreadyStarted(a.date)} onLoby={onLoby(a.date)}  {...a} />)
                        : (<span className="d-flex justify-content-center">{details && details?.no_meetings_message}</span>)}
                    </div>
                    <div className="tab-pane" id="PENDING" role="tabpanel">
                        {pendingAppointment?.length > 0 ? pendingAppointment?.map(p => <PendingAppointment activeTime={activeTime} event={event} rescheduleAppointmentCallback={setAppointmentUpdate} {...p} currentUser={user} {...{acceptAppointment, cancelAppointment}}/>)
                        : (<span className="d-flex justify-content-center">{details && details?.no_meetings_message}</span>)}
                    </div> 
                </div>

            </div>
            <div className="view_all_btn">
                <Link to={Path(URLContants.NETWORK)+'#meeting'}>
                    <button className="btn">
                        <span><i className="fa fa-angle-right"></i></span>VIEW ALL
                    </button>
                </Link>
            </div>
        </div>
        <ScheduleAppointmentModel appointmentUpdate={appointmentUpdate}/>
    </div>
}