import React, { forwardRef, useContext, useEffect, useState } from "react";
import { IconLoader } from "../../../../../UIComponets/IconLoader";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import useSlug from "../../../../../hooks/useSlug";
import { ResponseWrapper } from "../../../../../Model/ResponseWrapper";
import {InfoIcon, SearchIcon, SendIcon} from "../../../../../SVG/Icons";
import NotificationCenter from "../../../../../hooks/NotificationCenter";
import { InteractionSettings } from "../../../../../hooks/shared/InteractionSettings";
import { UserApi } from "../../../../../hooks/shared/UserApi";
import InputEmoji from 'react-input-emoji';
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import {Error} from "../../../../../Util/Utils";
import {useToasts} from "react-toast-notifications";
import PullToRefresh from 'react-simple-pull-to-refresh';

export const Message = (props) => {
    const _data = props;
    let user_name = _data.user_name
    if (_data.username) {
        user_name = _data.username
    } else if (_data.name || _data.last_name) {
        user_name = `${_data.name ?? ""} ${_data.last_name ?? ""} `
    }

    return {
        message: _data?.message,
        time: _data?.time,
        user_name: user_name,
        moderated: _data?.moderated,
        moderate_message:_data?.moderate_message
    }
}

export const ChatMessage = ({ isSender, message, key }) => {
    return <li className={`chat_${isSender ? "right" : "left"}`} key={key}>
        <h4>
            {message?.message}
            {isSender ? (<span className="chat_icon_right">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.202" height="19" viewBox="0 0 19.202 19">
                    <path id="Tail" d="M19.2,19H19a18.88,18.88,0,0,1-7.4-1.493,18.937,18.937,0,0,1-6.039-4.072A18.937,18.937,0,0,1,1.493,7.4,18.88,18.88,0,0,1,0,0H15V9a13.957,13.957,0,0,0,4.2,10Z" />
                </svg>
            </span>) : (<span className="chat_icon_left">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.201" height="19" viewBox="0 0 19.201 19">
                    <path id="Tail" d="M.2,19H0a13.952,13.952,0,0,0,3.074-4.484A13.907,13.907,0,0,0,4.2,9V0h15a18.879,18.879,0,0,1-1.493,7.4,18.938,18.938,0,0,1-4.072,6.039A18.938,18.938,0,0,1,7.6,17.507,18.88,18.88,0,0,1,.2,19Z" transform="translate(0)" />
                </svg>
            </span>)}

        </h4>
        <div className="chat_time_bottom d-flex align-items-center">
          <p>{message?.time} <span> {message?.user_name}</span> </p>

            {
                message.moderated === 0 ? <div className="information_box">
            <span className="informationin_text">
               {message?.moderate_message}
            </span>
                        <span className="information_icon">
                            <InfoIcon />
                        </span>
                    </div>
                    : null
            }



        </div>
      
    </li>
}
export const TabWrapper = forwardRef((props, ref) => {
    return <div ref={ref} className={`tab-pane ${props?.isActive ? "active" : ""}`} id={props.id} role="tabpanel">
        {props.children}
    </div>
})
export const ChatTab = () => {
    const {
        selectedChannel,
        chat,
        options,
        translations,
        isChatForEach,
        getChatMessages,
        isEmotiocnsEnabled
    } = InteractionSettings({})
    const { addToast } = useToasts();
    const slug = useSlug()
    const { messages } = chat
    const { user } = UserApi()
    const [isSending, setIsSending] = useState(false)
    const [message, setMessage] = useState('')
    const messageFieldRef = React.useRef(null);

    const chatContainer = React.useRef(null);
    const {
        sendChat
    } = useContext(defaultContext)

    const [channel_id, setChannelId] = useState(selectedChannel?.current_channel?.id)
    useEffect(() => {
        setChannelId(selectedChannel?.current_channel?.id)

    }, [selectedChannel])


    const onSendMessageEnter = (e) => {
        if (e.key === "Enter" && message?.length > 0) {
            sendChatMessage()
        }
    }
    const sendChatMessage = () => {
        setIsSending(true)
        sendChat(slug.path, { message, channel_id }, (response, error) => {
            setIsSending(false)
            if (response) {
                const wrapper = ResponseWrapper(response)
                if (wrapper.getStatus()) {
                    setMessage('')
                    const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                    addToast(wrapper.getMessage(), {appearance, autoDismiss})
                }else if (error){
                    const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                    addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                }
            }

            scrollToMyRef()
            setTimeout(() => {
                messageFieldRef.current.focus();
            }, 200)
        })
    }
    const [listner, setListner] = useState(null)
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        NotificationCenter.sharedInstance().registerNotification('chatEvent', (data) => {
            if (isChatForEach && channel_id === data?.event_channel_id || !isChatForEach && !data?.event_channel_id) {
                setTimeout(() => {
                    scrollToMyRef()
                }, 1000)
            }
        })
        scrollToMyRef()
    }, [isChatForEach, channel_id]);

    const scrollToMyRef = () => {
        if (chatContainer.current){
                if (chatContainer.current.scrollHeight && chatContainer.current.clientHeight){
                    window.$(chatContainer.current).animate({
                        scrollTop: chatContainer.current.scrollHeight - chatContainer.current.clientHeight
                    }, 500);
                }

        }
    };

    const [chatLoading, setChatLoading] = useState(false)
    const [hasNoMore, setHasNoMore] = useState(true)
    const getItems = (resolve) => {
        // alert(prmois)
        setChatLoading(true)
        if (messages.first()){
            getChatMessages(messages.first().id, (response, error) => {
                resolve()
                setChatLoading(false)
                if (response?.data?.data?.chat?.messages?.length === 0){
                    setHasNoMore(false)
                }
            })
        }

    }
    return <div className="lr_chat_box">
        <div className="searchbar_right">
                <input type="text" value={searchText} placeholder={translations?.general?.search} onChange={e => setSearchText(e.currentTarget.value)} name="search" className="form-control"/>
                <button type="submit">
                    <SearchIcon />
                </button>
            </div>
        <div className="send_input_box live_send_input_box">
            {isEmotiocnsEnabled && !chat?.blocked ? (
                    <InputEmoji 
                    type="text" 
                    value={message} 
                    className="form-control"
                    id="send" 
                    placeholder={translations?.chat?.answer_placeholder}
                    disabled={isSending}
                    onChange={setMessage}
                    ref={messageFieldRef}
                    cleanOnEnter
                    onKeyDown={onSendMessageEnter}
                    onEnter={onSendMessageEnter}
                />
            ) : (
                <input type="text" value={message} className="form-control"
                id="send" placeholder={!chat?.blocked ? translations?.chat?.answer_placeholder : "You Are Blocked!"}
                       disabled={isSending || chat?.blocked}
                onChange={e => setMessage(e.target.value)}
                ref={messageFieldRef}
                onKeyDown={onSendMessageEnter}
            />
            ) }
            {!chat?.blocked && <IconLoader isLoading={isSending}>
                <SendIcon className="live_send_icon" onClick={sendChatMessage} />
            </IconLoader> }
        </div>
        <PullToRefresh isPullable={hasNoMore} onRefresh={() => new Promise((resolve) => getItems(resolve))}>
        <ul className="one_chat_box" ref={chatContainer}>

            {messages && messages?.filter(m => {
                const seachedText = searchText?.trim().toLowerCase()
                if (seachedText.length > 0){
                    return m.message?.toLowerCase().includes(seachedText)
                }else {
                    return true;
                }

            })?.map((m, index) => ChatMessage({ isSender: m?.user_id === user?.id, message: Message(m), key: index }))}

        </ul>
        </PullToRefresh>
    </div>
}