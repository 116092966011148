export const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
export const RESET_STATE = 'RESET_STATE'
export const formReducer = (state, action) => {
    if (action.type === FORM_INPUT_UPDATE) {
        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value
        };
        const updatedValidities = {
            ...state.inputValidities,
            [action.input]: action.isValid
        };
        let updatedFormIsValid = true;
        for (const key in updatedValidities) {
            updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
        }
        let isPasswordMatched = false;
        if (state?.inputValues?.password === state?.inputValues?.confirmPassword) isPasswordMatched = true
        return {
            formIsValid: updatedFormIsValid,
            inputValidities: updatedValidities,
            inputValues: updatedValues,
            isPasswordMatched
        };
    }
    if (action.type === RESET_STATE) {
        const testState = Object.keys(state).forEach((i) => state.inputValues[i] = null);
        const _state = Object.keys(state).forEach(k => state.inputValues[k] = '');

        return {
            inputValues: action.value,
            inputValidities: action.isValid,
            formIsValid: false

        }
    }
    return state;
};