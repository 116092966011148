import React, { useState, useCallback, useEffect, useContext } from "react";
import Video from "twilio-video";
import Lobby from "./Lobby";
import Room from "./Room";
import { useToasts } from "react-toast-notifications";
import { Context as defaultContext } from './../../../../../../context/DefaultSectionContext';
import NotificationCenter from "../../../../../../hooks/NotificationCenter";
import {ErrorOption} from "../../../../../../hooks/showToast";
import SharedVideoChatState from "../../../../../../classes/SharedVideoChatState";


const VideoChat = () => {
  const {
    state: {
      videoChat,
      eventsPageData: { user
      },
      endChat
    }
  } = useContext(defaultContext)


  const { addToast } = useToasts();
  const [room, setRoom] = useState(null);
  const [connecting, setConnecting] = useState(false);



  useEffect(() => {
    const roomName = videoChat?.twilio_room;
    const userName = videoChat?.twilio_identity;
    const token = videoChat?.twilio_token;
    if (roomName && userName && token) {

      setConnecting(true);
      Video.connect(token, {
        name: roomName,
        audio: true,
        video: true,
      })
        .then((room) => {
          setConnecting(false);
          setRoom(room);
          SharedVideoChatState.instance().setRoom(room);
        })
        .catch((err) => {
          console.error(err);
          setConnecting(false);
        });

        NotificationCenter.sharedInstance().registerNotification("videoChatDeclineEvent", (data) => {
          if (data?.username) {
            addToast(`${data?.user_id == user.id ? "You" : data?.username} is not available`, ErrorOption)
          }
         })
      NotificationCenter.sharedInstance().registerNotification("endChatRequest", (data) => {
        // console.debug("endChat")
        let __room = SharedVideoChatState.instance().getRoom();
        __room?.localParticipant?.tracks?.forEach((trackPub) => {
          trackPub.track.stop();
        });
        __room?.disconnect();
        SharedVideoChatState.instance().setRoom(null);
        setRoom(null);
      })
    }

  }, [videoChat]);

  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      return null;
    });
  }, []);

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  let render = <></>;
  if (room) {
    render = (
      <>
        <Room room={room} />
      </>
    );
  }
  return render;
};

export default VideoChat;
