import useSlug from "../useSlug";
import {useContext, useEffect, useState} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import {AppointmentStatus} from "../../Model/AppointmentStatus";
import moment from "moment";
import {DateFormates} from "../../classes/DateFormates";
import {DateUtils} from "../../Util/Utils";
import {Constants} from "../../classes/Constants";
import Logger from "../../Util/Logger";

export function AppointmentsApi(){
    const slug = useSlug();
    const {
        state: {
            eventsPageData: {
                appointments,
                event,
                details,
            }
        },
        makeAppointment,
        updateAppointment,
    } = useContext(defaultContext);

    const [searchQuery, setSearchQuery] = useState('');
    const [appointmentsData, setAppointmentsData] = useState();

    useEffect(() =>{
        if(appointments){
            setAppointmentsData(appointments);
        }
    },[appointments]);

    const _appointments = appointmentsData?.filter(a => a.status !== AppointmentStatus.DECLINE)

    const searchMyMeeting = (query) => {
        setSearchQuery(query);
        if (query !== '') {
            const findAppointments = _appointments && _appointments.filter((items) => {
                return `${items?.host_user?.name} ${items?.host_user?.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
                || `${items?.user?.name} ${items?.user?.last_name}`.toLowerCase().includes(searchQuery.toLowerCase());
            });
            setAppointmentsData(findAppointments)
        }
        else{
            setAppointmentsData(appointments)
        }
    }
    const [activeEu, setActiveEu] = useState(false);

    return {
        searchMyMeeting,
        setActiveEu,
        state: {
            event:event,
            details:details,
            appointments: _appointments,
            appointmentsMap: _appointments?.reduce((prev, next) => {
                let key = DateUtils.getAppointmentDateTime(activeEu, next.date, event?.timezone ?? Constants.DEFAULT_TIMEZONE).format(DateFormates.DDMMYYYY_HYPHEN)
                const _md = {...prev}
                if(!_md[key]){
                    _md[key] = []
                }
                _md[key].push(next)
                return _md
            }, {})
        },
        makeAppointment: (data, callback) => {
            makeAppointment(slug.path, data, callback)
        },
        updateAppointment: (data, callback) => updateAppointment(slug.path, {...data, status: AppointmentStatus.PENDING}, callback),
        acceptAppointment: (data, callback) => updateAppointment(slug.path, {...data, status: AppointmentStatus.ACCEPTED}, callback),
        cancelAppointment: (data, callback) => updateAppointment(slug.path, {...data, status: AppointmentStatus.DECLINE}, callback)
    }

}
