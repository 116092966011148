export enum URLContants {
    LOGIN = "/login",
    REGISTER = "/register",
    ABOUT = "/about",
    BLOG = "/blog",
    LIVE = "/live",
    VIDEO_VAULT = "/vod",
    VIDEO_VAULT_DETAILS_WITOUTSLUG = "/video-vault-details",
    VIDEO_VAULT_DETAILS = "/video-vault-details/:slug",
    SESSIONS = "/sessions",
    SLIDE_DECKS = "/slide-decks",
    BREAKOUT_ROOM = "/breakout-room",
    SPONSORS = "/sponsors",
    SOCIAL_WALL = "/social-wall",
    NETWORK = "/network",
    NETWORKPROFILE = "/network/:slug",
    DAILY_TRIVIA = "/daily-trivia",
    PROFILE = "/profile",
    FAQ = "/faq",
    PROFILE_SESSION = "/profile-session",
    MEETING = "/meeting",
    CHAT_WINDOW = "/chat-window",
    DICE = "/dice",
    PARTICIPANT_DETAILS = "/participant-details/:slug",
    SPEAKERS = "/speakers",
    SPEAKERSEARCH = "/speakers/:slug",
    SPEAKER_DETAILS = "/speaker-details/:slug",
    SPONSORS_DETAILS = "/sponsors-details/:slug",
    BREAKOUT_ROOM_CALL = "/breakout-room-call",
    ACTIVE_Call = "/active-call",
}