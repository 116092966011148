import React,{useCallback} from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const UpDownScroll = ({id, isActive, onClick }) => {
    return (
        <>
            <a id="moreless-button" onClick={useCallback(() => onClick(id), [id])} className={`${isActive ? 'active' : ''}` }>
                {isActive ? (<FaChevronUp/>) : (<FaChevronDown />)}
            </a>  
        </>
    )
}

export default UpDownScroll;
