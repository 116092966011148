import React, { useState } from 'react';
import { IoMdVideocam } from "react-icons/io";
import VideoChat from "./VideoChat/VideoChat";
import NotificationCenter from "../../../../../hooks/NotificationCenter";
import { ActiveCallOnlinePeople } from "./UIComponents/ActiveCallOnlinePeople";
import { ActiveCallChat } from "./UIComponents/ActiveCallChat";
import { VideoCallApi } from "../../../../../hooks/videoCallApi";
import { UserDTO } from "../../../../../Util/Utils";
import { UserApi } from "../../../../../hooks/shared/UserApi";

const ActivesCall = ({ hidden }) => {
   const {
      connectedPeoples,
   } = VideoCallApi()
   const {
      user
   } = UserApi()



   const hostUser = connectedPeoples?.filter(i => i.id === i.host_id)?.first() ?? user
   return (
      <>
         <div className="mobile_active_call  " style={{ display: "none" }}>
            <div className="container">
               <h1 className="main_heading">Breakout Room: Meet at the bar</h1>
               <div className="mobile_active_call_inner">
                  <div className="action_call_area">
                     <div className="action_call_header">
                        <p>10 min 46 sec</p>
                        <div className="action_call_header_btn">
                           <button type="button" className="btn" data-toggle="modal" data-target="#m_chatbox_model">
                              <svg id="Icon_ionic-ios-chatbubbles" xmlns="http://www.w3.org/2000/svg" width="17.858" height="17.857" viewBox="0 0 17.858 17.857">
                                 <path id="Path_1272" d="M21.687,15.077a1.04,1.04,0,0,1,.142-.524,1.446,1.446,0,0,1,.09-.133A6.956,6.956,0,0,0,23.1,10.544a7.3,7.3,0,0,0-7.457-7.169A7.405,7.405,0,0,0,8.336,9.08a6.9,6.9,0,0,0-.159,1.468,7.282,7.282,0,0,0,7.341,7.259,8.841,8.841,0,0,0,2.026-.331c.485-.133.966-.309,1.09-.356a1.135,1.135,0,0,1,.4-.073,1.116,1.116,0,0,1,.434.086L21.9,18a.58.58,0,0,0,.167.043.342.342,0,0,0,.343-.343.551.551,0,0,0-.021-.116Z" transform="translate(-5.245 -3.375)" fill="#fff" />
                                 <path id="Path_1273" d="M14.987,21.874c-.155.043-.352.09-.567.137a7.923,7.923,0,0,1-1.46.193A7.282,7.282,0,0,1,5.62,14.946a8.116,8.116,0,0,1,.064-.919c.026-.185.056-.369.1-.549s.094-.386.15-.575l-.343.3a6.389,6.389,0,0,0-2.215,4.8,6.317,6.317,0,0,0,1.065,3.52c.1.15.155.266.137.343s-.511,2.662-.511,2.662a.344.344,0,0,0,.116.331.35.35,0,0,0,.219.077.308.308,0,0,0,.124-.026l2.408-.949a.671.671,0,0,1,.515.009,7.225,7.225,0,0,0,2.606.515,6.742,6.742,0,0,0,5.156-2.365s.137-.189.3-.412C15.348,21.771,15.167,21.827,14.987,21.874Z" transform="translate(-3.375 -7.086)" fill="#fff" />
                              </svg>
                           </button>
                           <button type="button" className="btn" data-toggle="modal" data-target="#m_chatbox_user">
                              <svg xmlns="http://www.w3.org/2000/svg" width="22.712" height="14.601" viewBox="0 0 22.712 14.601">
                                 <g id="Group_1017" transform="translate(6165.646 9526.811)">
                                    <path id="Icon_ionic-md-people" d="M17.736,13.959a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,17.736,13.959Zm-8.259,0a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,9.477,13.959Zm0,2.231c-2.426,0-7.227,1.166-7.227,3.549v2.738h14.6V19.738C16.851,17.355,11.9,16.189,9.477,16.189Zm8.259.558a5.51,5.51,0,0,0-.885.051,3.023,3.023,0,0,1,1.622,2.94v2.738h6.489V19.738C24.962,17.355,20.162,16.747,17.736,16.747Z" transform="translate(-6167.896 -9534.686)" fill="#fff" />
                                 </g>
                              </svg>
                           </button>
                        </div>
                     </div>
                     <img alt="image" src="/images/action_call_img.png" />
                     <div className="br_video_chat_footer">
                        <button type="button" className="btn"><img alt="image" src="/images/chat_icon1.png" /></button>
                        <button type="button" className="btn"><img alt="image" src="/images/chat_icon2.png" /></button>
                        <button type="button" className="btn"><img alt="image" src="/images/chat_icon3.png" /></button>
                        <button type="button" className="btn" data-toggle="modal" data-target="#create_public"><img alt="image" src="/images/chat_icon6.png" /></button>
                        <button type="button" className="btn" ><img alt="image" src="/images/chat_icon4.png" /></button>
                        <button type="button" className="btn" data-toggle="modal" data-target="#outging_call"><img alt="image" src="/images/chat_icon5.png" /></button>
                     </div>
                     <div className="ac_call_partner_img">
                        <img alt="image" src="/images/video_chat_img6.png" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="active_call_area people_call_back multi-people-call mt-50 mb-100" hidden={hidden}>
            <div className="container">

               {connectedPeoples && <div className="breakout_room_header">
                 <span><IoMdVideocam/></span> <h4>{UserDTO(hostUser).getFullName()} - HOST</h4>  
               </div>}

               <div className="row mt-20">
                  <div className="col-md-12 col-lg-8">
                     <VideoChat />
                  </div>
                  <div className="col-md-12 col-lg-4">
                     <div className="br_people_chat_box">
                        <ul className="nav nav-tabs" role="tablist">
                           <li className="nav-item">
                              <a className="nav-link active" data-toggle="tab" href="#CHAT" role="tab">CHAT</a>
                           </li>
                           <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#PEOPLE" role="tab">PEOPLE</a>
                           </li>
                        </ul>
                        {/* <!-- Tab panes --> */}
                        <div className="tab-content">
                           <ActiveCallChat />
                           <ActiveCallOnlinePeople />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- The Modal --> */}
         {/* <!-- mobile_chat_model --> */}
         <div className="modal fade mobile_chat_model" id="m_chatbox_model">
            <div className="modal-dialog modal-sm modal-dialog-centered">
               <div className="modal-content">
                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                     <h1 className="main_heading">CHAT</h1>
                     <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>
                  {/* <!-- Modal body --> */}
                  <div className="mobile_chat_box_mbody">
                     <ActiveCallChat />
                     {/* <div className="br_chat_inner">
                           <div className="lr_chat_box">
                              <div className="searchbar_right">
                                    <input type="text" placeholder="Search.." name="search" className="form-control"/>
                                    <button type="submit">
                                       <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                          <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                       </svg>
                                    </button>
                              </div>
                             
                              <div className="send_input_box">
                                    <input type="text" className="form-control" id="send" placeholder="Message"/>
                                    <svg className="svg-inline--fa fa-paper-plane fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="paper-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                       <path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
                                    </svg>
                              </div>
                           </div>
                        </div> */}
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- mobile_people_model --> */}
         <div className="modal fade mobile_chat_model" id="m_chatbox_user" >
            <div className="modal-dialog modal-sm modal-dialog-centered">
               <div className="modal-content">
                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                     <h1 className="main_heading">PEOPLE</h1>
                     <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>
                  {/* <!-- Modal body --> */}
                  <div className="mobile_people_mbody">
                     <div className="br_people_tab_inner">
                        <div className="tab-pane" id="Event1" role="tabpanel">
                           <div className="searchbar_right mb-30">
                              <input type="text" placeholder="Search.." name="search" className="form-control" />
                              <button type="submit">
                                 <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                 </svg>
                                 {/* <!-- <SearchIcon /> -->  */}
                              </button>
                           </div>
                           <ActiveCallOnlinePeople />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- mobile_chat_model_close --> */}
         {/* <!-- The Modal --> */}
      </>
   )
}

export default ActivesCall;