import React, {useRef, useState, useContext, useEffect, useCallback} from 'react';
import {RollingDice} from "./RollingDice";
import {UIUtils} from "../../../../../../hooks/shared/UIUtils";
import {VideoCallApi} from "../../../../../../hooks/videoCallApi";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {ErrorOption, getOptions, SuccessOption, ToastType} from "../../../../../../hooks/showToast";
import {Error} from "../../../../../../Util/Utils";
import $ from "jquery";
import {useToasts} from "react-toast-notifications";
import IncomingCallModel from "./IncomingCallModel";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import { useTemplate } from '../../../../../../hooks/shared/useTemplate';
import { Template, TemplateDetails } from '../../../../../../Model/TemplateDetails';
import useSlug from '../../../../../../hooks/useSlug';
import {UserApi} from "../../../../../../hooks/shared/UserApi";
import {SpinnerIcon} from "../../../../../../SVG/Icons";
import { EventApi } from '../../../../../../hooks/shared/EventApi';

const Dice = () => {
    const { isMobile } = UIUtils()
    const [matched, setMatched] = useState(false)
    const [isRollingDice, setIsRollingDice] = useState(false);
    const ref = useRef();

    const {
        dice_matched,
        rollDice
    } = VideoCallApi();

    const { addToast } = useToasts();
    const rollingTriggerButtonRef = useRef();

    const {
        state :{
            details
        }
    } = EventApi();

    const {
        userInteresetMap,
        rollDiceInterestUpdate,
        user_interests,
        interests
    } = UserApi()

    const [interesets, setInteresets] = useState(null);

    useEffect(() => {
            if (userInteresetMap && interests) {
                setInteresets({
                    isInitial: true,
                    items: interests?.map(i => {
                        return {
                            ...i,
                            selected:  userInteresetMap[i.id] ? true : false
                        };
                    })
                })
            }
    }, [JSON.stringify(userInteresetMap)]);

    const selectInterest = (interest) => {
        const index = interesets?.items?.findIndex(i => i.id === interest.id)
        if (index !== -1){
            let _interesets = [...interesets.items ?? []]
            _interesets[index].selected = !interest.selected
            setInteresets({
                isInitial: false,
                items: _interesets})
        }
    }
    const [isIneresetUpdating, setIsIneresetUpdating] = useState(false)
    useEffect(() => {
        if (!interesets || interesets?.isInitial === true) return;
        setIsIneresetUpdating(true)
        const timeOutId = setTimeout(() => {
            const items = interesets?.items?.filter(i => i.selected).map(i => i.id)
            rollDiceInterestUpdate(items, (response, error) => {
                if (response) {
                    addToast(response.data.message, SuccessOption)
                } else if (error) {
                    addToast(error.response.data.message, ErrorOption);
                }
                setIsIneresetUpdating(false)
            });
        }, 2000);
        return () => {
            clearTimeout(timeOutId)
        };
    }, [interesets]);



    const rollDiceReq = () => {
        ref.current.rollingDice()
        setIsRollingDice(true)
        setMatched(false)
        rollDice((response, error) => {
            const wrapper = ResponseWrapper(response)
            if (response){
                const {appearance, autoDismiss} = getOptions(ToastType.SUCCESS)
                let msg = typeof(wrapper.getMessage()) === 'undefined' ? "Operation Successfull" : wrapper.getMessage();
                addToast(msg , {appearance, autoDismiss})
                setMatched(true)

            }else if (error){
                const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                setMatched(false)
            }
        })
    }
    
    const {
        state: {
            layout
        }
    } = useTemplate()

    const detail = TemplateDetails(layout)
    const isOnePageTemplate = detail.getTemplate() === Template.ONEPAGE

    const animationEndedCallback = useCallback(() => {
        setIsRollingDice(false)
        if (matched){
            rollingTriggerButtonRef?.current?.click()
        }
    }, [matched])

   return (
      <>
          <div className={isOnePageTemplate ? "" : "col-md-12 col-lg-10 col-xl-11"}>
              <div className="dice_area_inner">
                  <div className="row">
                    {details?.nt_match_on === 'interest' && (
                        interesets?.items?.length > 0 ? <div className="col-md-12 col-lg-12 col-xl-3">
                            <div className="your_interests main-card">
                                <h4>Your interests {isIneresetUpdating ? <SpinnerIcon /> : null }</h4>
                                    <div className="poll_check">
                                    <form>
                                        {interesets?.items?.map((interest, index) => {
                                            return (
                                                <div className="form-group" key={index}>
                                                    <input 
                                                        type="checkbox" 
                                                        id={interest?.interest}   
                                                        onChange={()=> selectInterest(interest)}
                                                        defaultChecked={interest.selected}
                                                    />
                                                    <label htmlFor={interest?.interest}>{interest?.interest}</label>
                                                </div>
                                            )
                                        })}
                                    </form>
                                </div> 
                            </div> 
                      </div> : null)}

                      <div className={`col-md-12 col-lg-12 col-xl-${details?.nt_match_on === 'random' || interesets?.items?.length < 0 ? "12" : "9"}`}>
                            {!isMobile && <div className="fint_your_match main-card dashtop_view">
                              <h4>Find your match!</h4>
                              <p>Meet GS1 Peers networking is only available Monday at 15:15 CET and Tuesday at 07:00 CET
                              </p>
                              <div className="dice_section_area">
                                  <RollingDice animationEndedCallback={animationEndedCallback} isMatched={matched} ref={ref}/>
                              </div>
                              <div className="roll_btn">
                                  {/*data-target="#roll_the_dice"*/}
                                  <button disabled={isRollingDice} type="button" className="btn btn_border_orange" data-toggle="modal"
                                          onClick={rollDiceReq}>
                                      <IconLoader isLoading={isRollingDice}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="25.608" height="25.608"
                                               viewBox="0 0 25.608 25.608">
                                              <path id="Icon_metro-dice" data-name="Icon metro-dice"
                                                    d="M24.177,6.73h-12.8a4.013,4.013,0,0,0-4,4v12.8a4.013,4.013,0,0,0,4,4h12.8a4.013,4.013,0,0,0,4-4v-12.8a4.013,4.013,0,0,0-4-4Zm-11.2,17.605a2.4,2.4,0,1,1,2.4-2.4,2.4,2.4,0,0,1-2.4,2.4Zm0-9.6a2.4,2.4,0,1,1,2.4-2.4,2.4,2.4,0,0,1-2.4,2.4Zm4.8,4.8a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,17.775,19.534Zm4.8,4.8a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,22.577,24.335Zm0-9.6a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,22.577,14.732Zm.719-9.6a4.017,4.017,0,0,0-3.92-3.2H6.572a4.013,4.013,0,0,0-4,4v12.8a4.017,4.017,0,0,0,3.2,3.92V6.73a1.605,1.605,0,0,1,1.6-1.6H23.3Z"
                                                    transform="translate(-2.571 -1.928)" fill="#454545"/>
                                          </svg>
                                      </IconLoader>
                                      Roll the dice
                                  </button>
                              </div>
                          </div> }
                          <button type="button" hidden={true} ref={rollingTriggerButtonRef} className="btn btn_border_orange" data-toggle="modal"
                                  data-target="#roll_the_dice" id="rolling_dice_trigger_button"/>
                          { isMobile && <div className="fint_your_match main-card mobile_view mt-60">

                              <h4>Roll the dice</h4>
                              <p>Match with someone based on your mutual interests
                              </p>
                              <div className="dice_section_area">
                                  <RollingDice animationEndedCallback={animationEndedCallback} isMatched={matched} ref={ref}/>
                              </div>

                              <div className="roll_btn">
                                  {/* data-target="#roll_the_dice" */}
                                  <button disabled={isRollingDice} type="button" className="btn btn_border_orange" data-toggle="modal"
                                          onClick={rollDiceReq}>
                                      <IconLoader isLoading={isRollingDice}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="25.608" height="25.608"
                                               viewBox="0 0 25.608 25.608">
                                              <path id="Icon_metro-dice" data-name="Icon metro-dice"
                                                    d="M24.177,6.73h-12.8a4.013,4.013,0,0,0-4,4v12.8a4.013,4.013,0,0,0,4,4h12.8a4.013,4.013,0,0,0,4-4v-12.8a4.013,4.013,0,0,0-4-4Zm-11.2,17.605a2.4,2.4,0,1,1,2.4-2.4,2.4,2.4,0,0,1-2.4,2.4Zm0-9.6a2.4,2.4,0,1,1,2.4-2.4,2.4,2.4,0,0,1-2.4,2.4Zm4.8,4.8a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,17.775,19.534Zm4.8,4.8a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,22.577,24.335Zm0-9.6a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,22.577,14.732Zm.719-9.6a4.017,4.017,0,0,0-3.92-3.2H6.572a4.013,4.013,0,0,0-4,4v12.8a4.017,4.017,0,0,0,3.2,3.92V6.73a1.605,1.605,0,0,1,1.6-1.6H23.3Z"
                                                    transform="translate(-2.571 -1.928)" fill="#454545"/>
                                          </svg>
                                      </IconLoader>
                                      Roll the dice
                                  </button>
                              </div>
                          </div>
                          }
                      </div>
                  </div>
              </div>
          </div>
      </>
   )
}

export default Dice
