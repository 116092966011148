import React,{useEffect} from 'react';
import { Form } from 'react-bootstrap';

const UserInterests = ({interests, onInputCheck}) => {
    return (
        <>
            <div className="col-md-12 col-lg-12 col-xl-3">
               <div className="your_interests main-card">
                   <span className="interests_label">Interests</span>
                    {interests?.length > 0 ? <div className="poll_check">
                        {interests && interests.map((interest, index) => {
                            return (
                                <div className="form-group" key={interest?.id}>
                                    <Form.Check 
                                        type="checkbox" 
                                        name="interests"
                                        id={interest?.interest}  
                                        label={interest?.interest}
                                        value={interest?.id}
                                        onChange={(event) => onInputCheck(event)}
                                        defaultChecked={interest?.ischecked}
                                    />
                                </div>
                            )
                        })}
                    </div> : null}
                </div> 
            </div>  
        </>
    )
}

export default UserInterests;
