import React, {Fragment, useContext, useEffect, useState} from "react";
import useSlug from "../../../../../hooks/useSlug";
import {useToasts} from "react-toast-notifications";
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import {ResponseWrapper} from "../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import {Error} from "../../../../../Util/Utils";
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import {InteractionSettings} from "../../../../../hooks/shared/InteractionSettings";

export const VotingTab = () => {

    const {
        selectedChannel,
        vote,
        details,
        translations,
        isChatForEach
    } = InteractionSettings({})

    const channel = selectedChannel;
    const slug = useSlug()
    const [isSending, setIsSending] = useState(false)

    const { addToast } = useToasts();

    const {
        sendVote
    } = useContext(defaultContext)

    const [channel_id, setChannelId] = useState(selectedChannel?.current_channel?.id)
    useEffect(() => {
        setChannelId(selectedChannel?.current_channel?.id)

    }, [selectedChannel])

    const [selectionMap, setSelectionMap] = useState({})
    const selectVoteOption = ({question_id, answer_id}) => {
        setSelectionMap((prev) => {
            return {
                ...prev,
                [question_id]: answer_id
            }
        })
    }

    useEffect(() => {
        if (vote?.questions){
            setSelectionMap( prev => {
                return {
                    ...prev,
                    ...vote?.questions?.
                    filter(r => r?.answer_id !== 0).
                    reduce((prev, next) => {
                        return {
                            ...prev,
                            [next?.id]: {
                                answer_id: next?.given_star
                            }
                        }
                    }, {})
                }
            })
        }
    }, [vote])

    const sendVoteAnswer = ({question_id}) => {
        if (!selectionMap[question_id]) return;

        setIsSending(true)
        sendVote(slug.path, {question_id, option_id: selectionMap[question_id],  channel_id}, (response, error) => {
            setIsSending(false)
            if (response) {
                const wrapper = ResponseWrapper(response)
                if (wrapper.getStatus()) {
                    const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                    addToast(wrapper.getMessage(), {appearance, autoDismiss})
                }
            }else if (error){
                const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                addToast(Error(error).getResponseError(), {appearance, autoDismiss})
            }
        })
    }


    return <div className="poll_accordion_content voting_acco_content">

            {
                vote?.questions?.length > 0 ? vote?.questions.map((q, index) => {
                    const answer_id = q?.answer_id
                    const isAnswered = q?.answer_id > 0;
                    const optionsMap = q?.options?.reduce((prev, next) => {
                        return {
                            ...prev,
                            [next?.id]: next
                        }
                    }, {})
                    const answer = isAnswered ? optionsMap[answer_id]?.option : ""

                    return <Fragment key={"heading-" + index}>
                        <div className="p_acco_con_heading">
                                    <span className="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="36.786" height="25.75"
                                           viewBox="0 0 36.786 25.75">
                                         <path id="Icon_awesome-vote-yea" data-name="Icon awesome-vote-yea"
                                               d="M34.946,18.8H31.268v3.679h1.288a.513.513,0,0,1,.552.46v.92a.513.513,0,0,1-.552.46H4.23a.513.513,0,0,1-.552-.46v-.92a.513.513,0,0,1,.552-.46H5.518V18.8H1.839A1.837,1.837,0,0,0,0,20.643v5.518A1.837,1.837,0,0,0,1.839,28H34.946a1.837,1.837,0,0,0,1.839-1.839V20.643A1.837,1.837,0,0,0,34.946,18.8Zm-5.518,3.679V4.107A1.856,1.856,0,0,0,27.572,2.25H9.219A1.861,1.861,0,0,0,7.357,4.107V22.482ZM12.139,12.021,13.6,10.567a.612.612,0,0,1,.874.006l2.374,2.391,5.472-5.426a.612.612,0,0,1,.874.006l1.454,1.466a.612.612,0,0,1-.006.874l-7.374,7.311a.612.612,0,0,1-.874-.006l-4.259-4.294a.612.612,0,0,1,0-.874Z"
                                               transform="translate(0 -2.25)" fill={details?.primary_color ? details?.primary_color : 'currentColor'}/>
                                       </svg>
                                       </span>
                            <span className="content">
                                       <h4>{translations?.vote?.title}</h4>
                                       <p>{q?.question}</p>
                                    </span>

                        </div>

                        <div className="p_acco_con_body">
                            <div className="poll_check">
                                <form>
                                    {
                                        q.options?.map((o, i) => {
                                            return <div key={i} className="form-group">
                                                <input disabled={isAnswered} type="checkbox" id={o.id} checked={selectionMap[q?.id] === o?.id || (o.id === answer_id)} onChange={() => selectVoteOption({question_id: q?.id, answer_id: o?.id})}/>
                                                <label htmlFor={o.id}>{o.option}</label>
                                            </div>
                                        })
                                    }
                                </form>
                            </div>
                            {
                                !isAnswered &&
                                <div className="poll_send_btn">
                                <button type className="btn" disabled={isSending || !selectionMap[q?.id]}
                                        onClick={() => sendVoteAnswer({question_id: q?.id})}>
                                    <IconLoader isLoading={isSending}>
                                        <img alt="image" src="/images/send.png"/> {translations?.general?.send}
                                    </IconLoader>
                                </button>
                            </div>}

                            {isAnswered ? `You Answered : ${answer}` : ""}
                        </div>
                    </Fragment>
                }) : <><div className="p_acco_con_heading">
                                    <span className="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="36.786" height="25.75"
                                           viewBox="0 0 36.786 25.75">
                                         <path id="Icon_awesome-vote-yea" data-name="Icon awesome-vote-yea"
                                               d="M34.946,18.8H31.268v3.679h1.288a.513.513,0,0,1,.552.46v.92a.513.513,0,0,1-.552.46H4.23a.513.513,0,0,1-.552-.46v-.92a.513.513,0,0,1,.552-.46H5.518V18.8H1.839A1.837,1.837,0,0,0,0,20.643v5.518A1.837,1.837,0,0,0,1.839,28H34.946a1.837,1.837,0,0,0,1.839-1.839V20.643A1.837,1.837,0,0,0,34.946,18.8Zm-5.518,3.679V4.107A1.856,1.856,0,0,0,27.572,2.25H9.219A1.861,1.861,0,0,0,7.357,4.107V22.482ZM12.139,12.021,13.6,10.567a.612.612,0,0,1,.874.006l2.374,2.391,5.472-5.426a.612.612,0,0,1,.874.006l1.454,1.466a.612.612,0,0,1-.006.874l-7.374,7.311a.612.612,0,0,1-.874-.006l-4.259-4.294a.612.612,0,0,1,0-.874Z"
                                               transform="translate(0 -2.25)" fill="#f26334"/>
                                       </svg>
                                       </span>
                    <span className="content">
                                       <h4>{translations?.vote?.title}</h4>
                                    </span>

                </div>

                    <div className="p_acco_con_body">
                        <div className="poll_check">
                        {details?.no_vote_message}
                        </div>
                    </div>
                </>
            }

        </div>
}