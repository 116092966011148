class Logger {
     static getString = function (object) {
         let _str = object
         if (typeof object === 'object'){
             _str = JSON.stringify(object)
         }
         return _str
    }
   static debugLog = function (message) {
        if (Boolean.parse(process.env.REACT_APP_LOGGING)){
            console.log("%c" + Logger.getString(message), "color: blue")
        }
    }
    static errorLog = function (message) {
        if (Boolean.parse(process.env.REACT_APP_LOGGING)){
            console.log("%c" + Logger.getString(message), "color: red")
        }
    }

    static verbose = function (message) {
        if (Boolean.parse(process.env.REACT_APP_LOGGING)){
            console.log("%c" + Logger.getString(message), "color: green")
        }
    }

}

window.debugLog = Logger.debugLog;
window.errorLog = Logger.errorLog;
window.verbose = Logger.verbose;

export default Logger;