import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useSlug from "../../../../../../hooks/useSlug";
import {Context as defaultContext} from  '../../../../../../context/DefaultSectionContext';
import {VideoCallApi} from "../../../../../../hooks/videoCallApi";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import {Button} from "react-bootstrap";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import {useToasts} from "react-toast-notifications";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {Error, OnlineOfflineUser} from "../../../../../../Util/Utils";
import { Translations } from "../../../../../../hooks/shared/Translations";
import { UserApi } from "../../../../../../hooks/shared/UserApi";
import { Image } from "../../../../../../UIComponets/Image";
import $ from "jquery";

export const OutgoingDetails = ({participantDetails, startResponseCallback}) => {
    const {user} = UserApi();
    const {
        videoConnect
    } = VideoCallApi();

    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { addToast } = useToasts();

    const startCall = () => {
        if (participantDetails){
            const user_id =  OnlineOfflineUser(participantDetails).getId()
            setIsLoading(true)
            videoConnect({user_id, first_message: message}, (response, error) => {
                const wrapper = ResponseWrapper(response)
                if (error){
                    const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                    addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                }else{
                    const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                    addToast(wrapper.getMessage(), {appearance, autoDismiss})
                    $('#outging_call button[data-dismiss="modal"]').click();
                }
                setIsLoading(false)
                startResponseCallback && startResponseCallback(response, error)
            })
        }
    }

    return  <div className="connection_request_body">
        <div className="row">
            <div className="col-md-12 col-lg-6">
                <div className="connection_p_head">
                    <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.34" height="20.427" viewBox="0 0 31.34 20.427" className="primary-color">
                            <path
                                id="Icon_ionic-md-videocam"
                                data-name="Icon ionic-md-videocam"
                                d="M26.874,15.394V9.434a1.725,1.725,0,0,0-1.742-1.7H3.992a1.725,1.725,0,0,0-1.742,1.7v17.02a1.73,1.73,0,0,0,1.742,1.707h21.14a1.729,1.729,0,0,0,1.742-1.7V20.5l6.716,6.807V8.588Z"
                                transform="translate(-2.25 -7.734)"
                               
                            />
                        </svg>
                    </span>
                    <div className="info mb-30">
                        <h4>Outgoing call</h4>
                        <p>{participantDetails?.name} {participantDetails?.last_name}</p>
                    </div>
                </div>
                <div className="outgoing_img">
                    <Image src={user?.avtar} placeholderSrc={"/images/placeholder_square.jpg"} alt={"participant image"} className="img_right" />
                    <Image src={participantDetails?.avtar} placeholderSrc={"/images/placeholder_square.jpg"} alt={"participant image"} className="img_left" />
                </div>
            </div>
            <div className="col-md-12 col-lg-6">
                <div className="outgoing_right_box mt-100">
                    <h6>You are about to call {participantDetails?.name} {participantDetails?.last_name}</h6>
                    <h5>{participantDetails?.title}</h5>
                    <p>{participantDetails?.company}</p>
                    <form action="" className="mt-40">
                        <textarea onChange={e => {

                            setMessage(e.currentTarget.value)
                        }} className="form-control" rows="5" id="comment" className="form-control" placeholder={"Message *"} value={message}/>
                    </form>
                </div>
            </div>
        </div>
        <div className="calling-btn">
            <button type="send" disabled={isLoading} className="btn btn_border_orange" onClick={startCall}>
                <IconLoader isLoading={isLoading}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40.5" height="27" viewBox="0 0 40.5 27">
                        <path
                            id="Icon_awesome-video"
                            data-name="Icon awesome-video"
                            d="M23.639,4.5H3.361A3.361,3.361,0,0,0,0,7.861V28.139A3.361,3.361,0,0,0,3.361,31.5H23.639A3.361,3.361,0,0,0,27,28.139V7.861A3.361,3.361,0,0,0,23.639,4.5ZM36.956,7.151,29.25,12.466V23.534l7.706,5.309A2.255,2.255,0,0,0,40.5,27.028V8.965A2.255,2.255,0,0,0,36.956,7.151Z"
                            transform="translate(0 -4.5)"
                            fill="#454545"
                        />
                    </svg>
                    Call
                </IconLoader>
            </button>

        </div>
    </div>
}

export const OutgoingCallModel = ({participantDetails}) => {
    return (
        <div className="modal fade connection_request outging_call" id="outging_call">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                       <OutgoingDetails participantDetails={participantDetails} />
                    </div>
                </div>
            </div>
        </div>
    )
}