import React, {forwardRef, useRef, useState} from 'react'
import {VideoCallApi} from "../../../../../../hooks/videoCallApi";
import {DiceMatched, Error} from "../../../../../../Util/Utils";
import {Image} from "../../../../../../UIComponets/Image";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import $ from "jquery";
import {useToasts} from "react-toast-notifications";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";

const IncomingCallModel = () => {
    const {
        dice_matched,
        videoConnect,
    } = VideoCallApi()
    const userDetails = DiceMatched(dice_matched)
    const [isLoading, setIsLoading] = useState(false)
    const { addToast } = useToasts();
    const modelCloseRef = useRef()
    const startVideoChat = () => {
            if (dice_matched){
                const user_id =  userDetails?.getId();
                const dice_matched =  userDetails?.getMatchedOn();
                setIsLoading(true)
                videoConnect({user_id , dice_matched} , (response, error) => {
                    const wrapper = ResponseWrapper(response)
                    if (error){
                        const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                        addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                    }else{
                        const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                        addToast(wrapper.getMessage(), {appearance, autoDismiss})
                        modelCloseRef?.current?.click();
                    }
                    setIsLoading(false)
                })
            }
        }

        

    return (
        <>
            <div className="modal fade connection_request sehedule_appointment" id="roll_the_dice">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <button ref={modelCloseRef} type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        {/* Modal body */}
                        <div className="modal-body">
                            <div className="connection_request_body">
                                <div className="connection_p_head">
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31.339" height="31.339" viewBox="0 0 31.339 31.339">
                                            <path
                                                id="Icon_metro-dice"
                                                data-name="Icon metro-dice"
                                                d="M29.013,7.8H13.344a4.911,4.911,0,0,0-4.9,4.9v15.67a4.911,4.911,0,0,0,4.9,4.9h15.67a4.911,4.911,0,0,0,4.9-4.9V12.7a4.911,4.911,0,0,0-4.9-4.9ZM15.3,29.35a2.938,2.938,0,1,1,2.938-2.938A2.938,2.938,0,0,1,15.3,29.35Zm0-11.752A2.938,2.938,0,1,1,18.24,14.66,2.938,2.938,0,0,1,15.3,17.6Zm5.876,5.876a2.938,2.938,0,1,1,2.938-2.938A2.938,2.938,0,0,1,21.179,23.474Zm5.876,5.876a2.938,2.938,0,1,1,2.938-2.938A2.938,2.938,0,0,1,27.055,29.35Zm0-11.752a2.938,2.938,0,1,1,2.938-2.938A2.938,2.938,0,0,1,27.055,17.6Zm.88-11.752a4.916,4.916,0,0,0-4.8-3.917H7.467a4.911,4.911,0,0,0-4.9,4.9v15.67a4.916,4.916,0,0,0,3.917,4.8V7.8A1.964,1.964,0,0,1,8.447,5.845H27.935Z"
                                                transform="translate(-2.571 -1.928)"
                                                fill="#f26334"
                                            />
                                        </svg>
                                    </span>
                                    <div className="info mb-30">
                                        <h4>We have a match!</h4>
                                        <p>{userDetails?.getFullName()}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-6">
                                        <div className="dice_model_left">
                                            <Image src={userDetails?.getAvatar()} placeholderSrc={'/images/placeholder_square.jpg'} alt={"people pic"}/>

                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <div className="dice_model_right">
                                            <h1>Matching interest: {userDetails?.getMatchedOn()}</h1>

                                            <div className="information">
                                                <h6>{userDetails?.getTitle()}</h6>
                                                <h5>{userDetails?.getCompany()}</h5>
                                            </div>

                                            <p className="m_description">
                                                {
                                                    userDetails?.getDecription()
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="dice_model_btn">
                                    <button type="button" className="btn btn_border_blue" onClick={startVideoChat} disabled={isLoading}>
                                        <IconLoader isLoading={isLoading}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.986" height="29.257" viewBox="0 0 26.986 29.257">
                                                <path id="Icon_ionic-ios-thumbs-up" data-name="Icon ionic-ios-thumbs-up" d="M30.326,27.337A2.854,2.854,0,0,0,31.5,25.13a3.087,3.087,0,0,0-.766-2.046c-.042-.084-.077-.162-.112-.239l-.014-.077a.918.918,0,0,1,.141-.7,3.082,3.082,0,0,0,.408-1.807,2.136,2.136,0,0,0-.682-1.631,1.341,1.341,0,0,1-.288-1.287,2.712,2.712,0,0,0-.577-2.658c-.738-.83-1.427-.928-3.263-.823-1.266.077-3.9.485-5.639.759-.71.113-1.329.211-1.519.225-.893.091-1.069,0-1.153-.345a2.28,2.28,0,0,1,.345-.914,15.079,15.079,0,0,0,1.02-2.517c.71-2.334.633-4.866-.19-6.342A2.3,2.3,0,0,0,17.36,3.382a2.8,2.8,0,0,0-1.962.555c-.239.281-.162.816-.436,1.884a20.5,20.5,0,0,1-.844,2.953c-.527,1.167-2.791,3.171-4.008,4.247-.288.253-.534.471-.71.64a14.493,14.493,0,0,0-1.793,2.3,12.239,12.239,0,0,1-.963,1.3,2.54,2.54,0,0,1-1.568.766.564.564,0,0,0-.562.563V29.805a.582.582,0,0,0,.584.584,11.08,11.08,0,0,1,4.669.83,12.151,12.151,0,0,0,3.262.851,67.537,67.537,0,0,0,7.917.57,35.041,35.041,0,0,0,3.98-.274c2.18-.253,4.514-.759,5.288-2.6a1.986,1.986,0,0,0-.035-1.666.553.553,0,0,1-.056-.183A.6.6,0,0,1,30.326,27.337Z" transform="translate(-4.514 -3.382)" fill="#002C6C"/>
                                            </svg>
                                        </IconLoader>
                                        Start 1 on 1
                                    </button>
                                    <button type="button" className="btn btn_border_orange"  data-dismiss="modal">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.986" height="29.257" viewBox="0 0 26.986 29.257">
                                            <path id="Icon_ionic-ios-thumbs-down" data-name="Icon ionic-ios-thumbs-down" d="M5.674,8.663A2.854,2.854,0,0,0,4.5,10.87a3.087,3.087,0,0,0,.766,2.046c.042.084.077.162.113.239l.014.077a.918.918,0,0,1-.141.7,3.082,3.082,0,0,0-.408,1.807,2.136,2.136,0,0,0,.682,1.631,1.341,1.341,0,0,1,.288,1.287,2.712,2.712,0,0,0,.577,2.658c.738.83,1.427.928,3.262.823,1.266-.077,3.9-.485,5.639-.759.71-.112,1.329-.211,1.519-.225.893-.091,1.069,0,1.153.345a2.28,2.28,0,0,1-.345.914,15.079,15.079,0,0,0-1.02,2.517c-.71,2.334-.633,4.866.19,6.342a2.3,2.3,0,0,0,1.849,1.343,2.8,2.8,0,0,0,1.962-.555c.239-.281.162-.816.436-1.884a20.5,20.5,0,0,1,.844-2.953c.527-1.167,2.791-3.171,4.008-4.247.288-.253.534-.471.71-.64a14.493,14.493,0,0,0,1.793-2.3,12.239,12.239,0,0,1,.963-1.3,2.54,2.54,0,0,1,1.568-.766.564.564,0,0,0,.563-.562V6.195a.582.582,0,0,0-.584-.584,11.08,11.08,0,0,1-4.669-.83,12.151,12.151,0,0,0-3.263-.851,67.537,67.537,0,0,0-7.917-.57,35.042,35.042,0,0,0-3.98.274c-2.18.253-4.514.759-5.287,2.6A1.986,1.986,0,0,0,5.822,7.9a.553.553,0,0,1,.056.183A.6.6,0,0,1,5.674,8.663Z" transform="translate(-4.5 -3.361)" fill="#F26334"/>
                                        </svg>
                                        Roll again
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IncomingCallModel;
