import React from 'react';
import moment from 'moment';
import {SpeakerApi} from "../../../../../hooks/shared/SpeakerApi";

const SpeakersSession = ({sessions, isActiveEu}) => {

    const {
        getZoneTime,
        getZoneDateTime,
    } = SpeakerApi();

    return (
        <>
            {sessions && sessions.map((sessionItem, index) =>{
                return(
                    <div className="session_accordion_box mb-20" key={index}>
                        <div className="accordion destop_ed_time" id="accordionExample">
                            <div className="session_accordion_header">
                                <div className={`date ${sessionItem?.is_live && 'live'}`}>
                                    <div className="mobile_time">
                                    <span className="mr-2">{getZoneTime(isActiveEu, sessionItem?.start)}<br/>{getZoneTime(isActiveEu, sessionItem?.end)}</span>
                                        <span>{getZoneDateTime(isActiveEu, sessionItem?.start).format("ddd")}<br/>{getZoneDateTime(isActiveEu, sessionItem?.start).format("DD")}/{getZoneDateTime(isActiveEu, sessionItem?.start).format("MM")}</span> 
                                    </div>
                                  
                                    <p>{getZoneTime(isActiveEu, sessionItem?.start)}  {getZoneDateTime(isActiveEu, sessionItem?.start).format("ddd DD MMM")}</p>
                                    <h4 title={sessionItem?.title}>{sessionItem?.title}</h4>
                                </div>
                                {sessionItem?.is_live ? (<div className="live_icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" className='primary-stroke-color' width="34.584" height="30.69" viewBox="0 0 34.584 30.69">
                                    <g id="Multimedia-_-33---Multimedia_-sound_-surround-icon" data-name="Multimedia-/-33---Multimedia,-sound,-surround-icon" transform="translate(-0.5 -1.62)">
                                        <path id="Path" d="M26.355,30.929a16.23,16.23,0,0,0,7.729-13.773A16.859,16.859,0,0,0,26.326,3M9.229,30.929A16.859,16.859,0,0,1,1.5,16.791,16.232,16.232,0,0,1,9.258,3M11.6,11.146a7.571,7.571,0,0,0,0,11.637m12.789,0a7.571,7.571,0,0,0,0-11.637" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fillRule="evenodd"/>
                                        <circle id="Path-2" data-name="Path" cx="1.552" cy="1.552" r="1.552" transform="translate(16.24 15.413)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    </g>
                                    </svg>
                                </div>) : null}
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default SpeakersSession;
