import { useState, useRef, useEffect} from "react";
import { Link } from 'react-router-dom';
import { gsap } from "gsap";
import { CgSelectR } from "react-icons/cg";

const SessionsMobileCategory = ({filterSessionData, sessionCat, isActive, translations}) => {

    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);
    const [scrollBarArrow , setscrollBarArrow] = useState(false);

    useEffect(() => {
        if (scrl.current.scrollWidth > scrl.current.clientWidth) {
            setscrollBarArrow(true);
        }
    }, [scrollBarArrow]);

    
    //Slide click
    const slide = (shift) => {
      scrl.current.scrollLeft += shift;
      setscrollX(scrollX + shift);
      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
      ) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    };
  
    //Anim
    const anim = (e) => {
      gsap.from(e.target, { scale: 1 });
      gsap.to(e.target, { scale: 1.5 });
    };
    const anim2 = (e) => {
      gsap.from(e.target, { scale: 1.5 });
      gsap.to(e.target, { scale: 1 });
    };
  
    const scrollCheck = () => {
      setscrollX(scrl.current.scrollLeft);
      if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    };
    return (
        <>
         <div className='session-category-list'>
            {scrollBarArrow ? scrollX !== 0 &&  (
            <span className="prev cat-arrow-prev cat-arrow" onClick={() => slide(-50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)}>
              <i className="fa fa-angle-left"></i>
            </span>
            ) : null}

            <ul className="video_vault_nav session_area_nav mb-50" ref={scrl} onScroll={scrollCheck}>
                <li className={isActive === -1 ? "active" : ""} onClick={()=> filterSessionData(-1)}>
                    <Link to={"#"}>{translations?.general?.all}</Link>
                </li>
                {sessionCat && sessionCat.map((category, index) => {
                    const {id, name, slug, type, color} = category; 
                    return (
                            <li className={isActive === id ? "active" : ""} key={index} onClick={()=> filterSessionData(id)}>
                                <Link to={"#"} className="" style={{color:color}}>{name}</Link>
                            </li>
                        )
                    })}
            </ul>  
          {scrollBarArrow ? !scrolEnd && (
            <span className="cat-arrow-next next cat-arrow" onClick={() => slide(+50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)} >
              <i className="fa fa-angle-right"></i>
             </span>
            ) : null}
        </div>
        </>
    )
}

export default SessionsMobileCategory
