enum Event {
    CHAT = "chat",
    QA_A = "qa_a",
    POLL = "poll",
    RATING ="rating",
    VOTE ="vote",
    CALLING="calling",
    ONE_TO_ONE="one_to_one",
    APPOINTMENT="appointment",
    EVENTOPTION="event_option",
    SESSION="session",
    CHANNEL="channel",
    TICKER="tickers",
    CONNECTION="connection"

}

export enum Action {
    CREATE = "create",
    DELETE = "delete",
    UPDATE = "update",
    LIVE = "live",
    ACTIVE = "activate",
    DEACTIVE = "deactivate",
    BLOCKED = "blocked",
    UNBLOCKED = "unblock",
    ACCEPT = "accept",
    DECLINE = "decline",
    ACCEPTED = "accepted",
    DECLINED = "declined",
}
// @ts-ignore
export const EventHandler = (data, context) => {
    const type = data.type
    const {
    chatEvent,
        qaAnswerEvent,
        pollEvent,
        ratingEvent,
        voteEvent,
        callingEvent,
        o2oChatEvent,
        appointmentUpdate,
        eventOption,
        sessionEvent,
        updateChannel,
        tickerEvent,
        connectionEvent
    } = context;

    switch (type) {
        case Event.CHAT:
            chatEvent && chatEvent(data)
            break
        case Event.QA_A:
            qaAnswerEvent && qaAnswerEvent(data)
            break
        case Event.POLL:
            pollEvent && pollEvent(data)
            break
        case Event.RATING:
            ratingEvent && ratingEvent(data)
            break
        case Event.VOTE:
            voteEvent && voteEvent(data)
            break;
        case Event.CALLING:
            callingEvent && callingEvent(data)
            break
        case Event.ONE_TO_ONE:
            o2oChatEvent && o2oChatEvent(data)
            break
        case Event.APPOINTMENT:
            appointmentUpdate && appointmentUpdate(data)
            break
        case Event.EVENTOPTION:
            eventOption && eventOption(data)
            break;
        case Event.SESSION:
            sessionEvent && sessionEvent(data)
            break;
        case Event.CHANNEL:
            updateChannel && updateChannel(data)
            break;
        case Event.TICKER:
            tickerEvent && tickerEvent(data);
            break;
        case Event.CONNECTION:
            connectionEvent && connectionEvent(data)
            break;
    }
}