export function UIUtils() {

    const isMobile = () => {
        return window.innerWidth < 320;
    }

    return {
        isMobile: isMobile()
    }

}