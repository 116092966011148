import React, {useState, useContext, useEffect, useRef} from 'react';
import { useToasts } from "react-toast-notifications";
import VodList from './VodList';
import VodCat from './VodCat';
import VodMobileCat from './VodMobileCat';
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import { ShimmerPostList,ShimmerTitle} from "react-shimmer-effects";
import useSlug from "../../../../../hooks/useSlug";
import { SizeUtils } from '../../../../../hooks/shared/SizeUtils';
import {Constants} from "../../../../../classes/Constants";
import { IconLoader } from '../../../../../UIComponets/IconLoader';
import {SearchIcon} from "../../../../../SVG/Icons";

const VideoVault = () => {
    const slug = useSlug().absolutePath;
    const ServicesRef = useRef(null);
    const {isMobile} = SizeUtils()

    //React State
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false);

    const [color, setColor] = useState("#ffffff");

    const {
        getVodData,
        state:{ 
           eventsAllPagesData: { 
                vods,
                vod_categories 
            },
            eventsPageData: { 
                event,
                details,
                translations,
            }
        }
     } = useContext(defaultContext);
     
     const [isVideoVimeo, setVideoVimeoId] = useState(0);
     const [isModalOpen, setModalOpen] = useState(false);
     const [vodsItem, setVodsItem] = useState(vods);
     const [vodsCat, setVodsCat] = useState(vod_categories);
     const [isActive, setActive] = useState("all");
     const [currentChar, setCurrentChar] = useState(null);

    useEffect(()=> {
        setIsLoading(true);
        getVodData(slug, (res, error) => {
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        if (vods) {
            setVodsItem(vods);
        }
    }, [vods]);

    useEffect(() => {
        if (vod_categories) {
            setVodsCat(vod_categories);
        }
    }, [vod_categories]);


    const filterVideoVault = (categorieId) => {
        // window.scroll({
        //     top: ServicesRef.current.scrollIntoView(),
        //     behavior: 'smooth',
        // });
        setActive(categorieId);
        if(categorieId == "all"){
            setVodsItem(vods);
            return;
        }
        const updateVideoVault = vods && vods.filter((currentVods) => {
            return currentVods.category === categorieId;
        });
        setVodsItem(updateVideoVault);
    }

    const filterAlphabetical = (letter) => {
        let _letter = (letter === "All" ? null : letter);
        setCurrentChar(_letter);
        if(_letter === null){
            setVodsItem(vods);
        }else{
            const updateVodsItem = vods && vods.filter((vod) => {
                return vod?.user?.name.charAt(0).toUpperCase() === _letter || vod?.user?.last_name.charAt(0).toUpperCase() === _letter;
            });
            setVodsItem(updateVodsItem);
        }
    }

    const searchVideoVault = (query) => {
        if(query !== ''){
            const updatedList = vods && vods.filter((item) => {
                return item?.title.toLowerCase().includes(query.toLowerCase());
             });
             setVodsItem(updatedList);
        }else{
            setVodsItem(vods);
        }
    }

    const onSetVideoVimeoId = (videoVimeoId) => {
        setVideoVimeoId((prevState) => ({
            ...prevState, videoVimeoId: videoVimeoId, 
        }));
    }

    return (
        <>       
          <section className="video_vault_area mt-50">
                <div className="container">
                    <h1 className="main_heading mb-20">{translations?.vod?.title}</h1>
                   
                    {/* Vidoe Vault category list here */}
                    <div className='video_vault_header mb-50'>
                        {!isLoading ? vodsCat?.length > 0 ? (<VodCat
                            filterVideoVault={filterVideoVault} 
                            vodsCat={vodsCat}
                            isActive={isActive}
                            translations={translations}
                        />) : null : (<ShimmerTitle line={1} gap={10} variant="primary" />)}

                        <div className="searchbar_right">
                            <input 
                                type="text" 
                                placeholder="Search.." 
                                name="search" 
                                className="form-control" 
                                onChange={(e) => searchVideoVault(e?.target?.value)}
                            />
                            <button type="submit"><IconLoader isLoading={isLoading}><SearchIcon /></IconLoader></button>
                        </div>
                    </div>
                    {/* Vidoe Vault mobile view */}
                    <div className="video_vault_mobile">
                        <h1 className="mobile-main-heading-two"><span><i className="fa fa-angle-right"></i></span>{translations?.vod?.title}</h1>                  
                        {!isLoading ? vodsCat?.length > 0 ? (<VodMobileCat
                            filterVideoVault={filterVideoVault} 
                            vodsCat={vodsCat}
                            isActive={isActive}
                            translations={translations}
                            currentChar={currentChar}
                            onClick={filterAlphabetical}
                        />) : null : (<ShimmerTitle line={1} gap={10} variant="primary" />)}

                        <div class="searchbar_right mb-20">
                            <input 
                                type="text" 
                                placeholder="Search.." 
                                name="search" 
                                className="form-control" 
                                onChange={(e) => searchVideoVault(e?.target?.value)}
                            />
                            <button type="submit"><IconLoader isLoading={isLoading}><SearchIcon /></IconLoader></button>
                        </div>
                    </div>

                    {/* Vidoe Vault list here */}
                    {!isLoading ? vodsItem?.length > 0 ? (<div className="row" style={{position:"relative"}} ref={ServicesRef}>
                        <VodList vodsItem={vodsItem} onSetVideoVimeoId={onSetVideoVimeoId} translations={translations} event={event}/>
                    </div>)
                    : (<div className='col text-center'><span>{details?.no_vod_message ?? Constants.NO_DATA_AVAILABLE}</span></div>)
                    : (<ShimmerPostList postStyle="STYLE_FOUR" col={isMobile ? 1 : 4} row={2} gap={30} />) }
                </div>
            </section>
            {/* <!-- video_vault_area_close --> */}
        </>
    )
}

export default VideoVault;
