export function isNullOrEmpty(object) {
    if (!object || object === undefined) return true

    if (typeof object === 'string' || object instanceof String){
        return  object === ''
    }

    return false
}

export function isNotNullOrEmpty(object) {
    return !isNullOrEmpty(object)
}