import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import useSlug from '../../../../../../hooks/useSlug';
import { Image } from '../../../../../../UIComponets/Image';
import { FaHandsHelping } from "react-icons/fa";
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
import {URLContants} from "../../../../../../Model/URLContants";

const SponsorsUserMobile = ({peoples, sponsorDetails}) => {
    const slug = useSlug().absolutePath;
    const {
        state: {
            onlineUsers
        }
    } = useContext(defaultContext);
    const isOnline = (participantId) => onlineUsers && onlineUsers?.membersMap && onlineUsers?.membersMap[participantId] ? true : false;
    return (
        <>
        <div className="sd_attendess_area_mobile_view mt-20 mb-40">
        <h1 className="main_heading mb-20">ATTENDEES FROM THIS SPONSOR</h1>
        {peoples.map((people, index) => {
            return (
                    <div className="sd_attendess_box main-card">
                            <div className="attendess_profile_box">
                                <div className="img_box">
                                    <Image src={people?.user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'}  alt="sponsor pic" />
                                    {isOnline(people?.user?.id) ? <span className="dot"></span> : null}
                                </div>
                            </div>
                            <div className="attendess_info">
                                <h6>{people?.user?.name} {people?.user?.last_name}</h6>
                                <p><span>{people?.info?.company} </span>-{sponsorDetails?.name}</p>
                                {people?.info && people?.info?.length > 0 ? (
                                    <div className="social_btn">
                                        <a href={people?.info?.linkedin_link}  target={"_blank"}>
                                            <button type="button" className="btn"><img alt="image" src="/images/indeed_grey.png"/></button>
                                        </a>
                                        <a href={people?.info?.twitter_link}  target={"_blank"}>
                                            <button type="button" className="btn"><img alt="image" src="/images/twitter_grey.png"/></button>
                                        </a>
                                        <a href={people?.info?.facebook_link}  target={"_blank"}>
                                            <button type="button" className="btn"><img alt="image" src="/images/facebook_grey.png"/></button>
                                        </a>
                                    </div>
                                ) : ""}
                            </div>
                            <div className="attendess_connect_btn">
                                <Link to={slug +`${URLContants.NETWORK}/`+people?.user?.id}>
                                    <button type="button" className="btn btn_border_orange "><FaHandsHelping/> CONNECT</button>
                                </Link>
                            </div>
                    </div>)
                })} 
            </div>
        </>
    )
}

export default SponsorsUserMobile
