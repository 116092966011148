import React,{ useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { HorizontalScrollingMenu } from '../../../../../hooks/HorizontalScrollingMenu';

const SlideDecksCat = ({ filterSlideDecks, slideDecksCate, isActive, translations}) => {

    const {
        slide,
        scrollCheck,
        anim,
        anim2,
        state:{
            scrl,
            scrollX,
            scrolEnd
        }
    } = HorizontalScrollingMenu();

    const [scrollBarArrow , setscrollBarArrow] = useState(false);

    useEffect(() => {
        if(scrl.current){
            if (scrl.current.scrollWidth > scrl.current.clientWidth) {
                setscrollBarArrow(true);
            }
        }
    }, [scrollBarArrow]);

    return (
        <>
                <div className="session-category-list">
                    {scrollBarArrow ? scrollX !== 0 &&  (
                    <span className="prev cat-arrow-prev cat-arrow" onClick={() => slide(-50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)}>
                    <i className="fa fa-angle-left"></i>
                    </span>
                    ) : null}
                    <ul className="nav" ref={scrl} onScroll={scrollCheck}>
                        <li className={isActive === "all" ? "active" : ""} onClick={()=> filterSlideDecks("all")}>
                            <Link to={"#"}>{translations?.general?.all}</Link>
                        </li>
                        {slideDecksCate && slideDecksCate.map((category, index)=>{
                            return (
                                <li key={index} style={{color:category?.color}} className={isActive === category?.id ? "active" : ""} onClick={()=> filterSlideDecks(category?.id)}>
                                    <Link to={"#"}>{category?.name}</Link>
                                </li>
                            )
                        })}
                    </ul>
                    {scrollBarArrow ? !scrolEnd && (
                    <span className="cat-arrow-next next cat-arrow" onClick={() => slide(+50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)} >
                    <i className="fa fa-angle-right"></i>
                    </span>
                    ) : null}
                </div>
        </>
    )
}

export default SlideDecksCat
