import useSlug from "../useSlug";
import React, {useContext, useEffect, useState} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import {SettingOptions} from "../../classes/SettingOptions";
import {InterractionShimmer} from "../../templates/Event/Components/Pages/Live/Shimmer";
import {UserDetails} from "../../Util/Utils";

export function InteractionSettings(){
    const slug = useSlug()
    const {
        state: {
            channelMap,
            shared,
            eventsPageData: {
                options,
                details,
                translations,
                user_details
            },
            selectedChannelId,
            live_badges
        },
        getChannelDetails,
        setSelectedChannel,
        getChatMessages,
    } = useContext(defaultContext)

    const [settingOptions, setSettingOptions] = useState(options)
    const Settings = SettingsData(settingOptions, selectedChannelId)

    const userDetails =  UserDetails(user_details)
    useEffect(() => {
        if (options){
            setSettingOptions(options)
        }
    }, [options])

    useEffect(() => {
        const isMapNotIntialized = !channelMap;
        const detailNotExist = channelMap && !channelMap[selectedChannelId]
        if (selectedChannelId && (isMapNotIntialized || detailNotExist)){
            getChannelDetails(slug.path, selectedChannelId)
        }
    }, [selectedChannelId])

    // if (!selectedChannelId || !channelMap || !channelMap[selectedChannelId]) return <InterractionShimmer />
    const selectedChannel = channelMap && selectedChannelId ? channelMap[selectedChannelId] : null
    const chat = Settings.isChatForEach ? selectedChannel?.chat : shared?.chat
    const vote = Settings.isVoteForEach ? selectedChannel?.vote : shared?.vote
    const poll = Settings.isPollForEach ? selectedChannel?.poll : shared?.poll
    const rating = Settings.isRatingForEach ? selectedChannel?.rating : shared?.rating
    const qa = Settings.isQAForEach ? selectedChannel?.qa : shared?.qa

    let _active =  SettingOptions.CHAT
    if (Settings.isVoteEnabled) _active = SettingOptions.VOTE
    if (Settings.isRatingEnabled) _active = SettingOptions.RATING
    if (Settings.isPollEnabled) _active = SettingOptions.POLL
    if (Settings.isQnAEnabled) _active = SettingOptions.QA
    if (Settings.isChatEnabled)  _active = SettingOptions.CHAT


    const chatCount  = Settings.isChatForEach ?  (live_badges.chat[selectedChannelId] ?? 0) : (live_badges?.chat?.shared ?? 0) ;
    const voteCount  = Settings.isVoteForEach ?  (live_badges.vote[selectedChannelId] ?? 0) : (live_badges?.vote?.shared ?? 0) ;
    const pollCount  = Settings.isPollForEach ?  (live_badges.poll[selectedChannelId] ?? 0) : (live_badges?.poll?.shared ?? 0) ;
    const ratingCount  = Settings.isRatingForEach ?  (live_badges.rating[selectedChannelId] ?? 0) : (live_badges?.rating?.shared ?? 0) ;
    const qaCount  = Settings.isQAForEach ?  (live_badges.qa[selectedChannelId] ?? 0) : (live_badges?.qa?.shared ?? 0) ;

    const totalChatCount = userDetails?.isNotificationEnabled ? chatCount : 0;
    const totalVoteCount = userDetails?.isNotificationEnabled ? voteCount : 0;
    const totalPollCount = userDetails?.isNotificationEnabled ? pollCount : 0;
    const totalRatingCount = userDetails?.isNotificationEnabled ? ratingCount : 0;
    const totalQACount = userDetails?.isNotificationEnabled ? qaCount : 0;

    return {
        ...Settings,
        chat: chat,
        vote: vote,
        poll: poll,
        rating: rating,
        qa: qa,
        options:options,
        details:details,
        translations: translations,
        selectedChannel: selectedChannel,
        setSelectedChannel: setSelectedChannel,
        active: _active,
        selectedChannelId: selectedChannelId,

        live_badges: {
            chat: totalChatCount,
            poll: totalPollCount,
            vote: totalVoteCount,
            rating: totalRatingCount,
            qa: totalQACount
        },
        getChatMessages: (message_id, callback) => getChatMessages(slug, {message_id, event_channel_id: selectedChannelId}, callback),
    }
}

export const SettingsData = (options, activeId) => {
    const isChatForEach =  options?.includes(SettingOptions.CHAT_FOR_EACH_CHANNEL)
    const isQAForEach =  options?.includes(SettingOptions.QA_FOR_EACH_CHANNEL)
    const isVoteForEach =  options?.includes(SettingOptions.VOTE_FOR_EACH_CHANNEL)
    const isPollForEach =  options?.includes(SettingOptions.POLL_FOR_EACH_CHANNEL)
    const isRatingForEach =  options?.includes(SettingOptions.RATING_FOR_EACH_CHANNEL)

    const isChatEnableForChannel =  options?.includes(SettingOptions.CHAT_FOR_CHANNEL  + activeId)
    const isQAEnableForChannel =  options?.includes(SettingOptions.QA_FOR_CHANNEL  + activeId)
    const isVoteEnableForChannel =  options?.includes(SettingOptions.VOTE_FOR_CHANNEL  + activeId)
    const isPollEnableForChannel =  options?.includes(SettingOptions.POLL_FOR_CHANNEL  + activeId)
    const isRatingEnableForChannel =  options?.includes(SettingOptions.RATING_FOR_CHANNEL  + activeId)

    const isQnAEnabled =  options?.includes(SettingOptions.QA) && (isQAForEach ? isQAEnableForChannel : true)
    const isChatEnabled =  options?.includes(SettingOptions.CHAT) && (isChatForEach ? isChatEnableForChannel : true)
    const isRatingEnabled =  options?.includes(SettingOptions.RATING) && (isRatingForEach ? isRatingEnableForChannel : true)
    const isPollEnabled =  options?.includes(SettingOptions.POLL) && (isPollForEach ? isPollEnableForChannel : true)
    const isVoteEnabled =  options?.includes(SettingOptions.VOTE) && (isVoteForEach ? isVoteEnableForChannel : true)
    const isAllDisabled =  (isQnAEnabled ||  isChatEnabled || isRatingEnabled || isPollEnabled || isVoteEnabled)

    const isEmotiocnsEnabled =  options?.includes(SettingOptions.EMOTICONS)

    return {
        isQnAEnabled: isQnAEnabled,
        isChatEnabled: isChatEnabled,
        isRatingEnabled: isRatingEnabled,
        isPollEnabled: isPollEnabled,
        isVoteEnabled: isVoteEnabled,
        isAllDisabled: isAllDisabled,

        isChatForEach: isChatForEach,
        isQAForEach: isQAForEach,
        isVoteForEach: isVoteForEach,
        isPollForEach: isPollForEach,
        isRatingForEach: isRatingForEach,

        isChatEnableForChannel: isChatEnableForChannel,
        isQAEnableForChannel: isQAEnableForChannel,
        isVoteEnableForChannel: isVoteEnableForChannel,
        isPollEnableForChannel: isPollEnableForChannel,
        isRatingEnableForChannel: isRatingEnableForChannel,

        isEmotiocnsEnabled: isEmotiocnsEnabled,
    }
}