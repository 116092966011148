import {ShimmerPostList} from "react-shimmer-effects";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import useSlug from "../../../../../../hooks/useSlug";
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
import {AZPagination} from "../../../../../../UIComponets/AZPaggination";
import NetworkMenu from "./NetworkMenu";
import PeopleCategory from "./PeopleCategory";
import PeopleCard from "./PeopleCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Logger from "../../../../../../Util/Logger";
import { IconLoader } from '../../../../../../UIComponets/IconLoader';
import { SearchIcon } from '../../../../../../SVG/Icons';


export const Peoples = ({onClick}) => {
    const slug = useSlug().absolutePath;
    const [isLoading, setIsLoading] = useState(false);
    const [isShowOffline, setShowoffline] = useState(false)
    const searchRef = useRef(null);
    const {
        getNetworkPeople,
        getConnectionPeople,
        state:{
            usersDetail,
            onlineUsers
        }
    } = useContext(defaultContext);

    useEffect(()=> {
        fetchMore()
    },[]);

    useEffect(() => {
        if (usersDetail?.pagination?.current_page) {
            setCurrentPage(usersDetail?.pagination?.current_page)
        }
    }, [usersDetail?.pagination?.current_page])

    const [currentPage, setCurrentPage] = useState(0)
    const [currentChar, setCurrentChar] = useState(null)
    const [searchQuery, setSearchQuery] = useState('');
    const [selectCategory, setSelectCategory] = useState('');
    const [inputTimeout, setInputTimeout] = useState(null);
    const [catActive, setCatActive] = useState("all");

    const fetchMore = (currentPage, currentChar, search, category) => {
        setIsLoading(true);
        getNetworkPeople(slug, {current_page: currentPage, char: currentChar, search: search, category:category}, (res, error) => {
            if(res){
                setIsLoading(false);
            }
        });
    }

    const checkBoxClick = (checked) => {
        setCatActive('all');
        setShowoffline(checked);
    }

    const charFilter = (char) => {
        const _char = (char === "All" ? null : char)
        setCurrentChar(_char)
        searchRef.current.value = "";
        if (char !== currentChar && isShowOffline){
            setCurrentPage(1)
            fetchMore(1, _char, "", selectCategory);
        }else if (char !== currentChar) {
            setUsersData(
                onlineUsers?.members.filter(i => {
                        if (_char !== 'All' && _char){
                            return  i?.name?.toLowerCase().indexOf(_char.toLowerCase()) === 0
                        }
                        return true
                })
            )
        }
    }

    const hasMore = usersDetail?.pagination?.current_page < usersDetail?.pagination?.last_page && isShowOffline
    const Shimer = <ShimmerPostList postStyle="STYLE_FOUR" col={4} row={2} gap={20} />
    const users = (isShowOffline ? usersDetail?.users : onlineUsers?.members) ?? []
    const [usersData, setUsersData] = useState(users);
    const pagination = !isShowOffline ? onlineUsers?.members?.filter(m => m.name).map(m => m.name?.charAt(0)) : null
    const paginations = [...new Set(pagination)];
    paginations.sort();

    useEffect(() => {
        if(users){
            setUsersData(users);
        }
    },[JSON.stringify(users)]);

    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

    const handlePeopleCategory = (category) => {
        setCatActive(category);
        setSelectCategory(category);
        setCurrentPage(1);
        if(!isShowOffline){
            if(category === "all"){
                fetchMore(currentPage, currentChar, searchQuery,category);  
            }
            if(category === "participant"){
                setUsersData(
                    onlineUsers?.members.filter(i => {
                        return !i?.connection
                    })
                )
            }
            if(category === "connection"){
                setUsersData(
                    onlineUsers?.members.filter(i => {
                        return i?.connection
                    })
                )
            }
        }else{
            if(category === "all"){
                fetchMore(currentPage, currentChar, searchQuery,"");
           }else{
               if(isShowOffline){
                   fetchMore(currentPage, currentChar, searchQuery, category);
               }
           }
        }
    }

    const searchNetworkUser = (query) => {
        setSearchQuery(query);
        if (inputTimeout) clearTimeout(inputTimeout)
        setInputTimeout(
            setTimeout(() => {
                if (query !== '' && isShowOffline) {
                    fetchMore(currentPage, "", query);
                }else if(query !== ''){
                    const findUsers = users && users.filter(item => {
                        const fullName = `${item?.name} ${item?.last_name}`.toLowerCase()
                        const _query = query?.trim().toLowerCase()
                        if (_query.length > 0){
                            return fullName.includes(_query);
                        }else {
                            return true;
                        }
                    });
                    setUsersData(findUsers)
                }
                else{
                    if(isShowOffline){
                        fetchMore(1, currentChar, query);
                    }else{                    
                      setUsersData(users)
                    }
                }
            }, 1000)
        )
    }

    return  <>
        <div className="col-md-12 col-lg-10 col-xl-11">
            <div className="network_left_area">
                {/* People category tabs */}
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link ${catActive == "all" && "active"}`} data-toggle="tab" href="#ALL" onClick={() => handlePeopleCategory("all")} role="tab">ALL</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${catActive == "participant" && "active"}`} data-toggle="tab" href="#PARTICIPANTS" onClick={() => handlePeopleCategory("participant")} role="tab">PARTICIPANTS</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${catActive == "connection" && "active"}`} data-toggle="tab" href="#CONNECTIONS" onClick={() => handlePeopleCategory("connection")} role="tab">CONNECTIONS</a>
                    </li>
                </ul>
                {/* <PeopleCategory/> */}
                {/* Tab panes */}
                <div className="tab-content mt-30">
                    <div className="tab-pane active" id="ALL" role="tabpanel">
                        <div className="all_network_box">
                            <div className="network_search_inner">
                                <div className="row network_header_search align-items-center">
                                    <div className="col-12 col-md-12 col-lg-6 col-xl-4 mobile_view">
                                        <div className="searchbar_right">
                                            <input type="text" placeholder="Search.." name="search" className="form-control" onChange={(e) => searchNetworkUser(e.target.value)}/>
                                            <button type="submit">
                                                <IconLoader isLoading={isLoading}><SearchIcon/></IconLoader>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-7 col-md-7 col-lg-6 col-xl-4">
                                        <div className="sponsors_searchbar_pagination deshtop_view">
                                            <AZPagination  pagination={paginations?.length > 0 ? paginations : null} currentChar={currentChar} onClick={(char) => charFilter(char)}/>
                                        </div>

                                        <div className="sponsors_searchbar_pagination mobile_view">
                                            <AZPagination  pagination={paginations?.length > 0 ? paginations : null}  currentChar={currentChar} onClick={(char) => charFilter(char)}/>
                                        </div>
                                    </div>
                                    <div className="col-5 col-md-5 col-lg-6 col-xl-4">
                                        <div className="switch_toggle">
                                            <label className="switch" htmlFor="checkbox">
                                                <input type="checkbox" id="checkbox" defaultChecked={isShowOffline} onClick={e => checkBoxClick(e.target.checked)}/>
                                                <div className="slider round"></div>
                                            </label>
                                            <p>Show offline</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 col-xl-4 dashtop_view">
                                        <div className="searchbar_right">
                                            <input type="text" placeholder="Search.." ref={searchRef} name="search" className="form-control" onChange={(e) => searchNetworkUser(e.target.value)}/>
                                            <button type="submit">
                                                <IconLoader isLoading={isLoading}><SearchIcon/></IconLoader>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Start row card here  */}

                                <InfiniteScroll
                                    dataLength={users?.length ?? 0}
                                    next={() => fetchMore(currentPage + 1, currentChar, searchQuery)}
                                    hasMore={hasMore && searchQuery.length === 0}
                                    loader={Shimer}
                                    // height={500}
                                    endMessage={<></>}
                                >
                                <div className="row mt-60">
                                    {!isLoading && usersData ? (<PeopleCard onClick={onClick} isShowOffline={isShowOffline} people={usersData}/>) : (Shimer) }
                                </div>
                                </InfiniteScroll>
                                {/* End row card here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}