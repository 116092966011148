import React,{useContext, useEffect, useState} from 'react';
import useSlug from '../../../../../../hooks/useSlug';
import ProfileSessionMonth from './ProfileSessionMonth';
import ProfileSessionList from './ProfileSessionList';
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import ProfileSessionMobile from './ProfileSessionMobile';
import { ModalIframe } from '../../../../../../UIComponets/Modal/ModalIframe';
import _ from "lodash";

export const InitialSessionMap = () => {
    return {keys: [], map: {}}
}
const ProfileSession = () => {
    const slug  = useSlug().absolutePath;
    const {
        getSessionsData,
        state:{ 
            eventsAllPagesData: {
                sessions,
            },
           eventsPageData: { 
              translations,
              sessions_categories,
              registered_sessions,
           },
        }
     } = useContext(defaultContext);


     const [isLoading, setIsLoading] = useState(false);
     const myRegistered = sessions && sessions.filter(session => registered_sessions.includes(session.id));
     const [sessionMaps, setSessionMap] = useState(InitialSessionMap);
     const [selectedDate, setSelectedDate] = useState(null);
     const [allSession, setAllSession] = useState(null);
     const [filteredSessions, setFilteredSession] = useState(0);
     const [replay, setReplay] = useState({});


    // Rreact Lifecycle Methods
    useEffect(()=> {
        setIsLoading(true);
        getSessionsData(slug,(res, error) => {
            if(res?.data.status){
                setIsLoading(false);
            }
        });
    }, []);
    
 

    useEffect(() => {
        if (myRegistered) {
            const map = myRegistered?.reduce((prev, next) => {
                const obj = {...prev}
                const key = next.date
                if (next.date){
                    if (!obj.map[key]){
                        obj.map[key] = []
                        obj.keys.push(key)
                    }
                    obj.map[next.date].push(next)
                }
                return obj
            }, InitialSessionMap()) ?? InitialSessionMap()
            setSessionMap(map)
            if (!selectedDate || !map?.map[selectedDate]){
                setSelectedDate(map?.keys?.first())
            }
            setAllSession(myRegistered)
        }
    }, [JSON.stringify(myRegistered)]);

    useEffect(() => {
        if (allSession) {
            const _sessions = allSession?.filter(s => {
                if (selectedDate ===  'favorite') {
                    return s.isWishlisted
                }else if (selectedDate) {
                    return s.date == selectedDate
                }
                return true;
            })
            setFilteredSession(_sessions)
        }
    }, [selectedDate, JSON.stringify(allSession)]);

    const onCloseModal = (close) => {
        // console.log(close);
     }  

    return (
        <>
            <div className="col-md-12 col-lg-12 col-xl-11">
                {/* Mobile view session */}
                <div className="mobile_session_inner_box">
                    {!isLoading ? myRegistered?.length > 0  ?  (<ProfileSessionMobile
                        registerSession={sessionMaps?.map}
                        sessions_categories={sessions_categories}
                        setReplay={setReplay}
                    />) : (<div class="col text-center"><span>No data available.</span></div>) : (<ShimmerCategoryItem hasImage imageType="thumbnail" imageWidth={50}imageHeight={50} title/>)} 
                </div>
                {/* Desktop view */}
                <div className="session_inner_box main-card">
                    {myRegistered?.length > 0  ? !isLoading && myRegistered ? (
                        <ProfileSessionMonth 
                            keys={sessionMaps?.keys}
                            onClick={setSelectedDate}
                            active={selectedDate}
                        />) : "" : ""
                    }
                    <div className="session_inner_right">
                        {/* My Sessions List */}
                        {!isLoading ?  myRegistered?.length > 0  ? (    
                            <ProfileSessionList registerSession={filteredSessions} sessions_categories={sessions_categories} setReplay={setReplay}/>)
                             : (<div class="col text-center"><span>No data available.</span></div>) : (<ShimmerCategoryItem hasImage imageType="thumbnail" imageWidth={40} imageHeight={40} title/> )} 
                        {/* <div className="shopping_cart_btn">
                            <button type="button" className="btn btn_border_orange" data-toggle="modal" data-target="#shopping-card">View shoppingcart</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <ModalIframe onClose={() => onCloseModal(null)} replayPlayer={replay}  size='lg' id={"replay-btn-modal"} />
        </>
    )
}

export default ProfileSession
