import React,{useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { MdLogin} from "react-icons/md";
import { HashLink } from "react-router-hash-link";
import {FaAngleDown, FaAngleUp, FaQuestion} from "react-icons/fa";
import useSlug from "../../../../hooks/useSlug";
import { Image } from '../../../../UIComponets/Image';
import { OnePageModalWrapper } from "../../../../UIComponets/Modal/OnePageModalWrapper";
import Profile from "../../../Event/Components/Pages/Profile/Profile";
import {ShimmerCircularImage,ShimmerTitle} from "react-shimmer-effects";
import { EventApi } from "../../../../hooks/shared/EventApi";
import { NotifictionIcon } from "../../../../SVG/Icons";
import { Langauges } from "../../../../hooks/shared/Langauges";
import { SecurityApi } from '../../../../hooks/shared/SecurityApi';

export const Header = () => {
    const slug = useSlug().absolutePath;
    const {
        state:{ 
            languages,
            details,
            event,
            user
        }
     } = EventApi();

    const {
        security
    } = SecurityApi();

    const {
        changeLangauge,
        langauge
    } = Langauges();

    const isRegistration = () => security === "registration";
    const isInvitation = () => security === "invitation";
    const [currentLanguage, setCurrentLanguage] = useState(langauge);

    const handleChangeLangauge = (lang) => {
        setCurrentLanguage(lang);
        changeLangauge(lang);
    }
    
    return (
        <>
            <div className="header_area">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-lg-8">
                            <div className="header_logo_box">
                                <HashLink smooth to="#home">
                                    {details ? 
                                    (<Image src={details?.img_logo_desktop} placeholderSrc={"/images/logo-placeholder.png"}  alt="Desktop Logo"/>)
                                    : (<ShimmerCircularImage size={100} />) }
                                </HashLink>
                                {details ? <p>{event?.title}</p> :<ShimmerTitle line={1} />}
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="header_right_box">
                                <ul className="nav">
                                    {/***
                                        <li className="nav-item">
                                            <a className="nav-link" href="#"><NotifictionIcon/></a>
                                        </li>
                                    **/}
                                    {(isRegistration() || isInvitation()) && (<li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" type="button" data-toggle="dropdown">
                                            {user ? 
                                                (<Image src={user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'} alt="avatar" className="user-avatar"/>)
                                                : (<ShimmerCircularImage size={70} />)
                                            }
                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="user_languages">
                                                {languages && Object.keys(languages).length > 1 ? 
                                                <>
                                                    <h4>Languages</h4>
                                                    <ul>
                                                        {languages && languages ? Object.keys(languages).map((lang, index) => {
                                                            return (
                                                            <li className={lang === currentLanguage ? "active" : ""} key={index}>
                                                                <NavLink to={"#"} onClick={() => handleChangeLangauge(lang)}>{lang.toUpperCase()}</NavLink>
                                                            </li>
                                                            )
                                                        }) : null}
                                                    </ul>
                                                    </>
                                                : null }
                                            </div>
                                            <div className="user_profile">
                                                <ul>
                                                    <li>
                                                        <a href='#' data-toggle="modal" data-target="#profile_details">
                                                            <AiOutlineUser/>
                                                            <span>Profile</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <Link to={"#"} data-toggle="modal" data-target="#logout">
                                                            <MdLogin/>
                                                            <span>Logout</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(isRegistration() || isInvitation()) && (<OnePageModalWrapper className={"profile_details_model"} id={"profile_details"}>
                <Profile/>
            </OnePageModalWrapper>)} 
           </>
    )
}