import React, {useState} from "react";
import {AppointmentStatus} from "../../../../../Model/AppointmentStatus";
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import {RightIcon} from "../../../../../UIComponets/Icons/RightIcon";
import {ScheduleIcon} from "../../../../../UIComponets/Icons/ScheduleIcon";
import {CancelIcon} from "../../../../../UIComponets/Icons/CancelIcon";
import {UserApi} from "../../../../../hooks/shared/UserApi";
import {Appointment, DateUtils, DayGreetingFormate, UserDTO} from "../../../../../Util/Utils";
import {AppointmentsApi} from "../../../../../hooks/shared/AppointmentsApi";
import { Image } from "../../../../../UIComponets/Image";
import moment from "moment";
import {DateFormates} from "../../../../../classes/DateFormates";
import {SessionApi} from "../../../../../hooks/shared/SessionApi";
import {SessionApiV2} from "../../../../../hooks/shared/SessionApi2";

export const PendingAppointment = (props) => {

    const acceptAppointment = props.acceptAppointment
    const cancelAppointment = props.cancelAppointment
    const updateAppointment = props.updateAppointment

    const {
        getZoneTime,
        getZoneDateTime
    } = SessionApiV2()

    const user = props.currentUser;
    const appointment = Appointment(props)
    const [loading, setLoading] = useState({
        type: null,
        isLoading: false
    })
    const isLoading = (type) => loading.type === type && loading.isLoading
    const accept = (appointment) => {
        setLoading({type: AppointmentStatus.ACCEPTED, isLoading: true})
        acceptAppointment(appointment, (response, error) => {
            setLoading({type: AppointmentStatus.ACCEPTED, isLoading: false})
        })

    }
    const reschedule = (appointment) => {
        props?.rescheduleAppointmentCallback(appointment)
    }
    const cancel = (appointment) => {
        setLoading({type: AppointmentStatus.DECLINE, isLoading: true})
        cancelAppointment(appointment, (response, error) => {
            setLoading({type: AppointmentStatus.DECLINE, isLoading: true})
        })
    }
    const particiapnt = user?.id === appointment.getHostUserId() ? appointment.getParticiapnt() : appointment.getHost()
    const userDTO = UserDTO(particiapnt)


    const getTime = (time) => {
        return  getZoneTime(props?.activeTime, time)
    };

    // const getTime = (time) => {
    //     return  DateUtils.getAppointmentTime(props?.activeTime, time, props?.event?.timezone)
    // };

    const getDateTime = (time) => {
        return  DateUtils.getAppointmentDateTime(props?.activeTime, time, props?.event?.timezone)
    };

    const _date = getDateTime(props?.date)
    return <div className="accepted_box mb-20">
        <div className="accepted_box_row">

            <div className="image_box">
                <Image src={userDTO.getAvatar()} placeholderSrc={"/images/placeholder_square.jpg"} alt={"particiapnt image"}/>
            </div>
            
            <div className="accepted_box_content">
                <div className="accepted_datedescripion">
                    <div className="date">
                        <p>{getTime(props?.date)} <br/> {_date.format("DD") } {_date.format("MMM")}</p>
                    </div>
                    <div className="description">
                        <h4>{userDTO.getFullName()}</h4>
                        <p><span>{userDTO.getCompany()}</span> {userDTO.getTitle()}</p>

                    </div>
                </div>
                <div className="accepted_btn panding_btn">
                    {user?.id !== appointment.getHostUserId() &&  appointment.getStatus() !== AppointmentStatus.ACCEPTED && <IconLoader isLoading={isLoading(AppointmentStatus.ACCEPTED)} >
                        <RightIcon disabled={loading?.isLoading} onClick={() => accept(appointment.getRequestPayload())} />
                    </IconLoader>}
                    {appointment?.getCanModify() ? <IconLoader isLoading={isLoading(AppointmentStatus.PENDING)} >
                        <ScheduleIcon disabled={loading?.isLoading} onClick={() => reschedule(props)}/>
                    </IconLoader> : null }
                    <IconLoader isLoading={isLoading(AppointmentStatus.DECLINE)} >
                        <CancelIcon  disabled={loading?.isLoading} onClick={() => cancel(appointment.getRequestPayload())}/>
                    </IconLoader>
                </div>
            </div>

        </div>
    </div>
}