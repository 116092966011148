import React from "react";

export const RightIcon = ({onClick, disabled}) => {
    return <button disabled={disabled} type="button" className="btn" onClick={onClick}>
        <svg id="FORM_-_Checkbox_active" data-name="FORM - Checkbox active" xmlns="http://www.w3.org/2000/svg" width="37.183" height="37.183" viewBox="0 0 37.183 37.183">
            <g id="FORM_-_checkbox_active-2" data-name="FORM - checkbox active">
                <g id="FORM_-_Checkbox_Inactive" data-name="FORM - Checkbox Inactive">
                    <g id="Rectangle_1372" data-name="Rectangle 1372" fill="#fff" stroke="#49c168" strokeWidth="3">
                        <rect width="37.183" height="37.183" rx="10" stroke="none" />
                        <rect x="1.5" y="1.5" width="34.183" height="34.183" rx="8.5" fill="none" />
                    </g>
                </g>
                <g id="Icon_Check" data-name="Icon Check" transform="translate(6.126 9.296)">
                    <path
                        id="Icon_awesome-check"
                        data-name="Icon awesome-check"
                        d="M8.468,22.8l-8.1-8.1a1.247,1.247,0,0,1,0-1.763l1.763-1.763a1.247,1.247,0,0,1,1.763,0l5.458,5.458L21.041,4.942a1.247,1.247,0,0,1,1.763,0l1.763,1.763a1.247,1.247,0,0,1,0,1.763L10.231,22.8A1.247,1.247,0,0,1,8.468,22.8Z"
                        transform="translate(0 -4.577)"
                        fill="#49c168"
                    />
                </g>
            </g>
        </svg>
    </button>
}