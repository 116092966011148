import React from 'react'
import {SearchIcon} from "../../../../../../SVG/Icons";

const BreakoutRoomMobile = () => {
    return (
        <>
            <div className="video_vault_mobile">
                <ul className="video_vault_nav mobile_video_vault mb-20">
                    <li className="active">
                    <a href="#!">All</a>
                    </li>
                    <li>
                    <a href="#!">RELAX</a>
                    </li>
                    <li>
                    <a href="#!">NETWORK</a>
                    </li>
                    <li>
                    <a href="#!">BUSINESS</a>
                    </li>
                </ul>
                <div className="searchbar_right mb-20">
                    <input type="text" placeholder="Search.." name="search" className="form-control" />
                    <button type="submit">
                    <SearchIcon />
                    </button>
                </div>
                <div className="sponsors_searchbar_pagination mb-40">
                    <ul className="pagination justify-content-center">
                    <li className="page-item"><a className="page-link" href="#">ALL</a></li>
                    <li className="page-item"><a className="page-link" href="#">A</a></li>
                    <li className="page-item"><a className="page-link" href="#">B</a></li>
                    <li className="page-item"><a className="page-link" href="#">C</a></li>
                    <li className="page-item"><a className="page-link" href="#">...</a></li>
                    <li className="page-item"><a className="page-link" href="#">X</a></li>
                    <li className="page-item"><a className="page-link" href="#">Y</a></li>
                    <li className="page-item"><a className="page-link" href="#">Z</a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default BreakoutRoomMobile
