import React,{useContext} from "react";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import Marquee from "react-fast-marquee";

export  const Ticker = ({tickers, translations}) => {
 if (!tickers?.length){
     return  <></>
 }

    return <section className="happing_area mt-50">
        <div className="ticker-wrap">
            <div className="ticker-heading">
                {translations?.highlight?.title}
            </div>
            <div className="ticker">
                <Marquee speed={150} gradient={false} direction='left' pauseOnHover={true} pauseOnClick={true}>
                    {tickers && tickers?.map((ticker, index) => {
                        return(<span key={ticker?.id} className="ticker__item"> {ticker?.name}</span>);
                    })}
                </Marquee>
            </div>
        </div>
      
    </section>
}