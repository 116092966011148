import React, {useContext} from 'react';
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import Marquee from "react-fast-marquee";


const HappingTickerList = ({tickers}) => {

    const {
        state:{ 
           eventsPageData: { 
              translations,
           },
        }
     } = useContext(defaultContext);

    return (
        <>
            <div className="ticker-wrap">
                <div className="ticker-heading">{translations?.highlight?.title}</div>
                <div className="ticker">
                    <Marquee speed={150} gradient={false} direction='left' pauseOnHover={true} pauseOnClick={true}>
                        {tickers && tickers?.map((ticker, index) => {
                            return(<span key={index} className="ticker__item"> {ticker?.name} </span>);
                        })}
                    </Marquee>
                </div>
            </div>
        </>
    )
}

export default HappingTickerList
