import React, {useContext, useState} from 'react';
import { FaRegFile } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Image } from '../../../../UIComponets/Image';
import SessionFilesModel from '../../../Event/Components/Pages/ActiveSessions/SessionFilesModel';
import {LinkIcon, WifiIcon, FavoriteIcon} from '../../../../SVG/Icons';
import {Context as defaultContext} from "../../../../context/DefaultSectionContext";
import {ResponseWrapper} from "../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../hooks/showToast";
import {Error} from "../../../../Util/Utils";
import {IconLoader} from "../../../../UIComponets/IconLoader";
import {useToasts} from "react-toast-notifications";
import useSlug from "../../../../hooks/useSlug";
import {SessionModel} from "../../../../Model/SessionModel";
import { SessionStatus } from '../../../../Model/SesstionStatus';
import ReactHtmlParser from 'react-html-parser';
import { Translations } from '../../../../hooks/shared/Translations';
import {SessionApiV2} from "../../../../hooks/shared/SessionApi2";
import { URLContants } from '../../../../Model/URLContants';
import { SecurityApi } from '../../../../hooks/shared/SecurityApi';
import {useTemplate} from "../../../../hooks/shared/useTemplate";
import {Template, TemplateDetails} from "../../../../Model/TemplateDetails";

const LoadingContainer = (session_id, isLoading) => (prev) =>{
    return {
        ...prev,
        [session_id]: isLoading
    }
}
const OnePageSession = ({ sessionItem, sessionCat, registered_sessions, timezone, isActiveEu, setReplay, event}) => {

    const [showfiles, setShowfiles] = useState(false);
    const {
        getZoneTime,
        getZoneDateTime,
        setFavoriteData,
        fetchSessionSpeakers,
        state:{
            details,
            options
        }
    } = SessionApiV2();

    const {
        security
    } = SecurityApi();

    const {
        translations
    } = Translations();

    const sessionCategoryMap = sessionCat && sessionCat?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <h1 style={{background:current?.color}} className="mb-40">{current?.name.toUpperCase()}</h1>
        }
    }, {});

    const isRegistration = () => security === "registration";
    const isInvitation = () => security === "invitation";
    const [loadingContainer, setLoadingContainer] = useState({})
    const [favLoadingContainer, setFavLoadingContainer] = useState({})
    const {
        enrollSession,
        unEnrollSession
    } = useContext(defaultContext);
    const { addToast } = useToasts();
    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;
    const enrollSessionRequest = (session_id) => {

        setLoadingContainer(LoadingContainer(session_id, true))
        enrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false))
        })
    }

    const unEnrollSessionRequest = (session_id) => {
        setLoadingContainer(LoadingContainer(session_id, true))
        unEnrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false));
        })
    }

    const isLoading = (id) => loadingContainer[id]
    const isFavLoading = (id) => favLoadingContainer[id]

    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id);
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleFavoirte = (id) => {
        setFavLoadingContainer(LoadingContainer(id, true))
        setFavoriteData({id}, (response, error) => {
            if (response?.data?.status) {

            }
            setFavLoadingContainer(LoadingContainer(id, false))
        });
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }
    const [fetchLoadingContainer, setFetchLoadingContainer] = useState({})

    const fetchSessionDetails = (item) => {
        if (item.people === null && fetchLoadingContainer[item?.id] === undefined){
            setFetchLoadingContainer((prev) => {
                return {
                    ...prev,
                    [item?.id]: true
                }
            })
            fetchSessionSpeakers(item.id, (response, error) => {
                setFetchLoadingContainer((prev) => {
                    return {
                        ...prev,
                        [item?.id]: false
                    }
                })
            })
        }
    }

    const {
        state: {
            layout
        }
    } = useTemplate()

    const templateDetails = TemplateDetails(layout)
    const isOnePageTemplate = templateDetails.getTemplate() === Template.ONEPAGE;

  return (
    <>
        {sessionItem && sessionItem?.map(i => SessionModel(i)).map((session, index)=> {
        return (
            <div className="accordion destop_ed_time" id={`accordionExample_${session?.id}`} key={index} onClick={() => fetchSessionDetails(session)}>
                <div className="live_section_item_box mb-20">
                    <div className="live_section_info">
                        <h4 className="date">
                            {getZoneTime(isActiveEu, session?.start)}-{getZoneTime(isActiveEu, session?.end)}
                        </h4>
                        <div className="info_box">
                            <button class="btn p-0 collapsed collapse_btn" data-toggle="collapse" data-target={`#collapsethree_${session?.id}`}  aria-expanded="true" aria-controls="collapsethree" title={session?.title}>
                             <span class="coll_icon"> 
                                   <i class="fas fa-angle-down"></i> 
                                </span>
                            <p className="description collapsed" >
                                {session?.title}
                            </p>
                            </button>
                            <div className="file_btn" type="button">
                            {session?.files && session?.files.length > 0 ? (<Link to={"#"} title="Files" className='session_link ml1' onClick={()=>handleFilePopup(session?.id)}><FaRegFile></FaRegFile></Link>) : ""}
                            <SessionFilesModel files={session?.files} id={session?.id} close={handleClose} event={event}/>
                            {session?.meta?.external_link ? (<a className='btn session_link' href={session?.meta?.external_link} target={session?.target} title="Link"><LinkIcon/></a>) : ""}
                            </div>
                        </div>
                    </div>
                    {!session?.meta?.hide_action_button && (<div className="live_section_btn">
                        {session.getStatus() === SessionStatus.JOIN && (<a href={session?.link} target={session?.target}><button type="button" className="btn btn_theme1_white join">JOIN</button></a>)}
                        {session.getStatus() === SessionStatus.REGISTERED && (<button type="button" className="btn btn_theme1_white registered" onClick={() => unEnrollSessionRequest(session?.id)}><IconLoader isLoading={isLoading(session?.id)}>REGISTERED</IconLoader></button>)}
                        {session.getStatus() === SessionStatus.REGISTER && (<button disabled={isLoading(session?.id)} type="button" className="btn btn_theme1_white register" onClick={() => enrollSessionRequest(session?.id)}> <IconLoader isLoading={isLoading(session?.id)}> REGISTER </IconLoader></button>)}
                        {session.getStatus() === SessionStatus.ENDED && (<button type="button" className="btn btn_theme1_white ended">ENDED</button>)}
                        {session.getStatus() === SessionStatus.REPLAY && (<button type="button" data-toggle="modal" data-target="#replay-btn-modal"  className="btn btn_theme1_white replay" onClick={() => setReplay({meta:session?.meta})}>REPLAY</button>)}
                    </div>)}
                    {(isRegistration() || isInvitation()) && options && options.includes('show_session_favourites') && (<div className="session_accordion_fav">
                       <button type="button" className="btn" onClick={() => handleFavoirte(session?.id)}>
                            <IconLoader isLoading={favLoadingContainer[session?.id]}>
                                <FavoriteIcon color={session?.isWishlisted ? details?.primary_color : "none"} width="30" height="27.525" stroke={details?.primary_color}/>
                            </IconLoader>
                        </button>
                        {session?.is_live === 1 && (<div className="session_accordion_wifi"><WifiIcon /></div>)}
                    </div>)}
                </div>
                <div id={`collapsethree_${session?.id}`} className="collapse" aria-labelledby="headingthree" data-parent={`#accordionExample_${session?.id}`}>
                    <div className="session_clientinfo_box mb-30">
                        <div className="row">
                            <div className={`${session?.people?.length > 0 ? "col-md-12 col-lg-12 col-xl-8 d-flex" : "col-md-12 col-lg-12 col-xl-12 d-flex"}`}>
                            <div className="emerging_sectors">
                                {sessionCategoryMap[session?.category_id]}
                                <p>{ReactHtmlParser(session?.detail ?? '')}</p>
                            </div>
                            </div>
                           {session?.people?.length > 0 && (<div className="col-md-12 col-lg-12 col-xl-4 d-flex">
                                <div className="session_speaker_box">
                                    <h1 className="main-heading mb-30">Speakers</h1>
                                    {fetchLoadingContainer[session?.id] === true ? <p className={"text-center w-100"}>Fetching Speakers <IconLoader isLoading={true}/></p> : session?.people?.map((person , index) => {
                                        return (
                                            <div className="client_info mb-30" key={index}>
                                                <div className="client_info_img">
                                                    <Image src={person?.user?.avtar} placeholderSrc={"/images/placeholder_square.jpg"} alt={"people pic"}/>
                                                </div>
                                                <div className="client_info_content">
                                                    <h4>
                                                        <Link to={slug.absolutePath+`?search=${person?.user?.last_name}`}> {person?.user?.name} {person?.user?.last_name}</Link>
                                                    </h4>
                                                    <p>{person?.user?.title}</p>
                                                    <h6>{person?.user?.company}</h6>
                                                </div>
                                            </div>)
                                    })}
                                    {/* <div className="session_navigate">
                                        <h1 className="main-heading mb-20">Navigate</h1>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29436.842455096437!2d75.8874112!3d22.7429071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1634991919399!5m2!1sen!2sin"
                                            width="600"
                                            height="450"
                                            style={{border:"0"}}
                                            allowfullscreen=""
                                            loading="lazy"
                                            ></iframe>
                                    </div> */}
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            )
        })}
    </>
  )
}

export default OnePageSession