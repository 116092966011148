
import {useState, useEffect, useContext} from 'react';
import { Context as defaultContext } from "../../context/DefaultSectionContext";
import useSlug from '../../hooks/useSlug';

export const FaqApi = () => {
    const slug = useSlug().absolutePath;
    const {
        getFaqDetails
    } = useContext(defaultContext);

    const [isLoading, setIsLoading] = useState(false);
    const [faq, setFaq] = useState(null);
    const [faqCat, setFaqCat] = useState(null);
    const [faqList, setFaqList] = useState(faq);
    const [faqCategorys, setFaqCategorys] = useState(faqCat);
    const [currentChar, setCurrentChar] = useState('All');
    const [isActive, setActive] = useState("all");

    useEffect(() => {
       setIsLoading(true);
       getFaqDetails(slug , (res, error) => {  
          setIsLoading(false);
          setFaq(res?.data?.data?.faqs);
          setFaqList(res?.data?.data?.faqs);
          setFaqCat(res?.data?.data?.faq_categories);
          setFaqCategorys(res?.data?.data?.faq_categories);
       })
    },[]);

    const charFilter = (letter) => {
        let char = (letter === "All" ? null : letter);
        setCurrentChar(char);
        if(char === null){
           setFaqList(faq);
        }else {
           const updateFaq = faq.filter((word) => {
              return word?.question.charAt(0).toUpperCase() === char;
           });
           setFaqList(updateFaq);
        }
     }

    const filterFaqCategory = (categorieId) => {
       // inputRef.current.value = "";
        setActive(categorieId);
        if(categorieId == "all"){
          setFaqList(faq);
          return
        }
        const updatefaqCat = faq && faq.filter((currentFaq) => {
              return currentFaq.category === categorieId;
        });
        setFaqList(updatefaqCat);
    }

    const searchFaq = (searchValue) => {
      const filteredFaq = faq.filter((item) => {
          return  item?.question.toLowerCase().includes(searchValue.toLowerCase());
      })
      setFaqList(filteredFaq);
    }
 
    return {
        searchFaq,
        filterFaqCategory,
        charFilter,
        state:{
            faqList:faqList,
            faqCategorys:faqCategorys,
            isActive:isActive,
            isLoading:isLoading,
            currentChar:currentChar
        } 
    }
}