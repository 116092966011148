import React from "react";

export const FileIcon = () => {
    return  <svg xmlns="http://www.w3.org/2000/svg" width="16.592" height="14.311" viewBox="0 0 16.592 14.311">
        <g id="Group_1885" data-name="Group 1885" transform="translate(-328.692 -552.846)">
            <g id="Group_1884" data-name="Group 1884">
                <g id="Group_1883" data-name="Group 1883">
                    <g id="Group_1654" data-name="Group 1654" transform="translate(-824.486 -2439.211)">
                        <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(1168.794 2992.057) rotate(90)">
                            <path
                                id="Icon_ionic-ios-arrow-down"
                                data-name="Icon ionic-ios-arrow-down"
                                d="M7.311,5.94,12.837.312a1.028,1.028,0,0,1,1.475,0,1.085,1.085,0,0,1,0,1.505L8.051,8.193a1.03,1.03,0,0,1-1.44.031L.3,1.822A1.082,1.082,0,0,1,.3.317a1.028,1.028,0,0,1,1.475,0Z"
                                transform="translate(0 14.616) rotate(-90)"
                                fill="#141414"
                            />
                        </g>
                        <path
                            id="Path_1415"
                            data-name="Path 1415"
                            d="M5191.943,2990.357h14.592"
                            transform="translate(-4037.764 15.01)"
                            fill="none"
                            stroke="#141414"
                            stroke-linecap="round"
                            stroke-width="2"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
}