import {SessionStatus} from "./SesstionStatus";
import moment from "moment";
import {Constants} from "../classes/Constants";
import Logger from "../Util/Logger";

export const SessionModel = (props) => {
    let isRegistered = props.isRegistered ?? false;
    const _id =  props?.id;
    const _title =  props?.title;
    const _link =  props?.link;
    const _file =  props?.file;
    const _event_id =  props?.event_id;
    const _time =  props?.time;
    const _date =  props?.date;
    const _active =  props?.active;
    const _source_id =  props?.source_id;
    const _cvent_id =  props?.cvent_id;
    const _end =  props?.end;
    const _start =  props?.start;
    const _target =  props?.target;
    const _timezone =  props?.timezone;
    const _category =  props?.category;
    const _is_live =  props?.is_live;
    const _position =  props?.position;
    const _locale =  props?.locale;
    const _detail =  props?.detail;
    const _category_id =  props?.category_id;
    const _files =  props?.files;
    const _meta =  props?.meta;
    const _people =  props?.people;
    const _isWishlisted =  props?.isWishlisted;


    const setRegistered = (status) => {
        isRegistered = status
    }

    const setEnded = (endT) => {
        const today = moment().tz(window.eventTimezone ?? Constants.DEFAULT_TIMEZONE).utcOffset(0, true)
        let end = moment(endT, Constants.SESSION_DATE_FORMATE).utcOffset(0, true)
        // Logger.verbose({end, today, _title})
        if(today > end) {
            return true;
        }
    }

    const getStatus = () => {
        let status = SessionStatus.REGISTER;
        if (isRegistered)
        {
            status = SessionStatus.REGISTERED;
        }
        if (_is_live == 1 || _is_live == true)
        {
            if(isRegistered){
                status = SessionStatus.JOIN;
            }
        }
        if(setEnded(_end))
        {
            if(_meta?.replay_session){
                status = SessionStatus.REPLAY;
            }else{
                status = SessionStatus.ENDED;
            }
        }
        return status;
    }

    return {   id: _id,
        title: _title,
        link: _link,
        file: _file,
        event_id: _event_id,
        time: _time,
        date: _date,
        active: _active,
        source_id: _source_id,
        cvent_id: _cvent_id,
        end: _end,
        start: _start,
        target: _target,
        timezone: _timezone,
        category: _category,
        is_live: _is_live,
        position: _position,
        locale: _locale,
        detail: _detail,
        category_id: _category_id,
        files: _files,
        meta: _meta,
        people: _people,
        isWishlisted: _isWishlisted,
        isRegistered: isRegistered,
        setRegistered: (status) => setRegistered(status),
        getStatus: () => getStatus()
    }
}