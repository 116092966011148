import {LiveNavbar} from "./LiveNavbar";
import {LiveContent} from "./LiveContent";
import React from "react";
import {useTemplate} from "../../../../../hooks/shared/useTemplate";
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";

export const LiveRoom = ({activeId, setActiveId, channels}) => {
    const {
        state: {
            layout
        }
    } = useTemplate()

    const details = TemplateDetails(layout)
    return <div className="live_room_left_box">

        {details.getTemplate() !== Template.ONEPAGE && <LiveNavbar channels={channels} activeId={activeId} onClick={(id) => setActiveId(id)}/> }
        {/* Tab panes */}
        <LiveContent activeId={activeId}/>
    </div>
}