import React,{useState, useContext, useCallback, useReducer, useEffect, useRef} from 'react';
import useSlug from "../../../../../../hooks/useSlug";
import contries from "../../../../../../data/contries";
import { Context as AuthContext } from "../../../../../../context/AuthContext";
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import {changeLangauge, Context as languageContext} from "../../../../../../context/LanguageContext";
import { Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useToasts } from "react-toast-notifications";
import UserInterests from './UserInterests';
import toastProp from "../../../../../../data/toastProp";
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';
import DropDown from '../../../../../../UIComponets/DropDown/DropDown';
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import { ShimmerThumbnail, ShimmerSimpleGallery } from "react-shimmer-effects";
import { Image } from '../../../../../../UIComponets/Image';
import { useTemplate } from '../../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../../Model/TemplateDetails";


const ProfileDetails = () => {
    const slug = useSlug();
    const fileInput = useRef(null);
    const { addToast } = useToasts();
    const [profilePicName, setProfilePicName] = useState(null);
    const [fileBase64String, setFileBase64String] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [countString, setCountString] = useState(null);
 
    const {
        updateProfile,
       state:{ 
          eventsPageData: {
             user, 
             event,
             details,
             languages,
             translations,
             interests, 
             user_interests, 
             user_details
          },
       }
    } = useContext(defaultContext);

    const {changeLangauge, state: { langauge } } = useContext(languageContext);
 
    const [profileData, setProfileData] = useState(user);
    const [profileUserDetails, setProfileUserDetails] = useState(user_details);
    const [profileUserInterests, setProfileUserInterests] = useState(user_interests);

    const [interestsData, setInterestsData] = useState(null);

    const [profile, setProfile] = useState({first_name:"", last_name:"", email:"", phone:"", gender:"", language:"", title:"", company:""});
    const [profileExtra, setProfileExtra] = useState({facebook_link:"", linkedin_link:"", twitter_link:"", company:"", function:"", description:""});
    const [currentLanguage, setCurrentLanguage] = useState(langauge);
    const [radioButton, setRadioButton] = useState(user?.gender);
    const [country, setCountry] = useState(user?.country_code ? user.country_code : "Belgium");
    const [profilePic, setProfilePic] = useState("");



  useEffect(() => {
      if(profileData){
        setProfile({
          first_name: profileData?.name ?? "",
          last_name: profileData?.last_name ?? "",
          email: profileData?.email ?? "",
          phone: profileData?.phone ?? "",
          gender : profileData?.gender ?? "",
          language : profileData?.locale ?? "",
          title : profileData?.title ?? "",
          company : profileData?.company ?? "",
        });
      }
  }, [profileData]);

  useEffect(() => {
    if(profileUserDetails){
        setProfileExtra({
          facebook_link: profileUserDetails?.facebook_link ?? "",
          linkedin_link: profileUserDetails?.linkedin_link ?? "",
          twitter_link: profileUserDetails?.twitter_link ?? "",
          company: profileUserDetails?.company ?? "",
          function: profileUserDetails?.function ?? "",
          description : profileUserDetails?.description ?? ""
        });
    }
  }, [profileUserDetails]);

  useEffect(() => {setProfileData(user);}, [user]);
  useEffect(() => {setProfileUserDetails(user_details);}, [user_details]);



  useEffect(() => {
      if(interests){
          const userInteresetMap = user_interests && user_interests?.reduce((prev, next) => {
              if (!next?.id)  return prev;
              return {
                  ...prev,
                  [next?.id]: next
              }
          }, {})

          const arr1 = interests?.map(interest => {
              const _int = {...interest}
              _int.ischecked = userInteresetMap[interest?.id] ? true : false
              return _int
         });
         setInterestsData(arr1);
      }
  }, [JSON.stringify(interests), JSON.stringify(user_interests)]);


  useEffect(() => { 
     if(user_details){
        setCountString(user_details?.description?.length);
     }
  }, [user_details])



  //React lifecycle methods
   useEffect(() => {
       if (user){
           if (user?.gender){
               setRadioButton(user?.gender);
           }
           if (user?.country_code){
               setCountry(profileData?.country_code ? user.country_code : "Belgium");
           }
       }
   }, [user]);

  const inputChangeHandler = ({target}) => {
    const {name, value} = target;
    setProfile({...profile, [name]: value});
  };
  
  const inputExtraChangeHandler = ({target}) => {
    const {name, value} = target;
    setProfileExtra({...profileExtra, [name]: value});
  };



  const interestCheckBoxHandler = ({target}) => {
    const {checked, value} = target;
    // const checkboxes = document.querySelectorAll('input[name="interests"]:checked');
    // let selected = Array.from(checkboxes).map(x => x.value);
      setInterestsData((pre) => {
          const index = pre.findIndex(p => p.id == value)
          if (index !== -1){
              pre[index].ischecked = checked
          }
          return pre
      });
  }

  // useEffect(() => {
  //   const checkboxes = document.querySelectorAll('input[name="interests"]:checked');
  //   if(checkboxes){
  //     let selected = Array.from(checkboxes).map(x => x.value);
  //     setSelectedInterest(selected);
  //   }
  // }, []);

  const encodeFileBase64 = (file) => {
       var reader = new FileReader();
       if (file) {
         reader.readAsDataURL(file);
         reader.onload = () => {
           var Base64 = reader.result;
           setFileBase64String(Base64);
         };
         reader.onerror = (error) => {
         };
       }
    };
 
    const triggerInputFile = () => {
       fileInput.current.click();
    }

    const setProfileImage = (e) => {
       e.preventDefault();
       encodeFileBase64(e.target.files[0]);
       setProfilePic(URL.createObjectURL(e.target.files[0]));
       setProfilePicName(e.target.files[0].name);
    };
 
    const countriesList = contries.map((country, i) => (
       <React.Fragment key={i}>
          <option value={country.code} selected={profileData?.country_code === country?.code} >{country.name}</option>
       </React.Fragment>
    ));
 
    const LanguageMenu = languages && Object.keys(languages).length > 1 > 0 ? (
        <DropDown
          classAdd={"form-control"}
          items={languages}
          selected={currentLanguage}
          onChange={(data) =>
          setCurrentLanguage(data)
          }
        />
    ) : ("");
 
    const onUpdateProfile = async (e) => {
       e.preventDefault();
      //  let interest = [];
      // if (selectedInterest) {
      //    interest = Object.keys(selectedInterest).filter(k => selectedInterest[k] === true );
      // }
    const interestsPayload =  interestsData?.filter(i => i.ischecked).map(i => i.id) ?? []
    const data = {
        first_name: profile?.first_name,
        last_name: profile?.last_name,
        email: profile?.email,
        phone: profile?.phone,
        gender: radioButton,
        language: profile?.language,
        title:profile?.title,
        company:profile?.company,
        country_code : country,
        extra: profileExtra,
        avatar: fileBase64String,
        interests: interestsPayload
    };

    if (currentLanguage) {
      data.language = currentLanguage;
    }

    if (profile?.first_name === "") {
      addToast("Please Enter Name", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    } else if (profile?.last_name === "") {
      addToast("Please Enter Last Name", {
      appearance: "error",
      autoDismiss: true,
      });
      return;
    }
 
    setIsLoading(true);
    try {
        await updateProfile(slug, data, (response) => {
          if (response.status === 200){
            toast.success(response?.data?.message, toastProp);
            setIsLoading(false);
            setTimeout(() => {
              changeLangauge(currentLanguage);
            }, 1000);
          } else {
              toast.success(response?.data?.message, toastProp);
            setIsLoading(false);
          }
        });
      } catch (err) {
        addToast("Something went wrong! try again", {
          appearance: "error",
          autoDismiss: true,
        });
        setIsLoading(false);
      }
    }

    const isUrlAbsolute = (url) => {
      if(url){
        if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
            return true;
        }else{
            return false;
        }
      }else{
        return false;
      }
    }
      
    const {
        state: {
            layout
        }
    } = useTemplate()

    const detail = TemplateDetails(layout)
    const isOnePageTemplate = detail.getTemplate() === Template.ONEPAGE

    return (
        <>
        <div className={isOnePageTemplate ? "col-md-12 col-lg-12 col-xl-11" : "col-md-12 col-lg-12 col-xl-11"}>
            <Form onSubmit={onUpdateProfile} method='post'>
                <div className="profile_left_box main-card">
                    <div className="row">
                        <div className={isOnePageTemplate ? "col-md-12 col-lg-12" : `col-md-12 col-lg-12 col-xl-${details?.nt_match_on === 'random' ? "12": "9" }`}>
                        <div className="profile_user_form_inner">
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="profile_user_inner">
                                    <div className="profile_user_img">
                                       {profileData ? (<img  src={ profilePic ? profilePic : isUrlAbsolute(profileData?.avtar) ? profileData?.avtar : imageWithStorage + profileData?.avtar }  
                                        onError={(e) => {e.target.onerror = null; e.target.src = "/images/placeholder_square.jpg"}} 
                                        alt="user profile"/>)
                                        : (<ShimmerThumbnail height={175} rounded />)}
                                        <Form.Control hidden type="file" name="user_profile" onChange={setProfileImage}  ref={fileInput} accept="image/*" />                                       
                                        <button  type="button" className="btn btn_change" onClick={() => triggerInputFile()}>{translations?.account?.avatar_file_label}</button>
                                    </div>
                                    <div className="profile_user_radio">
                                        {event?.source === "cvent" ? null : (
                                                <>
                                                <p><Form.Check type="radio" id="Male" name="gender" onChange={(e) => setRadioButton(e.target.value)}  label="Male"  value="male"  checked={radioButton === "male"}/></p>
                                                <p><Form.Check type="radio" id="Female" name="gender" onChange={(e) => setRadioButton(e.target.value)} label="Female" value="female"  checked={radioButton === "female"}/></p>
                                                <p><Form.Check type="radio" id="Other" name="gender" onChange={(e) => setRadioButton(e.target.value)} label="Other" value="other"  checked={radioButton === "other"}/></p>
                                                </>
                                        )}
                                    </div>
                                    </div>
                                    <div className="profile_user_form mt-100">
                                       <div className="form-group coustom_form_group">
                                         <label>{translations?.account?.first_name_label}</label>
                                         <Form.Control type="text" placeholder="First Name*" name="first_name" value={profile?.first_name} onChange={inputChangeHandler}/>
                                       </div>
                                        <div className="form-group coustom_form_group">
                                         <label>{translations?.account?.last_name_label}</label>
                                        <Form.Control type="text" placeholder="Last Name*" name="last_name" value={profile?.last_name} onChange={inputChangeHandler}/>
                                        </div>
                                        {/* <Form.Control type="text" placeholder="Country" name="country"  id="Country" onChange={inputExtraChangeHandler} value={profileExtra?.country}/> */}
                                        <div className="form-group coustom_form_group select_icon">
                                         <label>{translations?.account?.country_label}</label>
                                        <Form.Select name="country" className="form-control" id="Country"  onChange={(e) => setCountry(e.target.value)}>
                                          <option value="">Select Country</option>
                                          {countriesList}
                                        </Form.Select>
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                        </div>
                                        <div className="form-group coustom_form_group">
                                         <label>Job Title</label>
                                        <Form.Control type="text" placeholder="Title" name="title" value={profile?.title} onChange={inputChangeHandler}/>
                                      </div>
                                       <div className="form-group coustom_form_group">
                                         <label>{translations?.account?.company_label}</label>
                                        <Form.Control type="text" placeholder="Company" name="company" value={profile?.company} onChange={inputChangeHandler}/>
                                        </div>
                                        <div className="form-group coustom_form_group">
                                         <label>Registration type</label>
                                        <Form.Control type="text" placeholder="Registration Type" name="cvent_registration_type_name" value={profileUserDetails?.cvent_registration_type_name} disabled/>
                                        </div>
                                        {LanguageMenu}    
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="profile_user_form">
                                      <div className="form-group coustom_form_group">
                                         <label>Email</label>
                                        <Form.Control type="email" placeholder="Email*" name="email" value={profile?.email} onChange={inputChangeHandler}/>
                                        </div>
                                        <div className="form-group coustom_form_group">
                                         <label>{translations?.account?.phone_label}</label>
                                        <Form.Control type="tel" placeholder="Phone" name="phone" defaultValue={profile?.phone} onChange={inputChangeHandler}/>
                                        </div>

                                        <div className="form-group coustom_form_group">
                                         <label>{translations?.account?.linkedin_label}</label>
                                        <Form.Control type="text" placeholder="Linkedin" name="linkedin_link" defaultValue={profileExtra?.linkedin_link}  onChange={inputExtraChangeHandler}/>
                                        </div>
                                        {event?.source === "cvent" ? "" : (<div className="form-group coustom_form_group">
                                          <label>{translations?.account?.facebook_label}</label>
                                          <Form.Control type="text" placeholder="Facebook" name="facebook_link" defaultValue={profileExtra?.facebook_link} onChange={inputExtraChangeHandler}/>
                                        </div>)}
                                         <div className="form-group coustom_form_group">
                                         <label>{translations?.account?.twitter_label}</label>
                                        <Form.Control type="text" placeholder="Twitter" name="twitter_link"  defaultValue={profileExtra?.twitter_link} onChange={inputExtraChangeHandler}/>                               
                                        </div>
                                        <div className="form-group coustom_form_group">
                                         <label>Short description</label>
                                        <Form.Control 
                                            style={{ height: '200px' }}
                                            rows="5"
                                            id="comment" 
                                            name="description"
                                            defaultValue={profileExtra?.description}  
                                            as="textarea" 
                                            placeholder="Short description (max 400 characters)" 
                                            onChange={inputExtraChangeHandler}
                                            onKeyUp={e => setCountString(e.target.value.length)}
                                        />
                                        </div>
                                        <span className="float-right mb-3">{countString? countString : 0}/400</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        {/* User interests list show here */}
                       {!isOnePageTemplate && <>{details?.nt_match_on === 'interest' && (interestsData ? (<UserInterests interests={interestsData} onInputCheck={interestCheckBoxHandler} />) : (<ShimmerSimpleGallery card row={1} col={1} imageHeight={400} />))}</> }
                    </div>
                    <div className="profile_save_button text-right">
                        <button
                        disabled={isLoading} 
                        type="submit" 
                        className="btn btn_border_orange" 
                        onClick={onUpdateProfile}>
                          <IconLoader isLoading={isLoading}>Save</IconLoader>
                        </button>
                    </div>
                </div>
            </Form>
        </div> 
        </>
    )
}

export default ProfileDetails;
