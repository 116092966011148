import React,{useState, useContext, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Location from "../../../../../hooks/Location";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import BreakoutRoomMobile from './BreakoutComponets/BreakoutRoomMobile';
import BreakoutRoomCat from './BreakoutComponets/BreakoutRoomCat';
import BreakoutRoomList from './BreakoutComponets/BreakoutRoomList';
import { ShimmerPostList,ShimmerTitle } from "react-shimmer-effects";
import useSlug from "../../../../../hooks/useSlug";


const BreakoutRoom = () => {
   const slug = useSlug();
   const [isLoading, setIsLoading] = useState(false);

   const {
      getBreakOutRoomsData,
      state:{ 
         eventsAllPagesData: { 
             breakouts, 
             breakout_categories
        },
        eventsPageData: {
            event,
            translations
        },
      }
   } = useContext(defaultContext);

   const [breakoutsItem, setBreakoutsItem] = useState(breakouts);
   const [breakoutsCat, setBreakoutsCat] = useState(breakout_categories);
   const [isActive, setActive] = useState("all");

   // life cycle methods 
   useEffect(()=> {
      setIsLoading(true);
      getBreakOutRoomsData(slug.path, (res, error) => {
        // setIsLoading(false);
      }).then((res) => {
        setIsLoading(false);
      })
   }, [])

   useEffect(() => {
      if (breakouts) {
         setBreakoutsItem(breakouts)
      }
   }, [breakouts]);
   
   useEffect(() => {
      if (breakout_categories) {
         setBreakoutsCat(breakout_categories)
      }
   }, [breakout_categories]);

   const filterBreakouts = (categorieId) => {
        setIsLoading(true);
        setActive(categorieId);
      if(categorieId == "all"){
        setIsLoading(false);
         setBreakoutsItem(breakouts);
         return;
      }
      const updateBreakouts = breakouts && breakouts.filter((currentbreakouts) => {
            setIsLoading(false);
            return currentbreakouts.category === categorieId;
      });
      setBreakoutsItem(updateBreakouts);
   }

   return (
      <>
        {/* start header right */}
         <div className="breakout_room_area mt-50">
             <div className="container">
                 <h1 className="main_heading mb-20">{translations ? translations?.breakout?.title : ""}</h1>
                  {/* Breakout rooms category */}
                  {!isLoading && breakoutsCat ?  (<BreakoutRoomCat 
                    filterBreakouts={filterBreakouts} 
                    breakoutsCat={breakoutsCat}
                    isActive={isActive}
                  />) : (<ShimmerTitle line={1} gap={10} variant="primary" />) }
                  {/* Breakout rooms mobile view category*/}
                  <BreakoutRoomMobile/>
                  <div className="row">
                  {/* Breakout rooms list hear show */}
                  {!isLoading && breakoutsItem ?  
                  <BreakoutRoomList breakoutsItem={breakoutsItem}/> 
                   : (<ShimmerPostList postStyle="STYLE_FOUR" col={4} row={2} gap={30} />) }
                  </div>
             </div>
         </div>

        {/*-- The Modal */}
        <div className="modal fade connection_request private_room" id="private_room">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    {/*-- Modal Header */}
                    <div className="modal-header">
                        <button type="button" className="close" dataDismiss="modal">&times;</button>
                    </div>

                    {/*-- Modal body */}
                    <div className="modal-body">
                        <div className="connection_request_body">
                           <div className="private_room_header">
                               <div className="connection_p_head">
                                            <span className="icon">
                                               <svg xmlns="http://www.w3.org/2000/svg" width="36.823" height="48.33" viewBox="0 0 36.823 48.33">
                                                    <path id="Icon_material-lock-outline"  d="M24.412,38.323a4.6,4.6,0,1,0-4.6-4.6A4.616,4.616,0,0,0,24.412,38.323ZM38.22,17.61h-2.3v-4.6a11.507,11.507,0,1,0-23.014,0v4.6H10.6a4.616,4.616,0,0,0-4.6,4.6V45.227a4.616,4.616,0,0,0,4.6,4.6H38.22a4.616,4.616,0,0,0,4.6-4.6V22.213A4.616,4.616,0,0,0,38.22,17.61Zm-20.943-4.6a7.134,7.134,0,0,1,14.269,0v4.6H17.277ZM38.22,45.227H10.6V22.213H38.22Z" transform="translate(-6 -1.5)" fill="#f26334"/>
                                                </svg>

                                            </span>
                                            <div className="info mb-30">
                                                <h4>Private Breakout Room</h4>
                                                <p>Meet at the beach</p>
                                            </div>
                                        </div>

                                        <div className="users">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="20.25" viewBox="0 0 31.5 20.25">
                                             <path id="Icon_ionic-md-people"  d="M23.727,16.313a4.219,4.219,0,1,0-4.3-4.219A4.27,4.27,0,0,0,23.727,16.313Zm-11.454,0a4.219,4.219,0,1,0-4.3-4.219A4.27,4.27,0,0,0,12.273,16.313Zm0,3.094c-3.365,0-10.023,1.617-10.023,4.922v3.8H22.5v-3.8C22.5,21.023,15.638,19.406,12.273,19.406Zm11.454.774a7.641,7.641,0,0,0-1.227.07c1.647,1.2,2.25,1.969,2.25,4.078v3.8h9v-3.8C33.75,21.023,27.092,20.18,23.727,20.18Z" transform="translate(-2.25 -7.875)" fill="#454545"/>
                                          </svg>
                                           <p>4<span>/10</span></p>
                                        </div>
                           </div>
                             
                            <div className="row  mt-30">
                                <div className="col-md-12 col-lg-6">
                                    <div className="private_room_left_box">
                                     
                                        <div className="private_room_img">
                                            <img alt="image" src="/images/vault_img1.png" />
                                        </div>
                                        <p>Take a drink and go to the beach. Let go of your stress and have a great time!</p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="private_room_right_box">

                                       <div className="password_input">
                                           <input type="password" className="form-control" placeholder="Password" />
                                           <i className="fa fa-eye" aria-hidden="true"></i>
                                       </div>
                                        
                                    <div className="detail_btn">
                            <Link to={slug+'/breakoutroomcall'}><button type="button" className="btn btn_border_orange"> <img alt="image" src="/images/upload.png" className="upload_img"/> Enter</button></Link>
                            <button type="button" className="btn btn_border_orange"><img alt="image" src="/images/info.png"/></button>
                           </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </>
   )
}

export default BreakoutRoom;
