import React,{useState, useContext, useEffect} from 'react';
import { useToasts } from "react-toast-notifications";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import DailyTriviaList from './DailyTriviaList';
import DailyTriviaCat from './DailyTriviaCat';
import {ShimmerTitle,  ShimmerSimpleGallery } from "react-shimmer-effects";
import { Translations } from '../../../../../hooks/shared/Translations';
import useSlug from '../../../../../hooks/useSlug';
import { IconLoader } from '../../../../../UIComponets/IconLoader';
import {SearchIcon} from "../../../../../SVG/Icons";
import {useTemplate} from "../../../../../hooks/shared/useTemplate";
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";

const DailyTrivia = () => {
  const slug = useSlug().path;
  const [isLoading, setIsLoading] = useState(false);

  const {
    getDailyTriviaData,
    state:{ 
        eventsAllPagesData: { 
          trivias, 
          trivias_categories 
        },
    }
  } = useContext(defaultContext);

  const { translations } = Translations();

  const [triviasItem, setTriviasItem] = useState(trivias);
  const [triviasCat, setTriviasCat] = useState(trivias_categories);
  const [isActive, setActive] = useState("all");
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(()=> {
      setIsLoading(true);
      getDailyTriviaData(slug, (res, error) => {
          setIsLoading(false);
      });
  }, [])

  useEffect(() => {
    if (trivias) {
      setTriviasItem(trivias);
    }
  }, [trivias]);

  useEffect(() => {
      if (trivias_categories) {
        setTriviasCat(trivias_categories);
      }
  }, [trivias_categories])

  const filterTrivias = (categorieId) => {
     setActive(categorieId);
     if(categorieId == "all"){
        setTriviasItem(trivias);
         return;
     }
     const updateTrivias = trivias && trivias?.filter((currentTrivias) => {
            return currentTrivias.category_id === categorieId;
     });
     setTriviasItem(updateTrivias);
  }
  
  const searchDailyTrivias = (query) => {
      if(query !== ''){
          const updatedList = trivias && trivias.filter((item) => {
              return item?.title.toLowerCase().includes(query.toLowerCase());
          });
          setTriviasItem(updatedList);
      }else{
        setTriviasItem(trivias);
      }
  }

  const {
    state: {
        layout
    }
  } = useTemplate()

  const templateDetails = TemplateDetails(layout)
  const isOnePageTemplate = templateDetails.getTemplate() === Template.ONEPAGE;

   return (
      <>
          <section className="daily_trivia_area mt-50" id='daily-trivia'>
            <div className="container">
               {isOnePageTemplate ? "" : (<h1 className="mobile-main-heading-two"><span><i className="fa fa-angle-right"></i></span>{translations?.daily_trivia?.title}</h1>) }
               <h1 className="main_heading mb-20">{translations?.daily_trivia?.title}</h1>
               {/* Daily trivia categorise will be come here*/}
               <div className='daily_trivia_header'>
                    {!isLoading ? (triviasItem?.length > 0 && <DailyTriviaCat
                      filterTrivias={filterTrivias}
                      triviasCat={triviasCat}
                      isActive={isActive}
                      translations={translations}
                    />) :  (<ShimmerTitle line={1} gap={10} variant="primary"/>)}
                    <div class="searchbar_right">
                          <input 
                              type="text" 
                              placeholder="Search.." 
                              name="search" 
                              className="form-control" 
                              onChange={(e) => searchDailyTrivias(e?.target?.value)}
                          />
                          <button type="submit"><IconLoader isLoading={isLoading}><SearchIcon/></IconLoader></button>
                      </div>
                </div>
               {/* Daily trivia items will be come here */}
              {!isLoading ?
               (<DailyTriviaList triviasItem={triviasItem} onClick={(e, item) => setSelectedItem(item)}/>) 
               : (<ShimmerSimpleGallery col={3} row={2} imageHeight={300} caption/>) }
            </div>
          </section>
      </>
   )
}

export default DailyTrivia
