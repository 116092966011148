import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Location from "../../../../../../hooks/Location";
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';
import useSlug from "../../../../../../hooks/useSlug";
import { Image } from '../../../../../../UIComponets/Image';

const BreakoutRoomList = ({breakoutsItem}) => {
    const slug = useSlug().absolutePath;

    return (
        <>
            {breakoutsItem && breakoutsItem.map((breakout, index) => {
                    return (
                        <div className="col-md-6 col-lg-4 col-xl-3" key={index}>
                            <div className="m_slider_inner_box breakout_room_box">
                                <div className="mslide_img">
                                    <Image src={breakout?.thumbnail} placeholderSrc={'/images/miniature.png'} alt={breakout?.name} />
                                    <div className="number_user">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.712" height="14.601" viewBox="0 0 22.712 14.601">
                                        <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people" d="M17.736,13.959a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,17.736,13.959Zm-8.259,0a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,9.477,13.959Zm0,2.231c-2.426,0-7.227,1.166-7.227,3.549v2.738h14.6V19.738C16.851,17.355,11.9,16.189,9.477,16.189Zm8.259.558a5.51,5.51,0,0,0-.885.051,3.023,3.023,0,0,1,1.622,2.94v2.738h6.489V19.738C24.962,17.355,20.162,16.747,17.736,16.747Z" transform="translate(-2.25 -7.875)" fill="#fff"/>
                                    </svg>
                                    <p>6/10</p>
                                    </div>
                                </div>
                                <div className="mslide_content">
                                    {breakout?.name ? (<h4>{breakout?.name}</h4>) : ""} 
                                    {breakout?.description ? (<p className="color1">{breakout?.description?.substring(0, 140)+'...'}</p>) : ""}
                                    <div className="detail_btn">
                                        <Link to={slug+'/breakout-room-call'}>
                                            <button type="button" className="btn btn-detail">
                                            <img alt="image" src="/images/upload.png"/> Enter</button>
                                        </Link>
                                        <button type="button" className="btn btn_info">
                                            <img alt="image" src="/images/info.png"/>
                                        </button>
                                    </div>
                                </div>
                        </div>
                    </div>
                    )
            })}
        </>
    )
}

export default BreakoutRoomList;
