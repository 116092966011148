import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollIntoView({children}) {
  const { pathname } = useLocation();

  // useEffect(() => {
  //    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  // }, [pathname]);

  return <>{children}</>;
}