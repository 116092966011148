import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import useSlug from '../../../../../hooks/useSlug';
import {Context as defaultContext, resetNetworkingBadge} from "../../../../../context/DefaultSectionContext";
import ParticipantDetails from "./ParticipantDetails";
import ActivesCall from "./ActivesCall";
import {Peoples} from "./UIComponents/People";
import {DiceMenu, MeetingMenu, PeopleMenu, NetworkMenu, ChatMenu} from "./UIComponents/NetworkMenu";
import Meeting from "./UIComponents/Meeting";
import Dice from "./UIComponents/Dice";
import ChatWindow from "./UIComponents/ChatWindow";
import {NetworkUIState, NetworkUIStateAll} from "./UIComponents/NetworkUIState";
import {ConnectingModel} from "./UIComponents/ConnectingModel";
import {OutgoingCallModel} from "./UIComponents/OutgoingCallModel";
import {ScheduleAppointmentModel} from "./UIComponents/ScheduleAppointmentModel";
import {MobileConnectionRequestModel} from "./UIComponents/MobileConnectionRequestModel";
import {MobileOutgoingCallModel} from "./UIComponents/MobileOutgoingCallModel";
import {VideoChatState} from "../../../../../hooks/VideoChatState";
import {VideoCallApi} from "../../../../../hooks/videoCallApi";
import {AppSettingOptions} from "../../../../../hooks/shared/AppSettings";
import $ from 'jquery';
import {CenterWrapper} from "../../../../LayoutRenderer";
import {useHistory, useLocation} from "react-router";
import {OnlineOfflineUser} from "../../../../../Util/Utils";
import {RouteWrapper} from "../../../Routes";
import {URLContants} from "../../../../../Model/URLContants";
import {BadgesApi} from "../../../../../hooks/shared/BadgesApi";
import {NetworkingBadgeType} from "../../../../../classes/BadgeType";
import {SizeUtils} from "../../../../../hooks/shared/SizeUtils";
import IncomingCallModel from "./UIComponents/IncomingCallModel";
import Logger from "../../../../../Util/Logger";
import {Prompt} from "react-router-dom";
import {Constants} from "../../../../../classes/Constants";

function isMenuActiveForState(state) {
    // if (state === NetworkUIState.DICE) return false;
    if (state === NetworkUIState.PARTICIPANT_DETAILS) return false;
    if (state === NetworkUIState.ACTIVE_CALL) return false;
    return true;
}

const isCallInProcess = (state) =>  [VideoChatState.RINGING, VideoChatState.CONNECTED, VideoChatState.CALLING].includes(state)
const Network = () => {
    const slug = useSlug()
    const Path = RouteWrapper(slug.absolutePath)
    let history = useHistory();
    const { isMobile } = SizeUtils();
    const headElRef = useRef()

    const [participantDetails, setParticipantDetails] = useState(slug.identifier_1 ? {id: slug.identifier_1} : null)
    const [appointmentUpdate, setAppointmentUpdate] = useState(null)

    const  {videoChatState, endChat} = VideoCallApi()
    const {isDiceEnable} = AppSettingOptions()

    const getDefaultMenu = () => {
        return isDiceEnable ? NetworkUIState.DICE : NetworkUIState.PEOPLE
    }
    const defaultMenu = getDefaultMenu()
    const [selectedMenu, setSelectedMenu] = useState(defaultMenu)

    useEffect(() => {
       if(isMobile && videoChatState === "CONNECTED"){
            window.$('.banner_area').css({display:'none'});
       }
    },[isMobile, videoChatState]);

    const [previouseState, setPreviouseState] = useState(selectedMenu)
    const { hash } = useLocation();
    const menuGroup = [
        defaultMenu,
        NetworkUIState.MEETING,
        NetworkUIState.CHAT,
    ]
    const urlGroup = [
        NetworkUIState.PARTICIPANT_DETAILS,
    ]



    const [isInCall, setIsInCall] = useState(false)

    useEffect(() => {
        if (isInCall) return;

        let menuName = defaultMenu
        if (hash){
            const _hash = hash.replace('#', "").toUpperCase()
            if (menuGroup.includes(_hash)){
                menuName = _hash
            }
        }
        if (participantDetails){
            menuName = NetworkUIState.PARTICIPANT_DETAILS
        }
        setSelectedMenu(menuName)
    }, [participantDetails, isDiceEnable, isInCall])

    useEffect(() => {
        if (slug.identifier_1 && selectedMenu === NetworkUIState.PARTICIPANT_DETAILS) return;
        let url = Path(URLContants.NETWORK)
        if (urlGroup.includes(selectedMenu)){
            if (participantDetails){
                const id = OnlineOfflineUser(participantDetails).getId() ?? ""
                url = Path(URLContants.NETWORK) + "/" + id
            }
        }
        if (menuGroup.includes(selectedMenu)){
            // if ()
            url = url.concat('#', selectedMenu.toLowerCase())
        }
        history.push(url)
        if (headElRef?.current){
            window.scrollTo(0, headElRef?.current?.offsetTop);
        }

    }, [selectedMenu])


    // useEffect(() => {
    //     if (participantDetails){
    //         const id = OnlineOfflineUser(participantDetails).getId() ?? ""
    //         setSelectedMenu(NetworkUIState.PARTICIPANT_DETAILS)
    //         history.push(Path(URLContants.NETWORK) + "/" + id)
    //     }
    // }, [participantDetails])
    useEffect(() => {
        let recent = null;
        const unlisten =  history.listen(location => {
           const pathname = location.pathname + location.hash;
            const components = pathname.split("/")
            if (components.length === 4 && !(components[3])?.includes("#")){
                const last = components[3]
                if (!last.includes("#")){
                    setSelectedMenu(defaultMenu)
                }
            }
            if (!pathname.includes("network") && isCallInProcess(videoChatState)){
                endChat({}, (response, error) => {
                })
            }
            recent = pathname;
        });
        return () => {
            unlisten()
        }
    }, [history, videoChatState])


    useEffect(() => {
        if (videoChatState === VideoChatState.CONNECTED){
            setPreviouseState(selectedMenu)
            setIsInCall(true)
            setSelectedMenu(NetworkUIState.ACTIVE_CALL)
        }
        if (isInCall && videoChatState === VideoChatState.DISCONNECTED){
            setSelectedMenu(previouseState)
            setIsInCall(false)
        }
    }, [videoChatState, isInCall])

    function isMenuActive(state) {
        return selectedMenu === state;
    }
    const {networking_badges_meeting_total, resetNetworkingBadge, networking_chat_total} = BadgesApi()
    useEffect(() => {
        if (selectedMenu === NetworkUIState.MEETING) {
            setTimeout(() => {
                resetNetworkingBadge(NetworkingBadgeType.MEETINGS, (response, error) => {})
            }, 1000)
        }
    }, [selectedMenu, networking_badges_meeting_total])

    const titles = {
        [NetworkUIState.PEOPLE]: "People",
        [NetworkUIState.PARTICIPANT_DETAILS]: "Details",
        [NetworkUIState.MEETING]: "Meeting",
        [NetworkUIState.DICE]: "Meet GS1 Peers",
        [NetworkUIState.CHAT]: "Chat",
        [NetworkUIState.USER_NOTFOUND]: "User Not Found!",

    }
    const backtoOverViewClick = () => {
        setSelectedMenu(defaultMenu)
    }
    const [appoinmentUser, setAppointmentUser] = useState(null)
    // const [connectionUser, setConnectionUser] = useState(null)

    useEffect(() => {
        if (!isDiceEnable){
            window.$("#roll_the_dice").modal("hide")
        }
    }, [isDiceEnable])
    return (
        <>
            <Prompt
                when={isCallInProcess(videoChatState)}
                message={(location, action) => {
                    // Logger.verbose(location.pathname)
                    return location.pathname.includes("network")
                        ? true
                        : Constants.CALL_DISCONNECT_MESSAGE
                }}
            />
            <section className={`network_meeting_area ${isMenuActive(NetworkUIState.ACTIVE_CALL) ? "":" mt-50"}`}>
               {/* {isMenuActive(NetworkUIState.PARTICIPANT_DETAILS) ? (<div className="view_all_btn overview_btn">
                    <button className="btn" onClick={backtoOverViewClick}>
                        <span> <i className="fa fa-angle-right" aria-hidden="true"></i></span>Back to overview
                    </button>
                </div>) : null}  */}
                <div className={isMenuActive(NetworkUIState.PARTICIPANT_DETAILS) || isMenuActive(NetworkUIState.ACTIVE_CALL) ? "" : "container"}>
                    {isMenuActive(NetworkUIState.PARTICIPANT_DETAILS) || isMenuActive(NetworkUIState.ACTIVE_CALL) ? "" : (<h1 ref={headElRef} className="main_heading mb-20"><span><i className="fa fa-angle-left"></i></span> {titles[selectedMenu]}</h1>)}
                    <div className={`${isMenuActive(NetworkUIState.PARTICIPANT_DETAILS) || isMenuActive(NetworkUIState.ACTIVE_CALL) ? "" : "row mb-100"} meeting_main_row`}>
                        {isMenuActive(NetworkUIState.PEOPLE) && !isDiceEnable && <Peoples onClick={setParticipantDetails} /> }
                        {isMenuActive(NetworkUIState.PARTICIPANT_DETAILS) && <ParticipantDetails
                            openChatWindow={(details) => {
                                setSelectedMenu(NetworkUIState.CHAT)
                                setAppointmentUser(details)
                            }}
                            details={participantDetails}
                            backtoOverViewClick={backtoOverViewClick}
                            scheduleAppointmentCallback={(user) => setAppointmentUser(user)}
                            // connectionCallback={(user) => setConnectionUser(user)}
                            callClick={(user) =>{
                                setAppointmentUser(user);
                                window.$("#outging_call").modal("show");
                            }
                            }
                        />}
                        <ActivesCall hidden={!isMenuActive(NetworkUIState.ACTIVE_CALL)}/>

                        {isMenuActive(NetworkUIState.MEETING) && <Meeting rescheduleAppointmentCallback={setAppointmentUpdate}/>}
                        {isMenuActive(NetworkUIState.DICE) && isDiceEnable && <Dice isDiceEnable={isDiceEnable} />}
                        {isMenuActive(NetworkUIState.CHAT) && <ChatWindow details={appoinmentUser}   openChatWindow={(details) => {
                                setSelectedMenu(NetworkUIState.CHAT)
                                setAppointmentUser(details)
                            }}/>}
                        {isMenuActiveForState(selectedMenu) && <NetworkMenu  active={selectedMenu}>
                            {!isDiceEnable && <PeopleMenu active={selectedMenu}  id={NetworkUIState.PEOPLE} onClick={setSelectedMenu}/>}
                            <MeetingMenu  badge={networking_badges_meeting_total} active={selectedMenu}  id={NetworkUIState.MEETING} onClick={setSelectedMenu}/>
                            <ChatMenu badge={networking_chat_total} active={selectedMenu}  id={NetworkUIState.CHAT} onClick={setSelectedMenu}/>
                            { isDiceEnable && <DiceMenu active={selectedMenu}  id={NetworkUIState.DICE} onClick={setSelectedMenu}/>}
                        </NetworkMenu>}
                    </div>
                </div>
            </section>

           {/*<OutgoingCallModel />*/}
            {/* <!-- The Modal --> */}
            {/* <ConnectingModel participantDetails={connectionUser}/> */}
            {/* <!-- The Modal --> */}
            <OutgoingCallModel participantDetails={appoinmentUser}/>
           <ScheduleAppointmentModel participantDetails={appoinmentUser} appointmentUpdate={appointmentUpdate}/>
           <MobileConnectionRequestModel />
           <MobileOutgoingCallModel />
            <IncomingCallModel/>
        </>
    )
}

export default Network

