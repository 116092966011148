import {isNotNullOrEmpty} from "../hooks/Helpers";

export enum Template {
    // ONLINEUSERS = "ONLINEUSERS",
    EVENT = "event",
    ONEPAGE = "one_page",
    NULL = "null"
}

export const TemplateDetails = (data: any) => {
    const _data = data;
    let _default_language = _data?.default_language;
    let _template = _data?.template;

    function getTemplate(): Template {
        if (_template === Template.EVENT) {
            return Template.EVENT
        }
        if (_template === Template.ONEPAGE) {
            return Template.ONEPAGE
        }
        return Template.NULL
    }

    return {
        getDefaultLanguage: () => _default_language,
        getTemplate: (): Template => getTemplate(),
        getPrimaryColor: () => isNotNullOrEmpty(_data?.primary_color) ? _data?.primary_color : null,
        getSecondaryColor: () => isNotNullOrEmpty(_data?.secondary_color) ? _data?.secondary_color : null,
        getTextColor: () => isNotNullOrEmpty(_data?.text_color) ? _data?.text_color : null
    }
}