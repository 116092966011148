import React from 'react';
import { Link } from 'react-router-dom';
import { BiLink, BiDownload } from "react-icons/bi";
import useSlug from "../../../../../hooks/useSlug";
import { imageWithStorage } from '../../../../../constants/url/urlConstants';
import ReactHtmlParser from 'react-html-parser';
import { CgPushChevronDown } from "react-icons/cg";
import VodFilesModel from './VodFilesModel';

const VodList = ({vodsItem, onSetVideoVimeoId, translations, event}) => {
    const slug = useSlug().absolutePath;

    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id);
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }

    return (
        <>
           {vodsItem?.map((vod, index)=> {
                const {id, title, description, thumbnail, vimeo_id, link, file} = vod;
                return (
                  <div className="col-md-6 col-lg-4 col-xl-3" key={index} data-index={id}>
                    <div className="video_vault_box">
                        <Link to={slug+'/video-vault-details/'+id}>
                            {/* <div className="vault_box_img" data-toggle="modal" data-target="#videovault" onClick={() => onSetVideoVimeoId(vimeo_id)}> */}
                            <div className="vault_box_img" >
                                <img src={thumbnail ? thumbnail : "/images/vault_img1.png"} alt={title} />
                                <span className="video_vault_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="79.006" height="79.006" viewBox="0 0 79.006 79.006">
                                        <path id="Icon_material-play-circle-outline" data-name="Icon material-play-circle-outline" d="M34.6,60.279,58.3,42.5,34.6,24.727ZM42.5,3a39.5,39.5,0,1,0,39.5,39.5A39.517,39.517,0,0,0,42.5,3Zm0,71.106a31.6,31.6,0,1,1,31.6-31.6A31.644,31.644,0,0,1,42.5,74.106Z" transform="translate(-3 -3)" fill="#fdfefe" opacity="0.75"/>
                                    </svg>
                                </span>
                                {/* <img alt="image" className="video_icon" onClick={() => onSetVideoVimeoId(vimeo_id)} src="/images/video_icon.png" type="button" data-toggle="modal" data-target="#videovault" /> */}
                            </div>
                        </Link>
                        <div className="vault_box_content mt-20">
                            <h4>{title}</h4>
                            <p>{description ? ReactHtmlParser(description?.substring(0, 120)+'..' ?? '') : ""}</p>
                            <Link to={slug+'/video-vault-details/'+id}><button type="button" className="more_btn">{translations?.general?.more}</button></Link>
                            <div className="video_vault_mobile_btn">
                                {vod?.link ? (
                                       <a href={vod?.link} target="_blank" >
                                            <button type="button" className="btn btn_border_orange"><BiLink/>{translations?.general?.link}</button>
                                        </a>
                                ) : null}
                                {vod?.files?.length > 0 ? (
                                    <button className="btn btn-theme-orange vod-li-fi" onClick={()=>handleFilePopup(id)}><CgPushChevronDown/>{translations?.general?.files}</button>
                                ) : null}
                                <VodFilesModel files={vod?.files} id={vod?.id} close={handleClose} event={event} />
                            </div>
                        </div>
                    </div>
                </div> 
                )
           })}
        </>
    )
}

export default VodList;
