import React, {Fragment, useContext, useEffect, useState} from "react";
import useSlug from "../../../../../hooks/useSlug";
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import {ResponseWrapper} from "../../../../../Model/ResponseWrapper";
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import {Error} from "../../../../../Util/Utils";
import {useToasts} from "react-toast-notifications";
import {InteractionSettings} from "../../../../../hooks/shared/InteractionSettings";

export const PollTab = () => {
    const {
        selectedChannel,
        poll,
        details,
        translations,
    } = InteractionSettings({})

    const channel = selectedChannel
    const question = poll?.question
    const question_id = question?.id
    const slug = useSlug()
    const [isSending, setIsSending] = useState(false)
    const [option_id, setOptionId] = useState(null)

    const { addToast } = useToasts();
    const {

        sendPollAnswer
    } = useContext(defaultContext)
    const [channel_id, setChannelId] = useState(selectedChannel?.current_channel?.id)
    useEffect(() => {
        setChannelId(selectedChannel?.current_channel?.id)

    }, [selectedChannel])
    const sendAnswer = () => {
        if (!option_id) return;

        setIsSending(true)
        sendPollAnswer(slug.path, {question_id, option_id,  channel_id}, (response, error) => {
            setIsSending(false)
            if (response) {
                const wrapper = ResponseWrapper(response)
                if (wrapper.getStatus()) {
                    setOptionId('')
                    const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                    addToast(wrapper.getMessage(), {appearance, autoDismiss})
                }
            }else if (error){
                const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                addToast(Error(error).getResponseError(), {appearance, autoDismiss})
            }
        })
    }
    const isAnswered = question?.answer_id > 0
    const optionsMap = question?.options?.reduce((prev, next) => {
        return {
            ...prev,
            [next?.id]: next
        }
    }, {})
    const answer = isAnswered ? optionsMap[question?.answer_id]?.option : ""

    return <div className="poll_accordion_content">
            <div className="p_acco_con_heading  mb-2">
                <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="38.851" height="38.851"
                        viewBox="0 0 38.851 38.851">
                        <path id="Icon_awesome-poll" data-name="Icon awesome-poll"
                            d="M34.688,2.25H4.163A4.164,4.164,0,0,0,0,6.413V36.938A4.164,4.164,0,0,0,4.163,41.1H34.688a4.164,4.164,0,0,0,4.163-4.163V6.413A4.164,4.164,0,0,0,34.688,2.25ZM13.875,31.388a1.387,1.387,0,0,1-1.388,1.388H9.713a1.387,1.387,0,0,1-1.388-1.388v-11.1A1.387,1.387,0,0,1,9.713,18.9h2.775a1.387,1.387,0,0,1,1.388,1.388Zm8.325,0a1.387,1.387,0,0,1-1.388,1.388H18.038a1.387,1.387,0,0,1-1.388-1.388V11.963a1.387,1.387,0,0,1,1.388-1.388h2.775A1.387,1.387,0,0,1,22.2,11.963Zm8.325,0a1.387,1.387,0,0,1-1.388,1.388H26.363a1.387,1.387,0,0,1-1.388-1.388v-5.55a1.387,1.387,0,0,1,1.388-1.388h2.775a1.387,1.387,0,0,1,1.388,1.388Z"
                            transform="translate(0 -2.25)" fill={details?.primary_color ? details?.primary_color : 'currentColor'}/>
                    </svg>
                </span>
                <span className="content">
                    <h4>{translations?.poll?.title}</h4>
                    <p>{question?.question}</p>
                </span>
            </div>
            {question && Object.keys(question).length > 0 ? <div className="p_acco_con_body">
                <div className="poll_check">
                    <form>
                        {
                            question?.options?.map((o, index) => {
                                return  <div key={index} className="form-group">
                                    <input disabled={isAnswered} type="checkbox" id={o.id} checked={o.id === option_id || o.id === question.answer_id} onChange={()=>setOptionId(o.id)}/>
                                    <label htmlFor={o.id}>{o.option}</label>
                                </div>
                            })
                        }
                    </form>
                </div>
                <div className="poll_send_btn" hidden={isAnswered}>
                    <button type className="btn" disabled={isSending || !option_id} onClick={sendAnswer}>
                        <IconLoader isLoading={isSending}>
                            <img alt="image" src="/images/send.png"/> {translations?.poll?.send_button_text}
                        </IconLoader>
                    </button>
                </div>

                {isAnswered ? `You Answered : ${answer}` : ""}
            </div>
                : (<div className="poll_check no_pll_check"><p>{details && details?.no_poll_message}</p> </div>)}
        </div>
}
