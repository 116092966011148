import {useContext} from "react";
import {changeLangauge, Context as languageContext} from "../../context/LanguageContext";

export const Langauges = () => {
    
    const {
        changeLangauge,
        state: {
             langauge
        },
    } = useContext(languageContext);

    return {
        changeLangauge,
        langauge: langauge
    }
}
