import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import Participant from "./Participant";
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import { OnlineUser, TwillioParticipant } from "../../../../../../Util/Utils";
import NotificationCenter from "../../../../../../hooks/NotificationCenter";
import {
  AudioDisableIcon,
  AudioEnableIcon,
  CloseIcon, SettingIcon,
  VideoDisableIcon,
  VideoEnableIcon
} from "../UIComponents/VideoChatIcons";
import { IconLoader } from "../../../../../../UIComponets/IconLoader";
import { VideoCallApi } from "../../../../../../hooks/videoCallApi";
import { useToasts } from "react-toast-notifications";
import { ErrorOption, InfoOption } from "../../../../../../hooks/showToast";
import $ from 'jquery';
import { ShimmerContentBlock, ShimmerPostDetails } from "react-shimmer-effects";
import moment from "moment";
import { UserApi } from "../../../../../../hooks/shared/UserApi";
import { UserDTO } from "../../../../../../Util/Utils";
import { WaitingPop } from "../UIComponents/WaitingPop";
import Logger from "../../../../../../Util/Logger";
import {Constants} from "../../../../../../classes/Constants";

const CallWating = () => {
  return <div className={"w-100 text-center"} style={{ minHeight: "100%", "verticalAlign": "middle", "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
    {/* <p className="call-wating">Waiting to join others</p> */}
    {/* <p className="call-wating">You are about to call</p> */}
    <WaitingPop />
  </div>

}

const ProgressBar = ({callIntervalInMinutes, initialSeconds}) => {

  const [percent, setPercent] = useState(0)
  const timeLeft = ({ minutes = 0, seconds = 0 }) => {
    // alert(seconds)
    const timeLeftSeconds = (minutes * 60) + seconds;
    const totalSeconds = callIntervalInMinutes * 60 + initialSeconds;
    const percent = totalSeconds / timeLeftSeconds;
    setPercent(percent)
    // $('.bar').after().css('width', percent + '%')
  }

  return <div>
    <Timer initialMinute={callIntervalInMinutes} timeLeftCallback={timeLeft} initialSeconds={initialSeconds}/>

    <span className="bar"><span className="bar_inner" style={`width: ${percent}%`}></span></span>
  </div>
}
const CallTimer = ({ callIntervalInMinutes, initialSeconds, timeElapsedCallback }) => {
  const [percent, setPercent] = useState(0)
  const timeLeft = ({ minutes = 0, seconds = 0 }) => {
    // alert(seconds)
    const timeLeftSeconds = (minutes * 60) + seconds;
    const totalSeconds = callIntervalInMinutes * 60 + initialSeconds;
    const percent = (1 - (timeLeftSeconds / totalSeconds));
    setPercent(percent)
    // $('.bar').after().css('width', percent + '%')
  }
  const getWidth = () => {
    return  percent * 150
  }

  return <div className="col-md-6 col-lg-6">
    <div className="time_left_section">
      <p className="time_text">Time Left</p>
      <div className="bar">
        <span className="bar"><span className="bar_inner" style={{width: `${getWidth()}px`}} ></span></span>
      </div>
      <p className="time_hour">
        <Timer initialMinute={callIntervalInMinutes} timeLeftCallback={timeLeft} initialSeconds={initialSeconds} timeElapsedCallback={timeElapsedCallback} />
      </p>
    </div>
  </div>
}
export const Timer = ({ initialMinute = 0, initialSeconds = 0, timeLeftCallback, timeElapsedCallback }) => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    setMinutes(initialMinute)
    setSeconds(initialSeconds)
  }, [initialMinute, initialSeconds])
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
          timeElapsedCallback()
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
      timeLeftCallback && timeLeftCallback({ minutes, seconds })
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0
        ? null
        : <h1> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
      }
    </div>
  )
}


const Room = ({ room }) => {
  const [isWating, setIsWating] = useState(true);
  const [isVideoEnable, setVideoEnable] = useState(true);
  const [isAudioEnable, setAudioEnable] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [participantsContainer, setParticipantsContainer] = useState({});
  const [endCallLoading, setEndCallLoading] = useState(false)
  const { addToast } = useToasts();
  const [isFetchingMatchedUser, setIsFetchingMatchedUser] = useState(false)

  const {
    onlineUsers,
    videoChat,
    endChat,
    totalUser,
    fetchMatchedUser,
    connectedPeoples,
    connectedUserMap,
    timezone
  } = VideoCallApi()

  const {
    user
  } = UserApi()

  const [callInterval, setCallInterval] = useState({ minutes: 0, seconds: 0, default: true})

  useEffect(() => {
    if (videoChat?.end_time && callInterval.default){
      const myTime = moment(new Date()).tz(Constants.DEFAULT_TIMEZONE).format(Constants.VIDEOCALL_ENDTIME_DATE_FORMATE)
      const endTime = moment(videoChat?.end_time).format(Constants.VIDEOCALL_ENDTIME_DATE_FORMATE)
      const diffTime = Math.abs(new Date(endTime) - new Date(myTime));
      const seconds = parseInt((diffTime / 1000) % 60)
      const minutes = parseInt((diffTime / (1000 * 60)) % 60)
      setCallInterval({ minutes: minutes, seconds: seconds, default: false })
    }
  }, [videoChat?.end_time])


  const onVideoSwitchClick = (status) => {
    setVideoEnable(status);
  }

  const onAudioSwitchClick = (status) => {
    setAudioEnable(status);
  }

  const endCall = () => {
    setEndCallLoading(true)
    endChat({}, (response, error) => {
      setEndCallLoading(false)
    })
  }


  useEffect(() => {
    if (!room || !room.localParticipant) {
      throw new Error('You must be connected to a room to mute tracks.');

    }
    room.localParticipant.videoTracks.forEach(
      publication => {
        if (isVideoEnable) {
          publication.track.enable()
        }
        else {
          publication.track.disable()
        }
      }
    );
  }, [isVideoEnable]);

  useEffect(() => {
    if (!room || !room.localParticipant) {
      throw new Error('You must be connected to a room to mute tracks.');

    }

    room.localParticipant.audioTracks.forEach(
      publication => {
        if (isAudioEnable) {
          publication.track.enable()
        }
        else {
          publication.track.disable()
        }
      }
    );
  }, [isAudioEnable]);


  const gotMatch = useCallback(() => {
    if (videoChat?.match_id && !isFetchingMatchedUser) {
      setIsFetchingMatchedUser(true)
      fetchMatchedUser((response) => {
        setIsFetchingMatchedUser(false)
      })
    }
  }, [videoChat, isFetchingMatchedUser])

  useEffect(() => {
    NotificationCenter.sharedInstance().registerNotification("participantConnected", (participants) => {
      if (participants.length > 0) {
        setIsWating(false)
      }
      gotMatch();
    })

    NotificationCenter.sharedInstance().registerNotification("videoChatReject", (data) => {
      let message = "Participant Rejected Call Request";
      if (data.username) {
        message = `${data.username} Rejected Call`
      }
      addToast(message, InfoOption);
    })

    NotificationCenter.sharedInstance().registerNotification("participantDisconnected", ({ participants, disconnected }) => {
      if (participants.length > 0) {
        setIsWating(false)
      }
      if (participants.length === 0) {
        endChat({})
      }
      const twillioParticipant = TwillioParticipant(disconnected);
      let message = "Participant Disconnected";
      const userID = twillioParticipant.getUserID()
      if (userID) {
        const userDetails = onlineUsers?.membersMap[userID];
        if (userDetails) {
          const user = OnlineUser(userDetails);
          const userName = user.getFullName() ?? "Participant";
          const disconnectedUser = connectedUserMap[userID];
          const isHostDisconnected = disconnectedUser && disconnectedUser.id == disconnectedUser.host_id;
          message = `${userName}${isHostDisconnected ? " (Host)" : ""} Disconnected The Call`;
        }
      }
      addToast(message, ErrorOption);
      gotMatch();
    })

  }, [videoChat, connectedUserMap, isFetchingMatchedUser])

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => {
        setTimeout(() => {
          NotificationCenter.sharedInstance().broadcastNotification("participantConnected", [...participants, participant])
        }, 1000)
        return [...prevParticipants, participant]
      });
    };

    const participantDisconnected = (participant) => {
      const _participant = { ...participant }
      setParticipants((prevParticipants) => {
        let parts = prevParticipants.filter((p) => p !== participant)
        setTimeout(() => {
          NotificationCenter.sharedInstance().broadcastNotification("participantDisconnected", { participants: parts, disconnected: _participant })
        }, 1000);
        return parts;
      }
      );

    };
    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);


  let self = useMemo(() => {
    return <Participant
      className="self"
      key={room.localParticipant.sid}
      participant={room.localParticipant}
      isSelf={true}
      audioEnable={isAudioEnable}
      videoEnable={isVideoEnable}
    />
  }, [room.localParticipant.sid, isAudioEnable, isVideoEnable])

  let remoteParticipants = participants.map(participant => {
    if (!participantsContainer[participant.sid]) {
      const id = TwillioParticipant(participant).getUserID()
      participantsContainer[participant.sid] = <Participant details={onlineUsers?.membersMap[id]} key={participant.sid} participant={participant} />
    }
    return participantsContainer[participant.sid]
  });
  remoteParticipants.push(self);
  const isGroupCall = remoteParticipants.length > 2;

  const hostUser = connectedPeoples?.filter(i => i.id === i.host_id)?.first() ?? user;
  // const roomTopBar = (participants?.length ?? 0) + 1 > 2 ? (`Breakout Room : ${hostUser}`) : `private call : ${hostUser}`;

  return (
    <>
      {isWating ? <CallWating /> : ""}
      {/* <p hidden={isWating} className="time"> <Timer initialMinute={callIntervalInMinutes} /></p> */}
      <h1 hidden={isWating} className="mobile-main-heading">
        <span className="icon"><i className="fa fa-angle-left "></i></span>
        <span className="text">{UserDTO(hostUser).getFullName()} - HOST</span>
      </h1>
      <div hidden={isWating} className="action_call_area">
        {(participants?.length ?? 0) + 1 > 2 ? (<div className="multi_video_chat_header">
          <div className="row">
            <div className="col-6 col-sm-6">
              <div className="time-left">
                <h4>Time Left</h4>
                <p>
                  <span className="bar"><span className="bar_inner"></span></span>
                  {/*<ProgressBar callIntervalInMinutes={callInterval.minutes} initialSeconds={callInterval.seconds}/>*/}
                  <span className="ber_text"><Timer initialMinute={callInterval.minutes} initialSeconds={callInterval.seconds}  timeElapsedCallback={() => endCall({}, (res, err) => {})} /></span></p>
              </div>
            </div>
            <div className="col-6 col-sm-6">
              <div className="br_video_chat_header_right">
                <div className="chat__model_area" data-toggle="modal" data-target="#m_chatbox_model" type="button">
                  <svg id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" xmlns="http://www.w3.org/2000/svg" width="17.858" height="17.857" viewBox="0 0 17.858 17.857">
                    <path id="Path_1272" data-name="Path 1272" d="M21.687,15.077a1.04,1.04,0,0,1,.142-.524,1.446,1.446,0,0,1,.09-.133A6.956,6.956,0,0,0,23.1,10.544a7.3,7.3,0,0,0-7.457-7.169A7.405,7.405,0,0,0,8.336,9.08a6.9,6.9,0,0,0-.159,1.468,7.282,7.282,0,0,0,7.341,7.259,8.841,8.841,0,0,0,2.026-.331c.485-.133.966-.309,1.09-.356a1.135,1.135,0,0,1,.4-.073,1.116,1.116,0,0,1,.434.086L21.9,18a.58.58,0,0,0,.167.043.342.342,0,0,0,.343-.343.551.551,0,0,0-.021-.116Z" transform="translate(-5.245 -3.375)" fill="#fff" />
                    <path id="Path_1273" data-name="Path 1273" d="M14.987,21.874c-.155.043-.352.09-.567.137a7.923,7.923,0,0,1-1.46.193A7.282,7.282,0,0,1,5.62,14.946a8.116,8.116,0,0,1,.064-.919c.026-.185.056-.369.1-.549s.094-.386.15-.575l-.343.3a6.389,6.389,0,0,0-2.215,4.8,6.317,6.317,0,0,0,1.065,3.52c.1.15.155.266.137.343s-.511,2.662-.511,2.662a.344.344,0,0,0,.116.331.35.35,0,0,0,.219.077.308.308,0,0,0,.124-.026l2.408-.949a.671.671,0,0,1,.515.009,7.225,7.225,0,0,0,2.606.515,6.742,6.742,0,0,0,5.156-2.365s.137-.189.3-.412C15.348,21.771,15.167,21.827,14.987,21.874Z" transform="translate(-3.375 -7.086)" fill="#fff" />
                  </svg>
                </div>
                <div className="users" data-toggle="modal" data-target="#m_chatbox_user" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22.712" height="14.601" viewBox="0 0 22.712 14.601">
                    <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people" d="M17.736,13.959a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,17.736,13.959Zm-8.259,0a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,9.477,13.959Zm0,2.231c-2.426,0-7.227,1.166-7.227,3.549v2.738h14.6V19.738C16.851,17.355,11.9,16.189,9.477,16.189Zm8.259.558a5.51,5.51,0,0,0-.885.051,3.023,3.023,0,0,1,1.622,2.94v2.738h6.489V19.738C24.962,17.355,20.162,16.747,17.736,16.747Z" transform="translate(-2.25 -7.875)" fill="#fff" />
                  </svg>
                  <p>{(participants?.length ?? 0) + 1}<span>/10</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>)
          :
          (<div className="action_call_header">
            <p hidden={isWating}> <Timer initialMinute={callInterval.minutes} initialSeconds={callInterval.seconds}  timeElapsedCallback={() => endCall({}, (res, err) => {})} /></p>
            <div className="action_call_header_btn">
              <button type="button" className="btn" data-toggle="modal" data-target="#m_chatbox_model">
                <svg id="Icon_ionic-ios-chatbubbles" xmlns="http://www.w3.org/2000/svg" width="17.858" height="17.857" viewBox="0 0 17.858 17.857">
                  <path id="Path_1272" d="M21.687,15.077a1.04,1.04,0,0,1,.142-.524,1.446,1.446,0,0,1,.09-.133A6.956,6.956,0,0,0,23.1,10.544a7.3,7.3,0,0,0-7.457-7.169A7.405,7.405,0,0,0,8.336,9.08a6.9,6.9,0,0,0-.159,1.468,7.282,7.282,0,0,0,7.341,7.259,8.841,8.841,0,0,0,2.026-.331c.485-.133.966-.309,1.09-.356a1.135,1.135,0,0,1,.4-.073,1.116,1.116,0,0,1,.434.086L21.9,18a.58.58,0,0,0,.167.043.342.342,0,0,0,.343-.343.551.551,0,0,0-.021-.116Z" transform="translate(-5.245 -3.375)" fill="#fff" />
                  <path id="Path_1273" d="M14.987,21.874c-.155.043-.352.09-.567.137a7.923,7.923,0,0,1-1.46.193A7.282,7.282,0,0,1,5.62,14.946a8.116,8.116,0,0,1,.064-.919c.026-.185.056-.369.1-.549s.094-.386.15-.575l-.343.3a6.389,6.389,0,0,0-2.215,4.8,6.317,6.317,0,0,0,1.065,3.52c.1.15.155.266.137.343s-.511,2.662-.511,2.662a.344.344,0,0,0,.116.331.35.35,0,0,0,.219.077.308.308,0,0,0,.124-.026l2.408-.949a.671.671,0,0,1,.515.009,7.225,7.225,0,0,0,2.606.515,6.742,6.742,0,0,0,5.156-2.365s.137-.189.3-.412C15.348,21.771,15.167,21.827,14.987,21.874Z" transform="translate(-3.375 -7.086)" fill="#fff" />
                </svg>
              </button>
              <button type="button" className="btn" data-toggle="modal" data-target="#m_chatbox_user">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.712" height="14.601" viewBox="0 0 22.712 14.601">
                  <g id="Group_1017" transform="translate(6165.646 9526.811)">
                    <path id="Icon_ionic-md-people" d="M17.736,13.959a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,17.736,13.959Zm-8.259,0a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,9.477,13.959Zm0,2.231c-2.426,0-7.227,1.166-7.227,3.549v2.738h14.6V19.738C16.851,17.355,11.9,16.189,9.477,16.189Zm8.259.558a5.51,5.51,0,0,0-.885.051,3.023,3.023,0,0,1,1.622,2.94v2.738h6.489V19.738C24.962,17.355,20.162,16.747,17.736,16.747Z" transform="translate(-6167.896 -9534.686)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
          </div>)}


        <div className="row people_call_header">
          <div className="col-md-2 col-lg-3"></div>
          <CallTimer callIntervalInMinutes={callInterval.minutes} initialSeconds={callInterval.seconds} timeElapsedCallback={() => endCall({}, (res, err) => {})} />
          <div className="col-md-4 col-lg-3">
            <div className="user_section">
              <div className="user_count">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="20.25" viewBox="0 0 31.5 20.25">
                    <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people" d="M23.727,16.313a4.219,4.219,0,1,0-4.3-4.219A4.27,4.27,0,0,0,23.727,16.313Zm-11.454,0a4.219,4.219,0,1,0-4.3-4.219A4.27,4.27,0,0,0,12.273,16.313Zm0,3.094c-3.365,0-10.023,1.617-10.023,4.922v3.8H22.5v-3.8C22.5,21.023,15.638,19.406,12.273,19.406Zm11.454.774a7.641,7.641,0,0,0-1.227.07c1.647,1.2,2.25,1.969,2.25,4.078v3.8h9v-3.8C33.75,21.023,27.092,20.18,23.727,20.18Z" transform="translate(-2.25 -7.875)" ></path>
                  </svg></span>
                <p className="count">{(participants?.length ?? 0) + 1}<span>/10</span></p>
              </div>
              {/*<div className="user_view">*/}
              {/*  <span className="icon">*/}
              {/*    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16.853" viewBox="0 0 25 16.853">*/}
              {/*      <path id="Icon_ionic-md-eye" data-name="Icon ionic-md-eye" d="M14.75,7.383a13.442,13.442,0,0,0-12.5,8.426,13.485,13.485,0,0,0,25,0A13.442,13.442,0,0,0,14.75,7.383Zm0,14.046a5.62,5.62,0,1,1,5.681-5.619A5.666,5.666,0,0,1,14.75,21.429Zm0-8.99a3.371,3.371,0,1,0,3.41,3.371A3.4,3.4,0,0,0,14.75,12.439Z" transform="translate(-2.25 -7.383)" ></path>*/}
              {/*    </svg>*/}

              {/*  </span>*/}
              {/*  <p className="count">18</p>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>

        <div className={`room ${isGroupCall ? "people_call_area" : ""}`}>
          <div className={`grid-chat ${!isGroupCall ? "grid-chat1" : ""}`}>{remoteParticipants}</div>
          <div className="br_video_chat_footer">
            <button type="button" className="btn" onClick={(e) => onVideoSwitchClick(!isVideoEnable)}>
              {isVideoEnable ? <VideoEnableIcon fill={"white"} /> : <VideoDisableIcon fill={"white"} />}
            </button>
            <button type="button" className="btn" onClick={(e) => onAudioSwitchClick(!isAudioEnable)}>
              {isAudioEnable ? <AudioEnableIcon fill={"white"} /> : <AudioDisableIcon fill={"white"} />}
            </button>
            {/* <button type="button" className="btn"><img alt="image" src="/images/chat_icon3.png" /></button>
            <button type="button" className="btn" data-toggle="modal" data-target="#create_public"><img alt="image" src="/images/chat_icon6.png" /></button>
            <button type="button" className="btn">
              <SettingIcon fill={"white"} />
            </button> */}
            <button type="button" className="btn" onClick={endCall}>
              <IconLoader fill={"white"} isLoading={endCallLoading}>
                <CloseIcon fill={"white"} />
              </IconLoader>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Room;
