import React,{ useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Translations } from '../../../../../../hooks/shared/Translations';
import { HorizontalScrollingMenu } from '../../../../../../hooks/HorizontalScrollingMenu';

const SponsorCat = ({categories, onClick, isActive}) => {
    const {translations} = Translations();
    const {
        slide,
        scrollCheck,
        anim,
        anim2,
        state:{
            scrl,
            scrollX,
            scrolEnd
        }
    } = HorizontalScrollingMenu();

    const [scrollBarArrow , setscrollBarArrow] = useState(false);

    useEffect(() => {
        if (scrl.current.scrollWidth > scrl.current.clientWidth) {
            setscrollBarArrow(true);
        }
    }, [scrollBarArrow]);

    return (
        <>
            <div className='sponsors-category-list mb-50'>
                {scrollBarArrow ? scrollX !== 0 &&  (
                <span className="prev cat-arrow-prev cat-arrow" onClick={() => slide(-50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)}>
                <i className="fa fa-angle-left"></i>
                </span>
                ) : null}
                <ul className="video_vault_nav deshboard_video_vault_nav" ref={scrl} onScroll={scrollCheck}>
                    <li className={isActive === "all" ? "active" : ""} onClick={() => onClick("all") }>
                        <Link to={"#"}>{translations?.general?.all}</Link>
                    </li>
                    {categories && categories?.map((category, index) => {
                        return (
                            <li key={index} onClick={()=> onClick(category?.id)} className={isActive === category?.id ? "active" : ""}>
                                <Link to={"#"} style={{color:category?.color}}>{category?.name}</Link>
                            </li>
                        )
                    })}
                </ul>
                {scrollBarArrow ? !scrolEnd && (
                <span className="cat-arrow-next next cat-arrow" onClick={() => slide(+50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)} >
                <i className="fa fa-angle-right"></i>
                </span>
                ) : null}
            </div>
        </>
    )
}

export default SponsorCat
