import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import useSlug from '../../../../../hooks/useSlug';
import { Image } from '../../../../../UIComponets/Image';
import { ShimmerSocialPost, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";
import $ from 'jquery';

import {
    LinkedinIcon,
    TwitterIcon,
    FacebookIcon,
    ConnectionIcon,
    CallIcon,
    MessageIcon,
    ScheduleAppointmentIcon
} from '../../../../../SVG/Icons';
import UserSession from './UIComponents/UserSession';
import UserSessionMobile from './UIComponents/UserSessionMobile';
import { Link } from 'react-router-dom';
import { Translations } from '../../../../../hooks/shared/Translations';
import { OnlineOfflineUser } from '../../../../../Util/Utils';
import { CenterWrapper } from "../../../../LayoutRenderer";
import { ModalIframe } from '../../../../../UIComponets/Modal/ModalIframe';
import { IconLoader } from '../../../../../UIComponets/IconLoader';
import {ResponseWrapper} from "../../../../../Model/ResponseWrapper";
import {Error} from "../../../../../Util/Utils";
import {useToasts} from "react-toast-notifications";
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import { ConnectionStatus } from '../../../../../Model/ConnectionStatus';
import ConnectConfirmPopup from './UIComponents/ConnectConfirmPopup';
import ApprovingConfirmPopup from './UIComponents/ApprovingConfirmPopup';
import { CancelIconConn, RightIconConn } from '../../../../../SVG/Icons';
import NotificationCenter from "../../../../../hooks/NotificationCenter";
import { useTemplate } from '../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";

const UserNotFound = ({backtoOverViewClick}) => {
    return  <div style={{"width": "100%", "verticalAlign":"middle","display":"flex", "justifyContent":"center","alignItems":"center"}}>
        <div>
            <p>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-times" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                     className="svg-inline--fa fa-user-times fa-w-20 fa-9x primary-color">
                    <path 
                          d="M589.6 240l45.6-45.6c6.3-6.3 6.3-16.5 0-22.8l-22.8-22.8c-6.3-6.3-16.5-6.3-22.8 0L544 194.4l-45.6-45.6c-6.3-6.3-16.5-6.3-22.8 0l-22.8 22.8c-6.3 6.3-6.3 16.5 0 22.8l45.6 45.6-45.6 45.6c-6.3 6.3-6.3 16.5 0 22.8l22.8 22.8c6.3 6.3 16.5 6.3 22.8 0l45.6-45.6 45.6 45.6c6.3 6.3 16.5 6.3 22.8 0l22.8-22.8c6.3-6.3 6.3-16.5 0-22.8L589.6 240zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                          className=""></path>
                </svg>
            </p>
            <p>
                User Not found!
            </p>
            <section className="participant_details_area mt-50 pb-100">
                <div className="view_all_btn overview_btn">
                    <button className="btn" onClick={backtoOverViewClick}>
                        <span> <i className="fa fa-angle-left" aria-hidden="true"></i></span>Back to Peoples
                    </button>
                </div>
            </section>
        </div>
    </div>
}

const ParticipantDetails = ({ backtoOverViewClick, details, openChatWindow, callClick, noDetailsFoundCallback, scheduleAppointmentCallback}) => {
    const { addToast } = useToasts();
    const slug = useSlug().absolutePath;
    const containerRef = useRef();
    const [value, onChange] = useState(new Date());
    const participantId = OnlineOfflineUser(details).getId() ?? -1;
    const [usersInfo, setUsersInfo] = useState(null);
    const [usersSession, setUsersSession] = useState(null);
    const [usersSessionCat, setUsersSessionCat] = useState(null);
    const [usersSessionRegistered, setUsersSessionRegistered] = useState(null);

    const [isActiveEu, setisActiveEu] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [favorite, setFavorite] = useState([]);
    const [replay, setReplay] = useState({});
    // connection state
    const [connect, setConnect] = useState(false);
    const [connectId, setConnectId] = useState(null);
    const [connectStatus, setConnectStatus] = useState(null);
    const [approving, setApproving] = useState(false);
    const [isStatus, setIsStatus] = useState(0);

    const {
        getParticipantUser,
        setFavoriteData,
        connectionRequest,
        approvingConnection,
        state: {
            usersDetail,
            onlineUsers,
            eventsPageData: {
                event,
                options
            },
            eventsAllPagesData:{
                connection,
                user,
                sessions,
                sessions_categories,
                registered_sessions
            }
        }
    } = useContext(defaultContext);

    const {
        translations
    } = Translations();

    const {
        state: {
            layout
        }
    } = useTemplate();
  
    const templateDetail = TemplateDetails(layout)
    const isOnePageTemplate = templateDetail.getTemplate() === Template.ONEPAGE;

    useEffect(
        () => {
          if (containerRef.current && !isOnePageTemplate) {
            window.scrollTo({
              behavior: "smooth",
              top: containerRef.current.offsetTop
            });
          }
    },[isLoading]);

    useEffect(() => {
        NotificationCenter.sharedInstance().registerNotification('connectionStatusUpdate', (data) => {
            setUsersInfo(prevState => {
                return {
                    ...prevState,
                    connection:data?.connection === null ? data?.connection : data
                }
            });
            if (data?.sender_id === user?.id){
                const userIf = OnlineOfflineUser(data.user)
                if(data?.status === 1){
                    addToast(`${userIf.getFullName()} approving your request`, {...getOptions(ToastType.INFO)})
                }
            }
            if(data?.connection === null){
                addToast(`disapproving your request`, {...getOptions(ToastType.INFO)})
            }
        })
    })

    useEffect(() => {
        setIsLoading(true);
        if (participantId) {
            getParticipantUser(slug, participantId, (res, error) => {
                if (res?.data?.status) {
                    setUsersInfo(res?.data?.data?.user);
                    setUsersSession(
                        res?.data?.data?.sessions.sort(function(a,b){
                            return new Date(a.date) - new Date(b.date);
                          })
                    );
                    setUsersSessionCat(res?.data?.data?.sessions_categories);
                    setUsersSessionRegistered(res?.data?.data?.registered_sessions);
                }
                setIsLoading(false);
            });
        }
    }, [participantId]);
    

    const isOnline = details && onlineUsers && onlineUsers?.membersMap && onlineUsers?.membersMap[participantId] ? true : false;

    const handleFavoirte = (id) => {
        setFavorite([...favorite, id]);
        const type = 'session';
        setFavoriteData(slug, { type, id }, (response, error) => {
            if (response?.data?.status) {
                const res = response?.data?.data;
                const checked = res?.wishlist?.session.includes(id) ? true : false;
                const updateSession = usersSession?.map(el => (
                    el?.id === id ? { ...el, isWishlisted: checked } : el
                ));
                setUsersSession(updateSession);
                setFavorite([]);
            }
        });
    }



    const onCloseModal = (close) => {
        console.log(close);
    }

    if (isLoading) {
        return <div className="container participant_details_shimmer"><ShimmerSocialPost type="text" title /></div>
    }
    if (!usersInfo){
        return  <UserNotFound backtoOverViewClick={backtoOverViewClick}/>
    }
    
    const handleConnection = (id) => {
        setConnectId(id);
    }

    const connectionRequestSend = (connect_id) => {
           if(connect_id){
            setConnect(true);
            connectionRequest(slug, connect_id, (response, error) => {
                if (response) {
                    const wrapper = ResponseWrapper(response)
                    if (wrapper.getStatus()) {
                        const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                        addToast(wrapper.getMessage(), {appearance, autoDismiss})
                        // connection
                        setUsersInfo(prevState => {
                            return {
                                ...prevState,
                                connection: wrapper.getData()?.connection
                            }
                        });
                       
                    }
                window.$('#connection').modal('hide');
                }else if (error){
                    const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                    addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                }
                setConnect(false);
            });
        }
    }

    const handleApproving = (connect_id, status) => {
        setConnectId(connect_id);
        setConnectStatus(status);
    }

    const approvingRequestSend = (connect_id, status) => {
        if(connect_id && status){
            setApproving(true);
            setIsStatus(status);
            approvingConnection(slug, connect_id, status, (response, error) => {
                if (response) {
                    const wrapper = ResponseWrapper(response)
                    if (wrapper.getStatus()) {
                        const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                        if(wrapper.getData().connection?.status === 1){
                            setUsersInfo(prevState => {
                                return {
                                    ...prevState,
                                    connection: wrapper.getData()?.connection
                                }
                            });
                            addToast("connection approved successfully.", {appearance, autoDismiss})
                        }
                        if(wrapper.getData().connection === null){
                            setUsersInfo(prevState => {
                                return {
                                    ...prevState,
                                    connection:null
                                }
                            });
                            addToast("connection disapproved successfully.", {appearance, autoDismiss})   
                        } 
                    }
                    window.$('#approving-model').modal('hide');
                }else if (error){
                    const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                    addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                }
               
                setApproving(false);
                setIsStatus(0);
            });
        }
    }

    const connectModalClose = () => {
        window.$('#connection').modal('hide');
    }

    const approvModalClose = () => {
        window.$('#approving-model').modal('hide');
    }

    return (
        <>
            <section className="participant_details_mobile mt-50 pb-100" style={{ width: '100%' }}  ref={containerRef}>
                <div className="container">
                    <h1 className="mobile-main-heading-two"><span><i className="fa fa-angle-left"></i></span>Network</h1>
                    <div className="participant_profile">
                        <div className="img_box">
                            {isOnline && <span className="dot"></span>}
                            {!isLoading ?
                                (<Image src={usersInfo && usersInfo?.avtar} placeholderSrc="/images/placeholder_square.jpg" alt="" />)
                                : (<ShimmerThumbnail height={248} rounded />)
                            }
                        </div>
                        <div className="profile_text">
                            <h5>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.943" height="12.943" viewBox="0 0 12.943 12.943" className="primary-color">
                                    <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people" d="M8.656,13.268a2.7,2.7,0,1,0-2.746-2.7A2.729,2.729,0,0,0,8.656,13.268Zm0,1.977c-2.151,0-6.406,1.034-6.406,3.146v2.427H15.193V18.391C15.193,16.279,10.807,15.245,8.656,15.245Z" transform="translate(-2.25 -7.875)"  />
                                </svg>
                                {usersInfo?.roles && usersInfo?.roles.map((role, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <h4>{role?.capitalize()}</h4>
                                        </React.Fragment>
                                    )
                                })}
                            </h5>
                            <h4>{usersInfo?.name} {usersInfo?.last_name}</h4>
                            {!isLoading ? (<p>{usersInfo?.title}</p>) : <ShimmerTitle line={1} gap={10} />}
                            {!isLoading ? (<h6>{usersInfo?.company}</h6>) : <ShimmerTitle line={1} gap={10} />}
                        </div>
                    </div>
                    <div className="social_btn">
                        {usersInfo?.user?.info ? (
                            <>
                                <a href={usersInfo?.info?.linkedin_link} target={"_blank"}><LinkedinIcon /></a>
                                <a href={usersInfo?.info?.twitter_link} target={"_blank"}><TwitterIcon /></a>
                                <a href={usersInfo?.info?.facebook_link} target={"_blank"}><FacebookIcon /></a>
                            </>
                        ) : ""}
                    </div>

                    <div className="participant_details">
                        {usersInfo?.info && (<p>{usersInfo?.info?.description}</p>)}
                        <div className="participant_connect_btn mt-100">

                            {usersInfo?.connection === null && options && options.includes('nt_connect_button') && (<button type="button" className="btn" data-toggle="modal" data-target="#connection" onClick={(e) => handleConnection(usersInfo?.id)}>
                                <ConnectionIcon/> <IconLoader isLoading={connect}>CONNECT</IconLoader>
                            </button>)}

                            {usersInfo?.connection?.sender_id === usersInfo?.id ?    
                                (<> 
                                    {usersInfo?.connection?.status === ConnectionStatus.PENDING && (<div className='participant_people_network_btn'> 
                                        <p><ConnectionIcon/> CONNECTION REQUEST</p>
                                        <div className="connection_btn">
                                        <button className="btn approv_btn right p-0" data-toggle="modal" data-target="#approving-model" onClick={() => handleApproving(usersInfo?.connection?.id,1)}><IconLoader isLoading={approving && isStatus === ConnectionStatus.CONNECTED}><RightIconConn/></IconLoader></button>
                                        <button className="btn approv_btn p-0" data-toggle="modal" data-target="#approving-model" onClick={() => handleApproving(usersInfo?.connection?.id,2)}><IconLoader isLoading={approving && isStatus === ConnectionStatus.DECLINED}><CancelIconConn/></IconLoader></button>
                                        </div>
                                    </div>)}
                                    {usersInfo?.connection?.status === ConnectionStatus.CONNECTED && (<button type="button" className="btn connected"><ConnectionIcon fill='#0c7513'/>CONNECTED</button>)}
                                </>) : null
                            }

                            {usersInfo?.connection?.receiver_id === usersInfo?.id ? (<>
                                {usersInfo?.connection?.status === ConnectionStatus.CONNECTED && (<button type="button" className="btn connected"><ConnectionIcon fill='#0c7513'/>CONNECTED</button>)}
                                {usersInfo?.connection?.status === ConnectionStatus.PENDING && (<button type="button" className="btn pending"><ConnectionIcon fill='#f2a20e'/>PENDING</button>)}
                                {usersInfo?.connection?.status === ConnectionStatus.DECLINED && (<button type="button" className="btn declined"><ConnectionIcon fill='#000000'/>DECLINED</button>)}
                            </>) : null}

                            <button type="button" className="btn" onClick={() => openChatWindow(usersInfo)}>
                                <MessageIcon />
                                MESSAGE
                            </button>

                            {isOnline && options && options.includes('nt_call_button') && <button type="button" className="btn" onClick={() => {
                                callClick ? callClick(usersInfo) : window.$("#outging_call").modal("show");
                            }}>
                                <CallIcon />
                                CALL
                            </button>}

                            <button type="button" className="btn" data-toggle="modal" data-target="#sehedule_appointment" onClick={() => scheduleAppointmentCallback(usersInfo)}>
                                <ScheduleAppointmentIcon />
                                SCHEDULE APPOINTMENT
                            </button>
                        </div>
                    </div>

                    {/* <!-- mobile_action_ed_time --> */}
                    <div className="mobile_session_date mt-40">
                        <div className="mobile_ed_time_section accordion mt-30" id="accordionExample">
                            {!isLoading ? (<UserSessionMobile
                                sessions={usersSession}
                                timezone={event?.timezone}
                                isActiveEu={isActiveEu}
                                onClick={handleFavoirte}
                                favorite={favorite}
                                usersSessionCat={usersSessionCat}
                                usersSessionRegistered={usersSessionRegistered}
                                event={event}
                                setReplay={setReplay}
                            />)
                            : (<ShimmerTitle line={1} gap={10} />)}
                        </div>
                    </div>
                    {/* <!-- mobile_action_ed_time --> */}
                </div>
            </section>
            {/* Desktop View Here */}
            <section className="participant_details_area mt-50 pb-100" ref={containerRef}>
                <div className="view_all_btn overview_btn">
                    <button className="btn" onClick={backtoOverViewClick}>
                        <span> <i className="fa fa-angle-right" aria-hidden="true"></i></span>Back to overview
                    </button>
                </div>
                <div className="container">
                    <div className="participant__head">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36.781" height="36.781" viewBox="0 0 36.781 36.781" className="primary-color">
                                <path
                                    id="Icon_ionic-md-people"
                                    data-name="Icon ionic-md-people"
                                    d="M20.455,23.2a7.756,7.756,0,0,0,7.8-7.663,7.8,7.8,0,0,0-15.6,0A7.756,7.756,0,0,0,20.455,23.2Zm0,5.619c-6.112,0-18.2,2.937-18.2,8.94v6.9H39.031v-6.9C39.031,31.757,26.567,28.82,20.455,28.82Z"
                                    transform="translate(-2.25 -7.875)"
                                    
                                />
                            </svg>
                        </div>
                        <div className="details">
                            {usersInfo?.roles && usersInfo?.roles.map((role, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <h4>{role?.capitalize()}</h4>
                                    </React.Fragment>
                                )
                            })}
                            <p>{usersInfo?.name} {usersInfo?.last_name}</p>
                        </div>
                    </div>
                    <div className="participant_body mt-40">
                        <div className="row">
                            <div className="col-md-12 col-lg-4">
                                <div className="participant_profile">
                                    <div className="img_box">
                                        {isOnline && <span className="dot"></span>}
                                        {!isLoading ?
                                            (<Image src={usersInfo && usersInfo?.avtar} placeholderSrc="/images/placeholder_square.jpg" alt="" />)
                                            : (<ShimmerThumbnail height={248} rounded />)
                                        }
                                        {!isLoading ? (<p>{usersInfo?.title}</p>) : <ShimmerTitle line={1} gap={10} />}
                                        {!isLoading ? (<h6>{usersInfo?.company}</h6>) : <ShimmerTitle line={1} gap={10} />}
                                    </div>
                                    {usersInfo?.user?.info ? (
                                        <div className="social_btn">
                                            <a href={usersInfo?.info?.linkedin_link} target={"_blank"}><LinkedinIcon /></a>
                                            <a href={usersInfo?.info?.twitter_link} target={"_blank"}><TwitterIcon /></a>
                                            <a href={usersInfo?.info?.facebook_link} target={"_blank"}><FacebookIcon /></a>
                                        </div>) : ""}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-8">
                                <div className="participant_details">
                                    <h4>ABOUT</h4>
                                    {usersInfo?.info && (<p>{usersInfo?.info?.description}</p>)}
                                    <div className="participant_connect_btn mt-100">
                                        
                                        {usersInfo?.connection === null && options && options.includes('nt_connect_button') && (<button type="button" className="btn" data-toggle="modal" data-target="#connection" onClick={(e) => handleConnection(usersInfo?.id)}>
                                            <ConnectionIcon/> <IconLoader isLoading={connect}>CONNECT</IconLoader>
                                        </button>)}

                                        {usersInfo?.connection?.sender_id === usersInfo?.id ?    
                                            (<> 
                                                {usersInfo?.connection?.status === ConnectionStatus.PENDING && (<div className='participant_people_network_btn'> 
                                                    <p><ConnectionIcon/> CONNECTION REQUEST</p>
                                                    <button className="btn approv_btn right p-0" data-toggle="modal" data-target="#approving-model" onClick={() => handleApproving(usersInfo?.connection?.id,1)}><IconLoader isLoading={approving && isStatus === ConnectionStatus.CONNECTED}><RightIconConn/></IconLoader></button>
                                                    <button className="btn approv_btn p-0" data-toggle="modal" data-target="#approving-model" onClick={() => handleApproving(usersInfo?.connection?.id,2)}><IconLoader isLoading={approving && isStatus === ConnectionStatus.DECLINED}><CancelIconConn/></IconLoader></button>
                                                </div>)}
                                                {usersInfo?.connection?.status === ConnectionStatus.CONNECTED && (<button type="button" className="btn connected"><ConnectionIcon fill='#0c7513'/>CONNECTED</button>)}
                                            </>) : null
                                        }

                                        {usersInfo?.connection?.receiver_id === usersInfo?.id ? (<>
                                            {usersInfo?.connection?.status === ConnectionStatus.PENDING && (<button type="button" className="btn pending"><ConnectionIcon fill='#f2a20e'/>PENDING</button>)}
                                            {usersInfo?.connection?.status === ConnectionStatus.CONNECTED && (<button type="button" className="btn connected"><ConnectionIcon fill='#0c7513'/>CONNECTED</button>)}
                                            {usersInfo?.connection?.status === ConnectionStatus.DECLINED && (<button type="button" className="btn declined" onClick={(e) => handleConnection(usersInfo?.id)}><ConnectionIcon fill='#000000'/>DECLINED</button>)}
                                        </>) : null}

                                        <button type="button" className="btn" onClick={() => openChatWindow && openChatWindow(usersInfo)}>
                                            <MessageIcon />
                                            MESSAGE
                                        </button>
                                        {isOnline && options && options.includes('nt_call_button') && <button type="button" className="btn" onClick={() => {
                                            callClick ? callClick(usersInfo) : window.$("#outging_call").modal("show");
                                        }}>
                                            <CallIcon />
                                            CALL
                                        </button>}
                                        <button type="button" className="btn" data-toggle="modal" data-target="#sehedule_appointment" onClick={() => scheduleAppointmentCallback(usersInfo)}>
                                            <ScheduleAppointmentIcon />
                                            SCHEDULE APPOINTMENT
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="participant_session mt-100">
                        {usersSession && usersSession?.length > 0 ? (
                            <>
                                <h1 className="main_heading mb-20">Sessions followed by : <p className="participant_session_name">{usersInfo?.name} {usersInfo?.last_name}</p></h1>
                                <ul className="nav eu_time_tab mb-40">
                                    <li className={`${isActiveEu ? "left active" : ""}`} onClick={() => setisActiveEu(true)}>
                                        <Link to={"#"}>{event?.shortTimezone} {translations?.general?.time}</Link>
                                    </li>
                                    <li className={`${isActiveEu ? "" : "right active"}`} onClick={() => setisActiveEu(false)}>
                                        <Link to={"#"}>{translations?.general?.device_time}</Link>
                                    </li>
                                </ul>
                                {!isLoading ? (<UserSession
                                    sessions={usersSession}
                                    timezone={event?.timezone}
                                    isActiveEu={isActiveEu}
                                    onClick={handleFavoirte}
                                    favorite={favorite}
                                    usersSessionCat={usersSessionCat}
                                    usersSessionRegistered={usersSessionRegistered}
                                    event={event}
                                    setReplay={setReplay}
                                />)
                                : (<ShimmerTitle line={1} gap={10} />)}
                            </>
                        ) : ""}
                    </div>
                </div>
            </section>
            {/* modal iframe video */}
            <ModalIframe onClose={() => onCloseModal(null)} size='lg' replayPlayer={replay} id={"replay-btn-modal"} />
            {/* modal connect video */}
            <ConnectConfirmPopup requestSend={connectionRequestSend} connectId={connectId} close={connectModalClose}/>
            <ApprovingConfirmPopup requestSend={approvingRequestSend} connectId={connectId} status={connectStatus} close={approvModalClose}/>
        </>
    )
}

export default ParticipantDetails;