import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {VideoSourceType} from "./VideoSourceType";
import Logger from "../Util/Logger";
import HtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
import {usePlayer} from "use-player";

const getUrl = ({source, contentId, direct_link}) => {
    return `https://player.vimeo.com/video/${contentId}`;
    //return  `${source} ${contentId}`
}

const DecastPlayer = ({id}) => {
    let playerRef = React.useRef(null);
    let playerOptions = {
        autoplay: false, // default
        provider: 'universe', // default
        previewThumbs: true
    };
    usePlayer(playerRef, id , playerOptions);
    return <div ref={playerRef} id="decast-player-jp"></div>
}

export const VODPlayer = forwardRef(({object, contentId, source, direct_link, width="820", height="480"}, ref) => {


    const [stop, setStop] = useState(false)
    useImperativeHandle(
        ref,
        () => ({
            play() {
                setStop(false)
            },
            stop() {
                setStop(true)
            }
        }),
    )
    let id = contentId?.replace('/', '-vod-')
    if (object?.meta?.direct_link){
        id = object?.meta?.direct_link?.replace('/', '-vod-')
    }
    let playerRef = React.useRef(null);
    let playerOptions = {
        autoplay: false, // default
        provider: 'universe', // default
    };

    usePlayer(playerRef, id , playerOptions);

    if (!contentId || !source || stop){
        return <></>
    }

    if (source === VideoSourceType.VIMEO){
        return <iframe id={contentId} src={getUrl({source, contentId, direct_link})} width={width} height={height} frameBorder='0' autoplay={false} scrolling='no' allowFullScreen={true} webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"/>
    }
    return <DecastPlayer id={id}/>
})

