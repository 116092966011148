import React, {useCallback,useContext,useEffect,useReducer,useState,useRef} from "react";
import {useHistory,Link} from "react-router-dom";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import DatePicker from "react-date-picker";
import { useToasts } from "react-toast-notifications";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {formReducer, FORM_INPUT_UPDATE} from "../../../../hooks/TextInputReducer";
import { Context as languageContext } from "../../../../context/LanguageContext";
import { Context as AuthContext } from "../../../../context/AuthContext";
import { Context  as DefaultSectionContext } from "../../../../context/DefaultSectionContext";
import {OptionConstants} from "../../../../constants/Constants";
import {imageBaseUrl,imageWithStorage,} from "../../../../constants/url/urlConstants";
import WelComeWidget from "../../../../UIComponets/Modal/WelComeWidget";
import { CustomButton } from "../../../../UIComponets/CustomButton";
import DropDown from "../../../../UIComponets/DropDown/DropDown";
import fileUpload from "../../../../assets/images/user_img.png";
import { Button,Form,InputGroup} from 'react-bootstrap';
import useSlug from "../../../../hooks/useSlug";
import {SupportScript} from "../../../../utils/SupportScript";
import FaqModal from '../Pages/Faq/FaqModal';
import useBodyClass from "../../../../hooks/BodyClass";
import contries from "../../../../data/contries";
import placeholderBody from "../../../../assets/images/preview_back.png";
import { FAQIcon } from '../../../../SVG/Icons';
import { Base64 } from 'js-base64';
import { Image } from "../../../../UIComponets/Image";


const Register2 = () => {

    const { addToast } = useToasts();
    const slug = useSlug()
    //set states
    const fileInput = useRef(null)
    const [currentLanguage, setCurrentLanguage] = useState("");
    const [userDetails, setUserDetails] = useState(null);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [globalLan, setGlobalLan] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [profilePic, setProfilePic] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [userLogging, setuserLogging] = useState(false);
    const [fileBase64String, setFileBase64String] = useState("");
    const [radioButton, setRadioButton] = useState(null);
    const [validated, setValidated] = useState(false);
    const [rizivNumber, setRizivNumber] = useState("");
    const [errors, setErrors] = useState({});
    const [country, setCountry] = useState("");
    useBodyClass("default-active");
    const [isWelcomeWidget, setIsWelcomeWidget] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);

    //store auth context 
    const { 
        onRegister,
        defaultAuthenticationWhileRegistration,
        state: {
            registrationPageData: {
                event,
                details,
                fields,
                languages,
                translations,
                options,
            },
        }
    } = useContext(AuthContext);
    
    const { 
        onUserLoggedIn,
        onRegisterSuccess,
    } = useContext(DefaultSectionContext);

    const {changeLangauge,state: { langauge },} = useContext(languageContext);

    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: {
            name: "",
            last_name: "",
            email: "",
            gender: "",
            password: "",
            password_confirmation: "",
            company : "",
            title: "",
        },
        inputValidities: {
            name: false,
            last_name: false,
            email: false,
            gender: false,
            password: false,
            password_confirmation: false,
            company:false,
            title:false
        },
        formIsValid: false,
        isPasswordMatched: false,
    });


     // register error messages
    const currentPageTranslation = translations?.registration;
    const namePlaceholder = currentPageTranslation?.name_placeholder;
    const lastnamePlaceholder = currentPageTranslation?.lastname_placeholder;
    const emailPlaceholder = currentPageTranslation?.email_placeholder;
    const genderPlaceholder = currentPageTranslation?.gender_placeholder;
    const passwordPlaceholder = currentPageTranslation?.password_placeholder;
    const confirmPasswordPlaceholder = currentPageTranslation?.confirm_password_placeholder;
    const errorText = currentPageTranslation?.required_error_text;
    const uniqueCodePlaceholder = currentPageTranslation?.unique_code_placeholder;

    const facebookPlaceholder = currentPageTranslation?.facebook_placeholder;
    const linkedInPlaceholder = currentPageTranslation?.linkedIn_placeholder;
    const twitterPlaceholder = currentPageTranslation?.twitter_placeholder;
    const companyPlaceholder = currentPageTranslation?.company_placeholder;
    const functionPlaceholder = currentPageTranslation?.function_placeholder;
    const registrationTypePlaceholder = currentPageTranslation?.registration_type_label;
    const countryLabel = currentPageTranslation?.country_label;

    const nameErrorText = namePlaceholder + " " + errorText;
    const lastnameErrorText = lastnamePlaceholder + " " + errorText;
    const emailErrorText = emailPlaceholder + " " + errorText;
    const genderErrorText = genderPlaceholder + " " + errorText;
    const passwordErrorText = passwordPlaceholder + " " + errorText;
    const confirmPasswordErrorText = confirmPasswordPlaceholder + " " + errorText;
    const [inputFieldsOrder, setInputFieldsOrder] = useState({});
    const [isAvatarSelectionEnable, setIsAvatarSelectionEnable] = useState(false);
    const [isGenderEnable, setIsGenderEnable] = useState(false);

    //life cycles hooks using app
    useEffect(() => {
        const bodyClass = document.querySelector(".default-active");
        let url;
        if(details){
        if (bodyClass) {
            if (details?.event_background_type === "image") {
            if (details?.event_background) {
                url = `url('${imageWithStorage + details?.event_background}') center center no-repeat`;
            } else {
                url = `url('${placeholderBody}') center center no-repeat`;
            }
            bodyClass.setAttribute("style", `background:${url}`);
            } else if (details?.event_background_type === "color") {
            bodyClass.setAttribute("style", `background:${details?.event_background}`);
            } else if (details?.event_background_type === "none") {
            bodyClass.setAttribute("style", "background :none");
            }
        }
        }
    }, [details, details?.background, details?.background_type]);

    useEffect(() => {
        const favicon = document.getElementById("favicon");
        if (favicon) {
          if (details?.img_favicon) {
            favicon.href = imageWithStorage + details?.img_favicon;
          } else if (details?.img_logo_desktop) {
            favicon.href = imageWithStorage + details?.img_logo_desktop;
          }
        }
    }, [details?.img_logo_desktop]);

    useEffect(() => {
        if(details?.field_order){
            setInputFieldsOrder(JSON.parse(details?.field_order));
        }
    },[details])


    useEffect(() => {
        if(event){
          document.title = `${event?.title} Registration`;
        }
    }, [event]);
    
    useEffect(() => {
        setCurrentLanguage(langauge);
    }, [langauge]);

    useEffect(() => {
        setIsAvatarSelectionEnable(options?.includes("show_avtar_on_registration"));
    }, [options]);

    useEffect(() => {
        setIsGenderEnable(options?.includes("show_gender_on_registration"));
    }, [options]);

    //function declaration methods 
    const inputChangeHandler = useCallback((inputIdentifier:any, inputValue:any, inputValidity:any) => {
            const name   = inputIdentifier?.target?.name;
            const value  = inputIdentifier?.target?.value;
            dispatchFormState({type:FORM_INPUT_UPDATE, value:value, isValid:inputValidity,input: name});
        },
        [dispatchFormState]
    );

    const inputRizivChangeHandler = (value:any) => {
        if(value !== ""){
                let rg = /(\d{2})(\d{1,3})/
                let token = "$1.$2"
                const text = value;
                const rawText = text.replaceAll(".", "").replaceAll("-", "")
                const length = rawText.length;
                if (length > 11) return;
                if (length > 5  &&  length <= 7) {
                    rg = /(\d{2})(\d{2})(\d{1,2})/
                    token = "$1.$2.$3"
                }else if (length > 7  &&  length <= 10) {
                    rg = /(\d{2})(\d{2})(\d{1,2})(\d{1,3})/
                    token = "$1.$2.$3-$4"
            }else if (length > 10  &&  length <= 11) {
                rg = /(\d{2})(\d{2})(\d{1,2})(\d{1,3})(\d{1,3})/
                token = "$1.$2.$3-$4.$5"
            }
            let onlyDigits = rawText?.replace(rg, token);
            setRizivNumber(onlyDigits);
        }else{
            setRizivNumber(value);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        const onRegister = async () => {
            try {
                await defaultAuthenticationWhileRegistration(slug.path, (response:any) => {
                setIsLoading(false);
            });
            } catch (error) {
                setIsLoading(false);
            }
            setIsLoading(false);
        };
        onRegister();
    }, [globalLan]);


    useEffect(() => {
        if (options) {
            setIsWelcomeWidget(options?.includes('welcome_widget'));
            setIsPopUp(options?.includes('welcome_widget_popup'));
        }
    }, [options]);

    useEffect(() => {
        if (isPopUp) {
            setTimeout(() => {
            window.$("#splashRegistrationeModal").modal("show");
            }, 1000);
        }
    }, [isPopUp]);


    const countriesList = contries.map((country, i) => (
        <React.Fragment key={i}>
           <option value={country.code}>{country.name}</option>
        </React.Fragment>
     ));

    const toShowPassword = useCallback((show, type) => {
        if (type === "password") setShowPassword(show);
        else if (type === "password_confirmation") setShowConfirmPassword(show);
        },
        [setShowPassword, setShowConfirmPassword]
    );

    const onGlobalLangChange = (lan:any) => {
        changeLangauge(lan);
        setGlobalLan(lan);
    };

    const handleChangeGender = (e:any) => {
        setRadioButton({...radioButton, [e.target.name]: e.target.value });
    }


    const onRegisterUser = async (event:any) => {
        event.preventDefault();
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let _inputStates = { ...formState.inputValues };
        delete _inputStates.name;
        delete _inputStates.last_name;
        delete _inputStates.email;
        delete _inputStates.gender;
        delete _inputStates.password;
        delete _inputStates.password_confirmation;
        delete _inputStates.company ;
        delete _inputStates.title;

        if (formState.inputValues?.name === "") {
            addToast("Please enter your first name.", {
              appearance: "error",
              autoDismiss: true,
            });
            return;
        } else if (formState.inputValues?.last_name === "") {
            addToast("Please enter your last name.", {
              appearance: "error",
              autoDismiss: true,
            });
            return;
        } else if (formState.inputValues?.email === "") {
            addToast("Please enter your email address.", {
              appearance: "error",
              autoDismiss: true,
            });
            return;
        } else if (!formState.inputValues?.email || reg.test(formState.inputValues?.email) === false) {
            addToast("Please enter valid email address.", {
              appearance: "error",
              autoDismiss: true,
            });
            return;
        } else if (event?.password_only && formState.inputValues.password === "") {
            addToast("Please enter your password.", {
              appearance: "error",
              autoDismiss: true,
            });
            return;
        } else if (event?.password_only && formState.inputValues.password_confirmation === "") {
            addToast("Please enter your confirm password.", {
              appearance: "error",
              autoDismiss: true,
            });
            return;
        } else if (formState.inputValues.password !== formState.inputValues.password_confirmation) {
            addToast("Password did not matched.", {
              appearance: "error",
              autoDismiss: true,
            });
            return;
        } else if (event?.marketing_checkbox && !newsletter) {
            addToast("Plese Select Checkbox", {
              appearance: "error",
              autoDismiss: true,
            });
            return;
        }

        if (fields?.length > 0) {
            const isValid = fields?.filter(field => field?.type == 'riziv').reduce((prev, next) => {
                let riziv_encode = (rizivNumber?.replace(/\D+/g, ""));
                const isValidRiz =  riziv_encode?.length === 11
                if (isValidRiz){
                    _inputStates[next?.field] = Base64.encode(riziv_encode);
                }
                return prev && isValidRiz
            }, true)
            if (!isValid){
                addToast("Must Enter 11 Digits!", {
                    appearance: "error",
                    autoDismiss: true,
                });
                return 
            }
        }
        
        const data = {
            first_name: formState.inputValues.name,
            last_name: formState.inputValues.last_name,
            email: formState.inputValues.email,
            gender:radioButton?.gender,
            password: formState.inputValues.password,
            password_confirmation: formState.inputValues.password,
            country_code : country,
            company:formState.inputValues.company,
            title:formState.inputValues.title,
            extra: { ..._inputStates, newsletter },
            avatar: fileBase64String,
        };

        if (currentLanguage) {
            data["locale"] = currentLanguage;
        }

        try {
            setuserLogging(true);
            await onRegister(slug.path, data, (response:any) => {
            if (response?.status === "success") {
                changeLangauge(currentLanguage);
                addToast(response?.message, {appearance: "success", autoDismiss: true});
                onUserLoggedIn(response.user);
                onRegisterSuccess();
                setuserLogging(false);
            } else {
                addToast(response?.message, {appearance: "error", autoDismiss: true});
                setuserLogging(false);
            }   
        });

        } catch (error) {
            setuserLogging(false);
            addToast("Error occurred While Registering!.", {
                appearance: "error",
                autoDismiss: true,
                TransitionState: "exiting",
            });
            setuserLogging(false);
        }
    };

    const encodeFileBase64 = (file:any) => {
        var reader = new FileReader();
        if (file) {
          reader.readAsDataURL(file);
          reader.onload = () => {
            var Base64 = reader.result;
            setFileBase64String(Base64);
          };
          reader.onerror = (error) => {
          };
        }
    };

    const triggerInputFile = () => {
        fileInput.current.click();
    }

    const setAvatar = (e:any) => {
        encodeFileBase64(e.target.files[0]);
        setProfilePic(URL.createObjectURL(e.target.files[0]));
    };

    const onDateUpdate = useCallback((id, inputValue, inputValidity) => {
        const _date = moment(inputValue).format("Y-MM-DD");
        inputChangeHandler(id, inputValue, inputValidity);
    },[]);

    // api fields 
    let InputFields;
    let inputKeys = [];
    let fieldsRequired = {};

    const onDropDownChage = (value:any) => {
        formState.inputValues[value.key] = value.value;
    };

    if (fields?.length > 0) {
    InputFields = fields?.map((inputType:any, index:any) => {
        if (inputType.is_required) {
            fieldsRequired[inputType.field] = inputType.original;
        }
        if (inputType?.type === "date" && !formState.inputValues[inputType.original]) {
            formState.inputValues[inputType?.original] = new Date();
        }
        if (inputType?.type === "checkbox" && !formState.inputValues[inputType.original]) {
            formState.inputValues[inputType?.original] = false;
        }
        if (!inputKeys.includes(inputType?.field)) {
            inputKeys.push(inputType?.field);
        }
        return (
            <>
                {inputType?.type === "text" ? (
                    <Form.Control
                        key={index}
                        id={inputType?.field}
                        type={inputType?.field}
                        name={inputType?.field}
                        placeholder={`${inputType?.original} ${inputType?.is_required ? "*" : ""}`}
                    />
                ) : null}

                {inputType?.type === "radio" ? (
                    <div className="db-radio-check form-row">
                        {inputType?.values.split(";").map((option:any, i:any) => {
                        return (
                            <InputGroup.Radio 
                                key={i}
                                style={{ color: event?.color }}
                                id={inputType?.original}
                                name={inputType?.original}
                                value={option}
                                aria-label="Radio button for following text input" 
                            />
                        );
                        })}
                    </div>
                ) : null}

                {inputType?.type === "checkbox" && (
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            id={inputType?.original}
                            onClick={(e) => onCheckBoxChecked(inputType?.original,e.target.checked,true)}
                        />
                        <Form.Label htmlFor={inputType?.original}>
                            {inputType?.original}
                        </Form.Label>
                    </Form.Group>
                )}

                {inputType?.type === "date" && (
                    <div className="date-lable-format">
                        <Form.Label className="Reg-Date">{inputType?.original}</Form.Label>
                        <DatePicker
                            onChange={(value?:any) => {
                            const _value = moment(value).format("Y-M-D");
                                onDateUpdate(String(inputType?.original), value, true);
                            }}
                            value={formState.inputValues[inputType.original]}
                            className="form-control form-group mb-3 "
                        />
                    </div>
                )}

                {inputType?.type === "dropdown" && (
                    <Form.Group controlId="formFileMultiple" className="my-4">
                        <Form.Select onChange={(e:any) => onDropDownChage({
                                    key: inputType?.field,
                                    value: e.target.value,
                                })
                            }
                            className="form-control"
                            id={inputType?.original}
                        >
                            <option value="">{inputType?.original}</option>
                            {inputType?.values.split(";").map((option:any, i:any) => {
                            return (
                                <option
                                    key={i}
                                    {...(i === 0 ? "selected" : "")}
                                    value={option}
                                >
                                    {option}
                                </option>
                            );
                            })}
                        </Form.Select>
                    </Form.Group>
                )}

                {inputType?.type === "riziv" ? (
                    <>
                        <Form.Control
                            key={index}
                            id={inputType?.field}
                            name={inputType?.field}
                            type="text"
                            onChange={(event) => inputRizivChangeHandler(event.target.value)}
                            placeholder={`${inputType?.original} ${inputType?.is_required ? "* 00.00.00-000.00" : ""}`}
                            value={rizivNumber.replace(/(?!-)[^0-9.]/g, "")}
                        />
                        {details?.riziv_description && (<p className="riziv_fields_decription">{details?.riziv_description}</p>)}
                    </>
                ) : null}

            </>
        );
    });
    }

    let MarketingCheckbox;
    if (options?.includes('marketing_checkbox')) {
        MarketingCheckbox = (
            <>
                {details?.marketing_text ? (
                    <Form.Group controlId="formFileSm" className="mt-3 marketing-check">
                        <Form.Check type="checkbox" id="marketing" onClick={(e:any) => setNewsletter(e.target.checked)} value={newsletter} />
                        <Form.Label htmlFor="marketing">{details?.marketing_text}</Form.Label>
                    </Form.Group>
                ) : (
                    ""
                )}
                {details?.marketing_file && details?.marketing_file_title ? (
                    <Link target="_blank" to={{
                            pathname:
                                `${process.env.REACT_APP_IMAGE_WITH_STORAGE}/` +
                                details?.marketing_file,
                        }}
                        className="privacy-policy-link"
                    >
                    {details?.marketing_file_title}
                    </Link>) : ( ""  )}
            </>
        );
    }

 
    let RegisterForm;
    RegisterForm = details &&  JSON.parse(details?.field_order).map((type:any, key:any) => {
        switch (type){
            case "first_name":
                return (<Form.Control type="text" id="name" name="name" placeholder={namePlaceholder} value={formState.value} onChange={inputChangeHandler}/>);
            case "last_name":
                return (<Form.Control type="text" id="last_name" name="last_name" placeholder={lastnamePlaceholder} value={formState.value} onChange={inputChangeHandler}/>);
            case "email":
                return (<Form.Control type="email" id="email" name="email" placeholder={emailPlaceholder} value={formState.value} onChange={inputChangeHandler}/>);
            case "locale":
            return (
                options && options?.includes("show_locale_on_registration") && (<>
                    {languages && Object.keys(languages).length > 0 && (
                        <DropDown
                            selected={langauge}
                            classAdd={"form-control"}
                            items={languages}
                            onChange={(data:any) => { setCurrentLanguage(data); }}
                        />
                    )}
                </>)
            )
            case "password":
                return (
                    options && options?.includes("show_password_on_registration") && (<>
                        <div className="login_password">
                            <Form.Control
                                id="password" 
                                type={showPassword ? "text" : "password"}
                                name="password"
                                autoFocus={false}
                                value={formState.value}
                                onChange={inputChangeHandler}
                                placeholder={passwordPlaceholder}
                            />
                            <div onClick={() => toShowPassword(!showPassword,'password')} className="input-group-append">
                                {showPassword ? (<i style={{ color: details?.primary_color }}><FaEye/></i>)
                                : (<i style={{ color: details?.primary_color }}><FaEyeSlash/></i>)}
                            </div>
                        </div>
                        <div className="login_password">
                            <Form.Control
                                id="password_confirmation" 
                                type={showConfirmPassword ? "text" : "password"}
                                name="password_confirmation"
                                autoFocus={false}
                                value={formState.value}
                                onChange={inputChangeHandler}
                                placeholder={confirmPasswordPlaceholder}
                            />
                            <div onClick={() => toShowPassword(!showConfirmPassword,'password_confirmation')} className="input-group-append">
                                {showConfirmPassword ? (<i style={{ color: details?.primary_color }}><FaEye/></i>)
                                : (<i style={{ color: details?.primary_color }}><FaEyeSlash/></i>)}
                            </div>
                        </div>
                    </>)
            )
            case "company":
                return (
                    <>
                        {options && options?.includes("show_company_on_registration") && 
                        (<Form.Control
                            id="company"
                            name="company"
                            type="text"
                            onChange={inputChangeHandler}
                            placeholder={companyPlaceholder}
                        />)}
                    </>
                );
            case "title":
                return (
                    <>
                        {options && options?.includes("show_title_on_registration") && 
                        (<Form.Control
                            id="title"
                            name="title"
                            type="text"
                            onChange={inputChangeHandler}
                            placeholder={functionPlaceholder}
                        />)}
                    </>
                );
            case "country_code":
                return (
                    <>
                        {options && options?.includes("show_countries_on_registration") && 
                        (<Form.Select name="country" className="form-control" id="Country"  onChange={(e) => setCountry(e.target.value)} required>
                            <option value="" disabled selected>{countryLabel}</option>
                            {countriesList}
                        </Form.Select>)}
                    </>
                );
            case "role":
                return (
                    <>
                     {options && options?.includes("show_role_on_registration") && ( 
                        <Form.Control 
                            type="text" 
                            name="role"
                            placeholder={registrationTypePlaceholder}
                            onChange={inputChangeHandler}
                        />)}
                    </>
                );
            default:
                return null;
        }
    });
 
    const LanguageMenu =
    languages && Object.keys(languages).length > 0 ? (
      <div className="db-language-wrap ml-auto">
        <div className="form-goup">
          <DropDown
            classAdd={"custom-select"}
            items={languages}
            selected={langauge}
            onChange={(data:any) => onGlobalLangChange(data)}
          />
        </div>
      </div>
    ) : (
      ""
    );

    let checkCol = isAvatarSelectionEnable && isGenderEnable ? true : false;

   return (
      <>
      <div className="login_area signup_area">
        <div className="languages_btn mb-100 d-flex justify-content-end">
            {/* <button type="button" className="btn">English</button> */}
            {LanguageMenu}
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-8 m-auto">
                {isWelcomeWidget && !isPopUp && (
                <div className="d-flex justify-content-center mt-10 welcome-widget">
                    <p>{ReactHtmlParser(details?.welcome_widget ?? '')}</p>
                </div>)}
                </div>
            </div>
            <div className="login_area_inner">
                <div className="login_logo_box">
                    <div className="logo_img">
                        <Image src={details?.img_logo_desktop} placeholderSrc="/images/logo-placeholder.png" alt="Registration page logo" className="img-fluid d-none d-md-block"/>
                        <Image src={details?.img_logo_mobile} placeholderSrc="/images/logo-placeholder.png" alt="Registration page logo" className="img-fluid d-md-none"/>
                    </div>
                    <h4>{event?.title}</h4>
                </div>
                <Form noValidate validated={validated}  className="register-form" onSubmit={(e) => onRegisterUser(e)}>
                    <div className="login_content_box">
                        <div className="row">
                           {isAvatarSelectionEnable || isGenderEnable ? (<div className="col-md-4 col-lg-4">

                                {isAvatarSelectionEnable && (
                                    <div className="profile_change_image">
                                        <img alt="user image" className="img-fluid" src={profilePic ? profilePic : fileUpload} />
                                        <Form.Control hidden onChange={(e) => setAvatar(e)} id="photo-upload" type="file" ref={fileInput} accept="image/*" />
                                        <Button onClick={() => triggerInputFile()} type="button" className="btn btn_change">Change</Button>
                                    </div>
                                )}

                               {isGenderEnable && (<div className="profile_gender_box mt-60">
                                    <div className="poll_check">
                                        <Form.Group  className="form-group" controlId="formBasicCheckbox">   
                                            <Form.Check 
                                                type="radio" 
                                                id="Male"
                                                name="gender"
                                                value="male"
                                                onChange={handleChangeGender}
                                                label="Male"
                                            />
                                        </Form.Group>
                                        <Form.Group className="form-group" controlId="formBasicCheckbox">
                                            <Form.Check
                                                type="radio" 
                                                id="Female "
                                                name="gender"
                                                value="female"
                                                onChange={handleChangeGender}
                                                label="Female"
                                            />
                                        </Form.Group>
                                        <Form.Group className="form-group" controlId="formBasicCheckbox">
                                            <Form.Check 
                                                type="radio" 
                                                id="Other"
                                                name="gender"
                                                value="other"
                                                onChange={handleChangeGender}
                                                label="Other"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>)}
                            </div>) : null}

                            <div className={`col-md-8 col-lg-8 ${checkCol ? "" : "mx-auto"}`}>
                                <div className="signup_form">
                                    {RegisterForm}
                                    {InputFields}
                                    {MarketingCheckbox}
                                    <div className="login_btn mt-40">
                                            {userLogging ? (
                                            <a href="#" className="btn vw-btn register-btn vw-btn-white mb-3 cmn-text-color w-50 registration-btn">
                                                <i aria-hidden="true" className={"fa fa-spinner fa-spin mr-3 text-black"}></i>
                                                <span className="text-black">Loading...</span>
                                            </a>
                                            ) : (
                                            <>
                                                <CustomButton type="submit" href="#"
                                                    onClick={onRegisterUser}
                                                    className={`btn btn_border_orange`}
                                                    name={translations?.registration?.watch_now_button}
                                                />
                                            </>
                                            )}
                                            <Link to={slug.path+'/login'}>
                                              <button type="button" className="btn btn_theme_white">or Login</button>
                                            </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    { event?.support && <SupportScript script={event?.support}/>}
    {options?.includes('faq') ? <button type='button' className='btn LeftIcon' data-toggle="modal" data-target="#showFaq">
        <FAQIcon FaqClass={"faqWhite"}/>
    </button> : null}
    <FaqModal translations={translations}/>
        {/* <!-- Modal --> */}
        {isWelcomeWidget && isPopUp ? (<WelComeWidget welcome_widget_content={details?.welcome_widget} />) : null}
   </>
   )
}

export default Register2