import React, { useEffect, useRef } from 'react';
import {OnlineOfflineUser} from "../Util/Utils";

export const SupportScript = ({ script, user }) => {
    const scriptRef = useRef(null);


    function removeTags(str) {
        if (script) {
            const ele = script.replace(/(<([^>]+)>)/ig, '');

            let scriptTag = document.getElementById('script');
            if (!scriptTag) {
                scriptTag = document.createElement('script');
                scriptTag.id = 'supportScript';
                scriptTag.innerHTML = ele;
                document.body.appendChild(scriptTag);
            }
        }
    }

    if (user){
        const _u = OnlineOfflineUser(user)
        window.Tawk_API= window.Tawk_API||{};
        window.Tawk_API.visitor = {
            name : _u.getFullName(),
            email : _u.getEmail()
        };
    }


    useEffect(() => {
        removeTags(script);
    }, []);

    return (
        <div className={"support-js"} ref={scriptRef}>
        </div>
    )
};

export default SupportScript;