import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import {Image} from "../../../../../UIComponets/Image";
import ReactHtmlParser from "react-html-parser";
import Logger from "../../../../../Util/Logger";
import { renderToString } from 'react-dom/server'

const Content = ({isActive, contentId, imageSrc, embed, fallback_image, data}) => {
    const ref = useRef()
    useEffect(() => {
        if (isActive){
            window.$(ref.current).empty()
            if (isActive && !fallback_image){
                window.$(ref.current).append(embed)
            }else if (fallback_image){
                const str = renderToString(<Image src={imageSrc}/>);
                window.$(ref.current).append(str)
            }
        }
        return () => {
            Object.keys(window.dacast?.players ?? {}).forEach(k => {
                const player = window.dacast?.players[k]
                player?.stop()
                player?.dispose()
                delete window.dacast?.players[k]
            })
            window.$(ref.current).empty()
        }
    }, [embed, isActive, fallback_image])

    return <div className={`tab-pane ${isActive ? "active" : ""}`} id={`CH-${contentId}`} role="tabpanel">
        <div ref={ref} className="live_room_inner">
        </div>
    </div>
}

export const  LiveContent = ({activeId}) => {
    const {
        state: { eventsAllPagesData : {channels}}
    } = useContext(defaultContext)

    const [active, setActive] = useState(activeId)
    useEffect(() => {
        // Logger.verbose(JSON.stringify(window.$(".vp-controls .play")))
        // window.$('.vjs-play-control').click()
        // window.$().click()
window.stopVimeoVideo("#CH-" + active)
        setActive(activeId)
    }, [activeId])

    return <div className="tab-content">
        {channels?.filter(i => i.status)?.map( (data, index) => <Content embed={data?.embed} key={index} isActive={activeId ? activeId === data.id : index === 0} data={data} contentId={data.id} imageSrc={data.image} fallback_image={data?.fallback_image} />)}
    </div>
}
