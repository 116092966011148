import React,{useContext} from 'react';
import Happing from "./Component/Happing";
import NowLive from "./Component/NowLive";
import ActiveSessions from "./Component/ActiveSessions";
import SlideDecks from "./Component/SlideDecks";
import Sponsors from "./Component/Sponsors";
import { EventApi } from '../../../../../hooks/shared/EventApi';

const HomePage = () => {

    const {
      state:{
        options,
        sponsors
      },
    } = EventApi();

    const defaultComp =  <>
      <Happing/>
      <NowLive/>
      <ActiveSessions/>
      {options && options?.includes('slide_decks') ? (<SlideDecks/>) : null}
      {options && options?.includes('sponsors') ? (<Sponsors sponsors={sponsors}/>) : null}
      
    </>

    return (defaultComp);
}

export default HomePage;
