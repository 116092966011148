import React from "react";

export const MobileConnectionRequestModel = () => {
    return <div className="modal fade mobile_chat_model mobile_connection_request" id="m_connection_request">
        <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="connection_request_mbody">
                    <h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27.793" height="23.919" viewBox="0 0 27.793 23.919">
                            <path id="Icon_awesome-hands-helping" data-name="Icon awesome-hands-helping" d="M21.192,8.971h-6.6v2.616a3.256,3.256,0,0,1-3.127,3.364,3.256,3.256,0,0,1-3.127-3.364V5.906L5.519,7.728a3.036,3.036,0,0,0-1.351,2.56V12.5L.694,14.657A1.555,1.555,0,0,0,.186,16.7L3.66,23.174a1.338,1.338,0,0,0,1.9.547l4.49-2.789h5.932a2.893,2.893,0,0,0,2.779-2.99h.695a1.444,1.444,0,0,0,1.39-1.5v-2.99h.347a1.081,1.081,0,0,0,1.042-1.121V10.092A1.081,1.081,0,0,0,21.192,8.971Zm6.414-1.747L24.132.748A1.338,1.338,0,0,0,22.234.2l-4.49,2.789H13.305a2.656,2.656,0,0,0-1.472.453l-1.455.976a1.514,1.514,0,0,0-.651,1.266v5.9a1.806,1.806,0,0,0,1.737,1.869A1.806,1.806,0,0,0,13.2,11.587V7.476h7.991a2.531,2.531,0,0,1,2.432,2.616v1.332L27.1,9.265A1.56,1.56,0,0,0,27.606,7.224Z" transform="translate(0.002 -0.002)" fill="#f26334"/>
                        </svg>
                        Connection Request
                    </h1>
                    <div className="people_network_box main-card">
                        <div className="p_network_b_inner">
                            <div className="img_box">
                                <img src="/images/metting_img.png" />
                                <span className="dot"></span>
                            </div>
                            <div className="info_box">
                                <h4>
                                    Brit Hansen

                                </h4>
                                <p><span>Torfs</span> Marketing Manager</p>

                                <div className="info_social_btn">
                                    <button type="button" className="btn"><img src="/images/indeed_grey.png"/></button>
                                    <button type="button" className="btn"><img src="/images/twitter_grey.png"/></button>
                                    <button type="button" className="btn"><img src="/images/facebook_grey.png"/></button>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                        </div>
                        <textarea className="form-control" rows="5" id="comment" className="form-control">Message *</textarea>
                        <div className="calling-btn">
                            <button type="send" className="btn btn_border_orange">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.808" height="20.13" viewBox="0 0 21.808 20.13">
                                    <path id="Icon_ionic-md-send" data-name="Icon ionic-md-send" d="M3.375,24.63,25.183,14.565,3.375,4.5v7.828L19,14.565,3.375,16.8Z" transform="translate(-3.375 -4.5)" fill="#454545"/>
                                </svg>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}