import React from 'react';
import { Link } from 'react-router-dom';
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';
import { FaFacebookSquare,FaTwitterSquare,FaLinkedin,FaGlobe } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";

const SponsorsLogo = ({sponsorDetails}) => {
    return (
        <>
            <div className="sd_brand_area mb-20">
                <img alt={sponsorDetails?.name} src={imageWithStorage + sponsorDetails?.logo}/>
            </div>
            <div className="sd_socail_box mb-20">
                {sponsorDetails?.meta?.linkedin ? (<a href={sponsorDetails?.meta?.linkedin} target={'_blank'}><FaLinkedin/></a>): null}
                {sponsorDetails?.meta?.twitter ? (<a href={sponsorDetails?.meta?.twitter} target={'_blank'}><FaTwitterSquare/></a>) : null}
                {sponsorDetails?.meta?.facebook ? (<a href={sponsorDetails?.meta?.facebook} target={'_blank'}><FaFacebookSquare/></a>) : null}
                {sponsorDetails?.meta?.website ? (<a href={sponsorDetails?.meta?.website} target={'_blank'}><FaGlobe/></a>) : null}
            </div>
            <div className="sponsors_details_description  mobile_view">
                <p>{ReactHtmlParser(sponsorDetails?.description ?? '')}</p>
            </div> 
        </>
    )
}

export default SponsorsLogo;
