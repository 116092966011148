import React, {useEffect, useRef, useState} from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import VideoVaultDetails from '../../../Event/Components/Pages/VideoVault/VideoVaultDetails';
import {VodApi} from "../../../../hooks/shared/VodApi";
import {Translations} from "../../../../hooks/shared/Translations";
import {VideoItem} from "./VideoItem";
import {ShimmerPostDetails} from "react-shimmer-effects";
import {OnePageModalWrapper} from "../../../../UIComponets/Modal/OnePageModalWrapper";
import VodCat from '../../../Event/Components/Pages/VideoVault/VodCat';
import VodList from '../../../Event/Components/Pages/VideoVault/VodList';
import {ShimmerTitle, ShimmerPostList} from "react-shimmer-effects";
import { EventApi } from '../../../../hooks/shared/EventApi';
import { Constants } from '../../../../classes/Constants';
import { SizeUtils } from '../../../../hooks/shared/SizeUtils';
import { SearchIcon } from '../../../../SVG/Icons';
import { IconLoader } from '../../../../UIComponets/IconLoader';

const VideoVoult = () => {
    const ServicesRef = useRef(null);
    const {isMobile} = SizeUtils()
    const vodDetailsComponentRef = useRef(null);

    const {
       getVodData,
       state: {vod_categories, vods}
    } = VodApi()

    const {
        state:{event, details}
    } = EventApi();

   const {
       translations
   } = Translations()

    const [isVideoVimeo, setVideoVimeoId] = useState(0);
    const [vodsItems, setVodsItems] = useState(vods);
    const [vodsCats, setVodsCats] = useState(vod_categories);
    const [selectedVod, setSelectedVod] = useState(null);

    useEffect(() => {
        if (vods) {
            setVodsItems(vods);
        }
    }, [vods]);

    useEffect(() => {
        getVodData()
    }, [])

    useEffect(() => {
        if (vod_categories) {
            setVodsCats(vod_categories);
        }
    }, [vod_categories]);

   const [active, setActive] = useState("all");

   const filterVideoVault = (categorieId) => {
        setActive(categorieId);
        if(categorieId == "all"){
           return setVodsItems(vods);
        }
        const updateVideoVault = vods && vods.filter((currentVods) => {
            return currentVods.category === categorieId;
        });
        setVodsItems(updateVideoVault);
    }

   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
       if (selectedVod){
           setIsLoading(true)
           vodDetailsComponentRef?.current?.fetchVod(selectedVod, (res, error) => {
               setIsLoading(false)
           });
       }
   }, [selectedVod]);

   const searchVideoVault = (query) => {
        if(query !== ''){
            const updatedList = vods && vods.filter((item) => {
                return item?.title.toLowerCase().includes(query.toLowerCase());
            });
            setVodsItems(updatedList);
        }else{
            setVodsItems(vods);
        }
    }   

   
   const onSetVideoVimeoId = (videoVimeoId) => {
        setVideoVimeoId((prevState) => ({
            ...prevState, videoVimeoId: videoVimeoId, 
        }));
    }

    
    const countItems = (ScreenWidth) => {
        let vodsDetailsItemLength= vodsItems?.length ?? 0;
        if(ScreenWidth >= 1200){
           return vodsDetailsItemLength > 4 ? true : false;
        }
        else if(ScreenWidth < 992 && ScreenWidth > 768){
           return vodsDetailsItemLength > 3 ? true : false;
        }
        else if(ScreenWidth < 768 && ScreenWidth > 400){
           return vodsDetailsItemLength > 2 ? true : false;
        }
        else if(ScreenWidth <= 400){
           return vodsDetailsItemLength > 1 ? true : false;
        }
    }

    const options = {
        margin: 20,
        responsiveClass: true,
        nav:countItems(window.innerWidth),
        dots: false,
        loop:countItems(window.innerWidth),
        autoplay:countItems(window.innerWidth),
        autoplayTimeout:3000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,

            }
        },
    };

    return (
        <>
         <section id="vod">
            <div className="s_video_vault_area pt-50">
               <div className="container">
                  <h1 className="internal_main_heading">VIDEO VAULT</h1>
                    <div className="video_vault_category mb-30">
                     {/* <ul className="nav">
                        <li className={active === "all" ? "active" : ""} onClick={() => filterVideoVault("all") }>
                           <Link to={"#"}>{translations?.general?.all}</Link>
                        </li>
                        {
                            vodsCats?.map(v => <li onClick={()=> filterVideoVault(v?.id)} > {v?.name} </li>)
                        }
                     </ul> */}
                     {vodsCats ? vodsCats?.length > 0 ? (<VodCat
                            filterVideoVault={filterVideoVault} 
                            vodsCat={vodsCats}
                            isActive={active}
                            translations={translations}
                        />) : null : (<ShimmerTitle line={1} gap={10} variant="primary"/>)}
                    </div>
                
                    <div className="video_vault_slider_top">
                        <OwlCarousel className='owl-carousel owl-theme video_vault_slider_top' {...options}>
                            {
                                vodsItems?.map((v, i) =>  <VideoItem key={i} {...v} translations={translations} onClick={() => setSelectedVod(v)}/>)
                            }
                        </OwlCarousel>
                    </div>
                  <div className="onepage_vod_mobile">
                        <div className="searchbar_right">
                            <input 
                                type="text" 
                                placeholder="Search.." 
                                name="search" 
                                className="form-control" 
                                onChange={(e) => searchVideoVault(e?.target?.value)}
                            />
                            <button type="submit"><IconLoader isLoading={isLoading}><SearchIcon/></IconLoader></button>
                        </div>

                        <div className='onepage-video-vault-box'>
                            {!isLoading ? vodsItems?.length > 0 ? (<div className="row" style={{position:"relative"}} ref={ServicesRef}>
                            <VodList vodsItem={vodsItems} onSetVideoVimeoId={onSetVideoVimeoId} translations={translations} event={event}/>
                            </div>)
                            : (<div className='col text-center'><span>{details?.no_vod_message ?? Constants.NO_DATA_AVAILABLE}</span></div>)
                            : (<ShimmerPostList postStyle="STYLE_FOUR" col={isMobile ? 1 : 4} row={2} gap={30} />) }
                        </div>
                    </div>
                </div>
            </div>
         </section>

        <OnePageModalWrapper className={"video_vault_details_model"} id={"videovault-details"}>
            <VideoVaultDetails item={selectedVod} ref={vodDetailsComponentRef}/>
        </OnePageModalWrapper>
        </>
    )
}

export default VideoVoult
