import React,{useState, useEffect} from 'react';
import { LinkIcon } from '../../../../../../SVG/Icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactHtmlParser from "react-html-parser";
import { Translations } from '../../../../../../hooks/shared/Translations';
import { Image } from '../../../../../../UIComponets/Image';
import useSlug from '../../../../../../hooks/useSlug';
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';
import {SessionModel} from "../../../../../../Model/SessionModel";
import { SessionApiV2 } from '../../../../../../hooks/shared/SessionApi2';
import { URLContants } from '../../../../../../Model/URLContants';

const InitialSessionMap = () => {
    return {keys: [], map: {}}
}
const UserSessionMobile = ({sessions, isActiveEu, timezone, onClick, favorite, usersSessionCat, usersSessionRegistered, event, setReplay}) => {
    const {
        getZoneTime
    } = SessionApiV2()

    const getTime = (time) => {
        return  getZoneTime(isActiveEu, time)
    };
    const {translations} = Translations();

    const map = usersSessionCat && usersSessionCat?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <h1 style={{background:current?.color}} className="mb-40">{current?.name.toUpperCase()}</h1>
        }
    }, {});

    const mapRegistered = usersSessionRegistered && usersSessionRegistered?.reduce((prev, current) => {
        return {
            ...prev,
            [current]: true
        }
    }, {});

    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;


    const [sessionMap, setSessionMap] = useState(InitialSessionMap());
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (sessions) {
            const map = sessions?.reduce((prev, next) => {
                const obj = {...prev}
                const key = next.date
                if (next.date){
                    if (!obj.map[key]){
                        obj.map[key] = []
                        obj.keys.push(key)
                    }
                    obj.map[next.date].push(next)
                }
                return obj
            }, InitialSessionMap()) ?? InitialSessionMap()
            setSessionMap(map)
            if (!selectedDate){
                setSelectedDate(map?.keys?.first());
            }
        }
    }, [JSON.stringify(sessions)]);

    return (
        <>
        {sessionMap?.map && Object.keys(sessionMap?.map).map(item => {  
        return (<>
            <h4 className="date_heading mt-20">{moment(`${item}`).format("DD MMM") }</h4>
            {sessionMap?.map[item].map(i => {
                const session = SessionModel(i);
                session.setRegistered(mapRegistered[i.id] ? true : false)
                return session
            }).map((session, index) => {
                return (<div className="mobile_accordion_item" key={index}>
                            <div className="m_ed_time_box">
                                <div className="time_box">
                                    <p>{getTime(session?.start)}<br/>{getTime(session?.end)}</p>
                                </div>
                                <div className="mobile_accordion_header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={`#collapseOne_${session?.id}`} aria-expanded="true" aria-controls="collapseOne">
                                        <span className="coll_header">{session?.title && session?.title}…</span>
                                        <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                                    </button>
                                </div>
                            </div>
                            <div id={`collapseOne_${session?.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="session_info_box">
                                    <div className="emerging_sectors mt-40">
                                        {map[session?.category_id]}
                                        <p>{ReactHtmlParser(session?.detail ?? '')}</p>
                                    </div>
                                    {session?.people?.length > 0 ? (<div className="row">
                                        {session?.people && session?.people.map((person , index) => {
                                            return (
                                            <div className="col-md-6 col-lg-6" key={index}>
                                                <div className="client_info mb-30">
                                                    <div className="client_info_img">
                                                        <Image src={person?.user?.avtar} placeholderSrc={"/images/accpted_img1.png"} alt={"people pic"}/>
                                                    </div>
                                                    <div className="client_info_content">
                                                        <h4>
                                                            <Link to={Path(URLContants.SPEAKERS)+`?search=${person?.user?.last_name}`}> {person?.user?.name} {person?.user?.last_name}</Link>
                                                        </h4>
                                                        <p>{person?.user?.title}</p>
                                                        <h6>{person?.user?.company}</h6>
                                                    </div>
                                                </div>
                                            </div>)
                                        })}
                                    </div>) : null}
                                    <div className="session_info_register_area">
                                        <ul className="nav icon">
                                            <li>
                                                {session?.meta?.external_link ? (<a href={session?.meta?.external_link} target={"_blank"}><LinkIcon/></a>) : "" }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 col-xl-4"> 
                                            {session?.files?.length > 0 ? (
                                                <div className="session_info_file">
                                                    <h2>{translations?.general?.files}</h2>
                                                    <ul className="list-group">
                                                        {session.files && session.files.map((file, index)=>{
                                                            return(
                                                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="file_name">{file?.filename}</span>
                                                                    <a href={file && imageWithStorage+file?.path} target="_blank" rel="noopener noreferrer" download>
                                                                        <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                                            <g id="Group_1947" data-name="Group 1947">
                                                                                <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                                                    <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                                                </g>
                                                                                <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                                            </g>
                                                                            </svg>
                                                                        </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>) 
                                            : null} 
                                            {session?.files?.length > 1 && (
                                                <a href={`${process.env.REACT_APP_API_HOST}/v2.0/zip-download/${event?.id}?module_id=${session?.id}&type=timetable`} target="_blank" className="text-dark">
                                                    <span className="mr-2">
                                                        <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                        <g id="Group_1947" data-name="Group 1947">
                                                        <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                        <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                        </g>
                                                        <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                        </g>
                                                        </svg>
                                                    </span>
                                                    <span> Download All Files </span>
                                                </a>
                                            )}   
                                        </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </>)
            })}
        </>)
    }

export default UserSessionMobile
