import React, { useContext, useState, useEffect} from 'react';
import { FaMicrophone } from "react-icons/fa";
import $ from 'jquery';
import { Image } from '../../../../../../UIComponets/Image';
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import useSlug from '../../../../../../hooks/useSlug';
import { VideoCallApi } from "../../../../../../hooks/videoCallApi";
import { CancelIconConn, RightIconConn } from '../../../../../../SVG/Icons';
import { IconLoader } from '../../../../../../UIComponets/IconLoader';
import NotificationCenter from "../../../../../../hooks/NotificationCenter";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {Error} from "../../../../../../Util/Utils";
import {useToasts} from "react-toast-notifications";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import { ConnectionStatus } from '../../../../../../Model/ConnectionStatus';
import ApprovingConfirmPopup from './ApprovingConfirmPopup';
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa";

const PeopleCard = ({ people, isShowOffline, onClick }) => {
     const { addToast } = useToasts();
    const slug = useSlug().path;
    const {
        onlineUsers
    } = VideoCallApi();

    const {
        approvingConnection,
    } = useContext(defaultContext);

    // const [peoples, setPeoples] = useState(people);
    const membersMap = onlineUsers?.membersMap;
   
    const users = people?.map(p => {
        return {
            ...p,
            isOnline: membersMap && membersMap[p?.id] ? true : !isShowOffline
        }
    })

    const [approving, setApproving] = useState(false);
    const [isStatus, setIsStatus] = useState(0);
    const [connectId, setConnectId] = useState(null);
    const [connectStatus, setConnectStatus] = useState(null);
    // handle Approving or Disapproving
    const handleApproving = (connect_id, status) => {
        setConnectId(connect_id);
        setConnectStatus(status);
    }

    useEffect(() => {
        NotificationCenter.sharedInstance().registerNotification('connectionStatusUpdate', (data) => {
            // console.log("plople=====");  
            // console.log(data);  
        })
    })

    const approvingRequestSend = (connect_id, people_id, status) => {
        if(connect_id && status){
            setApproving(true);
            setIsStatus(status);
            approvingConnection(slug, connect_id, status, (response, error) => {
                if (response) {
                    const wrapper = ResponseWrapper(response)
                    if (wrapper.getStatus()) {
                        const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                        // let index = users.findIndex(t => t?.id === people_id);
                        // if (index !== -1) {
                        //     users[index].connection = wrapper.getData()?.connection;
                        // }
                        if(wrapper.getData().connection?.status === 1){
                            addToast("connection approved successfully.", {appearance, autoDismiss});
                        }
                        if(wrapper.getData().connection === null){
                            addToast("connection disapproved successfully.", {appearance, autoDismiss});
                        } 
                    }
                window.$('#approving-model').modal('hide');
                }else if (error){
                    const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                    addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                }
                setApproving(false);
                setIsStatus(0);
            });
        }
    }

    const approvModalClose = () => {
        window.$('#approving-model').modal('hide');
    }

    if (!users.length && !isShowOffline) {
        return <p>No users online!</p>
    }

    return (
        <>
            {users && users?.map((person, index) => {
                return (
                    <div className="col-md-6 col-lg-4 col-xl-3" key={index}>
                        <div className="people_network_box main-card">
                            <div className="p_network_b_inner">
                                <div className="img_box" onClick={() => onClick(person)}>
                                    <Image src={person?.avtar ? person?.avtar : person?.avatar} placeholderSrc={"/images/placeholder_square.jpg"} alt={'network people'} />
                                    {person?.isOnline ? <span className="dot"></span> : ""}
                                </div>
                                <div className="info_box">
                                    <h4 onClick={() => onClick(person)} title={`${person?.name} ${person?.last_name}`}>
                                        {person?.name} {person?.last_name}
                                        {person?.role === "SPEAKERS" && (<FaMicrophone />)}
                                    </h4>
                                    {person?.company && (<p title={person?.company}>{person?.company}</p>)}
                                    {person?.title && (<h6 title={person?.title}>{person?.title}</h6>)}
                                    {person?.info && (<div className="info_social_btn">
                                           {person?.info?.linkedin_link && (<button type="button" className="btn"><a href={person?.info?.linkedin_link} target={'_blank'}><FaLinkedin/></a></button>) } 
                                           {person?.info?.twitter_link && (<button type="button" className="btn"><a href={person?.info?.twitter_link} target={'_blank'}><FaTwitterSquare/></a></button>) } 
                                           {person?.info?.facebook_link && (<button type="button" className="btn"><a href={person?.info?.facebook_link} target={'_blank'}><FaFacebookSquare/></a></button>) } 
                                        </div>)}
                                </div>
                            </div>
                            <div className="peaple_network_footer">
                                <ul className="people_network_btn">
                                    {person?.connection?.sender_id === person?.id ?
                                        (<> 
                                           {person?.connection?.status === ConnectionStatus.PENDING && (<li><button className="btn btn_theme_orange p-0">CONNECTION REQUEST</button></li>)}
                                           {person?.connection?.status === ConnectionStatus.PENDING && (<li><button className="btn p-0" data-toggle="modal" data-target="#approving-model" onClick={() => handleApproving(person?.connection?.id,1)}><IconLoader isLoading={approving && isStatus === ConnectionStatus.CONNECTED}><RightIconConn/></IconLoader></button></li>)}
                                           {person?.connection?.status === ConnectionStatus.PENDING && (<li><button className="btn p-0" data-toggle="modal" data-target="#approving-model" onClick={() => handleApproving(person?.connection?.id,2)}><IconLoader isLoading={approving && isStatus === ConnectionStatus.DECLINED}><CancelIconConn/></IconLoader></button></li>)}
                                           {person?.connection?.status === ConnectionStatus.CONNECTED && (<li><button className="btn btn_theme_orange p-0">CONNECTED</button></li>)}
                                        </>) : null
                                    }

                                    {person?.connection?.receiver_id === person?.id ?
                                        (<>
                                            {person?.connection?.status === ConnectionStatus.PENDING && (<li><button className="btn btn_theme_orange p-0">CONNECTION PENDING</button></li>)}
                                            {person?.connection?.status === ConnectionStatus.CONNECTED && (<li><button className="btn btn_theme_orange p-0">CONNECTED</button></li>)}
                                        </>) : null
                                    }
                                    {/* 
                                     <li>
                                        <button className="btn p-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.01" height="15.27" viewBox="0 0 18.01 15.27"><path id="Icon_awesome-hands-helping" data-name="Icon awesome-hands-helping" d="M13.731,5.728H9.454V7.4A2.092,2.092,0,0,1,7.428,9.545,2.092,2.092,0,0,1,5.4,7.4V3.771L3.576,4.934A1.931,1.931,0,0,0,2.7,6.569V7.98L.449,9.358a.984.984,0,0,0-.329,1.3L2.371,14.8a.874.874,0,0,0,1.23.349l2.91-1.781h3.844a1.859,1.859,0,0,0,1.8-1.909h.45a.928.928,0,0,0,.9-.954V8.591h.225a.7.7,0,0,0,.675-.716V6.443A.7.7,0,0,0,13.731,5.728Zm4.156-1.116L15.636.478a.874.874,0,0,0-1.23-.349L11.5,1.91H8.621a1.739,1.739,0,0,0-.954.289l-.943.623a.962.962,0,0,0-.422.808V7.4a1.128,1.128,0,1,0,2.251,0V4.773h5.178a1.626,1.626,0,0,1,1.576,1.67v.85l2.251-1.378A.987.987,0,0,0,17.888,4.612Z" transform="translate(0.002 -0.002)" fill="#454545"></path></svg>
                                            </button>
                                        </li>
                                    <li>
                                        <button className="btn p-0">
                                            <svg id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" xmlns="http://www.w3.org/2000/svg" width="15.037" height="15.037" viewBox="0 0 15.037 15.037"><path id="Path_1272" data-name="Path 1272" d="M19.553,13.229a.876.876,0,0,1,.119-.441,1.217,1.217,0,0,1,.076-.112,5.857,5.857,0,0,0,1-3.264,6.149,6.149,0,0,0-6.279-6.037,6.236,6.236,0,0,0-6.156,4.8,5.809,5.809,0,0,0-.134,1.236,6.132,6.132,0,0,0,6.181,6.113,7.444,7.444,0,0,0,1.706-.278c.408-.112.813-.26.918-.3a.956.956,0,0,1,.336-.061.94.94,0,0,1,.365.072l2.05.727a.489.489,0,0,0,.141.036.288.288,0,0,0,.289-.289.464.464,0,0,0-.018-.1Z" transform="translate(-5.708 -3.375)" fill="#454545"></path><path id="Path_1273" data-name="Path 1273" d="M13.153,20.457c-.13.036-.3.076-.477.116a6.671,6.671,0,0,1-1.229.163,6.132,6.132,0,0,1-6.181-6.113,6.834,6.834,0,0,1,.054-.774c.022-.155.047-.311.083-.463s.08-.325.127-.484l-.289.257A5.379,5.379,0,0,0,3.375,17.2a5.319,5.319,0,0,0,.9,2.964c.083.127.13.224.116.289s-.43,2.241-.43,2.241a.29.29,0,0,0,.1.278.294.294,0,0,0,.184.065.259.259,0,0,0,.1-.022l2.028-.8a.565.565,0,0,1,.434.007A6.084,6.084,0,0,0,9,22.662a5.677,5.677,0,0,0,4.341-1.992s.116-.159.249-.347C13.457,20.37,13.3,20.417,13.153,20.457Z" transform="translate(-3.375 -8.004)" fill="#454545"></path></svg>                                            
                                        </button>
                                    </li>
                                    <li>
                                        <button className="btn p-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.627" height="12.141" viewBox="0 0 18.627 12.141"><path id="Icon_ionic-md-videocam" data-name="Icon ionic-md-videocam" d="M16.886,12.287V8.745a1.025,1.025,0,0,0-1.035-1.01H3.285A1.025,1.025,0,0,0,2.25,8.745V18.861a1.028,1.028,0,0,0,1.035,1.015H15.85a1.028,1.028,0,0,0,1.035-1.01V15.322l3.992,4.046V8.242Z" transform="translate(-2.25 -7.734)" fill="#454545"></path></svg>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="btn p-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.282" height="15.04" viewBox="0 0 13.282 15.04"><path id="Icon_awesome-calendar-plus" data-name="Icon awesome-calendar-plus" d="M12.927,4.7H.356A.355.355,0,0,1,0,4.347V3.29A1.417,1.417,0,0,1,1.423,1.88H2.846V.352A.355.355,0,0,1,3.2,0H4.388a.355.355,0,0,1,.356.352V1.88H8.539V.352A.355.355,0,0,1,8.894,0H10.08a.355.355,0,0,1,.356.352V1.88h1.423a1.417,1.417,0,0,1,1.423,1.41V4.347A.355.355,0,0,1,12.927,4.7ZM.356,5.64H12.927a.355.355,0,0,1,.356.352V13.63a1.417,1.417,0,0,1-1.423,1.41H1.423A1.417,1.417,0,0,1,0,13.63V5.992A.355.355,0,0,1,.356,5.64ZM9.725,9.752A.355.355,0,0,0,9.369,9.4H7.59V7.637a.355.355,0,0,0-.356-.352H6.048a.355.355,0,0,0-.356.352V9.4H3.914a.355.355,0,0,0-.356.352v1.175a.355.355,0,0,0,.356.352H5.692v1.762a.355.355,0,0,0,.356.352H7.234a.355.355,0,0,0,.356-.352V11.28H9.369a.355.355,0,0,0,.356-.352Z" fill="#454545"></path></svg>
                                        </button>
                                    </li> */}
                                </ul>
                                <div className="more_btn">
                                    <button type="button" className="btn btn_border_orange" onClick={() => onClick(person)}>MORE</button>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            })}
            {/* model connection request*/}
            <ApprovingConfirmPopup requestSend={approvingRequestSend} connectId={connectId} status={connectStatus} close={approvModalClose}/>
        </>
    )
}

export default PeopleCard;
