import React,{useState, useContext, useRef, useEffect} from 'react';
import { Link,NavLink, useLocation } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import useSlug from '../../../../../hooks/useSlug';
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import { Image } from '../../../../../UIComponets/Image';
import {URLContants} from "../../../../../Model/URLContants";
import {changeLangauge, Context as languageContext} from "../../../../../context/LanguageContext";

import {
    Home,
    LiveIcon,
    VideoVaultIcon,
    SessionsIcon,
    SlideDecksIcon,
    SpeakersIcon,
    BreakoutRoomIcon,
    SponsorsIcon,
    SocialWallIcon,
    NetworkIcon,
    DailyTriviaIcon
} from "../../../../../SVG/Icons";

import {
    ShimmerCircularImage,
    ShimmerTitle,
} from "react-shimmer-effects";

import { MdLogin } from "react-icons/md";
import { FiBell } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { FaQuestion } from "react-icons/fa";
import { useTemplate } from '../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";
import { SecurityApi } from '../../../../../hooks/shared/SecurityApi';



const WebMobileHeader = () => {
    const [Height, setHeight] = useState('0%');
    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;
    const location = useLocation();
    const pathName = location.pathname.split('/');
    const lastsegment = pathName[pathName.length-1];
    const [clickedOutside, setClickedOutside] = useState(false);

    const myRef = useRef();

    const {
        state:{ 
           eventsPageData: {
            icons,
            options,
            user,
            details,
            languages,
            translations,
            event
           },
        }
     } = useContext(defaultContext);

    const {
        security,
    } = SecurityApi();

    const {
        changeLangauge,
        state: { langauge },
    } = useContext(languageContext);

    const isRegistration = () => security === "registration";
    const isInvitation = () => security === "invitation";

    const [currentLanguage, setCurrentLanguage] = useState(langauge);

     const handleChangeLangauge = (lang) => {
        setCurrentLanguage(lang);
        changeLangauge(lang);
    }

    const handleClickOutside = e => {
        if (!myRef?.current?.contains(e?.target)) {
            setClickedOutside(true);
        }
    };

    const handleClickInside = () => setClickedOutside(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    window.onclick = function(e) {  
        if (e.target.id === 'myNav' || e?.target?.offsetParent?.id === 'myNav' || clickedOutside == false) {
            setHeight('0%');   
        }
    }

    const toggleNavbar = () => ((Height == '0%') ? setHeight('100%') : setHeight('0%')); 

    const {
        state: {
            layout
        }
    } = useTemplate()
    const detail = TemplateDetails(layout)
    const isOnePageTemplate = detail.getTemplate() === Template.ONEPAGE

    return (
        <>
            {/* start mobile header */}
            <div className="mobile_header">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-sm-9 col-9">
                        <div className="mobile_logo">
                            <Link to={slug.absolutePath}>
                                {details && details?.img_logo_mobile != null ? 
                                (<Image src={details?.img_logo_mobile} placeholderSrc={"/images/logo-placeholder.png"}  alt="mobile logo"/>)
                                 : (<ShimmerCircularImage className="" size={50} />) }
                            </Link>
                            {event ? <p>{event?.title}</p> :<ShimmerTitle className="mobile_logo_img" line={1}  variant="primary"/>}
                        </div>
                    </div>
                    <div className="col-sm-3 col-3">
                        <div className="mobile_toogle_box">
                        <div className="mobile_toggle">
                            <span style={{fontSize:'30px', cursor:"pointer"}} onClick={() => toggleNavbar()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.501" height="22" viewBox="0 0 25.501 22">
                            <g id="Group_845" transform="translate(-326.405 -56.338)">
                                <path id="Path_1255" d="M-10009.595-11539.662h21.5" transform="translate(10338 11616)" fill="#002c6c" stroke="#002c6c" strokeLinecap="round" strokeWidth="4"/>
                                <path id="Path_1256" d="M-10009.595-11539.662h21.5" transform="translate(10338 11607)" fill="#002c6c" stroke="#002c6c" strokeLinecap="round" strokeWidth="4"/>
                                <path id="Path_1257" d="M-10009.595-11539.662h21.5" transform="translate(10338 11598)" fill="#002c6c" stroke="#002c6c" strokeLinecap="round" strokeWidth="4"/>
                            </g>
                            </svg>
                            </span>
                        </div>

                        {/* mobile_header_profile */}
                        {(isRegistration() || isInvitation()) ? ( <div className="mobile_profile_box" onClick={() => toggleNavbar()}>
                            {user ? (<Image src={user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'} alt="avatar"/>)
                            : (<ShimmerCircularImage size={40} />)} 
                        </div>) : null}
                        {/* mobile_header_profile */}
                    </div>
                    </div>
                </div>
                <div id="myNav" className="overlay"  style={{ height: Height}}>
                    <div className="overlay_header">
                        <div className="container">
                            <div className="row align-items-center">
                            <div className="col-sm-10 col-9">
                                <div className="mobile_logo">
                                    <Link to={slug}>
                                    {details && details?.img_logo_mobile != null ? 
                                    (<Image src={details?.img_logo_mobile} placeholderSrc={"/images/logo-placeholder.png"}  alt="mobile logo"/>)
                                    : (<ShimmerCircularImage className="" size={50} />) }
                                    </Link>
                                    {event ? <p>{event?.title}</p> :<ShimmerTitle className="mobile_logo_img" line={1}  variant="primary"/>}
                                </div>
                            </div>
                            <div className="col-sm-2 col-3">
                                <div className="mobile_toggle">
                                    <a href="#" className="closebtn" onClick={() => toggleNavbar()}>&times;</a>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                   {isRegistration() || isInvitation() ? (<div className="mobile_profile">
                        <div className="container">
                            <div className="mobile_profile_inner">
                            <div className="m_profile_img">
                                {user ? 
                                (<Image src={user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'} alt="avatar"/>)
                                : (<ShimmerCircularImage size={40} />)} 
                            </div>
                            <div className="m_profile_content">
                                <h4>{user?.name} {user?.last_name}</h4>
                                <ul className="nav nav-top-profile" id="nav-top-profile" ref={myRef} onClick={handleClickInside}>
                                    <li className='list-top'><Link to={"#"} data-toggle="modal" data-target="#logout"><MdLogin/></Link></li>
                                    <li className='list-top'><Link  to={"#"}><FiBell/></Link> </li>
                                    <li className='list-top'><Link to={Path(URLContants.PROFILE)}><AiOutlineUser/></Link></li>
                                    {options?.includes('faq') ? (<li className='list-top'><Link to={Path(URLContants.FAQ)}><FaQuestion/></Link></li>) : null }
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>) : null}

                    {languages && Object.keys(languages).length > 1 ? 
                        <div className="mobile_user_languages">
                            <div className="container">
                                <h4>Languages</h4>
                                <ol>
                                    {languages && languages ? Object.keys(languages).map((lang, index) => {
                                        return (
                                        <li className={lang === currentLanguage ? "active" : ""} key={index}>
                                            <NavLink to={"#"} onClick={() => handleChangeLangauge(lang)}>{lang.toUpperCase()}</NavLink>
                                        </li>
                                        )
                                    }) : null}
                                </ol>
                            </div>
                        </div>
                    : null }

                    <div className="mobile_nav_area">
                        <div className="container">
                            <ul className="mobile_nav_box">
                                {/* Defualt menu */}
                                {icons?.length > 0 ? icons && icons?.map((menu, index) => {
                                    const showMenu = options?.includes(menu?.icon_type_name);
                                    let iconType = menu?.icon_type_name?.replace(/_/g, '-');
                                    return(
                                        <>
                                            {isOnePageTemplate ? ( 
                                            <li key={index} id={"webbar-" + iconType} className={`${showMenu ? "" : "d-none"}`} >
                                                <HashLink smooth to={'#'+iconType} className="nav-link">
                                                    {menu?.is_fa === 1 ?
                                                    (<span className="svg_path"> <i className={menu?.fa_class}></i></span>) 
                                                    : (<span className="svg_path"> <Image src={menu?.image_url} placeholderSrc={""} alt="Menu Image"/></span>) } 
                                                    <p>{menu?.front_name}</p>
                                                </HashLink>
                                            </li>)
                                            : (<li className={`${showMenu ? "" : "d-none"}`} key={index}>
                                                <Link to={Path(`/${iconType}`)} className="nav-link">
                                                    {menu?.is_fa === 1 ? 
                                                    (<span className="svg_path"><i className={menu?.fa_class}></i></span>):
                                                    (<span className="svg_path"><Image src={menu?.image_url} placeholderSrc={""} alt={"Menu Image"} /></span>) } 
                                                    <p>{menu?.front_name}</p>
                                                </Link>
                                            </li>)}
                                        </>
                                    )
                                })
                                : null}
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
           {/* end mobile header */}
        </>
    )
}

export default WebMobileHeader;
