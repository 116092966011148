import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState, useRef} from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link, useHistory } from "react-router-dom";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import useSlug from "../../../../../hooks/useSlug";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import VideoVaultDetailsDesk from './VideoVaultDetailsDesk';
import VideoVaultDetailsMobile from './VideoVaultDetailsMobile';
import {useTemplate} from "../../../../../hooks/shared/useTemplate";
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";
import {URLContants} from "../../../../../Model/URLContants";
import {RouteWrapper} from "../../../Routes";
import { ShimmerPostList,ShimmerTitle ,ShimmerPostItem} from "react-shimmer-effects";
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import ReactHtmlParser from 'react-html-parser';
import { SizeUtils } from '../../../../../hooks/shared/SizeUtils';

const VideoVaultDetails = forwardRef((props, ref) => {
    const slug = useSlug();
    const {width, isMobile} = SizeUtils()
    let ServicesRef = useRef(null);
    let arrow = useRef(null);
    let history = useHistory();
    const Path = RouteWrapper(slug.absolutePath)
    
    useImperativeHandle(ref, () => ({
        fetchVod(details, callback) {
            const url  = `${slug.path}/vods/${details?.id}`;
            fetchVod(url, callback)
        }
    }));

   const { match } = props;
   const [isLoading, setIsLoading] = useState(false);
   const [catOpend, setCatOpend] = useState(false);
   const [scrollArrow, setScrollArrow] = useState(false);

   const {
      getVodDetails,
      state:{ 
         eventsAllPagesData: {
            vods, 
            vod, 
            vod_people, 
            vod_categories},
         eventsPageData: {translations,event}
      }
   } = useContext(defaultContext);

    const {
        state: {
            layout
        }
    } = useTemplate()

   const details = TemplateDetails(layout)
   const isOnePageTemplate = details.getTemplate() === Template.ONEPAGE;
   const [scrollBarArrow , setscrollBarArrow] = useState(false);
   const [vodsDetailsItem, setVodsDetailsItem] = useState(vods);
   const [vodsDetailsCat, setVodsDetailsCat] = useState(vod_categories);
   const [vodDetails, setVodDetails] = useState(vod);
   const [isPeople, setPeople] = useState(vod_people);
   const [isScrollable, SetIsScrollable] = useState(true);

   const fetchVod = (url, callback) => {
      setIsLoading(true);
      getVodDetails(url, (res, error) => {
         if(res?.data?.status){
            setIsLoading(false);
         }
      });
   }

   const scrollToArrow = () => {
         setscrollBarArrow(false);
         if(arrow.current?.scrollHeight && arrow.current?.clientHeight){
            if (arrow.current?.scrollHeight > arrow.current?.clientHeight) {
               setscrollBarArrow(true);
            }
      }
   };

   const fetchVodRequest = (details, callback) => {
      if (!details?.id) return
      if(isOnePageTemplate){
         const url  = `${slug.path}/vods/${details?.id}`;
         fetchVod(url,callback)
      }else{
         const url = Path(URLContants.VIDEO_VAULT_DETAILS_WITOUTSLUG + "/" + details?.id)
         history.push(url);
      }
      ServicesRef.current.scrollIntoView({behavior: 'smooth'})
      setTimeout(() => {
         scrollToArrow()
      }, 1000);
   }


   useEffect(() => {
      setTimeout(() => {
         if(ServicesRef.current){
            ServicesRef.current.scrollIntoView({behavior: 'smooth'});
         }
      }, 500);
    }, [ServicesRef.current]);

   useEffect(() => {
         let timerFunc = setTimeout(() => {
            if (arrow.current?.scrollHeight && arrow.current?.clientHeight){
               if (arrow.current.scrollHeight > arrow.current.clientHeight) {
                  setscrollBarArrow(true);
               }
            }
         }, 1000);
         return () => clearTimeout(timerFunc);
   }, [arrow]);

   useEffect(()=> {
       const vodId = match?.params?.slug;
       if (vodId){
           const url  = `${slug.path}/vods/${vodId}`;
           fetchVod(url)
       }
   }, [match?.params?.slug])

   useEffect(() => {
      if (vods) {
         setVodsDetailsItem(vods);
      }
   }, [vods]);

   useEffect(() => {
      if (vod_categories) {
         setVodsDetailsCat(vod_categories);
      }
   }, [vod_categories]);

   useEffect(() => {
      if (vod_people) {
         setPeople(vod_people);
      }
   }, [vod_people])

   useEffect(() => {
      if (vod) {
         setVodDetails(vod);
      }
   }, [vod])

   useEffect(()=>{
      vodToggleHandler();
   },[]);

   const vodToggleHandler = () => {
     if(catOpend){
      document.querySelectorAll(".cat-hide").forEach(el => {
         el.classList.add('cat-show');
         el.classList.remove("cat-hide");
     });
     }else{
      document.querySelectorAll(".cat-show").forEach(el => {
         el.classList.add('cat-hide');
         el.classList.remove('cat-show');
     });
     }
     setCatOpend(!catOpend);
   }

   const handleScroll  = () => {
      setScrollArrow(!scrollArrow);
      let divElement = document.getElementById("scroll-container");
      if(divElement.scrollTop > 0){
         divElement.scroll({
            top: 0,
            behavior: 'smooth'
         });
      }else{
         divElement.scroll({
            top: divElement.scrollHeight,
            behavior: 'smooth'
         });
      }
   }

   const vodDetailsFilter = (categorieId) => {
      if(categorieId == "all"){
         setVodsDetailsItem(vods);
          return;
      }
      const updateVodsDetails = vods && vods.filter((currentVods) => {
              return currentVods.category === categorieId;
      });
      setVodsDetailsItem(updateVodsDetails);
   }

   const countItems = (ScreenWidth) => {
      let vodsDetailsItemLength= vodsDetailsItem?.length ?? 0;
      if(ScreenWidth >= 1200){
         return vodsDetailsItemLength > 4 ? true : false;
      }
      else if(ScreenWidth < 992 && ScreenWidth > 768){
         return vodsDetailsItemLength > 3 ? true : false;
      }
      else if(ScreenWidth < 768 && ScreenWidth > 400){
         return vodsDetailsItemLength > 2 ? true : false;
      }
      else if(ScreenWidth <= 400){
         return vodsDetailsItemLength > 1 ? true : false;
      }
   }

   const options = {
        margin: 20,
        responsiveClass: true,
        nav:countItems(window.innerWidth),
        dots: false,
        loop:countItems(window.innerWidth),
        autoplay:countItems(window.innerWidth),
        autoplayTimeout:3000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,

            }
        },
    };



   return (
      <>
      {/* Start video vault details section */}
      <section className={`video_vault_details ${isOnePageTemplate ? "" : ""}`} ref={ServicesRef}>
         <div className={isOnePageTemplate ? "container-fluid" : "container"}>
            {!isOnePageTemplate ? <Link to={slug.absolutePath +'/vod'}><h1 className="mobile-main-heading">
               <span className="icon"><i className="fa fa-angle-left "></i></span>
               <span className="text"> {translations?.vod?.title}</span>
            </h1></Link> : <Link to={slug.absolutePath}><h1 className="mobile-main-heading-onepage">
               <span className="icon"><i className="fa fa-angle-left "></i> BACK</span>
               <span className="text">{translations?.vod?.title}</span>
            </h1></Link>}

            {/* Mobile View Here */}
            <VideoVaultDetailsMobile 
               vodDetails={vodDetails} 
               vod_categories={vod_categories} 
               isPeople={isPeople}
               vodsDetailsItem={vodsDetailsItem}
               vodsDetailsCat={vodsDetailsCat}
               onClick={vodDetailsFilter}
               catOpend={catOpend}
               vodToggleHandler={vodToggleHandler}
               fetchVodRequest={fetchVodRequest}
               isLoading={isLoading}
               event={event}
            />

            {/* Desktop View Here */}
            <VideoVaultDetailsDesk
               vodDetails={vodDetails} 
               vod_categories={vod_categories} 
               scrollArrow={scrollArrow}
               arrow={arrow}
               scrollBarArrow={scrollBarArrow}
               isPeople={isPeople}
               onClick={handleScroll}
               translations={translations}
               isLoading={isLoading}
               event={event}
            />
         </div>
      </section>
      {/* End video vault details section */}
      <section className="miniature_slider mt-50 mb-50">
            <div className={isOnePageTemplate ? "container-fluid" : "container"}>
               <div className="maniature_slider_nav">
                  <ul>
                     <li className="more"><p>{translations?.general?.more}</p></li>
                           {vodsDetailsCat && vodsDetailsCat.map((cat, index) => {
                              return (
                                 <li key={index} data-index={index} className={index !== 0 && "cat-hide"} onClick={()=> vodDetailsFilter(cat?.id)}>
                                    <button type="button" style={{color:cat?.color, borderColor:cat?.color}} className="btn global">{cat?.name}</button>
                                 </li>
                              )
                           })}
                        <li onClick={vodToggleHandler}>
                           {catOpend && catOpend ? <FaAngleRight/> : <FaAngleLeft/>} 
                        </li>
                  </ul>
               </div>  
               
               <div className="maniature_slider_inner mt-30 ">
                 {vodsDetailsItem ? <OwlCarousel className='owl-carousel owl-theme maniature_slider' {...options}>
                     {vodsDetailsItem ? (vodsDetailsItem.map((item, index) => {
                        return (
                           <div className="items" key={index}>
                              <div className="m_slider_inner_box">
                                 <a href id={`vod_${item?.id}`} onClick={() => {fetchVodRequest(item)}}>
                                    <div className="mslide_img">
                                       <img src={item?.thumbnail ? item?.thumbnail : "/images/vault_img1.png"} alt={item?.title}/>
                                    </div>
                                 </a>
                                 <div className="mslide_content">
                                    <h4>{item?.title ? item?.title : ""}</h4>
                                    <p style={{textAlign:'justify'}}> {item?.description ? (ReactHtmlParser(item?.description?.substring(0, 120) ?? '')) : ""}</p>
                                    <div className="detail_btn">
                                          <button type="button" onClick={() => {fetchVodRequest(item)}} className="btn btn-detail">
                                             {translations?.general?.more}
                                          </button>
                                    </div>
                                 </div>
                              </div>
                           </div>)
                        })) : "Data not found!"
                     }
                  </OwlCarousel> : (<ShimmerPostList postStyle="STYLE_FOUR" col={isMobile ? 1 : 4} row={isMobile ? 3 : 1} gap={30} />)}
               </div>
            </div>
         </section>
      </>
   )
})

export default VideoVaultDetails
