import React from "react";

export const ModalWrapper = ({className, id, children, size = "xl", onClose}) => {
    return <div className={`modal fade main_model ${className}`} id={`${id}`}>
        <div className={`modal-dialog modal-${size} modal-dialog-centered modal-dialog-scrollable`}>
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>
    </div>
}