import React, {useContext} from 'react';
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import ReactHtmlParser from "react-html-parser";
import {Constants} from "../../../../../classes/Constants";

const SocialWall = () => {
    const {
        state:{
            eventsPageData: {
                event,
                translations,
                details,
                social_wall,
            },
        }
    } = useContext(defaultContext);


    return (
        <>
        <section className="sponsors_slider_area mt-50 mb-50" id='social-wall'>
            <div className="container">
               <div className="">
                    <h2 className='main_heading mb-20'>{translations ? translations?.social_wall?.title : "" }</h2>
                    <div className="text-center main-color">{social_wall ? ReactHtmlParser(social_wall ?? '') :  details?.no_social_wall_message ?? Constants.NO_DATA_AVAILABLE }</div>
               </div>
            </div>
        </section> 
        </>
    )
}

export default SocialWall;
