import React, {useContext} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import useSlug from "../../hooks/useSlug";
import {Layout} from "./Components/Layout/Layout";
import LayoutMobile from "./Components/LayoutMobile/LayoutMobile";
import {Context as AuthContext} from "../../context/AuthContext";
import {URLContants} from "../../Model/URLContants";
//import Login from "../Event/Components/Auth/Login";
import Login from "./Components/Auth/Login";
// import Register from "../Event/Components/Auth/Register";
import Register from "./Components/Auth/Register";
import {RouteWrapper} from "../Event/Routes";
import useStyle from "../../hooks/cssLoad";
import SponsorsDetails from "../Event/Components/Pages/Sponsors/SponsorsDetails";
import VideoVaultDetails from "../Event/Components/Pages/VideoVault/VideoVaultDetails";
import Profile from "../Event/Components/Pages/Profile/Profile";
import Faq from "../Event/Components/Pages/Faq/Faq";

import { SecurityApi } from "../../hooks/shared/SecurityApi";

const getLayoutWrapper = (Page:any, props:any) => {
    //to render all others components except header and footer
    return (
        <LayoutMobile>
            <Page {...props} />
        </LayoutMobile>
    );
  };

export const Index = () => {
    useStyle('/css/internalstyle.css');
    const slug = useSlug()

    // const {
    //     state: { isLoggedIn },
    // } = useContext(AuthContext);

    const {
        isLoggedIn,
        security
    } = SecurityApi();
    
    const isPuplic = () => security === "public";

    const Path = RouteWrapper(slug.absolutePath);

    if (!isLoggedIn && !isPuplic()) {
        return <Switch>
            <Route exact path={Path(URLContants.LOGIN)} render={(props) => <Login security={security}/>}/>
            <Route exact path={Path(URLContants.REGISTER)} render={(props) => <Register/>}/>
            <Redirect  to={Path("/login")}/>
        </Switch>
    }

    return (
        <>
            <Switch>
                <Route exact path={slug.absolutePath}> <Layout/> </Route>
                <Route exact path={Path(URLContants.FAQ)} render ={(props) => getLayoutWrapper(Faq, props)}/>
                <Route exact path={Path(URLContants.SPONSORS_DETAILS)} render ={(props) => getLayoutWrapper(SponsorsDetails, props)}/>
                <Route exact path={Path(URLContants.VIDEO_VAULT_DETAILS)} render ={(props) => getLayoutWrapper(VideoVaultDetails, props)}/>
                <Route exact path={Path(URLContants.PROFILE)} render ={(props) => getLayoutWrapper(Profile, props)}/>
                <Redirect  to={slug.absolutePath}/>
            </Switch>
        </>
    )
}