
export const LiveShimmer = () => {
    return <div className="shimmer_live_room shimmer_border">
        <div className="shine"/>
    </div>
}

export const TickerShimmer = () => {
    return <div className="shimmer_ticker shimmer_border">
        <div className="shine"/>
    </div>
}

export const InterractionShimmer = () => {
    return (
        <div className="d-flex w-100">
            <div className="shimmer_room_chat shimmer_border w-100">
                <div className="shimmer_search">
                    <div className="shine"/>
                </div>
                <div className="shimmer_lchat mt-5">
                    <div className="ml-auto d-block mb-3 shine"/>
                    <div className="mr-auto d-block mb-3 shine"/>
                    <div className="ml-auto d-block mb-3 shine"/>
                    <div className="mr-auto d-block mb-3 shine"/>
                </div>
                <div className="shimmer_search mt-5">
                    <div className="shine"/>
                </div>
            </div>
            <div className="shimmer_room_chat_tab" style={{width: "100px", marginLeft: "20px"}} >
                <div className="shine"/>
                <div className="shine"/>
                <div className="shine"/>
                <div className="shine"/>
                <div className="shine"/>
            </div>
        </div>
    )
}

// export default ;
// export default TickerShimmer;
// export default InterractionShimmer;



