import React, {useState, useContext, useEffect, useMemo, useRef} from 'react';
import { useToasts } from "react-toast-notifications";
import {Link, Redirect, useHistory, useParams} from "react-router-dom";
import { useCookies } from 'react-cookie';
import WebHeaderRight from "../Partials/Header/WebHeaderRight";
import WebHeader from "../Partials/Header/WebHeader";
import WebMobileHeader from "../Partials/Header/WebMobileHeader";
import WebBanner from "../Partials/Banner/WebBanner";
import Loader from "../Loader/Loader";
import Location from "../../../../hooks/Location";
import { Context as defaultContext } from "../../../../context/DefaultSectionContext";
import { Context as languageContext } from "../../../../context/LanguageContext";
import LogoutPage from '../Auth/LogoutPage';
import ReactHtmlParser from "react-html-parser";
import {SupportScript} from "../../../../utils/SupportScript";
import {EventApi} from "../../../../hooks/shared/EventApi";
import {SizeUtils} from "../../../../hooks/shared/SizeUtils";
import IncomingCallPop from "../../../../UIComponets/IncomingCallPop";
import {VideoCallApi} from "../../../../hooks/videoCallApi";
import {VideoChatState} from "../../../../hooks/VideoChatState";
import NotificationCenter from "../../../../hooks/NotificationCenter";
import {getOptions, ToastType} from "../../../../hooks/showToast";
import useBodyClass from "../../../../hooks/BodyClass";
import {UserApi} from "../../../../hooks/shared/UserApi";
import { FAQIcon } from '../../../../SVG/Icons';
import { URLContants } from '../../../../Model/URLContants';
import useSlug from '../../../../hooks/useSlug';
import {SplashScreenDialog} from "../../../Shared/SplashScreenDialog";
import LocationPathSaperator, {OnlineOfflineUser} from "../../../../Util/Utils";
import {DocumentTitleMap} from "../../../../Model/DocumentTitleMap";
import {useLocation} from "react-router";
import { SecurityApi } from '../../../../hooks/shared/SecurityApi';
import { imageWithStorage } from '../../../../constants/url/urlConstants';

const Layout = ({children}) => {
    const { hash } = useLocation();
    const [clientName, event, page, id] = Location();
    const eventTitle = event?.replaceAll("-", " ")?.capitalize()
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoginRequired, setIsLoginRequired] = useState(false);
    const [isEventPublic, setIsEventPublic] = useState(false);
    const { addToast } = useToasts();
    const { passcode } = useParams()
    const [cookies, setCookie] = useCookies(['truid']);
    const {width, isMobile} = SizeUtils()
    const {videoChatState, incomingCall} = VideoCallApi()
    const [faqList, setFaqList] = useState(null);
    const slug = useSlug()
    const Path = (url) => slug.absolutePath + url
    useBodyClass("organization-body");

    const {
        fetchEventsPage,
        unsubscribeChannel,
        state: {
            eventsPageData,
            options,
            details,
            user
        },
    } = EventApi();

    const {
        state: {
          langauge
        },
    } = useContext(languageContext)

    const {
        security,
    } = SecurityApi();

    const isRegistration = () => security === "registration";
    const isInvitation = () => security === "invitation";

    useEffect(() => {
        const favicon = document.getElementById("favicon");
        if (favicon) {
          if (details?.img_favicon) {
            favicon.href = imageWithStorage + details?.img_favicon;
          } else if (details?.img_logo_desktop) {
            favicon.href = imageWithStorage + details?.img_logo_desktop;
          }
        }
    }, [details?.img_logo_desktop]);

    useEffect(() => {
        if (details){
            if (details?.register_bg_color){
                document.documentElement.style.setProperty('--register-bg-color', details?.register_bg_color);
                document.documentElement.style.setProperty('--register-text-color', details?.register_text_color);
            }
            if (details?.registered_bg_color){
                document.documentElement.style.setProperty('--registered-bg-color', details?.registered_bg_color);
                document.documentElement.style.setProperty('--registered-text-color', details?.registered_text_color);
            }
            if (details?.join_bg_color){
                document.documentElement.style.setProperty('--join-bg-color', details?.join_bg_color);
                document.documentElement.style.setProperty('--join-text-color', details?.join_text_color);
            }
            if (details?.ended_bg_color){
                document.documentElement.style.setProperty('--ended-bg-color', details?.ended_bg_color);
                document.documentElement.style.setProperty('--ended-text-color', details?.ended_text_color);
            }
            if (details?.replay_bg_color){
                document.documentElement.style.setProperty('--reply-bg-color', details?.replay_bg_color);
                document.documentElement.style.setProperty('--reply-text-color', details?.replay_text_color);
            }
        }
    }, [details]);

    useEffect(() => {
        const bodyClass = document.querySelector(".organization-body");
        let url;
        if (bodyClass) {
            bodyClass.setAttribute("style","");
        }

        const unlisten =  history.listen(location => {
            const pathname = location.pathname + location.hash;
           const [, , page, id] = LocationPathSaperator(pathname)
            if (DocumentTitleMap[page]){
                const obj = DocumentTitleMap[page]
                let title =  eventTitle + " | " + obj.title;
                if (id) {
                    title = title +  (id ? ` | ${id}` : "")
                }
                if (location.hash) {
                    title = title + location.hash?.capitalize()
                }
                document.title =title
            }else {
                document.title = eventTitle
            }
        });
        return () => {
            unlisten()
        }
    }, []);


    if (DocumentTitleMap[page]){
        const obj = DocumentTitleMap[page]
        let title =  eventTitle + " | " + obj.title;
        if (id) {
            title = title +  (id ? ` | ${id}` : "")
        }
        if (hash) {
            title = title + hash?.capitalize()
        }
        document.title =title
    }else {
        document.title = eventTitle
    }


    useEffect(() => {
        if (Boolean.parse(process.env.REACT_APP_LOGGING)) {
            const channelMap = eventsPageData?.channels?.reduce((prev, next) => {
                return {
                    ...prev,
                    [next.id]: next
                }
            }, {})
            NotificationCenter.sharedInstance().registerNotification('optionsUpdate', (data) => {
                const optionName = data?.payload?.optionname?.toLowerCase();
                const isForEach = optionName?.includes("for_channel");

                const interationFeatures = ["chat", "poll", "vote", "rating", "qa"];
                const index = ["chat", "poll", "vote", "rating", "qa"].findIndex(i => optionName.includes(i))
                let nameofFeature = index !== -1 && interationFeatures[index];
                const isInteraction = index !== -1;
                const channelId = isForEach && optionName?.split("_")?.last()

                if (isInteraction){
                    let message = nameofFeature + " " + data.action
                    if (isForEach && channelId && channelMap && channelMap[channelId]){
                        const channelName = channelMap[channelId]?.name
                        if (channelName){
                            message = `${channelName ?? ""} ${nameofFeature ?? ""} ${data.action ?? ""}`
                        }
                    }
                    addToast( message?.capitalize(), {...getOptions(ToastType.INFO)})
                }else {
                    nameofFeature = optionName
                    let message = nameofFeature + " " + data.action ?? ""
                    addToast(message?.capitalize(), {...getOptions(ToastType.INFO)})
                }
            })
        }

        NotificationCenter.sharedInstance().registerNotification('connectionEvent', (data) => {
            if (data && data?.user){
                const user = OnlineOfflineUser(data.user)
                addToast(`${user.getFullName()} sent you the connection request`, {...getOptions(ToastType.INFO)})
            }
        })

    }, [eventsPageData?.channels])


    useEffect(() => {
        const eventsData = async (callback) => {
            let url;
            setIsLoading(true);
            let channelUrl = sessionStorage.getItem("channelSelected");
            const _channel = JSON.parse(channelUrl);
            if (channelUrl) {
                url = _channel.url;
            } else if (passcode) {
                url = `${clientName}/${event}/${passcode}`;
            } else {
                url = `${clientName}/${event}`;
            }
            try {
                await fetchEventsPage((data) => {
                        callback(data);
                });
                setIsLoading(false);
            } catch (e) {
                console.error(e);
                callback(null);
                setIsLoading(false);
                addToast("Something went wrong! Please Try Again", {
                    appearance: "error",
                    autoDismiss: true,
                });
                return <Redirect />;
            }
            setIsLoading(false);
        };

        eventsData((data) => {
            if (data?.status !== "success") {
                //return <ErrorPageDefault />;
            }
            let lastPathComponent = "";
            let authPayload;
            if (data?.page_data?.event?.security === "registration") {
            lastPathComponent = "/login";
            setIsEventPublic(false);
            const pass = JSON.parse(localStorage.getItem(`${clientName}+${event}`));
            if (
                pass?.event === clientName + event &&
                !localStorage.getItem("defaultLogin")
            ) {
                setIsLoginRequired(true);
            }
            if (!localStorage.getItem("defaultLogin")) {
                setIsLoginRequired(true);
                localStorage.removeItem(`${clientName}+${event}`);
                history.replace(`/${clientName}/${event}/register`);
            }

            authPayload =  localStorage.getItem("defaultLogin");
            } else if (data?.page_data?.event?.security === "password") {
            const pass = JSON.parse(localStorage.getItem(`${clientName}+${event}`));
            if (!(pass?.event === clientName + event)) {
                setIsLoginRequired(true);
                history.replace(`/${clientName}/${event}/login`);
            }
            } else if (data?.page_data?.event?.security === "public") {
                setIsLoginRequired(false);
                setIsEventPublic(true);
            }

            // const details = JSON.parse(authPayload);
            // if (!details?.access_token || !details?.expires_at) {
            //   if (data?.page_data?.event?.security === "registration")
            //     // setIsLoginRequired(true);
            //     return;
            // }

        });
    }, [langauge]);

    const isLivePage = page == "live" || page == "video-vault-details";
    const isShwoingBanner = !(isMobile && isLivePage);
    const components = <>
        {/* <Loader isLoading={isLoading}/> */}
        {videoChatState === VideoChatState.RINGING  && <IncomingCallPop/>}
        {(isRegistration() || isInvitation()) && (<WebHeaderRight/>)}
        {details?.show_header === "default" && (<WebHeader/>)}
        <WebMobileHeader/>
        {isShwoingBanner ? <WebBanner sliders={eventsPageData?.sliders} isLoading={isLoading}/> : ""}
        {children}
        <LogoutPage/>
        { eventsPageData?.event?.support && process.env.REACT_APP_ENVIRONMENT === 'production' && <SupportScript script={eventsPageData?.event?.support} user={user}/>}
        {page == "faq" ? "" : options?.includes('faq') ? (<Link to={Path(URLContants.FAQ)}><button type='button' className='btn LeftIcon'><FAQIcon FaqClass={"faqPrimary"}/></button></Link>) : null }
        <SplashScreenDialog />
    </>
    return (components);
}

export default Layout;
