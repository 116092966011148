import React from 'react';
import { Link } from 'react-router-dom';
import {SearchIcon} from "../../../../../../SVG/Icons";

const BreakoutRoomcall = () => {
   return (
      <>
         <div className="breakoutroom_call_mobile">
               <div className="container">
                  <h1 className="main_heading">Breakout Room: Meet at the bar</h1>
                     <div className="br_video_chat">
                        <div className="br_video_chat_header">
                        <div className="row">
                              <div className="col-6 col-sm-6">
                              <div className="time-left">
                           <h4>Time Left</h4>
                           <p>
                              <span className="bar"></span>
                              <span className="ber_text">10 min 46 sec</span></p>
                           </div>
                              </div>
                              <div className="col-6 col-sm-6">
                              <div className="br_video_chat_header_right">
                                 <div className="chat__model_area" data-toggle="modal" data-target="#m_chat_model" type="button">
                           <svg id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" xmlns="http://www.w3.org/2000/svg" width="17.858" height="17.857" viewBox="0 0 17.858 17.857">
                              <path id="Path_1272" data-name="Path 1272" d="M21.687,15.077a1.04,1.04,0,0,1,.142-.524,1.446,1.446,0,0,1,.09-.133A6.956,6.956,0,0,0,23.1,10.544a7.3,7.3,0,0,0-7.457-7.169A7.405,7.405,0,0,0,8.336,9.08a6.9,6.9,0,0,0-.159,1.468,7.282,7.282,0,0,0,7.341,7.259,8.841,8.841,0,0,0,2.026-.331c.485-.133.966-.309,1.09-.356a1.135,1.135,0,0,1,.4-.073,1.116,1.116,0,0,1,.434.086L21.9,18a.58.58,0,0,0,.167.043.342.342,0,0,0,.343-.343.551.551,0,0,0-.021-.116Z" transform="translate(-5.245 -3.375)" fill="#fff"/>
                              <path id="Path_1273" data-name="Path 1273" d="M14.987,21.874c-.155.043-.352.09-.567.137a7.923,7.923,0,0,1-1.46.193A7.282,7.282,0,0,1,5.62,14.946a8.116,8.116,0,0,1,.064-.919c.026-.185.056-.369.1-.549s.094-.386.15-.575l-.343.3a6.389,6.389,0,0,0-2.215,4.8,6.317,6.317,0,0,0,1.065,3.52c.1.15.155.266.137.343s-.511,2.662-.511,2.662a.344.344,0,0,0,.116.331.35.35,0,0,0,.219.077.308.308,0,0,0,.124-.026l2.408-.949a.671.671,0,0,1,.515.009,7.225,7.225,0,0,0,2.606.515,6.742,6.742,0,0,0,5.156-2.365s.137-.189.3-.412C15.348,21.771,15.167,21.827,14.987,21.874Z" transform="translate(-3.375 -7.086)" fill="#fff"/>
                           </svg>

                           </div>
                                 <div className="users" data-toggle="modal" data-target="#m_peeple_chat" type="button">
                           <svg xmlns="http://www.w3.org/2000/svg" width="22.712" height="14.601" viewBox="0 0 22.712 14.601">
                              <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people" d="M17.736,13.959a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,17.736,13.959Zm-8.259,0a3.042,3.042,0,1,0-3.1-3.042A3.079,3.079,0,0,0,9.477,13.959Zm0,2.231c-2.426,0-7.227,1.166-7.227,3.549v2.738h14.6V19.738C16.851,17.355,11.9,16.189,9.477,16.189Zm8.259.558a5.51,5.51,0,0,0-.885.051,3.023,3.023,0,0,1,1.622,2.94v2.738h6.489V19.738C24.962,17.355,20.162,16.747,17.736,16.747Z" transform="translate(-2.25 -7.875)" fill="#fff"/>
                           </svg>

                              <p>7<span>/10</span></p>
                           </div>
                           </div>
                              </div>
                        </div>
                        </div>
                        <div className="br_video_chat_content">
                        <div className="row no-gutters">
                              <div className="col-6 col-md-4 col-lg-4">
                                 <div className="br_video_chat_box">
                                 <img alt="image" src="/images/mcall_img1.png" />
                                 <p>David</p>
                                 </div>
                              </div>
                              <div className="col-6 col-md-4 col-lg-4">
                              <div className="br_video_chat_box">
                                 <img alt="image" src="/images/mcall_img2.png"/>
                                 <p>Milo</p>
                                 </div>
                              </div>
                              <div className="col-6 col-md-4 col-lg-4">
                              <div className="br_video_chat_box">
                                 <img alt="image" src="/images/mcall_img3.png"/>
                                 <p>Gianluca</p>
                                 </div>
                              </div>
                              <div className="col-6 col-md-4 col-lg-4">
                              <div className="br_video_chat_box">
                                 <img alt="image" src="/images/mcall_img4.png"/>
                                 <p>Karen</p>
                                 </div>
                              </div>
                              <div className="col-6 col-md-4 col-lg-4">
                              <div className="br_video_chat_box">
                                 <img alt="image" src="/images/mcall_img5.png"/>
                                 <p>Jason</p>
                                 </div>
                              </div>
                              <div className="col-6 col-md-4 col-lg-4">
                              <div className="br_video_chat_box">
                                 <img alt="image" src="/images/mcall_img1.png"/>
                                 <p>Helena</p>
                                 </div>
                              </div>
                        </div>
                        </div>
                        <div className="br_video_chat_footer">
                        <button type="button" className="btn"><img alt="image" src="/images/chat_icon1.png"/></button>
                        <button type="button" className="btn"><img alt="image" src="/images/chat_icon2.png"/></button>
                        <button type="button" className="btn"><img alt="image" src="/images/chat_icon3.png"/></button>
                        <button type="button" className="btn"><img alt="image" src="/images/chat_icon4.png"/></button>
                        <button type="button" className="btn"><img alt="image" src="/images/chat_icon5.png"/></button>
                        </div>
                  </div>
               </div>
            </div>

               {/*-- breakoutroom_call_area */}
            <section className="breakoutroom_call_Area mt-50 pb-100">
                        <div className="view_all_btn overview_btn">
                                 <button className="btn">
                                 <span> <i className="fa fa-angle-right" aria-hidden="true"></i></span>Back to overview
                                 </button>
                              </div>
                  <div className="container">
                     <div className="breakout_room_header">
                        <img alt="image" src="/images/video_icon1.png"/>
                        <h4>BREAKOUT ROOM: AT THE BEACH</h4>
                     </div>

                     <div className="row">
                        <div className="col-md-12 col-lg-8 col-xl-8">
                        <div className="br_video_chat mt-40">
                              <div className="br_video_chat_header">
                                 <div className="time-left">
                                 <h4>Time Left</h4>
                                 <p>10 min 46 sec</p>
                                 </div>
                                 <div className="users">
                                 <svg className="svg_orange"  xmlns="http://www.w3.org/2000/svg" width="31.5" height="20.25" viewBox="0 0 31.5 20.25">
  <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people" d="M23.727,16.313a4.219,4.219,0,1,0-4.3-4.219A4.27,4.27,0,0,0,23.727,16.313Zm-11.454,0a4.219,4.219,0,1,0-4.3-4.219A4.27,4.27,0,0,0,12.273,16.313Zm0,3.094c-3.365,0-10.023,1.617-10.023,4.922v3.8H22.5v-3.8C22.5,21.023,15.638,19.406,12.273,19.406Zm11.454.774a7.641,7.641,0,0,0-1.227.07c1.647,1.2,2.25,1.969,2.25,4.078v3.8h9v-3.8C33.75,21.023,27.092,20.18,23.727,20.18Z" transform="translate(-2.25 -7.875)"/>
</svg>

                                    <p>7<span>/10</span></p>
                                 </div>
                                 <div className="eyes">
                                 <svg className="svg_orange" xmlns="http://www.w3.org/2000/svg" width="25" height="16.853" viewBox="0 0 25 16.853">
  <path id="Icon_ionic-md-eye" data-name="Icon ionic-md-eye" d="M14.75,7.383a13.442,13.442,0,0,0-12.5,8.426,13.587,13.587,0,0,0,9.4,8.07,12.956,12.956,0,0,0,3.1.357,13.442,13.442,0,0,0,12.5-8.426A13.442,13.442,0,0,0,14.75,7.383Zm0,14.046a5.62,5.62,0,1,1,5.681-5.619A5.666,5.666,0,0,1,14.75,21.429Zm0-8.99a3.371,3.371,0,1,0,3.41,3.371A3.4,3.4,0,0,0,14.75,12.439Z" transform="translate(-2.25 -7.383)"/>
</svg>

                                 <p>18</p>
                                 </div>
                              </div>
                              <div className="br_video_chat_content">
                              <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4">
                                       <div className="br_video_chat_box">
                                       <img alt="image" src="/images/video_chat_img1.png"/>
                                       <p>David</p>
                                       </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4">
                                    <div className="br_video_chat_box">
                                       <img alt="image" src="/images/video_chat_img2.png"/>
                                       <p>Milo</p>
                                       </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4">
                                    <div className="br_video_chat_box">
                                       <img alt="image" src="/images/video_chat_img3.png"/>
                                       <p>Gianluca</p>
                                       </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4">
                                    <div className="br_video_chat_box">
                                       <img alt="image" src="/images/video_chat_img4.png"/>
                                       <p>Karen</p>
                                       </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4">
                                    <div className="br_video_chat_box">
                                       <img alt="image" src="/images/video_chat_img5.png"/>
                                       <p>Jason</p>
                                       </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4">
                                    <div className="br_video_chat_box">
                                       <img alt="image" src="/images/video_chat_img6.png"/>
                                       <p>Helena</p>
                                       </div>
                                    </div>
                              </div>
                              </div>
                              <div className="br_video_chat_footer">
                              <button type="button" className="btn"><img alt="image" src="/images/chat_icon1.png"/></button>
                              <button type="button" className="btn"><img alt="image" src="/images/chat_icon2.png"/></button>
                              <button type="button" className="btn"><img alt="image" src="/images/chat_icon3.png"/></button>
                              <button type="button" className="btn"><img alt="image" src="/images/chat_icon4.png"/></button>
                              <button type="button" className="btn"><img alt="image" src="/images/chat_icon5.png"/></button>
                              </div>
                        </div>
                        </div>
                        <div className="col-md-12 col-lg-4 col-xl-4">
                        <div className="br_people_chat_box">
                           <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item">
                                 <a className="nav-link active" data-toggle="tab" href="#CHAT" role="tab">CHAT</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#PEOPLE" role="tab">PEOPLE</a>
                              </li>
                              
                           </ul>{/*-- Tab panes */}
                           <div className="tab-content">
                              <div className="tab-pane active" id="CHAT" role="tabpanel">
                                 <div className="br_chat_inner">
                                 <div className="lr_chat_box">
                                    <div className="searchbar_right">
                                       <input type="text" placeholder="Search.." name="search" className="form-control"/>
                                       <button type="submit">
                                          
                                          <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>{/*-- <SearchIcon /> */}
                                       </button>
                                    </div>

                                    <ul className="one_chat_box">
                                       <li className="chat_right">
                                          <h4>Let’s get lunch. How about pizza?</h4>
                                          <p>09:41  <span> Me</span></p>
                                       </li>
                                       <li className="chat_left">
                                             <h4>Let’s do it! I’m in a meeting until noon.</h4>
                                          <p>10:21  <span>Guest - 2</span></p>
                                       </li>
                                       <li className="chat_right">
                                             <h4>That’s perfect. There’s a new place on Main St I’ve been wanting to check out. I hear their hawaiian pizza is off the hook!</h4>
                                          <p>10:23  <span> Me</span></p>
                                       </li>
                                       <li className="chat_left">
                                             <h4>I don’t know why people are so 
                                             anti pineapple pizza. I kind of like 
                                             it.</h4>
                                          <p>10:24  <span> Guest - 2</span></p>
                                       </li>

                                    </ul>

                                    <div className="send_input_box">
                                          <input type="text" className="form-control" id="send" placeholder="Message"/>
                                          <svg className="svg-inline--fa fa-paper-plane fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="paper-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path></svg>{/*-- <i className="fa fa-paper-plane" aria-hidden="true"></i> */}
                                    </div>
                                 </div>
                                 </div>
                              </div>
                              <div className="tab-pane" id="PEOPLE" role="tabpanel">
                                 <div className="br_people_tab_inner">

                                 <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                       <a className="nav-link active" data-toggle="tab" href="#call" role="tab">In this call</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link" data-toggle="tab" href="#Event" role="tab">Event</a>
                                    </li>
                                    
                                 </ul>{/*-- Tab panes */}
                                 <div className="tab-content">
                                    <div className="tab-pane active" id="call" role="tabpanel">
                                       <div className="searchbar_right mb-30">
                                          <input type="text" placeholder="Search.." name="search" className="form-control"/>
                                          <button type="submit">
                                             
                                             <SearchIcon />
                                          </button>
                                       </div>

                                       <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon1.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Dave Philips </h4>
                                             <p>CEO</p>
                                             <h6>Nike</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_orange">HOST</button>
                                             </div>
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon2.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Patrick Stevens </h4>
                                             <p>COO</p>
                                             <h6>Proximus</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon3.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Martin Lecroix </h4>
                                             <p>Marketing Manager</p>
                                             <h6>Torfs</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon4.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Milo Edwards   </h4>
                                             <p>Office Manager</p>
                                             <h6>Torfs</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon5.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Mila Sharp </h4>
                                             <p>Chief entertainement</p>
                                             <h6>Fungroup</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon6.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Karen Shawn </h4>
                                             <p>Office Manager</p>
                                             <h6>Fungroup</h6>
                                             </div>
                                          
                                       </div>
                                    </div>
                                    <div className="tab-pane" id="Event" role="tabpanel">
                                          <div className="searchbar_right mb-30">
                                          <input type="text" placeholder="Search.." name="search" className="form-control"/>
                                          <button type="submit">
                                             
                                             <SearchIcon />
                                          </button>
                                       </div>

                                       <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon1.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                             <div className="user_details">
                                             <h4>Dave Philips </h4>
                                             <p>CEO</p>
                                             <h6>Nike</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_grey">add</button>
                                             </div>
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon2.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                             <div className="user_details">
                                             <h4>Patrick Stevens </h4>
                                             <p>COO</p>
                                             <h6>Proximus</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon3.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                             <div className="user_details">
                                             <h4>Martin Lecroix </h4>
                                             <p>Marketing Manager</p>
                                             <h6>Torfs</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_grey">add</button>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon4.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                             <div className="user_details">
                                             <h4>Milo Edwards   </h4>
                                             <p>Office Manager</p>
                                             <h6>Torfs</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_grey">add</button>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon5.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                             <div className="user_details">
                                             <h4>Mila Sharp </h4>
                                             <p>Chief entertainement</p>
                                             <h6>Fungroup</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_grey">add</button>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon6.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                             <div className="user_details">
                                             <h4>Karen Shawn </h4>
                                             <p>Office Manager</p>
                                             <h6>Fungroup</h6>
                                             </div>
                                          
                                       </div>
                                    </div>
                                    
                                 </div>

                                 </div>
                              </div>
                              
                           </div>
                        </div>

                        </div>
                     </div>
                  </div>
         </section>
         {/*-- breakoutroom_call_area_close */}

         {/*-- mobile_chat_model */}
            <div className="modal fade mobile_chat_model" id="m_chat_model">
         <div className="modal-dialog modal-sm modal-dialog-centered">
         <div className="modal-content">

            {/*-- Modal Header */}
            <div className="modal-header">
            
               <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            {/*-- Modal body */}
            <div className="mobile_chat_mbody">
                  <h1 className="main_heading mb-20">CHAT</h1>
                  <div className="br_thiscall_box mb-20">
                  <div className="user_img">
                  <a href="participant-details.html"><img alt="image" src="/images/call_icon1.png"/></a>
                  </div>
                  <div className="user_details">
                  <h4>Markus Mueller - <span>Host  13.10</span></h4>
                  <p>Hello everyone</p>
                  </div>
                  </div>
                  <div className="br_thiscall_box mb-20">
                  <div className="user_img">
                  <a href="participant-details.html"><img alt="image" src="/images/call_icon2.png"/></a>
                  </div>
                  <div className="user_details">
                  <h4>Dave Philips  <span> 13.12</span></h4>
                  <p>Fine thanks. What we will talk about? 
                  Because I have some specific questions.</p>
                  </div>
                  </div>
                  <div className="br_thiscall_box mb-20">
                  <div className="user_img">
                  <a href="participant-details.html"><img alt="image" src="/images/call_icon3.png"/></a>
                  </div>
                  <div className="user_details">
                  <h4>Patrick Stevens   <span>13.14</span></h4>
                  <p>Ok, shoot your question.</p>
                  </div>
                  </div>
                  <div className="br_thiscall_box mb-20">
                  <div className="user_img">
                  <a href="participant-details.html"><img alt="image" src="/images/call_icon4.png"/></a>
                  </div>
                  <div className="user_details">
                  <h4>Dave Philips <span>13.15</span></h4>
                  <p>My first question: When will be the exact 
                  launch date?</p>
                  </div>
                  </div>
                  <div className="br_thiscall_box mb-20">
                  <div className="user_img">
                  <a href="participant-details.html"><img alt="image" src="/images/call_icon5.png"/></a>
                  </div>
                  <div className="user_details">
                  <h4>Patricia Lecroix <span>  13.17</span></h4>
                  <p>That’s a good question, can please anybody 
                  answer thisone?</p>
                  </div>
                  </div>
                  <div className="br_thiscall_box mb-20">
                  <div className="user_img">
                  <a href="participant-details.html"><img alt="image" src="/images/call_icon6.png"/></a>
                  </div>
                  <div className="user_details">
                  <h4>Caroline Edwards<span>13.18</span></h4>
                  <p>Hello everyone</p>
                  </div>
                  </div>
                  <div className="send_input_box">
                  <input type="text" className="form-control" id="send" placeholder="Message"/>
                  <svg className="svg-inline--fa fa-paper-plane fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="paper-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path></svg>{/*-- <i className="fa fa-paper-plane" aria-hidden="true"></i> */}
                  </div>
               </div>
         </div>
         </div>
         </div>
         {/*-- mobile_chat_model_close */}



         {/*-- mobile_people_model */}
            <div className="modal fade mobile_chat_model" id="m_peeple_chat">
         <div className="modal-dialog modal-sm modal-dialog-centered">
         <div className="modal-content">

            {/*-- Modal Header */}
            <div className="modal-header">
            
               <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            
            {/*-- Modal body */}
            <div className="mobile_people_mbody">
         <h1 className="main_heading mb-20">PEOPLE</h1>
            <div className="br_people_tab_inner">

                                 <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                       <a className="nav-link active" data-toggle="tab" href="#call1" role="tab">In this call</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link" data-toggle="tab" href="#Event1" role="tab">Event</a>
                                    </li>
                                    
                                 </ul>{/*-- Tab panes */}
                                 <div className="tab-content">
                                    <div className="tab-pane active" id="call1" role="tabpanel">
                                       <div className="searchbar_right mb-30">
                                          <input type="text" placeholder="Search.." name="search" className="form-control"/>
                                          <button type="submit">
                                             
                                             <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>{/*-- <SearchIcon /> */}
                                          </button>
                                       </div>

                                       <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon1.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_orange">HOST</button>
                                             </div>
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon2.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon3.png"/></a>
                                             </div>
                                             <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon4.png"/></a>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon5.png"/></a>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon6.png"/></a>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                          
                                       </div>




                                    </div>
                                    <div className="tab-pane" id="Event1" role="tabpanel">
                                          <div className="searchbar_right mb-30">
                                          <input type="text" placeholder="Search.." name="search" className="form-control"/>
                                          <button type="submit">
                                             
                                             <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>{/*-- <SearchIcon /> */}
                                          </button>
                                       </div>

                                       <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"><img alt="image" src="/images/call_icon1.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_grey">add</button>
                                             </div>
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon2.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"></a><a href=""><img alt="image" src="/images/call_icon3.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_grey">add</button>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon4.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_grey">add</button>
                                             </div>
                                          
                                       </div>

                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon5.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                             <div className="br_call_btn">
                                                <button type="button" className="btn btn_theme_grey">add</button>
                                             </div>
                                          
                                       </div>
                                          <div className="br_thiscall_box event_call_box mb-20">
                                             <div className="user_img">
                                             <a href="participant-details.html"> <img alt="image" src="/images/call_icon6.png"/></a>
                                             <span className="g_dot"></span>
                                             </div>
                                          <div className="user_details">
                                             <h4>Markus Mueller  </h4>
                                             <p>Director Global Data Model 
                                                Programme</p>
                                             <h6>GS1 Belgium</h6>
                                             </div>
                                       </div>
                                    </div>
                                 </div>
                                 </div>
                        </div>
                        </div>
                     </div>
                  </div>
      {/*-- mobile_chat_model_close */}
      </>
   )
}

export default BreakoutRoomcall
