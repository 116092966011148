import useSlug from "../useSlug";
import {useContext, useEffect, useState} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import moment from "moment";
import {SizeUtils} from "./SizeUtils";
import {DateUtils} from "../../Util/Utils";
import Logger from "../../Util/Logger";
import {Constants} from "../../classes/Constants";
import {DateFormates} from "../../classes/DateFormates";

export const InitialSessionMap = ({activeEu}) => {
    return {keys: [], map: {}, activeEu: activeEu}
}
export  function SessionApiV2() {
    const slug = useSlug().path;
    const {isMobile} = SizeUtils()
    const {
        getSessionsData,
        setFavoriteData,
        getSessionsSpeaker,
        state:{
            eventsAllPagesData: {
                sessions,
                sessions_categories,
                registered_sessions,
            },
            eventsPageData: {
                event, 
                details,
                options,
                translations,
            },
        }
    } = useContext(defaultContext);

    const [selectedDateV2, setSelectedDateV2] = useState(null);
    const [selectedCategoryV2, setSelectedCategoryV2] = useState(-1);
    const [selectMySessionsV2, setSelectMySessionsV2] = useState(0);
    const [filteredSessions, setFilteredSession] = useState(0);
    const [sessionMapV2, setSessionMap] = useState(0);
    const [categoreis, setCategories] = useState(null);
    const [allSession, setAllSession] = useState(null)

    const [activeEu, setActiveEu] = useState(false);



    useEffect(() => {
        const registeredMap = registered_sessions?.reduce((prev, next) => {
            return {
                ...prev,
                [next]: registered_sessions?.includes(next)
            }
        }, {})

        if (sessions){
            const categoryMap = {};
            const _sessions = sessions?.map(s => {

                return {...s, isRegistered: registeredMap[s.id] ?? false}
            })
                .filter(s => {
                    if (selectMySessionsV2){
                        return s.isRegistered
                    }
                    return  true;
                }).filter(s => {
                    if (s.category_id){
                        categoryMap[s.category_id] = true;
                    }
                    if (selectedCategoryV2 != -1){
                        return s.category_id === selectedCategoryV2
                    }
                    return  true;
                })

            const isActive = sessionMapV2?.activeEu;
            const map = _sessions?.reduce((prev, next) => {
                const obj = {...prev}
                const date = next.start
                let key = DateUtils(next.start).getDDMMYYYYSLASH()
                if (!activeEu){
                    key = getDateTime(false, date).format(DateFormates.DDMMYYYY_HYPHEN)
                }
                if (date) {
                    if (!obj.map[key]) {
                        obj.map[key] = []
                        obj.keys.push(key)
                    }
                    obj.map[key].push(next)
                }else {
                    // Logger.verbose({message: "date issue", date})
                }
                return obj
            }, InitialSessionMap({activeEu})) ?? InitialSessionMap({activeEu})

            const _cats = sessions_categories?.filter(s => categoryMap[s.id] )
            setCategories(_cats)
            setSessionMap(map)
            if (!isMobile && !selectedDateV2 || !map?.map[selectedDateV2] || isActive !==  activeEu){
                const date = new Date()
                let currentDate = DateUtils(date).getDDMMYYYYSLASH()
                let key = map?.keys?.first()
                const selected_date = map?.keys?.filter(k => k === currentDate).first()
                if (selected_date){
                    key = selected_date
                }
                if(selectedDateV2 === "favorite"){
                    setSelectedDateV2(selectedDateV2)
                }else{
                    setSelectedDateV2(key)
                }
            }
            setAllSession(_sessions)
        }

    }, [JSON.stringify(sessions), JSON.stringify(registered_sessions), selectedCategoryV2, selectMySessionsV2, activeEu, event?.timezone])



    useEffect(() => {
        if (allSession) {
            const _sessions = allSession?.filter(s => {
                if (selectedDateV2 ===  'favorite') {
                    return s.isWishlisted
                }else if (selectedDateV2) {
                    let key = DateUtils(s.start).getDDMMYYYYSLASH()
                    if (!activeEu){
                        key = DateUtils.getDateTime(false, s.start, event?.timezone ?? Constants.DEFAULT_TIMEZONE).format(DateFormates.DDMMYYYY_HYPHEN)
                    }
                    return key == selectedDateV2
                }
                return true;
            })

            setFilteredSession(_sessions)
        }
    }, [selectedDateV2, JSON.stringify(allSession), event?.timezone]);


    const getTime = (isEventTimeZone, time) => {
        //const time = "03/29/2022 08:33:00"
        return getDateTime(isEventTimeZone, time).format("HH:mm")
    };

    const getDateTime = (isEventTimeZone, time) => {
        //const time = "03/29/2022 08:33:00"
        if (isEventTimeZone){
            const times = moment(time, Constants.SESSION_DATE_FORMATE);
            return moment(times);
        }else {
            const ltz = moment.tz.guess()
            const times = moment(time, Constants.SESSION_DATE_FORMATE);
            const formated = times.tz(event?.timezone ?? Constants.DEFAULT_TIMEZONE, true);
            // const formated = moment(times)
            return formated.tz(ltz)
        }
    };

    return {
        getSessionsData: (callback) => getSessionsData(slug, callback),
        setSelectedDateV2:setSelectedDateV2,
        setSelectedCategoryV2: setSelectedCategoryV2,
        setSelectMySessionsV2:setSelectMySessionsV2,
        setFavoriteData: ({id}, callback) => {
            setFavoriteData(slug, {type:'session', id}, callback)
        },
        getZoneTime: getTime,
        getZoneDateTime: getDateTime,
        setActiveEu: setActiveEu,
        fetchSessionSpeakers: (session_id, callback) => {
            getSessionsSpeaker(slug, session_id, callback)
        },
        state: {

            monthsV2: sessionMapV2?.keys,
            categoriesV2: categoreis,
            registered_sessions: registered_sessions,

            selectedCategoryV2: selectedCategoryV2,
            selectMySessionsV2:selectMySessionsV2,
            selectedDateV2: selectedDateV2,
            timezone: event?.timezone,
            event : event,
            details:details,
            options : options,
            filteredSessions,
            sessionMapV2: sessionMapV2.map,
            sessions
        }
    }
}