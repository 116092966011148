import React,{useState, useContext,useEffect} from 'react';
import { useToasts } from "react-toast-notifications";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import useSlug from '../../../../../hooks/useSlug';
import  Sponsor from './SpComponents/Sponsor';
import SponsorCat from './SpComponents/SponsorCat';
import { ShimmerSimpleGallery, ShimmerTitle} from "react-shimmer-effects";
import {AZPagination} from "../../../../../UIComponets/AZPaggination";
import {Constants} from "../../../../../classes/Constants";
import {SearchIcon} from "../../../../../SVG/Icons";


const AllSponsors = () => {
   const slug = useSlug().absolutePath;
   const Path = (url) => slug.absolutePath + url;
   //React State
   const { addToast } = useToasts();
   const [isActive, setActive] = useState("all");
   const [isLoading, setIsLoading] = useState(false);
   
   const {
      getSponserData,
      state:{ 
         eventsAllPagesData: { 
            sponsors,
            sponsors_categories,
            pagination
         },
         eventsPageData: {
            event,
            details,
            translations,
        },
      }
   } = useContext(defaultContext);

   const [sponsorItem, setSponsorsItem] = useState(sponsors);

   useEffect(()=> {
         setIsLoading(true);
         getSponserData(slug, (res, error) => {
            if(res?.data?.status){
               setIsLoading(false);
            }
         });
   }, []);

   useEffect(() => {
      if (sponsors) {
         setSponsorsItem(sponsors);
      }
   }, [sponsors]);


   const [currentChar, setCurrentChar] = useState(null);

   const sponsorsFilter = (letter) => {
      setIsLoading(true);
      setCurrentChar(letter);
      if(letter == "All"){
         setIsLoading(false);
         setSponsorsItem(sponsors);
         return;
      }
      const updateSponsors = sponsors.filter((word) => {
         setIsLoading(false);
         return word?.name.charAt(0).toUpperCase() === letter;
      });
      setSponsorsItem(updateSponsors);
   }

   const sponsorsFilterList = (event) => {
         setIsLoading(true);
         const query = event?.target?.value;
         const updatedList = sponsors.filter((item) => {
            setIsLoading(false);
            return item?.name.toLowerCase().includes(query.toLowerCase());
         });
      setSponsorsItem(updatedList);
   }

   const sponsorsCategoryFilter = (categorieId) => {
      setActive(categorieId);
      if(categorieId == "all"){
         setSponsorsItem(sponsors);
      }else{
         const updateSponsorsCategory = sponsors && sponsors.filter((sponsor) => {
            return sponsor.category_id === categorieId;
         });
         setSponsorsItem(updateSponsorsCategory);
      }
   }
    let no_data_avail_msg = Constants.NO_DATA_AVAILABLE

    if (!(sponsors?.length > 0)){
        no_data_avail_msg = details?.no_sponsors_message ?? Constants.NO_DATA_AVAILABLE
    }
   return (
      <>
         {/* All sponsors */}
          <section className="sponsors_area mt-50">
              <div className="container">
                  <h1 className="mobile-main-heading-two"><span><i className="fa fa-angle-right"></i></span>{translations ? translations?.sponsers?.title : ""}</h1>
                  <h1 className="main_heading mb-20">{translations ? translations?.sponsers?.title : ""}</h1>
                  <div className="sponsors_searchbar_head">
                      {sponsors_categories && sponsors_categories?.length > 0 && sponsors?.length > 0 ?
                          (!isLoading ? sponsors_categories?.length > 0 ? (<SponsorCat
                              categories={sponsors_categories}
                              onClick={sponsorsCategoryFilter}
                              isActive={isActive}
                          />) : null : (<ShimmerTitle line={1} gap={10}/>)) : ""}
                      {sponsors?.length > 0 > 0 && <><div className="sponsors_searchbar_pagination destop_view">
                          <AZPagination currentChar={currentChar === "All" ? null : currentChar} onClick={(char) => sponsorsFilter(char)} pagination={pagination}/>
                      </div>
                      <div className="searchbar_right">
                          <input type="text" placeholder="Search.." name="search" className="form-control"
                                 onChange={(e) => {
                                     sponsorsFilterList(e)
                                 }}/>
                          <button type="submit">
                              <SearchIcon />
                          </button>
                      </div>
                      <div className="sponsors_searchbar_pagination mobile_view">
                          <AZPagination currentChar={currentChar === "All" ? null : currentChar} onClick={(char) => sponsorsFilter(char)} pagination={pagination}/>
                      </div>
                      </>}
                  </div>
                  {/* Sponsor List data */}
                  {!isLoading ? sponsorItem?.length > 0 ? (<Sponsor sponsorItem={sponsorItem} translations={translations}/>) 
                  : (<div className='col text-center'> <span>{no_data_avail_msg}</span></div>)
                  : (<div className='mt-40 mb-100'><ShimmerSimpleGallery row={2} col={4} imageType={'thumbnail'} imageHeight={200} caption/></div>)}
              </div>
          </section>
         {/*  All Sponsors */}
      </>
   )
}

export default AllSponsors;
