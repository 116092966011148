import React from 'react'
import { Link } from 'react-router-dom';
import { Translations } from '../../../../../hooks/shared/Translations';
import { Image } from '../../../../../UIComponets/Image'; 
import { BiLink } from "react-icons/bi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import {URLContants} from "../../../../../Model/URLContants";
import useSlug from '../../../../../hooks/useSlug';
import { FiLink } from "react-icons/fi";
import { CgPushChevronDown } from "react-icons/cg";
import ReactHtmlParser from 'react-html-parser';
import { ShimmerThumbnail } from "react-shimmer-effects";
import {VODPlayer} from "../../../../../classes/VODPlayer";
import VodFilesModel from './VodFilesModel';
import { SecurityApi } from '../../../../../hooks/shared/SecurityApi';

const VideoVaultDetailsMobile = ({vodDetails, vod_categories, isPeople, vodsDetailsItem, vodsDetailsCat, onClick, catOpend, vodToggleHandler, fetchVodRequest, isLoading, event}) => {
    const slug = useSlug().absolutePath;
    const {translations} = Translations();
    const {security} = SecurityApi();
    const mapVod = vod_categories && vod_categories?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <button className="btn btn-theme_pink" style={{background:current?.color,border:`2px solid ${current?.color}`}}>{current?.name.toUpperCase()}</button>
        }
     }, {});

     const handleFilePopupNew = (id) => {
        let filseId =  document.getElementById('files_'+id+"_d_t");
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id+"_m");
        if (filseId.style.display === "none") 
        {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }


    return (
        <>
            <div className="video_vault_details_mobile_view">
                    <div className="live_room_inner">
                    {!isLoading ? (
                        <div className="miniature_img">
                            { vodDetails && <VODPlayer object={vodDetails} source={vodDetails?.player_type} contentId={vodDetails?.vimeo_id} /> }
                        </div>
                    ) : (<ShimmerThumbnail height={246} rounded />)}
                    </div>
                    {/* Ticker Here */}
                    <div className="miniature_details_box">
                        <div className="miniature_details_innerh">
                            <div className="miniature_details_header">
                                <h4>{vodDetails?.title}</h4>
                                {vodDetails && mapVod[vodDetails?.category]}
                            </div>
                            <p>{ReactHtmlParser(vodDetails?.description ?? '')}</p>
                            <div className="miniature_details_btn miniature_details_files_popup">
                                {vodDetails?.link ? (
                                 <a href={vodDetails?.link} target={vodDetails?.target}>
                                       <button className="btn btn-theme-orange vod-li-fi"><FiLink/> {translations?.general?.link}</button>
                                 </a>
                                 ) : "" }
                                 {/* {vodDetails?.file ? (<Link to={imageWithStorage + vodDetails?.file} target={vodDetails?.target} download>
                                    <button className="btn btn-theme-orange vod-li-fi"><CgPushChevronDown/>{translations?.general?.files}</button>
                                 </Link>) : ""} */}
                                {vodDetails?.files?.length > 0 && (
                                    <button type="button" className="btn btn-theme-orange vod-li-fi" onClick={()=>handleFilePopupNew(vodDetails?.id)}><CgPushChevronDown/>{translations?.general?.files}</button>
                                )}
                                 <VodFilesModel files={vodDetails?.files} id={`${vodDetails?.id}_d_t`} close={handleClose} event={event}/>
                            </div>

                            {isPeople?.length > 0 ? (
                            <div className="miniature_spakars mt-40">
                                <h1 className="main_heading mb-20">CONTACTS</h1>
                                {isPeople && isPeople.map((peopleInfo, index)=> {
                                return (
                                    <div className="speakers_box mb-30" key={index}>
                                        <div className="speakers_img">
                                            {security === "registration" || security === "invitation" ? (
                                                <Link to={slug+`${ URLContants.NETWORK}/`+peopleInfo?.user?.id}>
                                                    <Image alt="image" src={peopleInfo?.user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'}/>
                                                </Link>) : (
                                                <Link to={slug+`${URLContants.SPEAKERS}?search=`+peopleInfo?.user?.last_name}>
                                                    <Image alt="image" src={peopleInfo?.user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'}/>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="speakers_content">
                                            {security === "registration" || security === "invitation" ? (<Link to={slug+`${URLContants.NETWORK}/`+peopleInfo?.user?.id}>
                                                <h6>{peopleInfo?.user?.name} {peopleInfo?.user?.last_name}</h6>
                                            </Link>) : (<Link to={slug+`${URLContants.SPEAKERS}?search=`+peopleInfo?.user?.last_name}>
                                                <h6>{peopleInfo?.user?.name} {peopleInfo?.user?.last_name}</h6>
                                            </Link>) }
                                        <h4>{peopleInfo?.user?.title}</h4>
                                        <p>{peopleInfo?.user?.company}</p>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>) : null}
                        </div>
                    </div>

                    <div className="vault_more_About mt-50">
                        <div className="vault_ma_heading">
                            <h4>MORE ABOUT</h4>
                            <ul>
                                {vodsDetailsCat && vodsDetailsCat.map((cat, index) => {
                                    return (
                                    <React.Fragment key={index}>
                                        <li className={index !== 0 && "set_vot_cat cat-hide"} onClick={()=> onClick(cat?.id)}>
                                            <button type="button" style={{background:cat?.color, borderColor:cat?.color}} className="btn btn-theme_pink">{cat?.name}</button>
                                        </li> 
                                    </React.Fragment>
                                    )
                                })}
                           </ul>
                           <span onClick={vodToggleHandler}>{catOpend && catOpend ? <FaAngleDown/> : <FaAngleUp className='IconUp'/>} </span>
                        </div>
                        {vodsDetailsItem && vodsDetailsItem.map((item, index) => {
                            return (<div className="row mt-30"  key={index}>
                                <div className="col-md-4 col-lg-3">
                                    <div className="video_vault_box">
                                    <div className="vault_box_img" onClick={()=> fetchVodRequest(item)}>
                                            <img src={item?.thumbnail ? item?.thumbnail : "/images/vault_img1.png"} alt={item?.title}/>
                                            <span className="video_vault_icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="79.006" height="79.006" viewBox="0 0 79.006 79.006">
                                                    <path id="Icon_material-play-circle-outline" data-name="Icon material-play-circle-outline" d="M34.6,60.279,58.3,42.5,34.6,24.727ZM42.5,3a39.5,39.5,0,1,0,39.5,39.5A39.517,39.517,0,0,0,42.5,3Zm0,71.106a31.6,31.6,0,1,1,31.6-31.6A31.644,31.644,0,0,1,42.5,74.106Z" transform="translate(-3 -3)" fill="#fdfefe" opacity="0.75"/>
                                                </svg>
                                            </span>
                                    </div> 
                                    <div className="vault_box_content mt-20">
                                            {item?.title && (<h4>{item?.title}</h4>)}
                                            {item?.description && (<p>{ ReactHtmlParser( item?.description?.substring(0, 120) ?? '')}</p>)}
                                            {/* <a href="video-vault-details.html"><button type="button" className="more_btn">More</button></a> */}
                                            <div className="video_vault_mobile_btn">
                                                {item?.link ? (
                                                    <a href={item?.link} target={item?.target} >
                                                        <button type="button" className="btn btn_border_orange"><BiLink/>{translations?.general?.link}</button>
                                                    </a>
                                                ) : null}

                                                {item?.files?.length > 0 && (
                                                    <button type="button" className="btn btn_border_orange" onClick={()=>handleFilePopup(item?.id)}><CgPushChevronDown/>{translations?.general?.files}</button>
                                                )}
                                                <VodFilesModel files={item?.files} id={`${item?.id}_m`} close={handleClose} event={event}/>
                                            </div>
                                    </div>
                                    </div>
                                </div> 
                        </div>)
                        })}
            </div>
        </div>
        </>
    )
}

export default VideoVaultDetailsMobile;
