import React, {useEffect} from 'react';
import HappingTickerList from './HappingTickerList';
import { ShimmerTitle } from "react-shimmer-effects";
import {TickerHook} from "../../../../../../hooks/shared/TickerHook";

const Happing = () => {
    const {event_ticker} = TickerHook()

    if (!event_ticker) return  <> </>
    const classes = `happing_area mt-50 ${event_ticker ? event_ticker : "d-none"}`;
    return (
        <>
            {/* start happing now */}
            <section className={classes}>
                <div className="container">
                    {event_ticker &&  event_ticker.length > 0 ? (<HappingTickerList tickers={event_ticker} /> ) : (<ShimmerTitle line={1} variant="primary" />) }
                </div>
            </section>
            {/* end happing now */}
        </>
    )
}

export default Happing;
