import React,{useState,useContext} from 'react';
import useSlug from "../../../../../hooks/useSlug";
import { SessionsIcon, ProfileIcon, PasswordIcon} from '../../../../../SVG/Icons';
import ProfileDetails from './ProfileCompnents/ProfileDetails';
import ProfileSession from './ProfileCompnents/ProfileSession';
import ProfileMenu from './ProfileCompnents/ProfileMenu';
import { ProfileUIState } from './ProfileCompnents/ProfileUIState';
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import ShoppingCardModel from './ProfileCompnents/ShoppingCardModel';
import PurchaseModel from './ProfileCompnents/PurchaseModel';
import ProfilePassword from './ProfileCompnents/ProfilePassword';
import ProfilePasswordModel from './ProfileCompnents/ProfilePasswordModel';
import { useTemplate } from '../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";

function isMenuActiveForState(state) {
   // if (state === ProfileUIState.MYSESSIONS) return false;
   return true;
}

const Profile = () => {
   const slug = useSlug().absolutePath;
   const [selectedMenu, setSelectedMenu] = useState(ProfileUIState.PERSONAL);

   const {
      state:{ 
         eventsPageData: {
            event,
            languages,
            translations,
         },
      }
   } = useContext(defaultContext);

   function isMenuActive(state) {
      return selectedMenu === state;
   }

    const {
        state: {
            layout
        }
    } = useTemplate()

    const details = TemplateDetails(layout)
    const isOnePageTemplate = details.getTemplate() === Template.ONEPAGE

   return (
      <> 
         <div className="profile_page">
            <section className="profile_area profile_mysessions_area mt-50 pb-100">
               <div className={isOnePageTemplate ? "container" : "container pt-5"}>
                  <h1 className="main_heading mb-40">
                     {isOnePageTemplate && selectedMenu === ProfileUIState.PERSONAL ? <ProfileIcon/> : null}
                     {selectedMenu === ProfileUIState.PERSONAL &&  translations?.general?.profile}
                     {isOnePageTemplate && selectedMenu === ProfileUIState.MYSESSIONS ? <SessionsIcon/> : null}
                     {selectedMenu === ProfileUIState.MYSESSIONS &&  translations?.sessions?.my_sessions}
                     {isOnePageTemplate && selectedMenu === ProfileUIState.PASSWORD ? <PasswordIcon/> : null}
                     {selectedMenu === ProfileUIState.PASSWORD && "Password"}
                  </h1>
                  <div className="row profile_reverse">
                     {isMenuActive(ProfileUIState.PERSONAL) && <ProfileDetails/> }
                     {isMenuActive(ProfileUIState.MYSESSIONS) && <ProfileSession /> }
                     {isMenuActive(ProfileUIState.PASSWORD) && <ProfilePassword /> }
                     {isMenuActiveForState(selectedMenu) && <ProfileMenu onClick={setSelectedMenu} active={selectedMenu}/>}
                  </div>
               </div>
            </section>
         </div>
         <ShoppingCardModel/>
         <PurchaseModel/>
         <ProfilePasswordModel/>
      </>
   )
}

export default Profile;
