import { CHANGE_LANGAUGE } from "../constants/ActionsConstants/actiontypes";
import createDataContext from "./createDataContext";
const LanguageReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_LANGAUGE:
      return {
        ...state,
        langauge: action.payload,
      };
    default:
      return state;
  }
};

export const changeLangauge = (dispatch) => async (langauge) => {
  localStorage.setItem('lan', langauge);
  await dispatch({ type: CHANGE_LANGAUGE, payload: langauge });
};

export const getLang = () => {
  let lang = localStorage.getItem("lan");
  // if (!lang){
  //   lang = "en"
  //   localStorage.setItem("lan", lang)
  // }
  return lang
}

export const { Context, Provider } = createDataContext(
  LanguageReducer,
  {
    changeLangauge,
    //functionss
  },
  { langauge: getLang() }
);
