import React from 'react';
import { Image } from '../../../../../../UIComponets/Image';
import { SRLWrapper } from "simple-react-lightbox";
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';

const Gallery = ({sponsorDetails, translations}) => {

    const options = {
        buttons: {
          showDownloadButton: false,
        }
    }

    return (
        <>
        {sponsorDetails?.files?.length > 0  ? (
        <div className="sd_gallery_inner main-card mb-20">
            <h1 className="main_heading mb-20">{translations?.general?.gallery}</h1>
            <SRLWrapper options={options}>
                <div className="row no-gutters">
                    {sponsorDetails && sponsorDetails?.files?.map((file, index) => {
                        return(
                            <div className="col-6 col-md-6 col-lg-6" key={index}>
                                <div className="img-box">
                                    <a href={imageWithStorage+file?.path}>
                                        <img src={imageWithStorage+file?.path} alt={file?.type}/>
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </SRLWrapper>
        </div>
        ) : ""}
        </>
    )
}

export default Gallery;
