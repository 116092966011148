import React, {useContext, useState} from "react";
import {Context as defaultContext, netwrok_addUserToVideoChat} from "../../../../../../context/DefaultSectionContext";
import {OnlineUser, OnlineOfflineUser} from "../../../../../../Util/Utils";
import {VideoCallApi} from "../../../../../../hooks/videoCallApi";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import { Image } from "../../../../../../UIComponets/Image";
import Logger from "../../../../../../Util/Logger";
import {SearchIcon} from "../../../../../../SVG/Icons";

const People = ({details}) => {
    const onlineUser = OnlineUser(details)
    const [isLoading, setLoading] = useState(false)
    const {
        addUserToVideoChat,
    } = VideoCallApi()
    const addUser = () => {
        const user_id = onlineUser.getUserId()
        setLoading(true)
        addUserToVideoChat({user_id}, (response, error) => {
            if(response?.status === 200){
                setLoading(false)
            }
        })
    }

    return <div className="br_thiscall_box event_call_box mb-20">
        <div className="user_img">
            <a href="participant-details.html">
                <Image src={onlineUser?.getAvatar()} placeholderSrc="/images/placeholder_square.jpg" alt="users image"/>
            </a>
            <span className="g_dot"></span>
        </div>
        <div className="user_details">
            <h4>{onlineUser?.getFullName()}</h4>
            <p>{onlineUser?.getDivision()}</p>
            <h6>{onlineUser?.getOrganization()}</h6>
        </div>
        <div className="br_call_btn">
            <button disabled={isLoading || details?.waitingToAccept} type="button" className="btn btn_theme_grey" onClick={addUser}>
                <IconLoader isLoading={isLoading}>
                    {details?.waitingToAccept ? "Waiting..." : "Add"}
                </IconLoader>
            </button>
        </div>
    </div>
}

export const ActiveCallOnlinePeople = () => {

    const [searchQuery, setSearchQuery] = useState('');

    const {
        state:{
            onlineUsers,
            connectedUserMap
        }
    } = useContext(defaultContext);

    const isAlreadyInCall = (u) => {
        if (!connectedUserMap) return true;

        return connectedUserMap[u.user_id] ? false : true
    }
    
    const users = onlineUsers?.members?.filter(isAlreadyInCall)

    return (
        <div className="tab-pane" id="PEOPLE" role="tabpanel">
            <div className="br_people_tab_inner">
                <div className="searchbar_right mb-30">
                    <input type="text" placeholder="Search.." name="search" onChange={(e) => setSearchQuery(e.target.value)} className="form-control"/>
                    <button type="submit">
                        <SearchIcon />
                    </button>
                </div>
                {
                    users?.filter(u => {
                    if (searchQuery?.length > 0){
                        const name = OnlineOfflineUser(u)?.getFullName()?.toLowerCase()
                        return name.includes(searchQuery.toLowerCase())
                    }
                    return true;
                    }).map((u, i) => <People key={u.user_id} details={u} />)
                }
            </div>
        </div>
    )
}