
export const Image = ({src, placeholderSrc, className, alt}) => {
        let path = "";  
        if(src){
            const isUrlAbsolute = (url) =>(url.indexOf('//') === 0 ? true : url.indexOf('://') === -1 ? false : url.indexOf('.') === -1 ? false : url.indexOf('/') === -1 ? false : url.indexOf(':') > url.indexOf('/') ? false : url.indexOf('://') < url.indexOf('.') ? true : false);
             path = isUrlAbsolute(src) ? src : process.env.REACT_APP_IMAGE_WITH_STORAGE + "/" + src;
        }else{
             path = "";
        }
        return <img
            src={path}
            onError={(e) => {
                e.target.onerror = null;
                if (placeholderSrc) e.target.src = placeholderSrc;
            }}
            alt={alt ?? "image"} className={className} />
}
