import React,{ useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { HorizontalScrollingMenu } from '../../../../../hooks/HorizontalScrollingMenu';

const VodCat = ({ filterVideoVault, vodsCat, isActive, translations}) => {

    const {
        slide,
        scrollCheck,
        anim,
        anim2,
        state:{
            scrl,
            scrollX,
            scrolEnd
        }
    } = HorizontalScrollingMenu();

    const [scrollBarArrow , setscrollBarArrow] = useState(false);

    useEffect(() => {
        if (scrl.current.scrollWidth > scrl.current.clientWidth) {
            setscrollBarArrow(true);
        }
    }, [scrollBarArrow]);

    return (
        <>
        <div className='session-category-list'>
            {scrollBarArrow ? scrollX !== 0 &&  (
            <span className="prev cat-arrow-prev cat-arrow" onClick={() => slide(-50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)}>
            <i className="fa fa-angle-left"></i>
            </span>
            ) : null}
            <ul className="video_vault_nav deshboard_video_vault_nav " ref={scrl} onScroll={scrollCheck}>
                <li className={isActive === "all" && "active"} onClick={() => filterVideoVault("all") }>
                    <Link to={"#"}>{translations?.general?.all}</Link>
                </li>
                {vodsCat && vodsCat.map((vod_categorie, index) => {
                    return (
                        <li key={index}  className={isActive === vod_categorie?.id && "active"} onClick={()=> filterVideoVault(vod_categorie?.id)}>
                            <Link to={"#"} style={{color:vod_categorie?.color}}>{vod_categorie?.name}</Link>
                        </li>
                    )
                })}
            </ul>  
            {scrollBarArrow ? !scrolEnd && (
            <span className="cat-arrow-next next cat-arrow" onClick={() => slide(+50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)} >
            <i className="fa fa-angle-right"></i>
            </span>
            ) : null}
        </div>
        </>
    )
}

export default VodCat;
