import _ from 'lodash';
import moment from 'moment';
import {DateFormates} from "../classes/DateFormates";
import {Constants} from "../classes/Constants";
import Logger from "./Logger";


export  const getLanQueryParam = (language) => {
    return {locale: language};
}

export const DayGreetingFormate = (date) => {
    return {
        day: moment(`${date}`).format("DD"), month: moment(`${date}`).format("MMM"),
        time: moment(`${date}`).format("HH:mm")
    }
}


export const Appointment = (appointment) => {
    const _appointment = {...appointment}
    const _user = _appointment?.user;
    const _info = _appointment?.info;
    const _host_user = _appointment?.host_user;
    const _host_info = _appointment?.host_info;

    const getId = () => _appointment?.id;
    const getEventId = () => _appointment?.event_id;
    const getUserId = () => _user?.id;
    const getFullName = () => {return `${_user?.name ?? ""} ${_user?.last_name ?? ""}`.capitalize()};
    const getOrganization = () => {return `${_info?.company ?? ""}`.capitalize() }
    const getAvatar =  () => { return `${_user?.avtar}`};
    const getFormatedDate = () => { return moment(`${_appointment?.date}`).format("ddd DD/MM HH:mm") }
    const getDayFormatedDate = () => { return moment(`${_appointment?.date}`).format("ddd DD/MM HH:mm") }
    const getRequestPayload = () => { return {id: getId(), user_id: getUserId(), event_id: getEventId()}}
    //sandeep added
    const getHostUserId = () => _host_user?.id;
    const getHostFullName = () => {return `${_host_user.name ?? ""} ${_host_user.last_name ?? ""}`.capitalize()};
    const getHostOrganization = () => {return `${_host_info?.company ?? ""}`.capitalize() }
    const getHostAvatar =  () => { return `${_host_user?.avtar}`};



    return {
        getId : () => getId(),
        getEventId : () =>  getEventId(),
        getUserId : () => getUserId(),
        getFullName: () => getFullName(),
        getOrganization : () => getOrganization(),
        getAvatar: () => getAvatar(),
        getFormatedDate: () => getFormatedDate(),
        getRequestPayload: () => getRequestPayload(),
        getHostUserId: () => getHostUserId(),
        getHostFullName: () => getHostFullName(),
        getHostOrganization: () => getHostOrganization(),
        getHostAvatar: () => getHostAvatar(),
        getStatus: () => _appointment.status,
        getParticiapnt: () => {return {..._user, info: _info}},
        getHost: () =>  _appointment?.host_user,
        getCanModify : () => _appointment?.can_modify,
    }
}

export const Error = (error) => {
    const _error = error;

    return {
        getResponseError: () =>  _error.response?.data?.message,
    }
}
export const TwillioParticipant = (participant) => {
    const _participant = {...participant}
    const _identity = _participant?.identity;

    const getUserID = () => {
        if (_identity){
            return _.split( _identity, '-')?.first()
        }
        return null
    }

    return {
        getUserID: () => getUserID()
    }
}
export const OnlineOfflineUser = (userdetails) => {
    const _user = {...userdetails}
    const _info = _user?.info;

    const getId = () => {
        if (_user?.id > 0) return _user?.id
        if (_user?.user_id > 0)  return _user?.user_id;
    };
    const getFullName = () => {return `${_user?.name ?? ""} ${_user?.last_name ?? ""}`.capitalize()};
    const getOrganization = () => {return `${_info?.Organization ?? ""}`.capitalize() }
    const getAvatar =  () => { return `${_user?.avtar}`};
    const getDivision = () => { return _info?.Division }
    const getCompany = () => { return _user?.company }
    const getTitle   = () => { return _user?.title }

    return {
        ...{...userdetails},
        getId : () => getId(),
        getFullName: () => getFullName(),
        getOrganization : () => getOrganization(),
        getAvatar: () => getAvatar(),
        getDivision: () => getDivision(),
        getObject: () => userdetails,
        getEmail:() => _user.email,
        getCompany : () => getCompany(),
        getTitle : () => getTitle(),
    }
}
export const OnlineUser = (userdetails) => {
    const _user = {...userdetails}
    const _info = _user?.info;

    const getId = () => _user?.id;
    const getUserId = () => _user?.user_id;
    const getFullName = () => {return `${_user?.name ?? ""} ${_user?.last_name ?? ""}`.capitalize()};
    const getOrganization = () => {return `${_info?.Organization ?? ""}`.capitalize() }
    const getAvatar =  () => { return `${_user?.avtar ? _user?.avtar : _user?.avatar}`};
    const getDivision = () => { return _info?.Division }

    return {
        getId : () => getId(),
        getUserId : () => getUserId(),
        getFullName: () => getFullName(),
        getOrganization : () => getOrganization(),
        getAvatar: () => getAvatar(),
        getDivision: () => getDivision(),
    }
}

export const UserDTO = (userdetails) => {
    const _user = {...userdetails}


    const getId = () => _user?.id;
    const getUserId = () => _user?.user_id;
    const getFullName = () => {return `${_user?.name ?? ""} ${_user?.last_name ?? ""}`.capitalize()};
    const getAvatar =  () => { return `${_user?.avtar}`};
    const getDivision = () => { return _user?.Division }
    const getCompany = () => { return _user?.company }
    const getTitle   = () => { return _user?.title }

    return {
        getId : () => getId(),
        getUserId : () => getUserId(),
        getFullName: () => getFullName(),
        getAvatar: () => getAvatar(),
        getDivision: () => getDivision(),
        getCompany : () => getCompany(),
        getTitle : () => getTitle(),
    }
}

export const PusherObject = (appointment) => {
    const _appointmentModel = {...appointment}

    const isAppointment = () => {
        return _appointmentModel?.type === "appointment"
    }

    const getObject = () => {
        return isAppointment() ? _appointmentModel?.data : null
    }

    return {
        isAppointment : () => isAppointment(),
        getObject: () => getObject()
    }
}

export const DiceMatched = (props) => {
    const _data = props;
    const _user = props?.user
    const _info = _user?.info;

    const getId = () => _user?.id;
    const getUserId = () => _user?.user_id;
    const getFullName = () => {return `${_user?.name ?? ""} ${_user?.last_name ?? ""}`.capitalize()};
    const getOrganization = () => {return `${_info?.Organization ?? ""}`.capitalize() }
    const getAvatar =  () => { return `${_user?.avtar}`};
    const getDivision = () => { return _info?.Division }
    const getMatchedOn = () => {return _data?.matched_on}
    const getTitle = () => {return _user?.title}
    const getCompany = () => {return _user?.company}
    const getDecription = () => {return _info?.description}

    return {
        getId : () => getId(),
        getUserId : () => getUserId(),
        getFullName: () => getFullName(),
        getOrganization : () => getOrganization(),
        getAvatar: () => getAvatar(),
        getDivision: () => getDivision(),
        getTitle: () => getTitle(),
        getMatchedOn: () => getMatchedOn(),
        getCompany: () => getCompany(),
        getDecription: () => getDecription()
    }
}


export const DateUtils = (date) => {
    const _date = date;

    return {
        getDDMMYYYYHyphen: () =>  moment(_date).format(DateFormates.DDMMYYYY_HYPHEN),
        getDDMMYYYYHHMMSLASH: (tz) =>  moment(_date).local(),
        getDDMMYYYYSLASH: () => {
            const  _d = moment(_date, 'DD-MM-YYYYTHH:mm:ss').format()
            const __d = moment(_d).format(DateFormates.DDMMYYYY_HYPHEN)
            return __d
        },

        dayGreetingFormate: () =>  {
            return {
                day: moment(`${_date}`).format("DD"),
                month: moment(`${_date}`).format("MMM"),
                time: moment(`${_date}`).format("HH:mm")
            }
        }
    }
}
DateUtils.getTime = (isEventTimeZone, time, etz) => {
    //const time = "03/29/2022 08:33:00"
    return DateUtils.getDateTime(isEventTimeZone, time, etz).format("HH:mm")
}

DateUtils.getDateTime = (isEventTimeZone, time, etz) => {
    //const time = "03/29/2022 08:33:00"

    if (isEventTimeZone){
        const times = moment(time, Constants.SESSION_DATE_FORMATE);
        return moment(times);
    }else {
        const ltz = moment.tz.guess()
        const times = moment(time, Constants.SESSION_DATE_FORMATE);
        const formated = times.tz(etz ?? Constants.DEFAULT_TIMEZONE, true);
        // const formated = moment(times)
        return formated.tz(ltz)
    }
}

DateUtils.getAppointmentTime = (isEventTimeZone, time, etz) => {
    //const time = "03/29/2022 08:33:00"
    return DateUtils.getAppointmentDateTime(isEventTimeZone, time, etz).format("HH:mm")
}

DateUtils.getAppointmentDateTime = (isEventTimeZone, time, etz) => {
    //const time = "03/29/2022 08:33:00"

    if (isEventTimeZone){
        const times = moment(time, Constants.APPOINTMENT_DATE_FORMATE);
        return moment(times);
    }else {
        const ltz = moment.tz.guess()
        const times = moment(time, Constants.APPOINTMENT_DATE_FORMATE);
        const formated = times.tz(etz ?? Constants.DEFAULT_TIMEZONE, true);
        // const formated = moment(times)
        return formated.tz(ltz)
    }
}

export const SlugUtils = (string) => {
    const _str = string;

    return {
        getStringFromHashString: () => _str?.split("#").last().replace("-", " "),
        getHashString: () => "#" + _str?.replace(" ", "-")
    }
}

export const URLBuilder = (url) => {
    const _url = url;
    const param = {};

    const addURLParam = (key, value) => {
        if (value){
            param[key] = value;
        }
    }
    const getURL = () => {
        let url = _url;
        if (url){
            let paramString = ""
            _.map(param, (value, key) => {
                if (paramString !== ""){
                    paramString =  paramString.concat("&")
                }
                paramString = paramString.concat(key + "=", value)
            })
            url = url.concat("?", paramString)
        }
        return url;
    }
    return {
        addURLParam: addURLParam,
        getURL: getURL
    }
}

export const GetServeyUrl = (user) => {
    const urlUtils = URLBuilder("https://cvent.me/xwZWMq")
    urlUtils.addURLParam("first_name", user?.name)
    urlUtils.addURLParam("last_name", user?.last_name)
    urlUtils.addURLParam("email_address", user?.email)
    return urlUtils.getURL()
        // https://cvent.me/xwZWMq?first_name={$first_name}&last_name{$last_name}&email_address={$ email}&company={$company_name}
}

export default function LocationPathSaperator(pathname) {
    const segment_array = pathname.split('/');
    const [, identifier, slug, page, id1] = segment_array;
    return [identifier, slug, page, id1]
}

export const UserDetails = (user_details) => {
    const networking_enabled = user_details?.networking_enabled?.first() !== undefined ? user_details.networking_enabled?.first() === "1" : true
    const notification_enabled =  user_details?.notification_enabled?.first() !== undefined ? user_details.notification_enabled?.first() === "1" : true

    return {
        isNetworkingEnabled: networking_enabled,
        isNotificationEnabled: notification_enabled,
    }
}
