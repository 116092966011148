import {useContext, useEffect, useState} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import useSlug from "../useSlug";
import {Context as AuthContext} from "../../context/AuthContext";

export function UserApi() {
    const slug = useSlug()

    const context = useContext(defaultContext)
    const eventsPageData = context?.state?.eventsPageData;
    const user = eventsPageData?.user;
    const user_details = eventsPageData?.user_details;
    const user_interests = eventsPageData?.user_interests ?? [];
    const interests = eventsPageData?.interests;

    const [userInteresetMap, setUserInteresetMap] = useState(null)
    const {
        updateLoginStatus
    } = useContext(AuthContext);

    useEffect(() => {
        if (user_interests){
            const userInteresetMap = user_interests && user_interests?.reduce((prev, next) => {
                if (!next?.id)  return prev;
                return {
                    ...prev,
                    [next?.id]: next
                }
            }, {})
            setUserInteresetMap(userInteresetMap)
        }
    }, [JSON.stringify(user_interests)])

    return {
        user,
        user_interests,
        interests,
        userInteresetMap,
        rollDiceInterestUpdate: (items, callback) => context?.rollDiceInterestUpdate(slug, items, callback),
        logout: (callback) => {
            context?.logout(slug, context?.state, () => {
                updateLoginStatus()
                callback && callback()
            })

        }
    }
}
