import React, {useContext} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import SlideDecks from "../../templates/Event/Components/Pages/Home/Component/SlideDecks";
import Sponsors from "../../templates/Event/Components/Pages/Home/Component/Sponsors";
import {SettingOptions} from "../../classes/SettingOptions";

export function AppSettingOptions() {

    const {
        state: {
            eventsPageData: {
                options,
            },
        },
    } = useContext(defaultContext)

    const includes = (option) => options?.includes(option) ?? false
    return {
        isTickerEnable:  includes(SettingOptions.TICKER),
        isSlidedackEnable:  includes(SettingOptions.SLIDE_DECKS),
        isSponsorsEnable:  includes(SettingOptions.SPONSERS),
        isSpeakersEnable:  includes(SettingOptions.SPEAKERS),
        isDailyTriviaEnable:  includes(SettingOptions.DAILY_TRIVIA),
        isSocialWallEnable:  includes(SettingOptions.SOCIAL_WALL),
        isSessionEnable:  includes(SettingOptions.SESSIONS),
        isVODEnable:  includes(SettingOptions.VOD),
        isDiceEnable: includes(SettingOptions.NT_ROLLING_DICE),
        isNetworkingEnable: includes(SettingOptions.NETWORK),
    }
}