import React,{useState, useEffect, useRef} from 'react';
import ReactHtmlParser from "react-html-parser";
import { AZPagination } from '../../../../../UIComponets/AZPaggination';
import { FaqApi } from '../../../../../hooks/shared/FaqApi';
import {ShimmerTitle,  ShimmerSimpleGallery } from "react-shimmer-effects";
import { Translations } from '../../../../../hooks/shared/Translations';
import FaqCategory from './FaqCategory';
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import { SearchIcon } from '../../../../../SVG/Icons';
import {useTemplate} from "../../../../../hooks/shared/useTemplate";
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";

const Faq = () => {
   const inputRef = useRef(null);
   const {
      searchFaq,
      filterFaqCategory,
      charFilter,
      state:{
            faqList,
            faqCategorys,
            isActive,
            isLoading,
            currentChar
      } 
   } = FaqApi();

   const {
      translations
   } = Translations();

    const {
    state: {
        layout
    }
  } = useTemplate()

  const templateDetails = TemplateDetails(layout)
  const isOnePageTemplate = templateDetails.getTemplate() === Template.ONEPAGE;

   return (
      <>
         <div className="wrapper sponsors_wrapper">
            <section className="mobile_faq_area faq_area mt-40">
               <div className="container">
                  <div className="m_faq_heading">
                     {!isOnePageTemplate &&  <h1 className="mobile-main-heading-two"><span><i className="fa fa-angle-right"></i></span>{translations?.general?.faq}</h1> }
                     <h1 className="main_heading mb-20">{translations?.general?.faq}</h1>
                     <div className="searchbar_right mb-20">
                        <input type="text" placeholder="Search.." ref={inputRef} name="search"  onChange={(e) => searchFaq(e?.target?.value)} className="form-control"/>
                        <button type="button"> <IconLoader isLoading={isLoading}><SearchIcon/></IconLoader></button>
                     </div>
                     <div className="sponsors_searchbar_pagination mb-40 d-flex justify-content-center">
                        <AZPagination currentChar={currentChar} onClick={(char) => charFilter(char)}/>
                     </div>
                  </div>
                  <div className="accordion" id="accordionExample">
                        {faqList && faqList.map((fq, index) => {
                           return (
                              <div className="item" key={index}>
                                 <div className="item-header" id="headingOne">
                                    <h2 className="mb-0">
                                       <button className="btn btn-link collapsed text-left" type="button" data-toggle="collapse" data-target={`#collapseOneM_${fq?.id}`} aria-expanded="true" aria-controls="collapseOne">
                                          {fq?.question} <i className="fa fa-angle-right"></i>
                                       </button>
                                    </h2>
                                 </div>
                                 <div id={`collapseOneM_${fq?.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="t-p">
                                       <p>{ReactHtmlParser(fq?.answer ?? '')}</p>
                                    </div>
                                 </div>
                              </div>
                           )
                        })
                     }
                  </div>
               </div>
            </section>
            <section className="faq_area mt-50">
               <div className="container">
                  {!isLoading ? (
                        <>
                           <h1 className="main_heading mb-20">{translations?.general?.faq}</h1>
                           <div className="slide_decks_header mb-40">
                              {faqCategorys?.length > 0 ? 
                              !isLoading ? (
                              <FaqCategory
                                 categorys={faqCategorys} 
                                 isActive={isActive} 
                                 onClick={filterFaqCategory}
                                 translations={translations}
                              />) : (<ShimmerTitle line={1}  variant="primary" className="shimmer-trivias-cat"/>)
                              : null}
                              <div className="searchbar_right">
                                 <input type="text" placeholder="Search.." ref={inputRef} name="search" onChange={(e) => searchFaq(e?.target?.value)} className="form-control" />
                                 <button type="button"><IconLoader isLoading={isLoading}><SearchIcon/></IconLoader></button>
                              </div>
                           </div>

                           <div className="accordion" id="accordionExample">
                              {faqList && faqList.map((fq, index) => {
                              return (<div className="item" key={index}>
                                       <div className="item-header" id="headingOne">
                                          <h2 className="mb-0">
                                             <button className="btn btn-link collapsed text-left" type="button" data-toggle="collapse" data-target={`#collapseOne_${fq?.id}`} aria-expanded="true" aria-controls="collapseOne">
                                             <i className="fa fa-angle-right"></i> {fq?.question}
                                             </button>
                                          </h2>
                                       </div>
                                       <div id={`collapseOne_${fq?.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                          <div className="t-p">
                                             <p>{ReactHtmlParser(fq?.answer ?? '')}</p>
                                          </div>
                                       </div>
                                    </div>
                                    )
                                 })
                              }
                           </div>
                        </>
                  ) : <ShimmerTitle line={5} gap={10} />}
               </div>
            </section>
         </div>
      </>
   )
}

export default Faq
