import React, {useContext, useState} from 'react';
import moment from 'moment';
import { Image } from '../../../../../UIComponets/Image';
import { FaRegFile } from "react-icons/fa";
import { MdWifiTethering } from "react-icons/md";
import { Link } from 'react-router-dom';
import SessionFilesModel from './SessionFilesModel';
import { WifiIcon, FavoriteIcon, LinkIcon, EuroSymbol } from '../../../../../SVG/Icons';
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import {ResponseWrapper} from "../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import {Error} from "../../../../../Util/Utils";
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import {useToasts} from "react-toast-notifications";
import useSlug from "../../../../../hooks/useSlug";
import {SessionApi} from "../../../../../hooks/shared/SessionApi";
import {SessionModel} from "../../../../../Model/SessionModel";
import { SessionStatus } from '../../../../../Model/SesstionStatus';
import ReactHtmlParser from 'react-html-parser';
import { imageWithStorage } from '../../../../../constants/url/urlConstants';
import { Translations } from '../../../../../hooks/shared/Translations';
import {SessionApiV2} from "../../../../../hooks/shared/SessionApi2";
import { URLContants } from '../../../../../Model/URLContants';
import { EventApi } from '../../../../../hooks/shared/EventApi';

const LoadingContainer = (session_id, isLoading) => (prev) =>{
    return {
        ...prev,
        [session_id]: isLoading
    }
}
function SessionsDetails({ sessionItem, sessionCat, registered_sessions, timezone, isActiveEu, setReplay}) {

    const {
        setFavoriteData,
        state: {
            event
        },
    } = SessionApi();
    const [showfiles, setShowfiles] = useState(false);

    const {
        getZoneTime,
        getZoneDateTime,
        fetchSessionSpeakers,
    } = SessionApiV2()

    const {
        state:{
            details,
            options
        }
     } = EventApi();

    const {
        translations
    } = Translations();

    const map = sessionCat && sessionCat?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <h1 style={{background:current?.color}} className="mb-40">{current?.name.toUpperCase()}</h1>
        }
    }, {});



    const [loadingContainer, setLoadingContainer] = useState({})
    const [favLoadingContainer, setFavLoadingContainer] = useState({})
    const {
        enrollSession,
        unEnrollSession
    } = useContext(defaultContext);
    const { addToast } = useToasts();
    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;
    const enrollSessionRequest = (session_id) => {

        setLoadingContainer(LoadingContainer(session_id, true))
        enrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false))
        })
    }

    const unEnrollSessionRequest = (session_id) => {
        setLoadingContainer(LoadingContainer(session_id, true))
        unEnrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false));
        })
    }

    const isLoading = (id) => loadingContainer[id]
    const isFavLoading = (id) => favLoadingContainer[id]

    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id);
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleFavoirte = (id) => {
        setFavLoadingContainer(LoadingContainer(id, true))
        setFavoriteData({id}, (response, error) => {
            if (response?.data?.status) {

            }
            setFavLoadingContainer(LoadingContainer(id, false))
        });
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }
    const [fetchLoadingContainer, setFetchLoadingContainer] = useState({})

    const fetchSessionDetails = (item) => {
        if (item.people === null && fetchLoadingContainer[item?.id] === undefined){
            setFetchLoadingContainer((prev) => {
                return {
                    ...prev,
                    [item?.id]: true
                }
            })
            fetchSessionSpeakers(item.id, (response, error) => {
                setFetchLoadingContainer((prev) => {
                    return {
                        ...prev,
                        [item?.id]: false
                    }
                })
            })
        }
    }

    return (
        <>
            {sessionItem && sessionItem.map(i => SessionModel(i)).map((item, index)=> {
                const {id, title, link, file, files, start, end, time, date, is_live, target} = item;        
                return (
                
                    <div className="session_accordion_box mb-20" key={item?.id}>

                        <div className="accordion destop_ed_time" id={`accordionExample_${id}`} onClick={() => fetchSessionDetails(item)}>
                            <div className="session_accordion_header">
                                <div className="date">
                                    <p>
                                        {/* {SessionDateFormat(start, end, getLocale()).dateTime} */}
                                        {getZoneTime(isActiveEu, start)}-{getZoneTime(isActiveEu, end)}<br />
                                        { getZoneDateTime(isActiveEu, start).format("ddd DD MMM") }
                                    </p>
                                </div>
                                <div className="description collapse">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={`#collapseOne_${id}`} aria-expanded="true" aria-controls="collapseOne">
                                        <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                                        <h4 title={title}>{title}</h4>
                                    </button>
                                    {files && files.length > 0 ? (<Link to={"#"} title="Files" className='session_link ml1' onClick={()=>handleFilePopup(id)}><FaRegFile></FaRegFile></Link>) : ""}
                                    <SessionFilesModel files={files} id={id} close={handleClose} event={event}/>
                                    {item?.meta?.external_link ? (<a className='session_link' href={item?.meta?.external_link} target={target} title="Link"><LinkIcon/></a> ) : ""}
                                    {item.getStatus() === SessionStatus.REGISTERED && (<a className='add-event-link ml-1' title="Add To Calendar"  href={`https://www.addevent.com/dir/?client=${process.env.REACT_APP_ADD_EVENT_CLIENTID}&start=${moment(start,'DD-MM-YYYYTHH:mm:ss').format('DD-MM-YYYYTHH:mm:ss')}&end=${moment(end,'DD-MM-YYYYTHH:mm:ss').format('DD-MM-YYYYTHH:mm:ss')}&title=${title}&timezone=${event?.timezone}`} target="_blank"><i class="fa fa-calendar-plus"></i></a>)}
                                </div>
                            </div>
                            <div id={`collapseOne_${id}`} className="collapse" aria-labelledby="headingOne"  data-parent={`#accordionExample_${id}`}>
                                <div className="session_info_box">
                                    <div className="row">
                                            <div className={`${files?.length > 0  ? "" : "col-xl-12"  } col-md-12 col-lg-8`}>
                                                <div className="emerging_sectors">
                                                    <div className="row">
                                                    {fetchLoadingContainer[item.id] === true ? <p className={"text-center w-100"}>Fetching Speakers <IconLoader isLoading={true}/></p> : item?.people?.map((person , index) => {
                                                        return (
                                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                                <div className="client_info mb-30" key={index}>
                                                                    <div className="client_info_img">
                                                                        <Image src={person?.user?.avtar} placeholderSrc={"/images/placeholder_square.jpg"} alt={"people pic"}/>
                                                                    </div>
                                                                    <div className="client_info_content">
                                                                        <h4>
                                                                           <Link to={Path(URLContants.SPEAKERS)+`?search=${person?.user?.last_name}`}> {person?.user?.name} {person?.user?.last_name}</Link>
                                                                        </h4>
                                                                        <p>{person?.user?.title}</p>
                                                                        <h6>{person?.user?.company}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        })}
                                                    </div>
                                                    {map[item?.category_id]}
                                                    <p>{ReactHtmlParser(item?.detail ?? '')}</p>
                                                </div>
                                            </div>

                                        <div className="col-md-12 col-lg-4">
                                        {files?.length > 0 ? (
                                            <div className="session_info_file">
                                                    <h2>{translations?.general?.files}</h2>
                                                        <ul className="list-group">
                                                        {files && files.map((file, index)=>{
                                                        return(
                                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="file_name">{file?.filename}</span>
                                                                <a href={file && imageWithStorage+file?.path} target="_blank" rel="noopener noreferrer" download>
                                                                    <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                                    <g id="Group_1947" data-name="Group 1947">
                                                                    <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                                    <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                                    </g>
                                                                    <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                                    </g>
                                                                    </svg>
                                                                </a>
                                                        </li>
                                                        )
                                                        })}
                                                </ul>
                                            </div>)
                                            : null}
                                            {files?.length > 1 && (
                                                
                                                <a href={`${process.env.REACT_APP_API_HOST}/v2.0/zip-download/${event?.id}?module_id=${id}&type=timetable`} target="_blank" className="text-dark">
                                                    <span className="mr-2">
                                                        <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                        <g id="Group_1947" data-name="Group 1947">
                                                        <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                        <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                        </g>
                                                        <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                        </g>
                                                        </svg>
                                                    </span>
                                                    <span> Download All Files </span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!item?.meta?.hide_action_button && (<div className="session_accordion_btn">
                            {item.getStatus() === SessionStatus.JOIN && (<a href={link} target={target}><button type="button" className="btn join">JOIN</button></a>)}
                            {item.getStatus() === SessionStatus.REGISTERED && (<button type="button" className="btn registered" onClick={() => unEnrollSessionRequest(id)}><IconLoader isLoading={isLoading(id)}>REGISTERED</IconLoader></button>)}
                            {item.getStatus() === SessionStatus.REGISTER && (<button disabled={isLoading(id)} type="button" className="btn register" onClick={() => enrollSessionRequest(id)}> <IconLoader isLoading={isLoading(id)}> REGISTER </IconLoader></button>)}
                            {item.getStatus() === SessionStatus.ENDED && (<button type="button" className="btn ended">ENDED</button>)}
                            {item.getStatus() === SessionStatus.REPLAY && (<button type="button" data-toggle="modal" data-target="#replay-btn-modal"  className="btn replay" onClick={() => setReplay({meta:item?.meta})}>REPLAY</button>)}
                        </div>)}
                        {options && options.includes('show_session_favourites') && <div className={`session_accordion_fav ${item?.meta?.hide_action_button ? 'fav-meta-add' : ''}`}>
                            <button type="button" className="btn" onClick={() => handleFavoirte(id)}>
                                <IconLoader isLoading={favLoadingContainer[id]}>
                                    <FavoriteIcon color={item?.isWishlisted ? details?.primary_color : "none"} width="30" height="27.525" stroke={details?.primary_color}/>
                                </IconLoader>
                            </button>
                            {item.is_live === 1 && (<div className="session_accordion_wifi"><WifiIcon /></div>)}
                        </div>
                        }
                    </div>
                )
            })}
        </>
    )
}

export default SessionsDetails;
