import React, {useState, useContext} from 'react';
import moment from 'moment';
import { Image } from '../../../../../../UIComponets/Image';
import {FavoriteIcon, LinkIcon ,EuroSymbol} from './../../../../../../SVG/Icons';
import { Link } from 'react-router-dom';
import { Translations } from '../../../../../../hooks/shared/Translations';
import { FaRegFile } from "react-icons/fa";
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import {Error} from "../../../../../../Util/Utils";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import {useToasts} from "react-toast-notifications";
import useSlug from "../../../../../../hooks/useSlug";
import {SessionModel} from "../../../../../../Model/SessionModel";
import { SessionStatus } from '../../../../../../Model/SesstionStatus';
import ReactHtmlParser from 'react-html-parser';
import SessionFilesModel from '../../ActiveSessions/SessionFilesModel';
import { SessionApiV2 } from '../../../../../../hooks/shared/SessionApi2';
import { URLContants } from '../../../../../../Model/URLContants';
import { EventApi } from '../../../../../../hooks/shared/EventApi';

const LoadingContainer = (session_id, isLoading) => (prev) =>{
    return {
        ...prev,
        [session_id]: isLoading
    }
}

const ActiveSessionList = (props) => {
    const { sessions, sessions_categories, registered_sessions, onClick, favorite, event , setReplay} = props;
    const {
        getZoneTime,
        fetchSessionSpeakers
    } = SessionApiV2();

    const {
        state:{
            details,
            options
        }
    } = EventApi();

    const getTime = (time) => {
        return  getZoneTime(props.isActiveEu, time)
    };
    const {translations} = Translations();
    const mapCategoryName = sessions_categories?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <h1 style={{background:current?.color}} className="mb-40">{current?.name.toUpperCase()}</h1>
        }
    }, {});

    const mapRegistered = registered_sessions?.reduce((prev, current) => {
        return {
            ...prev,
            [current]: true
        }
    }, {});

    const [loadingContainer, setLoadingContainer] = useState({})
    const {
        enrollSession,
        unEnrollSession
    } = useContext(defaultContext);
    const { addToast } = useToasts();
    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;
    const enrollSessionRequest = (session_id) => {
        setLoadingContainer(LoadingContainer(session_id, true))
        enrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false))
        })
    }

    const unEnrollSessionRequest = (session_id) => {
        setLoadingContainer(LoadingContainer(session_id, true))
        unEnrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false));
        })
    }

    const isLoading = (id) => loadingContainer[id]

    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id);
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }

    const [fetchLoadingContainer, setFetchLoadingContainer] = useState({})

    const fetchSessionDetails = (item) => {
        if (item.people === null && fetchLoadingContainer[item?.id] === undefined){
            setFetchLoadingContainer((prev) => {
                return {
                    ...prev,
                    [item?.id]: true
                }
            })
            fetchSessionSpeakers(item.id, (response, error) => {
                setFetchLoadingContainer((prev) => {
                    return {
                        ...prev,
                        [item?.id]: false
                    }
                })
            })
        }
    }

    return (
        <>
            {sessions && sessions.filter(s => s.is_live === 1).map(i => {
                const session = SessionModel(i);
                session.setRegistered(mapRegistered[i.id] ? true : false)
                return session
            }).map((session, index)=> {
                return (
                    <div key={index} className="accordion_items mb-20" onClick={() => fetchSessionDetails(session)} >
                        <div className="ed_time_box">
                            <div className="row align-items-center">
                                <div className="col-md-2 col-lg-2">
                                    <div className="date">
                                        <p>
                                            {getTime(session?.start)}-{getTime(session?.end)}<br />
                                            { moment(`${session?.date}`).format("ddd DD MMM") }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-7">
                                    <div className="description">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={"#collapseOne_" + session?.id} aria-expanded="true" aria-controls="collapseOne">
                                            <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                                            <span className="coll_header">{session?.title}</span>   
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="ed_btn">
                                        {session?.files?.length > 0 ? (<Link to={"#"} className="mr-2 file"  onClick={()=>handleFilePopup(session?.id)}><FaRegFile/></Link>) : null}
                                        <SessionFilesModel files={session?.files} id={session?.id} close={handleClose} event={event}/>
                                        {session?.meta?.external_link ? (<a className='mr-2' href={session?.meta?.external_link} target={session?.target}><LinkIcon/></a>) : ""}
                                        {options && options.includes('show_session_favourites') && (<Link to={"#"} onClick={() => onClick(session?.id)} className={session?.meta?.hide_action_button ? "mr-3" : ""}>
                                            <IconLoader isLoading={favorite.includes(session?.id) ? true : false}>
                                                <FavoriteIcon color={session?.isWishlisted ? details?.primary_color : "none"} width="32" height="29.525" stroke={details?.primary_color}/>
                                            </IconLoader>
                                        </Link>)}
                                        {!session?.meta?.hide_action_button && (<>
                                        {session.getStatus() === SessionStatus.JOIN && (<a href={session?.link} target={session?.target}><button type="button" className="btn btn_theme_pink join">JOIN</button></a>)}
                                        {session.getStatus() === SessionStatus.REGISTERED && (<button type="button" className="btn btn_theme_pink registered"  onClick={() => unEnrollSessionRequest(session?.id)}><IconLoader isLoading={isLoading(session?.id)}>REGISTERED</IconLoader></button>)}
                                        {session.getStatus() === SessionStatus.REGISTER && (<button disabled={isLoading(session?.id)} type="button" className="btn btn_theme_pink register" onClick={() => enrollSessionRequest(session?.id)}> <IconLoader isLoading={isLoading(session?.id)}> REGISTER </IconLoader></button>)}
                                        {session.getStatus() === SessionStatus.ENDED && (<button type="button" className="btn btn_theme_pink ended">ENDED</button>)}
                                        {session.getStatus() === SessionStatus.REPLAY && (<button type="button" data-toggle="modal" data-target="#replay-btn-modal"  className="btn btn_theme_pink replay" onClick={() => setReplay({meta:session?.meta})}>REPLAY</button>)}
                                        </>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id={"collapseOne_"+ session?.id} className="collapse"  aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="session_info_box">
                                <div className="row align-items-center">
                                    <div className={`${session?.files?.length > 0  ? "" : "col-xl-12"  } col-md-12 col-lg-8` }>
                                         <div className="session_info_procontent">
                                             {
                                                 fetchLoadingContainer[session.id] === true ? <p className={"text-center w-100"}>Fetching Speakers <IconLoader isLoading={true}/></p> : session?.people && session?.people.map((person , index) => {
                                                 return (
                                                     <div className='col-md-6 col-lg-6'>
                                                         <div className="client_info mb-20" key={index}>
                                                             <div className="client_info_img">
                                                                 <Image src={person?.user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'} alt={'speaker pic'}/>
                                                             </div>
                                                             <div className="client_info_content">
                                                                 <h4>
                                                                     <Link to={Path(URLContants.SPEAKERS)+`?search=${person?.user?.last_name}`}> {person?.user?.name} {person?.user?.last_name}</Link>
                                                                </h4>
                                                                 <p>{person?.user?.title}</p>
                                                                 <h6>{person?.user?.company}</h6>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 )
                                                 })
                                             }
                                            <div className="emerging_sectors">
                                             
                                                {mapCategoryName[session?.category_id]}
                                                <p>{ReactHtmlParser(session?.detail ?? '')}</p>
                                            </div>
                                         </div>
                                    </div>
                                    <div className="col-md-12 col-lg-4"> 
                                        {session?.files?.length > 0 ? (
                                            <div className="session_info_file">
                                                    <h2>{translations?.general?.files}</h2>
                                                        <ul className="list-group">
                                                        {session.files && session.files.map((file, index)=>{
                                                        return(
                                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="file_name">{file?.filename}</span>
                                                                <a href={file && imageWithStorage+file?.path} target="_blank" rel="noopener noreferrer" download>
                                                                    <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                                    <g id="Group_1947" data-name="Group 1947">
                                                                    <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                                    <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                                    </g>
                                                                    <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                                    </g>
                                                                    </svg>
                                                                </a>
                                                        </li>
                                                        )
                                                        })}
                                                </ul>
                                            </div>) 
                                            : null} 
                                            {session?.files?.length > 1 && (
                                                <a href={`${process.env.REACT_APP_API_HOST}/v2.0/zip-download/${event?.id}?module_id=${session?.id}&type=timetable`} target="_blank" className="text-dark">
                                                    <span className="mr-2">
                                                        <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                        <g id="Group_1947" data-name="Group 1947">
                                                        <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                        <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                        </g>
                                                        <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                        </g>
                                                        </svg>
                                                    </span>
                                                    <span>{translations?.event_info?.download_all_files_text}</span>
                                                </a>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

        </>
    )
}

export default ActiveSessionList
