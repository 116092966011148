import {useEffect, useState} from "react";
import $ from 'jquery';

export function SizeUtils() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const win = $(window)
        win.resize(function() {
            setWidth(win.width())
            setHeight(win.height())
        })
        return () => {
            win.off("resize");
        }
    }, [])

    useEffect(() => {
       setIsMobile(width >= 320 && width <= 760)
    }, [width])

    return {
        width: width,
        height: height,
        isMobile: isMobile
    }
}