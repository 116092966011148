import React from 'react';
import { Link } from 'react-router-dom';
import useSlug from '../../../../../../hooks/useSlug';
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';

const Sponsor = ({sponsorItem, translations}) => {
    const slug = useSlug().absolutePath;
    return (
        <>
            <div className="row mt-40">
                {sponsorItem && sponsorItem?.map((sponsor, key) => {
                    const {id, name, description, logo} = sponsor;
                    return (
                        <div key={key} className="col-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="sponsors_box">
                                <div className="img_box">
                                    <Link to={slug + '/sponsors-details/' + id}>
                                        <img src={imageWithStorage + logo} alt=""/>
                                    </Link>
                                </div>
                                <div className="content_box">
                                    <h4>{name}</h4>
                                    <p>{description?.substr(0, 150)}</p>
                                    <Link to={slug + '/sponsors-details/' + id}>
                                        <button type="button" className="btn more">{translations?.general.more}</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Sponsor;
