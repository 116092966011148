export default {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
        border: '1px solid #713200',
        padding: '16px',
        zIndex: 1,
        color: '#713200',
    }
}