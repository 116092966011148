import {useContext} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import useSlug from "../useSlug";

export function LiveSection({activeId}){
    const slug = useSlug()
    const {
        state: {
            eventsAllPagesData: {
                channels,
            },
            eventsPageData: {
                translations,
            }
        },
        getChannels
    } = useContext(defaultContext)

    const getChannelsRequest = (callback) => {
        getChannels(slug.path, callback)
    }
    const selected =  activeId ? channels?.filter((channel) =>{
        return channel?.id === activeId;
    }).first() : channels?.first()

    return {
        channels: channels?.filter(s => s.status),
        getChannels: getChannelsRequest,
        translations: translations,
        slug:   slug,
        channelInfo: selected
    }
}