import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState} from "react";
import isMe from "../../../../../hooks/isMe";
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import {SendIcon} from "../../../../../SVG/Icons";
import useSlug from "../../../../../hooks/useSlug";
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import {ResponseWrapper} from "../../../../../Model/ResponseWrapper";
import {InteractionSettings} from "../../../../../hooks/shared/InteractionSettings";
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import {useToasts} from "react-toast-notifications";
import {Error} from "../../../../../Util/Utils";
const QuestionAndAnswer = (props) => {
    const { addToast } = useToasts();
    // const [answer, setAnswer] = useState('')
    // const [isSending, setIsSending] = useState(false)

    const onSendMessageEnter = (e) => {
        if (e.key === "Enter" && props?.answer?.length > 0) {
            sendAnswerRequest()
        }
    }

    const sendAnswerRequest = () => {
        props?.setIsLoading({isLoading: true, question_id: props?.id})
        props.sendAnswer(props.slug.path, {question_id: props.id, channel_id: props?.channel_id, answer: props.answer}, (response, error) => {
            props?.setIsLoading({isLoading: false, question_id: props?.id})
            if (response){
                const wrapper = ResponseWrapper(response)
                if (wrapper.getStatus()){
                    props?.setAnswer({answer:'', question_id: props.id})
                    const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                    // addToast(wrapper.getMessage(), {appearance, autoDismiss})
                }
                wrapper.getMessage()
            }
        })
    }

    const uiIdentity = `collapse-${props.id}`
    const headingID = `heading-${props.id}`
    return <div key={props.id} className="item mb-40">
        <div className="item-header" id={headingID}>
            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                    data-target={`#${uiIdentity}`} aria-expanded="true" aria-controls={uiIdentity}>
                <span className="icon"> <i className="fa fa-angle-right"></i></span>
                <span className="qa_accordion_header">
                                                  <h4>{props?.question}</h4>
                                                  <p>{`${props?.time} ${ isMe(props?.user_id) ? "Me" : props.user_name}`}<span className="anspink">{props.answers?.length} answers</span></p>
                                               </span>

            </button>
        </div>
        <div id={uiIdentity} className="collapse " aria-labelledby={headingID}
             data-parent="#accordionExample">
            <div className="qa_ac_collapse">
                <ul>
                    {props.answers?.map(a => {
                        return <li className={`${isMe(a.user_id) ? "me" : "moderator"}`}>
                            <h4>{a.answer}</h4>
                            <p>{a.time} <span>{`${isMe(a.user_id) ? "Me" : "Moderator"}`}</span></p>
                        </li>
                    })}
                    <div className="send_input_box">
                        {/*<input type="text" className="form-control"*/}
                        <input disabled={props?.isLoading} type="text" value={props?.answer} className="form-control"
                               id="send" placeholder="Message" disabled={props?.isLoading}
                               onChange={e => props.setAnswer({answer: e.target.value, question_id: props.id})}
                            // ref={messageFieldRef}
                               onKeyDown={onSendMessageEnter}
                        />
                        <IconLoader isLoading={props?.isLoading}>
                            <SendIcon onClick={() => sendAnswerRequest({question_id: props?.question?.id, answer: props?.answer})}/>
                        </IconLoader>
                    </div>
                </ul>
            </div>
        </div>
    </div>
}
export const QATab = forwardRef((props, ref) => {
    const {
        selectedChannel,
        qa,
        translations,
        isChatForEach
    } = InteractionSettings({})
    const { addToast } = useToasts();
    const questions = qa?.questions

    const slug = useSlug()
    const [isSending, setIsSending] = useState(false)
    const [question, setQuestion] = useState('')
    const messageFieldRef = React.useRef();

    const {
        sendQuestion,
        sendAnswer
    } = useContext(defaultContext)

    const [channel_id, setChannelId] = useState(selectedChannel?.current_channel?.id)
    useEffect(() => {
        setChannelId(selectedChannel?.current_channel?.id)

    }, [selectedChannel])
    const [answerContainer, setAnswerContainer] = useState({})

    useImperativeHandle(ref, () => ({
        qaEvent(data) {
            const index = qa.questions?.findIndex((q) => q.id === data.question_id)
            if (index !== -1){
                const id = `#heading-${data.question_id} button.btn.btn-link`
                const el = window.$(id)
                if(el.hasClass("collapsed")){
                    el.click()
                }
            }
        }
    }));
    const setIsLoading = ({isLoading, question_id}) => {
        setAnswerContainer((pre) => {
            return {
                ...pre,
                [question_id] : {
                    ...pre[question_id] ?? {},
                    isLoading: isLoading
                }
            }
        })
    }
    const setAnswer = ({answer, question_id}) => {
        setAnswerContainer((pre) => {
            return {
                ...pre,
                [question_id] : {
                    ...pre[question_id] ?? {},
                    answer: answer
                }
            }
        })
    }
    const onSendMessageEnter = (e) => {
        if (e.key === "Enter" && question?.length > 0) {
            sendQuestionRequest()
        }
    }
    const sendQuestionRequest = () => {
        setIsSending(true)
        sendQuestion(slug.path, {question, channel_id}, (response, error) => {
            messageFieldRef?.current?.focus();
            setIsSending(false)
            if (response){
                const wrapper = ResponseWrapper(response)
                if (wrapper.getStatus()){
                    setQuestion('')
                    const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                    addToast(wrapper.getMessage(), {appearance, autoDismiss})
                }else if (error){
                    const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                    addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                }
            }
        })
    }
    return <div className="qa_box_content">
            
            <div className="accordion qa_accordion" id="accordionExample">
                {questions?.sort((a, b) => {
                    let a_updated_date = new Date(a.updated_at)
                    let b_updated_date = new Date(b.updated_at)

                    if (a.answers?.length > 0){
                        const _answ_updated_date = a.answers.sort((answ1, answ2) => {
                            return answ1.id - answ2.id
                        }).last()?.updated_at
                        if (_answ_updated_date){
                            a_updated_date = new Date(_answ_updated_date)
                        }
                    }
                    if (b.answers?.length > 0){
                        const _answ_updated_date = b.answers.sort((answ1, answ2) => {
                            return answ1.id - answ2.id
                        }).last()?.updated_at
                        if (_answ_updated_date){
                            b_updated_date = new Date(_answ_updated_date)
                        }
                    }

                    if (a_updated_date && b_updated_date){
                        return a_updated_date < b_updated_date ? 1 : -1;
                    }else {
                        return 0
                    }
                }).map(q => QuestionAndAnswer({...q, channel_id, sendAnswer, slug, setAnswer, setIsLoading, answer: answerContainer[q?.id]?.answer, isLoading: answerContainer[q?.id]?.isLoading}))}
                <div className="send_input_box qa_send_input_box">
                    <input type="text" placeholder={translations?.qa?.question_placeholder} value={question} className="form-control"
                           id="send"  disabled={isSending}
                           onChange={e => setQuestion(e.target.value)}
                           ref={messageFieldRef}
                           onKeyDown={onSendMessageEnter}
                    />
                    <IconLoader isLoading={isSending}>
                        <SendIcon onClick={sendQuestionRequest}/>
                    </IconLoader>
                </div>
            </div>
        </div>
})