import React from "react";

export const SendIcon = ({onClick}) => {
    return <svg onClick={onClick} className="svg-inline--fa fa-paper-plane fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa"
                data-icon="paper-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                data-fa-i2svg="">
        <path fill="currentColor"
              d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
    </svg>
}

export const SpinnerIcon = () => {
    return <svg  aria-hidden="true" className="primary-color svg-inline--fa fa-spinner fa-w-16 fa-spin" focusable="false" data-prefix="fa"
                data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
        <path 
            d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path>
    </svg>
}


export const Home = ({iconCss}) => {
    return(
        <span className="svg_path" style={{fontSize:iconCss}}>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home" className="svg-inline--fa fa-home fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path  d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path>
            </svg>
        </span>
    )
}

export const LiveIcon = ({count}) => {
    return(
        <span className="svg_path">
            <svg xmlns="http://www.w3.org/2000/svg" width="35.22" height="32.019" viewBox="0 0 35.22 32.019">
                <path
                id="Icon_material-live-tv"
                d="M33.519,9.4H21.368l5.267-5.267L25.514,3l-6.4,6.4L12.707,3,11.57,4.137,16.853,9.4H4.7a3.2,3.2,0,0,0-3.2,3.2V31.817a3.211,3.211,0,0,0,3.2,3.2H33.519a3.211,3.211,0,0,0,3.2-3.2V12.606A3.2,3.2,0,0,0,33.519,9.4Zm0,22.413H4.7V12.606H33.519Z"
                transform="translate(-1.5 -3)"
                />
            </svg>
            {count ? (<span className="number">{count}</span>) : "" }
            
        </span>
    )
}


export const VideoVaultIcon = () => {
    return(
        <span className="svg_path">
            <svg xmlns="http://www.w3.org/2000/svg" width="27.169" height="27.005" viewBox="0 0 27.169 27.005">
                <g id="Group_1524"  transform="translate(-1677 -887)">
                <g id="Group_1814" >
                    <g id="Path_1406"  transform="translate(1677 889.272)" fill="#fff" strokeLinecap="round" strokeLinejoin="round">
                        <path
                            d="M 24.30011940002441 24.23264312744141 L 0.4999987483024597 24.23264312744141 L 0.4999987483024597 0.500003457069397 L 24.30011940002441 0.500003457069397 L 24.30011940002441 24.23264312744141 Z"
                            stroke="none"
                            />
                        <path
                            d="M 0.9999980926513672 1.000003814697266 L 0.9999980926513672 23.73264312744141 L 23.80011940002441 23.73264312744141 L 23.80011940002441 1.000003814697266 L 0.9999980926513672 1.000003814697266 M -1.9073486328125e-06 3.814697265625e-06 L 24.80011940002441 3.814697265625e-06 L 24.80011940002441 24.73264312744141 L -1.9073486328125e-06 24.73264312744141 L -1.9073486328125e-06 3.814697265625e-06 Z"
                            stroke="none"
                        
                            />
                    </g>
                    <g id="Rectangle_30887" transform="translate(1679 887)"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
                        <rect width="25.169" height="25.101" stroke="none" />
                        <rect x="0.5" y="0.5" width="24.169" height="24.101" fill="none" />
                    </g>
                </g>
                <path id="Icon_material-live-tv" d="M14.307,15.807V27.172l9.944-5.682Z" transform="translate(1673.994 878.652)" fill="#fff" />
                </g>
            </svg>
        </span>
    )
}

export const SessionsIcon = () => {
        return(
            <span className="svg_path">
                <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25" className="mr-2">
                    <path
                    id="Icon_ionic-ios-time"
                    d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm.984,15.891A.987.987,0,0,1,18,20.25H11.25a.984.984,0,0,1,0-1.969h5.766V9a.984.984,0,0,1,1.969,0Z"
                    transform="translate(-3.375 -3.375)"
                    />
                </svg>
            </span>
    )
}

export const SlideDecksIcon = () => {
    return(
        <span className="svg_path">
            <svg xmlns="http://www.w3.org/2000/svg" width="24.847" height="30.779" viewBox="0 0 24.847 30.779">
                <g id="Content-_-1---Content_-document_-blank_-file_-page_-paper_-sheet-icon"  transform="translate(1 2.699)">
                <path
                    id="Path"
                    d="M3,4.708V26.372A2.566,2.566,0,0,0,5.4,29.08H22.167a2.566,2.566,0,0,0,2.4-2.708V11.047h0L14.98,2H5.4A2.566,2.566,0,0,0,3,4.708Z"
                    transform="translate(-3 -2)"
                    
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    fillRule="evenodd"
                    />
                <path
                    id="Path-2"
                    d="M13,.3V10.894H23.307"
                    transform="translate(-0.46 -2)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    fillRule="evenodd"
                    />
                </g>
            </svg>
        </span>
        )
}

export const BreakoutRoomIcon = () => {
    return(
        <span className="svg_path">
            <svg xmlns="http://www.w3.org/2000/svg" width="42.432" height="27.278" viewBox="0 0 42.432 27.278">
                <path
                id="Icon_ionic-md-people"
                d="M31.181,19.241a5.752,5.752,0,0,0,5.786-5.683,5.787,5.787,0,0,0-11.572,0A5.752,5.752,0,0,0,31.181,19.241Zm-15.43,0a5.752,5.752,0,0,0,5.786-5.683,5.787,5.787,0,0,0-11.572,0A5.752,5.752,0,0,0,15.751,19.241Zm0,4.167c-4.533,0-13.5,2.178-13.5,6.63v5.115H29.528V30.038C29.528,25.586,20.284,23.408,15.751,23.408Zm15.43,1.042a10.293,10.293,0,0,0-1.653.094c2.219,1.61,3.031,2.652,3.031,5.493v5.115H44.682V30.038C44.682,25.586,35.714,24.45,31.181,24.45Z"
                transform="translate(-2.25 -7.875)"
                />
            </svg>
        </span>
        )
}

export const SpeakersIcon = ({fill}) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="11.663" height="16.964" viewBox="0 0 11.663 16.964">
            <path
                id="Icon_awesome-microphone"
                data-name="Icon awesome-microphone"
                d="M5.831,11.663A3.181,3.181,0,0,0,9.012,8.482v-5.3a3.181,3.181,0,0,0-6.361,0v5.3A3.181,3.181,0,0,0,5.831,11.663Zm5.3-5.3H10.6a.53.53,0,0,0-.53.53v1.59A4.246,4.246,0,0,1,5.408,12.7,4.37,4.37,0,0,1,1.59,8.293v-1.4a.53.53,0,0,0-.53-.53H.53a.53.53,0,0,0-.53.53V8.222a6.014,6.014,0,0,0,5.036,6.02v1.131H3.181a.53.53,0,0,0-.53.53v.53a.53.53,0,0,0,.53.53h5.3a.53.53,0,0,0,.53-.53V15.9a.53.53,0,0,0-.53-.53H6.627V14.255a5.837,5.837,0,0,0,5.036-5.773V6.892A.53.53,0,0,0,11.133,6.361Z"
                fill={fill}
            />
        </svg>
    )
}

export const SponsorsIcon = () => {
    return(
        <span className="svg_path">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.541" height="26.259" viewBox="0 0 29.541 26.259">
                <path
                id="Icon_awesome-hand-holding-heart"
                d="M14.118,12.846a.893.893,0,0,0,1.308,0L21.011,6.99A4.251,4.251,0,0,0,20.724.9a3.9,3.9,0,0,0-5.38.395l-.574.6L14.2,1.3A3.894,3.894,0,0,0,8.821.9,4.256,4.256,0,0,0,8.534,6.99Zm14.872,3.98a1.7,1.7,0,0,0-2.185,0l-4.739,3.79a3.265,3.265,0,0,1-2.051.718H13.949a.821.821,0,0,1,0-1.641h4.015a1.707,1.707,0,0,0,1.708-1.364,1.643,1.643,0,0,0-1.621-1.918H9.846a6.036,6.036,0,0,0-3.8,1.349L3.662,19.693H.821A.823.823,0,0,0,0,20.513v4.923a.823.823,0,0,0,.821.821h18.3a3.286,3.286,0,0,0,2.051-.718l7.754-6.205A1.64,1.64,0,0,0,28.991,16.826Z"
                transform="translate(0 0.002)"
                />
            </svg>
        </span>
        )
}

export const SocialWallIcon = () => {
    return(
        <span className="svg_path">
            <svg xmlns="http://www.w3.org/2000/svg" width="25.527" height="27.654" viewBox="0 0 25.527 27.654">
                <path
                id="Icon_ionic-md-hand"
                d="M28.242,17.157a2.149,2.149,0,0,0-3.126-.081l-2.573,2.572a1.268,1.268,0,0,1-1.948.114c-.312-.368-.189-1.5-.1-2.225L21.784,6.929a1.73,1.73,0,1,0-3.433-.417l-1.424,8.332c-.074.207-.234.243-.252-.036L16.19,3.978a1.729,1.729,0,0,0-3.459,0L12.7,14.889c.009.126-.1.162-.119.018L11.041,6.391A1.73,1.73,0,1,0,7.637,7l1.37,9.966c0,.177-.058.279-.142.051L6.821,11.558a1.758,1.758,0,0,0-2.208-1.1,1.788,1.788,0,0,0-1.051,2.261L7.145,24.42c.019.065.039.129.059.192l.007.024,0,.005A7.622,7.622,0,0,0,14.461,29.9a10.313,10.313,0,0,0,6.133-2.078h0c1.851-1.114,7.782-7.49,7.782-7.49A2.211,2.211,0,0,0,28.242,17.157Z"
                transform="translate(-3.462 -2.25)"
                />
            </svg>
        </span>
    )
}

export const NetworkIcon = ({count}) => {
    return(
        <span className="svg_path">
            <svg xmlns="http://www.w3.org/2000/svg" width="30.512" height="26.259" viewBox="0 0 30.512 26.259">
                <path
                id="Icon_awesome-hands-helping"
                d="M23.265,9.848H16.018v2.872a3.574,3.574,0,0,1-3.433,3.693,3.574,3.574,0,0,1-3.433-3.693V6.484l-3.094,2a3.333,3.333,0,0,0-1.483,2.811v2.426L.762,16.09A1.707,1.707,0,0,0,.2,18.332l3.814,7.109a1.469,1.469,0,0,0,2.083.6l4.93-3.062h6.512A3.176,3.176,0,0,0,20.6,19.7h.763a1.585,1.585,0,0,0,1.526-1.641V14.772h.381a1.187,1.187,0,0,0,1.144-1.231V11.079A1.187,1.187,0,0,0,23.265,9.848ZM30.307,7.93,26.493.821a1.469,1.469,0,0,0-2.083-.6L19.48,3.283H14.607a2.916,2.916,0,0,0-1.616.5l-1.6,1.072a1.662,1.662,0,0,0-.715,1.39v6.478a1.983,1.983,0,0,0,1.907,2.052,1.983,1.983,0,0,0,1.907-2.052V8.207h8.772a2.778,2.778,0,0,1,2.67,2.872v1.462l3.814-2.37a1.713,1.713,0,0,0,.558-2.241Z"
                transform="translate(0.002 -0.002)"
                />
            </svg>
            {count ? (<span className="number">{count}</span>) : "" }
        </span>
    )
}

export const DailyTriviaIcon = () => {
    return(
        <span className="svg_path">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.61" height="28.61" viewBox="0 0 28.61 28.61">
                <path
                id="Icon_awesome-poll"
                d="M25.544,2.25H3.065A3.066,3.066,0,0,0,0,5.315V27.794A3.066,3.066,0,0,0,3.065,30.86H25.544a3.066,3.066,0,0,0,3.065-3.065V5.315A3.066,3.066,0,0,0,25.544,2.25ZM10.218,23.707A1.021,1.021,0,0,1,9.2,24.729H7.152a1.021,1.021,0,0,1-1.022-1.022V15.533a1.021,1.021,0,0,1,1.022-1.022H9.2a1.021,1.021,0,0,1,1.022,1.022Zm6.131,0a1.021,1.021,0,0,1-1.022,1.022H13.283a1.021,1.021,0,0,1-1.022-1.022V9.4a1.021,1.021,0,0,1,1.022-1.022h2.044A1.021,1.021,0,0,1,16.348,9.4Zm6.131,0a1.021,1.021,0,0,1-1.022,1.022H19.414a1.021,1.021,0,0,1-1.022-1.022V19.62A1.021,1.021,0,0,1,19.414,18.6h2.044a1.021,1.021,0,0,1,1.022,1.022Z"
                transform="translate(0 -2.25)"
                />
            </svg>
        </span>
        )
}


// WifiIcon
export const WifiIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="34.584" height="30.69" viewBox="0 0 34.584 30.69" className="primary-stroke-color">
            <g id="Multimedia-_-33---Multimedia_-sound_-surround-icon" data-name="Multimedia-/-33---Multimedia,-sound,-surround-icon" transform="translate(-0.5 -1.62)">
                <path id="Path" d="M26.355,30.929a16.23,16.23,0,0,0,7.729-13.773A16.859,16.859,0,0,0,26.326,3M9.229,30.929A16.859,16.859,0,0,1,1.5,16.791,16.232,16.232,0,0,1,9.258,3M11.6,11.146a7.571,7.571,0,0,0,0,11.637m12.789,0a7.571,7.571,0,0,0,0-11.637" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fillRule="evenodd"/>
                <circle id="Path-2" data-name="Path" cx="1.552" cy="1.552" r="1.552" transform="translate(16.24 15.413)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </g>
        </svg>
    )
}

// Favorite Icon
export const FavoriteIcon = ({color, width, height, stroke}) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 29.525">
        <path
            id="Icon_material-favorite"
            data-name="Icon material-favorite"
            d="M18,32.025l-2.175-1.98C8.1,23.04,3,18.42,3,12.75A8.17,8.17,0,0,1,11.25,4.5,8.983,8.983,0,0,1,18,7.635,8.983,8.983,0,0,1,24.75,4.5,8.17,8.17,0,0,1,33,12.75c0,5.67-5.1,10.29-12.825,17.31Z"
            transform="translate(-2 -3.5)"
            fill={color ? color : "#ffffff"}
            stroke={stroke ? stroke : "#002C6C"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            />
        </svg>
    )
}

// link Icon
export const LinkIcon = () => {
    return(
        <span className="link">
            <svg xmlns="http://www.w3.org/2000/svg" width="25.87" height="25.857" viewBox="0 0 25.87 25.857">
                <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(-3.375 -3.375)">
                    <path
                        id="Path_1270"
                        data-name="Path 1270"
                        d="M17.8,22.711l-.075.006a1.028,1.028,0,0,0-.6.28l-4.015,4.015a4.527,4.527,0,0,1-6.4-6.4l4.264-4.264a4.5,4.5,0,0,1,.709-.578,4.569,4.569,0,0,1,.92-.466,4.352,4.352,0,0,1,.932-.23,4.408,4.408,0,0,1,.634-.044c.087,0,.174.006.286.012a4.514,4.514,0,0,1,2.909,1.305,4.457,4.457,0,0,1,1.063,1.7.978.978,0,0,0,1.2.628c.006,0,.012-.006.019-.006s.012,0,.012-.006a.971.971,0,0,0,.653-1.187,5.633,5.633,0,0,0-1.529-2.548A6.543,6.543,0,0,0,15.2,13.1c-.118-.019-.236-.037-.354-.05a6.415,6.415,0,0,0-.69-.037c-.162,0-.323.006-.479.019a6.269,6.269,0,0,0-1.007.155c-.068.012-.131.031-.2.05a6.466,6.466,0,0,0-1.212.454,6.385,6.385,0,0,0-1.722,1.237L5.271,19.193a6.557,6.557,0,0,0-1.9,4.631,6.536,6.536,0,0,0,11.151,4.618l4.059-4.059A.984.984,0,0,0,17.8,22.711Z"
                        transform="translate(0 -1.118)"
                        fill="#88888d"
                    ></path>
                    <path
                        id="Path_1271"
                        data-name="Path 1271"
                        d="M28.495,5.283a6.55,6.55,0,0,0-9.243,0L15.293,9.243a1,1,0,0,0,.628,1.7,1.01,1.01,0,0,0,.789-.286l3.966-3.953a4.527,4.527,0,0,1,6.4,6.4l-4.264,4.264a4.5,4.5,0,0,1-.709.578,4.569,4.569,0,0,1-.92.466,4.352,4.352,0,0,1-.932.23,4.408,4.408,0,0,1-.634.044c-.087,0-.18-.006-.286-.012a4.467,4.467,0,0,1-3.928-2.89.993.993,0,0,0-1.187-.609,1,1,0,0,0-.7,1.274,5.678,5.678,0,0,0,1.473,2.331h0L15,18.8a6.544,6.544,0,0,0,3.935,1.871,6.414,6.414,0,0,0,.69.037q.242,0,.485-.019a7.107,7.107,0,0,0,1.2-.2,6.466,6.466,0,0,0,1.212-.454A6.385,6.385,0,0,0,24.243,18.8l4.264-4.264a6.543,6.543,0,0,0-.012-9.249Z"
                        transform="translate(-1.171)"
                        fill="#88888d"
                    ></path>
                </g>
            </svg>
        </span>
    )
}

// Arrow Down Icon


export const ArrowDownIcon = () => {
    return(
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="25.877" height="20.801" viewBox="0 0 25.877 20.801">
                <g id="Group_1828" data-name="Group 1828" transform="translate(-1154.813 -3079.08)">
                    <g id="Group_1542" data-name="Group 1542" transform="translate(2 86.023)">
                        <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(1177.56 2993.057) rotate(90)">
                        <path
                            id="Icon_ionic-ios-arrow-down"
                            data-name="Icon ionic-ios-arrow-down"
                            d="M18,20.679l8.93-8.937a1.681,1.681,0,0,1,2.384,0,1.7,1.7,0,0,1,0,2.391L19.2,24.258a1.685,1.685,0,0,1-2.327.049L6.68,14.14a1.688,1.688,0,0,1,2.384-2.391Z"
                            transform="translate(-11.246 29.804) rotate(-90)"
                            fill="#88888d"
                            />
                        </g>
                        <path
                        id="Path_1415"
                        data-name="Path 1415"
                        d="M5191.943,2990.357h22.877"
                        transform="translate(-4037.631 22)"
                        fill="none"
                        stroke="#88888d"
                        strokeLinecap="round"
                        strokeWidth="3"
                        />
                    </g>
                </g>
            </svg>
        </span>
    )
}



export const LinkedinIcon = ({fill="#f26334"}) => {
    return(
        <button type="button" className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="30.951" height="30.951" viewBox="0 0 30.951 30.951">
                <path
                    id="Icon_simple-linkedin"
                    data-name="Icon simple-linkedin"
                    d="M26.369,26.376H21.786V19.194c0-1.713-.035-3.917-2.388-3.917-2.39,0-2.755,1.864-2.755,3.79v7.308H12.059V11.607h4.4V13.62h.059a4.83,4.83,0,0,1,4.346-2.386c4.644,0,5.5,3.056,5.5,7.035v8.107ZM6.883,9.586A2.662,2.662,0,1,1,9.545,6.923,2.659,2.659,0,0,1,6.883,9.586Zm2.3,16.79h-4.6V11.607h4.6ZM28.662,0H2.284A2.257,2.257,0,0,0,0,2.23V28.721a2.256,2.256,0,0,0,2.284,2.23H28.658a2.264,2.264,0,0,0,2.293-2.23V2.23A2.265,2.265,0,0,0,28.658,0Z"
                    fill={fill}
                />
            </svg>
        </button>
    )
}

export const TwitterIcon = ({fill="#f26334"}) => {
    return(
        <button type="button" className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="30.951" height="30.951" viewBox="0 0 30.951 30.951">
                <path
                    id="Icon_awesome-twitter-square"
                    data-name="Icon awesome-twitter-square"
                    d="M27.635,2.25H3.316A3.317,3.317,0,0,0,0,5.566V29.885A3.317,3.317,0,0,0,3.316,33.2H27.635a3.317,3.317,0,0,0,3.316-3.316V5.566A3.317,3.317,0,0,0,27.635,2.25ZM24.257,13.221c.014.193.014.394.014.587A12.8,12.8,0,0,1,11.379,26.7a12.832,12.832,0,0,1-6.957-2.031,9.546,9.546,0,0,0,1.092.055,9.085,9.085,0,0,0,5.624-1.934A4.537,4.537,0,0,1,6.9,19.646a4.883,4.883,0,0,0,2.045-.083A4.531,4.531,0,0,1,5.32,15.114v-.055a4.528,4.528,0,0,0,2.045.573A4.522,4.522,0,0,1,5.347,11.86a4.475,4.475,0,0,1,.615-2.287A12.866,12.866,0,0,0,15.3,14.313a4.541,4.541,0,0,1,7.731-4.138,8.879,8.879,0,0,0,2.874-1.092,4.519,4.519,0,0,1-1.99,2.494,9.017,9.017,0,0,0,2.612-.7A9.537,9.537,0,0,1,24.257,13.221Z"
                    transform="translate(0 -2.25)"
                    fill={fill}
                />
            </svg>
        </button>
    )
}

export const FacebookIcon = ({fill="#f26334"}) => {
    return(
        <button type="button" className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="31.405" height="31.405" viewBox="0 0 31.405 31.405">
                <path
                    id="Icon_awesome-facebook-square"
                    data-name="Icon awesome-facebook-square"
                    d="M28.04,2.25H3.365A3.365,3.365,0,0,0,0,5.615V30.29a3.365,3.365,0,0,0,3.365,3.365h9.621V22.978H8.57V17.953h4.416v-3.83c0-4.357,2.594-6.763,6.566-6.763a26.755,26.755,0,0,1,3.892.339v4.276H21.253a2.513,2.513,0,0,0-2.833,2.715v3.263h4.822l-.771,5.026h-4.05V33.655H28.04a3.365,3.365,0,0,0,3.365-3.365V5.615A3.365,3.365,0,0,0,28.04,2.25Z"
                    transform="translate(0 -2.25)"
                    fill={fill}
                />
            </svg>
        </button>
    )
}


export const ConnectionIcon = ({fill="#454545"}) => {
    return(
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.01" height="15.27" viewBox="0 0 18.01 15.27">
                <path
                    id="Icon_awesome-hands-helping"
                    data-name="Icon awesome-hands-helping"
                    d="M13.731,5.728H9.454V7.4A2.092,2.092,0,0,1,7.428,9.545,2.092,2.092,0,0,1,5.4,7.4V3.771L3.576,4.934A1.931,1.931,0,0,0,2.7,6.569V7.98L.449,9.358a.984.984,0,0,0-.329,1.3L2.371,14.8a.874.874,0,0,0,1.23.349l2.91-1.781h3.844a1.859,1.859,0,0,0,1.8-1.909h.45a.928.928,0,0,0,.9-.954V8.591h.225a.7.7,0,0,0,.675-.716V6.443A.7.7,0,0,0,13.731,5.728Zm4.156-1.116L15.636.478a.874.874,0,0,0-1.23-.349L11.5,1.91H8.621a1.739,1.739,0,0,0-.954.289l-.943.623a.962.962,0,0,0-.422.808V7.4a1.128,1.128,0,1,0,2.251,0V4.773h5.178a1.626,1.626,0,0,1,1.576,1.67v.85l2.251-1.378A.987.987,0,0,0,17.888,4.612Z"
                    transform="translate(0.002 -0.002)"
                    fill={fill}
                />
            </svg>
        </span>
    )
}

export const MessageIcon = () => {
    return(
        <span>
            <svg id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" xmlns="http://www.w3.org/2000/svg" width="15.037" height="15.037" viewBox="0 0 15.037 15.037">
                <path
                    id="Path_1272"
                    data-name="Path 1272"
                    d="M19.553,13.229a.876.876,0,0,1,.119-.441,1.217,1.217,0,0,1,.076-.112,5.857,5.857,0,0,0,1-3.264,6.149,6.149,0,0,0-6.279-6.037,6.236,6.236,0,0,0-6.156,4.8,5.809,5.809,0,0,0-.134,1.236,6.132,6.132,0,0,0,6.181,6.113,7.444,7.444,0,0,0,1.706-.278c.408-.112.813-.26.918-.3a.956.956,0,0,1,.336-.061.94.94,0,0,1,.365.072l2.05.727a.489.489,0,0,0,.141.036.288.288,0,0,0,.289-.289.464.464,0,0,0-.018-.1Z"
                    transform="translate(-5.708 -3.375)"
                    fill="#454545"
                />
                <path
                    id="Path_1273"
                    data-name="Path 1273"
                    d="M13.153,20.457c-.13.036-.3.076-.477.116a6.671,6.671,0,0,1-1.229.163,6.132,6.132,0,0,1-6.181-6.113,6.834,6.834,0,0,1,.054-.774c.022-.155.047-.311.083-.463s.08-.325.127-.484l-.289.257A5.379,5.379,0,0,0,3.375,17.2a5.319,5.319,0,0,0,.9,2.964c.083.127.13.224.116.289s-.43,2.241-.43,2.241a.29.29,0,0,0,.1.278.294.294,0,0,0,.184.065.259.259,0,0,0,.1-.022l2.028-.8a.565.565,0,0,1,.434.007A6.084,6.084,0,0,0,9,22.662a5.677,5.677,0,0,0,4.341-1.992s.116-.159.249-.347C13.457,20.37,13.3,20.417,13.153,20.457Z"
                    transform="translate(-3.375 -8.004)"
                    fill="#454545"
                />
            </svg>
        </span>
    )
}

export const CallIcon = () => {
    return(
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.627" height="12.141" viewBox="0 0 18.627 12.141">
                <path
                    id="Icon_ionic-md-videocam"
                    data-name="Icon ionic-md-videocam"
                    d="M16.886,12.287V8.745a1.025,1.025,0,0,0-1.035-1.01H3.285A1.025,1.025,0,0,0,2.25,8.745V18.861a1.028,1.028,0,0,0,1.035,1.015H15.85a1.028,1.028,0,0,0,1.035-1.01V15.322l3.992,4.046V8.242Z"
                    transform="translate(-2.25 -7.734)"
                    fill="#454545"
                />
            </svg>
        </span>
    )
}

export const ScheduleAppointmentIcon = () => {
    return( 
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13.282" height="15.04" viewBox="0 0 13.282 15.04">
                <path
                    id="Icon_awesome-calendar-plus"
                    data-name="Icon awesome-calendar-plus"
                    d="M12.927,4.7H.356A.355.355,0,0,1,0,4.347V3.29A1.417,1.417,0,0,1,1.423,1.88H2.846V.352A.355.355,0,0,1,3.2,0H4.388a.355.355,0,0,1,.356.352V1.88H8.539V.352A.355.355,0,0,1,8.894,0H10.08a.355.355,0,0,1,.356.352V1.88h1.423a1.417,1.417,0,0,1,1.423,1.41V4.347A.355.355,0,0,1,12.927,4.7ZM.356,5.64H12.927a.355.355,0,0,1,.356.352V13.63a1.417,1.417,0,0,1-1.423,1.41H1.423A1.417,1.417,0,0,1,0,13.63V5.992A.355.355,0,0,1,.356,5.64ZM9.725,9.752A.355.355,0,0,0,9.369,9.4H7.59V7.637a.355.355,0,0,0-.356-.352H6.048a.355.355,0,0,0-.356.352V9.4H3.914a.355.355,0,0,0-.356.352v1.175a.355.355,0,0,0,.356.352H5.692v1.762a.355.355,0,0,0,.356.352H7.234a.355.355,0,0,0,.356-.352V11.28H9.369a.355.355,0,0,0,.356-.352Z"
                    fill="#454545"
                />
            </svg>
        </span>
    )
}

export const EuroSymbol = ({fill="#fff"}) => {
    return( 
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.025" height="16" viewBox="0 0 18.025 20">
                <path id="Path_1429" data-name="Path 1429" d="M27,40a1,1,0,0,0,1,1h2.461A10.017,10.017,0,0,0,40,48a9.786,9.786,0,0,0,4.45-1.058,1,1,0,1,0-.9-1.785A7.809,7.809,0,0,1,40,46a8.009,8.009,0,0,1-7.411-5H39a1,1,0,0,0,0-2H32.069a7.229,7.229,0,0,1,0-2H39a1,1,0,0,0,0-2H32.589A8.009,8.009,0,0,1,40,30a7.809,7.809,0,0,1,3.55.843,1,1,0,1,0,.9-1.785A9.786,9.786,0,0,0,40,28a10.017,10.017,0,0,0-9.539,7H28a1,1,0,0,0,0,2h2.051c-.033.329-.051.662-.051,1s.018.671.051,1H28A1,1,0,0,0,27,40Z" transform="translate(-27 -28)" fill={fill}></path>
            </svg>
        </span>
    )
}


export const InfoIcon = ({fill= "currentColor"}) => {
    return <svg className="svg-inline--fa fa-info-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa"
                data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                data-fa-i2svg="">
        <path fill={fill}
              d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
    </svg>
}

export const LockIcon = ({fill= "currentColor"}) => {
    return(
        <span className="lock_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="31.001" viewBox="0 0 25 31.001">
                <g id="Group_1858" data-name="Group 1858" transform="translate(-819 -687.999)">
                    <path id="Subtraction_2" data-name="Subtraction 2" d="M-1971-2685h-13a6.007,6.007,0,0,1-6-6v-13a6.007,6.007,0,0,1,6-6h13a6.007,6.007,0,0,1,6,6v13A6.007,6.007,0,0,1-1971-2685Zm-6.5-18a3.5,3.5,0,0,0-3.5,3.5,3.517,3.517,0,0,0,2,3.163V-2691h3v-5.336a3.518,3.518,0,0,0,2-3.164A3.5,3.5,0,0,0-1977.5-2703Z" transform="translate(2809 3404)" fill="#fff"/>
                <g id="Group_1520" data-name="Group 1520" transform="translate(86 -101.977)">
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M6.675,9.812a14.079,14.079,0,0,1-4.554-.735C1.154,8.715,0,8.05,0,6.939a6.94,6.94,0,0,1,13.879,0c0,.864-.714,1.6-2.063,2.116a13.882,13.882,0,0,1-4.593.756,7.95,7.95,0,0,0,3-.589c.878-.4,1.342-.956,1.342-1.614A5.83,5.83,0,0,0,10.29,3.844a4.311,4.311,0,0,0-3.351-1.56,4.164,4.164,0,0,0-3.315,1.56A5.914,5.914,0,0,0,2.385,7.609c0,.659.47,1.219,1.359,1.617a7.957,7.957,0,0,0,2.931.584Z" transform="translate(738.715 789.976)" fill="#fff"/>
                </g>
                </g>
            </svg>
        </span>
    )
}


export const FAQIcon = ({FaqClass}) => {
    return(
        <span className="lock_icon">
            <svg xmlns="http://www.w3.org/2000/svg" className={FaqClass} width="65" height="65" viewBox="0 0 65 65">
                <g id="Group_1970" data-name="Group 1970" transform="translate(-37 -987)">
                    <path id="Icon_material-question-answer" data-name="Icon material-question-answer" d="M64.75,16h-6.5V45.25H16v6.5A3.26,3.26,0,0,0,19.25,55H55L68,68V19.25A3.26,3.26,0,0,0,64.75,16Zm-13,19.5V6.25A3.26,3.26,0,0,0,48.5,3H6.25A3.26,3.26,0,0,0,3,6.25v45.5l13-13H48.5A3.26,3.26,0,0,0,51.75,35.5Z" transform="translate(34 984)" />
                    <text id="FAQ" transform="translate(41 1012)" fontSize="18" fontFamily="Verdana-Bold, Verdana" fontWeight="700"><tspan x="0" y="0">FAQ</tspan></text>
                </g>
            </svg>
        </span>
    )
}

export const FileDownIcon = () => {
    return(
        <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
        <g id="Group_1947" data-name="Group 1947">
        <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
        <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
        </g>
        <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
        </g>
        </svg>
    )
}

export const SendIconForm= () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="21.808" height="20.13" viewBox="0 0 21.808 20.13">
        <path id="Icon_ionic-md-send" data-name="Icon ionic-md-send" d="M3.375,24.63,25.183,14.565,3.375,4.5v7.828L19,14.565,3.375,16.8Z" transform="translate(-3.375 -4.5)" fill="#454545" />
        </svg>
        
    )
}

export const SearchIcon = () => {
    return(
        <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
        </svg>
    )
}

export const CancelIconConn = () => {
    return <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20.183" height="20.183" viewBox="0 0 37.183 37.183">
            <g id="Group_1854" data-name="Group 1854" transform="translate(-1561.461 -343.75)">
                <g id="Group_1852" data-name="Group 1852">
                    <g id="FORM_-_Checkbox_active" data-name="FORM - Checkbox active" transform="translate(1561.461 343.75)">
                        <g id="FORM_-_checkbox_active-2" data-name="FORM - checkbox active">
                            <g id="FORM_-_Checkbox_Inactive" data-name="FORM - Checkbox Inactive">
                                <g id="Rectangle_1372" data-name="Rectangle 1372" fill="#fff" stroke="#f26334" strokeWidth="3">
                                    <rect width="37.183" height="37.183" rx="10" stroke="none" />
                                    <rect x="1.5" y="1.5" width="34.183" height="34.183" rx="8.5" fill="none" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="ICON_-_Close" data-name="ICON - Close" transform="translate(1569.631 352.482)">
                    <rect id="Rectangle_1308" data-name="Rectangle 1308" width="4.539" height="23.345" rx="2.27" transform="translate(0 3.21) rotate(-45)" fill="#f26334" />
                    <rect id="Rectangle_1309" data-name="Rectangle 1309" width="4.54" height="23.345" rx="2.27" transform="translate(16.509) rotate(45)" fill="#f26334" />
                </g>
            </g>
        </svg>
    </span>
}

export const RightIconConn = () => {
    return <span>
        <svg id="FORM_-_Checkbox_active" data-name="FORM - Checkbox active" xmlns="http://www.w3.org/2000/svg" width="20.183" height="20.183" viewBox="0 0 37.183 37.183">
            <g id="FORM_-_checkbox_active-2" data-name="FORM - checkbox active">
                <g id="FORM_-_Checkbox_Inactive" data-name="FORM - Checkbox Inactive">
                    <g id="Rectangle_1372" data-name="Rectangle 1372" fill="#fff" stroke="#49c168" strokeWidth="3">
                        <rect width="37.183" height="37.183" rx="10" stroke="none" />
                        <rect x="1.5" y="1.5" width="34.183" height="34.183" rx="8.5" fill="none" />
                    </g>
                </g>
                <g id="Icon_Check" data-name="Icon Check" transform="translate(6.126 9.296)">
                    <path
                        id="Icon_awesome-check"
                        data-name="Icon awesome-check"
                        d="M8.468,22.8l-8.1-8.1a1.247,1.247,0,0,1,0-1.763l1.763-1.763a1.247,1.247,0,0,1,1.763,0l5.458,5.458L21.041,4.942a1.247,1.247,0,0,1,1.763,0l1.763,1.763a1.247,1.247,0,0,1,0,1.763L10.231,22.8A1.247,1.247,0,0,1,8.468,22.8Z"
                        transform="translate(0 -4.577)"
                        fill="#49c168"
                    />
                </g>
            </g>
        </svg>
    </span>
}
export const NotifictionIcon = () => {
    return <span className="notifiction">
        <svg xmlns="http://www.w3.org/2000/svg" width="28.502" height="29.617"
            viewBox="0 0 28.502 29.617">
        <path
            id="XMLID_1126_"
            d="M25.34,16.742a.5.5,0,0,1-.49-.509V11.19c0-5.968-4.587-10.965-10.2-11.2A10.414,10.414,0,0,0,6.9,3.047a11.089,11.089,0,0,0-3.251,7.912v5.228a.5.5,0,0,1-.49.509A3.238,3.238,0,0,0,0,19.981V23.59a1.338,1.338,0,0,0,1.336,1.388h7.3A5.83,5.83,0,0,0,14.251,29.6a5.869,5.869,0,0,0,5.611-4.627h7.3A1.338,1.338,0,0,0,28.5,23.59V20.027A3.238,3.238,0,0,0,25.34,16.742ZM14.251,26.828a3.054,3.054,0,0,1-2.806-1.851h5.611A3.054,3.054,0,0,1,14.251,26.828ZM25.83,22.2H2.672V20.027a.5.5,0,0,1,.49-.509,3.238,3.238,0,0,0,3.162-3.285V11.005a8.306,8.306,0,0,1,2.4-5.922A7.793,7.793,0,0,1,14.518,2.77c4.186.139,7.615,3.933,7.615,8.421v5A3.238,3.238,0,0,0,25.3,19.472a.5.5,0,0,1,.49.509V22.2Z"
            transform="translate(0 0.012)"
            fill="#939393"
        />
        </svg>
        {/* <span className="namber">1</span> */}
    </span>
}



export const DiceIcon = () => {
    return <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="39.557" height="39.557" viewBox="0 0 39.557 39.557">
        <path
            id="Icon_metro-dice"
            data-name="Icon metro-dice"
            d="M35.947,9.345H16.168a6.2,6.2,0,0,0-6.181,6.181V35.3a6.2,6.2,0,0,0,6.181,6.181H35.947A6.2,6.2,0,0,0,42.127,35.3V15.526a6.2,6.2,0,0,0-6.181-6.181ZM18.641,36.54a3.708,3.708,0,1,1,3.708-3.708A3.708,3.708,0,0,1,18.641,36.54Zm0-14.834A3.708,3.708,0,1,1,22.349,18,3.708,3.708,0,0,1,18.641,21.706Zm7.417,7.417a3.708,3.708,0,1,1,3.708-3.708A3.708,3.708,0,0,1,26.057,29.123Zm7.417,7.417a3.708,3.708,0,1,1,3.708-3.708A3.708,3.708,0,0,1,33.474,36.54Zm0-14.834A3.708,3.708,0,1,1,37.183,18,3.708,3.708,0,0,1,33.474,21.706ZM34.585,6.873A6.2,6.2,0,0,0,28.53,1.928H8.751A6.2,6.2,0,0,0,2.571,8.109V27.887a6.2,6.2,0,0,0,4.945,6.056l.036-21.465c.027-2.564,1.928-6.1,6.7-5.822l20.333.217Z"
            transform="translate(-2.571 -1.928)"
            
            ></path>
        </svg>
    </span>
}

export const PeopleIcon = () => {
    return <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="44.646" height="45.673" viewBox="0 0 44.646 45.673">
                <g id="Image-_-33---Image_-photo_-photography_-picture_-portrait-icon" data-name="Image-/-33---Image,-photo,-photography,-picture,-portrait-icon" transform="translate(1.607 1.959)">
                    <circle id="Path" cx="14.512" cy="14.512" r="14.512" transform="translate(6.545 -0.459)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                    <path
                        id="Path-2"
                        data-name="Path"
                        d="M6,27.763C6.415,22.182,14.337,14,26.784,14s20.755,8.182,20.755,13.846"
                        transform="translate(-6 14.344)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        fillRule="evenodd"
                    />
                </g>
            </svg>
    </span>
}

export const MeetingIcon = () => {
    return <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="35.997" viewBox="0 0 45 35.997">
            <path
                id="Icon_awesome-hands-helping"
                data-name="Icon awesome-hands-helping"
                d="M34.313,13.5H23.625v3.938a5.063,5.063,0,0,1-10.125,0V8.887L8.937,11.63A4.49,4.49,0,0,0,6.75,15.483v3.326L1.125,22.057A2.245,2.245,0,0,0,.3,25.13l5.625,9.745A2.251,2.251,0,0,0,9,35.7l7.27-4.2h9.6a4.5,4.5,0,0,0,4.5-4.5H31.5a2.248,2.248,0,0,0,2.25-2.25v-4.5h.563A1.683,1.683,0,0,0,36,18.563V15.188A1.683,1.683,0,0,0,34.313,13.5ZM44.7,10.87,39.073,1.125A2.251,2.251,0,0,0,36,.3L28.73,4.5H21.544a4.534,4.534,0,0,0-2.384.682L16.8,6.652A2.235,2.235,0,0,0,15.75,8.557v8.88a2.813,2.813,0,0,0,5.625,0V11.25H34.313a3.94,3.94,0,0,1,3.938,3.938v2l5.625-3.248A2.252,2.252,0,0,0,44.7,10.87Z"
                transform="translate(0.002 -0.002)"
                ></path>
            </svg>
        </span>
}

export const MessagesIcon = () => {
    return <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path
                id="Icon_material-message"
                data-name="Icon material-message"
                d="M30,3H6A3,3,0,0,0,3.015,6L3,33l6-6H30a3.009,3.009,0,0,0,3-3V6A3.009,3.009,0,0,0,30,3ZM27,21H9V18H27Zm0-4.5H9v-3H27ZM27,12H9V9H27Z"
                transform="translate(-3 -3)"
                ></path>
            </svg>
        </span>
}

export const ProfileIcon = () => {
    return <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="38.899" viewBox="0 0 50 38.899" className="mr-2">
            <path id="Icon_metro-profile" data-name="Icon metro-profile" d="M50.928,13.269h-3.5V7.827l-7.218-.115.037,5.557H18.561l.148-5.557-7.068.115v5.557L8.07,13.269a3.641,3.641,0,0,0-3.571,3.7V42.907a3.641,3.641,0,0,0,3.571,3.7H50.928a3.641,3.641,0,0,0,3.571-3.7V16.974A3.641,3.641,0,0,0,50.928,13.269ZM42,9.564H45.57v7.409H42ZM18.785,22.183c2.157,0,3.907,2.384,3.907,5.325s-1.75,5.325-3.907,5.325-3.907-2.384-3.907-5.325,1.75-5.325,3.907-5.325ZM13.427,9.564H17v7.409H13.427Zm-2.38,29.541s.423-3.523,1.386-4.188a13.314,13.314,0,0,1,3.732-1.108s1.8,1.991,2.561,1.991,2.559-1.991,2.559-1.991a13.272,13.272,0,0,1,3.734,1.108c1.13.782,1.413,4.188,1.413,4.188H11.047ZM47.356,37.35H31.285V35.5H47.356Zm0-3.7H31.285V31.793H47.356Zm0-3.7H31.285V28.088H47.356Zm0-3.7H31.285V24.383H47.356Z" transform="translate(-4.499 -7.712)" />
       </svg>
    </span>
}

export const PasswordIcon = () => {
    return <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="30.671" height="40.256" viewBox="0 0 30.671 40.256" className="mr-2">
            <path
                id="Icon_material-lock-outline"
                data-name="Icon material-lock-outline"
                d="M21.336,32.171A3.834,3.834,0,1,0,17.5,28.337,3.845,3.845,0,0,0,21.336,32.171Zm11.5-17.253H30.92V11.085a9.585,9.585,0,1,0-19.169,0v3.834H9.834A3.845,3.845,0,0,0,6,18.753V37.922a3.845,3.845,0,0,0,3.834,3.834h23a3.845,3.845,0,0,0,3.834-3.834V18.753A3.845,3.845,0,0,0,32.837,14.919ZM15.393,11.085a5.943,5.943,0,0,1,11.885,0v3.834H15.393ZM32.837,37.922h-23V18.753h23Z"
                transform="translate(-6 -1.5)"
            />
        </svg>
    </span>
}






