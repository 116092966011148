import {ChatTab, TabWrapper} from "./ChatTab";
import {QATab} from "./QATab";
import {PollTab} from "./PollTab";
import {RatingTab} from "./RatingTab";
import {VotingTab} from "./VotingTab";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {InterractionShimmer} from "./Shimmer";
import {SettingOptions} from "../../../../../classes/SettingOptions";
import {ChatMenu, PollMenu, QnAMenu, RatingMenu, VoteMenu} from "./InterationMenuItem";
import $ from 'jquery';
import _ from 'lodash';
import {InteractionSettings} from "../../../../../hooks/shared/InteractionSettings";
import Logger from "../../../../../Util/Logger";
import NotificationCenter from "../../../../../hooks/NotificationCenter";
import {BadgesApi} from "../../../../../hooks/shared/BadgesApi";
import {LiveBadgeType} from "../../../../../classes/BadgeType";

export const Interaction = (
    {fullScreenCallback,
        isAllDisabled,
        isChatEnabled,
        isPollEnabled,
        isRatingEnabled,
        isVoteEnabled,
        isQnAEnabled,
        chat,
        qa,
        poll,
        rating,
        vote,
        translations,
        selectedChannel,
        active,
        live_badges,
        selectedChannelId,

        isRatingForEach,
        isQAForEach,
        isVoteForEach,
        isPollForEach,
    }
) => {


    const chatRef = useRef()
    const pollRef = useRef()
    const voteRef = useRef()
    const ratingRef = useRef()
    const qaRef = useRef()

    const chatContainerRef = useRef()
    const pollContainerRef = useRef()
    const voteContainerRef = useRef()
    const ratingContainerRef = useRef()
    const qaContainerRef = useRef()

    const qaTabRef = useRef()

    const MenuLinkItem = (settingId, elementId, ref, containerRef) => {
        return {settingId, elementId, ref, containerRef }
    }
    const menus = [
        MenuLinkItem(SettingOptions.CHAT, "#Chat", chatRef, chatContainerRef),
        MenuLinkItem(SettingOptions.VOTE, "#Vote", voteRef, voteContainerRef),
        MenuLinkItem(SettingOptions.RATING, "#Rating", ratingRef, ratingContainerRef),
        MenuLinkItem(SettingOptions.POLL, "#Poll", pollRef, pollContainerRef),
        MenuLinkItem(SettingOptions.QA, "#QA", qaRef, qaContainerRef),
    ]
    const {resetLiveBadge, live_badges_total} = BadgesApi()
    const currentSelectedRef = useRef()
    const menuClick = (e) => {
        currentSelectedRef.current = e.currentTarget;
        const currentTarget = window.$(e.currentTarget);
        const hasClass = currentTarget.hasClass("active");
        const chain = _(menus);
        chain.each((i) => {
            const item = window.$(i?.ref?.current);
            // alert(item.attr('class'))
            item.removeClass("active");

            const container = window.$(i?.containerRef?.current);
            // alert(item.attr('class'))
            container.removeClass("active");
        })
        if (!hasClass){
            const id = currentTarget.data("id")
            const container =  window.$("#" + id)
            if (container.hasClass("active")){
                container.removeClass("active")
            }else {
                container.addClass("active")
            }
            currentTarget.addClass("active");
            resetLiveBadge(id)
        }
       fullScreenCallback (hasClass)
    }

    useEffect(() => {
        if (currentSelectedRef?.current){
            const currentTarget = window.$(currentSelectedRef.current);
            const id = currentTarget.data("id")
            resetLiveBadge(id)
        }
    }, [live_badges_total])

    const resetUI = (_active) => {
        const chain = _(menus);
        chain.each((item) => {
            item?.ref?.current?.classList.remove('active')
            item?.containerRef?.current?.classList.remove('active')
        })
        chain.filter((item) => {
            return _active === item.settingId
        })
            .each(item => {
                item?.containerRef?.current?.classList.add('active')
                item?.ref?.current?.click()
            })

    }


    useEffect(() => {
        resetUI(active)
    }, [selectedChannel, active])

    const trigger = (elRef) => {
        if (elRef){
            const item = window.$(elRef.current)
            if (!item.hasClass("active")){
                elRef.current?.click()
            }
        }
    }

    const hide = (elRef) => {
        if (elRef){
            const item = window.$(elRef.current)
            if (item.hasClass("active")){
                elRef.current?.click()
            }
        }
    }

    useEffect(() => {
        NotificationCenter.sharedInstance().registerNotification('ratingLive', (data) => {
            if (data.live && isRatingEnabled){
                const isChannelBroadcast = selectedChannelId === data.event_channel_id;
                if (isRatingForEach){
                    if (isChannelBroadcast){
                        trigger(ratingRef)
                    }
                }else {
                    trigger(ratingRef)
                }
            }
        })
    }, [isRatingEnabled, selectedChannelId])

    useEffect(() => {
        NotificationCenter.sharedInstance().registerNotification('chatEvent', (data) => {
            if (data.live && isChatEnabled){
                // trigger(chatRef)
            }
        })
    }, [isChatEnabled, selectedChannelId])

    useEffect(() => {
        NotificationCenter.sharedInstance().registerNotification('qaLive', (data) => {
            // const isChannelBroadcast = selectedChannelId === data.event_channel_id;
            // if (data.live && isQnAEnabled){
            //     if (isQAForEach){
            //         if (isChannelBroadcast){
            //             trigger(qaRef)
            //         }
            //     }else {
            //         trigger(qaRef)
            //     }
            //
            // }
            qaTabRef?.current?.qaEvent(data)
        })
    }, [isQnAEnabled, selectedChannelId])

    useEffect(() => {
        NotificationCenter.sharedInstance().registerNotification('pollLive', (data) => {
            const isChannelBroadcast = selectedChannelId === data.event_channel_id;
            const isLive = data.live;
            if (isPollEnabled) {
                if (isPollForEach){
                    if (isChannelBroadcast){
                        if (isLive){
                            trigger(pollRef)
                        }else {
                            hide(pollRef)
                        }
                    }
                }else {
                    if (isLive){
                        trigger(pollRef)
                    }else {
                        hide(pollRef)
                    }
                }
            }
        })
    }, [isPollEnabled, selectedChannelId])

    useEffect(() => {
        NotificationCenter.sharedInstance().registerNotification('voteLive', (data) => {
            if (data.live && isVoteEnabled){
                const isChannelBroadcast = selectedChannelId === data.event_channel_id;
                if (isVoteForEach){
                    if (isChannelBroadcast){
                        trigger(voteRef)
                    }
                }else {
                    trigger(voteRef)
                }
            }
        })
    }, [isVoteEnabled, selectedChannelId])

    const isChatMenuActive = active === SettingOptions.CHAT
    const isQaMenuActive = active === SettingOptions.QA
    const isPollMenuActive = active === SettingOptions.POLL
    const isRatingMenuActive = active === SettingOptions.RATING
    const isVoteMenuActive = active === SettingOptions.VOTE

    if (!selectedChannel) return <div className="live_room_right_box"> <InterractionShimmer/> </div>

    return <div className="live_room_right_box">
        {
            isAllDisabled && <div className="tab-content">
                {isChatEnabled && chat && <TabWrapper id="Chat" isActive={isChatMenuActive} ref={chatContainerRef}><ChatTab/> </TabWrapper>}
                {isQnAEnabled && qa && <TabWrapper id="QA" ref={qaContainerRef} isActive={isQaMenuActive} qa={qa}> <QATab ref={qaTabRef}/> </TabWrapper>}
                {isPollEnabled && poll && <TabWrapper id="Poll" ref={pollContainerRef} isActive={isPollMenuActive}> <PollTab/> </TabWrapper>}
                {isRatingEnabled && rating && <TabWrapper id="Rating" ref={ratingContainerRef} isActive={isRatingMenuActive}> <RatingTab /> </TabWrapper>}
                {isVoteEnabled &&  vote && <TabWrapper id="Vote" ref={voteContainerRef} isActive={isVoteMenuActive}> <VotingTab/></TabWrapper>}
            </div>
        }
        {/* <!-- Tab panes --> */}
        {
            isAllDisabled && <ul className="nav nav-tabs room_chat_tab" role="tablist">
                {isChatEnabled && <ChatMenu badgeCount={live_badges?.chat} ref={chatRef}  translations={translations} onClick={menuClick} />}
                {isQnAEnabled &&  <QnAMenu  badgeCount={live_badges?.qa} ref={qaRef}   translations={translations} onClick={menuClick} />}
                {isPollEnabled && <PollMenu  badgeCount={live_badges?.poll} ref={pollRef}   translations={translations} onClick={menuClick} />}
                {isRatingEnabled && <RatingMenu  badgeCount={live_badges?.rating} ref={ratingRef}  translations={translations} onClick={menuClick} />}
                {isVoteEnabled && <VoteMenu  badgeCount={live_badges?.vote} ref={voteRef}  translations={translations} onClick={menuClick} />}

            </ul>
        }
    </div>
}
