import React from "react";

export const MobileOutgoingCallModel = () => {
    return  <div className="modal fade mobile_chat_model mobile_connection_request" id="m_outgoing_call">
        <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="connection_request_mbody">
                    <h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.57" height="16.014" viewBox="0 0 24.57 16.014">
                            <path id="Icon_ionic-md-videocam" data-name="Icon ionic-md-videocam" d="M21.555,13.74V9.067a1.352,1.352,0,0,0-1.366-1.333H3.616A1.352,1.352,0,0,0,2.25,9.067V22.41a1.357,1.357,0,0,0,1.366,1.338H20.189a1.356,1.356,0,0,0,1.366-1.333V17.743L26.82,23.08V8.4Z" transform="translate(-2.25 -7.734)" fill="#f26334"/>
                        </svg>

                        Outgoing Call
                    </h1>
                    <div className="outgoing_section_mobile">
                        <div className="img-box">
                            <img src="/images/call_icon5.png" />
                            <img src="/images/call_icon6.png" />
                        </div>
                        <div className="content_box">
                            <h6>You are calling</h6>
                            <h4>Brit Hansen</h4>
                            <p>Torfs Marketing Manager</p>
                        </div>
                    </div>


                    <textarea className="form-control" rows="5" id="comment" className="form-control" defaultValue={"Message *"}/>
                    <div className="calling-btn">
                        <button type="send" className="btn btn_border_orange">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21.808" height="20.13" viewBox="0 0 21.808 20.13">
                                <path id="Icon_ionic-md-send" data-name="Icon ionic-md-send" d="M3.375,24.63,25.183,14.565,3.375,4.5v7.828L19,14.565,3.375,16.8Z" transform="translate(-3.375 -4.5)" fill="#454545"/>
                            </svg>
                            Send
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
}