import {ResponseWrapper} from "../Model/ResponseWrapper";
//
// interface Handler {
//     setNext(handler: Handler): Handler;
//
//     handle(request: string): string;
// }
//
// abstract class AbstractErrorHandler implements Handler
// {
//     private nextHandler: Handler;
//     private unAthorized: (error:Error) => void;
//     private validation: (error: Error) => void;
//
//     public setNext(handler: Handler): Handler {
//         this.nextHandler = handler;
//         // Returning a handler from here will let us link handlers in a
//         // convenient way like this:
//         // monkey.setNext(squirrel).setNext(dog);
//         return handler;
//     }
//
//     public handle(request: any): any {
//         if (this.nextHandler) {
//             return this.nextHandler.handle(request);
//         }
//
//         return null;
//     }
// }
//
// export const HandlerChain = (handlers: [Handler]) => {
//     handlers.forEach((handler, index, handlers) => {
//         if (handlers.length < index) {
//             handler.setNext(handlers[index + 1])
//         }
//     })
//     return handlers[0]
// }
//
// class UnauthHandler extends AbstractErrorHandler{
//     setUnAthorizeCallback(callback:  (error:Error) => void)
//     handle(request: any): any {
//         if (request?.response?.status === 401) this.unAthorized?(request?.response?.err)
//         return super.handle(request);
//     }
// }
// const ErrorHandlers = HandlerChain([])
// @ts-ignore
export const ErrorHandler = (
    unAthorized: (error: Error) => void,
    validation: (error:Error) => void) => (error: any)=> {

if (error?.response?.status === 401){
    window.User.clearLoginData();
    unAthorized(error)
}else if (validation){
    validation(error)
}
}