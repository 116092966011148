import { Link } from "react-router-dom"
import { EventApi } from "../../../../hooks/shared/EventApi"
import { NotifictionIcon } from "../../../../SVG/Icons"
import { Image } from "../../../../UIComponets/Image"
import { OnePageModalWrapper } from "../../../../UIComponets/Modal/OnePageModalWrapper"
import Profile from "../../../Event/Components/Pages/Profile/Profile"
export const CustomHeader = () => {
    const {
        fetchEventsPage,
        state : {
            details,
            event
        }
    } = EventApi()

    if(!details?.img_custom_header){ return <></>};
    return (
        <>
            <div className="header_area">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-lg-8">
                            <Image src={details?.img_custom_header} alt={event.title} className="img-fluid"/>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="header_right_box">
                                <ul className="nav">
                                    {/***
                                        <li className="nav-item">
                                            <a className="nav-link" href="#"><NotifictionIcon/></a>
                                        </li>
                                    **/}
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" type="button" data-toggle="dropdown">
                                            <img src="/images/gv.png"/>
                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="user_languages">
                                                <h4>Languages</h4>
                                                <ul>
                                                    <li><a href="#">NL</a></li>
                                                    <li className="active"><a href="#">EN</a></li>
                                                    <li><a href="#">FR</a></li>
                                                    <li><a href="#">IT</a></li>
                                                </ul>
                                            </div>
                                            <div className="user_profile">
                                                <ul>
                                                    <li>
                                                        <a  data-toggle="modal" data-target="#profile_details">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.246"
                                                                 height="19.67" viewBox="0 0 19.246 19.67">
                                                                <g id="Group_1869" data-name="Group 1869"
                                                                   transform="translate(-37.993 -175.816)">
                                                                    <g
                                                                        id="Image-_-33---Image_-photo_-photography_-picture_-portrait-icon"
                                                                        data-name="Image-/-33---Image,-photo,-photography,-picture,-portrait-icon"
                                                                        transform="translate(39.064 177.275)"
                                                                    >
                                                                        <circle
                                                                            id="Path"
                                                                            cx="6"
                                                                            cy="6"
                                                                            r="6"
                                                                            transform="translate(2.706 -0.459)"
                                                                            fill="none"
                                                                            stroke="#C129B9"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth="2"
                                                                        />
                                                                        <path
                                                                            id="Path-2"
                                                                            data-name="Path"
                                                                            d="M6,19.69C6.172,17.383,9.447,14,14.593,14s8.581,3.383,8.581,5.725"
                                                                            transform="translate(-6 -2.55)"
                                                                            fill="none"
                                                                            stroke="#C129B9"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth="2"
                                                                            fillRule="evenodd"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <span>Profile</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <Link to={"#"} data-toggle="modal" data-target="#logout">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.529"
                                                                 height="12.713" viewBox="0 0 14.529 12.713">
                                                                <path
                                                                    id="Icon_open-account-logout"
                                                                    data-name="Icon open-account-logout"
                                                                    d="M5.448,0V1.816h7.265V10.9H5.448v1.816h9.081V0ZM3.632,3.632,0,6.357,3.632,9.081V7.265H10.9V5.448H3.632Z"
                                                                    fill="#C129B9"
                                                                />
                                                            </svg>
                                                            <span>Logout</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OnePageModalWrapper className={"profile_details_model"} id={"profile_details"}>
                <Profile/>
            </OnePageModalWrapper>
           </>
    )
}