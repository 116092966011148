import React from 'react'
import ReactHtmlParser from "react-html-parser";

const WelComeWidget = ({welcome_widget_content}) => {
  return (
    <>
        <div className="modal fade modal-wb-splash" id="splashRegistrationeModal" tabIndex="-1" role="dialog" aria-labelledby="splashRegistrationeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="wb-splash-reg-wrapper">
                            <div className="wb-splash-inner">
                                 { ReactHtmlParser(welcome_widget_content ?? '') }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default WelComeWidget