import {URLContants} from "./URLContants";



export const DocumentTitleMap = {
     "login": {title: "Login"},
     "register": {title: "Register"},
     "about": {title: "About"},
     "blog": {title: "Blog"},
     "live": {title: "Live"},
     "vod": {title: "Vod"},
     "video-vault-details": {title: "Video Vault Details"},
     "sessions": {title: "Sessions"},
     "slide-decks": {title: "Slide Decks"},
     "breakout-room": {title: "Breakout Room"},
     "sponsors": {title: "Sponsors"},
     "social-wall": {title: "Social Wall"},
     "network": {title: "Network"},
     "daily-trivia": {title: "Daily Trivia"},
     "profile": {title: "Profile"},
     "faq": {title: "Faq"},
     "profile-session": {title: "Profile Session"},
     "meeting": {title: "Meeting"},
     "chat-window": {title: "Chat Window"},
     "dice": {title: "Dice"},
     "participant-details": {title: "Participant Details"},
     "speakers": {title: "Speakers"},
     "speaker-details": {title: "Speaker Details"},
     "sponsors-details": {title: "Sponsors Details"},
     "breakout-room-call": {title: "Breakout Room"},
     "active-call": {title: "Breakout Room Call"},
}

/*
LOGIN = "/login",
    REGISTER = "/register",
    ABOUT = "/about",
    BLOG = "/blog",
    LIVE = "/live",
    VIDEO_VAULT = "/vod",
    VIDEO_VAULT_DETAILS_WITOUTSLUG = "/video-vault-details",
    VIDEO_VAULT_DETAILS = "/video-vault-details",
    SESSIONS = "/sessions",
    SLIDE_DECKS = "/slide-decks",
    BREAKOUT_ROOM = "/breakout-room",
    SPONSORS = "/sponsors",
    SOCIAL_WALL = "/social-wall",
    NETWORK = "/network",
    NETWORKPROFILE = "/network",
    DAILY_TRIVIA = "/daily-trivia",
    PROFILE = "/profile",
    FAQ = "/faq",
    PROFILE_SESSION = "/profile-session",
    MEETING = "/meeting",
    CHAT_WINDOW = "/chat-window",
    DICE = "/dice",
    PARTICIPANT_DETAILS = "/participant-details",
    SPEAKERS = "/speakers",
    SPEAKERSEARCH = "/speakers",
    SPEAKER_DETAILS = "/speaker-details",
    SPONSORS_DETAILS = "/sponsors-details",
    BREAKOUT_ROOM_CALL = "/breakout-room-call",
    ACTIVE_Call = "/active-call",

 */