import React, {useEffect, useState, useContext, useMemo} from "react";
import {Route,Switch,useHistory, Redirect} from "react-router-dom";
import Location from "../../hooks/Location";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import Live from "./Components/Pages/Live/Live";
import { Context as AuthContext } from "../../context/AuthContext";
import VideoVault from './Components/Pages/VideoVault/VideoVault';
import VideoVaultDetails from './Components/Pages/VideoVault/VideoVaultDetails';
import Sessions from './Components/Pages/ActiveSessions/Sessions';
import AllSlideDecks from './Components/Pages/SlideDecks/AllSlideDecks';
import BreakoutRoom from './Components/Pages/BreakoutRooms/BreakoutRoom';
import BreakoutRoomcall from './Components/Pages/BreakoutRooms/BreakoutComponets/BreakoutRoomcall';
import Network from './Components/Pages/Networking/Network';
import ActivesCall from './Components/Pages/Networking/ActivesCall';
import DailyTrivia from './Components/Pages/DailyTrivia/DailyTrivia';
import AllSponsors from './Components/Pages/Sponsors/AllSponsors';
import SocialWall from './Components/Pages/SocialWall/SocialWall';
import Profile from './Components/Pages/Profile/Profile';
import Faq from './Components/Pages/Faq/Faq';
import ProfileSession from './Components/Pages/Profile/ProfileCompnents/ProfileSession';
import ParticipantDetails from "./Components/Pages/Networking/ParticipantDetails";
import Speaker from "./Components/Pages/Speaker/Speaker";
import SpeakerDetails from "./Components/Pages/Speaker/SpeakerDetails";
import SponsorsDetails from "./Components/Pages/Sponsors/SponsorsDetails";
import Layout from "./Components/Layout/Layout";
import useSlug from "../../hooks/useSlug";
import {URLContants} from "../../Model/URLContants";
import ScrollIntoView from "./Components/ScrollIntoView";
import HomePage from "./Components/Pages/Home/HomePage";
import { SecurityApi } from "../../hooks/shared/SecurityApi";

const getLayoutWrapper = (Page:any, props:any) => {
    //to render all others components except header and footer
    return (
        <Layout>
            <Page {...props} />
        </Layout>
    );
  };

export const RouteWrapper = (domain: string) => (path: string) => {
    return `${domain}${path}`
}

const Routes = () => {
    const slug = useSlug()
    const Path = RouteWrapper(slug.absolutePath)

    // const {
    //     state: { isLoggedIn },
    // } = useContext(AuthContext);

    const {
        isLoggedIn,
        security
    } = SecurityApi();
    
    const isPuplic = () => security === "public";

    if (!isLoggedIn && !isPuplic()) {
        return <Switch>
        <Route exact path={Path(URLContants.LOGIN)} render={(props) => <Login security={null}/>}/>
        <Route exact path={Path(URLContants.REGISTER)} render={(props) => <Register/>}/>
        <Redirect  to={Path("/login")}/>
        </Switch>
    }

    return <ScrollIntoView>
    <Switch>
        <Route exact path={slug.absolutePath} render ={(props) => getLayoutWrapper(HomePage, props)}/>
        <Route exact path={Path(URLContants.LIVE)} render ={(props) => getLayoutWrapper(Live, props)}/>
        <Route exact path={Path(URLContants.VIDEO_VAULT)} render ={(props) => getLayoutWrapper(VideoVault, props)}/>
        <Route exact path={Path(URLContants.VIDEO_VAULT_DETAILS)} render ={(props) => getLayoutWrapper(VideoVaultDetails, props)}/>
        <Route exact path={Path(URLContants.SESSIONS)} render ={(props) => getLayoutWrapper(Sessions, props)}/>
        <Route exact path={Path(URLContants.SLIDE_DECKS)} render ={(props) => getLayoutWrapper(AllSlideDecks, props)}/>
        <Route exact path={Path(URLContants.BREAKOUT_ROOM)} render ={(props) => getLayoutWrapper(BreakoutRoom, props)}/>
        <Route exact path={Path(URLContants.BREAKOUT_ROOM_CALL)} render ={(props) => getLayoutWrapper(BreakoutRoomcall, props)}/>
        <Route exact path={Path(URLContants.SPONSORS)} render ={(props) => getLayoutWrapper(AllSponsors, props)}/>
        <Route exact path={Path(URLContants.SPONSORS_DETAILS)} render ={(props) => getLayoutWrapper(SponsorsDetails, props)}/>
        <Route exact path={Path(URLContants.SOCIAL_WALL)} render ={(props) => getLayoutWrapper(SocialWall, props)}/>
        <Route exact path={Path(URLContants.NETWORK)} render ={(props) => getLayoutWrapper(Network, props)}/>
        <Route exact path={Path(URLContants.NETWORKPROFILE)} render ={(props) => getLayoutWrapper(Network, props)}/>
        <Route exact path={Path(URLContants.ACTIVE_Call)} render ={(props) => getLayoutWrapper(ActivesCall, props)}/>
        <Route exact path={Path(URLContants.DAILY_TRIVIA)} render ={(props) => getLayoutWrapper(DailyTrivia, props)}/>
        <Route exact path={Path(URLContants.PROFILE)} render ={(props) => getLayoutWrapper(Profile, props)}/>
        <Route exact path={Path(URLContants.FAQ)} render ={(props) => getLayoutWrapper(Faq, props)}/>
        <Route exact path={Path(URLContants.PROFILE_SESSION)} render ={(props) => getLayoutWrapper(ProfileSession, props)}/>
        <Route exact path={Path(URLContants.PARTICIPANT_DETAILS)} render ={(props) => getLayoutWrapper(ParticipantDetails, props)}/>
        <Route exact path={Path(URLContants.SPEAKERS)} render ={(props) => getLayoutWrapper(Speaker, props)}/>
        <Route exact path={Path(URLContants.SPEAKERSEARCH)} render ={(props) => getLayoutWrapper(Speaker, props)}/>
        <Route exact path={Path(URLContants.SPEAKER_DETAILS)} render ={(props) => getLayoutWrapper(SpeakerDetails, props)}/>
        <Redirect  to={slug.absolutePath}/>
    </Switch>
    </ScrollIntoView>
}
export default  Routes;