import React from 'react'
import { Link } from 'react-router-dom';
import { EventApi } from '../../../../hooks/shared/EventApi';
import useSlug from '../../../../hooks/useSlug';
import { Translations } from '../../../../hooks/shared/Translations';
import { URLContants } from '../../../../Model/URLContants';

const Footer = () => {
   const slug = useSlug();
   const Path = (url) => slug.absolutePath + url;
   const {
      state:{
         details,
         options,
         event
      }
   } = EventApi();

   const {
      translations
   } = Translations();


  return (
    <>
        {/* -- footer_area -- */}
         <div className="footer_area">
            <div className="deshtop_footer_area">
            <div className="container-fluid">
               <div className="row align-items-center">
                  <div className="col-md-2 col-lg-2 col-xl-4">
                     <div className="footer_left_box">
                        <p><a href="#">Privacy policy</a></p>
                     </div>
                  </div>
                  <div className="col-md-10 col-lg-10 col-xl-8">
                     <div className="footer_right_box">
                        <ul>
                           <li className="copyright">© 2021 Webble-Up all rights reserved</li>
                           <li>E-mail voorkeuren</li>
                           <li>Persoonlijke gegevens beheren</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            </div>
            <div className="mobile_footer_area">
             <div className="container">
               <p>© 2021 Webble-Up all rights reserved</p>
             </div>
         </div>
         </div>
         {/* -- footer_area_close -- */}
    </>
  )
}

export default Footer