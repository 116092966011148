import React, {useState, useContext, useEffect, useRef} from 'react';
import SessionsDetails from './SessionsDetails';
import {Link, useHistory} from 'react-router-dom';
import { useToasts } from "react-toast-notifications";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import SessionsCategory from './SessionsCategory';
import SessionsMonthBy from './SessionsMonthBy';
import SessionsMobile from './SessionsMobile';
import useSlug from "../../../../../hooks/useSlug";
import { ShimmerTitle,ShimmerThumbnail } from "react-shimmer-effects";
import SessionsLoad from './SessionsLoad';
import _ from "lodash";
import {SessionApi} from "../../../../../hooks/shared/SessionApi";
import {Translations} from "../../../../../hooks/shared/Translations";
import SessionsLoadMobile from './SessionsLoadMobile';
import SessionsMobileCategory from './SessionsMobileCategory';
import { ModalIframe } from '../../../../../UIComponets/Modal/ModalIframe';
import {SessionApiV2} from "../../../../../hooks/shared/SessionApi2";
import $ from 'jquery';

const Sessions = () => {
    const {
        getSessionsData,
        setSelectedDate,
        setSelectedCategory,
        setSelectMySessions,
        state: {
            // months,
            // sessionMap,
            // categories,
            // registered_sessions,
            // sessionItems,
            // selectedCategory,
            // selectMySessions,
            // selectedDate,
            timezone,
            event
        }
    } = SessionApi();
    const [isActiveEu, setisActiveEu] = useState(false);
    const {
        setSelectedDateV2,
        setSelectedCategoryV2,
        setSelectMySessionsV2,
        setActiveEu,
        state: {
            selectedCategoryV2,
            selectMySessionsV2,
            selectedDateV2,
            monthsV2,
            filteredSessions,
            sessionMapV2,
            categoriesV2,
            sessions
        }

    } = SessionApiV2()

    const {
        translations
    } = Translations()

    const [isLoading, setIsLoading] = useState(false);

    const [scrollCheck, setScrollCheck] = useState(false);
    const [replay, setReplay] = useState({});

    // Rreact Lifecycle Methods
    useEffect(()=> {
        if (!sessions){
            setIsLoading(true);
            getSessionsData((res, error) => {
                if(res?.data.status){
                    setIsLoading(false);
                }
            });
        }

    }, []);

    const selectedSession = (select) => {
        setSelectedDateV2(select);
    }

    const filterSessionData = (categorieId) => {
        setSelectedCategoryV2(categorieId)
    }

    // All and My Session 
    const handlereLoadSessions = (type) => {
        setScrollCheck(true);
        setSelectMySessionsV2(type);
    }

    const onCloseModal = (close) => {
        console.log(close);
    }  

    const setEventTimeZone = (status) => {
        setisActiveEu(status)
        setActiveEu(status)
    }

    const checkVo = () => {
        $('#modalVideo').on('hidden.bs.modal', function () {
            $("#modalVideo iframe").attr("src", $("#modalVideo iframe").attr("src"));
        }); 
    }

    const playerRef = useRef()
    const setReplayData = (data) => {
        setReplay(data)
        playerRef?.current?.play()
    }

    return (
        <>
            {/*Start Session Mobile view */}
            <div className="mobile_session_area mt-50 mb-50">
                <div className="container">
                    <h1 className="mobile-main-heading-two"><span><i className="fa fa-angle-right"></i></span>{translations?.sessions?.title}</h1>
                    <div className="session_header_inner">
                        <SessionsLoadMobile
                            isActive={selectMySessionsV2}
                            loadSessions={handlereLoadSessions}
                            translations={translations}
                        />
                        <ul className="nav all-sessions_tab">
                            <li className={isActiveEu ? "active" : ""} onClick={() => {setEventTimeZone(true)}}>
                                <Link to={"#"} >{event?.shortTimezone} {translations?.general?.time}</Link>
                            </li>
                            <li className={isActiveEu ? "" : "active"} onClick={() => setEventTimeZone(false)}>
                                <Link to={"#"}>{translations?.general?.device_time}</Link>
                            </li>
                        </ul>
                    </div>

                    {/* Mobile Category */}
                    {!isLoading ? categoriesV2?.length > 0 ? (<SessionsMobileCategory
                    filterSessionData={filterSessionData} 
                    sessionCat={categoriesV2}
                    isActive={selectedCategoryV2}
                    translations={translations}
                    />) : null : (<ShimmerTitle line={1} gap={10} variant="primary" />)}

                    {/* Mobile Session List */}
                    {!isLoading  ?
                    (<SessionsMobile
                        sessionItem={sessionMapV2}
                        timezone={timezone}
                        sessionCat={categoriesV2}
                        isActiveEu={isActiveEu}
                        setReplay={setReplayData}
                    />)
                    :(<ShimmerTitle line={2} gap={10} variant="primary" />)}
                </div>
            </div>
             {/*End Session Mobile view */}


            {/* <!-- sessions_header_tab --> */}
            <div className="sessions_header_area">
                <div className="container">
                    <div className="session_header_inner">
                        <SessionsLoad
                            isActive={selectMySessionsV2}
                            loadSessions={handlereLoadSessions}
                            translations={translations}
                        />
                        <ul className="nav eu_time_tab all-sessions_tab">
                            <li className={isActiveEu ? "active" : ""} onClick={() => setEventTimeZone(true)}>
                                <Link to={"#"} >{event?.shortTimezone} {translations?.general?.time}</Link>
                            </li>
                            <li className={isActiveEu ? "" : "active"} onClick={() => setEventTimeZone(false)}>
                                <Link to={"#"}>{translations?.general?.device_time}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!--sessions_header_tab_close  --> */}

            {/* <!-- session_area --> */}
            <div className="session_area mt-50">
                <div className="container">
                    <h1 className="main_heading mb-20">{translations?.sessions?.title}</h1>

                    {/* Session category here */}
                    {!isLoading ? categoriesV2?.length > 0 ? (<SessionsCategory
                        filterSessionData={filterSessionData}
                        sessionCat={categoriesV2}
                        isActive={selectedCategoryV2}
                        translations={translations}
                        check={scrollCheck}
                    />) : null : (<ShimmerTitle line={1} gap={10} variant="primary" />)}
                    
                   
                    <div className="session_inner_box">
                        {/* Session click show by month */}
                        {!isLoading ? (
                            <SessionsMonthBy
                                keys={monthsV2}
                                onClick={selectedSession}
                                active={selectedDateV2}
                            />
                        ) : (
                            <ShimmerThumbnail width={80} height={80} rounded center/>
                        )}
                        
                        <div className="session_inner_right">
                            {/* Session list will be come here  */}
                            {!isLoading  ?
                            (<SessionsDetails 
                                sessionItem={filteredSessions}
                                timezone={timezone}
                                sessionCat={categoriesV2}
                                isActiveEu={isActiveEu}
                                setReplay={setReplayData}
                            />)
                            :(<ShimmerTitle line={2} gap={10} variant="primary" />)}
                        </div>
                    </div>
                </div>
            </div>
            <ModalIframe ref={playerRef} onClose={() => onCloseModal(null)} size='lg' replayPlayer={replay} id={"replay-btn-modal"} />
            {checkVo}
            {/* <!-- session_area_close --> */}
        </>
    )
}

export default Sessions
