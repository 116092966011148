import React, {useState, useEffect, useRef, useMemo} from "react";
import {OnlineUser} from "../../../../../../Util/Utils";
import {AudioDisableIcon, AudioEnableIcon, VideoDisableIcon, VideoEnableIcon} from "../UIComponents/VideoChatIcons";

const Participant = ({ participant, details, className, isSelf, videoEnable, audioEnable }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const [isAudioEnable, setAudioEnable] = useState( true);
  const [isVideoEnable, setVideoEnable] = useState(true);

  const user = useMemo(() => {
    return OnlineUser(details)
  }, [details])

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    function handleTrackEnabled(track) {
      track.on('enabled', () => {
        /* Hide the avatar image and show the associated <video> element. */
        if (track.kind === "video"){
          setVideoEnable(true)
        }else if (track.kind === "audio"){
          setAudioEnable(true)
        }
      });
      track.on('disabled', () => {
        /* Hide the avatar image and show the associated <video> element. */
        if (track.kind === "video"){
          setVideoEnable(false)
        }else if (track.kind === "audio"){
          setAudioEnable(false)
        }
      });
    }
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        handleTrackEnabled(publication.track);
      }
      publication.on('subscribed', handleTrackEnabled);
    });

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      setVideoEnable(videoTrack?.isEnabled)
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      setAudioEnable(audioTrack?.isEnabled)
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    if (isSelf){
      setAudioEnable(audioEnable)
    }
  }, [audioEnable])

  useEffect(() => {
    if (isSelf) {
      setVideoEnable(videoEnable)
    }
  }, [videoEnable])

  return (
    <div className={`participant ${className}`}>
      <video className="participant-video" ref={videoRef} autoPlay={true} />
      <audio ref={audioRef} autoPlay={true} />
      {  <div className="participant_header">

        {isVideoEnable ? <VideoEnableIcon/> : <VideoDisableIcon />}
        {isAudioEnable ? <AudioEnableIcon/> : <AudioDisableIcon />}
      </div> }
      {<p className="participant-name"> { isSelf ? "You" : user.getFullName()}</p>}
    </div>
  );
};

export default Participant;

