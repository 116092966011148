export  enum SettingOptions {
    MAGIC_LINK_ENABLE_CODE = "magic_link_enable_code",
    SECOND_SCREEN = "second_screen",
    BREAKOUT_ROOMS = "breakout_rooms",
    SOCIAL_WALL = "social_wall",
    NT_SHOW_SOCIAL_LINKS = "nt_show_social_links",
    SPONSERS = "sponsors",
    SESSIONS = "sessions",
    SPEAKERS = "speakers",
    DAILY_TRIVIA = "daily_trivia",
    EMBED_PLAYER = "embed_player",
    TIMETABLE = "timetable",
    FILE = "file",
    Q_AND_A = "q_and_a",
    POLL = "poll",
    CUSTOM_HEADER = "custom_header",
    HIDE_TITLE = "hide_title",
    TICKER = "ticker",
    VOTE = "vote",
    HYBRID = "hybrid",
    NT_ROLLING_DICE = "nt_rolling_dice",
    SUPPORT = "support",
    REGISTRATION_AVATAR = "registration_avatar",
    VOD = "vod",
    NETWORKING_TOOL = "networking_tool",
    NETWORK = "network",
    RATING = "rating",
    QA = "qa",
    CHAT = "chat",

    CHAT_FOR_EACH_CHANNEL = "chat_for_each_channel",
    RATING_FOR_EACH_CHANNEL = "rating_for_each_channel",
    POLL_FOR_EACH_CHANNEL = "poll_for_each_channel",
    QA_FOR_EACH_CHANNEL = "qa_for_each_channel",
    VOTE_FOR_EACH_CHANNEL = "vote_for_each_channel",


    CHAT_FOR_CHANNEL = "chat_for_channel_",
    RATING_FOR_CHANNEL = "rating_for_channel_",
    POLL_FOR_CHANNEL = "poll_for_channel_",
    VOTE_FOR_CHANNEL = "vote_for_channel_",
    QA_FOR_CHANNEL = "qa_for_channel_",

    SLIDE_DECKS = "slide_decks",
    EMOTICONS = "emoticons",

}