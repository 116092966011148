import React from 'react'
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { AZPagination } from '../../../../../UIComponets/AZPaggination';

const SlideDecksMobileCat = ({ filterSlideDecks, slideDecksCate, isActive, searchSlideDecks, translations, onClick, currentChar}) => {
    return (
        <>
            <div className="video_vault_mobile">
                  <ul className="video_vault_nav mobile_video_vault mb-20">
                    <li className={isActive === "all" ? "active" : ""} onClick={()=> filterSlideDecks("all")}>
                        <Link to={"#"}>{translations?.general?.all}</Link>
                    </li>
                    {slideDecksCate && slideDecksCate.map((category, index)=>{
                        return (
                            <li key={index} style={{color:category?.color}} className={isActive === category?.id ? "active" : ""} onClick={()=> filterSlideDecks(category?.id)}>
                                <Link to={"#"}>{category?.name}</Link>
                            </li>
                        )
                    })}
                </ul>
                  <div className="searchbar_right mb-20">
                    <input type="text" placeholder="Search.." name="search" className="form-control" onChange={(e)=>searchSlideDecks(e)} />
                    <button type="button">
                        <FaSearch/>
                    </button>
                  </div>
                  <div className="sponsors_searchbar_pagination mb-40 d-flex justify-content-center">
                        <AZPagination onClick={(char) => onClick(char)} currentChar={currentChar}/>
                  </div>
               </div>  
        </>
    )
}

export default SlideDecksMobileCat
