import {forwardRef, useEffect, useImperativeHandle, useState, useContext} from "react";
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import useSlug from "../../../../../../hooks/useSlug";

export const RollingDice = forwardRef(({isMatched = false, animationEndedCallback}, ref) => {
    const slug = useSlug();
    const min = 1;
    const max = 20;
    const [rand1, setRand1] = useState({ rand1: 0, rand2: 0 });
    const [rand2, setRand2] = useState({ rand1: 0, rand2: 0 });
    const [timer_interval, setTimer_interval] = useState(null);
    const [timer_sec, setTimer_sec] = useState(0);


    const [callbackInterval, setCallbackInterval] = useState(null)


    useImperativeHandle(
        ref,
        () => ({
            rollingDice() {
                setRand1({ rand1: getRandom(), rand2: getRandom() });
                setRand2({ rand1: getRandom(), rand2: getRandom() });
                setTimeout(() => {
                    setTimer_sec(0);
                }, 2000)
                setTimer_sec(15);
            }
        }),
    )


    useEffect(() => {
        if (isMatched) {
            setRand2(rand1)
        }else {
            if (rand1.rand1 === rand2.rand1 && rand1.rand2 === rand2.rand2) {
                setRand2({ rand1: rand1.rand2, rand2: rand1.rand1 })
            }
        }
    }, [isMatched])


    useEffect(() => {
        if (timer_sec > 0) {
            startTimer();
        } else {
            setCallbackInterval((prev) => setTimeout(() => {
                if (prev){
                    clearInterval(prev)
                }
                animationEndedCallback && animationEndedCallback()
            }, [2000]))
        }
    }, [timer_sec]);

    const startTimer = () => {
        if (timer_sec > 0) {
            setTimeout(() => setTimer_sec((prev) => prev - 1), 1000);
            const rand1 = { rand1: getRandom(), rand2: getRandom() }
            let rand2 = { rand1: getRandom(), rand2: getRandom() }
            if (isMatched){
                rand2 = rand1
            }
            setRand1(rand1);
            setRand2(rand2);
        } else {
            clearInterval(timer_interval);
        }
    };


    

    const getRandom = () => {
        return (Math.floor(Math.random() * (max - min)) + min) * 90;
    };

    const stylePlayer1 = {
        transform: "rotateX(" + rand1.rand1 + "deg) rotateY(" + rand1.rand2 + "deg)",
    };

    const stylePlayer2 = {
        transform: "rotateX(" + rand2.rand1 + "deg) rotateY(" + rand2.rand2 + "deg)",
    };




    return (
        <>
            <div className="dice-wrap dice-pairing-wrap pt-5">
                <div className="row align-items-center justify-content-center my-5">
                    <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                        <section className="dice-container">
                            <div id="player1" className="cube-box" style={stylePlayer1}>
                                <div className="front">
                                    <span className="dot dot1"></span>
                                </div>
                                <div className="back">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                </div>
                                <div className="right">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                    <span className="dot dot3"></span>
                                </div>
                                <div className="left">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                    <span className="dot dot3"></span>
                                    <span className="dot dot4"></span>
                                </div>
                                <div className="top">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                    <span className="dot dot3"></span>
                                    <span className="dot dot4"></span>
                                    <span className="dot dot5"></span>
                                </div>
                                <div className="bottom">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                    <span className="dot dot3"></span>
                                    <span className="dot dot4"></span>
                                    <span className="dot dot5"></span>
                                    <span className="dot dot6"></span>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                        <section className="dice-container">
                            <div id="player2" className="cube-box" style={stylePlayer2}>
                                <div className="front">
                                    <span className="dot dot1"></span>
                                </div>
                                <div className="back">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                </div>
                                <div className="right">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                    <span className="dot dot3"></span>
                                </div>
                                <div className="left">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                    <span className="dot dot3"></span>
                                    <span className="dot dot4"></span>
                                </div>
                                <div className="top">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                    <span className="dot dot3"></span>
                                    <span className="dot dot4"></span>
                                    <span className="dot dot5"></span>
                                </div>
                                <div className="bottom">
                                    <span className="dot dot1"></span>
                                    <span className="dot dot2"></span>
                                    <span className="dot dot3"></span>
                                    <span className="dot dot4"></span>
                                    <span className="dot dot5"></span>
                                    <span className="dot dot6"></span>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
});