import {
  AUTHENTICATION,
  GS1AUTHENTICATION,
  GS1LOGIN,
  LOGIN,
  REGISTRATION,
  DEFAULT_AUTHENTICATION_WHILE_REGISTRATION,
  DEFAULT_REGISTRATION,
  DEFAULT_AUTHENTICATION_WHILE_LOGIN,
  DEFAULT_LOGIN,
  FORGET_PASSWORD_DATA,
  RESET_PASSWORD_DATA,
  UPDATE_PROFILE, UPDATE_LOGIN_STATE,
} from "../constants/ActionsConstants/actiontypes";
import createDataContext from "./createDataContext";
import { useToasts } from "react-toast-notifications";
import React, { useEffect } from "react";
import { baseUrl } from "../constants/url/urlConstants";
import DBNetwork from "../api/DBNetwork";
import toast from "react-hot-toast";

const headers = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",

};

const authReducer = (state, action) => {
  switch (action.type) {
    case REGISTRATION:
      return {
        ...state,
        user: action.payload,
      };
    case LOGIN:
      return {
        ...state,
        user: action.payload,
      };
    case GS1AUTHENTICATION:
      return {
        ...state,
        isAuthenticate: action.payload,
      };
    case DEFAULT_AUTHENTICATION_WHILE_REGISTRATION:
      return {
        ...state,
        registrationPageData: action.payload,
      };
    case DEFAULT_REGISTRATION:
      return {
        ...state,
        isLoggedIn:  window.User.isLoggedIn(),
        user: action.payload,
      };
    case GS1LOGIN:
      return {
        ...state,

        isLogin: action.isLogin,
      };
    case DEFAULT_LOGIN:
      return {
        ...state,
        apiResponse: action.payload,
        isLogin: action.isLogin,
      };
    case DEFAULT_AUTHENTICATION_WHILE_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
        loginPageData: action.payload,
      };

    case FORGET_PASSWORD_DATA:
      return {
        ...state,
        forgetPasswordData: action.payload,
      };
    case RESET_PASSWORD_DATA:
      return {
        ...state,
        resetPasswordData: action.payload,
      };
    case UPDATE_LOGIN_STATE:
      return  {
        ...state,
        isLoggedIn:  window.User.isLoggedIn(),
        loginPageData: action.payload,
      }
    default:
      return state;
  }
};

// ****************************** Defaults Registration **********************************

const defaultAuthenticationWhileRegistration = (dispatch) => async (slug, callback) => {
  try {
    const response = await DBNetwork.get(slug + "/register");
   await dispatch({
      type: DEFAULT_AUTHENTICATION_WHILE_REGISTRATION,
      payload: response.data.data,
    });
    callback(response, null);
  } catch (e) {
    callback(null, e);
   }
};

const onRegister = (dispatch) => (slug, data, callback) => {
    DBNetwork.post(slug + "/register", { ...data })
    .then((response) => {
      if (response.data.status === "success") {
        window.User.setLoginData(response.data.data)
        callback(response.data);
        dispatch({
          type: DEFAULT_REGISTRATION,
          payload: response.data.message,
        });
      }
    })
    .catch((err) => {
      console.error(err);
      callback(err.response.data);
      toast(err.response.data.message);
    });
};

// ****************************** Defaults Login **********************************

const defaultAuthenticationWhileLogin = (dispatch) => async (slug) => {
  const url = slug + "/login";
  try {
    const response = await DBNetwork.get(url);
    dispatch({
      type: DEFAULT_AUTHENTICATION_WHILE_LOGIN,
      payload: response.data.data,
    });
    window.User.setDefaultLoginData(response.data?.data)
  } catch (e) { }
};

const onLogin = (dispatch) => async (
  slug,
  data,
  type,
  callback
) => {
  await DBNetwork.post(slug + "/login", { ...data })
  .then((response) => {
      if (type === "password") {
        // const pass = localStorage.getItem(`${client/event}`,);
        // const pass = localStorage.removeItem("eventPass");
        // const systemID = localStorage.getItem('system_id');
        // if (!pass) {
        //   localStorage.setItem(`eventPass`, JSON.stringify(response.data));
        // }
        // else if (!systemID) {
        //     localStorage.setItem('system_id', 'Event Password');
        // }
      }
      window.User.setLoginData(response.data?.data)
      callback && callback(response.data, null);
      if (response.status === 200) {
        updateLoginStatus(dispatch, response.data?.data)()
      } else {
        // toast(response.data.message);
      }
    })
    .catch((e) => {
      callback  && callback(null, e);
      // dispatch({ type: DEFAULT_LOGIN, payload: e, isLogin: false });
    });
};

const updateLoginStatus = (dispatch, response) => async () => {
  dispatch({ type: UPDATE_LOGIN_STATE, payload:response})
};

//  **************************************** GS1 Login ********************************
// Auth while loading login page.
const GS1Authentication = (dispatch) => async (slug) => {
  const url = baseUrl(slug + "/gs/login");
  try {
    const response = await DBNetwork.get(url);
    dispatch({ type: GS1AUTHENTICATION, payload: response.data });
  } catch (e) { }
};

// login into GS1 login page.

const GS1Login = (dispatch) => async (email, password, slug, callback) => {
  const url = baseUrl(slug + "/gs/authenticate");

  DBNetwork.post(url, { email, password }, { headers })
    .then((response) => {
      callback(response.data);
      dispatch({ type: GS1LOGIN, payload: response.data, isLogin: true });
    })
    .catch((err) => {
      callback(err.message);
    });
};

const updateProfile = (dispatch) => async (slug, data, callback) => {
  const response = await DBNetwork.post(slug+"/user-update", { ...data });
  callback(response);
  // dispatch({ type: UPDATE_PROFILE, payload: response.data });
};

const forgetPassword = (dispatch) => async (slug, data, callback) => {
  const url = baseUrl(slug);
  try {
    const response = await DBNetwork.post(url, { email: data });
    callback(response.data);
    await dispatch({ type: FORGET_PASSWORD_DATA, payload: response.data });
  } catch (e) { }
};

const resetPassword = (dispatch) => async (slug, data, callback) => {
  const url = baseUrl(slug);
  try {
    const response = await DBNetwork.post(url, { ...data });
    callback(response.data);
    await dispatch({ type: FORGET_PASSWORD_DATA, payload: response.data });
  } catch (e) { }
};
const validateToken = (dispatch) => async (slug, callback) => {
  const url = baseUrl(slug);
  try {
    const response = await DBNetwork.get(url);
    // callback(response)
    await dispatch({ type: RESET_PASSWORD_DATA, payload: response.data });
  } catch (e) { }
};

const checkEmail = (dispatch) => async (slug, data,  callback) => {
    try {
        const url = slug+"/check-email";
        const response = await DBNetwork.post(url, data);
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
};

export const { Context, Provider } = createDataContext(
  authReducer,
  {
    onRegister,
    onLogin,
    updateProfile,
    GS1Login,
    GS1Authentication,
    defaultAuthenticationWhileRegistration,
    defaultAuthenticationWhileLogin,
    forgetPassword,
    resetPassword,
    validateToken,
    updateLoginStatus,
    checkEmail
  },
  {
    user: [],
    isAuthenticate: false,
    token: null,
    isLoggedIn:  window.User.isLoggedIn(),
    response: null,
    apiResponse: null,
    loginPageData: {},
    registrationPageData: {},
    forgetPasswordData: {},
    resetPasswordData: {},

  }
);
