import {LinkIcon} from "../../../../SVG/Icons";
import {FileIcon} from "../../../../UIComponets/Icons/FileIcon";
import OwlCarousel from "react-owl-carousel";
import React from "react";
import {BiDownload, BiLink} from "react-icons/bi";
import {Link} from "react-router-dom";
import {imageWithStorage} from "../../../../constants/url/urlConstants";
import ReactHtmlParser from 'react-html-parser';

export const VideoItem = (props) => {
    const {id, title, description, thumbnail, vimeo_id, link, file} = props;
    return <div className="items">
        <div className="s_video_vault_box mb-30">
            <div className="vault_box_img" type="button" data-toggle="modal" data-target="#videovault-details" onClick={props.onClick}>
                <img src={thumbnail} alt={title}  />
                <span className="video_vault_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="79.006" height="79.006" 
                    viewBox="0 0 79.006 79.006"><path id="Icon_material-play-circle-outline" 
                    data-name="Icon material-play-circle-outline" 
                    d="M34.6,60.279,58.3,42.5,34.6,24.727ZM42.5,3a39.5,39.5,0,1,0,39.5,39.5A39.517,39.517,0,0,0,42.5,3Zm0,71.106a31.6,31.6,0,1,1,31.6-31.6A31.644,31.644,0,0,1,42.5,74.106Z" 
                    transform="translate(-3 -3)" fill="#fdfefe" opacity="0.75"></path>
                    </svg>
                    </span>
            </div>
            <div className="vault_box_content">
                <h4 className="sub-heading pt-20 pb-40">{title}</h4>
                <p className="description v_pink_border">
                    {description && ReactHtmlParser(description?.substring(0, 120)+'..' ?? '')}
                </p>
                <a href="#" data-toggle="modal" data-target="#videovault-details" onClick={props.onClick}>{props?.translations?.general?.more ?? "Read More"}</a>
                <div className="video_vault_btn">
                    {link ?
                        <a href={link} target="_blank" >
                        <button type="btn" className="btn btn_border_yellow">
                            <LinkIcon/>
                            Link
                        </button> </a>: ""
                    }
                    { file ? (
                        <Link to={imageWithStorage + file} target="_blank" download>
                            <button type="btn" className="btn btn_border_yellow ml-3">
                                <FileIcon />
                                files
                            </button>
                        </Link>
                    ) : null}
                </div>
            </div>
        </div>
    </div>
}