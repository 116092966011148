import React, {useEffect, useState} from "react";
import { FaQuestion } from "react-icons/fa";
import {EventApi} from "../../../../hooks/shared/EventApi";
import Footer from "../Footer/Footer";
import WebMobileHeader from "../../../Event/Components/Partials/Header/WebMobileHeader";
import useBodyClass from "../../../../hooks/BodyClass";
import { AppSettingOptions } from "../../../../hooks/shared/AppSettings";
import Logout from "../Auth/Logout";
import FaqModal from "../../../Event/Components/Pages/Faq/FaqModal";
import { Translations } from "../../../../hooks/shared/Translations";

const LayoutMobile = ({children}) => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useBodyClass("organization-body");
    const {
        fetchEventsPage,
        state:{
            options
        }
    } = EventApi()

    const {
        translations
    } = Translations();

    useEffect(() => {
        fetchEventsPage()
    }, [])

    useEffect(() => {
        const bodyClass = document.querySelector(".organization-body");
        if (bodyClass) {
            bodyClass.setAttribute("style","");
        }
    }, []);

        useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 600) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const {
        isSlidedackEnable,
        isNetworkingEnable
    } = AppSettingOptions();


  return (
    <>
        <WebMobileHeader/>
        {children}
         {options?.includes('faq') && <div className="faq_section"><span className="faq_footer" data-toggle="modal" data-target="#showFaq"><FaQuestion/></span></div>}
        <Footer/>
        {options?.includes('faq') && (<FaqModal translations={translations}/>)}
        {showTopBtn && (<div className="arrow_footer_top" onClick={goToTop}>
            <span class="arrow_footer"><i class="fa fa-chevron-up"></i></span>
        </div>)}
        <Logout/>
    </>
  )
}

export default LayoutMobile