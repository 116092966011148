import React, {useMemo} from "react";
import { Link } from "react-router-dom";

const Items = ({currentPage, onClick, pagination}) => {
    const mapItems = (text, index) => {
        return   <li key={index} onClick={(e) => onClick(text)} className={`page-item ${ currentPage === text ? "" : "disabled"}`}> <Link to={"#"} className="page-link" > {text} </Link></li>}

    return <div className="sponsors_searchbar_pagination">
        <ul className="pagination">
            <li onClick={(e) => onClick("All")}  className={`page-item ${ currentPage == "@" ? "" : 'disabled'}`}><Link to={"#"} className="page-link">ALL</Link></li>
            { pagination?.map(mapItems)}
        </ul>
    </div>
}

const DOTS = '...';

const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({totalCount, pageSize, siblingCount = 1, currentPage }) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

        /*
          We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 2 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {

            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
};

export const AZPagination = ({onClick, currentChar, pagination}) => {
    const  totalCount = pagination ? pagination?.length : 26
    const  siblingCount = 1
    const  currentPage = currentChar ? currentChar.charCodeAt(0) - 64 : 0
    const  pageSize = 1
    let paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

    paginationRange = paginationRange?.map((item, index) => {
        if (item === DOTS){
            return DOTS;
        }
        if (!pagination){
            return String.fromCharCode(item + 64)
        }else {
            return pagination[item - 1]
        }
    })

    return <>
        <Items onClick={(char) =>  {
            if (char !== DOTS) {
                onClick(char)
            }
        }
        } pagination={paginationRange} currentPage={String.fromCharCode( 64 + currentPage)}/>
    </>
}