import {useContext, useEffect, useState} from "react";
import {Context as languageContext } from "../../context/LanguageContext";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import _ from "lodash";

export function TickerHook(){

    const {
        state: {
            langauge
        }, 
    } = useContext(languageContext);

    const context = useContext(defaultContext);

    let local = langauge ? langauge : localStorage.getItem("lan");

    const tickers = _.map(context?.state?.tickerMap, (v, k) => v)
    const object = tickers?.filter(t => t.status === 1 && local === t.locale)?.reduce((prev, next) => {
        if (next.for_event){
            if (!prev.event_ticker) {
                prev.event_ticker = []
            }
            prev.event_ticker.push(next)
        }
        next?.for_channels?.forEach(c => {
            if (prev[c]){
                prev[c].push(next)
            }else {
                prev[c] = [next]
            }
        })
        return  {
            ...prev,
        }
    }, {})

    const sorted = {};
    _.map(object ?? {}, (v, k) => {
        sorted[k] = v.sort((a, b) => a.position - b.position)
    })





    return {
        ...sorted
    }
}