
class NotificationCenter {
    static shared = null;

    constructor() { }

    _container = null;
    static sharedInstance() {
        if (this.shared === null) {
            this.shared = new NotificationCenter();
            this.shared._container = {};
        }
        return this.shared;
    }

    registerNotification(key, callback) {
        NotificationCenter.shared._container[key] = callback;
    }

    broadcastNotification(key, message) {
        const method = NotificationCenter.shared._container[key];
        if (method) {
            method(message);
        }
    }

}

export default NotificationCenter;