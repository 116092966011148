import { useToasts } from 'react-toast-notifications';

export enum ToastType {
    ERROR = "ERROR",
    INFO = "INFO",
    SUCCESS = "SUCCESS"
}

export const ErrorOption = {
    appearance: "error",
    autoDismiss: true,
}
export const SuccessOption = {
    appearance: "success",
    autoDismiss: true,
}
export const InfoOption = {
    appearance: "info",
    autoDismiss: true,
}

export const getOptions = (type: ToastType) => {
    switch (type) {
        case ToastType.ERROR: return ErrorOption
        case ToastType.INFO: return InfoOption
        case ToastType.SUCCESS: return SuccessOption
    }
    return SuccessOption
}


// @ts-ignore
// export const showToast = (content: String, type: ToastType) => {
//     const { addToast } = useToasts();
//     const {appearance, autoDismiss} = getOptions(type)
//
//      addToast(content, {
//         appearance: appearance,
//         autoDismiss: autoDismiss
//     });
// };