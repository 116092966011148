import React, { useReducer, createContext } from 'react';

export default function createDataContext(reducer, actions, initialState) {
    
    const Context = createContext();

    const Provider = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, initialState);
        const boundActions = {}
        for (let key in actions) {
            boundActions[key] = actions[key](dispatch, state);
        };
       
        return (
            <Context.Provider value={{ state, ...boundActions }}>
                {children}
            </Context.Provider >
        )
    };
    return { Context, Provider };
};
