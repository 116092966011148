import {
  Breakout_Channel,
  Breakout_Event,
  Chat_Channel,
  ChatEvent,
  CommonEvent,
  CommonEventFront,
  OffAirPoll_Channel,
  OffAirPollEvent,
  OnAirPoll_Channel,
  OnAirPollEvent,
  OnAirRating_Channel,
  OnAirVote_Channel,
  PUSHER_APP_KEY,
  PUSHER_CLUSTER,
  QA_Answer_Channel,
  QAAnswerEvent,
  RatingEvent,
  Sponsor_Channel,
  Sponsor_Event,
  Ticker_Event,
  Tickers_Channel,
  VOD_Channel_defaultSection,
  VOD_Event__defaultSection,
  VoteEvent,
  Video_Decline_Channel,
  Video_Decline_Event,
  Video_End_Channel,
  Video_End_Event,
  VideoChatGlobalRequestEvent,
  VideoChatGlobalRequestChannel,
  VideoChatMessageEvent,
  VideoChatMessageChannel,
  CommonUserEventChannel,
  CommonUserEvent,
  InteractionChannelEvent,
  InteractionChannel,
  User_InteractionChannel,
  UserInteractionChannel,
  UserInteractionChannelEvent,
  PresenceChannel,
  SubscriptionSucceeded,
  MemberAdded, MemberRemoved, VideoChatRejectChannel, VideoChatRejectEvent,
} from "../constants/Pusher/PusherConstants";
import Pusher from "pusher-js";
import * as PusherTypes from "pusher-js";
import { DefaultContext } from "react-icons";
import { Context as DefaultSectionContext } from "../context/DefaultSectionContext";
import { useContext } from "react";
import toast from "react-hot-toast";
import EventObject from "../classes/EventObject";
import Logger from "../Util/Logger";
import {VideoCallApi} from "./videoCallApi";
class PusherWrapper {
  static shared = null;

  _pusher = null;
  _event = null;

  /**
   * @returns {PusherWrapper}
   */

   initialize(event) {
    this._event = event;

    let headers = {};
    const eventUrlParam = event?.event_id ? "?event_id=" + event?.event_id : "";
    const system_id = localStorage.getItem("system_id")
    const systemIdParam =  system_id ? "&system_id=" + system_id : "";
    let url = `${process.env.REACT_APP_API_HOST}/v2.0/api/pusher/auth${eventUrlParam}${systemIdParam}`;

    if (window.User.isLoggedIn() && window.User.getToken()) {
      headers["Authorization"] = `Bearer ${window.User.getToken()}`;
    }
    PusherWrapper.shared._pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_CLUSTER,
      encrypted: false,
      authEndpoint: url,
      auth: {
        headers: {
          ...headers,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        },
      },
    });

    PusherWrapper.shared._pusher.connection.bind("error", function (err) {
      if (err?.code === 404) {
       // Logger.errorLog("Pusher Over limit! " + JSON.stringify(err));
      } else {
        //Logger.errorLog("Pusher Error " + JSON.stringify(err));
      }
    });
    //Pusher.log = Logger.debugLog;
  }
  static sharedInstance() {
    if (PusherWrapper.shared === null) {
      PusherWrapper.shared = new PusherWrapper();
    }
    return this.shared;
  }

  removeChannels(channels) {
    if (this._pusher) {
      this._pusher
        .allChannels()
        .filter((channel) => channels.includes(channel.name))
        .forEach((channel) => this._pusher.unsubscribe(channel.name));
    }
  }

  pusher() {
    return this._pusher;
  }

  // registerGlobalChatEvets(details, context) {
  //   const { videoChatGlobalRequestEvent,  commonUserAppointmentEvent} = context;
  //
  //   const pusherEventMap = {
  //     videoChatGlobalRequestEvent: EventObject({
  //       channel: VideoChatGlobalRequestChannel,
  //       event: VideoChatGlobalRequestEvent,
  //       callback: videoChatGlobalRequestEvent,
  //     }),
  //     userCommanEvent: EventObject({
  //       channel: CommonUserEventChannel + details?.event?.id + "_",
  //       event: CommonUserEvent,
  //       callback: commonUserAppointmentEvent,
  //     })
  //   };
  //   const registeredEvent = this.register(this._pusher, details?.user?.id);
  //   Object.entries(pusherEventMap).forEach((entry) => {
  //     const [key, eventObj] = entry;
  //     const channel = eventObj.getChannel();
  //     const event = eventObj.getEvent();
  //     const callback = eventObj.getCallback();
  //     registeredEvent(channel, event, callback);
  //   });
  // }

  unRegisterGlobalChatEvets() {
    const pusher = this._pusher;
    pusher
      .allChannels()
      .filter((channel) => channel.name.includes("videochat.globalrequest"))
      .forEach((channel) => pusher.unsubscribe(channel.name));
  }

  registerNetworkingTool(match_id, context) {
    //  const {} = context;
    const { videoChatDeclineEvent, videoChatEndEvent, videoChatMessageEvent, videoChatRejectEvent } =
      context;

    const pusherEventMap = {
      videoChatDeclineEvent: EventObject({
        channel: Video_Decline_Channel,
        event: Video_Decline_Event,
        callback: videoChatDeclineEvent,
      }),
      videoChatEndEvent: EventObject({
        channel: Video_End_Channel,
        event: Video_End_Event,
        callback: videoChatEndEvent,
      }),
      videoChatMessageEvent: EventObject({
        channel: VideoChatMessageChannel,
        event: VideoChatMessageEvent,
        callback: videoChatMessageEvent,
      }),
      videoChatRejectEvent: EventObject({
        channel: VideoChatRejectChannel,
        event: VideoChatRejectEvent,
        callback: videoChatRejectEvent,
      }),
    };

    const registeredEvent = this.register(this._pusher, match_id);
    Object.entries(pusherEventMap).forEach((entry) => {
      const [key, eventObj] = entry;
      const channel = eventObj.getChannel();
      const event = eventObj.getEvent();
      const callback = eventObj.getCallback();
      registeredEvent(channel, event, callback);
    });
  }
  registerChannels(event, context) {
    this.initialize(event);
    const {
      setPusherTickerData,
      setSponserData,
      setVodData,
      setBreakOutData,
      insertChatMessage,
      pusherEvent,
      setQnAData,
      setOnAirPoll,
      setOffAirPoll,
      setOnAirVote,
      setOnAirRating,
      setPusherOnlineUser,
      memberAdd,
      memberRemove,
    } = context;

    const pusherEventMap = {
      ticker: EventObject({
        channel: Tickers_Channel,
        event: Ticker_Event,
        callback: setPusherTickerData,
      }),
      sponser: EventObject({
        channel: Sponsor_Channel,
        event: Sponsor_Event,
        callback: setSponserData,
      }),
      vod: EventObject({
        channel: VOD_Channel_defaultSection,
        event: VOD_Event__defaultSection,
        callback: setVodData,
      }),
      breakout: EventObject({
        channel: Breakout_Channel,
        event: Breakout_Event,
        callback: setBreakOutData,
      }),
      q_n_q: EventObject({
        channel: QA_Answer_Channel + event?.user?.id + ".",
        event: QAAnswerEvent,
        callback: setQnAData,
      }),
      insert_chat: EventObject({
        channel: Chat_Channel,
        event: ChatEvent,
        callback: insertChatMessage,
      }),
      pusherEvent: EventObject({
        channel: "commonfront_",
        event: CommonEventFront,
        callback: pusherEvent,
      }),
      on_air_poll: EventObject({
        channel: OnAirPoll_Channel,
        event: OnAirPollEvent,
        callback: setOnAirPoll,
      }),
      off_air_poll: EventObject({
        channel: OffAirPoll_Channel,
        event: OffAirPollEvent,
        callback: setOffAirPoll,
      }),
      on_air_vote: EventObject({
        channel: OnAirVote_Channel,
        event: VoteEvent,
        callback: setOnAirVote,
      }),
      on_air_rating: EventObject({
        channel: OnAirRating_Channel,
        event: RatingEvent,
        callback: setOnAirRating,
      }),
      presence: EventObject({
        channel: "presence-online-",
        event: "pusher:subscription_succeeded",
        callback: setPusherOnlineUser,
      }),
      memberAdd: EventObject({
        channel: "presence-online-",
        event: "pusher:member_added",
        callback: memberAdd,
      }),
      memberRemove: EventObject({
        channel: "presence-online-",
        event: "pusher:member_removed",
        callback: memberRemove,
      }),
    };
    const registeredEvent = this.register(this._pusher, event.id);
    Object.entries(pusherEventMap).forEach((entry) => {
      const [key, eventObj] = entry;
      const channel = eventObj.getChannel();
      const event = eventObj.getEvent();
      const callback = eventObj.getCallback();
      registeredEvent(channel, event, callback);
    });
  }

  v2RegisterChannels(event, context) {
     const {user_id, event_id} = event;
     this.initialize(event)

    const {
      interactionEvent,
      userInteractionEvent,
      videoChatEndEvent,
      videoChatGlobalRequestEvent
    } = context;

    const pusherEventMap = {
      interaction: EventObject({
        channel: InteractionChannel,
        event: InteractionChannelEvent,
        callback: interactionEvent,
      }),
      user_interaction: EventObject({
        channel: UserInteractionChannel + user_id + "_",
        event: UserInteractionChannelEvent,
        callback: userInteractionEvent,
      }),
    }
    this.subscribePresenceChannel(event, context);
    const registeredEvent = this.register(this._pusher, event.event_id);
    Object.entries(pusherEventMap).forEach((entry) => {
      const [key, eventObj] = entry;
      const channel = eventObj.getChannel();
      const event = eventObj.getEvent();
      const callback = eventObj.getCallback();
      registeredEvent(channel, event, callback);
    });
  }
  unregisterNetworkToolChannels() {
    const pusher = this._pusher;
    pusher
      .allChannels()
      .filter(
        (channel) =>
          channel.name.includes("videochat.") &&
          !channel.name.includes("videochat.globalrequest")
      )
      .forEach((channel) => pusher.unsubscribe(channel.name));
  }
  unregisterAllChannels() {
    const pusher = this._pusher;
    pusher?.allChannels().forEach((channel) => pusher?.unsubscribe(channel.name));
  }

    unsubscribePresenceChannel(channel_id) {
      const pusher = this._pusher;
      pusher
        .allChannels()
        .filter(
          (channel) =>
            channel.name.includes(PresenceChannel)
        )
        .forEach((channel) => pusher.unsubscribe(channel.name));
  }

  subscribePresenceChannel(event, context) {
    const {user_id, event_id} = event;

   const {
     onlineUsers,
     memberAdd,
     memberRemove,
   } = context;

   const pusherEventMap = {
     presence: EventObject({
       channel: PresenceChannel,
       event: SubscriptionSucceeded,
       callback:onlineUsers,
     }),
     memberAdd: EventObject({
       channel: PresenceChannel,
       event: MemberAdded,
       callback: memberAdd,
     }),
     memberRemove: EventObject({
       channel: PresenceChannel,
       event: MemberRemoved,
       callback: memberRemove,
     }),
   }
   const registeredEvent = this.register(this._pusher, event.event_id);
   Object.entries(pusherEventMap).forEach((entry) => {
     const [key, eventObj] = entry;
     const channel = eventObj.getChannel();
     const event = eventObj.getEvent();
     const callback = eventObj.getCallback();
     registeredEvent(channel, event, callback);
   });
 }

  registerEvent(pusher, channelName, eventName, callback) {
    const channel = pusher.subscribe(channelName);
      Logger.debugLog(
        `PusherEvent Registring => [${channelName}] [${eventName}] [${callback.name}]`
      );
    channel.bind(eventName, (data) => {
      Logger.debugLog(
          `[${channelName}] [${eventName}] => ${JSON.stringify(data)}`
        );
      callback(data);
    });
  }
  register = (pusher, id) => (channelName, eventName, callback) =>
    this.registerEvent(pusher, channelName + id, eventName, callback);
}
export default PusherWrapper;
