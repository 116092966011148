import React, {useContext, useState} from 'react';
import moment from 'moment';
import { Image } from '../../../../../UIComponets/Image';
import { Link } from 'react-router-dom';
import { WifiIcon, FavoriteIcon, LinkIcon, EuroSymbol } from '../../../../../SVG/Icons';
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import {ResponseWrapper} from "../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import {Error} from "../../../../../Util/Utils";
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import {useToasts} from "react-toast-notifications";
import useSlug from "../../../../../hooks/useSlug";
import {SessionApi} from "../../../../../hooks/shared/SessionApi";
import ReactHtmlParser from 'react-html-parser';
import { Translations } from '../../../../../hooks/shared/Translations';
import { imageWithStorage } from '../../../../../constants/url/urlConstants';
import {SessionModel} from "../../../../../Model/SessionModel";
import { SessionStatus } from '../../../../../Model/SesstionStatus';
import { SessionApiV2 } from '../../../../../hooks/shared/SessionApi2';
import { URLContants } from '../../../../../Model/URLContants';
import { EventApi } from '../../../../../hooks/shared/EventApi';

const LoadingContainer = (session_id, isLoading) => (prev) =>{
    return {
        ...prev,
        [session_id]: isLoading
    }
}

const SessionsMobile = ({sessionItem, sessionCat, registered_sessions, timezone, isActiveEu, setReplay}) => {

    const {
        setFavoriteData,
        state:{
            event
        },
    } = SessionApi();

    const {translations} = Translations();

    const [showfiles, setShowfiles] = useState(false);

    const {
        getZoneTime,
        fetchSessionSpeakers
    } = SessionApiV2()

    const {
        state:{
            details,
            options
        }
    } = EventApi();


    const getTime = (time) => {
        return  getZoneTime(isActiveEu, time)
    };

    const map = sessionCat && sessionCat?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <h1 style={{background:current?.color}} className="mb-40">{current?.name.toUpperCase()}</h1>
        }
    }, {});
    const mapRegistered = registered_sessions && registered_sessions?.reduce((prev, current) => {
        return {
            ...prev,
            [current]: true
        }
    }, {});

    const [loadingContainer, setLoadingContainer] = useState({})
    const [favLoadingContainer, setFavLoadingContainer] = useState({})
    const {
        enrollSession,
        unEnrollSession
    } = useContext(defaultContext);
    const { addToast } = useToasts();
    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;
    const enrollSessionRequest = (session_id) => {

        setLoadingContainer(LoadingContainer(session_id, true))
        enrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false))
        })
    }

    const unEnrollSessionRequest = (session_id) => {
        setLoadingContainer(LoadingContainer(session_id, true))
        unEnrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false));
        })
    }

    const isLoading = (id) => loadingContainer[id]
    const isFavLoading = (id) => favLoadingContainer[id]

    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id);
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleFavoirte = (id) => {
        setFavLoadingContainer(LoadingContainer(id, true))
        setFavoriteData({id}, (response, error) => {
            if (response?.data?.status) {

            }
            setFavLoadingContainer(LoadingContainer(id, false))
        });
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }
    const [fetchLoadingContainer, setFetchLoadingContainer] = useState({})

    const fetchSessionDetails = (item) => {
        if (item.people === null && fetchLoadingContainer[item?.id] === undefined){
            setFetchLoadingContainer((prev) => {
                return {
                    ...prev,
                    [item?.id]: true
                }
            })
            fetchSessionSpeakers(item.id, (response, error) => {
                setFetchLoadingContainer((prev) => {
                    return {
                        ...prev,
                        [item?.id]: false
                    }
                })
            })
        }
    }

    return (
        <>        
        {sessionItem && Object.keys(sessionItem).map(item => {             
            return (<>
                    <div className="mobile_session_date mt-40">
                        <div className="mobile_ed_time_section accordion" id={`accordionExample`}>
                            <h4 className="date_heading">{moment(`${item}`).format("DD MMM") }</h4>
                            {sessionItem[item].map(i => SessionModel(i)).map((items, index) => {
                                return(
                                    
                                    <div className="mobile_accordion_item" key={index}>
                                        <div className="m_ed_time_box">
                                            <div className="time_box">
                                                <p>{getTime(items?.start)}<br/>{getTime(items?.end)}</p>
                                            </div>
                                            <div className={`mobile_accordion_header ${items?.meta?.hide_action_button ? "hide-btn-action": ""}`}>
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={`#collapseOne_${items?.id}`} aria-expanded="true" aria-controls="collapseOne" onClick={() => fetchSessionDetails(items)}>
                                                    <span className="coll_header" title={items?.title}>{items?.title?.substring(0, 100)}</span>
                                                    <span className="coll_icon">
                                                        <svg
                                                            className="svg-inline--fa fa-angle-down fa-w-10"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="angle-down"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 320 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                    
                                                    </button>
                                                    {!items?.meta?.hide_action_button && (<>
                                                        {items.getStatus() === SessionStatus.JOIN && (<a href={items?.link} target={items?.target} className="btn btn_border_orange join">JOIN</a>)}
                                                        {items.getStatus() === SessionStatus.REGISTERED && (<a className="btn btn_border_orange registered" onClick={() => unEnrollSessionRequest(items?.id)}><IconLoader isLoading={isLoading(items?.id)}> REGISTERED </IconLoader></a>)}
                                                        {items.getStatus() === SessionStatus.REGISTER && (<a disabled={isLoading(items?.id)} className="btn btn_border_orange register" onClick={() => enrollSessionRequest(items?.id)}><IconLoader isLoading={isLoading(items?.id)}> REGISTER </IconLoader></a>)}
                                                        {items.getStatus() === SessionStatus.ENDED && (<a type="button" className="btn btn_border_orange ended">ENDED</a>)}
                                                        {items.getStatus() === SessionStatus.REPLAY && (<button type="button" data-toggle="modal" data-target="#replay-btn-modal"  className="btn btn_border_orange replay" onClick={() => setReplay({meta:items?.meta})}>REPLAY</button>)}
                                                        </>
                                                    )}
                                            </div>
                                        </div>
                                        <div id={`collapseOne_${items?.id}`} className="collapse" aria-labelledby="headingOne">
                                            <div className="session_info_box">
                                                <div className="emerging_sectors mt-40">
                                                        {map[items?.category_id]}
                                                    <p>{ReactHtmlParser(items?.detail ?? '')}</p>
                                                </div>
                                                <div className="row">
                                                    { fetchLoadingContainer[item.id] === true ? <p className={"text-center w-100"}>Fetching Speakers <IconLoader isLoading={true}/></p> : items?.people && items?.people.map((person , index) => {
                                                        return (
                                                        <div className="col-md-6 col-lg-6">
                                                            <div className="client_info mb-30" key={index}>
                                                                <div className="client_info_img">
                                                                    <Image src={person?.user?.avtar} placeholderSrc={"/images/placeholder_square.jpg"} alt={"people pic"}/>
                                                                </div>
                                                                <div className="client_info_content">
                                                                    <h4>
                                                                        <Link to={Path(URLContants.SPEAKERS)+`?search=${person?.user?.last_name}`}> {person?.user?.name} {person?.user?.last_name}</Link>
                                                                    </h4>
                                                                    <p>{person?.user?.title}</p>
                                                                    <h6>{person?.user?.company}</h6>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    })}
                                                </div>
                                                <div className="session_info_register_area">
                                                    <ul className="nav icon">
                                                        {items?.meta?.external_link ? (<li><a href={items?.meta?.external_link} target={items?.target}><LinkIcon/></a></li> ) : ""}
                                                        {options && options.includes('show_session_favourites') && (<li>
                                                            <button type="button" className="btn" onClick={() => handleFavoirte(items?.id)}>
                                                                <IconLoader isLoading={favLoadingContainer[items?.id]}>
                                                                    <FavoriteIcon color={items?.isWishlisted ? details?.primary_color : "none"} width="30" height="27.525" stroke={details?.primary_color}/>
                                                                </IconLoader>
                                                            </button>
                                                        </li>)}
                                                    </ul>
                                                    {!items?.meta?.hide_action_button && (<>
                                                        {items.getStatus() === SessionStatus.JOIN && (<a href={items?.link} target={items?.target}><button type="button" className="btn btn_border_orange join_border">JOIN</button></a>)}
                                                        {items.getStatus() === SessionStatus.REGISTERED && (<button type="button" className="btn btn_border_orange registered_border" onClick={() => unEnrollSessionRequest(items?.id)}><IconLoader isLoading={isLoading(items?.id)}>REGISTERED</IconLoader></button>)}
                                                        {items.getStatus() === SessionStatus.REGISTER && (<button disabled={isLoading(items?.id)} type="button"className="btn btn_border_orange register_border" onClick={() => enrollSessionRequest(items?.id)}> <IconLoader isLoading={isLoading(items?.id)}> REGISTER </IconLoader></button>)}
                                                        {items.getStatus() === SessionStatus.ENDED && (<button type="button" className="btn btn_border_orange ended_border">ENDED</button>)}
                                                        {items.getStatus() === SessionStatus.REPLAY && (<button type="button" data-toggle="modal" data-target="#replay-btn-modal"  className="btn btn_border_orange replay_border" onClick={() => setReplay({meta:items?.meta})}>REPLAY</button>)}
                                                    </>
                                                    )}
                                                </div>
                                                <div className="col-lg-4 col-xl-4"> 
                                                    {items?.files?.length > 0 ? (
                                                        <div className="session_info_file">
                                                            <h2>{translations?.general?.files}</h2>
                                                            <ul className="list-group">
                                                                {items.files && items.files.map((file, index)=>{
                                                                    return(
                                                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                                            <span className="file_name">{file?.filename}</span>
                                                                                <a href={file && imageWithStorage+file?.path} target="_blank" rel="noopener noreferrer" download>
                                                                                <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                                                <g id="Group_1947" data-name="Group 1947">
                                                                                <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                                                <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                                                </g>
                                                                                <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                                                </g>
                                                                                </svg>
                                                                                </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>) 
                                                    : null} 
                                                    {items?.files?.length > 1 && (
                                                        <a href={`${process.env.REACT_APP_API_HOST}/v2.0/zip-download/${event?.id}?module_id=${items?.id}&type=timetable`} target="_blank" className="text-dark">
                                                        <span className="mr-2">
                                                            <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                            <g id="Group_1947" data-name="Group 1947">
                                                            <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                            <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                            </g>
                                                            <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                            </g>
                                                            </svg>
                                                        </span>
                                                        <span> Download All Files </span>
                                                    </a>
                                                    )}  
                                                </div>
                                            </div>
                                        </div>
                                    </div>        
                                )
                            })}
                    </div>
                </div>
            </>)
        })}
    </>)
}

export default SessionsMobile;
