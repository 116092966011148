import React from 'react'

const PurchaseModel = () => {
    return (
        <>
                   {/* <!-- The Modal --> */}
        <div className="modal fade connection_request shopping_card_model purchase_model" id="purchase">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div className="connection_request_body">
                            <div className="connection_p_head mb-20">
                                <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41.83" height="32.5" viewBox="0 0 41.83 32.5">
                                        <path
                                            id="Icon_awesome-check"
                                            data-name="Icon awesome-check"
                                            d="M14.207,36.439.613,22.275a2.244,2.244,0,0,1,0-3.082L3.57,16.111a2.032,2.032,0,0,1,2.958,0l9.158,9.542L35.3,5.215a2.033,2.033,0,0,1,2.958,0L41.218,8.3a2.244,2.244,0,0,1,0,3.082L17.165,36.439A2.033,2.033,0,0,1,14.207,36.439Z"
                                            transform="translate(0 -4.577)"
                                            fill="#f26334"
                                        />
                                    </svg>
                                </span>
                                <div className="info mb-30">
                                    <h4>Purchase successful</h4>
                                    <p>Paid sessions have been added to ‘my sessions’</p>
                                </div>
                            </div>

                            <div className="shopping_card_content">

                                 <div className="mobile_accordion_item">
                                <div className="m_ed_time_box">
                                    <div className="time_box">
                                        <p>
                                            11:00 <br/>
                                            13:00
                                        </p>
                                    </div>
                                    <div className="mobile_accordion_header">
                                        <button className="btn btn-link collapsed" >
                                            <span className="coll_header">Chapter 1: Announcem …</span>
                                           

                                          
                                        </button>
                                    </div>
                                </div>
                              
                            </div>

                             <div className="mobile_accordion_item">
                                <div className="m_ed_time_box">
                                    <div className="time_box">
                                        <p>
                                            11:00 <br/>
                                            13:00
                                        </p>
                                    </div>
                                    <div className="mobile_accordion_header">
                                        <button className="btn btn-link collapsed" >
                                            <span className="coll_header">Chapter 1: Announcem …</span>
                                           

                                          
                                        </button>
                                    </div>
                                </div>
                              
                            </div>

                             <div className="mobile_accordion_item">
                                <div className="m_ed_time_box">
                                    <div className="time_box">
                                        <p>
                                            11:00 <br/>
                                            13:00
                                        </p>
                                    </div>
                                    <div className="mobile_accordion_header">
                                        <button className="btn btn-link collapsed" >
                                            <span className="coll_header">Chapter 1: Announcem …</span>
                                           

                                          
                                        </button>
                                    </div>
                                </div>
                              
                            </div>
                                <div className="shopping_card_list mb-20">
                                    <div className="session_accordion_box">
                                        <div className="session_accordion_header">
                                            <div className="date">
                                                <p>
                                                    15.00 - 16.00 <br />
                                                    Sat 10/07
                                                </p>
                                            </div>
                                            <div className="description">
                                                <h4>The semantic approach to data sharing</h4>
                                                <button className="workshop_btn">WORKSHOP</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="shopping_card_list mb-20">
                                    <div className="session_accordion_box">
                                        <div className="session_accordion_header">
                                            <div className="date">
                                                <p>
                                                    15.00 - 16.00 <br />
                                                    Sat 10/07
                                                </p>
                                            </div>
                                            <div className="description">
                                                <h4>The semantic approach to data sharing</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="shopping_card_list mb-20">
                                    <div className="session_accordion_box">
                                        <div className="session_accordion_header">
                                            <div className="date">
                                                <p>
                                                    15.00 - 16.00 <br />
                                                    Sat 10/07
                                                </p>
                                            </div>
                                            <div className="description">
                                                <h4>The semantic approach to data sharing</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sopping_card_btn">
                                <button type="button" className="btn btn_border_orange">
                                    Return to my sessions
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        </>
    )
}

export default PurchaseModel
