import {VideoChatState} from "../hooks/VideoChatState";


class SharedVideoChatState {
    static _shared = null;

    constructor() {
        if (SharedVideoChatState._shared) {
            return SharedVideoChatState._shared;
        }
        SharedVideoChatState._shared = this;
    }

    static instance() {
        if(!SharedVideoChatState._shared){
            return new SharedVideoChatState();
        }
        return SharedVideoChatState._shared;
    }

    setEvent(event){
        SharedVideoChatState._shared.event = event;
    }
    getEvent(){
        return SharedVideoChatState._shared.event
    }
    setVideoChatObject(object) {
        SharedVideoChatState._shared.videoChat = object;
    }

    getSlug(){
        return SharedVideoChatState._shared.slug
    }
    setSlug(slug) {
        SharedVideoChatState._shared.slug = slug;
    }

    getVideoChatObject() {
        return SharedVideoChatState._shared.videoChat;
    }

    setVideoChatState(currentState) {
        SharedVideoChatState._shared.currentState = currentState;
    }
    getVideoChatState() {
        return SharedVideoChatState._shared.currentState ?? VideoChatState.DISCONNECTED;
    }
    setRoom(room) {
        SharedVideoChatState._shared.room = room;
    }

    getRoom() {
        return SharedVideoChatState._shared.room;
    }

    getObject() {
        return {
            slug: this.getSlug(),
            videoChatState: this.getVideoChatState(),
            event: this.getEvent(),
            videoChat: this.getVideoChatObject()
        }
    }
}
export default SharedVideoChatState;