import React, { Fragment, useContext, useEffect, useState } from "react";
import useSlug from "../../../../../hooks/useSlug";
import { useToasts } from "react-toast-notifications";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import { ResponseWrapper } from "../../../../../Model/ResponseWrapper";
import { getOptions, ToastType } from "../../../../../hooks/showToast";
import { Error } from "../../../../../Util/Utils";
import { IconLoader } from "../../../../../UIComponets/IconLoader";
import { InteractionSettings } from "../../../../../hooks/shared/InteractionSettings";

export const RatingTab = () => {

    const {
        selectedChannel,
        poll,
        details,
        rating,
        translations,
        isChatForEach
    } = InteractionSettings({})

    const channel = selectedChannel
    const slug = useSlug()
    const [isSending, setIsSending] = useState(false)
    const [selectionMap, setSelectionMap] = useState({})

    const [channel_id, setChannelId] = useState(selectedChannel?.current_channel?.id)
    useEffect(() => {
        setChannelId(selectedChannel?.current_channel?.id)

    }, [selectedChannel])

    const { addToast } = useToasts();
    const {
        sendRating
    } = useContext(defaultContext)

    useEffect(() => {
        if (rating?.questions) {
            setSelectionMap(prev => {
                return {
                    ...prev,
                    ...rating?.questions?.
                        filter(r => r?.given_star > 0).
                        reduce((prev, next) => {
                            return {
                                ...prev,
                                [next?.id]: {
                                    star: next?.given_star
                                }
                            }
                        }, {})
                }
            })
        }
    }, [JSON.stringify(rating ?? [])])

    const selectRating = (q, star) => {
        if (q.given_star > 0) return;

        setSelectionMap((prev) => {
            return {
                ...prev,
                [q?.id]: {
                    star: star
                }
            }
        })
    }

    const sendAnswer = ({ question_id }) => {
        if (!selectionMap[question_id]) return;
        const { star } = selectionMap[question_id]
        const review = document.getElementById("review_" + question_id).value;

        setIsSending(true)
        sendRating(slug.path, { question_id, star, review, channel_id }, (response, error) => {
            setIsSending(false)
            if (response) {
                const wrapper = ResponseWrapper(response)
                if (wrapper.getStatus()) {
                    const { appearance, autoDismiss } = getOptions(ToastType.INFO)
                    addToast(wrapper.getMessage(), { appearance, autoDismiss })
                }
            } else if (error) {
                const { appearance, autoDismiss } = getOptions(ToastType.ERROR)
                addToast(Error(error).getResponseError(), { appearance, autoDismiss })
            }
        })
    }

    return <div className="poll_accordion_content rating_acco_content">
        {
            rating.questions?.length > 0 ? rating.questions?.map((q, index) => {
                const question_id = q?.id
                const isAnswered = q?.given_star > 0;
                const answer = q?.given_star ?? 0;
                const star = q?.star ?? 5;

                return <Fragment key={question_id}>
                    <div className="p_acco_con_heading">
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="33.443" height="32.009"
                                viewBox="0 0 33.443 32.009">
                                <path id="Icon_awesome-star" data-name="Icon awesome-star"
                                    d="M16.369,1.113,12.287,9.389,3.154,10.72a2,2,0,0,0-1.106,3.413l6.607,6.438-1.563,9.1a2,2,0,0,0,2.9,2.107l8.17-4.294,8.17,4.294a2,2,0,0,0,2.9-2.107l-1.563-9.1,6.607-6.438a2,2,0,0,0-1.106-3.413L24.039,9.389,19.957,1.113a2,2,0,0,0-3.588,0Z"
                                    transform="translate(-1.441 0.001)" fill={details?.primary_color ? details?.primary_color : 'currentColor'} />
                            </svg>
                        </span>
                        <span className="content">
                            <h4>{translations?.rating?.title}</h4>
                            <p>{q.question}</p>
                        </span>
                    </div>
                    <div className="p_acco_con_body">
                        <div className="rating_box">
                            {
                                [...Array(star).keys()].reverse().map((r, index) => {
                                    const counter = r + 1

                                    return <Fragment key={index}>
                                        <label id={"rating-" + counter} type="radio" name="rating" value={counter} onClick={() => { selectRating(q, counter) }}>
                                            <svg className="svg-inline--fa fa-star fa-w-18" aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="star" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                                data-fa-i2svg="">
                                                <path fill={selectionMap[q?.id]?.star >= counter ? details?.primary_color : "currentColor"}
                                                    d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                            </svg>
                                        </label>
                                    </Fragment>
                                })
                            }

                        </div>
                        <div className="comment-area" hidden={isAnswered && (!q?.review || q?.review?.isEmpty())}>
                            <textarea defaultValue={isAnswered ? q?.review : ""} disabled={isAnswered} id={`review_${question_id}`} name="review" className="form-control" placeholder={q?.comment} rows="3" />
                        </div>
                        <div className="poll_send_btn mb-3" hidden={isAnswered}>
                            <button className="btn" disabled={isSending || !selectionMap[q?.id]?.star} onClick={() => sendAnswer({ question_id })}>
                                <IconLoader isLoading={isSending}>
                                    <img alt="image" src="/images/send.png" /> {translations?.rating?.send_buton_text}
                                </IconLoader>
                            </button>
                        </div>
                        {isAnswered ? `Given Rating : ${answer}` : ""}
                    </div>
                </Fragment>
            })
                : <><div className="p_acco_con_heading">
                    <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33.443" height="32.009"
                            viewBox="0 0 33.443 32.009" className="primary-color">
                            <path id="Icon_awesome-star" data-name="Icon awesome-star"
                                d="M16.369,1.113,12.287,9.389,3.154,10.72a2,2,0,0,0-1.106,3.413l6.607,6.438-1.563,9.1a2,2,0,0,0,2.9,2.107l8.17-4.294,8.17,4.294a2,2,0,0,0,2.9-2.107l-1.563-9.1,6.607-6.438a2,2,0,0,0-1.106-3.413L24.039,9.389,19.957,1.113a2,2,0,0,0-3.588,0Z"
                                transform="translate(-1.441 0.001)" />
                        </svg>
                    </span>
                    <span className="content">
                        <h4>{translations?.rating?.title}</h4>

                    </span>
                </div>
                    <div className="p_acco_con_body">
                        <div className="rating_box_not_rating">
                            <p>{details?.no_rating_message}</p>
                        </div>
                    </div>
                </>
        }
    </div>
}