import moment from 'moment';
import { Link } from 'react-router-dom';
import { FavoriteIcon } from '../../../../../../SVG/Icons';
import { EventApi } from '../../../../../../hooks/shared/EventApi';
const ProfileSessionMonth = ({keys, onClick, active}) => {
    const {
        state:{
            details
        } 
    } = EventApi();
    return (
        <>
            <div className="session_inner_left">
                <ul>
                    {keys && keys.map((dayMonth, index) => {
                        return (
                            <li key={index} onClick={() => onClick(dayMonth)} className={`${active === dayMonth ? "active" : ""} `}>
                                <Link to={"#"}>
                                    <h4>{moment(dayMonth).format("DD")}</h4>
                                    <p>{moment(dayMonth).format("MMM")}</p>
                                </Link>
                            </li>
                        )
                    })}
                    <li className={`${active === 'favorite' ? "active" : ""} `}>
                        <Link to={"#"} onClick={() => onClick('favorite')} >
                                <span>
                                    <FavoriteIcon stroke={active === "favorite" ? details?.primary_color : details?.secondary_color } width="45.04" height="40.004" />
                                </span>
                                <p>Favourites</p>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default ProfileSessionMonth
