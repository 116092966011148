import React,{ useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ShimmerTitle,ShimmerThumbnail } from "react-shimmer-effects";
import moment from 'moment';
import {FavoriteIcon} from '../../../../SVG/Icons';
import { SessionApiV2 } from '../../../../hooks/shared/SessionApi2';
import { Translations } from '../../../../hooks/shared/Translations';
import OnePageSession from './OnePageSession';
import OnePageSessionMobile from './OnePageSessionMobile';
import { ModalIframe } from '../../../../UIComponets/Modal/ModalIframe';
import SessionsCategory from '../../../Event/Components/Pages/ActiveSessions/SessionsCategory';
import SessionsMobileCategory from '../../../Event/Components/Pages/ActiveSessions/SessionsMobileCategory';
import $ from 'jquery';

const Session = () => {
   const [isActiveEu, setisActiveEu] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [replay, setReplay] = useState({});
   const [scrollCheck, setScrollCheck] = useState(false);

   const {
      getSessionsData,
      setSelectedDateV2,
      setSelectedCategoryV2,
      setSelectMySessionsV2,
      setActiveEu,
      state: {
            selectedCategoryV2,
            selectMySessionsV2,
            selectedDateV2,
            monthsV2,
            filteredSessions,
            sessionMapV2,
            categoriesV2,
            sessions,
            event,
            details,
            timezone
      }
   } = SessionApiV2();

   const {
      translations
   } = Translations();

   useEffect(()=> {
      if (!sessions){
          setIsLoading(true);
          getSessionsData((res, error) => {
              if(res?.data.status){
                  setIsLoading(false);
              }
          });
      }
  }, []);
   

   const selectedSession = (select) => {
      setSelectedDateV2(select);
   }

   const filterSessionData = (categorieId) => {
      setSelectedCategoryV2(categorieId)
   }
   
   // All and My Session 
   const handlereLoadSessions = (type) => {
      setScrollCheck(true);
      setSelectMySessionsV2(type);
   }

   const setEventTimeZone = (status) => {
      setisActiveEu(status);
      setActiveEu(status)
   }

   const playerRef = useRef()
   const setReplayData = (data) => {
         setReplay(data)
         playerRef?.current?.play()
   }

   const checkVo = () => {
      $('#modalVideo').on('hidden.bs.modal', function () {
          $("#modalVideo iframe").attr("src", $("#modalVideo iframe").attr("src"));
      }); 
   }

   const onCloseModal = (close) => {
      console.log(close);
   } 

   return (
        <>
         <section id="sessions">
            <div className="onepage_session_area pt-100">
               <div className="session_top_button_box session_top_desktop">
                  <ul className="all_session_button">
                     <li className={selectMySessionsV2 ? "" : "active"} onClick={() => handlereLoadSessions(0)}>
                        <Link to={"#"}>{translations?.sessions?.all_sessions}</Link>
                     </li>
                     <li className={selectMySessionsV2 ? "active" : ""} onClick={() => handlereLoadSessions(1)}>
                        <Link to={"#"}>{translations?.sessions?.my_sessions}</Link>
                     </li>
                  </ul>
                  <ul className="all_session_button cet_time_button">
                     <li className={isActiveEu ? "active" : ""} onClick={() => setEventTimeZone(true)}>
                        <Link to={"#"} >{event?.shortTimezone} {translations?.general?.time}</Link>
                     </li>
                     <li className={isActiveEu ? "" : "active"} onClick={() => setEventTimeZone(false)}>
                        <Link to={"#"}>{translations?.general?.device_time}</Link>
                     </li>
                  </ul>
               </div>
               <div className="container">
                  <div className="session_area_inner">
                     <h1 className="internal_main_heading">{translations?.sessions?.title}</h1>
                     <div className="session_top_button_box session_top_mobile">
                  <ul className="all_session_button">
                     <li className={selectMySessionsV2 ? "" : "active"} onClick={() => handlereLoadSessions(0)}>
                        <Link to={"#"}>{translations?.sessions?.all_sessions}</Link>
                     </li>
                     <li className={selectMySessionsV2 ? "active" : ""} onClick={() => handlereLoadSessions(1)}>
                        <Link to={"#"}>{translations?.sessions?.my_sessions}</Link>
                     </li>
                  </ul>
                  <ul className="all_session_button cet_time_button">
                     <li className={isActiveEu ? "active" : ""} onClick={() => setEventTimeZone(true)}>
                        <Link to={"#"} >{event?.shortTimezone} {translations?.general?.time}</Link>
                     </li>
                     <li className={isActiveEu ? "" : "active"} onClick={() => setEventTimeZone(false)}>
                        <Link to={"#"}>{translations?.general?.device_time}</Link>
                     </li>
                  </ul>
               </div>
                     <div className="session_all_category">
                        {/* Session category here */}
                        {!isLoading ? categoriesV2?.length > 0 ? (<SessionsCategory
                           filterSessionData={filterSessionData}
                           sessionCat={categoriesV2}
                           isActive={selectedCategoryV2}
                           translations={translations}
                           check={scrollCheck}
                        />) : null : (<ShimmerTitle line={1} gap={10} variant="primary" />)}
                     </div>
                     <div className="now_live_session">
                        <div className="live_section_itembox">
                           <div className="live_section_left_box">
                              <ul>
                                    {monthsV2 && monthsV2?.map((dayMonth, index) => {
                                          return (
                                             <li key={index} onClick={() => selectedSession(dayMonth)} className={`${selectedDateV2 === dayMonth ? "active" : ""} `}>
                                                <Link to={"#"}>
                                                      <h4>{moment(dayMonth).format("DD")}</h4>
                                                      <p>{moment(dayMonth).format("MMM")}</p>
                                                </Link>
                                             </li>
                                          )
                                    })}
                                    <li className={`${selectedDateV2 === "favorite" ? "active" : ""} `}>
                                          <Link to={"#"} onClick={() => selectedSession('favorite')} >
                                             <span>
                                                <FavoriteIcon stroke={selectedDateV2 === "favorite" ? details?.primary_color : details?.secondary_color } width="45.04" height="40.004" />
                                             </span>
                                             <p>Favourites</p>
                                          </Link>
                                    </li>
                              </ul>
                           </div>
                           
                           <div className="live_section_right_box">
                              {/* one page session mobile start */}
                                 <div className='op_mobile_session_area'>
                                    {!isLoading  ? (<OnePageSessionMobile
                                       sessionItem={sessionMapV2}
                                       timezone={timezone}
                                       sessionCat={categoriesV2}
                                       isActiveEu={isActiveEu}
                                       setReplay={setReplayData}
                                       event={event}
                                    />)
                                    :(<ShimmerTitle line={2} gap={10} variant="primary" />)}
                                 </div>
                                 {/* one page session mobile end */}
                              
                                 {/* one page session desktop  start */}
                                 <div className='op_desktop_session_area'>
                                    {!isLoading  ? (<OnePageSession 
                                       sessionItem={filteredSessions}
                                       timezone={timezone}
                                       sessionCat={categoriesV2}
                                       isActiveEu={isActiveEu}
                                       setReplay={setReplayData}
                                       event={event}
                                    />)
                                    :(<ShimmerTitle line={2} gap={10} variant="primary" />)}
                                 </div>
                                 {/* one page session desktop end */}
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <ModalIframe ref={playerRef} onClose={() => onCloseModal(null)} size='lg' replayPlayer={replay} id={"replay-btn-modal"} />
         {checkVo}
        </>
    )
}

export default Session
