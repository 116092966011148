import React, {useContext, useEffect, useState, useRef} from 'react'
import Dice from '../../../Event/Components/Pages/Networking/UIComponents/Dice'
import {AZPagination} from "../../../../UIComponets/AZPaggination";
import {Context as defaultContext} from "../../../../context/DefaultSectionContext";
import {VideoCallApi} from "../../../../hooks/videoCallApi";
import useSlug from "../../../../hooks/useSlug";
import {OPPeopleCard} from "./OPPeopleCard";
import PeopleCard from "../../../Event/Components/Pages/Networking/UIComponents/PeopleCard";
import InfiniteScroll from "react-infinite-scroll-component";
import {ShimmerPostList} from "react-shimmer-effects";
import ChatWindow from "../../../Event/Components/Pages/Networking/UIComponents/ChatWindow";
import Meeting from '../../../Event/Components/Pages/Networking/UIComponents/Meeting';
import {OnePageModalWrapper} from "../../../../UIComponets/Modal/OnePageModalWrapper";
import ParticipantDetails from "../../../Event/Components/Pages/Networking/ParticipantDetails";
import {NetworkUIState} from "../../../Event/Components/Pages/Networking/UIComponents/NetworkUIState";
import {
   OutgoingCallModel,
   OutgoingDetails
} from "../../../Event/Components/Pages/Networking/UIComponents/OutgoingCallModel";
import ActivesCall from "../../../Event/Components/Pages/Networking/ActivesCall";
import VideoChat from "../../../Event/Components/Pages/Networking/VideoChat/VideoChat";
import {VideoChatState} from "../../../../hooks/VideoChatState";
import IncomingCallPop from "../../../../UIComponets/IncomingCallPop";
import IncomingCallModel from '../../../Event/Components/Pages/Networking/UIComponents/IncomingCallModel';
import { ScheduleAppointmentModel } from '../../../Event/Components/Pages/Networking/UIComponents/ScheduleAppointmentModel';
import {SearchIcon, PeopleIcon, DiceIcon, MessagesIcon, MeetingIcon} from "../../../../SVG/Icons";
import { AppSettingOptions } from '../../../../hooks/shared/AppSettings';
import { IconLoader } from '../../../../UIComponets/IconLoader';


const Network = () => {
   const searchRef = useRef(null);
   const slug = useSlug()
   const {
      getNetworkPeople,
      usersDetail,
      onlineUsers,
      videoChatState,
      endChat
   } = VideoCallApi();

   const {isDiceEnable} = AppSettingOptions();
   const [currentChar, setCurrentChar] = useState(null)
   const [currentPage, setCurrentPage] = useState(0)
   const [isLoading, setIsLoading] = useState(false);
   const [isShowOffline, setShowoffline] = useState(false)
   const [peopleDetail, setPeopleDetail] = useState(null)
   const [vcState, setVCState]  = useState(null);
   const [searchQuery, setSearchQuery] = useState('');
   const [selectCategory, setSelectCategory] = useState('');
   const [inputTimeout, setInputTimeout] = useState(null);
   const [catActive, setCatActive] = useState("all");
   const [appoinmentUser, setAppointmentUser] = useState(null);
   const [appointmentUpdate, setAppointmentUpdate] = useState(null);


   const getDefaultMenu = () => {
      return isDiceEnable ? NetworkUIState.DICE : NetworkUIState.PEOPLE
  }
  const defaultMenu = getDefaultMenu()
  const [selectedMenu, setSelectedMenu] = useState(defaultMenu)

   useEffect(() => {
      if (videoChatState === VideoChatState.CONNECTED){
         window.$("#video-chat").modal("show")
      }
      if (videoChatState === VideoChatState.DISCONNECTED){
         window.$("#video-chat").modal("hide")
      }
   }, [videoChatState])



   useEffect(()=> {
      fetchMore()
   },[]);

   useEffect(() => {
      if (usersDetail?.pagination?.current_page) {
         setCurrentPage(usersDetail?.pagination?.current_page)
      }
   }, [usersDetail?.pagination?.current_page])

   const fetchMore = (currentPage, currentChar, search, category) => {
      setIsLoading(true);
      getNetworkPeople(slug.path, {current_page: currentPage, char: currentChar, search: search, category:category}, (res, error) => {
         if(res){
            setIsLoading(false);
         }
      });
   }

   const membersMap = onlineUsers?.membersMap;
   const users = (isShowOffline ? usersDetail?.users : onlineUsers?.members) ?? [];
   const [usersData, setUsersData] = useState(users);
   const pagination = !isShowOffline ? onlineUsers?.members?.filter(m => m.name).map(m => m.name?.charAt(0)) : null
   const paginations = [...new Set(pagination)];
   paginations.sort();

   useEffect(() => {
      if(users){
          setUsersData(users);
      }
   },[JSON.stringify(users)]);


   const checkBoxClick = (checked) => {
      // const checked = e.target.checked
      // setShowoffline(checked)
      setShowoffline(checked);
   }
   const Shimer = <ShimmerPostList postStyle="STYLE_FOUR" col={4} row={2} gap={20} />
   const hasMore = usersDetail?.pagination?.current_page < usersDetail?.pagination?.last_page && isShowOffline


   const charFilter = (char) => {
      const _char = (char === "All" ? null : char)
      setCurrentChar(_char)
      searchRef.current.value = "";
      if (char !== currentChar && isShowOffline){
          setCurrentPage(1)
          fetchMore(1, _char, "", selectCategory);
      }else if (char !== currentChar) {
          setUsersData(
              onlineUsers?.members.filter(i => {
                      if (_char !== 'All' && _char){
                          return  i?.name?.toLowerCase().indexOf(_char.toLowerCase()) === 0
                      }
                      return true
              })
          )
      }
  }

   const handlePeopleCategory = (category) => {
      setCatActive(category);
      setSelectCategory(category);
      setCurrentPage(1);
      if(!isShowOffline){
          if(category === "all"){
              fetchMore(currentPage, currentChar, searchQuery, category);  
          }
          if(category === "participant"){
              setUsersData(
                  onlineUsers?.members.filter(i => {
                      return !i?.connection
                  })
              )
          }
          if(category === "connection"){
              setUsersData(
                  onlineUsers?.members.filter(i => {
                      return i?.connection
                  })
              )
          }
      }else{
          if(category === "all"){
              fetchMore(currentPage, currentChar, searchQuery,"");
         }else{
             if(isShowOffline){
                 fetchMore(currentPage, currentChar, searchQuery, category);
             }
         }
      }
   }

   const searchNetworkUser = (query) => {
      setSearchQuery(query);
      if (inputTimeout) clearTimeout(inputTimeout)
      setInputTimeout(
          setTimeout(() => {
              if (query !== '' && isShowOffline) {
                  fetchMore(currentPage, "", query);
              }else if(query !== ''){
                  const findUsers = users && users.filter(item => {
                      const fullName = `${item?.name} ${item?.last_name}`.toLowerCase()
                      const _query = query?.trim().toLowerCase()
                      if (_query.length > 0){
                          return fullName.includes(_query);
                      }else {
                          return true;
                      }
                  });
                  setUsersData(findUsers)
              }
              else{
                  if(isShowOffline){
                      fetchMore(1, currentChar, query);
                  }else{                    
                    setUsersData(users)
                  }
              }
          }, 1000)
      )
  }

   const allUsers = usersData?.map(p => {
      return {
         ...p,
         isOnline: membersMap && membersMap[p?.id] ? true : !isShowOffline
      }
   })
   
   return (
        <>
         <section id="network">
            <div className="network_area network_people_area pt-100 pb-100">
               <div className="container">
                  <h1 className="internal_main_heading">NETWORK</h1>
                  <div className="network_area_inner mt-30">
                     <div className="row lg_row_reveres">
                        <div className="col-md-12 col-lg-10 col-xl-11">
                        {!isDiceEnable && (<div className="network_left_area">
                               <ul className="nav nav-tabs mobile_network_tab" role="tablist">
                                    <li className="nav-item">
                                       <a className={`nav-link ${catActive == "all" && "active"}`} data-toggle="tab" href="#ALL" onClick={() => handlePeopleCategory("all")} role="tab">ALL</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className={`nav-link ${catActive == "participant" && "active"}`} data-toggle="tab" href="#PARTICIPANTS" onClick={() => handlePeopleCategory("participant")} role="tab">PARTICIPANTS</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className={`nav-link ${catActive == "connection" && "active"}`} data-toggle="tab" href="#CONNECTIONS" onClick={() => handlePeopleCategory("connection")} role="tab">CONNECTIONS</a>
                                    </li>
                                 </ul>
                              <div className="network_heading_box">
                                 <div className="row justify-content-between align-items-center">
                                    <div className="col-7 col-md-8 col-lg-8 col-xl-5">
                                       <AZPagination   pagination={paginations?.length > 0 ? paginations : null} currentChar={currentChar} onClick={(char) => charFilter(char)}/>
                                    </div>
                                    <div className="col-5 col-md-4 col-lg-4 col-xl-3">
                                       <div className="network_toggle">
                                          <div className="switch_toggle">
                                             <label className="switch" htmlFor="checkbox_show">
                                                <input type="checkbox" id="checkbox_show" name='show' checked={isShowOffline} onClick={e => checkBoxClick(e.target.checked)}/>
                                                <div className="slider round"></div>
                                             </label>
                                             <p>Show {isShowOffline ?  "offline" : "online" } </p>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                                       <div className="network_searchbar">
                                          <input type="text" placeholder="Search.." ref={searchRef} name="search" className="form-control" onChange={(e) => searchNetworkUser(e.target.value)}/>
                                          <button type="submit"><IconLoader isLoading={isLoading}><SearchIcon/></IconLoader></button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="network_tab_area mt-40">
                                 <ul className="nav nav-tabs desktop_network_tab" role="tablist">
                                    <li className="nav-item">
                                       <a className={`nav-link ${catActive == "all" && "active"}`} data-toggle="tab" href="#ALL" onClick={() => handlePeopleCategory("all")} role="tab">ALL</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className={`nav-link ${catActive == "participant" && "active"}`} data-toggle="tab" href="#PARTICIPANTS" onClick={() => handlePeopleCategory("participant")} role="tab">PARTICIPANTS</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className={`nav-link ${catActive == "connection" && "active"}`} data-toggle="tab" href="#CONNECTIONS" onClick={() => handlePeopleCategory("connection")} role="tab">CONNECTIONS</a>
                                    </li>
                                 </ul>
                                 
                                 <div className="tab-content">
                                    <div className="tab-pane active" id="ALL" role="tabpanel">
                                       <InfiniteScroll
                                           dataLength={allUsers?.length ?? 0}
                                           next={() => fetchMore(currentPage + 1, currentChar)}
                                           hasMore={hasMore}
                                           loader={Shimer}
                                           endMessage={<></>}
                                       >
                                       <div className="all_network_people">
                                             <div className="row mt-60">
                                                {!isLoading && allUsers ?  allUsers?.map(t => <OPPeopleCard {...t} speakerDetailsCallback={() => setPeopleDetail(t)}/>) : (Shimer) }
                                             </div>
                                       </div>
                                       </InfiniteScroll>
                                    </div>
                                 </div>
                              </div>
                           </div>)}
                           {isDiceEnable && (<Dice/>)}
                        </div>
                        <div className="col-md-12 col-lg-2 col-xl-1">
                           <div className="network_right_area">
                              <ul className="network_navbar">
                                {!isDiceEnable && (<li className="active">
                                    <a type="button">
                                        <PeopleIcon/>
                                       <p>People</p>
                                    </a>
                                 </li>)}
                                 {isDiceEnable && (<li className="active">
                                    <a type="button">
                                        <DiceIcon/>
                                       <p>Dice</p>
                                    </a>
                                 </li>)}
                                 <li>
                                    <a  type="button" data-toggle="modal" data-target="#my_meetings">
                                       <MeetingIcon/>
                                       <p>My meetings</p>
                                    </a>
                                 </li>
                                 <li>
                                    <a type="button" data-toggle="modal" data-target="#o2o_chat">
                                       <MessagesIcon/>
                                       <p>My messages</p>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

           <OnePageModalWrapper className={"speaker_details_model"} id={"speaker-details"}>
              {
                 <ParticipantDetails 
                     openChatWindow={(details) => {
                        setSelectedMenu(NetworkUIState.CHAT)
                        setAppointmentUser(details)
                        window.$("#o2o_chat").modal("show");
                        window.$("#speaker-details").modal("hide")
                     }} 
                     details={peopleDetail}
                     backtoOverViewClick={() => alert("open back")}
                     callClick={(details) => {
                        window.$("#speaker-details").modal("hide")
                        setTimeout(() => {
                        window.$("#outging_call").modal("show")
                        }, 500)
                     }} 
                     scheduleAppointmentCallback= {(user) => {
                        setAppointmentUser(user);
                        window.$("#speaker-details").modal("hide")
                     }}
                  />
              }
           </OnePageModalWrapper>
           <OnePageModalWrapper className="connection_request outging_call" id="outging_call">
              <OutgoingDetails participantDetails={peopleDetail} startResponseCallback={(response, error) => {
                if (response){
                   window.$("#outging_call").modal("hide")
                   setTimeout(() => {
                      window.$("#video-chat").modal("show")
                   }, 500)
                }

              }}/>
           </OnePageModalWrapper>

           <OnePageModalWrapper className="dice_model" id="dice">
              <Dice/>
           </OnePageModalWrapper>

           <OnePageModalWrapper className="o2o_chat_model" id="o2o_chat">
              <ChatWindow details={appoinmentUser} openChatWindow={(details) => {
                                setSelectedMenu(NetworkUIState.CHAT)
                                setAppointmentUser(details)
                            }} />
           </OnePageModalWrapper>

           <OnePageModalWrapper className="my_meetings_model" id="my_meetings">
              <Meeting/>
           </OnePageModalWrapper>

           <OnePageModalWrapper className={"video_chat_model"} id={"video-chat"} onClose={() => {
              endChat({}, (response, error) => {
              })

           }}>
              <ActivesCall />
           </OnePageModalWrapper>
           <IncomingCallModel/>
           <ScheduleAppointmentModel participantDetails={appoinmentUser} appointmentUpdate={appointmentUpdate}/>
        </>

    )
}

export default Network
