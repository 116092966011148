import {useContext} from "react";
import useSlug from "../useSlug";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import {SettingOptions} from "../../classes/SettingOptions";
import {AppSettingOptions} from "./AppSettings";

export function SponsersApi() {
    const slug = useSlug().path;
    const {
        getSponserData,
        state: {
            eventsAllPagesData: { 
                sponsors,
                sponsors_categories,
                pagination
             },
            eventsPageData: {
                details,
                options,
            }
        },
    } = useContext(defaultContext);

    const {isSponsorsEnable} = AppSettingOptions()
    return {
       getSponserData: (callback) => getSponserData(slug, callback),
       state: {
           isSponsorsEnable: isSponsorsEnable,
           sponsors: sponsors,
           sponsors_categories:sponsors_categories,
           pagination:pagination,
           options:options,
           details:details
       }
    }
}