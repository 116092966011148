import React,{useContext} from 'react';
import {Link} from "react-router-dom";
import useSlug from "../../../../../hooks/useSlug";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import { Image } from '../../../../../UIComponets/Image';
import {
    ShimmerButton,
    ShimmerCategoryItem,
    ShimmerCircularImage,
    ShimmerText,
    ShimmerTitle
} from "react-shimmer-effects";


const WebHeader = () => {
    const slug = useSlug().absolutePath;
    const {
        state:{ 
           eventsPageData: {
            details,
            languages,
            translations,
               event
           },
        }
     } = useContext(defaultContext);
    return (
        <>
            {/* start header */}
            <div className="destop_header">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6">
                        <div className="header_logo">
                            <Link to={slug}>
                                {details ? 
                                (<Image src={details?.img_logo_desktop} placeholderSrc={"/images/logo-placeholder.png"}  alt="desktop logo"/>)
                                 : (<ShimmerCircularImage size={100} />) }
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <div className="header_text">
                            {details ? <p>{event?.title}</p> :<ShimmerTitle line={1} />}
                        </div>
                    </div>
                </div>
                </div>
            </div>  
            {/* end header */}
        </>
    )
}

export default WebHeader
