import {LiveIcon} from "./LiveIcon";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import {useTemplate} from "../../../../../hooks/shared/useTemplate";
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";
import { throttle } from 'lodash';
import {IconLoader} from "../../../../../UIComponets/IconLoader";


const NavItem = ({isActive, isLive, title, contextId, onClick, isLoading}) => {
    return <li className="nav-item">
        <button disabled={isLoading} onClick={onClick} className={`nav-link ${isActive ? "active" : ""}`}
           data-toggle="tab" href={contextId} role="tab"
           >
            {title}
            {isLive ? <LiveIcon/> : ""}
            {isLoading && isActive ? <IconLoader isLoading={true}/> : null}
        </button>
    </li>
}
export const LiveNavbar = ({onClick, activeId, channels}) => {

    const {
        state: {
            layout
        }
    } = useTemplate()
    const details = TemplateDetails(layout);

    const [isLoading, setIsLoading] = useState({key: null, isLoading: false})

    useEffect(() => {
        if (isLoading.isLoading){
            setTimeout(() => {
                setIsLoading((prevState => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                }))
            }, 1500)
        }
    }, [isLoading])

    useEffect(() => {
        if (isLoading.key === null){
            setIsLoading(prevState =>  {
                return {
                    ...prevState,
                    key: activeId
                }
            })
        }
    }, [activeId])



    return <div className="live_navbar_header">
           {details.getTemplate() !== Template.ONEPAGE && <span className="live-mobile-arrow"><i className="fa fa-angle-left"></i></span> }
            <ul className="nav nav-tabs" role="tablist">
            {
            channels?.sort((a,b) => a.position - b.position).map((item, index) => {
                return <NavItem key={index} isLoading={isLoading.isLoading} isActive={item.id === activeId} isLive={item.live} title={item.name} contextId={`CH-${item.id}`} onClick={(e) => {
                    !isLoading.isLoading && onClick(item.id, e)
                    if (isLoading.key !== item.id){
                        setIsLoading({key: item.id, isLoading: true})
                    }
                    e.preventDefault();
                }} />
            })
            }
            </ul>
    </div>
}