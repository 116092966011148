import React,{useRef, useState, useEffect} from 'react'
import { imageWithStorage } from '../../../../../constants/url/urlConstants';
import { Link } from 'react-router-dom';
import { Translations } from '../../../../../hooks/shared/Translations';
import { Image } from '../../../../../UIComponets/Image'; 
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {URLContants} from "../../../../../Model/URLContants";
import useSlug from '../../../../../hooks/useSlug';
import { FiLink } from "react-icons/fi";
import { CgPushChevronDown } from "react-icons/cg";
import { ShimmerThumbnail } from "react-shimmer-effects";
import ReactHtmlParser from 'react-html-parser';
import {VODPlayer} from "../../../../../classes/VODPlayer";
import VodFilesModel from './VodFilesModel';
import { SecurityApi } from '../../../../../hooks/shared/SecurityApi';

const VideoVaultDetailsDesk = ({vodDetails, isPeople, vod_categories, scrollBarArrow, onClick, scrollArrow, arrow, isLoading, event}) => {
    const slug = useSlug().absolutePath;
    const {translations} = Translations();
    const {security} = SecurityApi();

    const showDescription = () => {
        
        if(vodDetails?.description){
            return true
        }

        if(vodDetails?.category){
            return true
        }

        if(vodDetails?.link){
            return true
        }

        if(isPeople?.length > 0){
            return true
        }

        if(vodDetails?.files.length > 0){
            return true
        }

        return false
    }

    const mapVod = vod_categories && vod_categories?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <button className="btn btn-theme_pink" style={{border:`2px solid ${current?.color}`}}>{current?.name.toUpperCase()}</button>
        }
    }, {});

    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id+"_d");
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }

    return <>
        <div className="video_vault_Miniature_area pt-20">
            <div className="row">
                <div className={`${showDescription() ? 'col-md-12 col-lg-8' : 'col-md-12 col-lg-12 full_screen'}`}>
                    <div className="miniature_header mb-20">
                        <svg className="svg_orange" xmlns="http://www.w3.org/2000/svg" width="51.626"
                                height="31.074" viewBox="0 0 51.626 31.074">
                            <path id="Icon_awesome-video" data-name="Icon awesome-video" d="M30.133,4.5H4.284A4.092,4.092,0,0,0,0,8.368V31.706a4.092,4.092,0,0,0,4.284,3.868H30.133a4.092,4.092,0,0,0,4.284-3.868V8.368A4.092,4.092,0,0,0,30.133,4.5ZM47.109,7.551l-9.823,6.118V26.406l9.823,6.11c1.9,1.181,4.517-.024,4.517-2.088V9.639C51.626,7.583,49.018,6.369,47.109,7.551Z" transform="translate(0 -4.5)"/>
                        </svg>
                        <h4>{vodDetails?.title}</h4>
                    </div>
                    {!isLoading ? (
                        <div className="miniature_img">
                            { vodDetails && <VODPlayer object={vodDetails} source={vodDetails?.player_type} contentId={vodDetails?.vimeo_id} /> }
                        </div>
                    ) : (<ShimmerThumbnail height={480} rounded />)}
                </div>
                {showDescription() ? (<div className="col-md-12 col-lg-4">
                {!isLoading ? 
                    (<div className="miniature_details_box">
                    <div className="miniature_details_innerh" id="scroll-container" ref={arrow}>
                        <p>{ReactHtmlParser(vodDetails?.description ?? '')}</p>
                        <div className="miniature_details_btn">
                            {vodDetails && mapVod[vodDetails?.category]}
                            {vodDetails?.link ? (
                            <a href={vodDetails?.link} target={vodDetails?.target}>
                                <button className="btn btn-theme-orange vod-li-fi"><FiLink/> {translations?.general?.link}</button>
                            </a>
                            ) : "" }
                            {vodDetails?.files?.length > 0 && (
                                <button className="btn btn-theme-orange vod-li-fi" onClick={()=>handleFilePopup(vodDetails?.id)}><CgPushChevronDown/>{translations?.general?.files}</button>
                            )}
                           
                        </div>

                        {isPeople?.length > 0 ? (
                        <div className="miniature_spakars mt-40">
                            <h1 className="main_heading mb-20">Contacts</h1>
                            {isPeople && isPeople.map((peopleInfo, index)=> {
                                return (
                                    <div className="speakers_box mb-30" key={index}>
                                        <div className="speakers_img">
                                            {security === "registration" || security === "invitation" ? (
                                                <Link to={slug+`${ URLContants.NETWORK}/`+peopleInfo?.user?.id}>
                                                    <Image alt="image" src={peopleInfo?.user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'}/>
                                                </Link>) : (
                                                <Link to={slug+`${URLContants.SPEAKERS}?search=`+peopleInfo?.user?.last_name}>
                                                    <Image alt="image" src={peopleInfo?.user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'}/>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="speakers_content">
                                            {security === "registration" || security === "invitation" ? (<Link to={slug+`${URLContants.NETWORK}/`+peopleInfo?.user?.id}>
                                                <h6>{peopleInfo?.user?.name} {peopleInfo?.user?.last_name}</h6>
                                            </Link>) : (<Link to={slug+`${URLContants.SPEAKERS}?search=`+peopleInfo?.user?.last_name}>
                                                <h6>{peopleInfo?.user?.name} {peopleInfo?.user?.last_name}</h6>
                                            </Link>) }
                                        <h4>{peopleInfo?.user?.title}</h4>
                                        <p>{peopleInfo?.user?.company}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>) : null}
                    </div>
                    <VodFilesModel files={vodDetails?.files} id={vodDetails?.id+"_d"} close={handleClose} event={event}/>
                    <div className="read_more_icon" onClick={() => onClick()}>
                        {scrollBarArrow ? scrollArrow ? (<FaChevronUp className='active'/>) : (<FaChevronDown/>) : null}
                    </div>
                </div>)
                 : (<ShimmerThumbnail height={532} rounded />)}
                </div>) : null }

            </div>
        </div>
    </>
};

export default VideoVaultDetailsDesk;
