import {useContext, useEffect, useState} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import useSlug from '../../hooks/useSlug';
import moment from "moment";
import { Constants } from "../../classes/Constants";
import { EventApi } from "./EventApi";

export const SpeakerApi = () => {
    const slug = useSlug().absolutePath;
    const {
        getSpeakerDetails,
        state:{ 
            speakerDetail,
        }
     } = useContext(defaultContext);

     const {
        state:{
            event
        }
     } = EventApi();

     useEffect(() => {
        if(speakerDetail?.speakers){
            setAllSpeaker(speakerDetail?.speakers);
        }
    }, [speakerDetail?.speakers]);

 
    const [allSpeaker,setAllSpeaker] = useState(speakerDetail?.speakers);


    const speakerSearch = (query) => {
        let lowercasedValue = query.toLowerCase().trim();
        if (lowercasedValue === ""){setAllSpeaker(speakerDetail?.speakers);}
        else{ 
            const updatedSpeaker = speakerDetail?.speakers && speakerDetail?.speakers.filter((speaker) => {
                return (
                    speaker?.user?.name.toLowerCase().includes(lowercasedValue) 
                    || speaker?.user?.last_name.toLowerCase().includes(lowercasedValue)
                    || `${speaker?.user?.name} ${speaker?.user?.last_name}`.toLowerCase().includes(lowercasedValue)
                    || speaker?.user?.title.toLowerCase().includes(lowercasedValue)
                );
            });
            setAllSpeaker(updatedSpeaker);
        }
    }

    const filterAlphabetical = (_letter) => {
        const updateSpeakerSorting = speakerDetail?.speakers && speakerDetail?.speakers.filter((speaker) => {
            return speaker?.user?.name.charAt(0).toUpperCase() === _letter || speaker?.user?.last_name.charAt(0).toUpperCase() === _letter;
        });
        setAllSpeaker(updateSpeakerSorting);
    }

    const getTime = (isEventTimeZone, time) => {
        //const time = "03/29/2022 08:33:00"
        return getDateTime(isEventTimeZone, time).format("HH:mm")
    };

    const getDateTime = (isEventTimeZone, time) => {
        //const time = "03/29/2022 08:33:00"
        if (isEventTimeZone){
            const times = moment(time, Constants.SESSION_DATE_FORMATE);
            return moment(times);
        }else {
            const ltz = moment.tz.guess()
            const times = moment(time, Constants.SESSION_DATE_FORMATE);
            const formated = times.tz(event?.timezone ?? Constants.DEFAULT_TIMEZONE, true);
            // const formated = moment(times)
            return formated.tz(ltz)
        }
    };

    return {
        speakerSearch,
        filterAlphabetical,
        getSpeakerDetails: ({current_page, char, search}, callback) => {
            getSpeakerDetails({slug, current_page, char, search}, callback)
        },
        getZoneTime: getTime,
        getZoneDateTime: getDateTime,
        state : {
            speakerDetail:speakerDetail,
            allSpeaker:allSpeaker,
            char_pagination:speakerDetail?.char_pagination,
        }
    }
        
}





