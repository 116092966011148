import React, {useState} from 'react';

const DropDown = (props) => {
    const [selected, setSelected] = useState(props.selected)
    const onChange = (value) => {
        setSelected(value)
        if (props.onChange){
            props.onChange(value)
        }
    }

    return <select className={props.classAdd} value={selected} onChange={(e) => onChange(e.target.value)}>
        {props.items &&  Object.keys(props.items).length > 0 &&  Object.keys(props.items).map(function(key, index) {
            return  <option key={index} value={key}>{props.items[key]}</option>;
        })}
    </select>
}
export default DropDown;