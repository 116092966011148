import React, {useState, useContext, useEffect} from 'react';
import {Link, NavLink ,useLocation} from "react-router-dom";
import {FiBell} from "react-icons/fi";
import { MdLogin} from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import {FaAngleDown, FaAngleUp, FaQuestion} from "react-icons/fa";
import {Image} from '../../../../../UIComponets/Image';
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import { ShimmerCircularImage } from "react-shimmer-effects";
import useSlug from "../../../../../hooks/useSlug";
import {URLContants} from "../../../../../Model/URLContants";
import {changeLangauge, Context as languageContext} from "../../../../../context/LanguageContext";
import {ResponseWrapper} from "../../../../../Model/ResponseWrapper";
import {Error, UserDetails, UserDTO} from "../../../../../Util/Utils";
import {useToasts} from "react-toast-notifications";
import {getOptions, ToastType} from "../../../../../hooks/showToast";
import {Constants} from "../../../../../classes/Constants";



const WebHeaderRight = () => {
    const { addToast } = useToasts();
    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;
    const location = useLocation();
    const pathName = location.pathname.split('/');
    const lastsegment = pathName[pathName.length-1];
    const [isOpened, setIsOpened] = useState(false);
    const [isAngle, setAngle] = useState(false);
    const [styleMenu, setStyleMenu] = useState({display: 'none'});
    const [isHovering, setIsHovering] = useState({right: '-160px'});


    const {
        getUserSettings,
        state:{ 
           eventsPageData: {
               languages,
               options,
               user,
               user_details
            },
        }
     } = useContext(defaultContext);

    const {
        changeLangauge,
        state: { langauge },
    } = useContext(languageContext);

    const [networkSetting, setNetworkSetting] = useState(null)
    const [notificationSetting, setNotificationSetting] = useState(null)

    useEffect(() => {
        if (user_details){
            const userDetails = UserDetails(user_details)
            setNetworkSetting(userDetails.isNetworkingEnabled)
            setNotificationSetting(userDetails.isNotificationEnabled)
        }
    }, [user_details])

    const [currentLanguage, setCurrentLanguage] = useState(langauge);

    const handleChangeLangauge = (lang) => {
        setCurrentLanguage(lang);
        changeLangauge(lang);
    }

    const toggleUserInfo = () => {
        setAngle(!isAngle);
        let subMenu = document.getElementById("sub-menu-mobile");
        if (subMenu.style.display === "none") {
            subMenu.style.display = "block";
            
        } else {
            subMenu.style.display = "none";
        }
    }

    const handleMouseEnter = () => {
        setIsHovering({right: '0px'});
    };

    const handleMouseLeave = () => {
        let subMenuCheck = document.getElementById("sub-menu-mobile");
        if (subMenuCheck.style.display === "block") {
            subMenuCheck.style.display = "none";
            setAngle(!isAngle);
        }
        setIsHovering({right: '-160px'});
    };

    const handleUserSettingNot = (event) => {
       const {checked, name} = event.target;
        setNotificationSetting(checked)
        getUserSettings(slug.path, name, checked, (response, error) => {
            if (error){
                const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                addToast(Error(error).getResponseError(), {appearance, autoDismiss})
            }
        });
    }
    const handleUserSettingNet = (event) => {
        const {checked, name} = event.target;
        setNetworkSetting(checked)
        getUserSettings(slug.path, name, checked, (response, error) => {
            if (error){
                const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                addToast(Error(error).getResponseError(), {appearance, autoDismiss})
            }
        });
    }


    

    return (
        <>
            {/* start header right  */}
            <div className="header_right-box">
                <div className="user_section" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={isHovering}>
                    <div className="user_img">
                          {user ? (<Image src={user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'} alt="avatar"/>)
                          : (<ShimmerCircularImage size={70} />)}                   
                    </div>
                    <div className="user_info">
                        <h4>{user && user?.name} {user && user?.last_name}</h4>
                        <ul className="user_info_btn">
                            <li><a className="plist" data-toggle="modal" data-target="#logout"><MdLogin/></a></li>
                            <li><a className="plist"><FiBell/></a></li>
                            <li className="sub-menu" onClick={toggleUserInfo}>
                                <a className="plist">{isAngle ? <FaAngleUp className="right active"/> : <FaAngleDown className="right"/> }</a>
                                <ul className="submenu_innwe_down" id="sub-menu-mobile" style={styleMenu}>
                                    <li>
                                        <div className="user_languages">
                                        {languages && Object.keys(languages).length > 1 ? 
                                            <React.Fragment>
                                                <h4>Languages</h4>
                                                <ol>
                                                    {languages && languages ? Object.keys(languages).map((lang, index) => {
                                                        return (
                                                            <li className={lang === currentLanguage ? "active" : ""} key={index}>
                                                                <NavLink to={"#"} onClick={() => handleChangeLangauge(lang)}>{lang.toUpperCase()}</NavLink>
                                                            </li>
                                                        )
                                                    }) : null}
                                                </ol>
                                            </React.Fragment>
                                             : null }
                                        </div>
                                    </li>  
                                    <li>
                                    <div className="user_profile">
                                        <ol>
                                            <li key="profile" className={`${lastsegment === "profile" ? "active" : ""}`}>
                                                <Link to={Path(URLContants.PROFILE)}><AiOutlineUser/> 
                                                <span className='profile-menu'>Profile</span></Link>
                                            </li>
                                            {options?.includes('faq') ? (<li key="faq" className={`${lastsegment === "faq" ? "active" : ""}`}>
                                                <Link  to={Path(URLContants.FAQ)}><FaQuestion/>
                                                <span className='profile-menu'>FAQ</span></Link>
                                            </li>) : null}
                                            <li  key={Constants.NOTIFICATION_ENABLED}>
                                                <div className="switch_toggle">
                                                    <label className="switch">
                                                        <input 
                                                            type="checkbox"
                                                            name={Constants.NOTIFICATION_ENABLED}
                                                            defaultChecked={notificationSetting}
                                                            onClick={e => handleUserSettingNot(e)}
                                                         />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p>Notifications</p>
                                                </div>
                                            </li>
                                           {options?.includes(Constants.NETWORKING) && (<li key={Constants.NETWORKING_NOTIFICATION_ENABLED}>
                                                <div className="switch_toggle">
                                                    <label className="switch">
                                                        <input type="checkbox"
                                                               name={Constants.NETWORKING_NOTIFICATION_ENABLED}
                                                         defaultChecked={networkSetting}
                                                               onClick={e => handleUserSettingNet(e)}
                                                         />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p>Networking</p>
                                                </div>
                                            </li>)}
                                        </ol>
                                    </div>
                                    </li>
                                </ul>
                            </li>
                            <li></li>
                        </ul>
                    </div>
                    <div></div>
                </div>
            </div>
            {/* end header right */}
        </>
    )
}

export default WebHeaderRight;
