import React,{useState,useContext} from 'react';
import { Link } from 'react-router-dom';
import { FaRegFile } from "react-icons/fa";
import { FavoriteIcon, LinkIcon, EuroSymbol, WifiIcon } from '../../../../../../SVG/Icons';
import moment from 'moment';
import { Translations } from '../../../../../../hooks/shared/Translations';
import ReactHtmlParser from "react-html-parser";
import { Image } from '../../../../../../UIComponets/Image';
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import {Error} from "../../../../../../Util/Utils";
import {useToasts} from "react-toast-notifications";
import useSlug from '../../../../../../hooks/useSlug';
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';
import {SessionModel} from "../../../../../../Model/SessionModel";
import SessionFilesModel from '../../ActiveSessions/SessionFilesModel';
import { SessionApiV2 } from '../../../../../../hooks/shared/SessionApi2';
import { URLContants } from '../../../../../../Model/URLContants';

const LoadingContainer = (session_id, isLoading) => (prev) =>{
    return {
        ...prev,
        [session_id]: isLoading
    }
}

const UserSession = ({sessions, isActiveEu, timezone, onClick, favorite, usersSessionCat, usersSessionRegistered, event, setReplay}) => {

    const {translations} = Translations();

    const {
        getZoneTime
    } = SessionApiV2()

    const getTime = (time) => {
        return  getZoneTime(isActiveEu, time)
    };

    const map = usersSessionCat && usersSessionCat?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <h1 style={{background:current?.color}} className="mb-40">{current?.name.toUpperCase()}</h1>
        }
    }, {});

    const mapRegistered = usersSessionRegistered && usersSessionRegistered?.reduce((prev, current) => {
        return {
            ...prev,
            [current]: true
        }
    }, {});

    const [loadingContainer, setLoadingContainer] = useState({});
    const {
        enrollSession,
        unEnrollSession
    } = useContext(defaultContext);
    const { addToast } = useToasts();
    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;
    const enrollSessionRequest = (session_id) => {
        setLoadingContainer(LoadingContainer(session_id, true))
        enrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false))
        })
    }

    const unEnrollSessionRequest = (session_id) => {
        setLoadingContainer(LoadingContainer(session_id, true))
        unEnrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false));
        })
    }

    const isLoading = (id) => loadingContainer[id]

    
    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id);
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }


    return (
        <>
            {sessions && sessions.map(i => {
                const session = SessionModel(i);
                session.setRegistered(mapRegistered[i.id] ? true : false)
                return session
            }).map((session, index) => {
                return(
                    <div className="session_accordion_box mb-20" key={index}>
                        <div className="accordion destop_ed_time" id="accordionExample">
                            <div className="session_accordion_header">
                                <div className="date live">
                                    <p>
                                        {getTime(session?.start)}-{getTime(session?.end)}<br />
                                        { moment(`${session?.date}`).format("ddd DD MMM") }
                                    </p>
                                </div>
                                <div className="description extra collapse">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={`#collapseOne_${session?.id}`} aria-expanded="true" aria-controls="collapseOne">
                                        <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                                        {session?.title && (<h4>{session?.title}</h4>)} 
                                    </button>
                                    {session?.files?.length > 0 ? (<Link to={"#"} className="mr-2 file" onClick={()=>handleFilePopup(session?.id)}><FaRegFile/></Link>) : "" }
                                    <SessionFilesModel files={session?.files} id={session?.id} close={handleClose} event={event}/>
                                    {session?.meta?.external_link ? (<a href={session?.meta?.external_link} target={"_blank"}><LinkIcon/></a>) : "" }
                                </div>
                            </div>
                            <div id={`collapseOne_${session?.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="session_info_box">
                                    <div className="row align-items-center">
                                        <div className={`${session?.files?.length > 0  ? "" : "col-xl-12"  } col-md-8 col-xl-8`}>
                                            <div className="emerging_sectors mt-20">
                                                <div className="row">
                                                {session?.people?.length > 0 ? ( <>
                                                {session?.people && session?.people.map((person , index) => {
                                                    return (
                                                        <div className="col-md-6 col-lg-4 col-xl-4">
                                                            <div className="client_info mb-30" key={index}>
                                                                <div className="client_info_img">
                                                                    <Image src={person?.user?.avtar} placeholderSrc={"/images/accpted_img1.png"} alt={"people pic"}/>
                                                                </div>
                                                                <div className="client_info_content">
                                                                    <h4>
                                                                        <Link to={Path(URLContants.SPEAKERS)+`?search=${person?.user?.last_name}`}> {person?.user?.name} {person?.user?.last_name}</Link>
                                                                    </h4>
                                                                    <p>{person?.user?.title}</p>
                                                                    <h6>{person?.user?.company}</h6>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        )
                                                    })}
                                                </>) : null }
                                                </div>
                                                {map[session?.category_id]}
                                                <p>{ReactHtmlParser(session?.detail ?? '')}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4"> 
                                        {session?.files?.length > 0 ? (
                                            <div className="session_info_file">
                                                    <h2>{translations?.general?.files}</h2>
                                                        <ul className="list-group">
                                                        {session.files && session.files.map((file, index)=>{
                                                        return(
                                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="file_name">{file?.filename}</span>
                                                                <a href={file && imageWithStorage+file?.path} target="_blank" rel="noopener noreferrer" download>
                                                                    <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                                    <g id="Group_1947" data-name="Group 1947">
                                                                    <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                                    <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                                    </g>
                                                                    <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                                    </g>
                                                                    </svg>
                                                                </a>
                                                        </li>
                                                        )
                                                        })}
                                                </ul>
                                            </div>) 
                                            : null} 
                                            {session?.files?.length > 1 && (
                                                <a href={`${process.env.REACT_APP_API_HOST}/v2.0/zip-download/${event?.id}?module_id=${session?.id}&type=timetable`} target="_blank" className="text-dark">
                                                    <span className="mr-2">
                                                        <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                        <g id="Group_1947" data-name="Group 1947">
                                                        <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                        <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                        </g>
                                                        <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                        </g>
                                                        </svg>
                                                    </span>
                                                    <span> Download All Files </span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

        </>
    )
}

export default UserSession;
