import React, {useRef, useState} from "react";
import {VideoCallApi} from "../../../../../../hooks/videoCallApi";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import {SendIcon} from "../../../../../../SVG/Icons";
import {ChatMessage, Message} from "../../Live/ChatTab";
import {Translations} from "../../../../../../hooks/shared/Translations";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {UserApi} from "../../../../../../hooks/shared/UserApi";
import InputEmoji from 'react-input-emoji';

export const ActiveCallChat = () => {
    const {
        sendVideoChatMessage,
        videoChatMessages,
    } = VideoCallApi()

    const {
        translations
    } = Translations()

    const {
        user
    } = UserApi()

    const [searchQuery, setSearchQuery] = useState('');
    const [isSending, setIsSending] = useState(false)
    const chatContainer = useRef();
    const messageFieldRef = useRef();
    const [message, setMessage] = useState('')
    const sendChatMessage = () => {
        setIsSending(true)
        sendVideoChatMessage({msg: message}, (response, error) => {
            setIsSending(false)
            setIsSending(false)
            if (response){
                const wrapper = ResponseWrapper(response)
                if (wrapper.getStatus()){
                    setMessage('')
                }
                wrapper.getMessage()
            }

            scrollToMyRef()
            setTimeout(() => {
                messageFieldRef.current.focus();
            }, 200)
        })
    }
    const scrollToMyRef = () => {
        const scroll =
            chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
        chatContainer.current.scrollTo(0, scroll);
    };
    const onSendMessageEnter = (e) => {
        if (e.key === "Enter" && message?.length > 0) {
            sendChatMessage()
        }
    }

    return <div className="tab-pane active" id="CHAT" role="tabpanel">
        <div className="br_chat_inner">
            <div className="lr_chat_box">
                <div className="searchbar_right">
                    <input type="text" placeholder="Search.." name="search" onChange={(e) => setSearchQuery(e.target.value)} className="form-control"/>
                    <button type="submit">
                        <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                        </svg>
                        {/* <!-- <SearchIcon /> -->  */}
                    </button>
                </div>
                <ul className="one_chat_box" ref={chatContainer}>
                    {videoChatMessages?.filter(m => {
                        const seachedText = searchQuery?.trim().toLowerCase()
                        if (seachedText.length > 0){
                            return m.message?.toLowerCase().includes(seachedText)
                        }else {
                            return true;
                        }
                    }).map((m, index) => ChatMessage({isSender:m.sender_id === user.id, message:Message(m), key: index}))}
                </ul>
                <div className="send_input_box live_send_input_box">
                    {/* <input 
                        type="text" 
                        value={message} 
                        className="form-control"
                        id="send" 
                        placeholder={translations?.chat?.answer_placeholder} disabled={isSending}
                        onChange={e => setMessage(e.target.value)}
                        ref={messageFieldRef}
                        onKeyDown={onSendMessageEnter}
                    /> */}
                    <InputEmoji 
                        type="text" 
                        className="form-control"
                        value={message} 
                        id="send" 
                        placeholder={translations?.chat?.answer_placeholder} disabled={isSending}
                        onChange={setMessage}
                        ref={messageFieldRef}
                        cleanOnEnter
                        onKeyDown={onSendMessageEnter}
                        onEnter={onSendMessageEnter}
                    />
                    <IconLoader isLoading={isSending}>
                        <SendIcon onClick={sendChatMessage}/>
                    </IconLoader>
                </div>
            </div>
        </div>
    </div>
}