import React, {useContext, useRef} from 'react';
import { Link } from 'react-router-dom';
import useSlug from '../../../../../../hooks/useSlug';
import { Image } from '../../../../../../UIComponets/Image';
import { FaHandsHelping } from "react-icons/fa";
import {URLContants} from "../../../../../../Model/URLContants";
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
import { useTemplate } from '../../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../../Model/TemplateDetails";
import Network from '../../../../../OnePage/Components/network/Network';

const SponsorsUsers = ({peoples, sponsorDetails, participantDetailsCallback}) => {
    
    const slug = useSlug().absolutePath;
    const {
        state: {
            onlineUsers
        }
    } = useContext(defaultContext);

    const isOnline = (participantId) => onlineUsers && onlineUsers?.membersMap && onlineUsers?.membersMap[participantId] ? true : false;


    const {
        state: {
            layout
        }
    } = useTemplate();

    const detaileTemplate = TemplateDetails(layout)
    const isOnePageTemplate = detaileTemplate.getTemplate() === Template.ONEPAGE;

    return (
        <>
            {peoples?.length > 0 && (<div className="sd_attendess_area mt-40">
                <h1 className="main_heading mb-20">ATTENDEES FROM THIS SPONSOR</h1>
                <div className="row">
                    {peoples.map((people, index) => {
                        return(
                            <div className="col-md-6 col-lg-6 col-xl-4" key={index}>
                                <div className="sd_attendess_box main-card">

                                    {isOnline(people?.user?.id) ? <span className="dot"></span> : null}
                                    <h2>{people?.user?.name} {people?.user?.last_name}</h2>
                                    <div className="attendess_profile_box">
                                        <div className="img_box">
                                            <Image src={people?.user?.avtar} placeholderSrc={'/images/placeholder_square.jpg'}  alt="sponsor pic" />
                                        </div>
                                        {people?.info && people?.info?.length > 0 ? (
                                            <div className="social_btn">
                                                <a href={people?.info?.linkedin_link}  target={"_blank"}>
                                                    <button type="button" className="btn"><img alt="image" src="/images/indeed_grey.png"/></button>
                                                </a>
                                                <a href={people?.info?.twitter_link}  target={"_blank"}>
                                                    <button type="button" className="btn"><img alt="image" src="/images/twitter_grey.png"/></button>
                                                </a>
                                                <a href={people?.info?.facebook_link}  target={"_blank"}>
                                                    <button type="button" className="btn"><img alt="image" src="/images/facebook_grey.png"/></button>
                                                </a>
                                            </div>
                                        ) : ""}
                                    </div>
                                    <div className="attendess_info">
                                        <p>{people?.info?.company}</p>
                                        <h6>{sponsorDetails?.name}</h6>
                                        {isOnePageTemplate ? 
                                            (<button type="button" className="btn btn_border_orange mt-20" data-toggle="modal" data-target="#speaker-details" onClick={() => participantDetailsCallback(people)}>
                                                <FaHandsHelping/> CONNECT
                                            </button>) : 
                                            (<Link to={slug +`${URLContants.NETWORK}/`+people?.user?.id}>
                                                <button type="button" className="btn btn_border_orange mt-20">
                                                    <FaHandsHelping/> CONNECT
                                                </button>
                                            </Link>)}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>)}
        </>
    )
}

export default SponsorsUsers
