import React, {useContext, useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import { Link } from 'react-router-dom';
import { URLContants } from '../../../../../Model/URLContants';
import useSlug from '../../../../../hooks/useSlug';
import SponsorsLogo from './SpComponents/SponsorsLogo';
import MoreInfo from './SpComponents/MoreInfo';
import Gallery from './SpComponents/Gallery';
import SponsorsUsers from './SpComponents/SponsorsUsers';
import SponsorsContent from './SpComponents/SponsorsContent';
import SponsorsUserMobile from './SpComponents/SponsorsUserMobile';
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import { 
    ShimmerThumbnail,
    ShimmerSocialPost,
    ShimmerSimpleGallery,
    ShimmerTitle
} from "react-shimmer-effects";
import {Translations} from "../../../../../hooks/shared/Translations";
import { useTemplate } from '../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";
import ReactHtmlParser from "react-html-parser";


const SponsorsDetails =  forwardRef((props, ref) => {
        useImperativeHandle(ref, () => ({
            fetchDetails(details, callback) {
                const _url  = `${slug}/sponsors/${details?.id}`;
                setIsLoading(true);
                getSponserDetails(_url, (res, error) => {
                    setIsLoading(false);
                });
            }
        }));

   const { match } = props;
   const vodId = match?.params?.slug ?? "";
   const slug = useSlug().absolutePath;
   const url  = `${slug}/sponsors/${vodId}`;

   const {
      translations
   } = Translations()

   const {
      getSponserDetails,
      state:{ 
         eventsAllPagesData: {peoples , sponsor},
      }
   } = useContext(defaultContext);


   //React state
   const [isLoading, setIsLoading] = useState(false);
   const [sponsorDetails, setSponsorDetails] = useState(sponsor);
   const [people, setPeoples] = useState(peoples);
   const [peopleDetail, setPeopleDetail] = useState(null);

   useEffect(()=> {
       if (match?.params?.slug){
           setIsLoading(true);
           getSponserDetails(url, (res, error) => {
               setIsLoading(false);
           });
       }
   }, [])

   useEffect(() => {
      setSponsorDetails(sponsor);
   }, [sponsor])

   useEffect(() => {
      setPeoples(peoples);
   }, [peoples])

   const participantDetailsCallback = (data) => {
         setPeopleDetail({...data?.user,info:data.info});
   }


   const {
        state: {
            layout
        }
    } = useTemplate();

    const detaileTemplate = TemplateDetails(layout)
    const isOnePageTemplate = detaileTemplate.getTemplate() === Template.ONEPAGE



   return (
      <>
         <div className="sponsors_details_area">
            <div className="view_all_btn overview_btn">
               <Link to={slug+URLContants.SPONSORS}>
                  <button className="btn">
                     <span> 
                        <svg className="svg-inline--fa fa-angle-right fa-w-8" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                           <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                        </svg>
                     </span> Back to overview
                  </button>
               </Link>
            </div>
            <div className={isOnePageTemplate ? "container-fluid" : "container"}>
               <div className={`sponsors_details_inner ${isOnePageTemplate ? "mt-50" : "mt-50"}`}>
                   <div className="row">
                        {/* First Row sponsors */}
                           <div className="col-md-12 col-lg-4 col-xl-3">
                              {isOnePageTemplate ? (<h1 className="mobile-main-heading-two">
                                <Link to={slug}><span><i className="fa fa-angle-left"></i></span>{sponsorDetails?.name}</Link>
                              </h1>) : (<h1 className="mobile-main-heading-two">
                                <Link to={slug+'/sponsors'}><span><i className="fa fa-angle-left"></i></span>{sponsorDetails?.name}</Link>
                              </h1>)}
                              {/* Sponsors Logo and social link here */}
                              {!isLoading ? (<SponsorsLogo sponsorDetails={sponsorDetails}/>) : (<ShimmerThumbnail height={250} rounded />) }
                              {/* sponsor content details mobile view */}
                              {!isLoading ? (
                              <div class="sponsors_details_description  mobile_view">
                                 <p>{ReactHtmlParser(sponsorDetails?.meta?.description ?? '')}</p>
                              </div>
                           ) : (<ShimmerSocialPost type="text" />)}
                              {/* Gallery */}
                              {!isLoading ? (<Gallery sponsorDetails={sponsorDetails} translations={translations}/>) : (<ShimmerSimpleGallery card row={2} col={2} imageHeight={100}  gap={10}/>)}
                              {/* More details here  */}
                              {!isLoading ? (<MoreInfo sponsorDetails={sponsorDetails} translations={translations}/>) : (<ShimmerTitle card line={5} gap={10}/>)}
                           </div>
                        {/* End row */}

                        {/* Second Row sponsors */}
                       <div className="col-md-12 col-lg-8 col-xl-9">
                           {/* sponsor content details destop_view */}
                           <div className="sponsors_details_description main-card destop_view">
                              {!isLoading ? (<SponsorsContent 
                              sponsorDetails={sponsorDetails}
                              translations={translations}
                              />) : (<ShimmerSocialPost type="text" />) } 
                           </div>
                           {/*  Mobile view sponsors */}
                           {people && people?.length > 0 ? (
                              !isLoading ? (<SponsorsUserMobile sponsorDetails={sponsorDetails} peoples={people}/>) :<ShimmerSimpleGallery imageType="thumbnail" row={1} imageHeight={100} card caption />
                           ) : "" }
                           {/* Attendees from this sponsor */}
                           {people && people?.length > 0 ? (
                              !isLoading ? (<SponsorsUsers sponsorDetails={sponsorDetails} peoples={people} participantDetailsCallback={participantDetailsCallback}/>) :<ShimmerSimpleGallery imageType="thumbnail" row={1} imageHeight={200} card caption />
                            ) : "" }
                      </div>
                       {/* End row */}
                   </div>
               </div>
            </div>             
         </div>
      </>
   )
})

export default SponsorsDetails;
