export  enum NetworkUIState {
    CHAT = "CHAT_WINDOW",
    MEETING = "MEETING",
    DICE = "DICE",
    PEOPLE = "PEOPLE",
    PARTICIPANT_DETAILS = "PARTICIPANT_DETAILS",
    ACTIVE_CALL = "ACTIVE_CALL",
    USER_NOTFOUND = "USER_NOTFOUND"
}

export const NetworkUIStateAll = [NetworkUIState.CHAT,
    NetworkUIState.MEETING,
    NetworkUIState.DICE,
    NetworkUIState.PEOPLE,
    NetworkUIState.PARTICIPANT_DETAILS]