import React,{useState} from 'react'
import SpeakersSession from './SpeakersSession';
import { Image } from '../../../../../UIComponets/Image';
import UpDownScroll from './UpDownScroll';


const SpeakersInfo = ({speakers, isActiveEu}) => {
    const [active, setActive] = useState({id:""});
    const sessionScroll  = (content_id) => {
        let divElement = document.getElementById("speaker_event_box_"+content_id);
        setActive(prevState => ({ id: prevState.id === content_id ? '': content_id }));
        if(divElement.scrollTop > 0){
        divElement.scroll({
            top: 0,
            behavior: 'smooth'
        });
        }else{
        divElement.scroll({
            top: divElement.scrollHeight,
            behavior: 'smooth'
        });
        }
    }
    if (speakers?.length === 0){
        return <div className='col text-center'><span>No data available.</span></div>
    }
    return (
        <>
        {speakers && speakers.map((speaker, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className="speaker_multi_box">
                        <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-6">
                                <div className="speaker_details_box">
                                    <div className="img_box">
                                        <Image src={speaker?.user?.avtar} className='rounded' placeholderSrc={'/images/placeholder_square.jpg'} alt={'speaker pic'}/>
                                    </div>
                                    <div id="module" className="content_box">
                                        <h4>{speaker?.user?.name} {speaker?.user?.last_name}</h4>
                                        {speaker?.user?.title && (<h5>{speaker?.user?.title}</h5>)}
                                        {speaker?.user?.company && (<h6>{speaker?.user?.company}</h6>)}
                                       {speaker?.info?.description ? (<div className='speaker_description'>
                                            <p className="collapse" id={`collapseExample_${index}`} aria-expanded="false">{speaker?.info?.description}</p>
                                            <a role="button" className="collapsed speaker_desktop_icon" data-toggle="collapse" href={`#collapseExample_${index}`} aria-expanded="false" aria-controls="collapseExample"></a>
                                            <a role="button" className="collapsed speaker_mobile_icon" data-toggle="collapse" href={`#collapseExample_${index}`} aria-expanded="false" aria-controls="collapseExample"><i className="fa fa-angle-down" aria-hidden="true"></i></a>
                                            </div>
                                       ) : "" } 
                                    </div>
                                </div>
                            </div>
                            {speaker?.sessions?.length > 0 ? ( <div className="col-md-12 col-lg-12 col-xl-6">
                                <div className="speaker_event_box" id={`speaker_event_box_${speaker?.user?.id}`}>
                                   <h1 className="main_heading">Sessions</h1>
                                    <SpeakersSession sessions={speaker?.sessions} isActiveEu={isActiveEu}/>
                                </div>
                                {speaker?.sessions?.length > 3 ?
                                    (<div className='speaker_scroll_icon text-center pt-2 '>
                                        <UpDownScroll 
                                            id={speaker?.user?.id}
                                            onClick={() => sessionScroll(speaker?.user?.id)}
                                            isActive={active?.id === speaker?.user?.id}
                                        />
                                    </div>)
                                : ""}
                            </div> ) : null } 
                        </div>
                        </div>
                    </div>
                    </React.Fragment>
                    )
            })}
        </>
    )
}

export default SpeakersInfo;


