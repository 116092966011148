import React,{useState, useContext, useEffect, useRef} from 'react';
import { Link, useHistory, useLocation} from 'react-router-dom';
import useSlug from '../../../../../hooks/useSlug';
import {RouteWrapper} from "../../../../Event/Routes";
import {AZPagination} from "../../../../../UIComponets/AZPaggination";
import SpeakersInfo from './SpeakersInfo';
import { ShimmerContentBlock } from "react-shimmer-effects";
import {SpeakerApi} from "../../../../../hooks/shared/SpeakerApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { IconLoader } from '../../../../../UIComponets/IconLoader';
import { SearchIcon } from '../../../../../SVG/Icons';
import { Translations } from '../../../../../hooks/shared/Translations';
import { EventApi } from '../../../../../hooks/shared/EventApi';
import { URLContants } from '../../../../../Model/URLContants';
import { AppSettingOptions } from '../../../../../hooks/shared/AppSettings';
import {useTemplate} from "../../../../../hooks/shared/useTemplate";
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";

export const SpeakersWrapper = () => {
    const {isSpeakersEnable}  = AppSettingOptions()
    return <>
        {isSpeakersEnable ? (<Speaker/>) : null}
    </>
}

const Speaker = () => {
    const slug = useSlug();
    const Path = RouteWrapper(slug.absolutePath);
    let url = Path(URLContants.SPEAKERS);
    const location = useLocation();
    const history = useHistory();
    const searchRef = useRef(null);
    const serachResult = new URLSearchParams(window.location.search); 
    const searchSpeker = serachResult.get('search');
    const [inputTimeout, setInputTimeout] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isActiveEu, setisActiveEu] = useState(false);
    const [currentChar, setCurrentChar] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState(searchSpeker ? searchSpeker : '');

    const {
        getZoneTime,
        getZoneDateTime,
        speakerSearch,
        filterAlphabetical,
        getSpeakerDetails,
        state: {
            speakerDetail,
            allSpeaker,
            char_pagination
        }
    } = SpeakerApi();

    const {
        state:{
            event
        }
    } = EventApi();

    const {
        translations
    } = Translations();

    const {
        state: {
            layout
        }
    } = useTemplate();

  const templateDetails = TemplateDetails(layout)
  const isOnePageTemplate = templateDetails.getTemplate() === Template.ONEPAGE;

    useEffect(() => {
        if(searchQuery){
            let querys = searchQuery.toLowerCase().trim();
            fetchMoreSpeaker(1, currentChar, querys);
        }else{
            fetchMoreSpeaker(currentPage, currentChar, "");
        }
    },[searchQuery])

    useEffect(() => {
        if (speakerDetail?.pagination?.current_page) {
            setCurrentPage(speakerDetail?.pagination?.current_page)
        }
    }, [speakerDetail?.pagination?.current_page]);

    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout])

    const setEventTimeZone = (status) => {
        setisActiveEu(status)
        //setActiveEu(status)
    }

    const fetchMoreSpeaker = (currentPage, currentChar, searchQuery) => {
        setIsLoading(true);
        setTimeout(() => {
            getSpeakerDetails({current_page: currentPage, char: currentChar, search:searchQuery}, (res, error) => {
               setIsLoading(false);
            });
          }, 1000);
    }

    const FilterByLetters = (letter) => {
        let _letter = (letter === "All" ? null : letter);
        searchRef.current.value = "";
        setCurrentChar(_letter)
        setSearchQuery('');
        if (_letter !== currentChar && _letter !==""){
            history.push(`${url}?search=${letter}`);
            setCurrentPage(1);
            fetchMoreSpeaker(1, _letter, "");
        }else{
            fetchMoreSpeaker(1, _letter, "");
            history.push(url);
        }
    }
    
    const filterSpeakerSearch = (query) => {
        if(!isOnePageTemplate){
            history.push(url);
        }
        let lowercasedValue = query.toLowerCase().trim();
        setCurrentChar(null);
        setSearchQuery(lowercasedValue)
        if (inputTimeout) clearTimeout(inputTimeout)
        setInputTimeout(
            setTimeout(() => {
                if(lowercasedValue === ""){
                    fetchMoreSpeaker(1, currentChar, lowercasedValue);
                }else{
                    fetchMoreSpeaker(1, currentChar, lowercasedValue);
                }
            }, 1000)
        )
    }

    const hasMore = speakerDetail?.pagination?.current_page < speakerDetail?.pagination?.last_page || (allSpeaker === undefined);
    const Shimer = <div className='container mb-40'><ShimmerContentBlock title cta text thumbnailWidth={280} thumbnailHeight={280}/></div>

    return (
        <>
            <section className="speaker_page_area" id='speakers'>
              <div className="container">
                    <div className="session_header_inner mb-50">
                        <ul className="nav all-sessions_tab">
                            <li className={isActiveEu ? "active" : ""} onClick={() => {setEventTimeZone(true)}}>
                                <Link to={"#"}>{event?.shortTimezone} {translations?.general?.time}</Link>
                            </li>
                            <li className={isActiveEu ? "" : "active"} onClick={() => {setEventTimeZone(false)}}>
                                <Link to={"#"}>{translations?.general?.device_time}</Link>
                            </li>
                        </ul>
                    </div>
                    <h1 className="mobile-main-heading-two"><span><i className="fa fa-angle-right"></i></span>SPEAKERS</h1>
                    <h1 className="main_heading mb-20">SPEAKERS</h1>
                    <div className="speaker_page_header mb-60">
                        <div className='row spekaer_mobile_inner_time mb-20'>
                            <div className='col participant_session'>
                                <ul className="nav eu_time_tab">
                                    <li className={isActiveEu ? "active" : ""} onClick={() => setEventTimeZone(true)}>
                                        <Link to={'#'}>
                                            {event?.shortTimezone} {translations?.general?.time}
                                        </Link>
                                    </li>
                                    <li className={isActiveEu ? "" : "active"} onClick={() => setEventTimeZone(false)}>
                                        <Link to={'#'}>
                                            {translations?.general?.device_time}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 col-xl-6">
                                <div className="sponsors_searchbar_pagination">
                                    <AZPagination onClick={(char) => FilterByLetters(char)} currentChar={currentChar} pagination={char_pagination}/>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                                <div className="searchbar_right">
                                    <input type="text" ref={searchRef} placeholder="Search.." name="search" className="form-control" value={searchSpeker} onChange={(e)=>filterSpeakerSearch(e?.target?.value)}/>
                                    <button type="submit"><IconLoader isLoading={isLoading}><SearchIcon/></IconLoader></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="speaker_page_inner">
                    <InfiniteScroll
                        dataLength={allSpeaker?.length ?? 0}
                        next={() => fetchMoreSpeaker(currentPage + 1, currentChar, searchQuery)}
                        hasMore={hasMore && searchQuery?.length === 0}
                        loader={Shimer}
                        endMessage={<></>}
                    >
                    {allSpeaker?.length > 0 && <SpeakersInfo speakers={allSpeaker} isActiveEu={isActiveEu}/>}
                    </InfiniteScroll> 
                </div>
          </section>  
        </>
    )
}

export default Speaker;


  