import React, { useState, useRef, useEffect } from 'react'
import { HashLink } from "react-router-hash-link";
import {useLocation} from "react-router-dom";
import { Image } from '../../../../UIComponets/Image';
import { EventApi } from '../../../../hooks/shared/EventApi';
import useSlug from '../../../../hooks/useSlug';
import $ from 'jquery';

const TopMenuBar = () => {
    const slug = useSlug().path;
    const menuRef = useRef();
    let location = useLocation();
    const {
        state:{
            icons,
            details,
            options,
        }
    } = EventApi();

   useEffect(() => {
      if(menuRef.current){
         $(menuRef.current).next("ul").hide();
      }
   },[menuRef.current])

   const handleMenuCollapse = () => {
        if(menuRef.current){
            $(menuRef.current).next("ul").slideToggle("100"); 
            $(menuRef.current).find(".right").toggleClass("fa-angle-up fa-angle-down");
        }
   }

  return (
    <>
        <div className="v_menu_top_bar" style={{top:details?.show_header === "hidden" ? "0" : "100px"}}>
            <div className='v_top-menu-arrow' onClick={handleMenuCollapse} ref={menuRef}>
                <span><a className="menu_angle"><span><i className="fa fa-angle-down right"></i></span></a></span>
            </div>
            <ul className="nav v_navbar_top_menu">
                {icons?.length > 0 ? icons && icons?.map((menu, index) => {
                    const showMenu = options?.includes(menu?.icon_type_name) || menu?.icon_type_name === "home" || menu?.icon_type_name === "live";
                    let iconType = menu?.icon_type_name?.replace(/_/g, '-');
                    return(
                        <>
                            <li key={index} id={"webbar-" + iconType} className={`${showMenu ? "" : "d-none"} ${location.hash === "#"+iconType ? "active" : ""}`} >
                                <HashLink smooth to={'#'+iconType} className="nav-link">
                                    {menu?.is_fa === 1 ?
                                    (<span className="svg_path"> <i className={menu?.fa_class}></i></span>) 
                                    : <Image src={menu?.image_url} placeholderSrc={""} alt="Menu Image"/> } 
                                    <p>{menu?.front_name}</p>
                                </HashLink>
                            </li>
                        </>
                    )
                })
                : null }
            </ul>
         </div>
    </>
  )
}

export default TopMenuBar