import React,{useContext, useEffect, useState} from 'react';
import { Link, useHistory} from 'react-router-dom';
import { useToasts } from "react-toast-notifications";
import { Context as AuthContext } from "../../../../context/AuthContext";
import { Context as languageContext } from "../../../../context/LanguageContext";
import {imageBaseUrl,imageWithStorage,} from "../../../../constants/url/urlConstants";
import { Button,Form } from 'react-bootstrap';
import DropDown from "../../../../UIComponets/DropDown/DropDown";
import useSlug from "../../../../hooks/useSlug";
import { Image } from '../../../../UIComponets/Image';
import {IconLoader} from "../../../../UIComponets/IconLoader";
import {Error} from "../../../../Util/Utils";
import {SupportScript} from "../../../../utils/SupportScript";
import FaqModal from '../Pages/Faq/FaqModal';
import useBodyClass from "../../../../hooks/BodyClass";
import placeholderBody from "../../../../assets/images/preview_back.png";
import { FAQIcon } from '../../../../SVG/Icons';
import { FaEye, FaEyeSlash, FaCheck, FaTimes } from "react-icons/fa";


const Login = ({security}) => {

   const history = useHistory();
   const slug = useSlug();

   const [currentLanguage, setCurrentLanguage] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState(null);
   const [rememberMe, setRememberMe] = useState(false);
   const { addToast } = useToasts();
   const [globalLan, setGlobalLan] = useState(null);
   const isLoginPage = history.location.pathname.split("/").includes("login");
   const [user, setUser] = useState({email: "",password: "",});
   const [showPassword, setShowPassword] = useState(false);
   const [errorEmail, setErrorEmail] = useState("");
   const [emailVerify, setEmailVerify] = useState("");
   const [errorCheck, setErrorCheck] = useState(null);


   useBodyClass("default-active");
   //store auth context 
   const { 
      onLogin,
      defaultAuthenticationWhileLogin,
      checkEmail,
      state: {
         loginPageData: {
              event,
              details,
              options,
              languages,
              translations
          },
      }
  } = useContext(AuthContext);

  const {changeLangauge,state: { langauge },} = useContext(languageContext);

  //life cycles hooks using app
  useEffect(() => {
    const bodyClass = document.querySelector(".default-active");
    let url;
    if(details){
      if (bodyClass) {
        if (details?.event_background_type === "image") {
          if (details?.event_background) {
            url = `url('${imageWithStorage + details?.event_background}') center center no-repeat`;
          } else {
            url = `url('${placeholderBody}') center center no-repeat`;
          }
          bodyClass.setAttribute("style", `background:${url}`);
        } else if (details?.event_background_type === "color") {
          bodyClass.setAttribute("style", `background:${details?.event_background}`);
        } else if (details?.event_background_type === "none") {
          bodyClass.setAttribute("style", "background :none");
        }
      }
    }
  }, [details, details?.background, details?.background_type]);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      if (details?.img_favicon) {
        favicon.href = imageWithStorage + details?.img_favicon;
      } else if (details?.img_logo_desktop) {
        favicon.href = imageWithStorage + details?.img_logo_desktop;
      }
    }
  }, [details?.img_logo_desktop]);

  useEffect(() => {
    setCurrentLanguage(langauge);
  }, [langauge]);

  useEffect(() => {
    if(event){
      document.title = `${event?.title} Login`;
    }
  }, [event]);

  useEffect(() => {
   setIsLoading(true);
   const onLogin = async () => {
       try {
           await defaultAuthenticationWhileLogin((slug.path), (response:any) => {
           setIsLoading(false);
       });
       } catch (error) {
           setIsLoading(false);
       }
       setIsLoading(false);
   };
   onLogin();
}, [security]);

const onGlobalLangChange = (lan:any) => {
   changeLangauge(lan);
   setGlobalLan(lan);
};


  // ******************* */ Function Declaration  *******************
  const {email,password} = user;
  const inputChangeHandler = (e:any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const isEmail = (val:any) => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!regEmail.test(val)){
      return false;
    }else{
      return true;
    }
}

  const handlercheckEmail = (e:any) => {
      setUser({ ...user, [e.target.name]: e.target.value });
      if(isEmail(email)){
          setIsLoading(true);
          const interval = setTimeout(async () => {
            if(email){
                await checkEmail(slug.path,{email: e.target.value}, (res:any, err: Error) => {
                  if(res?.status == 200){
                    setIsLoading(false);
                    if(res.data.data.status){
                      setErrorCheck(null)
                      setErrorEmail('valid');
                      setEmailVerify("isLogin");
                    }else{
                      setErrorEmail('invalid');
                      setEmailVerify("isRegistart");
                      setErrorCheck(res.data.data.message)
                    }
                  }
              }); 
            }
        }, 2000);
        return () => {clearInterval(interval)};
      }else{
        setIsLoading(false);
        setErrorCheck(null)
        setEmailVerify("");
        setErrorEmail("");
      }
  };

// register error messages
const currentPageTranslation = translations?.registration;
const emailPlaceholder = currentPageTranslation?.email_placeholder;
const passwordPlaceholder = currentPageTranslation?.password_placeholder;
const uniqueCodePlaceholder = currentPageTranslation?.unique_code_placeholder;

const LanguageMenu =
    languages && Object.keys(languages).length > 1 > 0 ? (
      <div className="db-language-wrap ml-auto">
        <div className="form-goup">
          <DropDown
            classAdd={"custom-select"}
            items={languages}
            selected={langauge}
            onChange={(data:any) => onGlobalLangChange(data)}
          />
        </div>
      </div>
    ) : (
      ""
    );

    const login = async (payload:any, type:any) => {
       setIsLoading(true)
      await onLogin(slug.absolutePath, { ...payload }, type, (response:any, error: Error) => {
          setIsLoading(false)
          if(response){
              if (response.status === "success") {
                  if (type === "password"){
                      const pass = JSON.parse(localStorage.getItem('defaultObject'));
                      if (!(pass?.event?.slug === slug.event)){
                        localStorage.setItem(slug.absolutePath,JSON.stringify({event: slug.absolutePath}));
                      }
                  }
                  if(response.message != ""){
                    addToast(response.message, {appearance: "success",autoDismiss: true});
                  }
              } else {
                  addToast(response.message, { appearance: "error",autoDismiss: true,});
                  setError(response.message);
              }
            }else if (error){
                addToast(Error(error).getResponseError(), {appearance: "error",autoDismiss: true,});
            }
          }
      );
    };

   const onUserLogin = async (e:any) => {
      e.preventDefault();
      let data = {
        email: user.email,
        password: user.password,
      };
      login(data, "login");
    };

    const onUserLoginViaPassword = (e:any) => {
          e.preventDefault();
      let data = { password: user.password};
      let url;
      // let channelUrl = sessionStorage.getItem("channelSelected");
      // const _channel = JSON.parse(channelUrl);
      // if (channelUrl) {
      //   url = "api/" + _channel.url;
      // } else {
      //   url = `api/${clientName}/${eventName}`;
      // }
      //const urlEvent = `${url}/check-event-password`;
      if(user.password === ""){
        addToast("Please enter password", {appearance: "error",autoDismiss: true});
        return;
      }
      login(data, "password");
      // if (isLoginPage) {
      //   loginViaURL(urlEvent, data, "password");
      // } else {
      // }
    };

  
     //if (isLoading) return <LoaderDefaultSection />;
     let LoginDetail;
     let bgStyle;
     let txtColor;
     let borderColor;
      if (event?.security === "password" || security === "password") {
        bgStyle = { backgroundColor: event?.color };
        txtColor = { color: event?.color };
        borderColor = { borderColor: event?.color };
        LoginDetail = (
          <React.Fragment>
            {error && (
              <div className="bg-danger text-white py-2 px-4 m-3">
                <i className="fa fa-times" aria-hidden="true"></i>&nbsp;
                {error}
              </div>
            )}
            <div className="login_password">
                  <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                  <Form.Control 
                    type={showPassword ? "text" : "password"}
                    id="password" 
                    placeholder={passwordPlaceholder}
                    name="password"
                    autoFocus={false}
                    value={password}
                    onChange={e => inputChangeHandler(e)}
                  />
                  <div onClick={() => setShowPassword(!showPassword)} className="input-group-append">
                      {showPassword ? (<i style={{ color: details?.primary_color }}><FaEye/></i>)
                      : (<i style={{ color: details?.primary_color }}><FaEyeSlash/></i>)}
                  </div>
            </div>
            <div className="login_btn mt-50">
                <Button  
                type="button"
                onClick={onUserLoginViaPassword}
                disabled={isLoading} 
                className={`btn btn_border_orange`}
                ><IconLoader isLoading={isLoading}>Login</IconLoader></Button>
            </div>
          </React.Fragment>
        );
      } 
      else if (event?.security === "registration" || security === "registration" || event?.security === "public") 
      {
        LoginDetail = (
          <React.Fragment>
                {error && (
                  <div className="bg-danger text-white py-2 px-4 m-3">
                    <i className="fa fa-times" aria-hidden="true"></i>&nbsp;
                    {error}
                  </div>
                )}
                <Form.Control
                  id="email" 
                  type="email" 
                  placeholder={emailPlaceholder} 
                  name="email"
                  autoFocus={true}
                  value={email}
                  onChange={e => inputChangeHandler(e)}
                />
                <div className="login_password">
                    <Form.Control
                      id="password" 
                      type="password" 
                      placeholder={translations ? `${uniqueCodePlaceholder}` : `${passwordPlaceholder}`}
                      name="password"
                      autoFocus={false}
                      value={password}
                      onChange={e => inputChangeHandler(e)}
                    />
                    <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                </div>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    style={{ color: "#ffffff" }} 
                    type="checkbox"
                    id="rememberMe"
                    onClick={(e:any) => setRememberMe(e.target.checked)}
                    value={rememberMe}
                    label="Remember Me"
                  />
              </Form.Group>
              <div className="login_btn mt-50">
                  <Button 
                    disabled={isLoading} 
                    className={`btn btn_border_orange`} 
                    type="button"  
                    onClick={onUserLogin}
                    >
                    <IconLoader isLoading={isLoading}>Login</IconLoader>
                  </Button>
                  
                  {details?.registration_external_link ? (
                    <Link to={{pathname:details?.registration_external_link}} target="_blank">
                      <button
                        className="btn btn_theme_white" 
                        type="button"
                        disabled={isLoading}
                      >or Register</button>
                    </Link>
                  ) : (
                    <Link  to={`${slug.absolutePath}/register`}>
                      <button
                        className="btn btn_theme_white" 
                        type="button"
                        disabled={isLoading}
                      >or Register</button>
                  </Link>
                  )}
              </div>
          </React.Fragment>
        );
    }else if(event?.security === "invitation"){
      LoginDetail = (<React.Fragment> 
        <div className='login_email'>  
          <Form.Control
              id="email" 
              type="email" 
              placeholder={emailPlaceholder} 
              name="email"
              autoFocus={true}
              value={email}
              onKeyUp={e => handlercheckEmail(e)}
              onChange={e => handlercheckEmail(e)}
          />
          <span className="input-group-append">
            {errorEmail === "valid" && (<FaCheck className='checkIcon'/>)}
            {errorEmail === "invalid" && (<FaTimes className='timeIcon'/>)}
          </span>
        </div>
        {isLoading ? (<div className="loaderEmail">Loading...</div>) : null}
        <p className='text-danger h6'>{errorCheck && errorCheck}</p>
        <div className="login_btn mt-50">
          {emailVerify === "isLogin" && ( <button disabled={isLoading} className={`btn btn_border_orange`} type="button"  onClick={onUserLogin}>
            <IconLoader isLoading={isLoading}>Login</IconLoader>
          </button>) }

          {emailVerify === "isRegistart" && ( <Link to={`${slug.absolutePath}/register`}>
          <button className="btn btn_theme_white" type="button" disabled={isLoading} >Register</button>
          </Link>)}
      </div>
    </React.Fragment>)
  }

   return (
      <>
         <div className="login_area">
            <div className="languages_btn mb-100 d-flex justify-content-end">
               {LanguageMenu}
         </div>
            <div className="container">
                <div className="login_area_inner ">
                   <div className="login_logo_box">
                      <div className="logo_img">
                        <Image className="img-fluid d-none d-md-block" src={details?.img_logo_desktop} placeholderSrc="/images/logo-placeholder.png" alt="Login page logo"/> 
                        <Image className="img-fluid d-md-none" src={details?.img_logo_mobile} placeholderSrc="/images/logo-placeholder.png" alt="Login page logo"/>                      
                      </div>
                        <h4 title={event?.title}>{event?.title}</h4>
                   </div>
                   <div className="login_content_box">
                    <Form onSubmit={onUserLogin} className="login-form" method="post">
                      {LoginDetail}
                      <button type="submit" style={{ display: "none" }}></button>
                    </Form >
                   </div>
                </div>
             </div>
         </div>
          {event?.support && <SupportScript script={event?.support}/>}
         {options?.includes('faq') ? <button type='button' className='btn LeftIcon' data-toggle="modal" data-target="#showFaq">
            <FAQIcon FaqClass={"faqWhite"}/>
          </button> : null}
          <FaqModal translations={translations}/>
      </>
   )
}

export default Login



