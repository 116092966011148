import CacheVersion from "./cacheVersion";
import {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import Location from "./Location";


const useSlug = () => {
    const [client, event, page, identifier1] = Location();
    const [slug, setSlug] = useState( `${client}/${event}`)
    const [absSlug, setAbsSlug] = useState( `/${client}/${event}`)

    useMemo(() => {
        setSlug(`${client}/${event}`)
        setAbsSlug(`/${client}/${event}`)
    }, [client, event]);

    return {
        absolutePath: absSlug,
        path: slug,
        client: client,
        event: event,
        page: page,
        identifier_1: identifier1
    }
};

export default useSlug;