import React,{useState, useEffect, useRef} from 'react';
import ReactHtmlParser from "react-html-parser";
import { AZPagination } from '../../../../../UIComponets/AZPaggination';
import { FaqApi } from '../../../../../hooks/shared/FaqApi';
import {IconLoader} from "../../../../../UIComponets/IconLoader";
import { SearchIcon } from '../../../../../SVG/Icons';
import { Link } from 'react-router-dom';
import { HorizontalScrollingMenu } from '../../../../../hooks/HorizontalScrollingMenu';


const FaqModal = ({translations}) => {

    const [scrollBarArrow , setscrollBarArrow] = useState(false);

    const {
        searchFaq,
        filterFaqCategory,
        charFilter,
        state:{
            faqList,
            faqCategorys,
            isActive,
            isLoading,
            currentChar,
        } 
     } = FaqApi();

    const {
        slide,
        scrollCheck,
        anim,
        anim2,
        state:{
            scrl,
            scrollX,
            scrolEnd
        }
    } = HorizontalScrollingMenu();
    

    useEffect(() => {
        const scroll = setInterval(() => {
            if(scrl.current){
                if (scrl.current.scrollWidth > scrl.current.clientWidth) {
                    setscrollBarArrow(true);
                }
            }
        }, 500);
        return () => clearInterval(scroll);
      },[]);

    return (
        <>
        <div className="modal fade connection_request faq_login_model" id="showFaq">
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    <section className="mobile_faq_area faq_area mt-40">
                        <div className="container">
                            <div className="m_faq_heading">
                                <h1 className="main_heading mb-20">{translations?.general?.faq}</h1>
                                <div className="searchbar_right mb-20">
                                    <input type="text" placeholder="Search.." name="search"  onChange={(e) => searchFaq(e?.target?.value)} className="form-control"/>
                                    <button type="button"> <IconLoader isLoading={isLoading}><SearchIcon/></IconLoader></button>
                                </div>
                                <div className="sponsors_searchbar_pagination mb-40 d-flex justify-content-center">
                                    <AZPagination currentChar={currentChar} onClick={(char) => charFilter(char)}/>
                                </div>
                            </div>
                            <div className="accordion" id="accordionExample">
                                    {faqList && faqList.map((fq, index) => {
                                    return (
                                        <div className="item" key={index}>
                                            <div className="item-header" id="headingOne">
                                                <h2 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={`#collapseOneM_${fq?.id}`} aria-expanded="true" aria-controls="collapseOne">
                                                    {fq?.question} <i className="fa fa-angle-right"></i>
                                                </button>
                                                </h2>
                                            </div>
                                            <div id={`collapseOneM_${fq?.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                <div className="t-p">
                                                <p>{ReactHtmlParser(fq?.answer ?? '')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    })
                                }
                            </div>
                        </div>
                        </section>
                        <section className="faq_area">
                            <div className="container">
                                <>
                                <h1 className="main_heading mb-20">{translations?.general?.faq}</h1>
                                <div className="slide_decks_header mb-40">
                                           {faqCategorys?.length > 0 ? 
                                            <div className='sponsors-category-list'>
                                                {scrollBarArrow ? scrollX !== 0 &&  (
                                                <span className="prev cat-arrow-prev cat-arrow" onClick={() => slide(-50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)}>
                                                    <i className="fa fa-angle-left"></i>
                                                </span>
                                                ) : null}
                                                    <ul className="nav" id='faq_area_nav' ref={scrl} onScroll={scrollCheck}>
                                                        <li className={isActive === "all" ? "active" : ""} onClick={() => filterFaqCategory("all") }>
                                                            <Link to={"#"}>{translations?.general?.all}</Link>
                                                        </li>
                                                        {faqCategorys && faqCategorys.map((category, index) => {
                                                            return (
                                                                <li key={index} className={isActive === category?.id ? "active" : ""} onClick={()=> filterFaqCategory(category?.id)}>
                                                                    <Link to={"#"} style={{color:category?.color}}>{category?.name}</Link>
                                                                </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                {scrollBarArrow ? !scrolEnd && (
                                                <span className="cat-arrow-next next cat-arrow" onClick={() => slide(+50)} onMouseEnter={(e) => anim(e)} onMouseLeave={(e) => anim2(e)} >
                                                    <i className="fa fa-angle-right"></i>
                                                </span>
                                                ) : null}
                                            </div> : "" }
                                            <div className="searchbar_right">
                                                <input type="text" placeholder="Search.." name="search" onChange={(e) => searchFaq(e?.target?.value)} className="form-control" />
                                                <button type="button"><IconLoader isLoading={isLoading}><SearchIcon/></IconLoader></button>
                                            </div>
                                </div>
                                <div className="accordion" id="accordionExample">
                                    {faqList && faqList.map((fq, index) => {
                                    return (<div className="item" key={index}>
                                            <div className="item-header" id="headingOne">
                                                <h2 className="mb-0">
                                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={`#collapseOne_${fq?.id}`} aria-expanded="true" aria-controls="collapseOne">
                                                    <i className="fa fa-angle-right"></i> {fq?.question}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id={`collapseOne_${fq?.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                <div className="t-p">
                                                    <p>{ReactHtmlParser(fq?.answer ?? '')}</p>
                                                </div>
                                            </div>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                                </>
                                </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </>  
    )
}

export default FaqModal
