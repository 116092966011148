import React from "react";

export const ChatMenu =  React.forwardRef(({badgeCount, isActive, translations, onClick}, ref) => {
    return <li className="nav-item" id="menu-Chat">
        <a ref={ref} className={`nav-link ${isActive ? "active" : ""}`} data-id="Chat"  onClick={onClick}>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="49.447" height="49.447"
                  viewBox="0 0 49.447 49.447">
            <g id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles"
                transform="translate(-3.375 -3.375)">
                <path id="Path_1272" data-name="Path 1272"
                      d="M45.583,35.777a2.88,2.88,0,0,1,.392-1.45,4,4,0,0,1,.25-.368,19.26,19.26,0,0,0,3.281-10.733c.036-10.959-9.212-19.85-20.646-19.85a20.5,20.5,0,0,0-20.242,15.8,19.1,19.1,0,0,0-.44,4.065A20.163,20.163,0,0,0,28.5,43.336a24.479,24.479,0,0,0,5.61-.915c1.343-.368,2.674-.856,3.019-.987a3.143,3.143,0,0,1,1.105-.2,3.09,3.09,0,0,1,1.2.238l6.739,2.389a1.607,1.607,0,0,0,.464.119.946.946,0,0,0,.951-.951,1.527,1.527,0,0,0-.059-.321Z"
                      transform="translate(3.316 0)"/>
                <path id="Path_1273" data-name="Path 1273"
                      d="M35.527,37.744c-.428.119-.975.25-1.569.38a21.938,21.938,0,0,1-4.041.535A20.163,20.163,0,0,1,9.591,18.56a22.472,22.472,0,0,1,.178-2.544c.071-.511.155-1.022.273-1.521.119-.535.261-1.07.416-1.593l-.951.844a17.689,17.689,0,0,0-6.133,13.3,17.492,17.492,0,0,0,2.948,9.747c.273.416.428.737.38.951s-1.414,7.369-1.414,7.369a.954.954,0,0,0,.321.915.968.968,0,0,0,.606.214.853.853,0,0,0,.345-.071l6.668-2.627a1.857,1.857,0,0,1,1.426.024A20,20,0,0,0,21.87,45a18.668,18.668,0,0,0,14.275-6.549s.38-.523.82-1.141C36.526,37.459,36.026,37.614,35.527,37.744Z"
                      transform="translate(0 6.578)"/>
            </g>
          </svg>
              
          </span>
          <p>{translations?.chat?.title}</p>
        </a>
        {badgeCount ? <p className="number">{badgeCount}</p> : null}
    </li>
})
export const RatingMenu = React.forwardRef(({badgeCount, isActive, translations, onClick}, ref) => {
    return <li className="nav-item" id="menu-Rating">
        <a ref={ref}  className={`nav-link ${isActive ? "active" : ""}`} data-id="Rating"  onClick={onClick}>

                                        <span>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="37.617" height="36.004"
                                               viewBox="0 0 37.617 36.004">
                                               <path id="Icon_awesome-star" data-name="Icon awesome-star"
                                                     d="M18.232,1.252l-4.591,9.309-10.273,1.5A2.251,2.251,0,0,0,2.123,15.9L9.555,23.14,7.8,33.37a2.249,2.249,0,0,0,3.262,2.37l9.19-4.83,9.19,4.83A2.25,2.25,0,0,0,32.7,33.37L30.945,23.14,38.377,15.9a2.251,2.251,0,0,0-1.245-3.839l-10.273-1.5L22.268,1.252a2.252,2.252,0,0,0-4.036,0Z"
                                                     transform="translate(-1.441 0.001)"/>
                                             </svg>
                                            
                                             </span>
            <p>{translations?.rating?.title}</p>

     {badgeCount ? <p className="number">{badgeCount}</p> : null}
        </a>
    </li>
})

export const VoteMenu = React.forwardRef(({badgeCount, isActive, translations, onClick}, ref) => {
    return <li className="nav-item" id="menu-Vote">
        <a ref={ref} className={`nav-link ${isActive ? "active" : ""}`} data-id="Vote"  onClick={onClick}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="31.5"
                    viewBox="0 0 45 31.5">
                      <g id="Group_1841" data-name="Group 1841"
                          transform="translate(-1823.5 -565.25)">
                        <g id="Group_1840" data-name="Group 1840">
                          <path id="Icon_awesome-vote-yea" data-name="Icon awesome-vote-yea"
                                d="M42.75,22.5h-4.5V27h1.575a.627.627,0,0,1,.675.563v1.125a.627.627,0,0,1-.675.563H5.175a.627.627,0,0,1-.675-.562V27.563A.627.627,0,0,1,5.175,27H6.75V22.5H2.25A2.248,2.248,0,0,0,0,24.75V31.5a2.248,2.248,0,0,0,2.25,2.25h40.5A2.248,2.248,0,0,0,45,31.5V24.75A2.248,2.248,0,0,0,42.75,22.5ZM36,27V4.521A2.27,2.27,0,0,0,33.729,2.25H11.278A2.276,2.276,0,0,0,9,4.521V27ZM14.85,14.2l1.793-1.779a.749.749,0,0,1,1.069.007l2.9,2.925,6.694-6.637a.749.749,0,0,1,1.069.007l1.779,1.793a.749.749,0,0,1-.007,1.069l-9.021,8.944a.749.749,0,0,1-1.069-.007l-5.21-5.252a.749.749,0,0,1,0-1.069Z"
                                transform="translate(1823.5 563)"/>
                        </g>
                      </g>
                    </svg>
                  
              </span>
            <p>{translations?.vote?.title}</p>
        </a>
        {badgeCount ? <p className="number">{badgeCount}</p> : null}
    </li>
})

export const PollMenu =  React.forwardRef(({badgeCount, isActive, translations, onClick}, ref) => {
    return <li className="nav-item" id="menu-Poll">
        <a ref={ref} className={`nav-link ${isActive ? "active" : ""}`} data-id="Poll"  onClick={onClick}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="38.851" height="38.851"
                      viewBox="0 0 38.851 38.851">
                  <path id="Icon_awesome-poll" data-name="Icon awesome-poll"
                        d="M34.688,2.25H4.163A4.164,4.164,0,0,0,0,6.413V36.938A4.164,4.164,0,0,0,4.163,41.1H34.688a4.164,4.164,0,0,0,4.163-4.163V6.413A4.164,4.164,0,0,0,34.688,2.25ZM13.875,31.388a1.387,1.387,0,0,1-1.388,1.388H9.713a1.387,1.387,0,0,1-1.388-1.388v-11.1A1.387,1.387,0,0,1,9.713,18.9h2.775a1.387,1.387,0,0,1,1.388,1.388Zm8.325,0a1.387,1.387,0,0,1-1.388,1.388H18.038a1.387,1.387,0,0,1-1.388-1.388V11.963a1.387,1.387,0,0,1,1.388-1.388h2.775A1.387,1.387,0,0,1,22.2,11.963Zm8.325,0a1.387,1.387,0,0,1-1.388,1.388H26.363a1.387,1.387,0,0,1-1.388-1.388v-5.55a1.387,1.387,0,0,1,1.388-1.388h2.775a1.387,1.387,0,0,1,1.388,1.388Z"
                        transform="translate(0 -2.25)"/>
                </svg>
                  
                </span>
            <p>{translations?.poll?.title}</p>
        </a>
        {badgeCount ? <p className="number">{badgeCount}</p> : null}
    </li>
})

export const QnAMenu =  React.forwardRef(({badgeCount, isActive, translations, onClick}, ref) => {
    return <li className="nav-item " id="menu-QA">
        <a ref={ref} className={`nav-link ${isActive ? "active" : ""}`} data-id="QA"  onClick={onClick}>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="46.201" height="46.201"
                viewBox="0 0 46.201 46.201">
                <g id="Group_1839" data-name="Group 1839"
                  transform="translate(-1822.899 -191.799)">
                  <g id="Group_1838" data-name="Group 1838">
                    <g id="Group_1837" data-name="Group 1837">
                      <g id="Group_1836" data-name="Group 1836">
                        <g id="Group_1835" data-name="Group 1835">
                          <g id="Group_1834" data-name="Group 1834">
                            <path id="Icon_material-question-answer"
                                  data-name="Icon material-question-answer"
                                  d="M46.891,12.24h-4.62V33.031H12.24v4.62a2.317,2.317,0,0,0,2.31,2.31H39.961L49.2,49.2V14.55A2.317,2.317,0,0,0,46.891,12.24ZM37.651,26.1V5.31A2.317,2.317,0,0,0,35.341,3H5.31A2.317,2.317,0,0,0,3,5.31V37.651l9.24-9.24h23.1A2.317,2.317,0,0,0,37.651,26.1Z"
                                  transform="translate(1819.899 188.799)"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            
        </span>
        <p>{translations?.qa?.title}</p>
        </a>
        {badgeCount ? <p className="number">{badgeCount}</p> : null}
    </li>
})