import React from 'react';
import { Link } from 'react-router-dom';

const SessionsLoadMobile = ({loadSessions, isActive, translations}) => {
    return (
        <>
            <ul className="nav all-sessions_tab">
                <li className={isActive ? "" : "active"} onClick={() => loadSessions(false)}>
                    <Link to={"#"}>{translations?.sessions?.all_sessions}</Link>
                </li>
                <li className={isActive ? "active" : ""} onClick={() => loadSessions(true)}>
                    <Link to={"#"}>{translations?.sessions?.my_sessions}</Link>
                </li>
            </ul>
        </>
    )
}

export default SessionsLoadMobile;
