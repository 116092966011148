import { useState, useRef} from "react";
import { gsap } from "gsap";

export const HorizontalScrollingMenu = () => {

    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);

    //Slide click
    const slide = (shift) => {
      scrl.current.scrollLeft += shift;
      setscrollX(scrollX + shift);
      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
      ) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    };
  
    //Anim
    const anim = (e) => {
      gsap.from(e.target, { scale: 1 });
      gsap.to(e.target, { scale: 1.5 });
    };
    const anim2 = (e) => {
      gsap.from(e.target, { scale: 1.5 });
      gsap.to(e.target, { scale: 1 });
    };
  
    const scrollCheck = () => {
      setscrollX(scrl.current.scrollLeft);
      if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    };

    return {
        slide,
        scrollCheck,
        anim,
        anim2,
        state: {
            scrl:scrl,
            scrollX:scrollX, 
            scrolEnd:scrolEnd
        }
    }
};
