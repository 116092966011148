import {useContext} from "react";
import {Context as LayoutContext} from "../../context/LayoutContext";
import {TemplateDetails} from "../../Model/TemplateDetails";
import useSlug from "../useSlug";

export function useTemplate() {
    const slug = useSlug()

    const {
        state: {
            layout
        },
        getLayoutDetails
    } = useContext(LayoutContext)

    return {
        state: {
            layout: layout
        },
        getLayoutDetails: (callback) => getLayoutDetails(slug, callback)
    }
}