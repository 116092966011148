import {useContext} from "react";
import useSlug from "../useSlug";
import {Context as defaultContext} from "../../context/DefaultSectionContext";

export function OneToOneChat() {
    const slug = useSlug()
    const {
        state: {
            chatContainer
        },
        sendOneToOneChat,
        prefetchOneToOneChat,
        fetchParticipantChat,
    } = useContext(defaultContext)


    return {
        prefetchOneToOneChat: (callback) => prefetchOneToOneChat({slug}, callback),
        fetchParticipantChat: ({participant_id, message_id}, callback) => {
            fetchParticipantChat({slug, participant_id, message_id}, callback)
        },
        sendOneToOneChat: ({receiver_id, message}, callback) => {
            sendOneToOneChat({slug, receiver_id, message}, callback)
        },

        state: {
            isFetched: chatContainer?.isFetched,
            users: chatContainer?.users,
            prefetch_messages: chatContainer?.prefetch_messages,
            countMap: chatContainer?.count,
            chatContainer: chatContainer
        }
    }
}