import React, {memo, useRef, useState} from 'react'
import {EventApi} from "../../hooks/shared/EventApi";
import {VODPlayer} from "../../classes/VODPlayer";
import moment from "moment";

export const SplashScreenDialog = memo(() => {

    const playerRef = useRef()
    const {state: {eventsPageData : {splash}}} = EventApi()
    const [canShow, setCanShow] = useState(false)
    if (splash && !canShow){
        const key = `splash_${splash.event_id}`

        const expiration = localStorage.getItem(key)
        if (expiration){
            const expiration_date = moment(expiration);
            const current_date = moment()
            if (current_date > expiration_date) {
                localStorage.removeItem(key)
            }
        }

        if (!localStorage.getItem(key)){
            const new_expiration = moment().add(splash?.rotation, 'hours').format()
            localStorage.setItem(key, new_expiration)
            setCanShow(true)
            setTimeout(() => {
                window.$("#splashscreen").modal("show")
            }, 2000)
        }
    }

    const closeVideo = () => {
        playerRef?.current?.stop()
    }

    return (
        <>
            <div className="modal fade connection_request splashscreen_model" id="splashscreen" data-backdrop="static" data-keyboard={false}>
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={closeVideo} type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <h2>{splash?.name}</h2>
                            <div className="splashscreen_body">
                                {canShow && <VODPlayer object={splash} ref={playerRef} source={splash?.type} contentId={splash?.content_id}/>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
