import {useContext} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import useSlug from "../useSlug";

export function VodApi() {
    const slug = useSlug();
    const {
        getVodData,
        state:{
            eventsAllPagesData: {
                vods,
                vod_categories
            },
        }
    } = useContext(defaultContext);

    return {
        getVodData: () => getVodData(slug.absolutePath),
        state: {
            vods: vods,
            vod_categories: vod_categories
        }
    }
}