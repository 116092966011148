import {useContext} from "react";
import {Context as defaultContext, resetNetworkingBadge} from "../../context/DefaultSectionContext";
import _ from 'lodash';
import Logger from "../../Util/Logger";
import useSlug from "../useSlug";
import {UserDetails} from "../../Util/Utils";

export function BadgesApi() {
    const context = useContext(defaultContext);
    const slug = useSlug();
    const live_badges = context?.state?.live_badges
    const networking_badges = context?.state?.networking_badges
   const list =  _.map(live_badges, (v, k) => v)
    const values = _.map(list, _.values)
    const total = _.sum(_.flatMap(values))



    const netwroking_chats =  _.map(networking_badges?.chat, (v, k) => v)

    const userDetails =  UserDetails(context?.state?.eventsPageData?.user_details)

    const networking_chat_total = userDetails?.isNotificationEnabled ? _.sum(_.flatMap(netwroking_chats)) : 0

    const pendings = userDetails?.isNotificationEnabled ?  (networking_badges?.meetings?.pendings ?? 0) : 0;
    const accepted = userDetails?.isNotificationEnabled ?  (networking_badges?.meetings?.accepted ?? 0) : 0;
    const networking_badges_total =  userDetails?.isNotificationEnabled ? (pendings + accepted + networking_chat_total) : 0
    const networking_badges_meeting_total = userDetails?.isNotificationEnabled ?  (pendings + accepted) : 0
    const live_badges_total = userDetails?.isNotificationEnabled ? total : 0
    const networking_chat_badge_map = userDetails?.isNotificationEnabled ? networking_badges?.chat : {}
    return {
        resetLiveBadge: context?.resetLiveBadge,
        resetNetworkingBadge:(type, object, callback) => context?.resetNetworkingBadge(type, slug, object, callback),
        networking_badges_total: networking_badges_total,
        networking_chat_total: networking_chat_total,
        networking_badges_meeting_total: networking_badges_meeting_total,
        networking_badges: {pendings, accepted},
        live_badges_total: live_badges_total,
        networking_chat_badge_map: networking_chat_badge_map
    }
}