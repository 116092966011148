//******************************************* Default Context Constants *******************************************

// Login registration
export const REGISTRATION = "REGISTRATION";
export const LOGIN = "LOGIN";
export const AUTHENTICATION = "AUTHENTICATION";
export const DEFAULT_AUTHENTICATION_WHILE_REGISTRATION =
  "DEFAULT_AUTHENTICATION_WHILE_REGISTRATION";
export const DEFAULT_AUTHENTICATION_WHILE_LOGIN =
  "DEFAULT_AUTHENTICATION_WHILE_LOGIN";
export const DEFAULT_REGISTRATION = "DEFAULT_REGISTRATION";
export const ON_USER_LOGIN = "ON_USER_LOGIN";
export const ON_USER_REGISTER = "ON_USER_REGISTER";
export const FORGET_PASSWORD_DATA = "FORGET_PASSWORD_DATA";
export const RESET_PASSWORD_DATA = "RESET_PASSWORD_DATA";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
// main Page data
export const EVENT_INFO = "EVENT_INFO";
export const EVENT_OPTIONS_ACTIVE = "EVENT_OPTIONS_ACTIVE";
export const EVENT_OPTIONS_DEACTIVE = "EVENT_OPTIONS_DEACTIVE";
export const UPDATE_NETWORK_BADGE = "UPDATE_NETWORK_BADGE";
export const UPDATE_LIVE_BADGE = "UPDATE_LIVE_BADGE";
export const EVENT_INFO_ON_SOCKET_UPDATE = "EVENT_INFO_ON_SOCKET_UPDATE";
export const ORGANIZATION_EVENTS = "ORGANIZATION_EVENTS";
export const UPDATE_PINNED_ITEMS = "UPDATE_PINNED_ITEMS";
// On Sub-channel selection
export const DEFAULT_SUB_CHANNEL = "DEFAULT_SUB_CHANNEL";
export const IS_POLL_AVAIL = "IS_POLL_AVAIL";
export const IS_VOTE_AVAIL = "IS_VOTE_AVAIL";
export const IS_VOD_AVAIL = "IS_VOD_AVAIL";
export const IS_RATING_AVAIL = "IS_RATING_AVAIL";
// Vod section category
export const VOD_SECTION_CATEGORY_SELECTED = "VOD_SECTION_CATEGORY_SELECTED";


//******************************************* GS1 Context Constants *******************************************
export const GS1AUTHENTICATION = "GS1AUTHENTICATION";
export const GS1LOGIN = "GS1LOGIN";
export const GS1SUB_CHANNEL = "GS1SUB_CHANNEL";
export const RATING_UPDATED = "RATING_UPDATED";
export const UPDATE_ALL_USER_RATINGS = "UPDATE_ALL_USER_RATINGS";
export const UPDATE_ALL_USER_VOTES = "UPDATE_ALL_USER_VOTES";
export const UPDATE_ALL_USER_POLLS = "UPDATE_ALL_USER_POLLS";
export const VOTE_UPDATED = "VOTE_UPDATED";
export const POLL_UPDATED = "POLL_UPDATED";
export const DEFAULT_LOGIN = "DEFAULT_LOGIN";
export const ISVALID = "ISVALID";
export const GS1SUB_CHANNEL_HAPPENING_NOW = "GS1SUB_CHANNEL_HAPPENING_NOW";
export const GS1SUB_UPDATE_HAPPENING_NOW_DATA =
  "GS1SUB_UPDATE_HAPPENING_NOW_DATA";
export const GS1SUB_UPDATE_SESSION_DATA = "GS1SUB_UPDATE_SESSION_DATA";
export const GS1SUB_UPDATE_LIVE_SESSION_DATA =
  "GS1SUB_UPDATE_LIVE_SESSION_DATA";
export const FETCH_SUBCHANNEL_WHEN_MAIN_UNLIVE =
  "FETCH_SUBCHANNEL_WHEN_MAIN_UNLIVE";

export const CHANGE_LANGAUGE = "CHANGE_LANGAUGE";

// GS1
export const GS1DATA = "GS1DATA";

//Fetch Data
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const DELETE_CHATS = "DELETE_CHATS";
export const ON_SUBMITE_VOTE = "ON_SUBMITE_VOTE";
export const ON_SUBMITE_POLL = "ON_SUBMITE_POLL";
export const ON_SEND_RATING = "ON_SEND_RATING";

export const PUSHER_TICKER = "PUSHER_TICKER";
export const PUSHER_SPONSER = "PUSHER_SPONSER";
//sandeep
export const GET_SPONSER = "GET_SPONSER";
export const GET_SPONSER_DETAILS = "GET_SPONSER_DETAILS";
export const GET_SLIDE_DECKS = "GET_SLIDE_DECKS";
export const GET_VOD = "GET_VOD";
export const GET_VOD_DETAILS = "GET_VOD_DETAILS";
export const GET_SESSIONS = "GET_SESSIONS";
export const GET_SESSIONS_SPEAKERS = "GET_SESSIONS_SPEAKERS";
export const GET_DAILY_TRIVIA = "GET_DAILY_TRIVIA";
export const GET_BREAKOUT_ROOMS = "GET_BREAKOUT_ROOMS";
export const GET_PARTICIPANT_USER = "GET_PARTICIPANT_USER";
export const GET_NETWORK_PEOPLE = "GET_NETWORK_PEOPLE";
export const GET_SPEAKERS = "GET_SPEAKERS"
export const GET_USER_SETTING = "GET_USER_SETTING"





export const PUSHER_VOD = "PUSHER_VOD";
export const PUSHER_BREAKOUT = "PUSHER_BREAKOUT";
export const PUSHER_INSERTCHAT = "PUSHER_INSERTCHAT";
export const PUSHER_DELETECHAT = "PUSHER_DELETECHAT";
export const PUSHER_QNA = "PUSHER_QNA";
export const INSERT_QNA = "INSERT_QNA";
export const FETCH_POLL = "FETCH_POLL";
export const PUSHER_ONAIR_POLL = "PUSHER_ONAIR_POLL";
export const PUSHER_OFFAIR_POLL = "PUSHER_OFFAIR_POLL";
export const PUSHER_ONAIR_VOTE = "PUSHER_ONAIR_VOTE";
export const PUSHER_ONAIR_RATING = "PUSHER_ONAIR_RATING";
export const PUSHER_TIMETABLE = "PUSHER_TIMETABLE";
export const PUSHER_ONLINE_MEMBERS = "PUSHER_ONLINE_MEMBERS";
export const PUSHER_ADD_MEMBERS = "PUSHER_ADD_MEMBERS";
export const PUSHER_REMOVE_MEMBERS = "PUSHER_REMOVE_MEMBERS";
export const PUSHER_REMOVE_ALL_MEMBERS = "PUSHER_REMOVE_ALL_MEMBERS";

export const NET_TOLL_VIDEO_CONNECT = "NET_TOLL_VIDEO_CONNECT";
export const NET_TOLL_DETAILS = "NET_TOLL_DETAILS";
export const NET_CHEK_FOR_DICE_OPEN = "NET_CHEK_FOR_DICE_OPEN";
export const RESET_VC_STATE = "RESET_VC_STATE";
export const ENROLL_SESSION = "ENROLL_SESSION";
export const FETCH_O_2_O = "FETCH_O_2_O";
export const PRE_FETCH_O_2_O = "PRE_FETCH_O_2_O";
export const SEND_O_2_O = "SEND_O_2_O";
export const ONE_TO_ONE_CHAT_RECIEVED = "ONE_TO_ONE_CHAT_RECIEVED";
export const GOT_MATCH = "GOT_MATCH";
export const VIDEO_CHAT_ACCEPT = "VIDEO_CHAT_ACCEPT";
export const FETCH_ONLINE_USERS = "FETCH_ONLINE_USERS";
export const SET_METCH_ID = "SET_METCH_ID";
export const SHOW_INCOMING_CALL = "SHOW_INCOMING_CALL";
export const CLEAR_INCOMING_CALL = "CLEAR_INCOMING_CALL";
export const DECLINE_CALLBACK = "DECLINE_CALLBACK";
export const ENDCHAT_CALLBACK = "ENDCHAT_CALLBACK";
export const DECLINE_CALLBACK_TRIGGER = "DECLINE_CALLBACK_TRIGGER";
export const ENDCHAT_CALLBACK_TRIGGER = "ENDCHAT_CALLBACK_TRIGGER";
export const SET_VIDEO_CHAT_STATE = "SET_VIDEO_CHAT_STATE";
export const VIDEO_CHAT_CONNECTING = "VIDEO_CHAT_CONNECTING";
export const VIDEO_CHAT_MESSAGE_RECIEVED = "VIDEO_CHAT_MESSAGE_RECIEVED";
export const VIDEO_CHAT_REJECT = "VIDEO_CHAT_REJECT";
export const ADD_USER_TO_VIDEOCHAT = "ADD_USER_TO_VIDEOCHAT";
export const FETCH_MATCHED = "FETCH_MATCHED";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const PUSHER_APPOINTMENT_UPDATE = "PUSHER_APPOINTMENT_UPDATE";
export const PUSHER_APPOINTMENT_ADD = "PUSHER_APPOINTMENT_ADD";
export const SELECTED_CHANNEL = "SELECTED_CHANNEL";
export const SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE";
export const BREAKOUT_FILTER = "BREAKOUT_FILTER";
export const DICE_MATCHEDED = "DICE_MATCHEDED";
export const DICE_MATCHEDED_CLEAR = "DICE_MATCHEDED_CLEAR";
export const PUSHER_APPOINTMENT_EVENT = "PUSHER_APPOINTMENT_EVENT";


//2.0
export const LAYOUT_RENDER = "LAYOUT_RENDER";
export const UPDATE_LOGIN_STATE = "UPDATE_LOGIN_STATE";
export const GET_CHANNELS_V2 = "GET_CHANNELS_V2";
export const SET_WISHLIST = "SET_WISHLIST";
export const GET_CHANNELS_DETAILS_V2 = "GET_CHANNELS_DETAILS_V2";

export const SEND_QUESTION = "SEND_QUESTION";
export const SEND_ANSWER = "SEND_ANSWER";
export const SEND_POLL_ANSWER = "SEND_POLL_ANSWER";
export const SEND_RATING_ANSWER = "SEND_RATING_ANSWER";
export const SEND_VOTE_ANSWER = "SEND_VOTE_ANSWER";
export const CHAT_CREATE = "CHAT_CREATE";
export const CHAT_DELETE = "CHAT_DELETE";
export const QA_DELETE = "QA_DELETE";
export const QA_ANSWER_CREATE = "QA_ANSWER_CREATE";
export const GET_POLL = "GET_POLL";
export const GET_VOTES = "GET_VOTES";
export const GET_RATINGS = "GET_RATINGS";

export const SESSION_UPDATE = "SESSION_UPDATE";
export const SESSION_CREATE = "SESSION_CREATE";
export const RESET_LIVE_BADGE = "RESET_LIVE_BADGE";
export const RESET_NETWORKING_BADGE = "RESET_NETWORKING_BADGE";
export const SEEN_PARTICIPANT_CHAT = "SEEN_PARTICIPANT_CHAT";
export const SEEN_NETWORK_MEETING = "SEEN_NETWORK_MEETING";
export const GET_MESSAGES = "GET_MESSAGES";
export const UPDATE_CHANNEL = "UPDATE_CHANNEL";
export const UPDATE_TICKER_PUSHER = "UPDATE_TICKER_PUSHER";
export const UPDATE_INTEREST = "UPDATE_INTEREST";


export const CHAT_BLOCK = "CHAT_BLOCK";
export const CHAT_UNBLOCK = "CHAT_UNBLOCK";

// CONNECTION ADD SANDEEP
export const GET_CONNECTION_PEOPLE = "GET_CONNECTION_PEOPLE";
export const GET_CONNECTION = "GET_CONNECTION";
export const STATUS_CONNECTION = "STATUS_CONNECTION";
export const PUSHER_STATUS_CONNECTION = "PUSHER_STATUS_CONNECTION";




