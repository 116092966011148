import React from 'react'
import { Link } from 'react-router-dom'

export function CustomButton(props) {
    return (
        <Link onClick={props.onClick}
            style={props.style}
            to={props.onClick ? '#' : props.href}
            type={props.type}
            className={props.className}>{props.name}</Link>
    )
}

export function CustomLinkWithDiv(props) {
    return (

        <div className={props.containerClassName}>
            <a href={props.href}
                type={props.type}
                className={props.className}>
                {props.fafa && <i className={props.faClassName}
                >
                </i>}{props.name}</a>
        </div >

    );
}