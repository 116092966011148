import {
    EVENT_INFO,
    ORGANIZATION_EVENTS,
    ISVALID,
    ON_USER_LOGIN,
    ON_USER_REGISTER,
    GET_SPONSER,
    GET_SPONSER_DETAILS,
    GET_SLIDE_DECKS,
    GET_VOD,
    GET_VOD_DETAILS,
    GET_SESSIONS,
    GET_DAILY_TRIVIA,
    GET_BREAKOUT_ROOMS,
    GET_NETWORK_PEOPLE,
    GET_PARTICIPANT_USER,
    GET_SPEAKERS,
    PUSHER_ONLINE_MEMBERS,
    PUSHER_REMOVE_MEMBERS,
    PUSHER_ADD_MEMBERS,
    PUSHER_REMOVE_ALL_MEMBERS,
    SEND_CHAT_MESSAGE,
    BREAKOUT_FILTER,
    UPDATE_LOGIN_STATE,
    GET_CHANNELS_V2,
    GET_CHANNELS_DETAILS_V2,
    CHAT_CREATE,
    SEND_QUESTION,
    SEND_ANSWER,
    QA_ANSWER_CREATE,
    SEND_POLL_ANSWER,
    GET_POLL,
    GET_VOTES,
    GET_RATINGS,
    SEND_RATING_ANSWER,
    SEND_VOTE_ANSWER,
    SHOW_INCOMING_CALL,
    GOT_MATCH,
    VIDEO_CHAT_MESSAGE_RECIEVED,
    PUSHER_APPOINTMENT_UPDATE,
    VIDEO_CHAT_ACCEPT,
    SET_VIDEO_CHAT_STATE,
    RESET_VC_STATE,
    VIDEO_CHAT_REJECT,
    ADD_USER_TO_VIDEOCHAT,
    FETCH_MATCHED,
    ENROLL_SESSION,
    SELECTED_CHANNEL,
    DICE_MATCHEDED_CLEAR,
    DICE_MATCHEDED,
    FETCH_O_2_O,
    SEND_O_2_O,
    PRE_FETCH_O_2_O,
    ONE_TO_ONE_CHAT_RECIEVED,
    SET_WISHLIST,
    ADD_APPOINTMENT,
    UPDATE_APPOINTMENT,
    APPOINTMENT_UPDATE,
    EVENT_OPTIONS_DEACTIVE,
    EVENT_OPTIONS_ACTIVE,
    SESSION_UPDATE,
    SESSION_CREATE,
    RESET_LIVE_BADGE,
    RESET_NETWORKING_BADGE,
    SEEN_PARTICIPANT_CHAT,
    GET_MESSAGES,
    SEEN_NETWORK_MEETING,
    UPDATE_NETWORK_BADGE,
    UPDATE_CHANNEL,
    UPDATE_LIVE_BADGE,
    UPDATE_TICKER_PUSHER,
    CHAT_DELETE,
    QA_DELETE,
    UPDATE_INTEREST,
    CHAT_BLOCK,
    CHAT_UNBLOCK,
    PUSHER_APPOINTMENT_ADD,
    GET_CONNECTION_PEOPLE,
    GET_CONNECTION,
    STATUS_CONNECTION,
    PUSHER_STATUS_CONNECTION,
    GET_SESSIONS_SPEAKERS,
    GET_USER_SETTING
} from "../constants/ActionsConstants/actiontypes";
import createDataContext from "./createDataContext";
import { baseUrl } from "../constants/url/urlConstants";
import DBNetwork from "../api/DBNetwork";
import PusherWrapper from "../hooks/PusherWrapper";
import {ErrorHandler} from "../Util/ErrorHandler";
import {Action, EventHandler} from "./PusherEventHandler";
import _ from 'lodash';
import {SettingOptions} from "../classes/SettingOptions";
import NotificationCenter from "../hooks/NotificationCenter";
import Logger from "../Util/Logger";
import {VideoChatState} from "../hooks/VideoChatState";
import SharedVideoChatState from "../classes/SharedVideoChatState";
import {PusherObject, URLBuilder} from "../Util/Utils";
import {SettingsData as Settings} from "../hooks/shared/InteractionSettings";
import {BadgeType, LiveBadgeType, NetworkingBadgeType} from "../classes/BadgeType";
import {AppointmentStatus} from "../Model/AppointmentStatus";
import {endChat} from "./NetworkTollContext";
import { isNullOrEmpty } from "../hooks/Helpers";

const initialState = {
    isValid: { status: true },
    eventsPageData: {},
    eventsAllPagesData: {},
    organizationEvents: {},
    isSubChannelDataRequest: true,
    isLoggedIn: window.User.isLoggedIn(),
    onlineUsers: {},
    videoChatState: VideoChatState.DISCONNECTED,
    videoChat: null,
    selectedChannelId: null,
    videoChatMessages: [],

    live_badges: {
        chat: {},
        vote: {},
        poll: {},
        qa: {
            // 6: 5,
            // 7: 5,
        },
        rating: {
            // shared: 20
        },
        channels:{}
    },
    networking_badges: {
        meetings: {
            pendings: 0,
            accepted: 0
        },
        chat: {
            // 1226: 5,
            // 3447: 5
        },
    }
}

const getSharedStateData = (eventsAllPagesData, details) => {
    return {
        ...details
    }
}
const authReducer = (state, action) => {
    let _videoChat = { ...state.videoChat };
    let members = state.onlineUsers?.members ?? [];
    let eventsAllPagesData = { ...state.eventsAllPagesData};
    let eventsPageData =  {...state?.eventsPageData};
    const scheduleAppointments  =  { ...state.scheduleAppointments};
    const event_channel_id = action.payload?.event_channel_id
    const channelMap = state?.channelMap ?? {};
    const details = (event_channel_id ? channelMap[event_channel_id] : null);
    const chatContainer = state?.chatContainer;
    const SettingsData = Settings(state?.eventsPageData?.options);
    const updatedAppointment = action.payload?.appoinment ?? {};
    let list_appointments = eventsPageData?.appointments;
    let options = [...state?.eventsPageData?.options ?? []];
    const live_badges = {...state.live_badges ?? {}};
    const networking_badges = {...state.networking_badges ?? {}};
    const selectedChannelId = state?.selectedChannelId;
    const tickerMap = state?.tickerMap ?? {};
    switch (action.type) {
        case EVENT_INFO:
            let _payload = { ...action.payload?.data };
            const tickers = action.payload?.data?.tickers;
            let _tickerMap = {}
            if (tickers) {
                _tickerMap = tickers?.reduce((prev, next) => {
                    if (!next.id) return  prev;
                    return {
                        ...prev,
                        [next.id]: next
                    }
                }, {})
            }
            window.eventTimezone = _payload?.event?.timezone;
            return {
                ...state,
                networking_badges: _payload?.networking_badges,
                eventsPageData: { ..._payload },
                eventsAllPagesData:{
                    ...state?.eventsAllPagesData ?? {}
                },
                scheduleAppointments:{
                    ...state?.scheduleAppointments ?? {}
                },
                tickerMap: _tickerMap
            };
        case SELECTED_CHANNEL:
            return  {
                ...state,
                selectedChannelId: action.payload
            }
        case GET_SLIDE_DECKS:

            const _slide_decks = action.payload.slide_decks ?? [];
            const _slide_decks_categorys = action.payload.slide_decks_categories ?? [];
            eventsAllPagesData.slide_decks = _slide_decks;
            eventsAllPagesData.slide_decks_categories = _slide_decks_categorys;
            state.eventsAllPagesData = eventsAllPagesData;
            //let _slide_decks = { ...action.payload?.data };
            return {
                ...state
            }
        case GET_VOD:
            const _vods = action.payload.vods ?? [];
            const _categorys = action.payload.vod_categories ?? [];
            eventsAllPagesData.vod_categories = _categorys;
            eventsAllPagesData.vods = _vods;
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state
            }

        case GET_VOD_DETAILS:
            const _vods_details = action.payload.vods ?? [];
            const _vod_details = action.payload.vod ?? [];
            const _vod_people = action.payload.people ?? [];
            const _vod_categorys = action.payload.vod_categories ?? [];
            eventsAllPagesData.vods = _vods_details;
            eventsAllPagesData.vod = _vod_details;
            eventsAllPagesData.vod_people = _vod_people;
            eventsAllPagesData.vod_categories = _vod_categorys;
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state
            }

        case GET_SESSIONS:
            const _sessions = action.payload.sessions ?? [];
            const _sessions_categorie = action.payload.sessions_categories ?? [];
            const _registered_sessions = action.payload.registered_sessions ?? [];
            eventsAllPagesData.sessions = _sessions;
            eventsAllPagesData.sessions_categories = _sessions_categorie;
            eventsAllPagesData.registered_sessions = _registered_sessions;
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state
            }
        case GET_SESSIONS_SPEAKERS:
            const session = action.payload;
            if (session?.id && eventsAllPagesData.sessions){
                const index = eventsAllPagesData.sessions?.findIndex(s => s.id === session.id)
                if (index !== -1){
                    eventsAllPagesData.sessions[index].people = action.payload.people
                    state.eventsAllPagesData = eventsAllPagesData;
                }
            }
            if (session?.id && eventsPageData.sessions) {
                const _index = eventsPageData.sessions?.findIndex(s => s.id === session.id)
                if (_index !== -1) {
                    eventsPageData.sessions[_index].people = action.payload.people
                    state.eventsPageData = eventsPageData;
                }
            }
            return {
                ...state
            }


        case GET_DAILY_TRIVIA:
            const _trivia = action.payload.trivias ?? [];
            const _trivia_categorie = action.payload.trivias_categories ?? [];
            eventsAllPagesData.trivias = _trivia;
            eventsAllPagesData.trivias_categories = _trivia_categorie;
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state
            }

        case GET_BREAKOUT_ROOMS:
            const _breakouts = action.payload.breakouts ?? [];
            const _breakout_categorie = action.payload.breakout_categories ?? [];
            eventsAllPagesData.breakouts = _breakouts;
            eventsAllPagesData.breakout_categories = _breakout_categorie;
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state
            }

        case GET_SPEAKERS:
           // const _speakers = action.payload?.speakers ?? [];
           // const _speakers_pagination = action.payload?.pagination ?? [];
            eventsAllPagesData.speakers = action.payload?.speakers ?? [];
            eventsAllPagesData.char_pagination = action.payload?.char_pagination ?? [];
            eventsAllPagesData.speakers_pagination = action.payload?.speakers_pagination ?? [];
            const previuseSpeakers = state.speakerDetail?.speakers ?? [];
            const speakers = [...(action.payload.speakers_pagination.current_page > 1 ? previuseSpeakers : []), ...(action.payload?.speakers ?? [])];
            state.speakerDetail = {
                speakers: speakers,
                char_pagination: action.payload?.char_pagination,
                pagination: {
                    ...action.payload.speakers_pagination,
                    data: [...(state.speakers_pagination?.data ?? []), ...(action.payload?.speakers_pagination?.data ?? [])]
                }
            }
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state
            }
            
        case GET_SPONSER:

            const _sponsors = action.payload.sponsors ?? [];
            const _sponsors_categories = action.payload.sponsors_categories ?? [];
            const _sponsors_pagination = action.payload.pagination ?? [];
            eventsAllPagesData.sponsors = _sponsors;
            eventsAllPagesData.sponsors_categories = _sponsors_categories;
            eventsAllPagesData.pagination = _sponsors_pagination;
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state,
            };

        case GET_SPONSER_DETAILS:
            eventsAllPagesData.sponsor = action.payload.sponsor ?? [];
            eventsAllPagesData.peoples  = action.payload.people ?? [];
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state,
            };

        case GET_CONNECTION_PEOPLE:
            const _connection_people = action.payload.people ?? [];
            const _pagination_people  = action.payload.pagination ?? [];
            eventsAllPagesData.people = _connection_people;
            eventsAllPagesData.pagination = _pagination_people;
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state,
            };

        case GET_CONNECTION:
            const _connection = action.payload.connection ?? [];
            eventsAllPagesData.connection = _connection;
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state,
            };

        case PUSHER_STATUS_CONNECTION:
                if (action.payload?.payload){
                    let index = state.usersDetail.users.findIndex(t => t?.id === action.payload.payload?.user?.id);
                    let usersDetail =  state.usersDetail.users;
                    if (index !== -1) {
                        usersDetail[index].connection = action?.payload?.payload?.connection === null ? action?.payload?.payload?.connection : action.payload.payload;
                    }
                    state.usersDetail.users = usersDetail
                }
                return {
                    ...state,
            };

        // case GET_PARTICIPANT_USER:
        //     const _user_p = action.payload.user ?? [];
        //     const _sessions_p = action.payload.sessions ?? [];
        //     const _sessions_categories_p = action.payload.sessions_categories ?? [];
        //     const _registered_sessions_p = action.payload.registered_sessions ?? [];
        //     eventsAllPagesData.user = _user_p;
        //     eventsAllPagesData.sessions = _sessions_p;
        //     eventsAllPagesData.sessions_categories = _sessions_categories_p;
        //     eventsAllPagesData.registered_sessions = _registered_sessions_p;
        //     state.eventsAllPagesData = eventsAllPagesData;
        //     return {
        //         ...state,
        //     };

        case GET_NETWORK_PEOPLE:
            eventsAllPagesData.people = action.payload.people ?? [];
            eventsAllPagesData.pagination  = action.payload.pagination ?? [];
            const previuseUsers = state.usersDetail?.users ?? [];
            const users = [...(action.payload.pagination.current_page > 1 ? previuseUsers : []), ...(action.payload?.people ?? [])];
            state.usersDetail = {
                users: users,
                pagination: {
                    ...action.payload.pagination,
                    data: [...(state.pagination?.data ?? []), ...(action.payload?.pagination?.data ?? [])]
                }
            }
            state.eventsAllPagesData = eventsAllPagesData;
            return {
                ...state,
            };

        case ON_USER_LOGIN:
            let _user = { ...action.payload?.user  ?? {} };
            let  _userDetails = { ...action.payload?.user_details  ?? {} };
            let  _userInterests = [ ...(action.payload?.user_interests  ?? [])] ;
            state.eventsPageData.user = { ..._user };
            state.eventsPageData.user_details = { ..._userDetails };
            state.eventsPageData.user_interests = _userInterests;
            return { ...state, isLoggedIn: true  };

        case ON_USER_REGISTER:
            let _userRegisterDetails = { ...state.eventsPageData.user };
            _userRegisterDetails = { ...action.payload };
            state.eventsPageData.user = { ..._userRegisterDetails };
            return { ...state, isLoggedIn: true };

        case ORGANIZATION_EVENTS:
            return {
                ...state,
                organizationEvents: action.payload,
            };
        case PUSHER_ONLINE_MEMBERS:
            const me = action.payload?.me;
            const memmbers_map = action.payload?.members ?? {};
            const keys = Object.keys(memmbers_map);
            const _members = keys
                .filter( k => {
                    const user = memmbers_map[k]
                    if (user.user_id > 0) return true;
                    return false;
                })
                .map((k) => {
                    let data = memmbers_map[k];
                    data["id"] = k;
                    return data;
                })
                .filter((u) => u.id != me.id);
            const map = _members?.reduce((prev, current) => {
                return {
                    ...prev,
                    [current.user_id]: current
                }
            }, {})

            return {
                ...state,
                onlineUsers: { me: action.payload?.me, members: _members, membersMap: map },
            };
        case PUSHER_ADD_MEMBERS:
            let userObject = action.payload;
            for (const [key, value] of Object.entries(action.payload?.info ?? {})) {
                userObject[key] = value;
            }
            let index = members.findIndex((u) => u.id == userObject.id);
            if (index === -1) {
                members.push(userObject);
                const map = members?.reduce((prev, current) => {
                    return {
                        ...prev,
                        [current.user_id]: current
                    }
                }, {})

                return {
                    ...state,
                    onlineUsers: { members: members, membersMap: map},
                };
            }

        case PUSHER_REMOVE_MEMBERS:
            members = members.filter((x) => x?.id != action.payload?.id);
            const _map = members?.reduce((prev, current) => {

                return {
                    ...prev,
                    [current.user_id]: current
                }
            }, {})
            return {
                ...state,
                onlineUsers: {  members: members, membersMap: _map },
            };
        case PUSHER_REMOVE_ALL_MEMBERS: 
            return {
                ...state,
                onlineUsers: {  members: [], membersMap: {} },
        };
        case BREAKOUT_FILTER:
            let breakout_rating_page_data = { ...state?.eventsPageData?.page_data };
            breakout_rating_page_data.breakout_room = action.payload;
            state.eventsPageData.page_data = breakout_rating_page_data;

            return {
                ...state,
            };
        case UPDATE_LOGIN_STATE:
            const _state = window.User.isLoggedIn() ? state : initialState
            return  {
                ..._state,
                isLoggedIn:  window.User.isLoggedIn()
            }
        case GET_CHANNELS_V2:
            return  {
                ...state,
                eventsAllPagesData: {
                    ...state.eventsAllPagesData,
                    ...action.payload
                }
            }
        case GET_CHANNELS_DETAILS_V2:
            const _tickers = action.payload?.tickers;
            _tickers?.forEach(t => {
                if (t.id){
                    tickerMap[t.id] = t
                }
            })
            if (!state.shared){
                state.shared = getSharedStateData(eventsAllPagesData, action.payload)
            }
            return  {
                ...state,
                channelMap: {
                    ...state.channelMap ?? {},
                    [action.payload?.current_channel?.id]:action.payload
                },
                tickerMap
            };
        case SEND_QUESTION:
            if (SettingsData.isQAForEach){
                if (details){
                    details?.qa?.questions?.push(action.payload)
                    channelMap[event_channel_id] = details;
                    state.channelMap = channelMap;
                }
            }else {
                if (state.shared){
                    state.shared.qa.questions = [...(state?.shared.qa.questions ?? []), action.payload]
                }
            }

            return {
                ...state
            }
        case SEND_ANSWER: case QA_ANSWER_CREATE:
            if (SettingsData.isQAForEach){
                if (details){
                    const question_id = action?.payload?.question_id;
                    const q_index = details?.qa?.questions?.findIndex(x => x.id === question_id)
                    if (q_index != -1){
                        details.qa.questions[q_index].answers = [...(details?.qa?.questions[q_index].answers ?? []), action?.payload]
                    }
                    channelMap[event_channel_id] = details;
                    state.channelMap = channelMap;
                }
            }else {
                const question_id = action?.payload?.question_id;
                const q_index = state?.shared?.qa?.questions?.findIndex(x => x.id === question_id)
                if (q_index != -1 && state.shared){
                    state.shared.qa.questions[q_index].answers = [...(state?.shared.qa?.questions[q_index].answers ?? []), action?.payload]
                }
            }

            return {
                ...state
            }
        case SEND_POLL_ANSWER:
            const question_id = action?.payload?.question_id;
            if (SettingsData.isPollForEach){
                if (details){
                    if (details?.poll?.question?.id === question_id){
                        details.poll.question.answer_id = action.payload.answer_id;
                        state.channelMap[event_channel_id] = details;
                    }
                }
            }else {
                if (state.shared){
                    state.shared.poll.question.answer_id = action.payload.answer_id
                }
            }
            return  {
                ...state
            }
        case SEND_VOTE_ANSWER:
            if (SettingsData.isVoteForEach){
                if (details){
                    const question_id = action?.payload?.id;
                    const q_index = details?.vote?.questions?.findIndex(x => x.id === question_id)
                    if (q_index != -1){
                        details.vote.questions[q_index] = action?.payload
                        state.channelMap[event_channel_id] = details;
                    }
                }
            }else {
                const question_id = action?.payload?.id;
                const q_index = state.shared.vote?.questions?.findIndex(x => x.id === question_id)
                if (q_index != -1 && state.shared){
                    state.shared.vote.questions[q_index] = action?.payload
                }
            }
            return {
                ...state
            }
        case SEND_RATING_ANSWER:
            if (SettingsData.isRatingForEach){
                if (details){
                    const question_id = action?.payload?.id;
                    const q_index = details?.rating?.questions?.findIndex(x => x.id === question_id)
                    if (q_index != -1){
                        details.rating.questions[q_index] = action?.payload
                        state.channelMap[event_channel_id] = details;
                    }
                }
            }else {
                const question_id = action?.payload?.id;
                const q_index = state.shared.rating?.questions?.findIndex(x => x.id === question_id)
                if (q_index != -1 && state.shared){
                    state.shared.rating.questions[q_index] = action?.payload
                }
            }
            return  {
                ...state
            }
            //Pusher events
        case CHAT_CREATE:
            if (SettingsData.isChatForEach){
                if (details){
                    const index = (details.chat.messages ?? []).findIndex(m => m.id == action.payload?.id)
                    if (index === -1){
                        details.chat.messages = [...(details.chat.messages ?? []), action.payload]
                    }else {
                        details.chat.messages[index] = action.payload;
                    }

                    state.channelMap[event_channel_id] = details
                }
            }else {
                if ( state.shared){
                    const index = (state.shared.chat.messages ?? []).findIndex(m =>m.id == action.payload?.id)
                    if (index === -1){
                        state.shared.chat.messages = [...(state.shared?.chat?.messages ?? []), action.payload]
                    }else {
                        state.shared.chat.messages[index] = action.payload;
                    }
                }
            }

            return {
                ...state
            }
        case CHAT_BLOCK:
            if (SettingsData.isChatForEach){
                if (state.channelMap){
                    _.forEach(state.channelMap, (value, key) => {
                        const _details = value;
                        _details.chat.blocked = true
                        state.channelMap[key] = _details
                    })
                }
            }else {
                if (state.shared){
                    state.shared.chat.blocked = true
                }
            }
            return {
                ...state
            }
        case CHAT_UNBLOCK:
            if (SettingsData.isChatForEach){
                if (state.channelMap){
                    _.forEach(state.channelMap, (value, key) => {
                        const _details = value;
                        _details.chat.blocked = false
                        state.channelMap[key] = _details
                    })
                }
            }else {
                if ( state.shared){
                    state.shared.chat.chat.blocked = false
                }
            }
            return {
                ...state
            }
        case CHAT_DELETE:
            if (SettingsData.isChatForEach){
                if (details){
                    const index = (details.chat.messages ?? []).findIndex(m => m.id == action.payload?.id)
                    if (index !== -1){
                         details?.chat?.messages?.splice(index, 1)
                    }
                    state.channelMap[event_channel_id] = details
                }
            }else {
                if ( state.shared){
                    const index = (state.shared.chat.messages ?? []).findIndex(m =>m.id == action.payload?.id)
                    if (index !== -1){
                        state.shared?.chat?.messages?.splice(index, 1)
                    }
                }
            }
            return {
                ...state
            }
        case QA_DELETE:
            if (SettingsData.isQAForEach){
                if (details){
                    const index = (details.qa.questions ?? []).findIndex(m => m.id == action.payload?.id)
                    if (index !== -1){
                        details?.qa?.questions?.splice(index, 1)
                    }
                    state.channelMap[event_channel_id] = details
                }
            }else {
                if ( state.shared){
                    const index = (state.shared.qa.questions ?? []).findIndex(m =>m.id == action.payload?.id)
                    if (index !== -1){
                        state.shared?.qa?.questions?.splice(index, 1)
                    }
                }
            }
            return {
                ...state
            }
        case GET_POLL:
            if (SettingsData.isPollForEach){
                if (details){
                    details.poll = action.payload?.poll
                    state.channelMap[event_channel_id] = details
                }
            }else {
                if (state.shared){
                    state.shared.poll =  action.payload?.poll
                }
            }
            return {
                ...state
            }
        case GET_RATINGS:
            if (SettingsData.isRatingForEach){
                if (details){
                    details.rating = action.payload?.rating
                    state.channelMap[event_channel_id] = details
                }
            }else {
                if (state.shared){
                    state.shared.rating = action.payload?.rating;
                }
            }
            return {
                ...state
            }
        case GET_VOTES:
            if (SettingsData.isVoteForEach){
                if (details){
                    details.vote = action.payload?.vote
                    state.channelMap[event_channel_id] = details
                }
            }else {
                if (state.shared) {
                    state.shared.vote = action.payload?.vote;
                }
            }
            return {
                ...state
            }
        case GOT_MATCH:
            // _videoChat = { ...(_videoChat ?? {})};
            _videoChat.match_id = action.payload.match_id;
            _videoChat.foi = action.payload.data.foi;
            _videoChat.matched_users = action.payload.data.matched_users[0];
            return { ...state, videoChat: _videoChat };

        case VIDEO_CHAT_ACCEPT:
            _videoChat.twilio_room = action.payload.data.twilio_room;
            _videoChat.twilio_token = action.payload.data.twilio_token;
            _videoChat.twilio_identity = action.payload.data.twilio_identity;
            _videoChat.vc_created_at = action.payload.data.vc_created_at;
            _videoChat.match_id = action.payload.match_id;
            _videoChat.active_videochat = true;
            _videoChat.first_message = action.payload.data.first_message;
            _videoChat.end_time = action.payload.data.end_time;

            const _videoChatMessages = [];
            if (action.payload.data.first_message){
                _videoChatMessages.push(action.payload.data.first_message)
            }
            if (_videoChat)
            return {
                ...state,
                videoChat: _videoChat,
                videoChatState: VideoChatState.CONNECTED,
                videoChatMessages: _videoChatMessages
            };
        case VIDEO_CHAT_MESSAGE_RECIEVED:
            const videoChatMessages = [...(state.videoChatMessages ?? []), action.payload];
            return { ...state, videoChatMessages };
        case SET_VIDEO_CHAT_STATE:
            SharedVideoChatState.instance().setVideoChatState(action.payload);
            return { ...state, videoChatState: action.payload };
        case RESET_VC_STATE:
            let object = { videoChat: null, incomingCall: null };
            if ( action.payload.videoChatState === VideoChatState.DISCONNECTED && state?.onlineUsers?.membersMap){
                const _memberMap = state?.onlineUsers?.membersMap;
                Object.keys(state?.onlineUsers?.membersMap ?? {}).forEach(key => {
                    _memberMap[key].waitingToAccept = false
                })
                state.onlineUsers.membersMap = _memberMap;
            }
            if (action.payload.videoChatState) {
                object["videoChatState"] = action.payload.videoChatState;
                SharedVideoChatState.instance().setVideoChatState(
                    action.payload.videoChatState
                );
                return { ...state, ...object, videoChatMessages: [], connectedUser:[],   connectedUserMap: {}};
            }

            return { ...state, videoChat: null, videoChatMessages: [] };
// updating user status > waiting to accept
        case ADD_USER_TO_VIDEOCHAT:
            if (state?.onlineUsers?.membersMap && action.payload.user_id){
                const _memberMap = state?.onlineUsers?.membersMap
                _memberMap[action.payload.user_id].waitingToAccept = true
                state.onlineUsers.membersMap = _memberMap;
            }
            //
            return  {...state}
        case VIDEO_CHAT_REJECT:
            if (state?.onlineUsers?.membersMap && action.payload.user_id && state?.onlineUsers?.membersMap[action.payload.user_id]){
                const _memberMap = state?.onlineUsers?.membersMap
                _memberMap[action.payload.user_id].waitingToAccept = false
                state.onlineUsers.membersMap = _memberMap;
            }
            return  {...state}
        case FETCH_MATCHED:
            const connectedUser = action.payload.matched_users;
            const totalUser = connectedUser?.length ?? 0;
            const _memberMap = state?.onlineUsers?.membersMap
            connectedUser?.forEach (u => {
                if (_.has(_memberMap, u.id)){
                    _memberMap[u.id].waitingToAccept = false
                }
            })
            state.onlineUsers.membersMap = _memberMap;
            const connectedUserMap = connectedUser?.reduce(function (acc, cur, i) {
                acc[cur.id] = cur;
                return acc;
            }, {})

            return { ...state, connectedUser: connectedUser, connectedUserMap: connectedUserMap, totalUser:totalUser };
        case ENROLL_SESSION:
            eventsAllPagesData.registered_sessions = action.payload?.registered_sessions ?? [];
            eventsPageData.registered_sessions = action.payload?.registered_sessions ?? [];
            state.eventsAllPagesData = eventsAllPagesData;
            state.eventsPageData = eventsPageData;
            return  {...state}
        case DICE_MATCHEDED:
            return {...state, dice_matched: action.payload?.data}

        case DICE_MATCHEDED_CLEAR:
            return {...state, dice_matched: null}
        case PRE_FETCH_O_2_O:
            return  {
                ...state,
                chatContainer: {
                    isFetched: true,
                    ...action.payload,
                    messageContainer: {}
                }
            }
        case FETCH_O_2_O:
             const _participant_id = action.payload.participant_id;
            if ( action.payload.user_messages?.length > 0){
                if (!chatContainer["messageContainer"]){
                    chatContainer.messageContainer = {}
                }
                let list = action.payload.user_messages;
                if (chatContainer.messageContainer && chatContainer.messageContainer[_participant_id] && chatContainer.messageContainer[_participant_id].messages){
                    list = [...list, ...chatContainer.messageContainer[_participant_id].messages]
                }
                chatContainer.messageContainer[_participant_id] = {messages: list, isFetched: true}
            }
            return  {
                ...state,
                chatContainer: chatContainer
            }
        case SEND_O_2_O:
            const participant_id = action.payload.participant_id;
            if (participant_id){
                if (!chatContainer.messageContainer[participant_id]){
                    chatContainer.users = [action.payload?.user, ...(chatContainer.users ?? [])]
                    chatContainer.prefetch_messages = {...chatContainer.prefetch_messages ?? {}, [participant_id]: action.payload?.message}
                    chatContainer.messageContainer[participant_id] = {messages: [], isFetched: true}
                }
                const index = chatContainer.users?.findIndex(p => p.id === participant_id)
                if (index !== -1){
                    const user = chatContainer.users[index]
                    chatContainer.users.splice(index, 1)
                    chatContainer.users.unshift(user)
                }
                chatContainer.prefetch_messages[participant_id] = action.payload?.message;
                chatContainer.messageContainer[participant_id].messages.push(action.payload?.message)
            }
            return  {
                ...state,
                chatContainer: chatContainer
            }
        case ONE_TO_ONE_CHAT_RECIEVED:
            if (action.payload?.user?.id){
                const participant_id = action.payload?.user?.id
                if (participant_id){
                    networking_badges.chat[participant_id] = (networking_badges.chat[participant_id] ?? 0) + 1;
                }
                if (chatContainer && !_.has(chatContainer?.messageContainer ?? {}, participant_id)){
                    // storing user if user does't exist in the current context
                    const index = chatContainer?.users?.findIndex((t) => t.id == action.payload.user.id);
                    if (index === -1){
                        chatContainer.users = [...(chatContainer.users ?? []), action.payload?.user]
                    }
                    chatContainer.prefetch_messages = {[participant_id]: action.payload?.message}
                    chatContainer.messageContainer[participant_id] = {messages: [], isFetched: true}
                }
                if (chatContainer){
                    const index = chatContainer.users?.findIndex(p => p.id === participant_id)
                    if (index !== -1){
                        const user = chatContainer.users[index]
                        chatContainer.users.splice(index, 1)
                        chatContainer.users.unshift(user)
                    }
                    chatContainer.messageContainer[participant_id].messages.push(action.payload?.message)
                }
            }
            return  {
                ...state,
                chatContainer: chatContainer,
                networking_badges
            }
        case SET_WISHLIST:
            // eventsAllPagesData.slide_decks = _slide_decks;
            // eventsAllPagesData.sessions = _sessions;
            const slide_decks = action.payload?.wishlist?.slide_decks;
            const sessionsIdMap = action.payload?.wishlist?.session?.reduce((prev, next) => {
                return {
                    ...prev,
                    [next]: true
                }
            }, {});

            if (sessionsIdMap){
                eventsAllPagesData.sessions = eventsAllPagesData?.sessions?.map(s => {
                   let _session = {...s}
                    _session.isWishlisted = sessionsIdMap[s.id] ? true : false
                    return _session
                });
                eventsPageData.sessions = eventsPageData?.sessions?.map(s => {
                    let _session = {...s}
                    _session.isWishlisted = sessionsIdMap[s.id] ? true : false
                    return _session
                })

            }
            return  {
                ...state,
                eventsAllPagesData: eventsAllPagesData,
                eventsPageData: eventsPageData
            }

        case ADD_APPOINTMENT:
        case PUSHER_APPOINTMENT_ADD:
            if (updatedAppointment){
                if (action.type === PUSHER_APPOINTMENT_ADD){
                    networking_badges.meetings.pendings =  networking_badges.meetings.pendings + 1;
                    state.networking_badges = networking_badges;
                }
                state.eventsPageData.appointments = [updatedAppointment, ...(state.eventsPageData.appointments ?? [])]
            }
            return { ...state };

        case UPDATE_APPOINTMENT:
            const _index = list_appointments.findIndex((t) => t.id == updatedAppointment.id);
            if (_index !== -1){
                list_appointments[_index] = updatedAppointment;
            }
            state.networking_badges = networking_badges;
            state.eventsPageData.appointments = list_appointments;
            return { ...state };
        case PUSHER_APPOINTMENT_UPDATE:
            const indexList = list_appointments.findIndex((t) => t.id == updatedAppointment.id);
            if (indexList !== -1){
                if (updatedAppointment.status === AppointmentStatus.ACCEPTED){
                    networking_badges.meetings.accepted =  networking_badges.meetings.accepted + 1
                }else {
                    networking_badges.meetings.pendings =  networking_badges.meetings.pendings + 1
                }
                list_appointments[indexList] = updatedAppointment;
            }
            state.networking_badges = networking_badges;
            state.eventsPageData.appointments = list_appointments;
            return { ...state };
        case SHOW_INCOMING_CALL:
            return {
                ...state,
                incomingCall: action.payload,
                videoChatState: VideoChatState.RINGING,
            };
        case EVENT_OPTIONS_ACTIVE:

            let ao_index = options.findIndex(t => t === action.payload.optionname)
            if (ao_index === -1) {
                options.push(action.payload.optionname);
            }
            state.eventsPageData.options = options;
            return {
                ...state
            }
        case EVENT_OPTIONS_DEACTIVE:
            let do_index = state?.eventsPageData?.options.findIndex(t => t === action.payload.optionname)
            if (do_index !== -1) {
                options?.splice(do_index, 1);
            }
            state.eventsPageData.options = options;
            return {
                ...state
            }
        case SESSION_UPDATE:
            if (action.payload.id && eventsAllPagesData?.sessions) {
                const index = eventsAllPagesData?.sessions?.findIndex(i => i.id === action.payload.id)
                if (index !== -1){
                    // Logger.verbose({
                    //     old: eventsAllPagesData.sessions[index],
                    //     new: action.payload
                    // })
                    let sesstions = eventsAllPagesData.sessions
                    sesstions[index] = action.payload
                    eventsAllPagesData.sessions = sesstions
                }
            }

            if(action.payload.id && eventsPageData?.sessions){
                const index = eventsPageData?.sessions?.findIndex(i => i.id === action.payload.id)
                if (index !== -1){
                    eventsPageData.sessions[index] = action.payload
                }
            }

        return  {
            ...state,
            eventsAllPagesData:  eventsAllPagesData,
            eventsPageData:  eventsPageData
        }
        case UPDATE_LIVE_BADGE:
            let _isForEach = false;
            let _type = null;
            switch (action.payload?.type?.toLowerCase()) {
                case LiveBadgeType.CHAT:
                    _isForEach = SettingsData.isChatForEach;
                    _type = LiveBadgeType.CHAT;
                    break;
                case LiveBadgeType.POLL:
                    _isForEach = SettingsData.isPollForEach;
                    _type = LiveBadgeType.POLL;
                    break;
                case LiveBadgeType.VOTE:
                    _isForEach = SettingsData.isVoteForEach;
                    _type = LiveBadgeType.VOTE;
                    break;
                case LiveBadgeType.RATING:
                    _isForEach = SettingsData.isRatingForEach;
                    _type = LiveBadgeType.RATING;
                    break;
                case LiveBadgeType.QA:
                    _isForEach = SettingsData.isQAForEach;
                    _type = LiveBadgeType.QA;
                    break;
                case LiveBadgeType.CHANNELS:
                    _type = LiveBadgeType.CHANNELS
                    break;
            }

            if (_isForEach){
                if (event_channel_id && _type){
                    live_badges[_type][event_channel_id] = (live_badges[_type][event_channel_id] ?? 0) + 1;
                }
            }else {
                if (_type){
                    live_badges[_type]["shared"] =  ( live_badges[_type]["shared"]?? 0) + 1;
                }
            }
            return  {
                ...state,
                live_badges
            }

        case RESET_LIVE_BADGE:
            let isForEach = false;
            let type = null;
            switch (action.payload.toLowerCase()) {
                case LiveBadgeType.CHAT:
                    isForEach = SettingsData.isChatForEach;
                    type = LiveBadgeType.CHAT;
                    break;
                case LiveBadgeType.POLL:
                    isForEach = SettingsData.isPollForEach;
                     type = LiveBadgeType.POLL;
                    break;
                case LiveBadgeType.VOTE:
                    isForEach = SettingsData.isVoteForEach;
                     type = LiveBadgeType.VOTE;
                    break;
                case LiveBadgeType.RATING:
                    isForEach = SettingsData.isRatingForEach;
                     type = LiveBadgeType.RATING;
                    break;
                case LiveBadgeType.QA:
                    isForEach = SettingsData.isQAForEach;
                     type = LiveBadgeType.QA;
                    break;
            }

            if (isForEach){
                if (selectedChannelId && type){
                    live_badges[type][selectedChannelId] = 0;
                }
            }else {
                if (state.shared && type){
                    live_badges[type]["shared"] = 0;
                }
            }
            live_badges.channels.shared = 0;
            return  {
                ...state,
                live_badges
            }
        case RESET_NETWORKING_BADGE:
            switch (action.payload.type?.toLowerCase()) {
                case NetworkingBadgeType.CHAT:
                    if (action.payload.participant_id){
                        networking_badges.chat[action.payload.participant_id] = 0;
                    }
                    break;
                case NetworkingBadgeType.MEETINGS:
                    networking_badges.meetings = {
                        pendings: 0,
                        accepted: 0
                    };
                    break;
                case NetworkingBadgeType.ACCEPTED_MEETING:
                    networking_badges.meetings = {
                        pendings: networking_badges.meetings?.pendings ?? 0,
                        accepted: 0
                    };
                    break;
                case NetworkingBadgeType.PENDING_MEETING:
                    networking_badges.meetings = {
                        accepted: networking_badges.meetings?.accepted ?? 0,
                        pendings: 0
                    };
                    break;

            }
            return  {
                ...state,
                networking_badges
            }
        case GET_MESSAGES:
            if (SettingsData.isChatForEach){
                if (details){
                    details.chat.messages = [...(action.payload.chat.messages ?? []), ...(details.chat.messages ?? [])]
                    state.channelMap[event_channel_id] = details
                }
            }else {
                if ( state.shared){
                    state.shared.chat.messages = [...(action.payload.chat.messages ?? []), ...(details.chat.messages ?? [])]
                }
            }
            return  {
                ...state
            }
        case UPDATE_CHANNEL:
            if(action.payload.indexes){
                const indexes = action.payload.indexes;
                let _channels = [...eventsAllPagesData?.channels];
                eventsAllPagesData.channels = _channels.map((c) => {                    
                    c.position = indexes[c.id] || 0
                    return c
                })
            }
            
            if (action.payload.id){
                const index = eventsAllPagesData?.channels?.findIndex(s => s.id === action.payload.id);
                if (eventsAllPagesData?.channels && index !== -1){
                    const _channel = {...eventsAllPagesData.channels[index]}
                    _channel.status = action.payload.status;
                    _channel.live = action.payload.live
                    _channel.fallback_image = action.payload.live
                    eventsAllPagesData.channels[index] = action.payload;
                }
                if (channelMap[action.payload.id]){
                    const _channel = {...channelMap[action.payload.id]}
                    _channel.status = action.payload.status;
                    _channel.live = action.payload.live
                    _channel.fallback_image = action.payload.live
                    channelMap[action.payload.id] = _channel;
                }
            }
            return  {
                ...state,
                eventsAllPagesData,
                channelMap
            }
        case UPDATE_TICKER_PUSHER:
            const ticker = action.payload?.ticker;
            const indexes = action.payload?.indexes;

            if (ticker?.id) {
                tickerMap[ticker.id] = ticker;
                if (indexes){
                    _.each(indexes, (value, key) => {
                        tickerMap[key].position = value
                    });
                }
                state.tickerMap = tickerMap

            }
            return {
                ...state
            }
        case UPDATE_INTEREST:
            if (action.payload.interests){
                state.eventsPageData.user_interests = action.payload.interests;
            }
        return {
                ...state,
            }

        case GET_USER_SETTING:
            if (action.payload && eventsPageData){
                eventsPageData.user_details = {...eventsPageData?.user_details ?? {}, ...action.payload}
                state.eventsPageData = eventsPageData
            }
            return {
                ...state
            }
        default:
            return state;
    }
};

const HandlerWrapper = (dispatch, customerErrorHandler) => ErrorHandler(() => dispatch({ type: UPDATE_LOGIN_STATE}), customerErrorHandler)

//******************************** fetch Events Page Data ********************************88
const fetchEventsPage = (dispatch) => async (slug, callback) => {
    DBNetwork.get(slug.path)
        .then(res => {
            
            dispatch({ type: EVENT_INFO, payload: res?.data})
                const event_id  = res?.data?.data?.event?.id;
                let user_id   = res?.data?.data?.user?.id;
                if(isNullOrEmpty(user_id)){
                    user_id = res?.data?.data?.system_id;
                }
                PusherWrapper.sharedInstance().unregisterAllChannels();
                PusherWrapper.sharedInstance().v2RegisterChannels({event_id, user_id}, {
                    interactionEvent: (data) => interactionEvent(dispatch)(data, slug),
                    userInteractionEvent:  (data) => userInteractionEvent(dispatch)(data, slug),
                    onlineUsers: (data) => onlineUsers(dispatch)(data),
                    memberAdd: (data) => memberAdd(dispatch)(data),
                    memberRemove: (data) => memberRemove(dispatch)(data),
                });  
        })
        .catch(HandlerWrapper(dispatch));
};

const logout = (dispatch) => (slug, state, callback) => {
    const clearall = () => {
        PusherWrapper.sharedInstance().unregisterAllChannels();
        window.User.clearLoginData()
        callback()
        dispatch({ type: UPDATE_LOGIN_STATE})
    }
     if (state.videoChatState !== VideoChatState.DISCONNECTED){
        networking_endChat(dispatch)(slug, state, {}, (res, err) => {
            clearall()
        });
    }else {
         clearall()
    }
}

const subscribeChannel = (dispatch) => async (event, callback) => {
    const {videoChatState} = SharedVideoChatState.instance().getObject()
    if(videoChatState !== VideoChatState.CONNECTED){
        PusherWrapper.sharedInstance().subscribePresenceChannel( {event_id: event.id}, {
            onlineUsers: (data) => onlineUsers(dispatch)(data),
            memberAdd: (data) => memberAdd(dispatch)(data),
            memberRemove: (data) => memberRemove(dispatch)(data)
        }); 
    }
}

const unsubscribeChannel = (dispatch) => async (callback) => {
    const {videoChatState} = SharedVideoChatState.instance().getObject()
    if(videoChatState !== VideoChatState.CONNECTED){
        PusherWrapper.sharedInstance().unsubscribePresenceChannel(); 
        await dispatch({ type: PUSHER_REMOVE_ALL_MEMBERS });
    }
}

export const setSelectedChannel = (dispatch) => async (channel_id) => {
    dispatch({
        type: SELECTED_CHANNEL,
        payload: channel_id,
    });
};


export const networking_commonUserAppointmentEvent = (dispatch) => async (data) => {
    const model = PusherObject(data);
    if (model.isAppointment()){
        dispatch({type: PUSHER_APPOINTMENT_UPDATE, payload: model.getObject()});
    }
};

const getOrganizationEvents = (dispatch) => async (url) => {
    const slug = baseUrl(`api/${url}`);

    try {
        const response = await DBNetwork.get(slug);
        dispatch({ type: ORGANIZATION_EVENTS, payload: response.data });
    } catch (e) {
        dispatch({ type: ISVALID, payload: false });
    }
    // alert('Event Org');
};


// ********************* for user Details Update ****************************

const updateUserDetails = (dispatch) => async (payload) => {
    await dispatch({ type: ON_USER_LOGIN, payload });
    // await dispatch({ type: ON_USER_LOGIN, payload: data })
};

const eventOption = (dispatch) => (data, slug) => {
    NotificationCenter.sharedInstance().broadcastNotification('optionsUpdate', data)
    const action = data.action
    switch (action) {
        case Action.ACTIVE:
            dispatch({ type: EVENT_OPTIONS_ACTIVE, payload: data.payload});
            break;
        case Action.DEACTIVE:
            dispatch({ type: EVENT_OPTIONS_DEACTIVE, payload: data.payload});
            break;
    }

}

const sessionEvent = (dispatch) => (data, slug) => {
    const action = data.action
    switch (action) {
        case Action.UPDATE:
            dispatch({ type: SESSION_UPDATE, payload: data.payload});
            break;
        // case Action.CREATE:
        //     dispatch({ type: SESSION_CREATE, payload: data.payload});
        //     break;
    }
}

const tickerEvent = (dispatch) => (data) => {
    const action = data.action
    const ticker = {...data.payload}
    delete ticker['indexes']
    const indexes = data.payload.indexes

    switch (action) {
        case Action.UPDATE:
            dispatch({ type: UPDATE_TICKER_PUSHER, payload: {ticker: ticker, indexes: indexes}});
            break;
        // case Action.CREATE:
        //     dispatch({ type: SESSION_CREATE, payload: data.payload});
        //     break;
    }
}

const updateChannel = (dispatch) => (data) => {
    dispatch({type: UPDATE_LIVE_BADGE, payload: {event_channel_id: "shared", type: LiveBadgeType.CHANNELS}})
    dispatch({ type: UPDATE_CHANNEL, payload: data.payload});
}
const interactionEvent = (dispatch) => (data, slug) => {
    EventHandler(data,
        {
            chatEvent: (data) => chatEvent(dispatch)(data, slug),
            pollEvent: (data) => pollEvent(dispatch)(data, slug),
            ratingEvent: (data) => ratingEvent(dispatch)(data, slug),
            voteEvent: (data) => voteEvent(dispatch)(data, slug),
            eventOption: (data) => eventOption(dispatch)(data, slug),
            sessionEvent: (data) => sessionEvent(dispatch)(data, slug),
            updateChannel: (data) => updateChannel(dispatch)(data),
            tickerEvent: (data) => tickerEvent(dispatch)(data),
        })
}

const userInteractionEvent = (dispatch) => (data) => {
    EventHandler(data,
        {
            qaAnswerEvent: (data) => qaAnswerEvent(dispatch)(data),
            callingEvent: (data) => callingEvent(dispatch)(data),
            o2oChatEvent: (data) => o2oChatEvent(dispatch)(data),
            appointmentUpdate: (data) => appointmentUpdate(dispatch)(data),
            chatEvent: (data) => chatEventBlockUnblock(dispatch)(data),
            connectionEvent: (data) => connectionEvent(dispatch)(data),

        })
}

const connectionEvent = (dispatch) => (data) => {
    const action = data.action
    switch (action) {
        case Action.CREATE:
            NotificationCenter.sharedInstance().broadcastNotification('connectionEvent', data.payload)
            NotificationCenter.sharedInstance().broadcastNotification('connectionStatusUpdate', data.payload)
            dispatch({ type: PUSHER_STATUS_CONNECTION, payload: data});
            break;
        case Action.ACCEPTED:
            NotificationCenter.sharedInstance().broadcastNotification('connectionStatusUpdate', data.payload);
            break;
        case Action.DECLINED:
            NotificationCenter.sharedInstance().broadcastNotification('connectionStatusUpdate', data.payload);
            break;
    }
}

const chatEventBlockUnblock = (dispatch) => (data) => {
    const action = data.action
    switch (action) {
        case Action.BLOCKED:
            dispatch({ type: CHAT_BLOCK, payload: data});
            break;
        case Action.UNBLOCKED:
            dispatch({ type: CHAT_UNBLOCK, payload: data});
            break;
    }
}
const appointmentUpdate = (dispatch) => (data) => {
    const action = data.action
    switch (action) {
        case Action.UPDATE:
            dispatch({type: PUSHER_APPOINTMENT_UPDATE, payload: {appoinment: data.payload}})
            break;
        case Action.CREATE:
            dispatch({ type: PUSHER_APPOINTMENT_ADD, payload: {appoinment: data.payload} });
            break;
    }


}

const o2oChatEvent = (dispatch) => (data) => {
    const action = data.action
    switch (action) {
        case Action.CREATE:

            dispatch({type: ONE_TO_ONE_CHAT_RECIEVED, payload: data.payload})
            break;
    }
}


const callingEvent = (dispatch) => (data) => {
    const {slug, event, videoChatState, videoChat} = SharedVideoChatState.instance().getObject()
    const action = data.action
    if (videoChatState !== VideoChatState.DISCONNECTED && action !== Action.ACCEPT && action !== Action.DECLINE) return;
    const match_id = data.payload?.match_id
    registerPuserEvents(dispatch)(match_id);
    switch (action) {
        case Action.CREATE:
            dispatch({
                type: SHOW_INCOMING_CALL,
                payload: { ...data?.payload, requestedChat: true },
            })
            break;
        case Action.ACCEPT:
            case Action.DECLINE:
            if (!window.recentTab){
                resetVCState(dispatch)(VideoChatState.DISCONNECTED);
            }
            break;
    }
}
const pollEvent = (dispatch) => (data, slug) => {
    const action = data.action
    const payload = {...data.payload}
    switch (action) {
        case Action.LIVE:
            NotificationCenter.sharedInstance().broadcastNotification('pollLive', payload)
            if (payload.live){
                dispatch({type: UPDATE_LIVE_BADGE, payload: {...payload, type: LiveBadgeType.POLL}})
            }
            getPoll(dispatch)(slug, payload)
            break;
    }
}
const ratingEvent = (dispatch) => (data, slug) => {
    const action = data.action
    const payload = {...data.payload}
    switch (action) {
        case Action.LIVE:
            NotificationCenter.sharedInstance().broadcastNotification('ratingLive', payload)
            if (payload.live){
                dispatch({type: UPDATE_LIVE_BADGE, payload: {...payload, type: LiveBadgeType.RATING}})
            }
            getRatings(dispatch)(slug, payload)
            break;
    }
}
const qaAnswerEvent = (dispatch) => (data) => {
    const action = data.action
    const payload = {...data.payload}

    switch (action) {
        case Action.CREATE:
            NotificationCenter.sharedInstance().broadcastNotification('qaLive', payload)
            dispatch({type: UPDATE_LIVE_BADGE, payload: {...payload, type: LiveBadgeType.QA}})
            dispatch({type: QA_ANSWER_CREATE, payload: {...payload, isEvent: true}})
            break;
    }
}
const chatEvent = (dispatch) => (data) => {
    const action = data.action
    const payload = {...data.payload}
    NotificationCenter.sharedInstance().broadcastNotification('chatEvent', payload)
    switch (action) {
        case Action.CREATE:
            dispatch({type: CHAT_CREATE, payload: {...payload}})
            dispatch({type: UPDATE_LIVE_BADGE, payload: {...payload, type: LiveBadgeType.CHAT}})
            break;
        case Action.DELETE:
            dispatch({type: CHAT_DELETE, payload: {...payload}})
            dispatch({type: UPDATE_LIVE_BADGE, payload: {...payload, type: LiveBadgeType.CHAT}})
            break;
    }
}
const voteEvent = (dispatch) => (data, slug) => {
    const action = data.action;
    const payload = {...data.payload}
    switch (action) {
        case Action.LIVE:
            NotificationCenter.sharedInstance().broadcastNotification('voteLive', data.payload)
            if (payload.live) {
                dispatch({type: UPDATE_LIVE_BADGE, payload: {...data.payload, type: LiveBadgeType.VOTE}})
            }
            getVotes(dispatch)(slug, data)
            break;
    }
}


const getPoll = (dispatch) => (slug, data) => {
    const url = `${slug.path}/poll?event_channel_id=${data?.event_channel_id ?? ""}`;
    DBNetwork.get(url)
        .then(response => {
            dispatch({ type: GET_POLL, payload: {...response?.data?.data, event_channel_id: data.event_channel_id} })
        })
        .catch(HandlerWrapper( dispatch));
}

const getVotes = (dispatch) => (slug, data) => {
    const url = `${slug.path}/votes?event_channel_id=${data?.event_channel_id ?? ""}`;
    DBNetwork.get(url)
        .then(response => {
            dispatch({ type: GET_VOTES, payload: {...response?.data?.data ?? {}, event_channel_id: data.event_channel_id} })
        })
        .catch(HandlerWrapper( dispatch));
}

const getRatings = (dispatch) => (slug, payload) => {
    const url = `${slug.path}/ratings?event_channel_id=${payload?.event_channel_id ?? ""}`;
    DBNetwork.get(url)
        .then(response => {
            dispatch({ type: GET_RATINGS, payload: {...response?.data?.data, event_channel_id: payload.event_channel_id}})
        })
        .catch(HandlerWrapper( dispatch));
}
const seenParticipantChat = (dispatch) => (slug, {participant_id}) => {
    const url = `${slug.path}/seen-participant-chat`;
    DBNetwork.post(url, {participant_id})
        .then(response => {
            dispatch({ type: RESET_NETWORKING_BADGE, payload: {type: NetworkingBadgeType.CHAT, participant_id} });
        })
        .catch(HandlerWrapper( dispatch));
}
const seenNetworkingChat = (dispatch) => (slug, type) => {
    const url = `${slug.path}/seen-networking`;
    DBNetwork.post(url, {status: type})
        .then(response => {
            dispatch({ type: RESET_NETWORKING_BADGE, payload: {type} });
        })
        .catch(HandlerWrapper( dispatch));
}


const getChatMessages = (dispatch) => (slug, payload, callback) => {
    const urlUtils = URLBuilder(`${slug.path}/chat-messages`);
    urlUtils.addURLParam("event_channel_id", payload?.event_channel_id);
    urlUtils.addURLParam("message_id", payload.message_id);

    DBNetwork.get(urlUtils.getURL())
        .then(response => {
            dispatch({ type: GET_MESSAGES, payload: response?.data?.data})
            callback(response, null)
        })
        .catch(HandlerWrapper( dispatch, callback));
}


    // /v2.0/api/{client_slug}/{event_slug}/seen-participant-chat
//******************************** On Event Page Socket Updates ********************************
const onUserLoggedIn = (dispatch) => async (data) => {
    await dispatch({ type: ON_USER_LOGIN, payload: data });
};
const onRegisterSuccess = (dispatch) => async (data) => {
    await dispatch({ type: ON_USER_REGISTER, payload: data });
};

//******************************** fetch Events Page Data ********************************88

// Get Sponser Data
const getSponserData = (dispatch) => async (slug, callback) => {
    const url = slug+"/sponsors";
    DBNetwork.get(url)
        .then(response => { dispatch({ type: GET_SPONSER, payload: response?.data?.data })
                callback && callback(response, null)
        })
        .catch(HandlerWrapper(dispatch));
};

const getSponserDetails = (dispatch) => async (slug, callback) => {
    DBNetwork.get(slug)
        .then(response => {  dispatch({ type: GET_SPONSER_DETAILS, payload: response?.data?.data })
            callback && callback(response, null)
        })
        .catch(HandlerWrapper(dispatch));
};

// Get Slide Decks Data
const getSlideDecksData = (dispatch) => async (slug, callback) => {
    const url = slug+"/slide-decks";
    DBNetwork.get(url)
        .then(response => { dispatch({ type:GET_SLIDE_DECKS, payload: response.data?.data }) 
            callback && callback(response, null)
        })
        .catch(HandlerWrapper(dispatch));
};

// Get Vod  Data
const getVodData = (dispatch) => async (slug, callback) => {
    const url = slug+'/vods';
    DBNetwork.get(url)
        .then(response => {
            dispatch({ type: GET_VOD, payload: response?.data?.data })
            callback && callback(response, null)
        })
        .catch(HandlerWrapper( dispatch, (error) => callback && callback(null, error)));
};

const getVodDetails =  (dispatch) => async (slug, callback) => {
    DBNetwork.get(slug)
        .then(response => {
            dispatch({ type: GET_VOD_DETAILS, payload: response?.data?.data })
            callback && callback(response, null)
        })
        .catch(HandlerWrapper( dispatch, (error) => callback && callback(null, error)));
};

// Get Session Data
const getSessionsData = (dispatch) => async (slug, callback) => {
    const url = slug+'/sessions';
    DBNetwork.get(url)
        .then(response => { 
            dispatch({ type: GET_SESSIONS, payload: response?.data?.data})
            callback(response, null)
        })
        .catch(HandlerWrapper(dispatch, (error) => callback && callback(null, error)));
};

// Get Session Data
const getSessionsSpeaker = (dispatch) => async (slug, session_id, callback) => {
    const url = slug+'/sessions-speakers/' + session_id;
    DBNetwork.get(url)
        .then(response => {
            dispatch({ type: GET_SESSIONS_SPEAKERS, payload: {...response?.data?.data, id: session_id}})
            callback(response, null)
        })
        .catch(HandlerWrapper(dispatch, (error) => callback && callback(null, error)));
};


const getDailyTriviaData = (dispatch) => async (slug, callback) => {
    const url = slug+'/daily-trivia';
    DBNetwork.get(url)
        .then(response => {
            dispatch({ type: GET_DAILY_TRIVIA, payload: response?.data?.data})
            callback(response, null)
        })
        .catch(HandlerWrapper(dispatch, (error) => callback && callback(null, error)));
};

// Get BreakOut Rooms Data
const getBreakOutRoomsData = (dispatch) => async (slug) => {
    const url = slug+'/breakouts';
    DBNetwork.get(url)
        .then(response => dispatch({ type: GET_BREAKOUT_ROOMS, payload: response?.data?.data}))
        .catch(HandlerWrapper(dispatch));
};

//Get Speaker Details
const getSpeakerDetails = (dispatch) => async ({slug , current_page, char, search}, callback) => {
    const object = {}
    if (current_page)
    {
        object.page = current_page
    }
    if (char){
        object.char = char
    }
    if (search){
        object.search = search
    }
    const searchParams = new URLSearchParams(object);
    const url = slug+'/speakers?' +  searchParams.toString();
    DBNetwork.get(url)
    .then(response => {
            dispatch({ type: GET_SPEAKERS, payload: response?.data.data}) 
           callback(response, null)
    })
    .catch(HandlerWrapper(dispatch, (error) => callback && callback(null, error)));
}

//set set Favoirte
const setFavoriteData = (dispatch) => async (slug, {type, id}, callback) => {
    try{
        const url = slug+'/wishlist';
        const response = await DBNetwork.post(url, {type: type, module_id: id});
        dispatch({ type: SET_WISHLIST, payload: response?.data?.data})
        callback && callback(response, null);
    }catch (error) { 
        callback && callback(null, error);
    }
}

const getChannels = (dispatch) => async (slug, callback) => {
    const url = slug+'/channels';
    DBNetwork.get(url)
        // .then(response => console.log(`[DefaultSectionContext][getChannels] ${JSON.stringify(response)}`) )
        .then(response => dispatch({ type: GET_CHANNELS_V2, payload: response?.data?.data}))
        .catch(HandlerWrapper(dispatch, (error) => callback && callback(null, error)));
};

const getChannelDetails = (dispatch) => async (slug, id, callback) => {
    const url = slug+'/channels/'+ id;
    DBNetwork.get(url)
        .then(response => dispatch({ type: GET_CHANNELS_DETAILS_V2, payload: response?.data?.data}))
        .catch(HandlerWrapper(dispatch));
};

const sendChat = (dispatch) => (slug, {message, channel_id}, callback) => {
    const url = slug + "/send-chat"
    DBNetwork.post(url, {message: message, event_channel_id: channel_id})
        .then(response => {
            dispatch({type: CHAT_CREATE, payload:{...response?.data?.data?.message, moderate_message: response?.data?.data?.moderate_message}})
            callback(response, null)})
        .catch(HandlerWrapper(dispatch, (e) => callback(null, e)))
}

export const networking_sendVideoChatMessage = (dispatch) => async (slug, state, {msg}, callback) => {
    if (msg !== "" && state?.videoChat.match_id) {
        try {
            const url = slug.path + "/video-chat/chat/" + state?.videoChat.match_id;
            const response = await DBNetwork.post(url, { msg });
            callback(response, null);
        } catch (error) {
            callback(null, error);
        }
    }
};
const sendQuestion = (dispatch) => (slug, {question, channel_id}, callback) => {
    const url = slug + "/send-question"
    DBNetwork.post(url, {question: question, event_channel_id: channel_id})
        .then(response => {
            dispatch({ type: SEND_QUESTION, payload: response?.data?.data?.question})
            callback(response, null)
        })
        .catch(HandlerWrapper(dispatch, (e) => callback(null, e)))
}
const sendAnswer = (dispatch) => (slug, {question_id, answer, channel_id}, callback) => {
    const url = slug + "/send-answer"
    DBNetwork.post(url, {question_id, answer, event_channel_id: channel_id})
        .then(response => {
            dispatch({ type: SEND_ANSWER, payload: {...response?.data?.data?.answer ?? {}, event_channel_id: channel_id, question_id}})
            callback(response, null)
        })
        .catch(HandlerWrapper(dispatch, (e) => callback(null, e)))
}
const sendPollAnswer = (dispatch) => (slug, {question_id, option_id, channel_id}, callback) => {
    const url = slug + "/send-poll-answer"
    DBNetwork.post(url, {question_id, option_id, event_channel_id: channel_id})
        .then(response => {
            callback(response, null)
            dispatch({ type: SEND_POLL_ANSWER, payload: {...response?.data?.data ?? {}, event_channel_id: channel_id, question_id}})
        })
        .catch(HandlerWrapper(dispatch, (e) => callback(null, e)))
}
const sendRating = (dispatch) => (slug, {star, question_id, review, channel_id}, callback) => {
    const url = slug + "/send-rating-answer"
    DBNetwork.post(url, {star, question_id, review, event_channel_id: channel_id})
        .then(response => {
            callback(response, null)
            dispatch({ type: SEND_RATING_ANSWER, payload: response?.data?.data?.question})
        })
        .catch(HandlerWrapper(dispatch, (e) => callback(null, e)))
}
const sendVote = (dispatch) => (slug, {star, option_id, question_id, channel_id}, callback) => {
    const url = slug + "/send-vote-answer"
    DBNetwork.post(url, {option_id, question_id, event_channel_id: channel_id})
        .then(response => {
            callback(response, null)
            dispatch({ type: SEND_VOTE_ANSWER, payload: response?.data?.data?.question})
        })
        .catch(HandlerWrapper(dispatch, (e) => callback(null, e)))
}



const onlineUsers = (dispatch) => (data) => {
    dispatch({ type: PUSHER_ONLINE_MEMBERS, payload: data });
    // alert(JSON.stringify(data))
};
const memberAdd = (dispatch) => (data) => {
    // alert(JSON.stringify(data))
    dispatch({ type: PUSHER_ADD_MEMBERS, payload: data });
};
const memberRemove = (dispatch) => (data) => {
    // alert(JSON.stringify(data))
    dispatch({ type: PUSHER_REMOVE_MEMBERS, payload: data });
};

// NetWorking Participant User
const getParticipantUser = (dispatch) => async (slug, id, callback) => {
    const url = slug+'/users/'+ id;
    DBNetwork.get(url)
    .then(response =>{ 
        dispatch({ type: GET_PARTICIPANT_USER, payload: response?.data?.data}) 
        callback(response, null)
    })
    .catch(HandlerWrapper(dispatch, (e) => callback(null, e)))
}

const getNetworkPeople = (dispatch) => async (slug,{current_page,char, search, category},  callback) => {
    const object = {}
    if (current_page){
        object.page = current_page
    }
    if (char){
        object.char = char
    }
    if (search){
        object.search = search
    }
    if (category){
        object.category = category
    }
    const searchParams = new URLSearchParams(object);
    const url = slug+'/network?' +  searchParams.toString();
    DBNetwork.get(url)
        .then(response =>{ 
            dispatch({ type: GET_NETWORK_PEOPLE, payload: response?.data?.data}) 
            callback(response, null)
        })
        .catch(HandlerWrapper(dispatch, (e) => callback(null, e)))
};


const registerPuserEvents = (dispatch) => async (match_id) => {
    PusherWrapper.shared.unregisterNetworkToolChannels();
    PusherWrapper.shared.registerNetworkingTool(match_id, {
        videoChatDeclineEvent: (data) => networking_videoChatDeclineEvent(dispatch)(data),
        videoChatEndEvent: (data) => networking_videoChatEndEvent(dispatch)(data),
        videoChatMessageEvent: (data) => networking_videoChatMessageEvent(dispatch)(data),
        videoChatRejectEvent: (data) => networking_videoChatRejectEvent(dispatch)(data)
    });
};
const networking_videoChatMessageEvent = (dispatch) => async (data) => {
    NotificationCenter.sharedInstance().broadcastNotification(
        "receivedMessages",
        data
    );
    dispatch({ type: VIDEO_CHAT_MESSAGE_RECIEVED, payload: data });
};

const networking_videoChatRejectEvent = (dispatch) => async (data) => {
    NotificationCenter.sharedInstance().broadcastNotification(
        "videoChatReject",
        data
    );
    dispatch({ type: VIDEO_CHAT_REJECT, payload: data });
};
export const networking_videoChatDeclineEvent = (dispatch) => async (data) => {
    const {slug, event, videoChatState} = SharedVideoChatState.instance().getObject()
    if (videoChatState === VideoChatState.DISCONNECTING || videoChatState === VideoChatState.DISCONNECTED )return
    const match_id = data.match_id;

    NotificationCenter.sharedInstance().broadcastNotification(
        "videoChatDeclineEvent",
        data
    );
    networking_endChat(dispatch)(slug, {match_id, videoChatState, videoChat: data}, {}, (res, err) => {});
    // resetVCState(dispatch)();
};

const networking_gotMatch =  (dispatch) => async (slug, state, callback) => {
    if (!state?.videoChat?.match_id)return;

    const url =`${slug.path}/video-chat/match/${state?.videoChat?.match_id}`;
    const response = await DBNetwork.get(url);
    callback && callback(response, null)
    dispatch({
        type: GOT_MATCH,
        payload: { ...response.data, match_id: state?.videoChat?.match_id },
    });
}
export const networking_fetchMatchedUser = (dispatch) => async (slug, state, callback) => {
    if (!state?.videoChat?.match_id)return;
    const url = `${slug.path}/video-chat/match/${state?.videoChat?.match_id}`;
    const response = await DBNetwork.get(url);
    dispatch({ type: FETCH_MATCHED, payload: { ...response.data.data } });
    callback(response);
};

const networking_declineMatch =  (dispatch) => async (slug, state, {match_id}, callback) => {
    const {event, videoChatState} = SharedVideoChatState.instance().getObject()
    if (videoChatState === VideoChatState.DISCONNECTING)return
    try {
        const url = `${slug.path}/video-chat/decline/${match_id}`;
        const respone = await DBNetwork.get(url);
        resetVCState(dispatch)(VideoChatState.DISCONNECTED);
        callback && callback(respone, null)
    }catch (error) {
        callback && callback(null, error)
    }
}
const networking_videoConnect =  (dispatch) => async (slug, state, {user_id, dice_matched, first_message}, callback) => {
    try {
    const diceMatchedParam = dice_matched ? "?match=" + dice_matched : ""
    const url = `${slug.path}/video-chat/global/${user_id}${diceMatchedParam}`;
    const body = first_message ? {first_message} : null
    const response = await DBNetwork.post(url, body);
    const match_id = response?.data?.data?.match_id;
    // await networking_gotMatch(dispatch)(slug, state, {match_id})
        callback && callback(response, null)
        await networking_videoChatAccept(dispatch)(slug, state, {match_id});
    } catch (error) {
        // dispatch({
        //     type: SET_VIDEO_CHAT_STATE,
        //     payload: VideoChatState.DISCONNECTED,
        // });
        callback && callback(null, error);
    }


}
const networking_videoChatAccept =  (dispatch) => async (slug, state, {match_id}, callback) => {
    const url = `${slug.path}/video-chat/accept/${match_id}`;
    const response = await DBNetwork.get(url);
    // registerPuserEvents(dispatch)(match_id);
    dispatch({ type: VIDEO_CHAT_ACCEPT, payload: {...response.data, match_id }});
    callback && callback(response, null)
}
const networking_check_for_dice_open =  (dispatch) => (slug, state, object) => {
    // Logger.debugLog(" args : " + object.ID)
}
const networking_endChat =  (dispatch) => async (slug, state, {}, callback) => {
    window.recentTab = false
    const currentState = SharedVideoChatState.instance().getVideoChatState();
    const isDisconnectedOrDisconnecting =  currentState === VideoChatState.DISCONNECTED || currentState === VideoChatState.DISCONNECTING
    const match_id = state.videoChat?.match_id;
    if (isDisconnectedOrDisconnecting && !match_id) return;

    setVideoState(dispatch)(VideoChatState.DISCONNECTING);
    const url = `${slug.path}/video-chat/end-chat/${match_id}`;
    const response = await DBNetwork.get(url);
    NotificationCenter.sharedInstance().broadcastNotification(
        "endChatRequest",
        response.data
    );
    resetVCState(dispatch)(VideoChatState.DISCONNECTED);
    PusherWrapper.sharedInstance().unregisterNetworkToolChannels();
    callback && callback(response)
}

const networking_rollDice =  (dispatch) => async (slug, state, callback) => {
    try {
        dispatch({type: DICE_MATCHEDED_CLEAR})
        const url = `${slug.path}/video-chat/roll-dice`;
        const response = await DBNetwork.get(url);
        dispatch({type: DICE_MATCHEDED, payload: response})
        callback && callback(response, null)
    } catch (error) {
        dispatch({type: DICE_MATCHEDED_CLEAR})
        callback && callback(null, error)
    }
}

const networking_rollDiceInterestUpdate =  (dispatch) => (slug, state, object) => {
    // Logger.debugLog(" args : " + object.ID)
}

export const rollDiceInterestUpdate = (dispatch) => async (slug, dice_list, callback) => {
    try {
     const url = `${slug.path}/video-chat/users/interests`; 
      const response = await DBNetwork.post(url,{"interests":dice_list});

        dispatch({ type: UPDATE_INTEREST, payload: response.data.data});

        callback && callback(response, null)

    } catch (error) {
      callback && callback(null, error)
    }
};


export const networking_videoChatEndEvent = (dispatch) => async (data) => {
    const {slug, event, videoChatState} = SharedVideoChatState.instance().getObject()
    if (videoChatState === VideoChatState.DISCONNECTING || videoChatState === VideoChatState.DISCONNECTED )return

    const match_id = data.match_id;
    networking_endChat(dispatch)(slug,  {match_id, videoChatState, videoChat: data}, {}, (res, err) => {});
};
export const networking_addUserToVideoChat =
    (dispatch) => async (slug, state, {user_id}, callback) => {
    const match_id = state?.videoChat?.match_id
        try {
            const url = `${slug.path}/video-chat/add-user/${match_id}/${user_id}`;
            const response = await DBNetwork.get(url);
            dispatch({ type: ADD_USER_TO_VIDEOCHAT, payload: {...response.data.data, user_id} });
            callback(response, null);
        } catch (error) {
            callback(null, error);
        }
    };

export const setVideoState = (dispatch) => async (state) => {
    dispatch({ type: SET_VIDEO_CHAT_STATE, payload: state });
};

export const resetVCState = (dispatch) => async (videoChatState) => {
    dispatch({
        type: RESET_VC_STATE,
        payload: { videoChatState: videoChatState },
    });
};

export const enrollSession = (dispatch) => async ({slug, session_id}, callback) => {

    try {
        const url = `${slug.path}/sessions/${session_id}/register`;
        const response = await DBNetwork.post(url);
        dispatch({ type: ENROLL_SESSION, payload: {...response.data.data} });
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
};

export const unEnrollSession = (dispatch) => async ({slug, session_id}, callback) => {

    try {
        const url = `${slug.path}/sessions/${session_id}/unregister`;
        const response = await DBNetwork.post(url);
        dispatch({ type: ENROLL_SESSION, payload: {...response.data.data} });
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
};

export const fetchParticipantChat = (dispatch) => async ({slug, participant_id, message_id}, callback) => {
    try {
        const urlUtils = URLBuilder(`${slug.path}/fetch-participant-chat`);
        urlUtils.addURLParam("participant_id", participant_id);
        urlUtils.addURLParam("message_id", message_id);
        const response = await DBNetwork.get(urlUtils.getURL());
        dispatch({ type: FETCH_O_2_O, payload: {...response.data.data, participant_id} });
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
}
export const prefetchOneToOneChat = (dispatch) => async ({slug}, callback) => {
    try {
        const url = `${slug.path}/prefetch-o2o-chat`;
        const response = await DBNetwork.get(url);
        dispatch({ type: PRE_FETCH_O_2_O, payload: {...response.data.data} });
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
}
export  const sendOneToOneChat = (dispatch) => async ({slug, message, receiver_id}, callback) => {
    try {
        const url = `${slug.path}/send-one-to-one`;
        const response = await DBNetwork.post(url, {message, receiver_id});
        dispatch({ type: SEND_O_2_O, payload: {...response.data.data, participant_id:receiver_id} });
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
}

export const makeAppointment = (dispatch) => async (slug ,appointment, callback) => {
    try {
      const url = slug+"/make-appointment";
      const response = await DBNetwork.post(url, appointment);
      dispatch({ type: ADD_APPOINTMENT, payload: response?.data?.data });
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
  };

  export const updateAppointment = (dispatch) => async (slug ,appointment, callback) => {
    try {
      const url = slug + "/update-appointment/" + appointment?.id;
      const response = await DBNetwork.post(url, {
        user_id: appointment.user_id,
        date: appointment.date,
        status: appointment.status
      });
      dispatch({ type: UPDATE_APPOINTMENT, payload: response?.data?.data });
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
  };

export const getFaqDetails = (dispatch) => async (slug, callback) => {
    try {
        const url = slug+"/faqs";
        const response = await DBNetwork.get(url);
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
  };
  
export const resetLiveBadge = (dispatch) => (type) => {
    dispatch({ type: RESET_LIVE_BADGE, payload: type });
}

export const resetNetworkingBadge = (dispatch) => (type, slug, object, callback) => {
    if (type === NetworkingBadgeType.MEETINGS){
        seenNetworkingChat(dispatch)(slug, type, callback)

    }else if (type === NetworkingBadgeType.CHAT){
        seenParticipantChat(dispatch)(slug, {participant_id: object?.participant_id}, callback)

    }else if(type === NetworkingBadgeType.PENDING_MEETING){
        seenNetworkingChat(dispatch)(slug, type, callback)

    }else if(type === NetworkingBadgeType.ACCEPTED_MEETING){
        seenNetworkingChat(dispatch)(slug, type, callback)
    }
}

const updateProfile = (dispatch) => async (slug, data, callback) => {
    const response = await DBNetwork.post(slug.path+"/user-update", { ...data });
    callback(response);
    await dispatch({ type: ON_USER_LOGIN, payload: response.data.data });
};

export const getCarousels = (dispatch) => async (slug, callback) => {
    try {
        const url = slug+"/carousels";
        const response = await DBNetwork.get(url);
        callback && callback(response, null);
    } catch (error) {
        callback && callback(null, error);
    }
};

const getConnectionPeople = (dispatch) => async (slug, category, callback) => {
    const url = slug +'/connections?category='+category;
    DBNetwork.get(url)
        .then(response => {
             dispatch({ type: GET_CONNECTION_PEOPLE, payload: response?.data?.data})
             callback(response, null)
        })
        .catch(HandlerWrapper(dispatch, (error) => callback && callback(null, error)));
};

export const connectionRequest = (dispatch) => async (slug, user_id, callback) => {
    const url = slug +"/connect/" + user_id;
    DBNetwork.post(url)
    .then(response => {
            dispatch({ type: GET_CONNECTION, payload: response?.data.data}) 
            callback(response, null)
    })
    .catch(HandlerWrapper(dispatch, (error) => callback && callback(null, error)));
};

export const approvingConnection = (dispatch) => async (slug, connect_id, status, callback) => {
    const url = slug +"/connect/" +connect_id+ "/approve-disapprove";
    DBNetwork.post(url,{status:status})
    .then(response => {
            dispatch({type: STATUS_CONNECTION, payload: response?.data.data}) 
            callback(response, null)
    })
    .catch(HandlerWrapper(dispatch, (error) => callback && callback(null, error)));
};

const getUserSettings = (dispatch) => async (slug, property, value, callback) => {
    const url = slug +'/user-setting';
    DBNetwork.post(url, {property:property, value:value})
        .then(response => {
            dispatch({ type: GET_USER_SETTING, payload: response?.data?.data}) 
            callback(response, null)
        })
        .catch(HandlerWrapper(dispatch));
};

//********************************* Context and Provider //***********************************************
export const { Context, Provider } = createDataContext(
    // Reducer Function
    authReducer,
    {
        // Dispatch Functions
        //V2
        fetchEventsPage,
        getSponserData,
        getSponserDetails,
        getSlideDecksData,
        getVodData,
        getVodDetails,
        getSessionsData,
        getDailyTriviaData,
        getBreakOutRoomsData,
        getOrganizationEvents,
        getChannels,
        getChannelDetails,
        getNetworkPeople,
        getParticipantUser,
        getSpeakerDetails,
        sendChat,
        sendQuestion,
        sendAnswer,
        sendPollAnswer,
        sendRating,
        sendVote,
        //V1
        onUserLoggedIn,
        onRegisterSuccess,
        updateUserDetails,
        enrollSession,
        unEnrollSession,

        networking_gotMatch,
        networking_declineMatch,
        networking_videoConnect,
        networking_videoChatAccept,
        networking_check_for_dice_open,
        networking_endChat,
        networking_rollDice,
        rollDiceInterestUpdate,
        networking_rollDiceInterestUpdate,
        networking_addUserToVideoChat,
        networking_fetchMatchedUser,
        networking_sendVideoChatMessage,
        //UIState
        
        // connection 
        getConnectionPeople,
        connectionRequest,
        approvingConnection,

        //one to one chat requests
        setSelectedChannel,
        sendOneToOneChat,
        fetchParticipantChat,
        prefetchOneToOneChat,
        setFavoriteData,
        // Schedule appointment
        makeAppointment,
        updateAppointment,
        getFaqDetails,
        resetLiveBadge,
        resetNetworkingBadge,
        seenParticipantChat,
        getChatMessages,
        updateProfile,
        getCarousels,
        getSessionsSpeaker,
        getUserSettings,
        logout,

        //subscribe
        subscribeChannel,
        unsubscribeChannel
    },
    // States
    initialState

);




/*{
            chat: {},
            vote: {
                // shared: 10
            },

            poll: {
                // 6: 5,
                // 7: 5,
            },
            qa: {
                // 6: 5,
                // 7: 5,
            },
            rating: {
                // shared: 20
            }
        }

 */