import React,{useContext} from 'react';
import OwlCarousel from 'react-owl-carousel';
import WebNavbar from '../Navbar/WebNavbar';
import BannerSlider from './BannerSlider';
import { ShimmerThumbnail } from "react-shimmer-effects";
import { EventApi } from '../../../../../hooks/shared/EventApi';

const WebBanner = ({sliders, isLoading}) => {
    const isProfilePage = window.location.toString().includes("profile");
    const {
        state: {
            details,
            options,
        }
    } = EventApi();

    const getRandomAnimation = () => {
        var animationList = ['flipInX', 'zoomIn']; 
        return animationList[Math.floor(Math.random() * animationList?.length)];
    }
    
    let owlOptions = {
        items:1,
        loop:details?.slider_loop,
        autoPlay:sliders?.length > 1 ? true : false,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        merge:false,
        dots:false,
        nav:false,
        animateOut:details?.slider_randomize ? getRandomAnimation() : false,
        animateIn:details?.slider_randomize ? getRandomAnimation() : false,
    }

    return (
        <>
           {/* START BANNER */} 
           <section className="banner_area">
               <div className="container">
                   {!isProfilePage && <div className="banner_inner">
                           {!isLoading && sliders ? (
                                <div className={`${options?.includes('slider') ? "" : "d-none"}`}>
                                    <OwlCarousel className='owl-carousel owl-theme' {...owlOptions}>
                                        <BannerSlider sliders={sliders}/>
                                    </OwlCarousel>
                                </div>
                            ) : (<ShimmerThumbnail height={750} center />)}
                    </div>}
                    <WebNavbar/>
                </div>
            </section>
            {/* END BANNER */} 
        </>
    )
}

export default WebBanner;
