import React, {useContext, useState} from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { imageWithStorage } from '../../../../../../constants/url/urlConstants';
import { Image } from '../../../../../../UIComponets/Image';
import { 
    LinkIcon,
    WifiIcon,
    FavoriteIcon
 } from '../../../../../../SVG/Icons';
import { FaRegFile } from "react-icons/fa";
import ReactHtmlParser from 'react-html-parser';
import {SessionModel} from "../../../../../../Model/SessionModel";
import { SessionStatus } from '../../../../../../Model/SesstionStatus';
import { IconLoader } from '../../../../../../UIComponets/IconLoader';
import SessionFilesModel from '../../ActiveSessions/SessionFilesModel';
import { Translations } from '../../../../../../hooks/shared/Translations';
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import {Error} from "../../../../../../Util/Utils";
import {useToasts} from "react-toast-notifications";
import useSlug from "../../../../../../hooks/useSlug";
import { SessionApiV2 } from '../../../../../../hooks/shared/SessionApi2';
import { URLContants } from '../../../../../../Model/URLContants';

const LoadingContainer = (session_id, isLoading) => (prev) =>{
    return {
        ...prev,
        [session_id]: isLoading
    }
}

const ProfileSessionList = ({registerSession, sessions_categories, setReplay}) => {
    const slug = useSlug();
    const Path = (url) => slug.absolutePath + url;
    const { addToast } = useToasts();
    const {
        setFavoriteData,
        getZoneTime,
        state: {
            event,
            details,
            options
        },
    } = SessionApiV2();

    const getTime = (time) => {
        return  getZoneTime(true, time)
    };

    const {
        translations
    } = Translations();

    const [loadingContainer, setLoadingContainer] = useState({})
    const [favLoadingContainer, setFavLoadingContainer] = useState({})

    const {
        unEnrollSession
    } = useContext(defaultContext);

    const mapRegisterCategory = sessions_categories?.reduce((prev, current) => {
        return {
            ...prev,
            [current?.id]: <h1 style={{background:current?.color}} className="mb-40">{current?.name.toUpperCase()}</h1>
        }
    }, {});

    const unEnrollSessionRequest = (session_id) => {
        setLoadingContainer(LoadingContainer(session_id, true))
        unEnrollSession({slug, session_id}, (response, error) => {
            if (response) {
                const wrapper = ResponseWrapper(response)
                addToast(wrapper.getMessage(), {...getOptions(ToastType.INFO)})
            }else if (error){
                addToast(Error(error).getResponseError(), {...getOptions(ToastType.ERROR)})
            }
            setLoadingContainer(LoadingContainer(session_id, false));
        })
    }

    const isLoading = (id) => loadingContainer[id]
    const isFavLoading = (id) => favLoadingContainer[id]

    const handleFavoirte = (id) => {
        setFavLoadingContainer(LoadingContainer(id, true))
        setFavoriteData({id}, (response, error) => {
            if (response?.data?.status) {

            }
            setFavLoadingContainer(LoadingContainer(id, false))
        });
    }

    const handleFilePopup = (id) => {
        let filseId =  document.getElementById('files_'+id);
        if (filseId.style.display === "none") {
            filseId.style.display = "block";
        } else {
            filseId.style.display = "none";
        }
    }

    const handleClose = (id) => {
        document.getElementById('files_'+id).style.display = "none";
    }



    return (
        <>
            {registerSession && registerSession.map(i => {
                 const session = SessionModel(i);
                 session.setRegistered(true)
                 return session
            }).map((register, index) => {
            return (
            <div className="session_accordion_box mb-20" key={index}>
                <div className="accordion destop_ed_time" id="accordionExample">
                    <div className="session_accordion_header">
                        <div className="date">
                            <p>
                                {getTime(register?.start)}-{getTime(register?.end)}<br />
                                { moment(`${register?.date}`).format("ddd DD MMM") }
                            </p>
                        </div>
                        <div className="description collapse">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={"#collapseOne_" + register?.id} aria-expanded="true" aria-controls="collapseOne">
                                <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                                <h4>{register?.title}</h4>
                                {/*{register.is_live === 1 &&  <WifiIcon />}*/}
                            </button>
                            {register?.files && register?.files?.length > 0 ? (<Link to={"#"} className='session_link ml1' onClick={()=>handleFilePopup(register?.id)}><FaRegFile></FaRegFile></Link>) : ""}
                            <SessionFilesModel files={register?.files} id={register?.id} close={handleClose} event={event}/>
                            {register?.meta?.external_link ? (<a className='session_link' href={register?.meta?.external_link} target={register?.target}><LinkIcon/></a> ) : ""}
                            {register.getStatus() === SessionStatus.REGISTERED && (<a className='add-event-link ml-1' title="Add To Calendar"  href={`https://www.addevent.com/dir/?client=${process.env.REACT_APP_ADD_EVENT_CLIENTID}&start=${moment(register?.start,'DD-MM-YYYYTHH:mm:ss').format('DD-MM-YYYYTHH:mm:ss')}&end=${moment(register?.end,'DD-MM-YYYYTHH:mm:ss').format('DD-MM-YYYYTHH:mm:ss')}&title=${register?.title}&timezone=${event?.timezone}`} target="_blank"><i class="fa fa-calendar-plus"></i></a>)}
                        </div>
                    </div>
                    <div id={"collapseOne_"+ register?.id} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="session_info_box">
                            <div className="row">
                            <div className={`${register?.files?.length > 0  ? "" : "col-xl-12"  } col-md-12 col-lg-8`}>
                                <div className="emerging_sectors">
                            <div className="row">
                                {register?.people && register?.people.map((person , index) => {
                                return (
                                    <div className="col-md-6 col-lg-4 col-xl-4">
                                    <div className="client_info mb-30" key={index}>
                                        <div className="client_info_img">
                                            <Image src={person?.user?.avtar} placeholderSrc={"/images/accpted_img1.png"} alt={"people pic"}/>
                                        </div>
                                        <div className="client_info_content">
                                            <h4>
                                            <Link to={Path(URLContants.SPEAKERS)+`?search=${person?.user?.last_name}`}> {person?.user?.name} {person?.user?.last_name}</Link>
                                            </h4>
                                            <p>{person?.user?.title}</p>
                                            <h6>{person?.user?.company}</h6>
                                        </div>
                                    </div>
                                    </div>)
                                })}
                                </div>
                                    {mapRegisterCategory[register?.category_id]}
                                    <p>{ReactHtmlParser(register?.detail ?? '')}</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                            {register?.files?.length > 0 ? (
                            <div className="session_info_file">
                                    <h2>{translations?.general?.files}</h2>
                                        <ul className="list-group">
                                        {register?.files && register?.files.map((file, index)=>{
                                        return(
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="file_name">{file?.filename}</span>
                                                <a href={file && imageWithStorage+file?.path} target="_blank" rel="noopener noreferrer" download>
                                                    <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                                    <g id="Group_1947" data-name="Group 1947">
                                                    <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                                    <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                                    </g>
                                                    <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                                    </g>
                                                    </svg>
                                                </a>
                                        </li>
                                        )
                                        })}
                                </ul>
                            </div>) 
                            : null} 
                                {register?.files?.length > 1 && (
                                    <a href={`${process.env.REACT_APP_API_HOST}/v2.0/zip-download/${event?.id}?module_id=${register?.id}&type=timetable`} target="_blank" className="text-dark">
                                        <span className="mr-2">
                                            <svg id="Group_1896" data-name="Group 1896" xmlns="http://www.w3.org/2000/svg" width="16.009" height="15.301" viewBox="0 0 16.009 15.301">
                                            <g id="Group_1947" data-name="Group 1947">
                                            <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(16.009) rotate(90)">
                                            <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M14.2,17.641l6.053-6.058a1.139,1.139,0,0,1,1.616,0,1.154,1.154,0,0,1,0,1.621l-6.859,6.864a1.142,1.142,0,0,1-1.578.033L6.521,13.208a1.144,1.144,0,1,1,1.616-1.621Z" transform="translate(-11.246 22.197) rotate(-90)" fill="#88888d"/>
                                            </g>
                                            <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h13.987" transform="translate(-5190.933 -2976.057)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                            </g>
                                            </svg>
                                        </span>
                                        <span> Download All Files </span>
                                    </a>
                                )}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!register?.meta?.hide_action_button && (<div className="session_accordion_btn">
                    {register.getStatus() === SessionStatus.JOIN && (<a href={register?.link} target={register?.target}><button type="button" className="btn join">JOIN </button></a>)}
                    {register.getStatus() === SessionStatus.REGISTERED && (<button type="button" className="btn registered" onClick={() => unEnrollSessionRequest(register?.id)}><IconLoader isLoading={isLoading(register?.id)}> REGISTERED</IconLoader></button>)}
                    {register.getStatus() === SessionStatus.ENDED && (<button type="button" className="btn ended">ENDED</button>)}
                    {register.getStatus() === SessionStatus.REPLAY && (<button type="button" data-toggle="modal" data-target="#replay-btn-modal"  className="btn replay" onClick={() => setReplay({meta:register?.meta})}>REPLAY</button>)}
                </div>)}

                {options && options.includes('show_session_favourites') && <div className={`session_accordion_fav ${register?.meta?.hide_action_button ? 'fav-meta-add' : ''}`}>
                    <button type="button" className="btn" onClick={() => handleFavoirte(register?.id)}>
                        <IconLoader isLoading={favLoadingContainer[register?.id]}>
                            <FavoriteIcon color={register?.isWishlisted ? details?.primary_color : "none"} width="30" height="27.525" stroke={details?.primary_color}/>
                        </IconLoader>
                    </button>
    
                </div>
                }
            </div>
            )
            })}
        </>
    )
}

export default ProfileSessionList
