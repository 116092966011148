import CacheVersion from "./cacheVersion";
import {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import Location from "./Location";


const isMe = (id) => {
    const {user} = window.User.getDetails()
    return user.id === id
};

export default isMe;