import {FETCH_SUBCHANNEL_WHEN_MAIN_UNLIVE, LAYOUT_RENDER} from "../constants/ActionsConstants/actiontypes";
import createDataContext from "./createDataContext";
import DBNetwork from "../api/DBNetwork";
import {ResponseWrapper} from "../Model/ResponseWrapper";
import {TemplateDetails} from "../Model/TemplateDetails";
const LayoutReducer = (state, action) => {
  switch (action.type) {
    case LAYOUT_RENDER:
      return {
        ...state,
        layout: action.payload.data,
      };
    default:
      return state;
  }
};

export const getLayoutDetails = (dispatch) => async (slug, callback) => {
  try {
    const response = await DBNetwork.get(`${slug.path}/prefetch`);
    dispatch({ type: LAYOUT_RENDER, payload: response.data })
    if (response.data.status === "success") {
      localStorage.setItem("system_id", response?.data?.data?.system_id);
      localStorage.setItem("lan", response?.data?.data?.current_locale);
    }
    callback(response, null)
  } catch (err) {
    callback(null, err)
    // dispatch({ type: ISVALID, payload: false });
  }
  // await dispatch({ type: CHANGE_LANGAUGE, payload: langauge });
};

export const { Context, Provider } = createDataContext(
  LayoutReducer,
  {
    getLayoutDetails,
    //functionss
  },
  { }
);
