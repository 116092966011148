import React,{useState, useContext, useEffect} from 'react';
import { FaSearch } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import SlideDecks from './SlideDecks';
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import SlideDecksMobileView from './SlideDecksMobileView';
import SlideDecksCat from './SlideDecksCat';
import { ShimmerCategoryItem, ShimmerTitle  } from "react-shimmer-effects";
import useSlug from '../../../../../hooks/useSlug';
import SlideDecksMobileCat from './SlideDecksMobileCat';
import { IconLoader } from '../../../../../UIComponets/IconLoader';


const AllSlideDecks = () => {
   const slug = useSlug().absolutePath;
   //React State
   const { addToast } = useToasts();
   const [isLoading, setIsLoading] = useState(false);
   
   const {
      getSlideDecksData,
      setFavoriteData,
      state:{ 
         eventsAllPagesData: {slide_decks, slide_decks_categories},
         eventsPageData: {translations, details}
      }
   } = useContext(defaultContext);

   const [slideDecksList, setSlideDecks] = useState(slide_decks);
   const [slideDecksCate, setSlideDecksCate] = useState(slide_decks_categories);
   const [currentChar, setCurrentChar] = useState(null);
   const [isActive, setActive] = useState("all");
   const [favorite, setFavorite] = useState([]);

   useEffect(()=> {
      setIsLoading(true);
      getSlideDecksData(slug, (res, error) => {
         if(res?.data?.status){
            setIsLoading(false);
         }
      })
   }, []);

   useEffect(() => {
      if (slide_decks) {
         setSlideDecks(slide_decks);
      }
   }, [slide_decks]);

   useEffect(() => {
      if (slide_decks_categories) {
         setSlideDecksCate(slide_decks_categories);
      }
   }, [slide_decks_categories]);


   const filterSlideDecks = (categorieId) => {
      setIsLoading(true);
      setActive(categorieId);
      if(categorieId == "all"){
         setIsLoading(false);
         setSlideDecks(slide_decks);
          return;
      }
      const updateSlideDecks = slide_decks && slide_decks.filter((currentSlideDecks) => {
         setIsLoading(false);
         return currentSlideDecks.category_id === categorieId;
      });
      setSlideDecks(updateSlideDecks); 
   }

   const searchHandlerSlideDecks = (event) => {
      setIsLoading(true);
      const query = event?.target?.value;
      const updatedList = slide_decks.filter((item) => {
         setIsLoading(false);
         return item?.title.toLowerCase().includes(query.toLowerCase()) || item?.name.toLowerCase().includes(query.toLowerCase());
      });
      setSlideDecks(updatedList);
   }

   const handleFavoirte = (id) => {
         setFavorite([...favorite, id]);
         const type = 'slide_decks';
         setFavoriteData(slug, {type, id}, (response, error) => {
            if (response?.data?.status) {
               const res = response?.data?.data;
               const checked = res?.wishlist?.slide_decks.includes(id) ? true : false;
               const updateSlideDecksData = slideDecksList?.map(el => (
                   el?.id === id ? {...el, isWishlisted:checked}: el
               ));
               setFavorite([]);
               setSlideDecks(updateSlideDecksData);
            }
      });
   }

   const filterAlphabetical = (letter) => {
      let _letter = (letter === "All" ? null : letter);
      setCurrentChar(_letter);
      if(_letter === null){
         setSlideDecks(slide_decks);
      }else{
          const updateSlide = slide_decks && slide_decks.filter((slide) => {
              return slide?.title.charAt(0).toUpperCase() === _letter;
          });
          setSlideDecks(updateSlide);
      }
  }
   

   return (
      <>
         {/* Start slide deck area */}
         <section className="slide_decks_Area slide_kaynote mt-50">
            <div className="container">
               <div className="slide_deck_inner ">
                  <h1 className="main_heading mb-20">
                     {translations?.slide_decks?.title}
                  </h1>
                  
                  {/* Slide decks category here */}
                  <div className="slide_decks_header">
                     {!isLoading ? slideDecksCate?.length > 0 ? (<SlideDecksCat
                        filterSlideDecks={filterSlideDecks}
                        slideDecksCate={slideDecksCate}
                        isActive={isActive}
                        translations={translations}
                     />) : null : (<ShimmerTitle line={1} gap={10} variant="primary" />)}
                     {/* search filter */}
                     <div className="searchbar_right">
                        <input type="text" placeholder="Search.." name="search" className="form-control" onChange={(e)=>searchHandlerSlideDecks(e)} />
                        <button type="button"><IconLoader isLoading={isLoading}><FaSearch/></IconLoader></button>
                     </div>
                  </div>

                   {/* Slide decks category mobile here */}
                  {!isLoading ? slideDecksCate?.length > 0 ? ( <SlideDecksMobileCat
                  filterSlideDecks={filterSlideDecks}
                  slideDecksCate={slideDecksCate}
                  isActive={isActive}
                  searchSlideDecks={searchHandlerSlideDecks}
                  translations={translations}
                  onClick={filterAlphabetical}
                  currentChar={currentChar}
                  />) :null: (<ShimmerTitle line={1} gap={10} variant="primary" />)}
                 

                  {/* Slide decks mobile view here */}
                  {!isLoading && slideDecksList ?
                  (<SlideDecksMobileView 
                  slideDecksList={slideDecksList} 
                  slideDecksCate={slideDecksCate}
                  onClick={handleFavoirte}
                  favorites={favorite}
                  details={details}
                  />)
                  : (<ShimmerCategoryItem
                  hasImage
                  imageType="thumbnail"
                  imageWidth={100}
                  imageHeight={100}
                  title
                  />) }
                  {/* Slide deck header close */}
                  <div className="slide_deck_content mt-40">
                     <div className="row">
                        <div className="col-md-12 col-lg-12">
                           <div className="slide_content_box">
                              {!isLoading && slideDecksList ?
                               (<SlideDecks 
                                 slideDecksList={slideDecksList} 
                                 slideDecksCate={slideDecksCate}
                                 onClick={handleFavoirte}
                                 favorites={favorite}
                                 details={details}
                                 />)
                                : (<ShimmerCategoryItem
                                 hasImage
                                 imageType="thumbnail"
                                 imageWidth={100}
                                 imageHeight={100}
                                 title
                               />) }
                           </div>
                           {/* <div className="col-md-3 col-lg-3 slide_edit_box"></div> */}
                        </div>
                     </div>
                  </div>
               </div>
               {/* <div className="slide_edit_img">
                  <img alt="image" src="/images/edit_icon2.png" />
               </div> */}
            </div>
         </section>
         {/* End slide deck close */}
      </>
   )
}

export default AllSlideDecks;
