export enum LiveBadgeType {
    CHAT = "chat",
    QA = "qa",
    POLL = "poll",
    VOTE = "vote",
    RATING = "rating",
    CHANNELS = "channels",
}

export enum NetworkingBadgeType {
    CHAT = "chat",
    MEETINGS = "meetings",
    PENDING_MEETING = "pending",
    ACCEPTED_MEETING = "accepted",
}