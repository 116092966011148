import React from "react";

export enum Constants {
    DEFAULT_TIMEZONE= "Europe/Brussels",
    VIDEOCALL_ENDTIME_DATE_FORMATE = "YYYY/MM/DD HH:mm:ss",
    SESSION_APPOINTMENT_DATE_FORMATE="MM/DD/YYYY HH:mm",
    SESSION_DATE_FORMATE="DD-MM-YYYYTHH:mm:ss",
    APPOINTMENT_DATE_FORMATE="YYYY-MM-DDTHH:mm:ss",
    CALL_DISCONNECT_MESSAGE="Be aware your going to disconnect the call.",

    NO_CHANNEL_MESSAGE="At this moment, there is no live session. Check the programme to see when sessions begin",
    NO_DATA_AVAILABLE="No data available.",

    NOTIFICATION_ENABLED ="notification_enabled",
    NETWORKING_NOTIFICATION_ENABLED ="networking_enabled",
    NETWORKING = "network",

    CONNECTION_REQUEST="Connect requests will add the contact to your list of connections for the event. See the tab in the Networking section for your list of Connections. Please confirm."

}