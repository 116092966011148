import React,{useContext} from 'react';
import { Link } from 'react-router-dom';
import useSlug from "../../../../../../hooks/useSlug";
import { ProfileUIState } from './ProfileUIState';
import { useTemplate } from '../../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../../Model/TemplateDetails";
import { Translations } from '../../../../../../hooks/shared/Translations';
import { EventApi } from '../../../../../../hooks/shared/EventApi';
import { SecurityApi } from '../../../../../../hooks/shared/SecurityApi';
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const ProfileMenu = ({onClick, active, menu}) => {
    const slug = useSlug().absolutePath;
    const {translations} = Translations()
    const {
        state:{ 
            event,
           eventsPageData
        }
     } = EventApi();

    const {
        state: {
            layout
        }
    } = useTemplate();

    const {
        security,
    } = SecurityApi();

    const isRegistration = security === "registration";
    var hasCvent = event && Object.values(event).includes('cvent');
    const details = TemplateDetails(layout)
    const isOnePageTemplate = details.getTemplate() === Template.ONEPAGE;

    return (
        <>
            <div className={isOnePageTemplate ? "col-md-12 col-lg-12 col-xl-1" : "col-md-12 col-lg-12 col-xl-1"}>
                <div className="profile_right_box">
                    {event ? (<ul>
                       <PersonalMenu active={active} id={ProfileUIState.PERSONAL} onClick={onClick} translations={translations}/>
                       <MysessionsMenu active={active} id={ProfileUIState.MYSESSIONS} onClick={onClick} translations={translations}/>
                       {!hasCvent && isRegistration && (<PasswordMenu active={active} onClick={onClick} id={ProfileUIState.PASSWORD}/>)} 
                       </ul>) :<ShimmerSimpleGallery row={3} col={1} imageHeight={100} />
                    }
                </div>
            </div>  
        </>
    )
}

export default ProfileMenu;

const MenuWrapper = (props) => {
    return <li className={props.active === props.id ? "active" : ""} onClick={() => props.onClick(props.id)}>
            <Link to={"#"}>
            {props.children}
            </Link>
        </li>
}

const PersonalMenu = (props) => {
   return <MenuWrapper {...props}>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="33.797" viewBox="0 0 50 33.797">
                <path id="Icon_metro-profile" data-name="Icon metro-profile" d="M50.928,12.54h-3.5V7.812l-7.218-.1.037,4.828H18.561l.148-4.828-7.068.1V12.64l-3.571-.1A3.41,3.41,0,0,0,4.5,15.759V38.29A3.41,3.41,0,0,0,8.07,41.509H50.928A3.41,3.41,0,0,0,54.5,38.29V15.759A3.41,3.41,0,0,0,50.928,12.54ZM42,9.321H45.57v6.437H42ZM18.785,20.284c2.157,0,3.907,2.071,3.907,4.627s-1.75,4.627-3.907,4.627-3.907-2.071-3.907-4.627,1.75-4.627,3.907-4.627ZM13.427,9.321H17v6.437H13.427Zm-2.38,25.666s.423-3.061,1.386-3.639a14.883,14.883,0,0,1,3.732-.962s1.8,1.73,2.561,1.73,2.559-1.73,2.559-1.73a14.837,14.837,0,0,1,3.734.962c1.13.679,1.413,3.639,1.413,3.639H11.047Zm36.309-1.526H31.285V31.853H47.356Zm0-3.219H31.285V28.634H47.356Zm0-3.219H31.285V25.415H47.356Zm0-3.219H31.285V22.2H47.356Z" transform="translate(-4.499 -7.712)" />
            </svg>
        </span>
        <p title={props?.translations?.general?.profile}>{props?.translations?.general?.profile}</p>
     </MenuWrapper>
}

const PasswordMenu = (props) => {
    return <MenuWrapper {...props}>
    <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="30.671" height="40.256" viewBox="0 0 30.671 40.256">
            <path
                id="Icon_material-lock-outline"
                data-name="Icon material-lock-outline"
                d="M21.336,32.171A3.834,3.834,0,1,0,17.5,28.337,3.845,3.845,0,0,0,21.336,32.171Zm11.5-17.253H30.92V11.085a9.585,9.585,0,1,0-19.169,0v3.834H9.834A3.845,3.845,0,0,0,6,18.753V37.922a3.845,3.845,0,0,0,3.834,3.834h23a3.845,3.845,0,0,0,3.834-3.834V18.753A3.845,3.845,0,0,0,32.837,14.919ZM15.393,11.085a5.943,5.943,0,0,1,11.885,0v3.834H15.393ZM32.837,37.922h-23V18.753h23Z"
                transform="translate(-6 -1.5)"
                />
        </svg>
    </span>
    <p>Password</p>
    </MenuWrapper>
}

const MysessionsMenu = (props) => {
    return <MenuWrapper {...props}>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="39.215" height="39.215" viewBox="0 0 39.215 39.215">
                <path id="Icon_material-schedule" data-name="Icon material-schedule" d="M22.588,3A19.607,19.607,0,1,0,42.215,22.607,19.6,19.6,0,0,0,22.588,3Zm.02,35.293A15.686,15.686,0,1,1,38.293,22.607,15.682,15.682,0,0,1,22.607,38.293Zm.98-25.49H20.647V24.568l10.294,6.176,1.471-2.412L23.588,23.1Z" transform="translate(-3 -3)" />
            </svg>
        </span>
        <p title={props?.translations?.sessions?.my_sessions}>{props?.translations?.sessions?.my_sessions}</p>
    </MenuWrapper>
}
