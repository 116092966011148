import React, {useContext} from "react";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as defaultContext } from "../../context/DefaultSectionContext";
import {SettingOptions} from "../../classes/SettingOptions";

export function SecurityApi() {

    const {
        state: {
             isLoggedIn,
             loginPageData: {
                event
            },
        },
    } = useContext(AuthContext);

    const context = useContext(defaultContext);

    // const includes = (option) => options?.includes(option) ?? false;

    return {
        isLoggedIn:isLoggedIn,
        security:event?.security ? event?.security : context?.state?.eventsPageData?.event?.security,
    }
}

