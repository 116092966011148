import React from "react";

export const CancelIcon =({onClick, disabled}) => {
    return <button disabled={disabled} onClick={onClick} type="button" className="btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="37.183" height="37.183" viewBox="0 0 37.183 37.183">
            <g id="Group_1854" data-name="Group 1854" transform="translate(-1561.461 -343.75)">
                <g id="Group_1852" data-name="Group 1852">
                    <g id="FORM_-_Checkbox_active" data-name="FORM - Checkbox active" transform="translate(1561.461 343.75)">
                        <g id="FORM_-_checkbox_active-2" data-name="FORM - checkbox active">
                            <g id="FORM_-_Checkbox_Inactive" data-name="FORM - Checkbox Inactive">
                                <g id="Rectangle_1372" data-name="Rectangle 1372" fill="#fff" stroke="#f26334" strokeWidth="3">
                                    <rect width="37.183" height="37.183" rx="10" stroke="none" />
                                    <rect x="1.5" y="1.5" width="34.183" height="34.183" rx="8.5" fill="none" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="ICON_-_Close" data-name="ICON - Close" transform="translate(1569.631 352.482)">
                    <rect id="Rectangle_1308" data-name="Rectangle 1308" width="4.539" height="23.345" rx="2.27" transform="translate(0 3.21) rotate(-45)" fill="#f26334" />
                    <rect id="Rectangle_1309" data-name="Rectangle 1309" width="4.54" height="23.345" rx="2.27" transform="translate(16.509) rotate(45)" fill="#f26334" />
                </g>
            </g>
        </svg>
    </button>
}