import React from 'react';
import { IconLoader } from '../../../../../../UIComponets/IconLoader';
import {Constants} from "../../../../../../classes/Constants";

const ApprovingConfirmPopup = ({requestSend, connectId, status, close}) => {
  return (
        <>
            <div className="modal fade connection_request outging_call show" id="approving-model">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={close}>&times;</button>
                        </div>
                        <div className="connection_request_mbody text-center">
                            <p className='h4 mb-20'>{Constants.CONNECTION_REQUEST}</p>
                            <div className="calling-btn">
                                <button type="button" onClick={() => requestSend(connectId, status)} className="btn btn_border_blue" data-toggle="modal" data-target="#confirm_del">
                                    <IconLoader isLoading={false}>Yes</IconLoader>
                                </button>
                                <button type="button" className="btn btn_border_orange" onClick={close}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApprovingConfirmPopup;