import React from 'react'

const ShoppingCardModel = () => {
    return (
        <>
                   {/* <!-- The Modal --> */}
        <div className="modal fade connection_request shopping_card_model" id="shopping-card">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div className="connection_request_body">
                            <div className="connection_p_head mb-20">
                                <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36.991" height="30.266" viewBox="0 0 36.991 30.266">
                                        <path
                                            id="Path_1435"
                                            data-name="Path 1435"
                                            d="M36.823,8.478a.955.955,0,0,0-.673-.252H7.566a.794.794,0,0,0-.841.841c0,.5,3.962,16.213.252-5.465A2.528,2.528,0,0,0,4.456,1.5H2.522A2.477,2.477,0,0,0,0,4.022a.794.794,0,0,0,.841.841.794.794,0,0,0,.841-.841.794.794,0,0,1,.841-.841H4.456a.819.819,0,0,1,.841.673l3.867,21.69a3.47,3.47,0,0,0-1.6,2.858,3.373,3.373,0,0,0,3.363,3.363H32.788a.841.841,0,0,0,0-1.681H10.929a1.681,1.681,0,1,1,0-3.363H31.443a3.356,3.356,0,0,0,3.363-2.858L36.991,9.15A2.6,2.6,0,0,0,36.823,8.478Z"
                                            transform="translate(0 -1.5)"
                                            fill="#f26334"
                                        />
                                    </svg>
                                </span>
                                <div className="info mb-30">
                                    <h4>Shopping cart</h4>
                                    <p>Unpaid saved sessions</p>
                                </div>
                            </div>
                                <h1 className="main_heading mb-20">SHOPPINGCART</h1>
                            <div className="shopping_card_content">

                                  <div className="mobile_accordion_item">
                                <div className="m_ed_time_box">
                                    <div className="time_box">
                                        <p>
                                            11:00 <br/>
                                            13:00
                                        </p>
                                    </div>
                                    <div className="mobile_accordion_header">
                                        <button className="btn btn-link collapsed" >
                                            <span className="coll_header">Chapter 1: Announcem …</span>
                                            <span className="coll_icon">
                                                € 50,-
                                            </span>
                                             <div className="shopping_check">
                                                <div className="poll_check">
                                                    <form>
                                                        <div className="form-group">
                                                            <input type="checkbox" id="girl" checked="" />
                                                            <label htmlFor="girl"></label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                          
                                        </button>
                                    </div>
                                </div>
                              
                            </div>

                              <div className="mobile_accordion_item">
                                <div className="m_ed_time_box">
                                    <div className="time_box">
                                        <p>
                                            11:00 <br/>
                                            13:00
                                        </p>
                                    </div>
                                    <div className="mobile_accordion_header">
                                        <button className="btn btn-link collapsed" >
                                            <span className="coll_header">Chapter 1: Announcem …</span>
                                            <span className="coll_icon">
                                                € 50,-
                                            </span>
                                             <div className="shopping_check">
                                                <div className="poll_check">
                                                    <form>
                                                        <div className="form-group">
                                                            <input type="checkbox" id="girl" checked="" />
                                                            <label htmlFor="girl"></label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                          
                                        </button>
                                    </div>
                                </div>
                              
                            </div>

                              <div className="mobile_accordion_item">
                                <div className="m_ed_time_box">
                                    <div className="time_box">
                                        <p>
                                            11:00 <br/>
                                            13:00
                                        </p>
                                    </div>
                                    <div className="mobile_accordion_header">
                                        <button className="btn btn-link collapsed" >
                                            <span className="coll_header">Chapter 1: Announcem …</span>
                                            <span className="coll_icon">
                                                € 50,-
                                            </span>
                                             <div className="shopping_check">
                                                <div className="poll_check">
                                                    <form>
                                                        <div className="form-group">
                                                            <input type="checkbox" id="girl" checked="" />
                                                            <label htmlFor="girl"></label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                          
                                        </button>
                                    </div>
                                </div>
                              
                            </div>
                                <div className="shopping_card_list mb-20">
                                    <div className="shopping_check">
                                        <div className="poll_check">
                                            <form>
                                                <div className="form-group">
                                                    <input type="checkbox" id="girl" checked="" />
                                                    <label htmlFor="girl"></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="session_accordion_box">
                                        <div className="session_accordion_header">
                                            <div className="date">
                                                <p>
                                                    15.00 - 16.00 <br />
                                                    Sat 10/07
                                                </p>
                                            </div>
                                            <div className="description">
                                                <h4>The semantic approach to data sharing</h4>
                                                <button className="workshop_btn">WORKSHOP</button>
                                            </div>

                                            <div className="payment">
                                                <p>€ 50,-</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="shopping_card_list mb-20">
                                    <div className="shopping_check">
                                        <div className="poll_check">
                                            <form>
                                                <div className="form-group">
                                                    <input type="checkbox" id="girl" />
                                                    <label htmlFor="girl"></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="session_accordion_box">
                                        <div className="session_accordion_header">
                                            <div className="date blue_back">
                                                <p>
                                                    15.00 - 16.00 <br />
                                                    Sat 10/07
                                                </p>
                                            </div>
                                            <div className="description">
                                                <h4>The semantic approach to data sharing</h4>
                                            </div>

                                            <div className="payment">
                                                <p>€ 50,-</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="shopping_card_list mb-20">
                                    <div className="shopping_check">
                                        <div className="poll_check">
                                            <form>
                                                <div className="form-group">
                                                    <input type="checkbox" id="girl" checked="" />
                                                    <label htmlFor="girl"></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="session_accordion_box">
                                        <div className="session_accordion_header">
                                            <div className="date">
                                                <p>
                                                    15.00 - 16.00 <br />
                                                    Sat 10/07
                                                </p>
                                            </div>
                                            <div className="description">
                                                <h4>The semantic approach to data sharing</h4>
                                            </div>

                                            <div className="payment">
                                                <p>€ 50,-</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="shopping_card_list mb-20">
                                    <div className="shopping_check">
                                        <div className="poll_check">
                                            <form>
                                                <div className="form-group">
                                                    <input type="checkbox" id="girl" checked="" />
                                                    <label htmlFor="girl"></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="session_accordion_box">
                                        <div className="session_accordion_header">
                                            <div className="date">
                                                <p>
                                                    15.00 - 16.00 <br />
                                                    Sat 10/07
                                                </p>
                                            </div>
                                            <div className="description">
                                                <h4>The semantic approach to data sharing</h4>
                                            </div>

                                            <div className="payment">
                                                <p>€ 50,-</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="total_paid_ammount">
                                    <ul>
                                        <li>Total:</li>
                                        <li>€ 150,-</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="sopping_card_btn">
                                <button type="button" className="btn btn_border_orange" data-dismiss="modal" data-toggle="modal" data-target="#purchase">
                                    Proceed to checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        </>
    )
}

export default ShoppingCardModel;
