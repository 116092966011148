import React from 'react';

const SpeakerDetails = () => {
   return (
      <>
         <section className="participant_details_mobile mt-50 pb-100">
            <div className="container">
               <h1 className="main_heading mb-20">SPEAKERS</h1>
               <div className="participant_profile">
                  <div className="img_box">
                     <img src="/images/video_chat_img3.png" />
                     <span className="dot"></span>
                  </div>
                  <div className="profile_text">
                     <h5>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8.964" height="13.039" viewBox="0 0 8.964 13.039">
                           <path id="Icon_awesome-microphone" data-name="Icon awesome-microphone" d="M4.482,8.964A2.445,2.445,0,0,0,6.927,6.52V2.445a2.445,2.445,0,0,0-4.89,0V6.52A2.445,2.445,0,0,0,4.482,8.964ZM8.557,4.89H8.149a.407.407,0,0,0-.407.407V6.52A3.264,3.264,0,0,1,4.156,9.764,3.359,3.359,0,0,1,1.222,6.374V5.3A.407.407,0,0,0,.815,4.89H.407A.407.407,0,0,0,0,5.3V6.32a4.622,4.622,0,0,0,3.871,4.627v.87H2.445a.407.407,0,0,0-.407.407v.407a.407.407,0,0,0,.407.407H6.52a.407.407,0,0,0,.407-.407v-.407a.407.407,0,0,0-.407-.407H5.093v-.86A4.487,4.487,0,0,0,8.964,6.52V5.3A.407.407,0,0,0,8.557,4.89Z" fill="#f26334"/>
                        </svg>
                        SPEAKER
                     </h5>
                     <h4>Markus Mueller</h4>
                     <p>Director Global Data Model Programme</p>
                     <h6>GS1 Belgium</h6>
                  </div>
               </div>
               <div className="social_btn">
                  <button type="button" className="btn">
                     <svg xmlns="http://www.w3.org/2000/svg" width="30.951" height="30.951" viewBox="0 0 30.951 30.951">
                        <path
                           id="Icon_simple-linkedin"
                           data-name="Icon simple-linkedin"
                           d="M26.369,26.376H21.786V19.194c0-1.713-.035-3.917-2.388-3.917-2.39,0-2.755,1.864-2.755,3.79v7.308H12.059V11.607h4.4V13.62h.059a4.83,4.83,0,0,1,4.346-2.386c4.644,0,5.5,3.056,5.5,7.035v8.107ZM6.883,9.586A2.662,2.662,0,1,1,9.545,6.923,2.659,2.659,0,0,1,6.883,9.586Zm2.3,16.79h-4.6V11.607h4.6ZM28.662,0H2.284A2.257,2.257,0,0,0,0,2.23V28.721a2.256,2.256,0,0,0,2.284,2.23H28.658a2.264,2.264,0,0,0,2.293-2.23V2.23A2.265,2.265,0,0,0,28.658,0Z"
                           fill="#f26334"
                           />
                     </svg>
                  </button>
                  <button type="button" className="btn">
                     <svg xmlns="http://www.w3.org/2000/svg" width="30.951" height="30.951" viewBox="0 0 30.951 30.951">
                        <path
                           id="Icon_awesome-twitter-square"
                           data-name="Icon awesome-twitter-square"
                           d="M27.635,2.25H3.316A3.317,3.317,0,0,0,0,5.566V29.885A3.317,3.317,0,0,0,3.316,33.2H27.635a3.317,3.317,0,0,0,3.316-3.316V5.566A3.317,3.317,0,0,0,27.635,2.25ZM24.257,13.221c.014.193.014.394.014.587A12.8,12.8,0,0,1,11.379,26.7a12.832,12.832,0,0,1-6.957-2.031,9.546,9.546,0,0,0,1.092.055,9.085,9.085,0,0,0,5.624-1.934A4.537,4.537,0,0,1,6.9,19.646a4.883,4.883,0,0,0,2.045-.083A4.531,4.531,0,0,1,5.32,15.114v-.055a4.528,4.528,0,0,0,2.045.573A4.522,4.522,0,0,1,5.347,11.86a4.475,4.475,0,0,1,.615-2.287A12.866,12.866,0,0,0,15.3,14.313a4.541,4.541,0,0,1,7.731-4.138,8.879,8.879,0,0,0,2.874-1.092,4.519,4.519,0,0,1-1.99,2.494,9.017,9.017,0,0,0,2.612-.7A9.537,9.537,0,0,1,24.257,13.221Z"
                           transform="translate(0 -2.25)"
                           fill="#f26334"
                           />
                     </svg>
                  </button>
                  <button type="button" className="btn">
                     <svg xmlns="http://www.w3.org/2000/svg" width="31.405" height="31.405" viewBox="0 0 31.405 31.405">
                        <path
                           id="Icon_awesome-facebook-square"
                           data-name="Icon awesome-facebook-square"
                           d="M28.04,2.25H3.365A3.365,3.365,0,0,0,0,5.615V30.29a3.365,3.365,0,0,0,3.365,3.365h9.621V22.978H8.57V17.953h4.416v-3.83c0-4.357,2.594-6.763,6.566-6.763a26.755,26.755,0,0,1,3.892.339v4.276H21.253a2.513,2.513,0,0,0-2.833,2.715v3.263h4.822l-.771,5.026h-4.05V33.655H28.04a3.365,3.365,0,0,0,3.365-3.365V5.615A3.365,3.365,0,0,0,28.04,2.25Z"
                           transform="translate(0 -2.25)"
                           fill="#f26334"
                           />
                     </svg>
                  </button>
               </div>
               <div className="participant_details">
                  <p>
                     A digital marketing professional with twenty years of experience working with multi-national businesses around the world. Currently, I am the co-founder and CEO of Tambo, The Marketplace Services
                     Platform that helps businesses grow on Amazon and other marketplaces in the UK and Internationally.
                  </p>
                  <p>
                     A digital marketing professional with twenty years of experience working with multi-national businesses around the world. Currently, I am the co-founder and CEO of Tambo, The Marketplace Services
                     Platform that helps businesses grow on Amazon and other marketplaces in the UK and Internationally.
                  </p>
                  <div className="participant_connect_btn mt-100">
                     <button type="button" className="btn" data-toggle="modal" data-target="#m_connection_request">
                        <span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="18.01" height="15.27" viewBox="0 0 18.01 15.27">
                              <path
                                 id="Icon_awesome-hands-helping"
                                 data-name="Icon awesome-hands-helping"
                                 d="M13.731,5.728H9.454V7.4A2.092,2.092,0,0,1,7.428,9.545,2.092,2.092,0,0,1,5.4,7.4V3.771L3.576,4.934A1.931,1.931,0,0,0,2.7,6.569V7.98L.449,9.358a.984.984,0,0,0-.329,1.3L2.371,14.8a.874.874,0,0,0,1.23.349l2.91-1.781h3.844a1.859,1.859,0,0,0,1.8-1.909h.45a.928.928,0,0,0,.9-.954V8.591h.225a.7.7,0,0,0,.675-.716V6.443A.7.7,0,0,0,13.731,5.728Zm4.156-1.116L15.636.478a.874.874,0,0,0-1.23-.349L11.5,1.91H8.621a1.739,1.739,0,0,0-.954.289l-.943.623a.962.962,0,0,0-.422.808V7.4a1.128,1.128,0,1,0,2.251,0V4.773h5.178a1.626,1.626,0,0,1,1.576,1.67v.85l2.251-1.378A.987.987,0,0,0,17.888,4.612Z"
                                 transform="translate(0.002 -0.002)"
                                 fill="#454545"
                                 />
                           </svg>
                        </span>
                        CONNECT
                     </button>
                     <a href="chat_window.html"><button type="button" className="btn">
                        <span>
                           <svg id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" xmlns="http://www.w3.org/2000/svg" width="15.037" height="15.037" viewBox="0 0 15.037 15.037">
                              <path
                                 id="Path_1272"
                                 data-name="Path 1272"
                                 d="M19.553,13.229a.876.876,0,0,1,.119-.441,1.217,1.217,0,0,1,.076-.112,5.857,5.857,0,0,0,1-3.264,6.149,6.149,0,0,0-6.279-6.037,6.236,6.236,0,0,0-6.156,4.8,5.809,5.809,0,0,0-.134,1.236,6.132,6.132,0,0,0,6.181,6.113,7.444,7.444,0,0,0,1.706-.278c.408-.112.813-.26.918-.3a.956.956,0,0,1,.336-.061.94.94,0,0,1,.365.072l2.05.727a.489.489,0,0,0,.141.036.288.288,0,0,0,.289-.289.464.464,0,0,0-.018-.1Z"
                                 transform="translate(-5.708 -3.375)"
                                 fill="#454545"
                                 />
                              <path
                                 id="Path_1273"
                                 data-name="Path 1273"
                                 d="M13.153,20.457c-.13.036-.3.076-.477.116a6.671,6.671,0,0,1-1.229.163,6.132,6.132,0,0,1-6.181-6.113,6.834,6.834,0,0,1,.054-.774c.022-.155.047-.311.083-.463s.08-.325.127-.484l-.289.257A5.379,5.379,0,0,0,3.375,17.2a5.319,5.319,0,0,0,.9,2.964c.083.127.13.224.116.289s-.43,2.241-.43,2.241a.29.29,0,0,0,.1.278.294.294,0,0,0,.184.065.259.259,0,0,0,.1-.022l2.028-.8a.565.565,0,0,1,.434.007A6.084,6.084,0,0,0,9,22.662a5.677,5.677,0,0,0,4.341-1.992s.116-.159.249-.347C13.457,20.37,13.3,20.417,13.153,20.457Z"
                                 transform="translate(-3.375 -8.004)"
                                 fill="#454545"
                                 />
                           </svg>
                        </span>
                        MESSAGE
                     </button></a>
                     <button type="button" className="btn" data-toggle="modal" data-target="#m_outgoing_call">
                        <span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="18.627" height="12.141" viewBox="0 0 18.627 12.141">
                              <path
                                 id="Icon_ionic-md-videocam"
                                 data-name="Icon ionic-md-videocam"
                                 d="M16.886,12.287V8.745a1.025,1.025,0,0,0-1.035-1.01H3.285A1.025,1.025,0,0,0,2.25,8.745V18.861a1.028,1.028,0,0,0,1.035,1.015H15.85a1.028,1.028,0,0,0,1.035-1.01V15.322l3.992,4.046V8.242Z"
                                 transform="translate(-2.25 -7.734)"
                                 fill="#454545"
                                 />
                           </svg>
                        </span>
                        CALL
                     </button>
                     <button type="button" className="btn" data-toggle="modal" data-target="#sehedule_appointment">
                        <span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="13.282" height="15.04" viewBox="0 0 13.282 15.04">
                              <path
                                 id="Icon_awesome-calendar-plus"
                                 data-name="Icon awesome-calendar-plus"
                                 d="M12.927,4.7H.356A.355.355,0,0,1,0,4.347V3.29A1.417,1.417,0,0,1,1.423,1.88H2.846V.352A.355.355,0,0,1,3.2,0H4.388a.355.355,0,0,1,.356.352V1.88H8.539V.352A.355.355,0,0,1,8.894,0H10.08a.355.355,0,0,1,.356.352V1.88h1.423a1.417,1.417,0,0,1,1.423,1.41V4.347A.355.355,0,0,1,12.927,4.7ZM.356,5.64H12.927a.355.355,0,0,1,.356.352V13.63a1.417,1.417,0,0,1-1.423,1.41H1.423A1.417,1.417,0,0,1,0,13.63V5.992A.355.355,0,0,1,.356,5.64ZM9.725,9.752A.355.355,0,0,0,9.369,9.4H7.59V7.637a.355.355,0,0,0-.356-.352H6.048a.355.355,0,0,0-.356.352V9.4H3.914a.355.355,0,0,0-.356.352v1.175a.355.355,0,0,0,.356.352H5.692v1.762a.355.355,0,0,0,.356.352H7.234a.355.355,0,0,0,.356-.352V11.28H9.369a.355.355,0,0,0,.356-.352Z"
                                 fill="#454545"
                                 />
                           </svg>
                        </span>
                        SCHEDULE APPOINTMENT
                     </button>
                  </div>
               </div>
               {/* <!-- mobile_action_ed_time --> */}
               <div className="mobile_ed_time_section accordion mt-30" id="accordionExample">
                  <div className="mobile_accordion_item">
                     <div className="m_ed_time_box">
                        <div className="time_box">
                           <p>11:00 <br/>13:00</p>
                        </div>
                        <div className="mobile_accordion_header">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                           <span className="coll_header">Chapter 1: Announcem …</span>
                           <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                           <a className="btn btn_theme_btn">REGISTER</a>
                           </button>
                        </div>
                     </div>
                     <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="session_info_box">
                           <div className="emerging_sectors mt-40">
                              <h1 className="mb-40">EMERGING SECTORS</h1>
                              <p>
                                 As the world evolves towards digital, the need of sharing data increases day by day. Sharing information with partners requires a common language not only in terms of syntaxes
                                 and technologies but also in terms of common definition of data, in business and not technical language. During the session, the GS1 strategy on semantic approach in standards
                                 definition will be presented, with a particular focus on Electronic Data Interchange Semantic methodology and Global Data Model.
                              </p>
                           </div>
                           <div className="row">
                              <div className="col-md-6 col-lg-6">
                                 <div className="client_info mb-20">
                                    <div className="client_info_img">
                                       <img src="/images/user_img1.png" />
                                    </div>
                                    <div className="client_info_content">
                                       <h4>Evi Janssen</h4>
                                       <p><span style={{color:"var(--event-primary) !important"}}>GS1 Belgium</span> - Sales Director</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 col-lg-6">
                                 <div className="client_info">
                                    <div className="client_info_img">
                                       <img src="/images/user_img2.png" />
                                    </div>
                                    <div className="client_info_content">
                                       <h4>Josi Smit</h4>
                                       <p><span style={{color:"var(--event-primary)"}}>GS1 Belgium</span> - Sales Director</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="session_info_register_area">
                              <ul className="nav icon">
                                 <li>
                                    <a href="">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="13.616" height="11.67" viewBox="0 0 13.616 11.67">
                                          <g id="Group_1650" data-name="Group 1650" transform="translate(0.891 1)">
                                             <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(12.725 -1) rotate(90)">
                                                <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M6.811,5.241,11.959.275a1,1,0,0,1,1.374,0,.921.921,0,0,1,0,1.328L7.5,7.23a1,1,0,0,1-1.342.027L.284,1.608a.919.919,0,0,1,0-1.328,1,1,0,0,1,1.374,0Z" transform="translate(0 13.616) rotate(-90)" fill="#88888d"/>
                                             </g>
                                             <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h11.462" transform="translate(-5191.758 -2980.687)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                          </g>
                                       </svg>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="">
                                       <svg id="Group_1649" data-name="Group 1649" xmlns="http://www.w3.org/2000/svg" width="14.298" height="14.292" viewBox="0 0 14.298 14.292">
                                          <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(0 0)">
                                             <path id="Path_1270" data-name="Path 1270" d="M11.345,18.374l-.041,0a.568.568,0,0,0-.33.155L8.755,20.751a2.5,2.5,0,0,1-3.539-3.539l2.357-2.357a2.487,2.487,0,0,1,.392-.319,2.525,2.525,0,0,1,.508-.258,2.4,2.4,0,0,1,.515-.127,2.436,2.436,0,0,1,.35-.024c.048,0,.1,0,.158.007a2.5,2.5,0,0,1,1.608.721,2.463,2.463,0,0,1,.587.938.541.541,0,0,0,.663.347s.007,0,.01,0,.007,0,.007,0a.537.537,0,0,0,.361-.656,3.113,3.113,0,0,0-.845-1.409,3.617,3.617,0,0,0-1.979-1.007c-.065-.01-.131-.021-.2-.027a3.545,3.545,0,0,0-.381-.021c-.089,0-.179,0-.265.01a3.465,3.465,0,0,0-.557.086c-.038.007-.072.017-.11.027a3.573,3.573,0,0,0-.67.251,3.529,3.529,0,0,0-.952.684L4.423,16.43a3.624,3.624,0,0,0-1.048,2.559,3.612,3.612,0,0,0,6.163,2.553L11.782,19.3A.544.544,0,0,0,11.345,18.374Z" transform="translate(-3.375 -8.305)" fill="#88888d"/>
                                             <path id="Path_1271" data-name="Path 1271" d="M21.773,4.43a3.62,3.62,0,0,0-5.109,0L14.476,6.618a.552.552,0,0,0,.347.941A.558.558,0,0,0,15.26,7.4l2.192-2.185A2.5,2.5,0,0,1,20.99,8.755l-2.357,2.357a2.487,2.487,0,0,1-.392.319,2.525,2.525,0,0,1-.508.258,2.4,2.4,0,0,1-.515.127,2.436,2.436,0,0,1-.35.024c-.048,0-.1,0-.158-.007a2.469,2.469,0,0,1-2.171-1.6.549.549,0,0,0-.656-.337.555.555,0,0,0-.388.7,3.138,3.138,0,0,0,.814,1.288h0l.007.007a3.617,3.617,0,0,0,2.175,1.034,3.545,3.545,0,0,0,.381.021q.134,0,.268-.01a3.928,3.928,0,0,0,.663-.11,3.574,3.574,0,0,0,.67-.251,3.529,3.529,0,0,0,.952-.684L21.78,9.542a3.616,3.616,0,0,0-.007-5.112Z" transform="translate(-8.536 -3.375)" fill="#88888d"/>
                                          </g>
                                       </svg>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16.531" height="15.291" viewBox="0 0 16.531 15.291">
                                          <path id="Icon_material-favorite" data-name="Icon material-favorite" d="M10.515,18.291,9.426,17.3C5.555,13.789,3,11.474,3,8.633A4.093,4.093,0,0,1,7.133,4.5a4.5,4.5,0,0,1,3.382,1.571A4.5,4.5,0,0,1,13.9,4.5a4.093,4.093,0,0,1,4.133,4.133c0,2.841-2.555,5.155-6.426,8.673Z" transform="translate(-2.25 -3.75)" fill="none" stroke="#f26334" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                                       </svg>
                                    </a>
                                 </li>
                              </ul>
                              <button className="btn btn_border_orange">REGISTER</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="mobile_accordion_item">
                     <div className="m_ed_time_box">
                        <div className="time_box">
                           <p>11:30 <br/>12:30</p>
                        </div>
                        <div className="mobile_accordion_header">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsetwo" aria-expanded="true" aria-controls="collapseOne">
                           <span className="coll_header">Product Launch</span>
                           <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                           </button>
                        </div>
                     </div>
                     <div id="collapsetwo" className="collapse" aria-labelledby="headingtwo" data-parent="#accordionExample">
                        <div className="session_info_box">
                           <div className="emerging_sectors mt-40">
                              <h1 className="mb-40">EMERGING SECTORS</h1>
                              <p>
                                 As the world evolves towards digital, the need of sharing data increases day by day. Sharing information with partners requires a common language not only in terms of syntaxes
                                 and technologies but also in terms of common definition of data, in business and not technical language. During the session, the GS1 strategy on semantic approach in standards
                                 definition will be presented, with a particular focus on Electronic Data Interchange Semantic methodology and Global Data Model.
                              </p>
                           </div>
                           <div className="row">
                              <div className="col-md-6 col-lg-6">
                                 <div className="client_info mb-20">
                                    <div className="client_info_img">
                                       <img src="/images/user_img1.png" />
                                    </div>
                                    <div className="client_info_content">
                                       <h4>Evi Janssen</h4>
                                       <p><span style={{color:"var(--event-primary) !important"}}>GS1 Belgium</span> - Sales Director</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 col-lg-6">
                                 <div className="client_info">
                                    <div className="client_info_img">
                                       <img src="/images/user_img2.png" />
                                    </div>
                                    <div className="client_info_content">
                                       <h4>Josi Smit</h4>
                                       <p><span style={{color:"var(--event-primary)"}}>GS1 Belgium</span> - Sales Director</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="session_info_register_area">
                              <ul className="nav icon">
                                 <li>
                                    <a href="">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="13.616" height="11.67" viewBox="0 0 13.616 11.67">
                                          <g id="Group_1650" data-name="Group 1650" transform="translate(0.891 1)">
                                             <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(12.725 -1) rotate(90)">
                                                <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M6.811,5.241,11.959.275a1,1,0,0,1,1.374,0,.921.921,0,0,1,0,1.328L7.5,7.23a1,1,0,0,1-1.342.027L.284,1.608a.919.919,0,0,1,0-1.328,1,1,0,0,1,1.374,0Z" transform="translate(0 13.616) rotate(-90)" fill="#88888d"/>
                                             </g>
                                             <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h11.462" transform="translate(-5191.758 -2980.687)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                          </g>
                                       </svg>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="">
                                       <svg id="Group_1649" data-name="Group 1649" xmlns="http://www.w3.org/2000/svg" width="14.298" height="14.292" viewBox="0 0 14.298 14.292">
                                          <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(0 0)">
                                             <path id="Path_1270" data-name="Path 1270" d="M11.345,18.374l-.041,0a.568.568,0,0,0-.33.155L8.755,20.751a2.5,2.5,0,0,1-3.539-3.539l2.357-2.357a2.487,2.487,0,0,1,.392-.319,2.525,2.525,0,0,1,.508-.258,2.4,2.4,0,0,1,.515-.127,2.436,2.436,0,0,1,.35-.024c.048,0,.1,0,.158.007a2.5,2.5,0,0,1,1.608.721,2.463,2.463,0,0,1,.587.938.541.541,0,0,0,.663.347s.007,0,.01,0,.007,0,.007,0a.537.537,0,0,0,.361-.656,3.113,3.113,0,0,0-.845-1.409,3.617,3.617,0,0,0-1.979-1.007c-.065-.01-.131-.021-.2-.027a3.545,3.545,0,0,0-.381-.021c-.089,0-.179,0-.265.01a3.465,3.465,0,0,0-.557.086c-.038.007-.072.017-.11.027a3.573,3.573,0,0,0-.67.251,3.529,3.529,0,0,0-.952.684L4.423,16.43a3.624,3.624,0,0,0-1.048,2.559,3.612,3.612,0,0,0,6.163,2.553L11.782,19.3A.544.544,0,0,0,11.345,18.374Z" transform="translate(-3.375 -8.305)" fill="#88888d"/>
                                             <path id="Path_1271" data-name="Path 1271" d="M21.773,4.43a3.62,3.62,0,0,0-5.109,0L14.476,6.618a.552.552,0,0,0,.347.941A.558.558,0,0,0,15.26,7.4l2.192-2.185A2.5,2.5,0,0,1,20.99,8.755l-2.357,2.357a2.487,2.487,0,0,1-.392.319,2.525,2.525,0,0,1-.508.258,2.4,2.4,0,0,1-.515.127,2.436,2.436,0,0,1-.35.024c-.048,0-.1,0-.158-.007a2.469,2.469,0,0,1-2.171-1.6.549.549,0,0,0-.656-.337.555.555,0,0,0-.388.7,3.138,3.138,0,0,0,.814,1.288h0l.007.007a3.617,3.617,0,0,0,2.175,1.034,3.545,3.545,0,0,0,.381.021q.134,0,.268-.01a3.928,3.928,0,0,0,.663-.11,3.574,3.574,0,0,0,.67-.251,3.529,3.529,0,0,0,.952-.684L21.78,9.542a3.616,3.616,0,0,0-.007-5.112Z" transform="translate(-8.536 -3.375)" fill="#88888d"/>
                                          </g>
                                       </svg>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16.531" height="15.291" viewBox="0 0 16.531 15.291">
                                          <path id="Icon_material-favorite" data-name="Icon material-favorite" d="M10.515,18.291,9.426,17.3C5.555,13.789,3,11.474,3,8.633A4.093,4.093,0,0,1,7.133,4.5a4.5,4.5,0,0,1,3.382,1.571A4.5,4.5,0,0,1,13.9,4.5a4.093,4.093,0,0,1,4.133,4.133c0,2.841-2.555,5.155-6.426,8.673Z" transform="translate(-2.25 -3.75)" fill="none" stroke="#f26334" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                                       </svg>
                                    </a>
                                 </li>
                              </ul>
                              <button className="btn btn_border_orange">REGISTER</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="mobile_accordion_item">
                     <div className="m_ed_time_box">
                        <div className="time_box">
                           <p>12:00 <br/>13:00</p>
                        </div>
                        <div className="mobile_accordion_header">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsethree" aria-expanded="true" aria-controls="collapseOne">
                           <span className="coll_header">Trend Search - Explorin …</span>
                           <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                           </button>
                        </div>
                     </div>
                     <div id="collapsethree" className="collapse" aria-labelledby="headingthree" data-parent="#accordionExample">
                        <div className="session_info_box">
                           <div className="emerging_sectors mt-40">
                              <h1 className="mb-40">EMERGING SECTORS</h1>
                              <p>
                                 As the world evolves towards digital, the need of sharing data increases day by day. Sharing information with partners requires a common language not only in terms of syntaxes
                                 and technologies but also in terms of common definition of data, in business and not technical language. During the session, the GS1 strategy on semantic approach in standards
                                 definition will be presented, with a particular focus on Electronic Data Interchange Semantic methodology and Global Data Model.
                              </p>
                           </div>
                           <div className="row">
                              <div className="col-md-6 col-lg-6">
                                 <div className="client_info mb-20">
                                    <div className="client_info_img">
                                       <img src="/images/user_img1.png" />
                                    </div>
                                    <div className="client_info_content">
                                       <h4>Evi Janssen</h4>
                                       <p><span style={{color:"var(--event-primary) !important"}}>GS1 Belgium</span> - Sales Director</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 col-lg-6">
                                 <div className="client_info">
                                    <div className="client_info_img">
                                       <img src="/images/user_img2.png" />
                                    </div>
                                    <div className="client_info_content">
                                       <h4>Josi Smit</h4>
                                       <p><span style={{color:"var(--event-primary)"}}>GS1 Belgium</span> - Sales Director</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="session_info_register_area">
                              <ul className="nav icon">
                                 <li>
                                    <a href="">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="13.616" height="11.67" viewBox="0 0 13.616 11.67">
                                          <g id="Group_1650" data-name="Group 1650" transform="translate(0.891 1)">
                                             <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(12.725 -1) rotate(90)">
                                                <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M6.811,5.241,11.959.275a1,1,0,0,1,1.374,0,.921.921,0,0,1,0,1.328L7.5,7.23a1,1,0,0,1-1.342.027L.284,1.608a.919.919,0,0,1,0-1.328,1,1,0,0,1,1.374,0Z" transform="translate(0 13.616) rotate(-90)" fill="#88888d"/>
                                             </g>
                                             <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h11.462" transform="translate(-5191.758 -2980.687)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                          </g>
                                       </svg>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="">
                                       <svg id="Group_1649" data-name="Group 1649" xmlns="http://www.w3.org/2000/svg" width="14.298" height="14.292" viewBox="0 0 14.298 14.292">
                                          <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(0 0)">
                                             <path id="Path_1270" data-name="Path 1270" d="M11.345,18.374l-.041,0a.568.568,0,0,0-.33.155L8.755,20.751a2.5,2.5,0,0,1-3.539-3.539l2.357-2.357a2.487,2.487,0,0,1,.392-.319,2.525,2.525,0,0,1,.508-.258,2.4,2.4,0,0,1,.515-.127,2.436,2.436,0,0,1,.35-.024c.048,0,.1,0,.158.007a2.5,2.5,0,0,1,1.608.721,2.463,2.463,0,0,1,.587.938.541.541,0,0,0,.663.347s.007,0,.01,0,.007,0,.007,0a.537.537,0,0,0,.361-.656,3.113,3.113,0,0,0-.845-1.409,3.617,3.617,0,0,0-1.979-1.007c-.065-.01-.131-.021-.2-.027a3.545,3.545,0,0,0-.381-.021c-.089,0-.179,0-.265.01a3.465,3.465,0,0,0-.557.086c-.038.007-.072.017-.11.027a3.573,3.573,0,0,0-.67.251,3.529,3.529,0,0,0-.952.684L4.423,16.43a3.624,3.624,0,0,0-1.048,2.559,3.612,3.612,0,0,0,6.163,2.553L11.782,19.3A.544.544,0,0,0,11.345,18.374Z" transform="translate(-3.375 -8.305)" fill="#88888d"/>
                                             <path id="Path_1271" data-name="Path 1271" d="M21.773,4.43a3.62,3.62,0,0,0-5.109,0L14.476,6.618a.552.552,0,0,0,.347.941A.558.558,0,0,0,15.26,7.4l2.192-2.185A2.5,2.5,0,0,1,20.99,8.755l-2.357,2.357a2.487,2.487,0,0,1-.392.319,2.525,2.525,0,0,1-.508.258,2.4,2.4,0,0,1-.515.127,2.436,2.436,0,0,1-.35.024c-.048,0-.1,0-.158-.007a2.469,2.469,0,0,1-2.171-1.6.549.549,0,0,0-.656-.337.555.555,0,0,0-.388.7,3.138,3.138,0,0,0,.814,1.288h0l.007.007a3.617,3.617,0,0,0,2.175,1.034,3.545,3.545,0,0,0,.381.021q.134,0,.268-.01a3.928,3.928,0,0,0,.663-.11,3.574,3.574,0,0,0,.67-.251,3.529,3.529,0,0,0,.952-.684L21.78,9.542a3.616,3.616,0,0,0-.007-5.112Z" transform="translate(-8.536 -3.375)" fill="#88888d"/>
                                          </g>
                                       </svg>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16.531" height="15.291" viewBox="0 0 16.531 15.291">
                                          <path id="Icon_material-favorite" data-name="Icon material-favorite" d="M10.515,18.291,9.426,17.3C5.555,13.789,3,11.474,3,8.633A4.093,4.093,0,0,1,7.133,4.5a4.5,4.5,0,0,1,3.382,1.571A4.5,4.5,0,0,1,13.9,4.5a4.093,4.093,0,0,1,4.133,4.133c0,2.841-2.555,5.155-6.426,8.673Z" transform="translate(-2.25 -3.75)" fill="none" stroke="#f26334" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                                       </svg>
                                    </a>
                                 </li>
                              </ul>
                              <button className="btn btn_border_orange">REGISTER</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="mobile_accordion_item">
                     <div className="m_ed_time_box">
                        <div className="time_box">
                           <p>11:30 <br/>12:30</p>
                        </div>
                        <div className="mobile_accordion_header">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="true" aria-controls="collapseOne">
                              <span className="coll_header">Product Launch</span>
                              <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                              </button>
                        </div>
                     </div>
                     <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordionExample">
                     <div className="session_info_box">
                     <div className="emerging_sectors mt-40">
                     <h1 className="mb-40">EMERGING SECTORS</h1>
                     <p>
                     As the world evolves towards digital, the need of sharing data increases day by day. Sharing information with partners requires a common language not only in terms of syntaxes
                     and technologies but also in terms of common definition of data, in business and not technical language. During the session, the GS1 strategy on semantic approach in standards
                     definition will be presented, with a particular focus on Electronic Data Interchange Semantic methodology and Global Data Model.
                     </p>
                     </div>
                     <div className="row">
                     <div className="col-md-6 col-lg-6">
                     <div className="client_info mb-20">
                     <div className="client_info_img">
                     <img src="/images/user_img1.png" />
                     </div>
                     <div className="client_info_content">
                     <h4>Evi Janssen</h4>
                     <p><span style={{color:"var(--event-primary) !important"}}>GS1 Belgium</span> - Sales Director</p>
                     </div>
                     </div>
                     </div>
                     <div className="col-md-6 col-lg-6">
                     <div className="client_info">
                     <div className="client_info_img">
                     <img src="/images/user_img2.png" />
                     </div>
                     <div className="client_info_content">
                     <h4>Josi Smit</h4>
                     <p><span style={{color:"var(--event-primary)"}}>GS1 Belgium</span> - Sales Director</p>
                     </div>
                     </div>
                     </div>
                     </div>
                     <div className="session_info_register_area">
                     <ul className="nav icon">
                     <li>
                     <a href="">
                     <svg xmlns="http://www.w3.org/2000/svg" width="13.616" height="11.67" viewBox="0 0 13.616 11.67">
                     <g id="Group_1650" data-name="Group 1650" transform="translate(0.891 1)">
                     <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(12.725 -1) rotate(90)">
                     <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M6.811,5.241,11.959.275a1,1,0,0,1,1.374,0,.921.921,0,0,1,0,1.328L7.5,7.23a1,1,0,0,1-1.342.027L.284,1.608a.919.919,0,0,1,0-1.328,1,1,0,0,1,1.374,0Z" transform="translate(0 13.616) rotate(-90)" fill="#88888d"/>
                     </g>
                     <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h11.462" transform="translate(-5191.758 -2980.687)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                     </g>
                     </svg>
                     </a>
                     </li>
                     <li>
                     <a href="">
                     <svg id="Group_1649" data-name="Group 1649" xmlns="http://www.w3.org/2000/svg" width="14.298" height="14.292" viewBox="0 0 14.298 14.292">
                     <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(0 0)">
                     <path id="Path_1270" data-name="Path 1270" d="M11.345,18.374l-.041,0a.568.568,0,0,0-.33.155L8.755,20.751a2.5,2.5,0,0,1-3.539-3.539l2.357-2.357a2.487,2.487,0,0,1,.392-.319,2.525,2.525,0,0,1,.508-.258,2.4,2.4,0,0,1,.515-.127,2.436,2.436,0,0,1,.35-.024c.048,0,.1,0,.158.007a2.5,2.5,0,0,1,1.608.721,2.463,2.463,0,0,1,.587.938.541.541,0,0,0,.663.347s.007,0,.01,0,.007,0,.007,0a.537.537,0,0,0,.361-.656,3.113,3.113,0,0,0-.845-1.409,3.617,3.617,0,0,0-1.979-1.007c-.065-.01-.131-.021-.2-.027a3.545,3.545,0,0,0-.381-.021c-.089,0-.179,0-.265.01a3.465,3.465,0,0,0-.557.086c-.038.007-.072.017-.11.027a3.573,3.573,0,0,0-.67.251,3.529,3.529,0,0,0-.952.684L4.423,16.43a3.624,3.624,0,0,0-1.048,2.559,3.612,3.612,0,0,0,6.163,2.553L11.782,19.3A.544.544,0,0,0,11.345,18.374Z" transform="translate(-3.375 -8.305)" fill="#88888d"/>
                     <path id="Path_1271" data-name="Path 1271" d="M21.773,4.43a3.62,3.62,0,0,0-5.109,0L14.476,6.618a.552.552,0,0,0,.347.941A.558.558,0,0,0,15.26,7.4l2.192-2.185A2.5,2.5,0,0,1,20.99,8.755l-2.357,2.357a2.487,2.487,0,0,1-.392.319,2.525,2.525,0,0,1-.508.258,2.4,2.4,0,0,1-.515.127,2.436,2.436,0,0,1-.35.024c-.048,0-.1,0-.158-.007a2.469,2.469,0,0,1-2.171-1.6.549.549,0,0,0-.656-.337.555.555,0,0,0-.388.7,3.138,3.138,0,0,0,.814,1.288h0l.007.007a3.617,3.617,0,0,0,2.175,1.034,3.545,3.545,0,0,0,.381.021q.134,0,.268-.01a3.928,3.928,0,0,0,.663-.11,3.574,3.574,0,0,0,.67-.251,3.529,3.529,0,0,0,.952-.684L21.78,9.542a3.616,3.616,0,0,0-.007-5.112Z" transform="translate(-8.536 -3.375)" fill="#88888d"/>
                     </g>
                     </svg>
                     </a>
                     </li>
                     <li>
                     <a href="">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16.531" height="15.291" viewBox="0 0 16.531 15.291">
                     <path id="Icon_material-favorite" data-name="Icon material-favorite" d="M10.515,18.291,9.426,17.3C5.555,13.789,3,11.474,3,8.633A4.093,4.093,0,0,1,7.133,4.5a4.5,4.5,0,0,1,3.382,1.571A4.5,4.5,0,0,1,13.9,4.5a4.093,4.093,0,0,1,4.133,4.133c0,2.841-2.555,5.155-6.426,8.673Z" transform="translate(-2.25 -3.75)" fill="none" stroke="#f26334" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                     </svg>
                     </a>
                     </li>
                     </ul>
                     <button className="btn btn_border_orange">REGISTER</button>
                     </div>
                     </div>
                     </div>
                  </div>
                  <div className="mobile_accordion_item">
                     <div className="m_ed_time_box">
                        <div className="time_box">
                           <p>12:00 <br/>13:00</p>
                        </div>
                        <div className="mobile_accordion_header">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsefive" aria-expanded="true" aria-controls="collapseOne">
                           <span className="coll_header">Trend Search - Explorin …</span>
                           <span className="coll_icon"><i className="fas fa-angle-down"></i></span>
                           </button>
                        </div>
                     </div>
                     <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordionExample">
                        <div className="session_info_box">
                           <div className="emerging_sectors mt-40">
                              <h1 className="mb-40">EMERGING SECTORS</h1>
                              <p>
                                 As the world evolves towards digital, the need of sharing data increases day by day. Sharing information with partners requires a common language not only in terms of syntaxes
                                 and technologies but also in terms of common definition of data, in business and not technical language. During the session, the GS1 strategy on semantic approach in standards
                                 definition will be presented, with a particular focus on Electronic Data Interchange Semantic methodology and Global Data Model.
                              </p>
                           </div>
                           <div className="row">
                              <div className="col-md-6 col-lg-6">
                                 <div className="client_info mb-20">
                                    <div className="client_info_img">
                                       <img src="/images/user_img1.png" />
                                    </div>
                                    <div className="client_info_content">
                                       <h4>Evi Janssen</h4>
                                       <p><span style={{color:"var(--event-primary) !important"}}>GS1 Belgium</span> - Sales Director</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 col-lg-6">
                                 <div className="client_info">
                                    <div className="client_info_img">
                                       <img src="/images/user_img2.png" />
                                    </div>
                                    <div className="client_info_content">
                                       <h4>Josi Smit</h4>
                                       <p><span style={{color:"var(--event-primary)"}}>GS1 Belgium</span> - Sales Director</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="session_info_register_area">
                              <ul className="nav icon">
                                 <li>
                                    <a href="">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="13.616" height="11.67" viewBox="0 0 13.616 11.67">
                                          <g id="Group_1650" data-name="Group 1650" transform="translate(0.891 1)">
                                             <g id="ICON_-_arrow" data-name="ICON - arrow" transform="translate(12.725 -1) rotate(90)">
                                                <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M6.811,5.241,11.959.275a1,1,0,0,1,1.374,0,.921.921,0,0,1,0,1.328L7.5,7.23a1,1,0,0,1-1.342.027L.284,1.608a.919.919,0,0,1,0-1.328,1,1,0,0,1,1.374,0Z" transform="translate(0 13.616) rotate(-90)" fill="#88888d"/>
                                             </g>
                                             <path id="Path_1415" data-name="Path 1415" d="M5191.943,2990.357h11.462" transform="translate(-5191.758 -2980.687)" fill="none" stroke="#88888d" strokeLinecap="round" strokeWidth="2"/>
                                          </g>
                                       </svg>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="">
                                       <svg id="Group_1649" data-name="Group 1649" xmlns="http://www.w3.org/2000/svg" width="14.298" height="14.292" viewBox="0 0 14.298 14.292">
                                          <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(0 0)">
                                             <path id="Path_1270" data-name="Path 1270" d="M11.345,18.374l-.041,0a.568.568,0,0,0-.33.155L8.755,20.751a2.5,2.5,0,0,1-3.539-3.539l2.357-2.357a2.487,2.487,0,0,1,.392-.319,2.525,2.525,0,0,1,.508-.258,2.4,2.4,0,0,1,.515-.127,2.436,2.436,0,0,1,.35-.024c.048,0,.1,0,.158.007a2.5,2.5,0,0,1,1.608.721,2.463,2.463,0,0,1,.587.938.541.541,0,0,0,.663.347s.007,0,.01,0,.007,0,.007,0a.537.537,0,0,0,.361-.656,3.113,3.113,0,0,0-.845-1.409,3.617,3.617,0,0,0-1.979-1.007c-.065-.01-.131-.021-.2-.027a3.545,3.545,0,0,0-.381-.021c-.089,0-.179,0-.265.01a3.465,3.465,0,0,0-.557.086c-.038.007-.072.017-.11.027a3.573,3.573,0,0,0-.67.251,3.529,3.529,0,0,0-.952.684L4.423,16.43a3.624,3.624,0,0,0-1.048,2.559,3.612,3.612,0,0,0,6.163,2.553L11.782,19.3A.544.544,0,0,0,11.345,18.374Z" transform="translate(-3.375 -8.305)" fill="#88888d"/>
                                             <path id="Path_1271" data-name="Path 1271" d="M21.773,4.43a3.62,3.62,0,0,0-5.109,0L14.476,6.618a.552.552,0,0,0,.347.941A.558.558,0,0,0,15.26,7.4l2.192-2.185A2.5,2.5,0,0,1,20.99,8.755l-2.357,2.357a2.487,2.487,0,0,1-.392.319,2.525,2.525,0,0,1-.508.258,2.4,2.4,0,0,1-.515.127,2.436,2.436,0,0,1-.35.024c-.048,0-.1,0-.158-.007a2.469,2.469,0,0,1-2.171-1.6.549.549,0,0,0-.656-.337.555.555,0,0,0-.388.7,3.138,3.138,0,0,0,.814,1.288h0l.007.007a3.617,3.617,0,0,0,2.175,1.034,3.545,3.545,0,0,0,.381.021q.134,0,.268-.01a3.928,3.928,0,0,0,.663-.11,3.574,3.574,0,0,0,.67-.251,3.529,3.529,0,0,0,.952-.684L21.78,9.542a3.616,3.616,0,0,0-.007-5.112Z" transform="translate(-8.536 -3.375)" fill="#88888d"/>
                                          </g>
                                       </svg>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16.531" height="15.291" viewBox="0 0 16.531 15.291">
                                          <path id="Icon_material-favorite" data-name="Icon material-favorite" d="M10.515,18.291,9.426,17.3C5.555,13.789,3,11.474,3,8.633A4.093,4.093,0,0,1,7.133,4.5a4.5,4.5,0,0,1,3.382,1.571A4.5,4.5,0,0,1,13.9,4.5a4.093,4.093,0,0,1,4.133,4.133c0,2.841-2.555,5.155-6.426,8.673Z" transform="translate(-2.25 -3.75)" fill="none" stroke="#f26334" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                                       </svg>
                                    </a>
                                 </li>
                              </ul>
                              <button className="btn btn_border_orange">REGISTER</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <!-- mobile_action_ed_time --> */}
            </div>
         </section>

         <section className="participant_details_area mt-50 pb-100">
            <div className="view_all_btn overview_btn">
               <button className="btn">
               <span> <i className="fa fa-angle-right" aria-hidden="true"></i></span>Back to overview
               </button>
            </div>
            <div className="container">
               <div className="participant__head">
                  <div className="icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="36.781" height="36.781" viewBox="0 0 36.781 36.781">
                        <path
                           id="Icon_ionic-md-people"
                           data-name="Icon ionic-md-people"
                           d="M20.455,23.2a7.756,7.756,0,0,0,7.8-7.663,7.8,7.8,0,0,0-15.6,0A7.756,7.756,0,0,0,20.455,23.2Zm0,5.619c-6.112,0-18.2,2.937-18.2,8.94v6.9H39.031v-6.9C39.031,31.757,26.567,28.82,20.455,28.82Z"
                           transform="translate(-2.25 -7.875)"
                           fill="#f26334"
                           />
                     </svg>
                  </div>
                  <div className="details">
                     <h4>SPEAKERS</h4>
                     <p>Markus Mueller</p>
                  </div>
               </div>
               <div className="participant_body mt-40">
                  <div className="row">
                     <div className="col-md-12 col-lg-4">
                        <div className="participant_profile">
                           <div className="img_box">
                              <img src="/images/video_chat_img1.png" />
                              <p>Director Global Data Model Programme</p>
                              <h6>GS1 Belgium</h6>
                           </div>
                           <div className="social_btn">
                              <button type="button" className="btn">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="30.951" height="30.951" viewBox="0 0 30.951 30.951">
                                    <path
                                       id="Icon_simple-linkedin"
                                       data-name="Icon simple-linkedin"
                                       d="M26.369,26.376H21.786V19.194c0-1.713-.035-3.917-2.388-3.917-2.39,0-2.755,1.864-2.755,3.79v7.308H12.059V11.607h4.4V13.62h.059a4.83,4.83,0,0,1,4.346-2.386c4.644,0,5.5,3.056,5.5,7.035v8.107ZM6.883,9.586A2.662,2.662,0,1,1,9.545,6.923,2.659,2.659,0,0,1,6.883,9.586Zm2.3,16.79h-4.6V11.607h4.6ZM28.662,0H2.284A2.257,2.257,0,0,0,0,2.23V28.721a2.256,2.256,0,0,0,2.284,2.23H28.658a2.264,2.264,0,0,0,2.293-2.23V2.23A2.265,2.265,0,0,0,28.658,0Z"
                                       fill="#f26334"
                                       />
                                 </svg>
                              </button>
                              <button type="button" className="btn">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="30.951" height="30.951" viewBox="0 0 30.951 30.951">
                                    <path
                                       id="Icon_awesome-twitter-square"
                                       data-name="Icon awesome-twitter-square"
                                       d="M27.635,2.25H3.316A3.317,3.317,0,0,0,0,5.566V29.885A3.317,3.317,0,0,0,3.316,33.2H27.635a3.317,3.317,0,0,0,3.316-3.316V5.566A3.317,3.317,0,0,0,27.635,2.25ZM24.257,13.221c.014.193.014.394.014.587A12.8,12.8,0,0,1,11.379,26.7a12.832,12.832,0,0,1-6.957-2.031,9.546,9.546,0,0,0,1.092.055,9.085,9.085,0,0,0,5.624-1.934A4.537,4.537,0,0,1,6.9,19.646a4.883,4.883,0,0,0,2.045-.083A4.531,4.531,0,0,1,5.32,15.114v-.055a4.528,4.528,0,0,0,2.045.573A4.522,4.522,0,0,1,5.347,11.86a4.475,4.475,0,0,1,.615-2.287A12.866,12.866,0,0,0,15.3,14.313a4.541,4.541,0,0,1,7.731-4.138,8.879,8.879,0,0,0,2.874-1.092,4.519,4.519,0,0,1-1.99,2.494,9.017,9.017,0,0,0,2.612-.7A9.537,9.537,0,0,1,24.257,13.221Z"
                                       transform="translate(0 -2.25)"
                                       fill="#f26334"
                                       />
                                 </svg>
                              </button>
                              <button type="button" className="btn">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="31.405" height="31.405" viewBox="0 0 31.405 31.405">
                                    <path
                                       id="Icon_awesome-facebook-square"
                                       data-name="Icon awesome-facebook-square"
                                       d="M28.04,2.25H3.365A3.365,3.365,0,0,0,0,5.615V30.29a3.365,3.365,0,0,0,3.365,3.365h9.621V22.978H8.57V17.953h4.416v-3.83c0-4.357,2.594-6.763,6.566-6.763a26.755,26.755,0,0,1,3.892.339v4.276H21.253a2.513,2.513,0,0,0-2.833,2.715v3.263h4.822l-.771,5.026h-4.05V33.655H28.04a3.365,3.365,0,0,0,3.365-3.365V5.615A3.365,3.365,0,0,0,28.04,2.25Z"
                                       transform="translate(0 -2.25)"
                                       fill="#f26334"
                                       />
                                 </svg>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-8">
                        <div className="participant_details">
                           <h4>ABOUT</h4>
                           <p>
                              A digital marketing professional with twenty years of experience working with multi-national businesses around the world. Currently, I am the co-founder and CEO of Tambo, The Marketplace Services
                              Platform that helps businesses grow on Amazon and other marketplaces in the UK and Internationally.
                           </p>
                           <p>
                              A digital marketing professional with twenty years of experience working with multi-national businesses around the world. Currently, I am the co-founder and CEO of Tambo, The Marketplace Services
                              Platform that helps businesses grow on Amazon and other marketplaces in the UK and Internationally.
                           </p>
                           <div className="participant_connect_btn mt-100">
                              <button type="button" className="btn" data-toggle="modal" data-target="#connection">
                                 <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.01" height="15.27" viewBox="0 0 18.01 15.27">
                                       <path
                                          id="Icon_awesome-hands-helping"
                                          data-name="Icon awesome-hands-helping"
                                          d="M13.731,5.728H9.454V7.4A2.092,2.092,0,0,1,7.428,9.545,2.092,2.092,0,0,1,5.4,7.4V3.771L3.576,4.934A1.931,1.931,0,0,0,2.7,6.569V7.98L.449,9.358a.984.984,0,0,0-.329,1.3L2.371,14.8a.874.874,0,0,0,1.23.349l2.91-1.781h3.844a1.859,1.859,0,0,0,1.8-1.909h.45a.928.928,0,0,0,.9-.954V8.591h.225a.7.7,0,0,0,.675-.716V6.443A.7.7,0,0,0,13.731,5.728Zm4.156-1.116L15.636.478a.874.874,0,0,0-1.23-.349L11.5,1.91H8.621a1.739,1.739,0,0,0-.954.289l-.943.623a.962.962,0,0,0-.422.808V7.4a1.128,1.128,0,1,0,2.251,0V4.773h5.178a1.626,1.626,0,0,1,1.576,1.67v.85l2.251-1.378A.987.987,0,0,0,17.888,4.612Z"
                                          transform="translate(0.002 -0.002)"
                                          fill="#454545"
                                          />
                                    </svg>
                                 </span>
                                 CONNECT
                              </button>
                              <button type="button" className="btn">
                                 <span>
                                    <svg id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" xmlns="http://www.w3.org/2000/svg" width="15.037" height="15.037" viewBox="0 0 15.037 15.037">
                                       <path
                                          id="Path_1272"
                                          data-name="Path 1272"
                                          d="M19.553,13.229a.876.876,0,0,1,.119-.441,1.217,1.217,0,0,1,.076-.112,5.857,5.857,0,0,0,1-3.264,6.149,6.149,0,0,0-6.279-6.037,6.236,6.236,0,0,0-6.156,4.8,5.809,5.809,0,0,0-.134,1.236,6.132,6.132,0,0,0,6.181,6.113,7.444,7.444,0,0,0,1.706-.278c.408-.112.813-.26.918-.3a.956.956,0,0,1,.336-.061.94.94,0,0,1,.365.072l2.05.727a.489.489,0,0,0,.141.036.288.288,0,0,0,.289-.289.464.464,0,0,0-.018-.1Z"
                                          transform="translate(-5.708 -3.375)"
                                          fill="#454545"
                                          />
                                       <path
                                          id="Path_1273"
                                          data-name="Path 1273"
                                          d="M13.153,20.457c-.13.036-.3.076-.477.116a6.671,6.671,0,0,1-1.229.163,6.132,6.132,0,0,1-6.181-6.113,6.834,6.834,0,0,1,.054-.774c.022-.155.047-.311.083-.463s.08-.325.127-.484l-.289.257A5.379,5.379,0,0,0,3.375,17.2a5.319,5.319,0,0,0,.9,2.964c.083.127.13.224.116.289s-.43,2.241-.43,2.241a.29.29,0,0,0,.1.278.294.294,0,0,0,.184.065.259.259,0,0,0,.1-.022l2.028-.8a.565.565,0,0,1,.434.007A6.084,6.084,0,0,0,9,22.662a5.677,5.677,0,0,0,4.341-1.992s.116-.159.249-.347C13.457,20.37,13.3,20.417,13.153,20.457Z"
                                          transform="translate(-3.375 -8.004)"
                                          fill="#454545"
                                          />
                                    </svg>
                                 </span>
                                 MESSAGE
                              </button>
                              <button type="button" className="btn" data-toggle="modal" data-target="#outging_call">
                                 <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.627" height="12.141" viewBox="0 0 18.627 12.141">
                                       <path
                                          id="Icon_ionic-md-videocam"
                                          data-name="Icon ionic-md-videocam"
                                          d="M16.886,12.287V8.745a1.025,1.025,0,0,0-1.035-1.01H3.285A1.025,1.025,0,0,0,2.25,8.745V18.861a1.028,1.028,0,0,0,1.035,1.015H15.85a1.028,1.028,0,0,0,1.035-1.01V15.322l3.992,4.046V8.242Z"
                                          transform="translate(-2.25 -7.734)"
                                          fill="#454545"
                                          />
                                    </svg>
                                 </span>
                                 CALL
                              </button>
                              <button type="button" className="btn" data-toggle="modal" data-target="#sehedule_appointment">
                                 <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.282" height="15.04" viewBox="0 0 13.282 15.04">
                                       <path
                                          id="Icon_awesome-calendar-plus"
                                          data-name="Icon awesome-calendar-plus"
                                          d="M12.927,4.7H.356A.355.355,0,0,1,0,4.347V3.29A1.417,1.417,0,0,1,1.423,1.88H2.846V.352A.355.355,0,0,1,3.2,0H4.388a.355.355,0,0,1,.356.352V1.88H8.539V.352A.355.355,0,0,1,8.894,0H10.08a.355.355,0,0,1,.356.352V1.88h1.423a1.417,1.417,0,0,1,1.423,1.41V4.347A.355.355,0,0,1,12.927,4.7ZM.356,5.64H12.927a.355.355,0,0,1,.356.352V13.63a1.417,1.417,0,0,1-1.423,1.41H1.423A1.417,1.417,0,0,1,0,13.63V5.992A.355.355,0,0,1,.356,5.64ZM9.725,9.752A.355.355,0,0,0,9.369,9.4H7.59V7.637a.355.355,0,0,0-.356-.352H6.048a.355.355,0,0,0-.356.352V9.4H3.914a.355.355,0,0,0-.356.352v1.175a.355.355,0,0,0,.356.352H5.692v1.762a.355.355,0,0,0,.356.352H7.234a.355.355,0,0,0,.356-.352V11.28H9.369a.355.355,0,0,0,.356-.352Z"
                                          fill="#454545"
                                          />
                                    </svg>
                                 </span>
                                 SCHEDULE APPOINTMENT
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="participant_session mt-100">
                  <h1 className="main_heading mb-20">SESSIONS</h1>
                  <ul className="nav eu_time_tab">
                     <li className="active"><a href="#!">EU TIME</a></li>
                     <li><a href="#!">DEVICE TIME</a></li>
                  </ul>
                  <div className="session_accordion_box mb-20 mt-40">
                     <div className="accordion destop_ed_time" id="accordionExample">
                        <div className="session_accordion_header">
                           <div className="date live">
                              <p>
                                 Live <br />
                                 15.00 - 16.00
                              </p>
                           </div>
                           <div className="description collapse">
                              <button className="btn" type="button">
                                 <h4>GS1 in maritime - Full steam ahead</h4>
                                 <span><img src="/images/files.png" /></span>
                              </button>
                              <span className="link">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="25.87" height="25.857" viewBox="0 0 25.87 25.857">
                                    <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(-3.375 -3.375)">
                                       <path
                                          id="Path_1270"
                                          data-name="Path 1270"
                                          d="M17.8,22.711l-.075.006a1.028,1.028,0,0,0-.6.28l-4.015,4.015a4.527,4.527,0,0,1-6.4-6.4l4.264-4.264a4.5,4.5,0,0,1,.709-.578,4.569,4.569,0,0,1,.92-.466,4.352,4.352,0,0,1,.932-.23,4.408,4.408,0,0,1,.634-.044c.087,0,.174.006.286.012a4.514,4.514,0,0,1,2.909,1.305,4.457,4.457,0,0,1,1.063,1.7.978.978,0,0,0,1.2.628c.006,0,.012-.006.019-.006s.012,0,.012-.006a.971.971,0,0,0,.653-1.187,5.633,5.633,0,0,0-1.529-2.548A6.543,6.543,0,0,0,15.2,13.1c-.118-.019-.236-.037-.354-.05a6.415,6.415,0,0,0-.69-.037c-.162,0-.323.006-.479.019a6.269,6.269,0,0,0-1.007.155c-.068.012-.131.031-.2.05a6.466,6.466,0,0,0-1.212.454,6.385,6.385,0,0,0-1.722,1.237L5.271,19.193a6.557,6.557,0,0,0-1.9,4.631,6.536,6.536,0,0,0,11.151,4.618l4.059-4.059A.984.984,0,0,0,17.8,22.711Z"
                                          transform="translate(0 -1.118)"
                                          fill="#88888d"
                                          ></path>
                                       <path
                                          id="Path_1271"
                                          data-name="Path 1271"
                                          d="M28.495,5.283a6.55,6.55,0,0,0-9.243,0L15.293,9.243a1,1,0,0,0,.628,1.7,1.01,1.01,0,0,0,.789-.286l3.966-3.953a4.527,4.527,0,0,1,6.4,6.4l-4.264,4.264a4.5,4.5,0,0,1-.709.578,4.569,4.569,0,0,1-.92.466,4.352,4.352,0,0,1-.932.23,4.408,4.408,0,0,1-.634.044c-.087,0-.18-.006-.286-.012a4.467,4.467,0,0,1-3.928-2.89.993.993,0,0,0-1.187-.609,1,1,0,0,0-.7,1.274,5.678,5.678,0,0,0,1.473,2.331h0L15,18.8a6.544,6.544,0,0,0,3.935,1.871,6.414,6.414,0,0,0,.69.037q.242,0,.485-.019a7.107,7.107,0,0,0,1.2-.2,6.466,6.466,0,0,0,1.212-.454A6.385,6.385,0,0,0,24.243,18.8l4.264-4.264a6.543,6.543,0,0,0-.012-9.249Z"
                                          transform="translate(-1.171)"
                                          fill="#88888d"
                                          ></path>
                                    </g>
                                 </svg>
                              </span>
                           </div>
                        </div>
                     </div>
                     <div className="session_accordion_btn">
                        <button type="button" className="btn green">
                        JOIN
                        </button>
                     </div>
                     <div className="session_accordion_fav">
                        <button type="button" className="btn">
                           <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27.525" viewBox="0 0 30 27.525">
                              <path
                                 id="Icon_material-favorite"
                                 data-name="Icon material-favorite"
                                 d="M18,32.025l-2.175-1.98C8.1,23.04,3,18.42,3,12.75A8.17,8.17,0,0,1,11.25,4.5,8.983,8.983,0,0,1,18,7.635,8.983,8.983,0,0,1,24.75,4.5,8.17,8.17,0,0,1,33,12.75c0,5.67-5.1,10.29-12.825,17.31Z"
                                 transform="translate(-3 -4.5)"
                                 fill="#f26334"
                                 ></path>
                           </svg>
                        </button>
                     </div>
                  </div>
                  <div className="session_accordion_box mb-20">
                     <div className="accordion destop_ed_time" id="accordionExample">
                        <div className="session_accordion_header">
                           <div className="date">
                              <p>
                                 15.00 - 16.00 <br />
                                 Sat 10/07
                              </p>
                           </div>
                           <div className="description collapse">
                              <button className="btn" type="button">
                                 <h4>Perspective from a marketplace - How they use GS1 standards</h4>
                                 <span><img src="/images/files.png" /></span>
                              </button>
                              <span className="link">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="25.87" height="25.857" viewBox="0 0 25.87 25.857">
                                    <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(-3.375 -3.375)">
                                       <path
                                          id="Path_1270"
                                          data-name="Path 1270"
                                          d="M17.8,22.711l-.075.006a1.028,1.028,0,0,0-.6.28l-4.015,4.015a4.527,4.527,0,0,1-6.4-6.4l4.264-4.264a4.5,4.5,0,0,1,.709-.578,4.569,4.569,0,0,1,.92-.466,4.352,4.352,0,0,1,.932-.23,4.408,4.408,0,0,1,.634-.044c.087,0,.174.006.286.012a4.514,4.514,0,0,1,2.909,1.305,4.457,4.457,0,0,1,1.063,1.7.978.978,0,0,0,1.2.628c.006,0,.012-.006.019-.006s.012,0,.012-.006a.971.971,0,0,0,.653-1.187,5.633,5.633,0,0,0-1.529-2.548A6.543,6.543,0,0,0,15.2,13.1c-.118-.019-.236-.037-.354-.05a6.415,6.415,0,0,0-.69-.037c-.162,0-.323.006-.479.019a6.269,6.269,0,0,0-1.007.155c-.068.012-.131.031-.2.05a6.466,6.466,0,0,0-1.212.454,6.385,6.385,0,0,0-1.722,1.237L5.271,19.193a6.557,6.557,0,0,0-1.9,4.631,6.536,6.536,0,0,0,11.151,4.618l4.059-4.059A.984.984,0,0,0,17.8,22.711Z"
                                          transform="translate(0 -1.118)"
                                          fill="#88888d"
                                          ></path>
                                       <path
                                          id="Path_1271"
                                          data-name="Path 1271"
                                          d="M28.495,5.283a6.55,6.55,0,0,0-9.243,0L15.293,9.243a1,1,0,0,0,.628,1.7,1.01,1.01,0,0,0,.789-.286l3.966-3.953a4.527,4.527,0,0,1,6.4,6.4l-4.264,4.264a4.5,4.5,0,0,1-.709.578,4.569,4.569,0,0,1-.92.466,4.352,4.352,0,0,1-.932.23,4.408,4.408,0,0,1-.634.044c-.087,0-.18-.006-.286-.012a4.467,4.467,0,0,1-3.928-2.89.993.993,0,0,0-1.187-.609,1,1,0,0,0-.7,1.274,5.678,5.678,0,0,0,1.473,2.331h0L15,18.8a6.544,6.544,0,0,0,3.935,1.871,6.414,6.414,0,0,0,.69.037q.242,0,.485-.019a7.107,7.107,0,0,0,1.2-.2,6.466,6.466,0,0,0,1.212-.454A6.385,6.385,0,0,0,24.243,18.8l4.264-4.264a6.543,6.543,0,0,0-.012-9.249Z"
                                          transform="translate(-1.171)"
                                          fill="#88888d"
                                          ></path>
                                    </g>
                                 </svg>
                              </span>
                           </div>
                        </div>
                     </div>
                     <div className="session_accordion_btn">
                        <button type="button" className="btn border-grey">
                        REGISTER
                        </button>
                     </div>
                     <div className="session_accordion_fav">
                        <button type="button" className="btn">
                           <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27.525" viewBox="0 0 30 27.525">
                              <path
                                 id="Icon_material-favorite"
                                 data-name="Icon material-favorite"
                                 d="M18,32.025l-2.175-1.98C8.1,23.04,3,18.42,3,12.75A8.17,8.17,0,0,1,11.25,4.5,8.983,8.983,0,0,1,18,7.635,8.983,8.983,0,0,1,24.75,4.5,8.17,8.17,0,0,1,33,12.75c0,5.67-5.1,10.29-12.825,17.31Z"
                                 transform="translate(-3 -4.5)"
                                 fill="#f26334"
                                 ></path>
                           </svg>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

      {/* <!-- connection_model --> */}
      {/* <!-- The Modal --> */}
      <div className="modal fade connection_request" id="connection">
         <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
               {/* <!-- Modal Header --> */}
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
               </div>
               {/* <!-- Modal body --> */}
               <div className="modal-body">
                  <div className="connection_request_body">
                     <div className="row">
                        <div className="col-md-12 col-lg-6">
                           <div className="connection_profile">
                              <div className="connection_p_head">
                                 <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="53.609" height="46.135" viewBox="0 0 53.609 46.135">
                                       <path
                                          id="Icon_awesome-hands-helping"
                                          data-name="Icon awesome-hands-helping"
                                          d="M40.876,17.3H28.145v5.046a6.279,6.279,0,0,1-6.031,6.488,6.279,6.279,0,0,1-6.031-6.488V11.39L10.647,14.9a5.856,5.856,0,0,0-2.605,4.938v4.262l-6.7,4.163a3,3,0,0,0-.98,3.938l6.7,12.49a2.581,2.581,0,0,0,3.66,1.054l8.661-5.38H30.825A5.58,5.58,0,0,0,36.186,34.6h1.34a2.785,2.785,0,0,0,2.68-2.884V25.953h.67a2.086,2.086,0,0,0,2.01-2.163V19.465A2.086,2.086,0,0,0,40.876,17.3Zm12.372-3.37-6.7-12.49A2.581,2.581,0,0,0,42.887.387l-8.661,5.38H25.665a5.123,5.123,0,0,0-2.84.874L20.02,8.524a2.92,2.92,0,0,0-1.256,2.442V22.348a3.484,3.484,0,0,0,3.351,3.6,3.484,3.484,0,0,0,3.351-3.6v-7.93H40.876a4.881,4.881,0,0,1,4.691,5.046v2.568l6.7-4.163A3.009,3.009,0,0,0,53.248,13.931Z"
                                          transform="translate(0.002 -0.001)"
                                          fill="#f26334"
                                          />
                                    </svg>
                                 </span>
                                 <div className="info mb-30">
                                    <h4>Connection Request</h4>
                                    <p>Brit Hansen</p>
                                 </div>
                              </div>
                              <div className="connection_p_content">
                                 <img src="/images/vault_img1.png" />
                                 <div className="con_p_social">
                                    <button type="button" className="btn">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="30.951" height="30.951" viewBox="0 0 30.951 30.951">
                                          <path
                                             id="Icon_simple-linkedin"
                                             data-name="Icon simple-linkedin"
                                             d="M26.369,26.376H21.786V19.194c0-1.713-.035-3.917-2.388-3.917-2.39,0-2.755,1.864-2.755,3.79v7.308H12.059V11.607h4.4V13.62h.059a4.83,4.83,0,0,1,4.346-2.386c4.644,0,5.5,3.056,5.5,7.035v8.107ZM6.883,9.586A2.662,2.662,0,1,1,9.545,6.923,2.659,2.659,0,0,1,6.883,9.586Zm2.3,16.79h-4.6V11.607h4.6ZM28.662,0H2.284A2.257,2.257,0,0,0,0,2.23V28.721a2.256,2.256,0,0,0,2.284,2.23H28.658a2.264,2.264,0,0,0,2.293-2.23V2.23A2.265,2.265,0,0,0,28.658,0Z"
                                             fill="#454545"
                                             />
                                       </svg>
                                    </button>
                                    <button type="button" className="btn">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="30.951" height="30.951" viewBox="0 0 30.951 30.951">
                                          <path
                                             id="Icon_awesome-twitter-square"
                                             data-name="Icon awesome-twitter-square"
                                             d="M27.635,2.25H3.316A3.317,3.317,0,0,0,0,5.566V29.885A3.317,3.317,0,0,0,3.316,33.2H27.635a3.317,3.317,0,0,0,3.316-3.316V5.566A3.317,3.317,0,0,0,27.635,2.25ZM24.257,13.221c.014.193.014.394.014.587a13.521,13.521,0,0,1-4.376,9.754A12.309,12.309,0,0,1,11.379,26.7a12.832,12.832,0,0,1-6.957-2.031,9.546,9.546,0,0,0,1.092.055,9.085,9.085,0,0,0,5.624-1.934A4.537,4.537,0,0,1,6.9,19.646a4.883,4.883,0,0,0,2.045-.083A4.531,4.531,0,0,1,5.32,15.114v-.055a4.528,4.528,0,0,0,2.045.573A4.522,4.522,0,0,1,5.347,11.86a4.475,4.475,0,0,1,.615-2.287A12.866,12.866,0,0,0,15.3,14.313a4.541,4.541,0,0,1,7.731-4.138,8.879,8.879,0,0,0,2.874-1.092,4.519,4.519,0,0,1-1.99,2.494,9.017,9.017,0,0,0,2.612-.7A9.537,9.537,0,0,1,24.257,13.221Z"
                                             transform="translate(0 -2.25)"
                                             fill="#454545"
                                             />
                                       </svg>
                                    </button>
                                    <button type="button" className="btn">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="31.405" height="31.405" viewBox="0 0 31.405 31.405">
                                          <path
                                             id="Icon_awesome-facebook-square"
                                             data-name="Icon awesome-facebook-square"
                                             d="M28.04,2.25H3.365A3.365,3.365,0,0,0,0,5.615V30.29a3.365,3.365,0,0,0,3.365,3.365h9.621V22.978H8.57V17.953h4.416v-3.83c0-4.357,2.594-6.763,6.566-6.763a26.755,26.755,0,0,1,3.892.339v4.276H21.253a2.513,2.513,0,0,0-2.833,2.715v3.263h4.822l-.771,5.026h-4.05V33.655H28.04a3.365,3.365,0,0,0,3.365-3.365V5.615A3.365,3.365,0,0,0,28.04,2.25Z"
                                             transform="translate(0 -2.25)"
                                             fill="#454545"
                                             />
                                       </svg>
                                    </button>
                                 </div>
                                 <h6>Director Global Data Model Programme</h6>
                                 <h5>GS1 Belgium</h5>
                                 <p>Here comes the persons description. This can be for example 400 characters of text what you can change in the My account</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                           <div className="connection_form">
                              <div className="online_button">
                                 <button className="btn btn_theme_blue">ONLINE</button>
                              </div>
                              <form action="/action_page.php" className="mt-40">
                                 <input type="text" className="form-control" placeholder="" id="text" disabled="" />
                                 <input type="text" className="form-control" placeholder="" id="text" disabled="" />
                                 <input type="text" className="form-control" placeholder="" id="text" disabled="" />
                                 <textarea className="form-control" rows="5" id="comment" className="form-control" defaultValue={"Message *"}/>
                                 <button type="send" className="btn btn_border_orange">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.808" height="20.13" viewBox="0 0 21.808 20.13">
                                       <path id="Icon_ionic-md-send" data-name="Icon ionic-md-send" d="M3.375,24.63,25.183,14.565,3.375,4.5v7.828L19,14.565,3.375,16.8Z" transform="translate(-3.375 -4.5)" fill="#454545" />
                                    </svg>
                                    send
                                 </button>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      {/* <!-- call-model --> */}
      {/* <!-- The Modal --> */}
      <div className="modal fade connection_request outging_call" id="outging_call">
         <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
               {/* <!-- Modal Header --> */}
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
               </div>
               {/* <!-- Modal body --> */}
               <div className="modal-body">
                  <div className="connection_request_body">
                     <div className="row">
                        <div className="col-md-12 col-lg-6">
                           <div className="connection_p_head">
                              <span className="icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="31.34" height="20.427" viewBox="0 0 31.34 20.427">
                                    <path
                                       id="Icon_ionic-md-videocam"
                                       data-name="Icon ionic-md-videocam"
                                       d="M26.874,15.394V9.434a1.725,1.725,0,0,0-1.742-1.7H3.992a1.725,1.725,0,0,0-1.742,1.7v17.02a1.73,1.73,0,0,0,1.742,1.707h21.14a1.729,1.729,0,0,0,1.742-1.7V20.5l6.716,6.807V8.588Z"
                                       transform="translate(-2.25 -7.734)"
                                       fill="#f26334"
                                       />
                                 </svg>
                              </span>
                              <div className="info mb-30">
                                 <h4>Outgoing call</h4>
                                 <p>Dirk de Langhe</p>
                              </div>
                           </div>
                           <div className="outgoing_img">
                              <img src="/images/video_chat_img6.png" className="img_left" />
                              <img src="/images/video_chat_img3.png" className="img_right" />
                           </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                           <div className="outgoing_right_box mt-100">
                              <h6>You are calling Dirk de Langhe</h6>
                              <h5>Marketing Manager</h5>
                              <p>Torfs</p>
                              <form action="/action_page.php" className="mt-40">
                                 <textarea className="form-control" rows="5" id="comment" className="form-control" defaultValue={"Message *"}/>
                              </form>
                           </div>
                        </div>
                     </div>
                     <div className="calling-btn">
                        <a href="active-call.html">
                           <button type="send" className="btn btn_border_orange">
                              <svg xmlns="http://www.w3.org/2000/svg" width="40.5" height="27" viewBox="0 0 40.5 27">
                                 <path
                                    id="Icon_awesome-video"
                                    data-name="Icon awesome-video"
                                    d="M23.639,4.5H3.361A3.361,3.361,0,0,0,0,7.861V28.139A3.361,3.361,0,0,0,3.361,31.5H23.639A3.361,3.361,0,0,0,27,28.139V7.861A3.361,3.361,0,0,0,23.639,4.5ZM36.956,7.151,29.25,12.466V23.534l7.706,5.309A2.255,2.255,0,0,0,40.5,27.028V8.965A2.255,2.255,0,0,0,36.956,7.151Z"
                                    transform="translate(0 -4.5)"
                                    fill="#454545"
                                    />
                              </svg>
                              Call
                           </button>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      {/* <!-- The Modal --> */}
      <div className="modal fade connection_request sehedule_appointment" id="sehedule_appointment">
         <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
               {/* <!-- Modal Header --> */}
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
               </div>
               {/* <!-- Modal body --> */}
               <div className="modal-body">
                  <div className="connection_request_body">
                     <div className="connection_p_head">
                        <span className="icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="29.146" height="33.002" viewBox="0 0 29.146 33.002">
                                      <path id="Icon_awesome-calendar-plus" data-name="Icon awesome-calendar-plus" d="M28.365,10.313H.781A.779.779,0,0,1,0,9.54V7.219A3.109,3.109,0,0,1,3.123,4.125H6.246V.773A.779.779,0,0,1,7.026,0h2.6a.779.779,0,0,1,.781.773V4.125h8.327V.773A.779.779,0,0,1,19.517,0h2.6A.779.779,0,0,1,22.9.773V4.125h3.123a3.109,3.109,0,0,1,3.123,3.094V9.54A.779.779,0,0,1,28.365,10.313ZM.781,12.376H28.365a.779.779,0,0,1,.781.773V29.908A3.109,3.109,0,0,1,26.023,33H3.123A3.109,3.109,0,0,1,0,29.908V13.149A.779.779,0,0,1,.781,12.376ZM21.339,21.4a.779.779,0,0,0-.781-.773h-3.9V16.759a.779.779,0,0,0-.781-.773h-2.6a.779.779,0,0,0-.781.773v3.867h-3.9a.779.779,0,0,0-.781.773v2.578a.779.779,0,0,0,.781.773h3.9v3.867a.779.779,0,0,0,.781.773h2.6a.779.779,0,0,0,.781-.773V24.751h3.9a.779.779,0,0,0,.781-.773Z" fill="#f26334"/>
                                    </svg>

                        </span>
                        <div className="info mb-30">
                           <h4>Schedule appointment</h4>
                           <p>Brit Hansen</p>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-12 col-lg-6">
                           <div className="sehedule_appointment_left">
                              <img src="/images/video_chat_img4.png" />
                              <div className="content_box_mobile">
                                <h4>Brit Hansen</h4>
                                <p>Torfs Marketing Manager</p>
                              </div>
                              <div className="content_box">
                              <p>Marketing Manager</p>
                              <h6>Torfs</h6>
                          </div>
                           </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                           <div className="sehedule_appointment_right">
                            <h4 className="my_heading_mobile">My suggestion</h4>
                              <form action="/action_page.php" className="mt-40">
                                 <input type="text" className="form-control" placeholder="Date + time" id="text"  />
                                 <textarea className="form-control" rows="5" id="comment" className="form-control" defaultValue={"Message *"}/>
                              </form>
                              <div className="switch_toggle">
                                 <label className="switch" for="checkbox">
                                    <input type="checkbox" id="checkbox" checked="" /> 
                                    <div className="slider round"></div>
                                 </label>
                                 <p>Allow other date suggestion</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="calling-btn">
                        <button type="send" className="btn btn_border_orange">
                           <svg xmlns="http://www.w3.org/2000/svg" width="21.808" height="20.13" viewBox="0 0 21.808 20.13">
                              <path id="Icon_ionic-md-send" data-name="Icon ionic-md-send" d="M3.375,24.63,25.183,14.565,3.375,4.5v7.828L19,14.565,3.375,16.8Z" transform="translate(-3.375 -4.5)" fill="#454545"/>
                           </svg>
                           Send
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>


      {/* <!-- speaker_mobile_model --> */}

      {/* <!-- mobile_people_model --> */}
      {/* <!-- connection_request_model --> */}
      <div className="modal fade mobile_chat_model mobile_connection_request" id="m_connection_request">
         <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
               {/* <!-- Modal Header --> */}
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
               </div>
               {/* <!-- Modal body --> */}
               <div className="connection_request_mbody">
                  <h1>
                     <svg xmlns="http://www.w3.org/2000/svg" width="27.793" height="23.919" viewBox="0 0 27.793 23.919">
                        <path id="Icon_awesome-hands-helping" data-name="Icon awesome-hands-helping" d="M21.192,8.971h-6.6v2.616a3.256,3.256,0,0,1-3.127,3.364,3.256,3.256,0,0,1-3.127-3.364V5.906L5.519,7.728a3.036,3.036,0,0,0-1.351,2.56V12.5L.694,14.657A1.555,1.555,0,0,0,.186,16.7L3.66,23.174a1.338,1.338,0,0,0,1.9.547l4.49-2.789h5.932a2.893,2.893,0,0,0,2.779-2.99h.695a1.444,1.444,0,0,0,1.39-1.5v-2.99h.347a1.081,1.081,0,0,0,1.042-1.121V10.092A1.081,1.081,0,0,0,21.192,8.971Zm6.414-1.747L24.132.748A1.338,1.338,0,0,0,22.234.2l-4.49,2.789H13.305a2.656,2.656,0,0,0-1.472.453l-1.455.976a1.514,1.514,0,0,0-.651,1.266v5.9a1.806,1.806,0,0,0,1.737,1.869A1.806,1.806,0,0,0,13.2,11.587V7.476h7.991a2.531,2.531,0,0,1,2.432,2.616v1.332L27.1,9.265A1.56,1.56,0,0,0,27.606,7.224Z" transform="translate(0.002 -0.002)" fill="#f26334"/>
                     </svg>
                     Connection Request
                  </h1>
                  <div className="people_network_box main-card">
                     <div className="p_network_b_inner">
                        <div className="img_box">
                           <img src="/images/metting_img.png" />
                           <span className="dot"></span>
                        </div>
                        <div className="info_box">
                           <h4>
                             Brit Hansen
                             
                           </h4>
                           <p><span>Torfs</span> Marketing Manager</p>
                          
                           <div className="info_social_btn">
                              <button type="button" className="btn"><img src="/images/indeed_grey.png"/></button>
                              <button type="button" className="btn"><img src="/images/twitter_grey.png"/></button>
                              <button type="button" className="btn"><img src="/images/facebook_grey.png"/></button>
                           </div>
                        </div>
                     </div>
                     <div className="description">
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                     </div>
                     <textarea className="form-control" rows="5" id="comment" className="form-control" defaultValue={"Message *"}/>
                     <div className="calling-btn">
                        <button type="send" className="btn btn_border_orange">
                           <svg xmlns="http://www.w3.org/2000/svg" width="21.808" height="20.13" viewBox="0 0 21.808 20.13">
                              <path id="Icon_ionic-md-send" data-name="Icon ionic-md-send" d="M3.375,24.63,25.183,14.565,3.375,4.5v7.828L19,14.565,3.375,16.8Z" transform="translate(-3.375 -4.5)" fill="#454545"/>
                           </svg>
                           Send
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

        {/* <!-- m_outgoing_call_model --> */}
      <div className="modal fade mobile_chat_model mobile_connection_request" id="m_outgoing_call">
         <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
               {/* <!-- Modal Header --> */}
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
               </div>
               {/* <!-- Modal body --> */}
               <div className="connection_request_mbody">
                  <h1>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24.57" height="16.014" viewBox="0 0 24.57 16.014">
                                  <path id="Icon_ionic-md-videocam" data-name="Icon ionic-md-videocam" d="M21.555,13.74V9.067a1.352,1.352,0,0,0-1.366-1.333H3.616A1.352,1.352,0,0,0,2.25,9.067V22.41a1.357,1.357,0,0,0,1.366,1.338H20.189a1.356,1.356,0,0,0,1.366-1.333V17.743L26.82,23.08V8.4Z" transform="translate(-2.25 -7.734)" fill="#f26334"/>
                                </svg>

                    Outgoing Call
                  </h1>
                    <div className="outgoing_section_mobile">
                         <div className="img-box">
                            <img src="/images/call_icon5.png" /> 
                             <img src="/images/call_icon6.png" />                                                                                                                                                
                         </div>
                         <div className="content_box">
                             <h6>You are calling</h6>
                             <h4>Brit Hansen</h4>
                             <p>Torfs Marketing Manager</p>
                         </div>
                    </div>
                     
                    
                     <textarea className="form-control" rows="5" id="comment" className="form-control" defaultValue={"Message *"}/>
                     <div className="calling-btn">
                        <button type="send" className="btn btn_border_orange">
                           <svg xmlns="http://www.w3.org/2000/svg" width="21.808" height="20.13" viewBox="0 0 21.808 20.13">
                              <path id="Icon_ionic-md-send" data-name="Icon ionic-md-send" d="M3.375,24.63,25.183,14.565,3.375,4.5v7.828L19,14.565,3.375,16.8Z" transform="translate(-3.375 -4.5)" fill="#454545"/>
                           </svg>
                           Send
                        </button>
                     </div>
                 
               </div>
            </div>
         </div>
      </div>
      {/* <!-- mobile_chat_model_close --> */} 
      {/* <!-- speaker_mobile_model_close --></div> */}
      </>
   )
}

export default SpeakerDetails
