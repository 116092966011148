import axios from "axios";
import { url as baseUrl } from '../constants/url/urlConstants';
import {getLanQueryParam} from "../Util/Utils";
import {getLang} from "../context/LanguageContext";

const DBNetwork = axios.create({ baseURL: `${process.env.REACT_APP_API_HOST}/v2.0/api`});
DBNetwork.interceptors.request.use(
    async (config) => {
        const location = window.location.pathname.toLowerCase();
        if (window.User.isLoggedIn()) {
            config.headers.Authorization = `Bearer ${window.User.getToken()}`;
        }
        const system_id = localStorage.getItem('system_id');
        if (system_id){
            if (system_id && system_id !== '') {
                if (!config.params) {
                    config.params = { system_id: system_id }
                } else {
                    config.params.system_id = system_id
                }
            }
        }
        
        let param = {...config.params};
        let lanParam = getLanQueryParam(getLang());
        for (const [key, value] of Object.entries(lanParam)) {
            param[key] = value
        }
        config.params = param;

        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);
DBNetwork.interceptors.response.use(
    function (successRes) {
        if (successRes.data && successRes.data.system_id){
            localStorage.setItem('system_id', successRes.data.system_id);
        }
        return successRes;
    },
    function (error) {
        return Promise.reject(error);
    });
export default DBNetwork;