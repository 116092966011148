import useSlug from "../useSlug";
import {useContext, useEffect, useState} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import moment from "moment";
import {Constants} from "../../classes/Constants";

const InitialSessionMap = () => {
    return {keys: [], map: {}}
}
export  function SessionApi() {
    const slug = useSlug().path;

    const {
        getSessionsData,
        setFavoriteData,
        state:{
            eventsAllPagesData: {
                sessions,
                sessions_categories,
                registered_sessions,
            },
            eventsPageData: {
                event,
                translations,
            },
        }
    } = useContext(defaultContext);

    const [sessionMap, setSessionMap] = useState(InitialSessionMap());
    const [selectedDate, setSelectedDate] = useState(null);
    const [sessionItems, setSessionItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [selectMySessions, setSelectMySessions] = useState(0);


    useEffect(() => {
        if (sessions) {
            const map = sessions?.reduce((prev, next) => {
                const obj = {...prev}
                const key = next.date
                if (next.date){
                    if (!obj.map[key]){
                        obj.map[key] = []
                        obj.keys.push(key)
                    }
                    obj.map[next.date].push(next)
                }
                return obj
            }, InitialSessionMap()) ?? InitialSessionMap()
            setSessionMap(map)
            if (!selectedDate){
                setSelectedDate(map?.keys?.first());
            }
        }
    }, [JSON.stringify(sessions)]);

    useEffect(() => {
        let items = sessionMap?.map[selectedDate];
        if (selectMySessions){
            items = items.filter(sessionRegistered => registered_sessions.includes(sessionRegistered.id));
        }
        setSessionItems(items);
    }, [selectedDate, selectMySessions, sessionMap]);

    useEffect(() => {
        let items = sessionMap?.map[selectedDate];
        if (selectedCategory !== -1){
            items = items?.filter(i => i.category_id === selectedCategory)
        }
        if (selectedDate === 'favorite'){
            items = sessions?.filter(i => i.isWishlisted)
        }
        setSessionItems(items);
    }, [selectedDate, selectedCategory, sessionMap]);

    const getTime = (isActiveEu) => (time) => {
        if (isActiveEu){
            return moment(time).format("HH:mm")
        }else {
            return moment(time).tz(event?.timezone ?? Constants.DEFAULT_TIMEZONE).format("HH:mm")
        }
    };
    return {
        getSessionsData: (callback) => getSessionsData(slug, callback),
        setSelectedDate:setSelectedDate,
        setSelectedCategory: setSelectedCategory,
        setSelectMySessions:setSelectMySessions,
        setFavoriteData: ({id}, callback) => {
            setFavoriteData(slug, {type:'session', id}, callback)
        },
        getZoneTime: getTime,
        state: {
            months: sessionMap?.keys,
            sessionMap: sessionMap?.map,
            categories: sessions_categories,
            registered_sessions: registered_sessions,
            sessionItems: sessionItems,
            selectedCategory: selectedCategory,
            selectMySessions:selectMySessions,
            selectedDate: selectedDate,
            timezone: event?.timezone,
            event : event,
        }
    }
}