import {useContext, useEffect, useMemo, useState} from "react";
import {Context as LayoutContext} from "../context/LayoutContext";
import {ResponseWrapper} from "../Model/ResponseWrapper";
import {Template, TemplateDetails} from "../Model/TemplateDetails";
import {Index as EventTemplate} from "./Event/index";
import {Index as OnePageTemplate} from "./OnePage/index";
import useSlug from "../hooks/useSlug";
import {useTemplate} from "../hooks/shared/useTemplate";
import { EventApi } from "../hooks/shared/EventApi";
import { TITLE } from "../constants/url/urlConstants";
import {BrowserCompatibilityDialog} from "./Shared/BrowserCompatibilityDialog";
const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
}
export const CenterWrapper = (props: any) => {
    return <div className={"w-100 text-center"} style={{minHeight: "100vh","verticalAlign":"middle","display":"flex","justifyContent":"center","alignItems":"center"}}>
        {props.children}
    </div>
}
const TemplateLoading = () => {
    return <CenterWrapper> <p className="template-loading">Loading Template</p> </CenterWrapper>
}
const NoTemplateFound = () => {
    return <CenterWrapper> <div style={{color: 'red'}}><p>No Template Found</p><p style={{fontSize: 200}}>404</p><p>Check the url</p></div> </CenterWrapper>
}
const TemplateLayout = (template: Template) => {
     // return <OnePageTemplate/>
    // return <EventTemplate/>

    let _layout =  <NoTemplateFound />
    if (template === Template.EVENT) {
        _layout = <EventTemplate/>
    }else if  (template === Template.ONEPAGE) {
        _layout = <OnePageTemplate/>
    }

    return (
        <>
            {_layout }
            {/*{process.env.REACT_APP_VERSION && <p>{process.env.REACT_APP_VERSION}</p> }*/}
        </>
    )
}
const LayoutRenderer = () => {
    
    const {
        getLayoutDetails,
        state: {
            layout
        }
    } = useTemplate()

    const {
        subscribeChannel,
        unsubscribeChannel,
        state: {
            eventsPageData,
            event,
        },
    } = EventApi();


    function onFocus(event:any) {
        return function () {
            subscribeChannel(event);
        }
    }
    
    function onBlur(event:any) {
        return function () {
           unsubscribeChannel();
        }
    }

    function setIdleTimeout(millis:any, onIdle:any, onUnidle:any) {
        let timeout = 0;
        startTimer();
    
        function startTimer() {
            timeout = setTimeout(onExpires, millis);
            document.addEventListener("mousemove", onActivity);
            document.addEventListener("keypress", onActivity);
        }
        
        function onExpires() {
            timeout = 0;
            onIdle();
        }
    
        function onActivity() {
            if (timeout) clearTimeout(timeout);
            else onUnidle();
            //since the mouse is moving, we turn off our event hooks for 1 second
            document.removeEventListener("mousemove", onActivity);
            document.removeEventListener("keypress", onActivity);
            setTimeout(startTimer, 1000);
        }
    }

    setIdleTimeout(300000, function() {
        unsubscribeChannel();
    }, function() {
        console.log('back!!');
    });

    useEffect(() => {
        // alert(JSON.stringify(event))
        const _focus = onFocus(event)
        const _onblur = onBlur(event)
        if (event){
            
            window.addEventListener("focus", _focus);
            window.addEventListener("blur", _onblur);
        }

        return () => {
            if (event){ 
                window.removeEventListener("focus", _focus);
                window.removeEventListener("blur", _onblur);
            }
           
        };
    }, [event]);


    useEffect(() => {
        if(eventsPageData){
            document.title = eventsPageData?.event?.title ?? TITLE;
        }
    }, [eventsPageData]);

    const [isLoading, setLoading] = useState(true)
    const [template, setTemplate] = useState(Template.NULL);

    useEffect(() => {
        if (layout){
            const template = TemplateDetails(layout)
            setTemplate( template.getTemplate())
            if (template.getPrimaryColor()){
                document.documentElement.style.setProperty('--event-primary', template.getPrimaryColor());
            }
            if (template.getSecondaryColor()){
                document.documentElement.style.setProperty('--event-secondary', template.getSecondaryColor());
            }
            if (template.getTextColor()){
                document.documentElement.style.setProperty('--main-color', template.getTextColor());
            }
        }
    }, [layout])

    useMemo(() => {
        setLoading(true)
        getLayoutDetails((res: any, error: Error) => {
            setLoading(false)
        })
    }, [])



    return isLoading ? <TemplateLoading/> : <div id={`template-${template}`}> {TemplateLayout(template)} </div>
}

export default LayoutRenderer;