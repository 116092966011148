import React, {useState, useContext, useEffect} from "react";
import DatePicker from "react-datepicker";
import { Image } from "../../../../../../UIComponets/Image";
import { Translations } from "../../../../../../hooks/shared/Translations";
import Form from 'react-bootstrap/Form';
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
import { useToasts } from "react-toast-notifications";
import useSlug from "../../../../../../hooks/useSlug";
import NotificationCenter from '../../../../../../hooks/NotificationCenter';
import moment from 'moment-timezone';
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import { SpinnerIcon } from "../../../../../../SVG/Icons";
import {Appointment, Error, OnlineOfflineUser} from "../../../../../../Util/Utils";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import {AppointmentsApi} from "../../../../../../hooks/shared/AppointmentsApi";
import {UserApi} from "../../../../../../hooks/shared/UserApi";
import { EventApi } from "../../../../../../hooks/shared/EventApi";
import "react-datepicker/dist/react-datepicker.css";
import {Constants} from "../../../../../../classes/Constants";


export const ScheduleAppointmentModel = ({participantDetails, appointmentUpdate}) => {
    const [value, onChange] = useState(null);
    const {translations} = Translations();
    const {
        state: {
            event
        }
    } = EventApi();
    const { addToast } = useToasts();


    const {
        makeAppointment,
        updateAppointment,

    } =  AppointmentsApi();

    const {user} = UserApi()

    const [isLoading, setIsLoading] = useState(false);
    const [participant, setParticipant] = useState(null)
    const [appointment, setAppointment] = useState(null)
    const [loggedInUser, setLoggedInUser] = useState(user)
    useEffect(() => {
        if (user) {
            setLoggedInUser(user)
        }
    }, [user])
    useEffect(() => {
        setParticipant(participantDetails)
    }, [participantDetails])

    useEffect(() => {
        if (appointmentUpdate){
            const appointment = Appointment(appointmentUpdate)
            const user = appointment.getHostUserId() === loggedInUser?.id ? appointment.getParticiapnt() : appointment.getHost()
            if (user){
                setParticipant(user)
            }
            setAppointment(appointmentUpdate)
        }
    }, [appointmentUpdate])



    // const selectDateTime = (value, dateString) => {
    //     setAppointmentDate(moment(dateString));
    // }
    const [isAlowRescheduling, setIsAlowRescheduling] = useState(false)
    const checkBoxClickAllow = (checked) => {
        // const checked = e.target.checked
        setIsAlowRescheduling(checked)
    }

    const timeZone = event?.shortTimezone ?? Constants.DEFAULT_TIMEZONE;
    const makeAppointmentRequest = (event) => {
        event.preventDefault();
        let data = {
            user_id: OnlineOfflineUser(participant).getId(),
            date: moment(value, Constants.SESSION_APPOINTMENT_DATE_FORMATE).format(),
            can_modify:isAlowRescheduling
        }

        const isUpdate = appointment ? true : false;
        if (isUpdate){
            data.id = appointment?.id
        }
        setIsLoading(true);
        const method = isUpdate  ? updateAppointment : makeAppointment;
            method(data, (response, error) => {
            if (response?.data?.status) {
                const wrapper = ResponseWrapper(response);
                if (wrapper.getStatus()) {
                    window.$("#sehedule_appointment").modal("hide");
                    const {appearance, autoDismiss} = getOptions(ToastType.SUCCESS);
                    addToast(wrapper.getMessage(), {appearance, autoDismiss})
                }
            }else if (error){
                const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                addToast(Error(error).getResponseError(), {appearance, autoDismiss});
                window.$("#sehedule_appointment").modal("hide");
            }
          setIsLoading(false);
        });
     }

    // const startTime = moment(event && event?.start_time).format("YYYY-MM-DD");
    // const endTime = moment(event && event?.end_time).format("YYYY-MM-DD");


    return <div className="modal fade connection_request sehedule_appointment" id="sehedule_appointment">
        <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="modal-body">
                    <div className="connection_request_body">
                        <div className="connection_p_head">
                        <span className="icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="29.146" height="33.002"
                                viewBox="0 0 29.146 33.002" className="primary-color">
                                      <path id="Icon_awesome-calendar-plus" data-name="Icon awesome-calendar-plus"
                                            d="M28.365,10.313H.781A.779.779,0,0,1,0,9.54V7.219A3.109,3.109,0,0,1,3.123,4.125H6.246V.773A.779.779,0,0,1,7.026,0h2.6a.779.779,0,0,1,.781.773V4.125h8.327V.773A.779.779,0,0,1,19.517,0h2.6A.779.779,0,0,1,22.9.773V4.125h3.123a3.109,3.109,0,0,1,3.123,3.094V9.54A.779.779,0,0,1,28.365,10.313ZM.781,12.376H28.365a.779.779,0,0,1,.781.773V29.908A3.109,3.109,0,0,1,26.023,33H3.123A3.109,3.109,0,0,1,0,29.908V13.149A.779.779,0,0,1,.781,12.376ZM21.339,21.4a.779.779,0,0,0-.781-.773h-3.9V16.759a.779.779,0,0,0-.781-.773h-2.6a.779.779,0,0,0-.781.773v3.867h-3.9a.779.779,0,0,0-.781.773v2.578a.779.779,0,0,0,.781.773h3.9v3.867a.779.779,0,0,0,.781.773h2.6a.779.779,0,0,0,.781-.773V24.751h3.9a.779.779,0,0,0,.781-.773Z"
                                            />
                                    </svg>

                        </span>
                            <div className="info mb-30">
                                <h4>Schedule appointment</h4>
                                <p>{participant?.name} {participant?.last_name}</p>
                            </div>
                        </div>
                        <Form onSubmit={makeAppointmentRequest} method="post">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <div className="sehedule_appointment_left">
                                        <Image src={participant?.avatar ? participant?.avatar : participant?.avtar} placeholderSrc={"/images/placeholder_square.jpg"} alt={"participant image"}/>
                                        <div className="content_box_mobile">
                                            <h4>{participant?.name} {participant?.last_name}</h4>
                                            <p>{participant?.title} {participant?.company}</p>
                                        </div>
                                        <div className="content_box">
                                            <p>{participant?.title}</p>
                                            <h6>{participant?.company}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="sehedule_appointment_right">
                                        <h4 className="my_heading_mobile">
                                            {translations?.account?.my_appointments_label}
                                        </h4>
                                        <div className="mt-40">
                                         <div className="sehedule_appointment_input mt-40">
                                                <DatePicker
                                                className="form-control"
                                                selected={value}
                                                onChange={(date) => onChange(date)}
                                                timeFormat="HH:mm"
                                                dateFormat="MM/dd/yyyy HH:mm aa"
                                                showTimeSelect
                                                value={value}
                                                minDate={new Date(moment(event && event?.start_time).format("YYYY-MM-DD"))}
                                                maxDate={new Date(moment(event && event?.end_time).format("YYYY-MM-DD"))}
                                                placeholderText="Date + Time"
                                                timeIntervals={1}
                                                showDisabledMonthNavigation
                                            />
                                            {<p style={{fontSize: '15px'}} className={"mb-2 text-right cet_time"}>{`${timeZone}`}  </p>}
                                         </div>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Control as="textarea" rows={3}  name="message" placeholder="Type message here."/>
                                            </Form.Group>
                                        </div>
                                        <div className="switch_toggle">
                                            <label className="switch" htmlFor="checkbox">
                                                <input type="checkbox" id="checkbox" name="suggestion" defaultChecked={false} onClick={e => checkBoxClickAllow(e.target.checked)}/>
                                                <div className="slider round"></div>
                                            </label>
                                            <p>Allow other date suggestion</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="calling-btn">
                                <button disabled={isLoading} type="submit" className="btn btn_border_orange">
                                    <IconLoader isLoading={isLoading} >

                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.808" height="20.13"
                                             viewBox="0 0 21.808 20.13">
                                            <path id="Icon_ionic-md-send" data-name="Icon ionic-md-send"
                                                  d="M3.375,24.63,25.183,14.565,3.375,4.5v7.828L19,14.565,3.375,16.8Z"
                                                  transform="translate(-3.375 -4.5)" fill="#454545"/>
                                        </svg>
                                        {translations?.general?.send}
                                    </IconLoader>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}