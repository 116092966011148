    import React from 'react'

    const ProfilePassword = () => {
        return (
            <>
            <div className="col-md-12 col-lg-12 col-xl-11">
                <div className="mobile_profile_password main-card">
                <div className="connection_request_body">
                    <div className="connection_p_head">
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="58.944" height="52.232" viewBox="0 0 58.944 52.232" className='primary-color'>
                                <path
                                    id="Icon_awesome-hand-holding-heart"
                                    data-name="Icon awesome-hand-holding-heart"
                                    d="M28.171,25.555a1.786,1.786,0,0,0,2.609,0L41.924,13.9a8.438,8.438,0,0,0-.573-12.119,7.8,7.8,0,0,0-10.734.785L29.471,3.765,28.335,2.582a7.787,7.787,0,0,0-10.734-.8A8.447,8.447,0,0,0,17.027,13.9Zm29.675,7.916a3.4,3.4,0,0,0-4.359,0l-9.455,7.539a6.527,6.527,0,0,1-4.093,1.428H27.833a1.632,1.632,0,1,1,0-3.264h8.012a3.4,3.4,0,0,0,3.408-2.714,3.271,3.271,0,0,0-3.234-3.815H19.647a12.067,12.067,0,0,0-7.583,2.683L7.306,39.173H1.637A1.64,1.64,0,0,0,0,40.805V50.6A1.64,1.64,0,0,0,1.637,52.23H38.148A6.57,6.57,0,0,0,42.241,50.8L57.713,38.459A3.256,3.256,0,0,0,57.846,33.471Z"
                                    transform="translate(0 0.002)"
                                />
                            </svg>
                        </span>
                        <div className="info mb-30">
                            <h4>My profile</h4>
                            <p>Password reset</p>
                        </div>
                    </div>
                    <div className="forgot_password_text">
                        <p>Are you sure you want to reset your password?</p>
                        <p>When you proceed, you will receive an email with further instructions</p>
                    </div>

                    <div className="dice_model_btn">
                        <button type="button" className="btn btn_border_blue">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.766" height="32.505" viewBox="0 0 24.766 32.505">
                                <path
                                    id="Icon_material-lock-outline"
                                    data-name="Icon material-lock-outline"
                                    d="M18.383,26.266a3.1,3.1,0,1,0-3.1-3.1A3.1,3.1,0,0,0,18.383,26.266ZM27.67,12.335H26.122v-3.1a7.739,7.739,0,1,0-15.479,0v3.1H9.1a3.1,3.1,0,0,0-3.1,3.1V30.909a3.1,3.1,0,0,0,3.1,3.1H27.67a3.1,3.1,0,0,0,3.1-3.1V15.431A3.1,3.1,0,0,0,27.67,12.335Zm-14.086-3.1a4.8,4.8,0,1,1,9.6,0v3.1h-9.6ZM27.67,30.909H9.1V15.431H27.67Z"
                                    transform="translate(-6 -1.5)"
                                    fill="#454545"
                                />
                            </svg>

                            Reset your password
                        </button>
                        <button type="button" className="btn btn_border_orange">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.849" height="30.849" viewBox="0 0 30.849 30.849">
                                <path
                                    id="Icon_metro-cancel"
                                    data-name="Icon metro-cancel"
                                    d="M17.995,1.928A15.424,15.424,0,1,0,33.419,17.352,15.424,15.424,0,0,0,17.995,1.928Zm0,27.956A12.532,12.532,0,1,1,30.527,17.352,12.532,12.532,0,0,1,17.995,29.885ZM22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
                                    transform="translate(-2.571 -1.928)"
                                    fill="#454545"
                                />
                            </svg>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ProfilePassword
