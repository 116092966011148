import React,{useState, useContext, useEffect, useRef} from 'react';
import ActiveSessionList from './ActiveSessionList';
import ActiveSessionsMobile from './ActiveSessionsMobile';
import { ShimmerTitle } from "react-shimmer-effects";
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import useSlug from "../../../../../../hooks/useSlug";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {MyMeetings} from "../../ActiveSessions/MyMeetings";
import { ModalIframe } from '../../../../../../UIComponets/Modal/ModalIframe';
import {SessionApiV2} from "../../../../../../hooks/shared/SessionApi2";
import { OptionConstants } from '../../../../../../constants/Constants';

function ActiveSessions() {
      const slug = useSlug().absolutePath;
      const activeRef = useRef(0);
      const {
         setFavoriteData,
         state:{ 
            eventsPageData: { 
               event,
               details,
               options,
               translations,
               sessions,
               sessions_categories,
               registered_sessions,
            },
         }
      } = useContext(defaultContext);

      // React state
      const [isLoading, setIsLoading] = useState(false);
      const [isActiveEu, setisActiveEu] = useState(false);
      const [activeSession, setActiveSession] = useState(sessions);
      const [favorite, setFavorite] = useState([]);
      const [scrollArrow, setScrollArrow] = useState(true);
      const [replay, setReplay] = useState({});
      const [activeArrow, setActiveArrow] = useState(false);

      useEffect(() => { 
         if(sessions){
            setActiveSession(sessions);
         }
      },[sessions])

      useEffect(() =>{
         if(activeRef && activeRef.current.clientHeight > 0){
            if(activeRef.current.scrollHeight > activeRef.current.clientHeight){
               setActiveArrow(true);
            } 
         }
      },[activeRef])

      const handleFavoirte = (id) => {
         setFavorite([...favorite, id]);
         setFavoriteData(slug, {type:'session', id}, (response, error) => {
            if (response?.data?.status) {
               const res = response?.data?.data;
               setFavorite([]);
            }
      });
   }

   const activeSessionScroll  = () => {
      let divElement = document.getElementById("accordionExampleDestop");
      if(divElement.scrollTop > 0){
         divElement.scroll({
            top: 0,
            behavior: 'smooth'
         });
         setScrollArrow(true);
      }else{
         divElement.scroll({
            top: divElement.scrollHeight,
            behavior: 'smooth'
         });
         setScrollArrow(false);
      }
   }
   
   const onCloseModal = (close) => {
      console.log(close);
   }

    const playerRef = useRef()

    const setReplayData = (data) => {
          setReplay(data)
         playerRef?.current?.play()
    }

    let fullEdge = options?.includes(OptionConstants.NETWORK);

    return (
        <>
        {/* -- active_section --*/}
         <section className="active_section_area mt-50">
            <div className="container">
               <div className="row">
                     {options?.includes(OptionConstants.SESSIONS) && (<div className={`col-md-12 col-lg-12 ${fullEdge ? 'col-xl-8' : 'col-xl-12' }`}>
                     <h1 className="main_heading mb-20">
                        {translations?.sessions?.active_sessions}
                     </h1>
                     <div className="active_section_box">
                        <ul className="nav nav-tabs" role="tablist">
                           <li className="nav-item" onClick={() => setisActiveEu(true)}>
                              <Link to={'#'} className={`nav-link ${isActiveEu ? "active" : ""}`}>
                              {event?.shortTimezone} {translations?.general?.time}
                              </Link>
                           </li>
                           <li className="nav-item" onClick={() => setisActiveEu(false)}>
                              <Link to={'#'} className={`nav-link ${isActiveEu ? "" : "active"}`}>
                                 {translations?.general?.device_time}
                              </Link>
                           </li>
                        </ul>
                        {/* <!-- Tab panes --> */}
                        <div className="tab-content mt-60 pb-30">
                           <div className="tab-pane active" id="EUTIME" role="tabpanel">
                              <div className="accordion destop_ed_time" id="accordionExampleDestop" ref={activeRef}>
                                 {/* Home Session list here */}
                                 {activeSession ? activeSession.filter(s => s.is_live === 1)?.length > 0 ? (<ActiveSessionList
                                    timezone={event?.timezone} 
                                    isActiveEu={isActiveEu} 
                                    sessions={activeSession} 
                                    sessions_categories={sessions_categories}
                                    registered_sessions = {registered_sessions}
                                    onClick={handleFavoirte}
                                    favorite={favorite}
                                    event={event}
                                    setReplay={setReplayData}
                                 />
                                 ) : (<div className='col text-center'><span>{details?.no_live_sessions_message}</span></div>) : (<ShimmerTitle line={10} gap={10} variant="primary" />)}
                              </div>

                              {/* -- mobile_action_ed_time --*/}  
                              <div className="mobile_ed_time_section accordion" id="accordionExample">
                              {activeSession ? activeSession?.filter(s => s.is_live === 1)?.length > 0 ?  (<ActiveSessionsMobile
                                    timezone={event?.timezone} 
                                    isActiveEu={isActiveEu} 
                                    sessions={activeSession} 
                                    sessions_categories={sessions_categories}
                                    registered_sessions = {registered_sessions}
                                    onClick={handleFavoirte}
                                    favorite={favorite}
                                    event={event}
                                    setReplay={setReplayData}
                                 />
                                 ) : (<div className='col text-center'><span>{details?.no_live_sessions_message}</span></div>) : (<ShimmerTitle line={10} gap={10} variant="primary" />)
                                 }
                              </div>

                              {/* -- mobile_action_ed_time --*/}
                              {activeSession?.filter(s => s.is_live === 1)?.length > 5 ? <div className="read_more_btn">
                                 <Link id="moreless-button" to={"#"} onClick={activeSessionScroll} className={scrollArrow && "active"}>
                                   {scrollArrow ? (<FaChevronUp/>) : (<FaChevronDown />)}
                                 </Link> 
                              </div> : null}
                           </div>

                           <div className="tab-pane" id="DEVICETIME" role="tabpanel">
                              <p>Second Panel</p>
                           </div>
                           
                        </div>
                        <div className="view_all_btn">
                           <Link to={slug+'/sessions'}>
                              <button className="btn">
                                 <span>
                                    <i className="fa fa-angle-right" ></i>
                                    </span> {translations?.general?.view_all}
                              </button>
                           </Link>
                        </div>
                     </div>
                  </div>)}

                  {/* START MY MEETINGS */}
                  {options?.includes(OptionConstants.NETWORK) && (<MyMeetings/>)}
               </div>
            </div>
         </section>
         <ModalIframe onClose={() => onCloseModal(null)} replayPlayer={replay}  size='lg' id={"replay-btn-modal"} ref={playerRef}/>
         {/* <!-- end active section area --> */} 
        </>
    )
}

export default ActiveSessions;
