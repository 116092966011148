// import {
//     check_for_dice_open,
//     declineMatch, endChat,
//     gotMatch, rollDice, rollDiceInterestUpdate,
//     videoChatAccept,
//     videoConnect
// } from "../context/NetworkTollContext";
import Logger from "../Util/Logger";
import { Context as defaultContext } from "./../context/DefaultSectionContext";
import {useContext, useEffect, useState} from "react";
import _ from "lodash"
import useSlug from "./useSlug";

export function VideoCallApi(props){
    const context = useContext(defaultContext);
    const container = {};
    const slug = useSlug()
    const videoChatMessages = context?.state?.videoChatMessages;

    const methodWrapper = (func) => {
        return (...args) => func(slug, context?.state, ...args)
    }
    const filterd_methods = _.pickBy(context, (value, key) => key.startsWith("networking_"))
    const others = _.omitBy(context, (value, key) => key.startsWith("networking_"))
     _.each(filterd_methods,(value, key) => {
            container[key.replace("networking_", "")] = methodWrapper(value)
        });

    return {...container,
        ...others, videoChatMessages,
        connectedPeoples: context?.state?.connectedUser,
        connectedUserMap: context?.state?.connectedUserMap,
        onlineUsers: context?.state?.onlineUsers,
        videoChat: context?.state?.videoChat,
        dice_matched: context?.state?.dice_matched,
        videoChatState: context?.state?.videoChatState,
        incomingCall: context?.state?.incomingCall,
        usersDetail: context?.state?.usersDetail,
        totalUser:context?.state?.totalUser,
        timezone: context?.state?.eventsPageData?.event?.timezone
    }
}



