import React, { useState, useRef, useEffect } from 'react'
import { HashLink } from "react-router-hash-link";
import {useLocation} from "react-router-dom";
import { Image } from '../../../../UIComponets/Image';
import { EventApi } from '../../../../hooks/shared/EventApi';
import useSlug from '../../../../hooks/useSlug';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const LeftMenuBar = () => {
    const slug = useSlug().path;
    const menuRef = useRef();
    let location = useLocation();
    const {
        state:{
            icons,
            options,
        }
    } = EventApi();

   useEffect(() => {
      if(menuRef.current){
         $(menuRef.current).children("ul").hide();
      }
   },[menuRef.current])

   // useEffect(() => {
   //    let menu = ['home', 'live'];
   //    if(options){
   //        options.push(...menu);
   //    }
   // },[options])

   const handleMenuCollapse = () => {
        if(menuRef.current){
            $(menuRef.current).children("ul").slideToggle("100"); 
            $(menuRef.current).find(".right").toggleClass("fa-angle-up fa-angle-down");
        }
   }

  return (
    <>
        <div className="v_menu_bar">
            <ul className="v_navbar_menu" id="collapsibleNavbar1">
               <li className='live_box'>
                  <HashLink smooth to="#live" className="live_menu_box">
                     <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19.091" viewBox="0 0 21 19.091">
                           <path
                              id="Icon_material-live-tv"
                              data-name="Icon material-live-tv"
                              d="M20.591,6.818H13.346l3.14-3.14L15.818,3,12,6.818,8.182,3,7.5,3.678l3.15,3.14H3.409A1.909,1.909,0,0,0,1.5,8.727V20.182a1.915,1.915,0,0,0,1.909,1.909H20.591A1.915,1.915,0,0,0,22.5,20.182V8.727A1.909,1.909,0,0,0,20.591,6.818Zm0,13.364H3.409V8.727H20.591ZM9.136,10.636v7.636l6.682-3.818Z"
                              transform="translate(-1.5 -3)"
                              />
                        </svg>
                     </span>
                     <span className="text">LIVE</span>
                  </HashLink>
               </li>
               <li className="sub-menu all_menu_btn" onClick={handleMenuCollapse} ref={menuRef}>
                  <a className="menu_angle"><span><i className="fa fa-angle-down right"></i></span></a>
                  <ul>
                       {icons?.length > 0 ? icons && icons?.map((menu, index) => {
                           const showMenu = options?.includes(menu?.icon_type_name) || menu?.icon_type_name === "home" || menu?.icon_type_name === "live";
                            let iconType = menu?.icon_type_name?.replace(/_/g, '-');
                            return(
                                <>
                                    <li key={index} id={"webbar-" + iconType} className={`${showMenu ? "" : "d-none"} ${location.hash === "#"+iconType ? "active" : ""}`} >
                                        <HashLink smooth to={'#'+iconType} className="nav-link">
                                            {menu?.is_fa === 1 ?
                                            (<span className="svg_path"> <i className={menu?.fa_class}></i></span>) 
                                            : <Image src={menu?.image_url} placeholderSrc={""} alt="Menu Image"/> } 
                                            <p>{menu?.front_name}</p>
                                        </HashLink>
                                    </li>
                                </>
                            )
                        })
                    : null }
                  </ul>
               </li>
            </ul>
         </div>
    </>
  )
}

export default LeftMenuBar