import {useContext, useEffect, useMemo} from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as DefaultContext } from '../context/DefaultSectionContext';
import SharedVideoChatState from "../classes/SharedVideoChatState";
import useSlug from "../hooks/useSlug";

const ContextSynchronizer = (props) => {
    const slug = useSlug()
    const {
        state: {
            isLoggedIn,
            videoChatState
        }
    } = useContext(DefaultContext)

    const {
        updateLoginStatus
    } = useContext(AuthContext)

    useEffect(() => {
        if (isLoggedIn !== undefined){
            updateLoginStatus(isLoggedIn)
        }
    }, [isLoggedIn])

    // Updating share video chat object for getting updated state on video calling sockets
    useEffect(() => {
        if (videoChatState){
            SharedVideoChatState.instance().setVideoChatState(videoChatState)
        }
    }, [videoChatState])

    useEffect(() => {
        if (slug){
            SharedVideoChatState.instance().setSlug(slug)
        }
    }, [slug])

    return <>
            {props.children}
        </>
}

export default ContextSynchronizer;