import React, {useContext, useEffect, useState} from 'react';
import {Link,useLocation} from "react-router-dom";
import useSlug from "../../../../../hooks/useSlug";
import {URLContants} from "../../../../../Model/URLContants";
import {Context as defaultContext} from "../../../../../context/DefaultSectionContext";
import {
    Home,
    LiveIcon,
    VideoVaultIcon,
    SessionsIcon,
    SlideDecksIcon,
    BreakoutRoomIcon,
    SpeakersIcon,
    SponsorsIcon,
    SocialWallIcon,
    NetworkIcon,
    DailyTriviaIcon
} from "../../../../../SVG/Icons";
import {BadgesApi} from "../../../../../hooks/shared/BadgesApi";
import { Image } from '../../../../../UIComponets/Image';


const WebNavbar = () => {
    const slug = useSlug()
    const Path = (url) => slug.absolutePath + url
    const location = useLocation();
    const pathName = location.pathname.split('/');
    const lastsegment = pathName[pathName.length-1];

    const {
        state: { 
            eventsPageData : {
                options,
                icons,
                channels,
            }
        }
    } = useContext(defaultContext)

    const liveCount = channels && channels.reduce((accumulator, currentValue) => accumulator.concat(currentValue), []
    ).filter(item => item.live).length;


    const {
        live_badges_total,
        networking_badges_total
    } =  BadgesApi()

    const [menuBadgeMap, setMenuBadgeMap] = useState({
        network: networking_badges_total,
        live: live_badges_total,
    });

    useEffect(() => {
        setMenuBadgeMap({
            network: networking_badges_total,
            live: live_badges_total,
        })
    }, [live_badges_total, networking_badges_total])

    // useEffect(() => {
    //     if(options){
    //         return options.push('home','live');
    //     }
    // },[options]);

    return (
        <>
            {/* start banner navbar */}
            <div className="banner_navbar">
                <ul className="nav">
                    {icons?.length > 0 ? icons && icons?.map((menu, index) => {
                        const showMenu = options?.includes(menu?.icon_type_name) || menu?.icon_type_name === "home" || menu?.icon_type_name === "live";
                        let iconType = menu?.icon_type_name?.replace(/_/g, '-');
                        let menuType = iconType === "home" ?  slug.event : iconType;
                        return(
                            <>
                                <li id={"webbar-" + iconType} className={`nav-item live ${showMenu ? "" : "d-none"} ${lastsegment === menuType ? "active" : ""}`} key={index}>
                                    <Link to={Path(`${iconType === 'home' ? '' : '/'+iconType}`)} className="nav-link">
                                        {menu?.is_fa === 1 ? 
                                        (
                                            <>
                                                <span className="svg_path">
                                                    <i className={menu?.fa_class}></i>
                                                    {menuBadgeMap[iconType] ? <span className="number">{menuBadgeMap[iconType]}</span> : null}
                                                </span>
                                            </>
                                        ):
                                        (<span className="svg_path"><Image src={menu?.image_url} placeholderSrc={""} alt={"Menu Image"}/></span>)} 
                                        <p>{menu?.front_name}</p>
                                    </Link>
                                </li>
                            </>
                        )
                    })
                    :null}
                </ul>
            </div>
            {/* end banner navbar */}
        </>
    )
}

export default WebNavbar;
