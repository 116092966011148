const EventObject = ({channel, event, callback}) => {
    let _channel = channel;
    let _event = event;
    let _callback = callback;
    return {
        getChannel:() => _channel,
        getEvent:() => _event,
        getCallback:() => _callback,
    }
}
export default EventObject;