import React from 'react';
import { FaHandHoldingHeart } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";
import { EventApi } from '../../../../../../hooks/shared/EventApi';
const SponsorsContent = ({sponsorDetails, translations}) => {

    const {
        state:{
            details
        }
    } = EventApi();

    return (
        <>
            <div className="main_icon_header">
                <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="58.944" height="52.232" viewBox="0 0 58.944 52.232">
                    <path id="Icon_awesome-hand-holding-heart" 
                    data-name="Icon awesome-hand-holding-heart" 
                    d="M28.171,25.555a1.786,1.786,0,0,0,2.609,0L41.924,13.9a8.438,8.438,0,0,0-.573-12.119,7.8,7.8,0,0,0-10.734.785L29.471,3.765,28.335,2.582a7.787,7.787,0,0,0-10.734-.8A8.447,8.447,0,0,0,17.027,13.9Zm29.675,7.916a3.4,3.4,0,0,0-4.359,0l-9.455,7.539a6.527,6.527,0,0,1-4.093,1.428H27.833a1.632,1.632,0,1,1,0-3.264h8.012a3.4,3.4,0,0,0,3.408-2.714,3.271,3.271,0,0,0-3.234-3.815H19.647a12.067,12.067,0,0,0-7.583,2.683L7.306,39.173H1.637A1.64,1.64,0,0,0,0,40.805V50.6A1.64,1.64,0,0,0,1.637,52.23H38.148A6.57,6.57,0,0,0,42.241,50.8L57.713,38.459A3.256,3.256,0,0,0,57.846,33.471Z" 
                    transform="translate(0 0.001)" 
                    fill={details?.primary_color ? details?.primary_color : 'currentColor'}/>
                </svg>
                </span>
                <div className="content">
                    <h4>{translations?.sponsers?.title}</h4>
                    <p style={{textTransform: 'capitalize'}}>{sponsorDetails?.name && sponsorDetails?.name}</p>   
                </div>
            </div>
            <p>{ReactHtmlParser(sponsorDetails?.meta?.description ?? '')}</p>  
        </>
    )
}

export default SponsorsContent
