import React from 'react';
import { Link } from 'react-router-dom';
import useSlug from '../../../../../../hooks/useSlug';
import {Peoples} from "./People";
import {NetworkUIState} from "./NetworkUIState";

import { Translations } from '../../../../../../hooks/shared/Translations';

export const NetworkMenu = ({active,children}) => {

    return (
        <>
            <div className="col-md-12 col-lg-2 col-xl-1">
                <div className={`network_right_area ${active === 'PEOPLE' ? "network_people_navbar":""}`}>
                    {/* Networking right menu */}
                    <ul className="network_navbar">
                        {children}
                    </ul>
                </div>
            </div>

        </>
    )
}


const MenuWrapper = (props) => {
    return <li id={`networking-menu-${props.id}`} className={props.active === props.id ? "active" : ""} onClick={() => props.onClick(props.id)}>
            <a onClick={() => {}}>
                {props.children}
            </a>
        </li>
}

export const PeopleMenu = (props) => {
    const {translations} = Translations();

    return <MenuWrapper {...props}>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="44.646" height="45.673" viewBox="0 0 44.646 45.673">
                <g id="Image-_-33---Image_-photo_-photography_-picture_-portrait-icon" data-name="Image-/-33---Image,-photo,-photography,-picture,-portrait-icon" transform="translate(1.607 1.959)">
                    <circle id="Path" cx="14.512" cy="14.512" r="14.512" transform="translate(6.545 -0.459)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                    <path
                        id="Path-2"
                        data-name="Path"
                        d="M6,27.763C6.415,22.182,14.337,14,26.784,14s20.755,8.182,20.755,13.846"
                        transform="translate(-6 14.344)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        fillRule="evenodd"
                    />
                </g>
            </svg>
        </span>
        <p>{translations?.general?.people}</p>
    </MenuWrapper>
}

export const MeetingMenu = (props) => {
    const {translations} = Translations();
    return  <MenuWrapper {...props}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="35.997" viewBox="0 0 45 35.997">
                        <path
                            id="Icon_awesome-hands-helping"
                            data-name="Icon awesome-hands-helping"
                            d="M34.313,13.5H23.625v3.938a5.063,5.063,0,0,1-10.125,0V8.887L8.937,11.63A4.49,4.49,0,0,0,6.75,15.483v3.326L1.125,22.057A2.245,2.245,0,0,0,.3,25.13l5.625,9.745A2.251,2.251,0,0,0,9,35.7l7.27-4.2h9.6a4.5,4.5,0,0,0,4.5-4.5H31.5a2.248,2.248,0,0,0,2.25-2.25v-4.5h.563A1.683,1.683,0,0,0,36,18.563V15.188A1.683,1.683,0,0,0,34.313,13.5ZM44.7,10.87,39.073,1.125A2.251,2.251,0,0,0,36,.3L28.73,4.5H21.544a4.534,4.534,0,0,0-2.384.682L16.8,6.652A2.235,2.235,0,0,0,15.75,8.557v8.88a2.813,2.813,0,0,0,5.625,0V11.25H34.313a3.94,3.94,0,0,1,3.938,3.938v2l5.625-3.248A2.252,2.252,0,0,0,44.7,10.87Z"
                            transform="translate(0.002 -0.002)"
                        />
                    </svg>                    
                </span>
        <p>{translations?.general?.my_meetings}</p>
        {props?.badge ? (<span className="number">{props?.badge}</span>) : "" }
    </MenuWrapper>
}

export const ChatMenu = (props) => {
    const {translations} = Translations();

    return  <MenuWrapper {...props}>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <path
                    id="Icon_material-message"
                    data-name="Icon material-message"
                    d="M30,3H6A3,3,0,0,0,3.015,6L3,33l6-6H30a3.009,3.009,0,0,0,3-3V6A3.009,3.009,0,0,0,30,3ZM27,21H9V18H27Zm0-4.5H9v-3H27ZM27,12H9V9H27Z"
                    transform="translate(-3 -3)"
                />
            </svg>
        </span>
        <p>{translations?.general?.my_messages}</p>
        {props?.badge ? (<span className="number">{props?.badge}</span>) : "" }
    </MenuWrapper>
}

export const DiceMenu = (props) => {
    const {translations} = Translations();

    return  <MenuWrapper {...props}>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="39.557" height="39.557" viewBox="0 0 39.557 39.557">
                <g id="Group_1846" data-name="Group 1846" transform="translate(-30.221 -20.444)">
                    <path
                        id="Icon_metro-dice"
                        data-name="Icon metro-dice"
                        d="M35.947,9.345H16.168a6.2,6.2,0,0,0-6.181,6.181V35.3a6.2,6.2,0,0,0,6.181,6.181H35.947A6.2,6.2,0,0,0,42.127,35.3V15.526a6.2,6.2,0,0,0-6.181-6.181ZM18.641,36.54a3.708,3.708,0,1,1,3.708-3.708A3.708,3.708,0,0,1,18.641,36.54Zm0-14.834A3.708,3.708,0,1,1,22.349,18,3.708,3.708,0,0,1,18.641,21.706Zm7.417,7.417a3.708,3.708,0,1,1,3.708-3.708A3.708,3.708,0,0,1,26.057,29.123Zm7.417,7.417a3.708,3.708,0,1,1,3.708-3.708A3.708,3.708,0,0,1,33.474,36.54Zm0-14.834A3.708,3.708,0,1,1,37.183,18,3.708,3.708,0,0,1,33.474,21.706ZM34.585,6.873A6.2,6.2,0,0,0,28.53,1.928H8.751A6.2,6.2,0,0,0,2.571,8.109V27.887a6.2,6.2,0,0,0,4.945,6.056l.036-21.465c.027-2.564,1.928-6.1,6.7-5.822l20.333.217Z"
                        transform="translate(27.65 18.516)"
                    />
                </g>
            </svg>
        </span>
        <p>{translations?.general?.dice}</p>
    </MenuWrapper>
}
