import React, {useEffect, useState} from 'react';
import {Ticker} from "./Ticker";
import {InterractionShimmer, LiveShimmer, TickerShimmer} from "./Shimmer";
import {LiveRoom} from "./LiveRoom";
import {Interaction} from "./Interaction";
import {InteractionSettings} from "../../../../../hooks/shared/InteractionSettings";
import {LiveSection} from "../../../../../hooks/shared/LiveSection";
import {useTemplate} from "../../../../../hooks/shared/useTemplate";
import {Template, TemplateDetails} from "../../../../../Model/TemplateDetails";
import {LiveNavbar} from "./LiveNavbar";
import { useTransition, animated } from 'react-spring'
import {BadgesApi} from "../../../../../hooks/shared/BadgesApi";
import ReactHtmlParser from 'react-html-parser';
import {useLocation, useHistory} from "react-router";
import Logger from "../../../../../Util/Logger";
import {SlugUtils} from "../../../../../Util/Utils";
import {TickerHook} from "../../../../../hooks/shared/TickerHook";
import {EventApi} from "../../../../../hooks/shared/EventApi";
import {Constants} from "../../../../../classes/Constants";

const Live = () => {
    const [activeId, setActiveId] = useState(null)
    const {channels, getChannels, translations, slug, channelInfo } =  LiveSection({activeId})
    const { hash } = useLocation();
    const history = useHistory()
    const {
        setSelectedChannel,
        selectedChannelId,
        isAllDisabled,
        isChatEnabled,
        isPollEnabled,
        isRatingEnabled,
        isVoteEnabled,
        isQnAEnabled,
        chat,
        qa,
        poll,
        rating,
        vote,
        selectedChannel,
        active,

        live_badges,

        isRatingForEach,
        isQAForEach,
        isVoteForEach,
        isPollForEach,
    } = InteractionSettings()



useEffect(() => {
    if (channels){
        const firstFrag = hash.split("#").last();
        let channel =  channels?.first();
        if (firstFrag){
             const _channel = channels?.filter(c => {
                return c.slug === firstFrag.toLowerCase()
            })?.first()
            if (_channel){
                channel = _channel
            }
        }
        if (channel?.id){
            setSelectedChannel(channel?.id)
        }
    }
}, [JSON.stringify(channels)])

    const {
        state: {
            layout
        }
    } = useTemplate()

    useEffect(() => {
        const channelMap = channels?.reduce((prev, next) => {
            return {
                ...prev,
                [next?.id] : next
            }
        }, {})

        if (selectedChannelId){
            const channel = channelMap[selectedChannelId]
            const _hash = channel?.slug;
            if (_hash !== hash){
                const str = history.location.pathname + "#" + _hash
                history.replace(str)
            }
        }

    }, [selectedChannelId])

    useEffect(() => {
        if (!channels){
            getChannels()
        }
    }, [])
    const [fullScreen, setFullScreen] = useState(false)
    const event = EventApi()
    const [isAllInteractionDisabled, setAllInteractionDisabled] = useState(isAllDisabled)

    useEffect(() => {
        setAllInteractionDisabled(!isAllDisabled)
    }, [isAllDisabled])

    if (channels && channels.length === 0) {
        return <>
            <section className="live_room_section mt-50">
                <div className="container">
                    <div className="internal_liveroom_inner mt-30">
                        <div className="row">
                            <div className={`col-md-12 col-lg-12 col-xl-12 text-center`}>
                                { event?.state?.details?.no_channel_message ?? Constants.NO_DATA_AVAILABLE}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    }
    const templateDetail = TemplateDetails(layout);
    const isOnePageTemplate = templateDetail.getTemplate() === Template.ONEPAGE;
    const tickerContainer = TickerHook()


    return (
        <>
            {/* start live room section*/}
            <section className={`live_room_section ${isOnePageTemplate ? "" : "mt-50"} ${event?.state?.details?.show_header === "hidden" ? "" : "live-mt"}`} id='live'>
                <div className="container">
                    <div className="onpage_live_navbar">
                        {isOnePageTemplate &&
                        <LiveNavbar channels={channels} activeId={selectedChannelId} onClick={(id) => setSelectedChannel(id)}/>}
                    </div>
                   
                    <h1 className="main_heading mb-20">
                        {translations?.general?.live}
                    </h1>
                    <div className={`internal_liveroom_inner ${isOnePageTemplate ? "mt-50 " : "mt-30"}`}>
                    <div className="row">
                        <div className={`col-md-12 col-lg-12 fullScreen ${isAllInteractionDisabled ? "col-xl-12" : (fullScreen ? "col-xl-11" : "col-xl-8")}`}>
                            {/*<LiveRoom activeId={activeId} setActiveId={setActiveId} />*/}
                            {channels ? <LiveRoom activeId={selectedChannelId} setActiveId={setSelectedChannel} channels={channels}/> : <LiveShimmer/>}
                            {/* Start Ticker Area */}
                            {/*<Ticker />*/}
                            {channels ? <Ticker tickers={tickerContainer[selectedChannelId]} translations={translations}/> : <TickerShimmer/> }
                            {/* Ticker Area Close */}
                        </div>
                        {isAllInteractionDisabled ? null : <div className={`interaction-container fullScreen col-md-12 col-lg-12 ${fullScreen ? "col-xl-1" : "col-xl-4"}`}>
                            <Interaction fullScreenCallback={(status) => setFullScreen(status)} { ...{isAllDisabled,
                                isChatEnabled,
                                isPollEnabled,
                                isRatingEnabled,
                                isVoteEnabled,
                                isQnAEnabled,
                                chat,
                                qa,
                                poll,
                                rating,
                                vote,
                                selectedChannel,
                                translations,
                                active,
                                selectedChannelId,

                                isRatingForEach,
                                isQAForEach,
                                isVoteForEach,
                                isPollForEach,
                            }}
                                         live_badges={live_badges}
                            />
                            {/* mobile view description  */}
                            {/* <div className="gs_opening_lbox mt-5">
                                <h4 className='text-uppercase'>{selectedChannel?.current_channel?.name ?? "" }</h4>
                                <p className='text-justify'>{ReactHtmlParser(selectedChannel?.current_channel?.description ?? '')}</p>
                            </div> */}
                        </div>}
                    </div>
                    </div>
                </div>
            </section>
            {/* live_room_section_close */}
            <section className="gs1_opening_area mt-50 mb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col">
                            <div className="gs_opening_lbox">
                                <h4 className='text-uppercase'>{selectedChannel?.current_channel?.name ?? ""}</h4>
                                <p className='text-justify'>{ReactHtmlParser(selectedChannel?.current_channel?.description ?? '')}</p>
                            </div>
                        </div>
                        {/* <div className="col-md-12 col-lg-4">
                            <div className="gs_opening_rbox">
                                <img alt="image" src="/images/gs_img.png"/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <!-- video_model --> */}
            <div className="modal fade live_room" id="liveroom">
                <div className="modal-dialog modal-lg modal-dialog-centered ">
                    <div className="modal-content">
                        {/* <!-- Modal body --> */}
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <video controls id="video1">
                                <source
                                    src="/http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                                    type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- video_model_close --> */}
        </>
    )
}

export default Live;
