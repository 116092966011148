import React, {forwardRef, useEffect, useRef, useState, useContext} from 'react';
import {OneToOneChat} from "../../../../../../hooks/shared/OneToOneChat";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import {Error, OnlineOfflineUser, OnlineUser} from "../../../../../../Util/Utils";
import {useToasts} from "react-toast-notifications";
import {SearchIcon, SendIcon} from "../../../../../../SVG/Icons";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import {LiveShimmer} from "../../Live/Shimmer";
import {Image} from "../../../../../../UIComponets/Image";
import {ChatMessage, Message} from "../../Live/ChatTab";
import {UserApi} from "../../../../../../hooks/shared/UserApi";
import {O2OMessage} from "../../../../../../Model/O2OMessage";
import InputEmoji from 'react-input-emoji';
import {VideoCallApi} from "../../../../../../hooks/videoCallApi";
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
import {BadgesApi} from "../../../../../../hooks/shared/BadgesApi";
import {NetworkingBadgeType} from "../../../../../../classes/BadgeType";
import { Translations } from '../../../../../../hooks/shared/Translations';
import PullToRefresh from "react-simple-pull-to-refresh";
import useSlug from '../../../../../../hooks/useSlug';

const LoadingObj = (container) => {
   const _container = container;
   return {
      setIsLoading: (id, isLoading) => {_container[id] = isLoading; return LoadingObj(_container)},
      isLoading: (id) => _container[id],
      getObject:() => _container,
      ...container ?? {}
   }
}

const ChatNavItem = forwardRef(({message, user, badgeCount, onClick, isOnline}, ref) => {
   return <div className="chat_list" ref={ref} onClick={onClick}>
      <div className="chat_people">
         <div className="chat_img"> <Image src={user?.avtar} placeholderSrc="/images/placeholder_square.jpg" alt="" />  {isOnline ? <span className="dot" /> : null } </div>
         <div className="chat_ib">
            <h5>{user?.name}  <span className="chat_date">{user?.title} - {user?.company}</span></h5>
            <p>{message?.message}</p>
            <h6>{new Date(message?.created_at)?.formatedChatDate()}</h6>
         </div>
            {badgeCount > 0 && <div className="msg_number">
            <p>{badgeCount}</p>
         </div>}
      </div>
   </div>
})

const ChatWindow = ({details, openChatWindow}) => {
   const {
      sendOneToOneChat,
      prefetchOneToOneChat,
      fetchParticipantChat,
     state: {
        isFetched,
        users,
        prefetch_messages,
        countMap,
        chatContainer
     }
   } =   OneToOneChat()

   const {
      onlineUsers
   } = VideoCallApi();

   const {
      user
   } = UserApi()

   const {
      translations
   } = Translations();

   const {
      getNetworkPeople,
      state:{
          usersDetail,
      }
   } = useContext(defaultContext);


   const {networking_chat_badge_map} = BadgesApi()
  
   const usersMap = users?.reduce((prev, next) => {return {...prev, [next.id]: next}}, {})
   const [participant, setParticipant] = useState(null)
   const { addToast } = useToasts();
   const slug = useSlug().absolutePath;
   const inputFileRef = useRef(null);
   const [messageContainer, setMessageContainer] = useState({})
   const [sendingContainer, setSendingContainer] = useState(LoadingObj({}))
   const [fetchingChatContainer, setFetchingChatContainer] = useState(LoadingObj({}))
   const [fetchMoreContainer, setFetchingMoreContainer] = useState(LoadingObj({}))
   const messageFieldRef = useRef();
   const chatContainerRef = React.useRef(null);
   const [files, setfiles] = useState("");
   const [seachLoading, setSearchLoading] = useState(false)
   const [searchQuery, setSearchQuery] = useState('')
   const [inputTimeout, setInputTimeout] = useState(null);
   const scrollToMyRef = () => {
      if (chatContainerRef.current){
         if (chatContainerRef.current.scrollHeight && chatContainerRef.current.clientHeight){
            window.$(chatContainerRef.current).animate({
               scrollTop: chatContainerRef.current.scrollHeight - chatContainerRef.current.clientHeight
            }, 500);
         }

      }
   };

   const setMessage = (message) => {
      if (!participant_id) return;
      setMessageContainer((prev) => {
         return {
            ...prev,
            [participant_id]: message ? message : files
         }
      })
   }

   const triggerInputFile = () => {
      inputFileRef.current.click();
   }

   const uploadAttachment = (e) => {
      e.preventDefault();
      setfiles(URL.createObjectURL(e.target.files[0]));
   };

   const [isPrefecthing, setIsPrefetching] = useState(isFetched ?? false)

   useEffect(() => {
      if (details){
         const _user = OnlineOfflineUser( details)
         setParticipant(_user)
      }
   }, [details])

   useEffect(() => {
      if (!details){
         const _user = OnlineOfflineUser(  users?.first())
         setParticipant(_user)
      }
   }, [JSON.stringify(users)])



   const {resetNetworkingBadge} = BadgesApi()



   useEffect(() => {
      if (!isFetched){
         setIsPrefetching(true)
         prefetchOneToOneChat((response, error) => {
            setIsPrefetching(false)
         })
      }else {
         setIsPrefetching(false)
      }

   }, [isFetched])

   useEffect(() => {
      fetchUserChat()
   }, [participant])

   // const [hasNoMore, setHasNoMore] = useState(true)
   const getItems = (resolve) => {
      // alert(prmois)
      const messageContainer = chatContainer?.messageContainer
      const participant_id = participant?.getId()
      const firstMessage = participant_id && messageContainer && messageContainer[participant_id].messages?.first()
      if (firstMessage && !fetchMoreContainer?.isLoading(participant_id)){
         const message_id = firstMessage?.id;
         setFetchingMoreContainer( (pre) => {
                return {
                   ...LoadingObj(pre).setIsLoading(participant_id, true)
                }
             }
         )
         fetchParticipantChat({participant_id, message_id}, (response, error) => {
            resolve && resolve()
            if (response?.data?.data?.user_messages?.length === 0 ){
               const {appearance, autoDismiss} = getOptions(ToastType.INFO)
               addToast("No Messages Left", {appearance, autoDismiss})
            }
            setFetchingMoreContainer( (pre) => {
                   return {
                      ...LoadingObj(pre).setIsLoading(participant_id, false)
                   }
                }
            )
         })
      }


   }
   const resetBadge = () => {
      const participant_id = participant?.getId()
      if (participant_id){
         resetNetworkingBadge(NetworkingBadgeType.CHAT, {participant_id}, (response, error) => {})
      }
   }
   const fetchUserChat = () => {
      const messageContainer = chatContainer?.messageContainer
      const participant_id = participant?.getId()
      resetBadge()
      if (!participant_id || messageContainer && messageContainer[participant_id] && messageContainer[participant_id].isFetched || fetchingChatContainer?.isLoading(participant_id)) return;

      setFetchingChatContainer( (pre) => {
             return {
                ...LoadingObj(pre).setIsLoading(participant_id, true)
             }
          }
      )

      fetchParticipantChat({participant_id}, (response, error) => {
         setFetchingChatContainer( (pre) => {
                return {
                   ...LoadingObj(pre).setIsLoading(participant_id, false)
                }
             }
         )
         scrollToMyRef()
      })
   }

   const sendMessage = () => {
      const _message = messageContainer[participant?.getId()]
      setSendingContainer( (pre) => {
             return {
                ...LoadingObj(pre).setIsLoading(participant?.getId(), true)
             }
          }
      )
      sendOneToOneChat({message: _message, receiver_id: participant?.getId()}, (response, error) => {
         setSendingContainer( (pre) => {
                return {
                   ...LoadingObj(pre).setIsLoading(participant?.getId(), false)
                }
             }
         )
         if (response) {
            const wrapper = ResponseWrapper(response)
            if (wrapper.getStatus()) {
               setMessageContainer((prev) => {
                  return {
                     ...prev,
                     [participant?.getId()]: ''
                  }
               })
               scrollToMyRef()
            }else {
               const {appearance, autoDismiss} = getOptions(ToastType.INFO)
               addToast(wrapper.getMessage(), {appearance, autoDismiss})
            }
         }else if (error){
            const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
            addToast(Error(error).getResponseError(), {appearance, autoDismiss})
         }
      })
   }

   const sendMessageEnter = (e) => {
      if (e.key === "Enter" && messageContainer[participant?.getId()]?.length > 0) {
         sendMessage()
      }
   }

   useEffect(() => {
      if (participant){
         resetNetworkingBadge()
         scrollToMyRef()
      }
   }, [participant])
   useEffect(() => {
      if (fetchingChatContainer.isLoading(participant?.getId()) === false){
         scrollToMyRef()
      }
   }, [fetchingChatContainer])





   const participant_id = participant?.getId()
   const messages = chatContainer?.messageContainer[participant_id]?.messages ?? []

   useEffect(() => {
      if (messages){
         scrollToMyRef()
         const lastMessage = messages?.last()
         if (lastMessage && !lastMessage?.seen){
            resetBadge()
         }
      }
   }, [JSON.stringify(messages)])

   if (isPrefecthing){
      return <div className="col-md-12 col-lg-10 col-xl-11">
         <LiveShimmer />
      </div>
   }

   const onInput = (query) => {
      if (inputTimeout) clearTimeout(inputTimeout)
      setSearchLoading(true)
      setInputTimeout(
          setTimeout(() => {
             if (query !== ""){
                fetchMore(1, "", query, (res, error) => {
                   setSearchLoading(false)
                });
             }else {
                setSearchLoading(false)
             }
          }, 2000)
      )

   }

   const fetchMore = (currentPage, currentChar, search, callback) => {
      //setIsLoading(true);
      getNetworkPeople(slug, {current_page: currentPage, char: currentChar, search: search}, callback)
   }

   if (messages?.length === 0 && (isFetched && !participant_id)){
      return <div className="col-md-12 col-lg-10 col-xl-11">
            <div className="chat_window_inner mb-30 text-center">
                  <p>No messages available. Start new conversation from people!</p>
               </div>
               <div className="chat_window_searchbar mb-50 text-center">
                  <div className='chat_search_wrapper'>
                  <div className="searchbar_right">
                     <input 
                        type="text" 
                        placeholder="Search.." 
                        name="search" 
                        className="form-control" 
                        onChange={(e) => onInput(e.target.value)}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        autocomplete="off"
                     />
                     <button type="submit"> <IconLoader isLoading={seachLoading}> <SearchIcon /> </IconLoader></button>
                  </div>
                  {usersDetail?.users && usersDetail?.users?.length > 0 && (
                     <ul className="list">
                        {usersDetail?.users.filter( u => {
                           return u?.id !== user?.id
                        }).map(item => (
                              <li onClick={() => openChatWindow(item)}>
                                 <span className='chat-user-img'><Image src={item?.avtar} placeholderSrc="/images/placeholder_square.jpg" alt=""/></span> 
                                  <div className="description">
                                    <h4>{item?.name} {item?.last_name}</h4>
                                    <h5>{item?.company} </h5>
                                    <p>{item?.title} </p>
                                  </div>
                              </li>
                        ))}
                     </ul>
                  )}
               </div>
               </div>
            
      </div>
   }

   const membersMap = onlineUsers?.membersMap;
   const isOnlline = membersMap && membersMap[participant?.getId()]

   return (
      <>
      {/*-- chat_window_mobile */}
      <div className="mobile_chat_window mt-50" style={{display:"none"}}>
         <div className="container">
            <div className="mobile_cw_top  mb-50">
               <h1 className="main_heading mb-20">NETWORK</h1>
               <div className="network_right_area">
                  <ul className="network_navbar">
                     <li className="">
                        <a href="network.html">
                           <p>People</p>
                        </a>
                     </li>
                     <li>
                        <a href="meeting.html">
                           <p>My 
                              meetings
                           </p>
                        </a>
                     </li>
                     <li className="active">
                        <a href="chat_window.html">
                           <p>My 
                              messages
                           </p>
                        </a>
                     </li>
                  </ul>
               </div>
               <div className="chat_window_searchbar mb-30 text-center">
                  <div className="searchbar_right">
                     <input type="text" placeholder="Search.." name="search" className="form-control" onChange={(e) => setSearchQuery(e.target.value)} />
                     <button type="submit">
                        <IconLoader isLoading={seachLoading}> <SearchIcon /> </IconLoader>
                     </button>
                  </div>
               </div>
               <div className="chat_window_inner" style={{display:"none"}}>
                  <div className="inbox_chat">
                     <div className="chat_list active_chat">
                        <div className="chat_people">
                           <div className="chat_img"> <img alt="image" src="/images/user_img1.png" /> <span className="dot"></span></div>
                           <div className="chat_ib">
                              <h6>Sat 12/5 15:00 </h6>
                              <h5>Brit Hansen  </h5>
                              <p>Hi there! When can we have a video call? </p>
                              
                           </div>
                        </div>
                     </div>
                     <div className="chat_list">
                        <div className="chat_people">
                           <div className="chat_img"> <img alt="image" src="/images/user_img2.png" alt="r" /> </div>
                           <div className="chat_ib">
                               <h6>Sat 12/5 15:00 </h6>
                              <h5>Brit Hansen </h5>
                              <p>What will you be attending today? </p>
                              
                           </div>
                           <div className="msg_number">
                              <p>2</p>
                           </div>
                        </div>
                     </div>
                     <div className="chat_list">
                        <div className="chat_people">
                           <div className="chat_img"> <img alt="image" src="/images/call_icon3.png" alt="r" /> <span className="dot"></span></div>
                           <div className="chat_ib">
                               <h6>Sat 12/5 15:00 </h6>
                              <h5>Brit Hansen </h5>
                              <p>Nice to hear from you! We should plan a …</p>
                             
                           </div>
                           <div className="msg_number">
                              <p>2</p>
                           </div>
                        </div>
                     </div>
                     <div className="chat_list active_chat">
                        <div className="chat_people">
                           <div className="chat_img"> <img alt="image" src="/images/user_img1.png" /> <span className="dot"></span></div>
                           <div className="chat_ib">
                               <h6>Sat 12/5 15:00 </h6>
                              <h5>Brit Hansen  </h5>
                              <p>Hi there! When can we have a video call? </p>
                              
                           </div>
                           <div className="msg_number">
                              <p>2</p>
                           </div>
                        </div>
                     </div>
                     <div className="chat_list">
                        <div className="chat_people">
                           <div className="chat_img"> <img alt="image" src="/images/user_img2.png" alt="r" /> </div>
                           <div className="chat_ib">
                               <h6>Sat 12/5 15:00 </h6>
                              <h5>Brit Hansen </h5>
                              <p>What will you be attending today? </p>
                            
                           </div>
                        </div>
                     </div>
                     <div className="chat_list">
                        <div className="chat_people">
                           <div className="chat_img"> <img alt="image" src="/images/call_icon3.png" alt="r" /> </div>
                           <div className="chat_ib">
                               <h6>Sat 12/5 15:00 </h6>
                              <h5>Brit Hansen  </h5>
                              <p>Nice to hear from you! We should plan a …</p>
                             
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="mobile_cw_bottom mb-50" style={{display:"none"}}>
               <div className="chat_window_inner">
                  <div className="msg_history main-card">
                     <div className="msg_history_heading mb-40">
                        <div className="mh_user_profile">
                           <div className="back_btn">
                              <button type="button" className="chat-back"> <i className="fa fa-angle-left" aria-hidden="true"></i></button>
                           </div>
                           <div className="img_box">
                              <img alt="image" src="/images/user_img1.png" /> <span className="dot"></span>
                           </div>
                           <div className="info_box">
                              <h4>{participant?.getFullName()}</h4>
                              <p>Online</p>
                           </div>
                        </div>
                        <div className="mh_user_link">
                           <button type="button" className="btn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="41.784" height="41.764" viewBox="0 0 41.784 41.764">
                                 <g id="Group_20" data-name="Group 20" transform="translate(-888.551 -2523)">
                                    <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(888.551 2523)">
                                       <path id="Path_1270" data-name="Path 1270" d="M26.666,28.676l-.12.01a1.661,1.661,0,0,0-.964.452L19.1,35.624A7.312,7.312,0,0,1,8.756,25.283L15.643,18.4a7.268,7.268,0,0,1,1.144-.934,7.379,7.379,0,0,1,1.486-.753,7.029,7.029,0,0,1,1.506-.371,7.119,7.119,0,0,1,1.024-.07c.141,0,.281.01.462.02a7.291,7.291,0,0,1,4.7,2.108,7.2,7.2,0,0,1,1.717,2.741,1.58,1.58,0,0,0,1.938,1.014c.01,0,.02-.01.03-.01s.02,0,.02-.01a1.568,1.568,0,0,0,1.054-1.918,9.1,9.1,0,0,0-2.47-4.116,10.569,10.569,0,0,0-5.783-2.942c-.191-.03-.381-.06-.572-.08a10.361,10.361,0,0,0-1.114-.06c-.261,0-.522.01-.773.03a10.125,10.125,0,0,0-1.626.251c-.11.02-.211.05-.321.08a10.442,10.442,0,0,0-1.958.733,10.313,10.313,0,0,0-2.781,2L6.437,22.994a10.59,10.59,0,0,0-3.062,7.479,10.556,10.556,0,0,0,18.011,7.459l6.556-6.556A1.589,1.589,0,0,0,26.666,28.676Z" transform="translate(-3.375 0.749)" fill="#88888d"/>
                                       <path id="Path_1271" data-name="Path 1271" d="M37.739,6.457a10.58,10.58,0,0,0-14.929,0l-6.4,6.4A1.614,1.614,0,0,0,17.429,15.6a1.631,1.631,0,0,0,1.275-.462l6.405-6.385A7.312,7.312,0,0,1,35.45,19.1l-6.887,6.887a7.268,7.268,0,0,1-1.144.934,7.379,7.379,0,0,1-1.486.753,7.029,7.029,0,0,1-1.506.371,7.118,7.118,0,0,1-1.024.07c-.141,0-.291-.01-.462-.02A7.215,7.215,0,0,1,16.6,23.424a1.6,1.6,0,0,0-1.918-.984A1.622,1.622,0,0,0,13.544,24.5a9.17,9.17,0,0,0,2.379,3.765h0l.02.02A10.57,10.57,0,0,0,22.3,31.3a10.36,10.36,0,0,0,1.114.06q.392,0,.783-.03a11.478,11.478,0,0,0,1.938-.321,10.443,10.443,0,0,0,1.958-.733,10.313,10.313,0,0,0,2.781-2L37.759,21.4a10.567,10.567,0,0,0-.02-14.939Z" transform="translate(0.943 -3.375)" fill="#88888d"/>
                                    </g>
                                 </g>
                              </svg>
                           </button>
                           <button type="button" className="btn">
                              <svg xmlns="http://www.w3.org/2000/svg" width="6" height="40" viewBox="0 0 6 40">
                                 <g id="Group_1572" data-name="Group 1572" transform="translate(-1609 -1292)">
                                    <circle id="Ellipse_48" data-name="Ellipse 48" cx="3" cy="3" r="3" transform="translate(1609 1292)" fill="#88888d"/>
                                    <circle id="Ellipse_49" data-name="Ellipse 49" cx="3" cy="3" r="3" transform="translate(1609 1309)" fill="#88888d"/>
                                    <circle id="Ellipse_50" data-name="Ellipse 50" cx="3" cy="3" r="3" transform="translate(1609 1326)" fill="#88888d"/>
                                 </g>
                              </svg>
                           </button>
                        </div>
                     </div>
                     <ul className="one_chat_box">
                        <li className="chat_right">
                           <div className="chat_inner_right">
                              <h4>Let’s get lunch. How about pizza?</h4>
                              <p>09:41  <span> Me</span></p>
                           </div>
                        </li>
                        <li className="chat_left">
                           <div className="chat_inner_left">
                              <h4>Let’s do it! I’m in a meeting until noon.</h4>
                              <p>10:21  <span>Guest - 2</span></p>
                           </div>
                        </li>
                        <li className="chat_right">
                           <div className="chat_inner_right">
                              <h4>That’s perfect. There’s a new place on Main St I’ve been wanting to check out. I hear their hawaiian pizza is off the hook!</h4>
                              <p>10:23  <span> Me</span></p>
                           </div>
                        </li>
                        <li className="chat_left">
                           <div className="chat_inner_left">
                              <h4>I don’t know why people are so 
                                 anti pineapple pizza. I kind of like 
                                 it.
                              </h4>
                              <p>10:24  <span> Guest - 2</span></p>
                           </div>
                        </li>
                        <li className="chat_left">
                           <div className="chat_inner_left">
                              <h4>Hi there! When can we have a video call? </h4>
                              <p>10:24  <span> Guest - 2</span></p>
                           </div>
                        </li>
                     </ul>
                     <div className="type_msg">
                        <div className="input_msg_write">
                           <input type="text" className="form-control"  placeholder="Message" />
                           <button className="msg_send_btn" type="button"><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      {/*-- chat_window_close */}
         <div className="col-md-12 col-lg-10 col-xl-11">
            <div className='mobile_chat_window'>
                  <div className="chat_window_searchbar mb-30 text-center">
                     <div className="searchbar_right">
                        <input type="text" placeholder="Search.." name="search" className="form-control" onChange={(e) => setSearchQuery(e.target.value)} />
                        <button type="submit">
                           <SearchIcon />
                        </button>
                     </div>
               </div>
            </div>
            <div className="chat_window_inner mb-100">
               <div className="messaging">
                  <div className="row">
                     <div className="col-md-12 col-lg-4 ">
                        <div className="searchbar_right msg_chat_searchbar">
                           <input type="text" placeholder="Search.." name="search" className="form-control" onChange={(e) => setSearchQuery(e.target.value)} />
                           <button type="submit">
                              <SearchIcon />
                           </button>
                        </div>
                        <div className="inbox_chat main-card">
                           {
                              users?.filter(u => {
                                 if (searchQuery?.length > 0){
                                    const name = OnlineOfflineUser(u)?.getFullName()?.toLowerCase()
                                    return name.includes(searchQuery.toLowerCase())
                                 }
                                 return true;
                              }).map((u, i) => <ChatNavItem key={i} isOnline={membersMap && membersMap[OnlineOfflineUser(u).getId()]} onClick={() => setParticipant(OnlineOfflineUser(u))} user={u} message={prefetch_messages[u.id]} badgeCount={networking_chat_badge_map[u.id]}/>)
                           }
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-8 ">
                        <div className="msg_history main-card lr_chat_box">
                           <div className="msg_history_heading mb-40">
                              <div className="mh_user_profile">
                                 <div className="img_box">
                                    <Image src={participant?.getAvatar()} placeholderSrc="/images/placeholder_square.jpg" alt="" />
                                    {
                                       isOnlline ?   <span className="dot"></span> : null
                                    }
                                 </div>
                                 <div className="info_box">
                                    <h4>{participant?.getFullName()}</h4>
                                    {isOnlline ? <p>Online</p> : null}
                                 </div>
                              </div>
                              {/* <div className="mh_user_link">
                                 <input hidden type="file" name="chatFiles" ref={inputFileRef} onChange={uploadAttachment}/>
                                 <button type="button" className="btn" onClick={() => triggerInputFile()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41.784" height="41.764" viewBox="0 0 41.784 41.764">
                                       <g id="Group_20" data-name="Group 20" transform="translate(-888.551 -2523)">
                                          <g id="Icon_ionic-ios-link" data-name="Icon ionic-ios-link" transform="translate(888.551 2523)">
                                             <path id="Path_1270" data-name="Path 1270" d="M26.666,28.676l-.12.01a1.661,1.661,0,0,0-.964.452L19.1,35.624A7.312,7.312,0,0,1,8.756,25.283L15.643,18.4a7.268,7.268,0,0,1,1.144-.934,7.379,7.379,0,0,1,1.486-.753,7.029,7.029,0,0,1,1.506-.371,7.119,7.119,0,0,1,1.024-.07c.141,0,.281.01.462.02a7.291,7.291,0,0,1,4.7,2.108,7.2,7.2,0,0,1,1.717,2.741,1.58,1.58,0,0,0,1.938,1.014c.01,0,.02-.01.03-.01s.02,0,.02-.01a1.568,1.568,0,0,0,1.054-1.918,9.1,9.1,0,0,0-2.47-4.116,10.569,10.569,0,0,0-5.783-2.942c-.191-.03-.381-.06-.572-.08a10.361,10.361,0,0,0-1.114-.06c-.261,0-.522.01-.773.03a10.125,10.125,0,0,0-1.626.251c-.11.02-.211.05-.321.08a10.442,10.442,0,0,0-1.958.733,10.313,10.313,0,0,0-2.781,2L6.437,22.994a10.59,10.59,0,0,0-3.062,7.479,10.556,10.556,0,0,0,18.011,7.459l6.556-6.556A1.589,1.589,0,0,0,26.666,28.676Z" transform="translate(-3.375 0.749)" fill="#88888d"/>
                                             <path id="Path_1271" data-name="Path 1271" d="M37.739,6.457a10.58,10.58,0,0,0-14.929,0l-6.4,6.4A1.614,1.614,0,0,0,17.429,15.6a1.631,1.631,0,0,0,1.275-.462l6.405-6.385A7.312,7.312,0,0,1,35.45,19.1l-6.887,6.887a7.268,7.268,0,0,1-1.144.934,7.379,7.379,0,0,1-1.486.753,7.029,7.029,0,0,1-1.506.371,7.118,7.118,0,0,1-1.024.07c-.141,0-.291-.01-.462-.02A7.215,7.215,0,0,1,16.6,23.424a1.6,1.6,0,0,0-1.918-.984A1.622,1.622,0,0,0,13.544,24.5a9.17,9.17,0,0,0,2.379,3.765h0l.02.02A10.57,10.57,0,0,0,22.3,31.3a10.36,10.36,0,0,0,1.114.06q.392,0,.783-.03a11.478,11.478,0,0,0,1.938-.321,10.443,10.443,0,0,0,1.958-.733,10.313,10.313,0,0,0,2.781-2L37.759,21.4a10.567,10.567,0,0,0-.02-14.939Z" transform="translate(0.943 -3.375)" fill="#88888d"/>
                                          </g>
                                       </g>
                                    </svg>
                                 </button>
                                 <button type="button" className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="40" viewBox="0 0 6 40">
                                       <g id="Group_1572" data-name="Group 1572" transform="translate(-1609 -1292)">
                                          <circle id="Ellipse_48" data-name="Ellipse 48" cx="3" cy="3" r="3" transform="translate(1609 1292)" fill="#88888d"/>
                                          <circle id="Ellipse_49" data-name="Ellipse 49" cx="3" cy="3" r="3" transform="translate(1609 1309)" fill="#88888d"/>
                                          <circle id="Ellipse_50" data-name="Ellipse 50" cx="3" cy="3" r="3" transform="translate(1609 1326)" fill="#88888d"/>
                                       </g>
                                    </svg>
                                 </button>
                                 <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <span className="dropdown-item chat-list">Start video call</span>
                                    <span className="dropdown-item chat-list">Mark all messages as read</span>
                                    <span className="dropdown-item chat-list">Mute this conversation</span>
                                 </div>
                              </div> */}
                           </div>
                           {fetchingChatContainer?.isLoading(participant?.getId()) ? <LiveShimmer /> :
                              <PullToRefresh isPullable={true} onRefresh={() => new Promise((resolve) => getItems(resolve))}>
                              <ul className="one_chat_box" ref={chatContainerRef}>
                                 {messages?.map((m, index) => ChatMessage({isSender: m.sender_id == user?.id, message:{...Message({...m, ...usersMap[m.sender_id] ?? {}})}, key: index}))}
                              </ul>
                               </PullToRefresh>
                           }
                           <div className="type_msg">
                              <div className="input_msg_write">
                                 {/* <input 
                                 value={messageContainer[participant?.getId()]} 
                                 disabled={sendingContainer[participant?.getId()]} 
                                 type="text" 
                                 className="form-control"
                                 placeholder={translations?.chat?.answer_placeholder}
                                 onChange={e => setMessage(e.target.value)}
                                 ref={messageFieldRef}
                                 onKeyDown={sendMessageEnter} 
                                 /> */}
                                 <InputEmoji key={participant?.getId()}
                                    type="text" 
                                    className="form-control"
                                    value={messageContainer[participant?.getId()]} 
                                    // placeholder={translations?.chat?.answer_placeholder}
                                    disabled={sendingContainer.isLoading(participant?.getId())}
                                    onChange={(text) => setMessage(text)}
                                    ref={messageFieldRef}
                                    cleanOnEnter
                                    onKeyDown={sendMessageEnter}
                                    onEnter={sendMessageEnter}
                                 />
                                 <button disabled={sendingContainer.isLoading(participant?.getId())} onClick={sendMessage} className="msg_send_btn" type="button">
                                    <IconLoader isLoading={sendingContainer[participant?.getId()]}>
                                       <SendIcon/>
                                    </IconLoader>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default ChatWindow
