export const O2OMessage = (props) => {
    const _object = props;
    const _loggedin_userid = props?.loggedin_userid
    const sender_id = props?.sender_id
    const receiver_id = props?.receiver_id
    let participant_id = sender_id;
    if (_loggedin_userid === sender_id){
        participant_id = receiver_id
    }
    return {
        getParticipantId:() => participant_id
    }
}