import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useToasts} from "react-toast-notifications";
import {AppointmentsApi} from "../../../../../../hooks/shared/AppointmentsApi";
import _ from 'lodash';
import {RightIcon} from "../../../../../../UIComponets/Icons/RightIcon";
import {ScheduleIcon} from "../../../../../../UIComponets/Icons/ScheduleIcon";
import {CancelIcon} from "../../../../../../UIComponets/Icons/CancelIcon";
import {MeetIcon} from "../../../../../../UIComponets/Icons/MeetIcon";
import {Appointment, DateUtils, UserDTO} from "../../../../../../Util/Utils";
import {UserApi} from "../../../../../../hooks/shared/UserApi";
import { Translations } from '../../../../../../hooks/shared/Translations';
import {AppointmentStatus} from "../../../../../../Model/AppointmentStatus";
import {IconLoader} from "../../../../../../UIComponets/IconLoader";
import { Image } from '../../../../../../UIComponets/Image';
import { VideoCallApi } from '../../../../../../hooks/videoCallApi';
import {SessionApiV2} from "../../../../../../hooks/shared/SessionApi2";
import {SearchIcon} from "../../../../../../SVG/Icons";
import { FaHandsHelping } from "react-icons/fa";
import { MdPending } from "react-icons/md";
import {Error, OnlineOfflineUser} from "../../../../../../Util/Utils";
import {ResponseWrapper} from "../../../../../../Model/ResponseWrapper";
import {getOptions, ToastType} from "../../../../../../hooks/showToast";
import {VideoChatState} from "../../../../../../hooks/VideoChatState";
import {Constants} from "../../../../../../classes/Constants";
import Logger from "../../../../../../Util/Logger";

const LoadingContainer = (appoinment_id, isLoading) => (prev) =>{
    return {
        ...prev,
        [appoinment_id]: isLoading
    }
}

const Meeting = ({rescheduleAppointmentCallback}) => {
    const {
        state: {
            appointments,
            appointmentsMap,
            event,
            details
        },
        acceptAppointment,
        cancelAppointment,
        updateAppointment,
        searchMyMeeting,
        setActiveEu
    } = AppointmentsApi();

    const{
        translations,
    } = Translations();
    
    const {
        user
    } = UserApi();

    const {
        videoConnect,
        onlineUsers
    } = VideoCallApi();

    const { addToast } = useToasts();
    const [isLoadingCall, setIsLoadingCall] = useState(false)
    const membersMap = onlineUsers?.membersMap;
    const [loadingContainer, setLoadingContainer] = useState({});
    const [activeTime, setActiveTime] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());


    useEffect(() => {
        setInterval(function() {
          // do stuff
          // setCurrentTime(new Date())
        }, 2000);    
    }, [])

    const getLoadingObject  = (appointment, type, isLoading) => (prev) => {
        return {
            ...prev,
            [appointment.id] : {
                type: type,
                isLoading: isLoading
            }
        }
    }

    const accept = (appointment) => {
        setLoadingContainer(getLoadingObject(appointment, AppointmentStatus.ACCEPTED, true))
        acceptAppointment(appointment, (response, error) => {
            setLoadingContainer(setLoadingContainer(getLoadingObject(appointment, AppointmentStatus.ACCEPTED, false))
            )
        })
    }

    const reschedule = (appointment) => {
        rescheduleAppointmentCallback && rescheduleAppointmentCallback(appointment)
    }

    const cancel = (appointment) => {
        setLoadingContainer(getLoadingObject(appointment, AppointmentStatus.DECLINE, true))
        cancelAppointment(appointment, (response, error) => {
            setLoadingContainer(getLoadingObject(appointment, AppointmentStatus.DECLINE, false))
        })
    }

    const {
        getZoneTime,
        getZoneDateTime
    } = SessionApiV2()

    const getTime = (time) => {
        return  getZoneTime(activeTime, time)
    };

    const isAlreadyStarted = (date) => {
        const _currrent = moment().tz(event?.timezone ?? Constants.DEFAULT_TIMEZONE).utcOffset(0, true)
        let threash_hold = moment(date).utcOffset(0, true)
        Logger.verbose({ date: date, app_date: threash_hold, current: _currrent, tz: moment.tz.guess() })
        return _currrent >= threash_hold;
    }

    const onLoby = (date) => {
        const _currrent = moment().tz(event?.timezone ?? Constants.DEFAULT_TIMEZONE).utcOffset(0, true)
        let lobyDateTime = moment(date).utcOffset(0, true).subtract(10, 'minutes').local(false)
        let threash_hold = new Date(lobyDateTime);
        return _currrent >= threash_hold;
    }

    useEffect(() => {
        setActiveEu(activeTime)
    }, [activeTime])

    const startCall = (particiapnt, isOnline, appointment) => {
        if (particiapnt && appointment){
            if (isOnline){
                const user_id =  OnlineOfflineUser(particiapnt).getId();
                setLoadingContainer(getLoadingObject(appointment, VideoChatState.CALLING, true))
                videoConnect({user_id, first_message: ""}, (response, error) => {
                    const wrapper = ResponseWrapper(response)
                    if (error){
                        const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                        addToast(Error(error).getResponseError(), {appearance, autoDismiss})
                    }else{
                        const {appearance, autoDismiss} = getOptions(ToastType.INFO)
                        addToast(wrapper.getMessage(), {appearance, autoDismiss})
                    }
                    setLoadingContainer(getLoadingObject(appointment, AppointmentStatus.CALLING, false))
                })
            }else{
                const {appearance, autoDismiss} = getOptions(ToastType.ERROR)
                let message = "Participant is not online."
                const fullName =  OnlineOfflineUser(particiapnt).getFullName();
                if (fullName.length > 0) {
                    message = `${fullName} is not online.`
                }
                addToast(message, {appearance, autoDismiss})
            }
        }

    }


  

    return (
        <>
            <div className="col-md-12 col-lg-10 col-xl-11">
                <div className="network_meeting_inner">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-4"></div>
                        <div className="col-md-12 col-lg-6 col-xl-4 participant_session">
                            <ul className="nav eu_time_tab">
                                <li className={activeTime ? "active" : ""} onClick={() => setActiveTime(true)}>
                                    <a>{event?.shortTimezone} {translations?.general?.time}</a>
                                </li>
                                <li className={activeTime ? "" : "active"} onClick={() => setActiveTime(false)}>
                                    <a>{translations?.general?.device_time}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-4">
                            <div className="searchbar_right">
                                <input 
                                 type="text" 
                                 placeholder="Search.."
                                 name="search" className="form-control"
                                 onChange={(e) => searchMyMeeting(e.target.value)}
                                />
                                <button type="submit">
                                    <SearchIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile view here appointments*/}
                <div className="mobile_network_meeting_date mt-30">
                    {appointments?.length > 0 ? _.map(appointmentsMap, (appointments, key) => {
                        return <>
                                {appointments?.map((a, i) => {
                                    const appointment = Appointment(a)
                                    const loading = _.has(loadingContainer, appointment.getId()) ? loadingContainer[appointment.getId()] : null;
                                    const isLoading = (type) => {
                                        return loading?.isLoading && loading?.type === type
                                    }
                                    const particiapnt = user?.id === appointment.getHostUserId() ? appointment.getParticiapnt() : appointment.getHost()
                                    const userDTO = UserDTO(particiapnt)
                                    const isOnline = membersMap && membersMap[userDTO.getId()] ? true : false;
                                    return (<div className="people_network_box main-card mb-20">
                                        <div className="p_network_b_inner">
                                            <div className="img_box">
                                            <Image src={userDTO.getAvatar()} placeholderSrc="/images/placeholder_square.jpg" alt="" />
                                            {isOnline ? <span className="dot"></span> : null } 
                                            </div>
                                            <div className="info_box">
                                                <h4>{userDTO.getFullName()}</h4>
                                                <p>{userDTO.getCompany()}</p>
                                                <h6>{userDTO.getTitle()}</h6>
                                                <div className="meeting_time">
                                                <div className="button_box">
                                                    {user.id !== appointment.getHostUserId() &&  appointment.getStatus() !== AppointmentStatus.ACCEPTED && <IconLoader isLoading={isLoading(AppointmentStatus.ACCEPTED)} >
                                                        <RightIcon disabled={loading?.isLoading} onClick={() => accept(appointment.getRequestPayload())} />
                                                    </IconLoader>}

                                                    {appointment?.getCanModify() ? <IconLoader isLoading={isLoading(AppointmentStatus.PENDING)} >
                                                        <ScheduleIcon disabled={loading?.isLoading} onClick={() => reschedule(a)}/>
                                                    </IconLoader> : null}

                                                    <IconLoader isLoading={isLoading(AppointmentStatus.DECLINE)} >
                                                        <CancelIcon  disabled={loading?.isLoading} onClick={() => cancel(appointment.getRequestPayload())}/>
                                                    </IconLoader>
                                                </div>
                                                    {
                                                        appointment.getStatus() === AppointmentStatus.ACCEPTED ? isAlreadyStarted(a?.date) || onLoby(a?.date) ?
                                                    (<button type='button' className="btn mobile_button_box_meet" onClick={() => startCall(particiapnt, isOnline,  appointment.getRequestPayload())}>
                                                        <IconLoader isLoading={isLoading(VideoChatState.CALLING)}>JOIN</IconLoader>
                                                    </button>)
                                                    : (<button type="button" title='Meeting room will open 10 minutes before the start time.' className="btn mobile_button_box_meet pending">JOIN</button>) : null
                                                    }
                                                    <p>{ moment(`${a?.date}`).format("DD MMM") } {getTime(a?.date)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="more_btn">
                                            <button type="button" className="btn btn_border_orange">MORE</button>
                                        </div> */}
                                    </div>)
                                })
                            } 
                            </>   
                        })
                    : <div className='col text-center pt-40 mobile_text msg_for_meeting'><p>{details && details?.no_meetings_message}</p></div>}
                </div>
                
                {/* desktop view view here appointments*/}
                {appointments?.length > 0 ?
                    // appointmentsMap
                    _.map(appointmentsMap, (appointments, key) => {
                        const date = DateUtils(key).dayGreetingFormate();
                        return  <div className="network_meeting_date mt-40" key={key}> 
                            <div className="date_box_left">
                                    <span>
                                        <h4>{date.day}</h4>
                                        <p>{date.month}</p>
                                    </span>
                            </div>
                            <div className="meeting_cliant_inner">
                            {
                                appointments?.map((a, i) => {
                                    const appointment = Appointment(a)
                                    const loading = _.has(loadingContainer, appointment.getId()) ? loadingContainer[appointment.getId()] : null;
                                    const isLoading = (type) => {
                                        return loading?.isLoading && loading?.type === type
                                    }
                                    const particiapnt = user?.id === appointment.getHostUserId() ? appointment.getParticiapnt() : appointment.getHost()
                                    const userDTO = UserDTO(particiapnt)
                                    const isOnline = membersMap && membersMap[userDTO.getId()] ? true : false;
                                    return   <div key={i} className={`meeting_cliant_box ${i !== 0 ? ' mt-30' : ""}`}>
                                            <div className="img_box">
                                                <Image src={userDTO.getAvatar()} placeholderSrc="/images/placeholder_square.jpg" alt="" />
                                                {isOnline ? <span className="dot"></span> : null } 
                                            </div>
                                            <div className="info_box">
                                                <div className="date">
                                                    <p>{getTime(a?.date)}</p>
                                                    {/* <p> 15.00 <br/> 16.00 </p> */}
                                                </div>
                                                <div className="descri">
                                                    <p> {userDTO.getFullName()} <span className="orange">{userDTO.getTitle() ? `- ${userDTO.getTitle()}` : ""}</span> <span className="grey">{userDTO.getCompany() ? `- ${userDTO.getCompany()}` : ""}</span></p>
                                                </div>
                                            </div>
                                            <div className="button_box">
                                                {user.id !== appointment.getHostUserId() &&  appointment.getStatus() !== AppointmentStatus.ACCEPTED && <IconLoader isLoading={isLoading(AppointmentStatus.ACCEPTED)} >
                                                    <RightIcon disabled={loading?.isLoading} onClick={() => accept(appointment.getRequestPayload())} />
                                                </IconLoader>}

                                                {appointment?.getCanModify() ? <IconLoader isLoading={isLoading(AppointmentStatus.PENDING)} >
                                                    <ScheduleIcon disabled={loading?.isLoading} onClick={() => reschedule(a)}/>
                                                </IconLoader> : null}

                                                <IconLoader isLoading={isLoading(AppointmentStatus.DECLINE)} >
                                                    <CancelIcon  disabled={loading?.isLoading} onClick={() => cancel(appointment.getRequestPayload())}/>
                                                </IconLoader>
                                            </div>
                                            {/*appears on scheduled time*/}

                                        {
                                            appointment.getStatus() == AppointmentStatus.ACCEPTED ? isAlreadyStarted(a?.date) || onLoby(a?.date) ?
                                            (<div className="button_box_meet"><button type="button" className="btn text-white" onClick={() => startCall(particiapnt, isOnline, appointment.getRequestPayload())}><IconLoader fill="#fff" isLoading={isLoading(VideoChatState.CALLING)}>JOIN</IconLoader></button></div>)
                                            : (<div className="button_box_meet"><button className="btn btn_theme_grey text-white" style={{background :'#B1B3B3'}} data-title='Meeting room will open 10 minutes before the start time.'>JOIN</button> </div>) : null
                                        }
                                    </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                : <div className='col text-center pt-40 msg_for_meeting'><p>{details && details?.no_meetings_message}</p></div> }
            </div>
        </>
    )
}

export default Meeting
