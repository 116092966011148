import { useEffect } from 'react';
import CacheVersion from "./cacheVersion";

const useStyle = url => {
    const REACT_APP_CACHE_BUST = CacheVersion();
    useEffect(() => {
        const script = document.createElement('link');
        // script.href = path + url;
        script.href = url + `?version=${REACT_APP_CACHE_BUST}`;
        script.async = true;
        script.rel = "stylesheet";
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        }
    }, [url]);
};
export default useStyle;