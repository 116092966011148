import {useContext, useEffect} from "react";
import {Context as defaultContext} from "../../context/DefaultSectionContext";
import useSlug from "../useSlug";

export function EventApi() {
    const slug = useSlug()
    const context = useContext(defaultContext);


    return {
        fetchEventsPage: (callback) => context?.fetchEventsPage(slug, callback),
        subscribeChannel: (event, callback) => context?.subscribeChannel(event, callback),
        unsubscribeChannel: (callback) => context?.unsubscribeChannel(callback),
       state: {
           eventsPageData: context?.state?.eventsPageData,
           tickers: context?.state?.eventsPageData?.tickers,
           icons:context?.state?.eventsPageData?.icons,
           options: context?.state?.eventsPageData?.options,
           sponsors: context?.state?.eventsPageData?.sponsors,
           details:context?.state?.eventsPageData?.details,
           event:context?.state?.eventsPageData?.event,
           languages:context?.state?.eventsPageData?.languages,
           layouts: context?.state?.eventsPageData?.layouts,
           user: context?.state?.eventsPageData?.user,
           channelMap: context?.state?.channelMap
       }
    }
}