import React from 'react';
import ReactHtmlParser from 'react-html-parser';


const MoreInfo = ({sponsorDetails, translations}) => {
    const neededKeys = ['hours', 'phone', 'address'];
    const keys = Object.keys(sponsorDetails?.meta ?? {});
    const isExists = keys.reduce((prev, next) => {
        return prev || neededKeys.includes(next);
    }, false) 
    return (
    <>
       
    {isExists ? ( 
            <div className="sd_more_info main-card mb-20">
                    <h1 className="main_heading mb-20">{translations?.general?.more_info}</h1>  
                    <ul>
                        {sponsorDetails?.meta?.address ? 
                            <li>
                                <div className="icon"><img alt="image" src="/images/address.png"/></div>
                                <div className="content">
                                    <h4>{translations?.general?.address}</h4><p>{sponsorDetails?.meta?.address}</p>
                                </div>
                            </li>
                        :""}
                        {sponsorDetails?.meta?.phone ?
                        <li>
                            <div className="icon"><img alt="image" src="/images/phone.png"/></div>
                            <div className="content">
                                <h4>{translations?.general?.phone}</h4><p>{sponsorDetails?.meta?.phone}</p>   
                            </div>
                        </li>
                        :""}
                        {sponsorDetails?.meta?.hours ?
                        <li>
                            <div className="icon"><img alt="image" src="/images/watch.png"/></div>
                            <div className="content">
                                <h4>{translations?.general?.opening_hours}</h4>
                                {ReactHtmlParser(sponsorDetails?.meta?.hours ?? '')}
                            </div>
                        </li>
                        :""} 
                    </ul>
            </div>) : "" 
                        }
        
    </>
    )
}

export default MoreInfo;
