import React,{useEffect, useState} from 'react'
import { SponsersWrapper } from '../../../Event/Components/Pages/Home/Component/Sponsors';
import { SponsersApi } from '../../../../hooks/shared/SponserApi';
import Sponsor from '../../../Event/Components/Pages/Sponsors/SpComponents/Sponsor';
import { Translations } from '../../../../hooks/shared/Translations';
import { ShimmerSimpleGallery, ShimmerTitle} from "react-shimmer-effects";
import { Constants } from '../../../../classes/Constants';

const OnePageSponsers = () => {

  const {
      getSponserData,
      state:{
        sponsors,
        details,
      }
  } = SponsersApi();

  const {
    translations
  }= Translations();

  useEffect(() => {
    if (sponsors) {
       setSponsorsItem(sponsors);
    }
 }, [sponsors]);

  const [isActive, setActive] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [sponsorItem, setSponsorsItem] = useState(sponsors);

  useEffect(()=> {
      setIsLoading(true);
        getSponserData((res, error) => {
          if(res?.data?.status){
              setIsLoading(false);
          }
        });
  }, []);

  let no_data_avail_msg = Constants.NO_DATA_AVAILABLE

  if (!(sponsors?.length > 0)){
      no_data_avail_msg = details?.no_sponsors_message ?? Constants.NO_DATA_AVAILABLE
  }

  return (
    <>
      <div id="sponsors">
          <div className='onepage_desktop_sponsors'>
              <SponsersWrapper/>
          </div>
          <div className="sponsors_area onepage_mobile_sponsors mt-50">
              <div className="container">
                  <h1 className="main_heading mb-20">{translations ? translations?.sponsers?.title : ""}</h1>
                  {!isLoading ? sponsorItem?.length > 0 ? (<Sponsor sponsorItem={sponsorItem} translations={translations}/>) 
                  : (<div className='col text-center'> <span>{no_data_avail_msg}</span></div>)
                  : (<div className='mt-40 mb-100'><ShimmerSimpleGallery row={2} col={4} imageType={'thumbnail'} imageHeight={200} caption/></div>)}
              </div>
          </div>
      </div>
    </>
  )
}

export default OnePageSponsers