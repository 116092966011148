import React, {useContext} from 'react';
import {Context as AuthContext} from "../../../../context/AuthContext";

const Logout = () => {

    const {
        updateLoginStatus
    } = useContext(AuthContext);

    const onLogout = async (e) => {
        e.preventDefault();
        window.User.clearLoginData()
        updateLoginStatus()
    };

    return (
        <>
            <div className="modal fade connection_request outging_call show" id="logout">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="connection_request_mbody">
                            <p>Are you sure you want to log-off?</p>
                            <div className="calling-btn">
                                <button type="button" onClick={onLogout} className="btn btn_border_blue" data-dismiss="modal" data-toggle="modal" data-target="#confirm_del">Yes</button>
                                <button type="button" className="btn btn_border_orange" data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Logout
