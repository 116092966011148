import React, {useEffect, useRef, useState} from 'react';
import SponsorsList from './SponsorsList';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import useSlug from '../../../../../../hooks/useSlug';
import SponsorsDetails from '../../Sponsors/SponsorsDetails';
import {SponsersApi} from "../../../../../../hooks/shared/SponserApi";
import { useTemplate } from '../../../../../../hooks/shared/useTemplate';
import {Template, TemplateDetails} from "../../../../../../Model/TemplateDetails";
import { Translations } from '../../../../../../hooks/shared/Translations';

export const SponsersWrapper = () => {
    const {state: {isSponsorsEnable}} = SponsersApi()

    return <>
        {isSponsorsEnable ? (<Sponsors/>) : null}
    </>
}
const Sponsors = () => {
   const slug = useSlug().absolutePath;
   const [isLoading, setIsLoading] = useState(false);

   const {
      getSponserData,
      state: {
         sponsors
      }
   } = SponsersApi();

   const {translations} = Translations();

   useEffect(()=> {
      if (!sponsors){
          setIsLoading(true);
          getSponserData((res, error) => {
              if(res?.data.status){
                  setIsLoading(false);
              }
          });
      }
   }, []);

   const sponsorDetailsComponentRef = useRef();
   const sponserDetailsCallback = (data) => {
       sponsorDetailsComponentRef?.current?.fetchDetails(data)
   }

   const countItems = (ScreenWidth) => {
      let sponsorlength= sponsors?.length ?? 0;
      if(ScreenWidth >= 1200){
         return sponsorlength > 5 ? true : false;
      }
      else if(ScreenWidth < 992 && ScreenWidth > 768){
         return sponsorlength > 3 ? true : false;
      }
      else if(ScreenWidth < 768 && ScreenWidth > 320){
         return sponsorlength > 2 ? true : false;
      }
      else if(ScreenWidth <= 320){
         return sponsorlength > 1 ? true : false;
      }
   }

   const options = {
      margin: 20,
      responsiveClass: true,
      nav:countItems(window.innerWidth),
      dots:false,
      loop:countItems(window.innerWidth),
      autoplay:countItems(window.innerWidth),
      autoplayTimeout:3000,
      responsive: {
          0: {
              items: 1,
          },
          768: {
              items: 2,
          },
          992: {
              items: 3,
          },
          1200: {
              items: 5,
          }
      },
   };



   const {
      state: {
          layout
      }
  } = useTemplate()

  const details = TemplateDetails(layout)
  const isOnePageTemplate = details.getTemplate() === Template.ONEPAGE;

   return (
      <>
         {/* <!-- sponsors_slider --> */}
         <section className="sponsors_slider_area mt-50 mb-50">
            <div className="container">
                  <h1 className="main_heading mb-20">
                     {translations?.sponsers?.title}
                  </h1>
                  
                  {sponsors ? (
                     <OwlCarousel className='owl-carousel owl-theme  sponsors' {...options}>
                        <SponsorsList sponsors={sponsors} sponserDetailsCallback={sponserDetailsCallback}/>
                     </OwlCarousel>) : (<ShimmerSimpleGallery  row={1} col={4} imageHeight={180} />)
                   }
               </div>
               <div className="view_all_btn">
                  <Link to={slug+'/sponsors'}>
                     <button className="btn">
                        <span><i className="fa fa-angle-right" ></i></span> {translations?.general?.view_all}
                     </button>
                  </Link>
               </div>
            </section>

            {/* sponsors_model_open */}
          {isOnePageTemplate ? <div className="modal fade main_model sponsors_details_model" id="sponsors-details">
               <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
               
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                     </div>
               
                     <div className="modal-body">
                        <SponsorsDetails ref={sponsorDetailsComponentRef}/>
                     </div>
                  </div>
               </div>
            </div> : "" }
            {/* sponsors_model_close */}
         {/*  Sponsors Slider */}
      </>
   )
}

export default Sponsors;
