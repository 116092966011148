import React, { useEffect, useState } from 'react'
import { VideoCallApi } from "../hooks/videoCallApi";
import { IconLoader } from "./IconLoader";
import useSlug from "../hooks/useSlug";
import { OptionConstants } from "../constants/Constants";
import { RouteWrapper } from "../templates/Event/Routes";
import { URLContants } from "../Model/URLContants";
import { useHistory } from "react-router-dom";
import ring2 from "./../assets/audio/ring2.mp3";
import { Image } from "./Image";
import { useTemplate } from "../hooks/shared/useTemplate";
import { Template, TemplateDetails } from "../Model/TemplateDetails";
import { EventApi } from '../hooks/shared/EventApi';
import Logger from "../Util/Logger";

export const Timer = ({ initialMinute = 0, initialSeconds = 0, timeLeftCallback }) => {
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    useEffect(() => {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval)
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
        timeLeftCallback && timeLeftCallback({ minutes, seconds })
      }, 1000)
      return () => {
        clearInterval(myInterval);
      };
    });
  
    return (
      <>
        {minutes === 0 && seconds === 0
          ? null
          : <p> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
        }
      </>
    )
}

const IncomingCallPop = () => {
    const [endCallLoading, setEndCallLoading] = useState(false)
    const [acceptLoading, setAcceptLoading] = useState(false)
    const [declineLoading, setDeclineLoading] = useState(false)
    const isLoading = acceptLoading || declineLoading
    const {
        incomingCall,
        videoChatAccept,
        declineMatch,
        endChat
    } = VideoCallApi()

    const {
        state :{
            details
        }
    } = EventApi();

    let callIntervalInMinutes = 60;
    let callIntervalInSeconds = 0;
    if(details?.nt_waiting_time){
        const secondsTime = details?.nt_waiting_time ? parseInt(details?.nt_waiting_time) : 60;
        var minutes = Math.floor(secondsTime / 60);
        var seconds = secondsTime - minutes * 60;
        callIntervalInMinutes = minutes
        callIntervalInSeconds = seconds
    }

    const { state: { layout } } = useTemplate()

    const history = useHistory();
    const match_id = incomingCall?.match_id;
    const slug = useSlug()
    const Path = RouteWrapper(slug.absolutePath)
    const delay = 500
    const [templateDetails, setTemplateDetails] = useState(null)
    useEffect(() => {
        if (layout) {
            setTemplateDetails(TemplateDetails(layout))
        }
    }, [layout])

    const accept = () => {
        if (!match_id) return;
        setAcceptLoading(true)
        window.recentTab = true
        setTimeout(() => {
            if (templateDetails?.getTemplate() === Template.EVENT) {
                history.push(Path(URLContants.NETWORK));
            }
            videoChatAccept({ match_id }, (response, error) => {
                setAcceptLoading(true)
                if (error) {

                }
            })
        }, delay)
    }

    const decline = () => {
        if (!match_id) return;
        window.recentTab = true
        setDeclineLoading(true)
        setTimeout(() => {
            declineMatch({ match_id }, (response, error) => {
                setDeclineLoading(false)
            })
        }, delay)
    }

    const endCall = () => {
        setEndCallLoading(true)
        endChat({}, (response, error) => {
          setEndCallLoading(false)
        })
    }

    const timeLeft = ({ minutes = 0, seconds = 0 }) => {
        const timeLeftSeconds = (minutes * 60) + seconds;
        const totalSeconds = callIntervalInMinutes * 60 + callIntervalInSeconds;
        const percent = totalSeconds / timeLeftSeconds;
        if (totalSeconds == percent) {
            decline();
        }
    }

    return (
        <>
            <div className="gs1_call_connect_window">
                {/*<audio loop={true} autoPlay={true}>*/}
                {/*    <source src={ring2} type="audio/mp3" />*/}
                {/*</audio>*/}
                <div className="calling-widget">
                    <div className="calling_user">
                        <Image src={incomingCall?.requesting_avtar} placeholderSrc={"/images/placeholder_square.jpg"} alt={"participant image"} />
                    </div>
                    <div className="calling_detail">
                        <Timer initialMinute={callIntervalInMinutes} timeLeftCallback={timeLeft} initialSeconds={callIntervalInSeconds} />
                        <h4>Incoming call from</h4>
                        <h6>{incomingCall?.requesting_username}</h6>
                    </div>
                    <div className="calling_action_wrap" >
                        <button disabled={acceptLoading} href="#" className="calling-ans-btn" onClick={accept}>
                            <IconLoader isLoading={acceptLoading}>
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" className="svg-inline--fa fa-phone-alt fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg>
                            </IconLoader>
                        </button>
                        <button type="button" disabled={declineLoading} href="#" className="calling-decline-btn" onClick={decline}>
                            <IconLoader isLoading={declineLoading}>
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" className="svg-inline--fa fa-phone-alt fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg>
                            </IconLoader>
                        </button>


                    </div>

                </div>
                {incomingCall?.interest ? <p className="pop_interest">
                    <span className="dice-icon mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.339" height="31.339" viewBox="0 0 31.339 31.339">
                            <path id="Icon_metro-dice" data-name="Icon metro-dice" d="M29.013,7.8H13.344a4.911,4.911,0,0,0-4.9,4.9v15.67a4.911,4.911,0,0,0,4.9,4.9h15.67a4.911,4.911,0,0,0,4.9-4.9V12.7a4.911,4.911,0,0,0-4.9-4.9ZM15.3,29.35a3.218,3.218,0,0,1-1.548-.44,2.812,2.812,0,0,1-1.39-2.5A2.938,2.938,0,1,1,15.3,29.35Zm0-11.752A2.938,2.938,0,1,1,18.24,14.66,2.938,2.938,0,0,1,15.3,17.6Zm5.876,5.876a2.938,2.938,0,1,1,2.938-2.938A2.938,2.938,0,0,1,21.179,23.474Zm5.876,5.876a2.938,2.938,0,1,1,2.938-2.938A2.938,2.938,0,0,1,27.055,29.35Zm0-11.752a2.938,2.938,0,1,1,2.938-2.938A2.938,2.938,0,0,1,27.055,17.6Zm.88-11.752a4.916,4.916,0,0,0-4.8-3.917H7.467a4.911,4.911,0,0,0-4.9,4.9v15.67a4.916,4.916,0,0,0,3.917,4.8V7.8A1.964,1.964,0,0,1,8.447,5.845H27.935Z" transform="translate(-2.571 -1.928)" fill="#fff" />
                        </svg>
                    </span>
                    You’ve been matched on “{incomingCall?.interest}”!
                </p> : null }
            </div>
        </>
    )
}

export default IncomingCallPop;
