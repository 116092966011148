import React from 'react';
import {imageBaseUrl, imageWithStorage} from "../../../../../constants/url/urlConstants";
const BannerSlider = ({ sliders }) => {

    const banner = <>
     {sliders && sliders?.map((slider, index) => {
            return (
                <div className="items" key={index}>
                    <div className="banner_box">
                        <img src={imageWithStorage + slider?.slider_image} alt="banner images"/>
                    </div>
                </div>  )
            })}
        </>
    return (banner)
}

export default BannerSlider;
