import React, {useEffect, useState} from "react";
import { FaQuestion } from "react-icons/fa";
import {EventApi} from "../../../../hooks/shared/EventApi";
import {Header} from "../Header/Header";
import Footer from "../Footer/Footer";
import IncomingCallPop from "../../../../UIComponets/IncomingCallPop";
import {VideoCallApi} from "../../../../hooks/videoCallApi";
import {VideoChatState} from "../../../../hooks/VideoChatState";
import WebMobileHeader from "../../../Event/Components/Partials/Header/WebMobileHeader";
import LeftMenuBar from "../Header/LeftMenuBar";
import TopMenuBar from "../Header/TopMenuBar";
import {CustomHeader} from "../Header/CustomHeader";
import Live from "../../../Event/Components/Pages/Live/Live";
import VideoVoult from "../VideoVault/VideoVoult";
import Session from "../Sessions/Session";
import Network from "../network/Network";
import OnePageSponsers from "../Sponsers/OnePageSponsers";
import {SlideDecksWrapper} from "../../../Event/Components/Pages/Home/Component/SlideDecks";
import { SpeakersWrapper } from "../../../Event/Components/Pages/Speaker/Speaker";
import Logout from "../Auth/Logout";
import DailyTrivia from "../../../Event/Components/Pages/DailyTrivia/DailyTrivia";
import SocialWall from "../../../Event/Components/Pages/SocialWall/SocialWall";
import useBodyClass from "../../../../hooks/BodyClass";
import { AppSettingOptions } from "../../../../hooks/shared/AppSettings";
import { imageWithStorage } from "../../../../constants/url/urlConstants";
import { Translations } from "../../../../hooks/shared/Translations";
import FaqModal from "../../../Event/Components/Pages/Faq/FaqModal";
import { SplashScreenDialog } from "../../../Shared/SplashScreenDialog";
import SupportScript from "../../../../utils/SupportScript";

export const Layout = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    const {videoChatState, incomingCall} = VideoCallApi();
    useBodyClass("organization-body");

    const {
        fetchEventsPage,
        state: {
            eventsPageData,
            options,
            details,
            layouts,
            event,
            user
        },
    } = EventApi();

    const {
        translations
    } = Translations();


    useEffect(() => {
        const favicon = document.getElementById("favicon");
        if (favicon) {
          if (details?.img_favicon) {
            favicon.href = imageWithStorage + details?.img_favicon;
          } else if (details?.img_logo_desktop) {
            favicon.href = imageWithStorage + details?.img_logo_desktop;
          }
        }
    }, [details?.img_logo_desktop]);

    useEffect(() => {
        fetchEventsPage()
    }, [])

    useEffect(() => {
        const bodyClass = document.querySelector(".organization-body");
        if (bodyClass) {
            bodyClass.setAttribute("style","");
        }
    }, []);

    useEffect(() => {
        if (details){
            if (details?.register_bg_color){
                document.documentElement.style.setProperty('--register-bg-color', details?.register_bg_color);
                document.documentElement.style.setProperty('--register-text-color', details?.register_text_color);
            }
            if (details?.registered_bg_color){
                document.documentElement.style.setProperty('--registered-bg-color', details?.registered_bg_color);
                document.documentElement.style.setProperty('--registered-text-color', details?.registered_text_color);
            }
            if (details?.join_bg_color){
                document.documentElement.style.setProperty('--join-bg-color', details?.join_bg_color);
                document.documentElement.style.setProperty('--join-text-color', details?.join_text_color);
            }
            if (details?.ended_bg_color){
                document.documentElement.style.setProperty('--ended-bg-color', details?.ended_bg_color);
                document.documentElement.style.setProperty('--ended-text-color', details?.ended_text_color);
            }
            if (details?.replay_bg_color){
                document.documentElement.style.setProperty('--reply-bg-color', details?.replay_bg_color);
                document.documentElement.style.setProperty('--reply-text-color', details?.replay_text_color);
            }
        }
    }, [details]);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 600) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const {
        isSlidedackEnable,
        isNetworkingEnable,
        isSponsorsEnable,
        isSpeakersEnable,
        isDailyTriviaEnable,
        isSocialWallEnable,
        isSessionEnable,
        isVODEnable
    } = AppSettingOptions();

    let VODLayout = (
        <React.Fragment key="vod">
            {isVODEnable ? <VideoVoult/> : null} 
        </React.Fragment>
    );

    let SessionsLayout = (
        <React.Fragment key="sessions">
             {isSessionEnable ? <Session/> : null} 
        </React.Fragment>
    );

    let SlideDecksLayout = (
        <React.Fragment key="slide_decks">
             {isSlidedackEnable ? <SlideDecksWrapper/> : null}
        </React.Fragment>
    );

    let SponsorsLayout = (
        <React.Fragment key="sponsors">
            {isSponsorsEnable ? <OnePageSponsers/> : null} 
        </React.Fragment>
    );

    let SpeakersLayout = (
        <React.Fragment key="speakers">
             {isSpeakersEnable ? <SpeakersWrapper/> : null}
        </React.Fragment>
    );

    let DailyTriviaLayout = (
        <React.Fragment key="daily_trivia">
               {isDailyTriviaEnable ? <DailyTrivia/> : null}
        </React.Fragment>
    );

    let SocialWallLayout = (
        <React.Fragment key="social_wall">
               {isSocialWallEnable ? <SocialWall/> : null}
        </React.Fragment>
    );
    
    let NetworkLayout = (
        <React.Fragment key="network">
             {isNetworkingEnable ? <Network/> : null}
        </React.Fragment>
    );

    let componentsDesktop = [];
    let componentsMobile = [];

    const layout_map = {
        vod: VODLayout,
        sessions: SessionsLayout,
        slide_decks: SlideDecksLayout,
        sponsors: SponsorsLayout,
        network: NetworkLayout,
        speakers: SpeakersLayout,
        daily_trivia: DailyTriviaLayout,
        social_wall: SocialWallLayout,
    };
   
    let layoutSectionDesktop = layouts && Object.keys(layouts?.desktop).sort((i1, i2) => i1.position - i2.position);
    let _layoutsDesktop = [];
    layouts && layoutSectionDesktop.forEach((item) => {
        if (layout_map[item]) {
            _layoutsDesktop.push(layout_map[item]);
        }
    });

    componentsDesktop = _layoutsDesktop;

    let layoutSectionMobile = layouts && Object.keys(layouts?.mobile).sort((i1, i2) => i1.position - i2.position);
    let _layoutsMobile = [];
    layouts && layoutSectionMobile.forEach((item) => {
        if (layout_map[item]) {
            _layoutsMobile.push(layout_map[item]);
        }
    });

    componentsMobile = _layoutsMobile;

    return <>
        <div id="home">
            {videoChatState === VideoChatState.RINGING  && <IncomingCallPop/>}
            {details?.show_header === "default" && (<Header/>)}
            {details?.show_header === "custom" && (<CustomHeader/>)}
            <WebMobileHeader/>
            {details?.show_top_menu === "top" && (<TopMenuBar/>)}
            {details?.show_top_menu === "side" && (<LeftMenuBar/>)}
            <Live/>

            {/* Layouts */}
            {window.innerWidth <= 767 ? (<>{componentsMobile}</>) : (<>{componentsDesktop}</>)}

            {options?.includes('faq') && <div className="faq_section"><span className="faq_footer" data-toggle="modal" data-target="#showFaq"><FaQuestion/></span></div>}
            {eventsPageData?.event?.support && process.env.REACT_APP_ENVIRONMENT === 'production' && <SupportScript script={eventsPageData?.event?.support} user={user}/>}
            {eventsPageData?.event?.support && process.env.REACT_APP_ENVIRONMENT !== 'production' && <SupportScript script={eventsPageData?.event?.support}/>}
            {details?.show_footer === "show" && (<Footer/>)}
            {options?.includes('faq') && (<FaqModal translations={translations}/>)}
            {showTopBtn && (<div className="arrow_footer_top" onClick={goToTop}>
                <span class="arrow_footer"><i class="fa fa-chevron-up"></i></span>
            </div>)}
            <Logout/>
            <SplashScreenDialog />
        </div>
    </>
}