export const ResponseWrapper = (response: any) => {
    const _resData = response?.data;
    const _data = _resData?.data;
    const _message = _resData?.message;
    const _status = _resData?.status;

    return {
        getData: () => _data,
        getMessage: () => _message,
        getStatus: () => _status
    }

}