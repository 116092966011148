import React, { useEffect, useState } from 'react';
import { Image } from '../../../../../../UIComponets/Image';
import { IconLoader } from '../../../../../../UIComponets/IconLoader';
import { VideoCallApi } from '../../../../../../hooks/videoCallApi';
import { EventApi } from '../../../../../../hooks/shared/EventApi';
import {AudioEnableIcon,VideoEnableIcon} from "../UIComponents/VideoChatIcons";

export const Timer = ({ initialMinute = 0, initialSeconds = 0, timeLeftCallback }) => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
      timeLeftCallback && timeLeftCallback({ minutes, seconds })
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      {minutes === 0 && seconds === 0
        ? null
        : <p> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
      }
    </>
  )
}

export const WaitingPop = () => {

  const [endCallLoading, setEndCallLoading] = useState(false);
  const [isFetchingMatchedUser, setIsFetchingMatchedUser] = useState(false)
  const [interest, setInterest] = useState("");

  const {
    videoChat,
    fetchMatchedUser,
    connectedPeoples,
    endChat,
  } = VideoCallApi();

  const {
    state: {
      details
    }
  } = EventApi();

  useEffect(() => {
    if (videoChat) {
      setIsFetchingMatchedUser(true);
      fetchMatchedUser((response) => {
        if(response){
          setInterest(response?.data?.data?.foi);
          setIsFetchingMatchedUser(false);
        }
      });
    }
  },[videoChat]);


  let callIntervalInMinutes = 60;
  let callIntervalInSeconds = 0;
  if (details?.nt_waiting_time) {
    const secondsTime = details?.nt_waiting_time ? parseInt(details?.nt_waiting_time) : 60;
    var minutes = Math.floor(secondsTime / 60);
    var seconds = secondsTime - minutes * 60;
    callIntervalInMinutes = minutes
    callIntervalInSeconds = seconds
  }

  const endCall = () => {
    setEndCallLoading(true)
    endChat({}, (response, error) => {
      setEndCallLoading(false)
    })
  }

  const connectedUser = connectedPeoples?.reduce(function (acc, i) {return i;}, {});

  const timeLeft = ({ minutes = 0, seconds = 0 }) => {
    const timeLeftSeconds = (minutes * 60) + seconds;
    const totalSeconds = callIntervalInMinutes * 60 + callIntervalInSeconds;
    const percent = totalSeconds / timeLeftSeconds;
    if (totalSeconds === percent) {
        endCall();
    }
  }

  return <>
    <div className='waiting_call_timer'><Timer initialMinute={callIntervalInMinutes} timeLeftCallback={timeLeft} initialSeconds={callIntervalInSeconds} /></div>
    <div className="waiting_call_popup_box">
      <Image src={connectedUser?.avatar} placeholderSrc={"/images/placeholder_square.jpg"} className="waiting_call_back" alt={"participant image"} />
      <div className="calling-widget">
        <div className="calling_detail">
          <h4>You are calling {connectedUser?.name} {connectedUser?.last_name}</h4>
          {interest !== "Random" && (<p>
            <span className="icon mr-2 primary-color">
              <svg xmlns="http://www.w3.org/2000/svg" width="23.74" height="23.696" viewBox="0 0 23.74 23.696">
                <path id="Icon_metro-dice" data-name="Icon metro-dice" d="M22.6,6.371H10.731a3.717,3.717,0,0,0-3.709,3.7V21.922a3.717,3.717,0,0,0,3.709,3.7H22.6a3.717,3.717,0,0,0,3.709-3.7V10.074a3.717,3.717,0,0,0-3.709-3.7ZM12.215,22.662a2.441,2.441,0,0,1-1.172-.333,2.125,2.125,0,0,1-1.053-1.889,2.226,2.226,0,1,1,2.226,2.222Zm0-8.886a2.222,2.222,0,1,1,2.226-2.222,2.224,2.224,0,0,1-2.226,2.222Zm4.451,4.443A2.222,2.222,0,1,1,18.892,16,2.224,2.224,0,0,1,16.666,18.219Zm4.451,4.443a2.222,2.222,0,1,1,2.226-2.222A2.224,2.224,0,0,1,21.117,22.662Zm0-8.886a2.222,2.222,0,1,1,2.226-2.222A2.224,2.224,0,0,1,21.117,13.776Zm.667-8.886A3.722,3.722,0,0,0,18.15,1.928H6.28a3.717,3.717,0,0,0-3.709,3.7V17.479a3.719,3.719,0,0,0,2.967,3.628V6.371A1.487,1.487,0,0,1,7.022,4.89H21.784Z" transform="translate(-2.571 -1.928)" />
              </svg>
            </span>
             Matching interest: ‘{interest}’
          </p>)}
        </div>
        <div className="calling_user">
          <Image src={connectedUser?.avatar} placeholderSrc={"/images/placeholder_square.jpg"} alt={"participant image"} />
        </div>
        <div className="calling_action_wrap" >
          {/* <button type="button" className="btn">
            <VideoEnableIcon fill={"white"} />
          </button>
          <button type="button" className="btn">
            <AudioEnableIcon fill={"white"} />
          </button> */}
          <button type="button" className="calling-decline-btn" disabled={endCallLoading} onClick={endCall}>
            <IconLoader isLoading={endCallLoading}>
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" class="svg-inline--fa fa-phone-alt fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg>
            </IconLoader>
          </button>
        </div>
      </div>
    </div>
  </>;
};
