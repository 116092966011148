import React from "react";

export const ScheduleIcon = ({onClick, disabled}) => {
    return <button disabled={disabled} onClick={onClick} type="button" className="btn" data-toggle="modal" data-target="#sehedule_appointment">
        <svg xmlns="http://www.w3.org/2000/svg" width="37.183" height="37.183" viewBox="0 0 37.183 37.183">
            <g id="Group_1853" data-name="Group 1853" transform="translate(-1509.461 -343.75)">
                <g id="Group_1851" data-name="Group 1851">
                    <g id="FORM_-_Checkbox_active" data-name="FORM - Checkbox active" transform="translate(1509.461 343.75)">
                        <g id="FORM_-_checkbox_active-2" data-name="FORM - checkbox active">
                            <g id="FORM_-_Checkbox_Inactive" data-name="FORM - Checkbox Inactive">
                                <g id="Rectangle_1372" data-name="Rectangle 1372" fill="#fff" stroke="#032e6a" strokeWidth="3">
                                    <rect width="37.183" height="37.183" rx="10" stroke="none" />
                                    <rect x="1.5" y="1.5" width="34.183" height="34.183" rx="8.5" fill="none" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <path
                    id="Icon_awesome-calendar-plus"
                    data-name="Icon awesome-calendar-plus"
                    d="M17.309,6.293H.476A.476.476,0,0,1,0,5.821V4.405A1.9,1.9,0,0,1,1.906,2.517H3.811V.472A.476.476,0,0,1,4.288,0H5.876a.476.476,0,0,1,.476.472V2.517h5.082V.472A.476.476,0,0,1,11.91,0H13.5a.476.476,0,0,1,.476.472V2.517H15.88a1.9,1.9,0,0,1,1.906,1.888V5.821A.476.476,0,0,1,17.309,6.293ZM.476,7.552H17.309a.476.476,0,0,1,.476.472V18.25a1.9,1.9,0,0,1-1.906,1.888H1.906A1.9,1.9,0,0,1,0,18.25V8.024A.476.476,0,0,1,.476,7.552Zm12.545,5.507a.476.476,0,0,0-.476-.472H10.163v-2.36a.476.476,0,0,0-.476-.472H8.1a.476.476,0,0,0-.476.472v2.36H5.24a.476.476,0,0,0-.476.472v1.573a.476.476,0,0,0,.476.472H7.622v2.36a.476.476,0,0,0,.476.472H9.687a.476.476,0,0,0,.476-.472V15.1h2.382a.476.476,0,0,0,.476-.472Z"
                    transform="translate(1519.16 352.482)"
                    fill="#002c6c"
                />
            </g>
        </svg>
    </button>
}